// VersionCheck - component to check app version, and alert if reload needed
//  - Relies on Webpack's cache-busting hash id's tacked onto css and js refs within index.html
// Based on: https://marmelab.com/blog/2016/08/29/auto-reload-spa-on-mobile-setinterval.html

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class VersionCheck extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    tryDelay: PropTypes.number.isRequired,
    reloadMessage: PropTypes.string.isRequired,
  }
  static defaultProps = {
    url: '/',
    tryDelay: 5 * 60 * 1000, // 5 minutes
    reloadMessage: 'Aww Yeah, a new version of CodeSpace is here!',
  }

  constructor(props) {
        super(props)
        this.previousHash = null
        this.state = {
            codeHasChanged: false,
        }
    }

    componentDidMount() {
        this.fetchSource()
        this.interval = setInterval(this.fetchSource, this.props.tryDelay)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    fetchSource = () => {
        return fetch(this.props.url)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('offline')
                }
                return response.text()
            })
            .then(html => {
                const hash = this.hash(html)
                if (!this.previousHash) {
                    this.previousHash = hash
                    return
                }
                if (this.previousHash !== hash) {
                    this.previousHash = hash
                    this.setState({ codeHasChanged: true })
                }
            })
            .catch(() => { /* do nothing */ })
    }

    /**
     * Java-like hashCode function for strings
     *
     * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
     */
    hash(str) {
        const len = str.length
        let hash = 0
        if (len === 0) return hash
        let i
        for (i = 0; i < len; i++) {
            hash = ((hash << 5) - hash) + str.charCodeAt(i)
            hash |= 0 // Convert to 32bit integer
        }
        return hash
    }

    reloadApp = (e) => {
        window.location.reload(true)
        e.preventDefault()
    }

    render() {
        if (!this.state.codeHasChanged) {
           return null
        }
        const style = {
            position: 'absolute',
            top: 10,
            right: 10,
            padding: '0.5em',
            zIndex: 1050,
            backgroundColor: '#FFEB3B',
            borderRadius: 5,
            textAlign: 'center',
            boxShadow: '6px 6px 10px 0px rgba(0,0,0,0.75)',
            cursor: 'pointer',
        }
        return (
            <div
              style={style}
              onClick={this.reloadApp}
            >
                <div>{this.props.reloadMessage}<br />Click <b>here</b> to load</div>
            </div>
        )
    }
}


export default VersionCheck