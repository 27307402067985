// Project: Spirit Level

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  //LegendMessageWarning,
  //LegendMessageConcept,
  //LegendMessageInteract,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import heroImg from './assets/Level.png'
import StandingBits from './assets/StandingBits.gif'
import LedMatrix from './assets/LedMatrix.gif'
import SolarTrack from './assets/SolarTrack.jpg'
import AccelX from './assets/AccelX.gif'
import ProtractorImg from './assets/Protractor.gif'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'


export const SpiritLevelImg = heroImg

class SpiritLevel extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Spirit Level {lesson-title}
___
#### *Level Up!* {centered}
#### How level is your desk or table?
Write some code to find out! In this project you'll build a *spirit level*!
`}
<img src={heroImg} alt=""
    style={{
      width: '80%', margin: 'auto', display:'block',
    }}
/>
{`
You will create a **digital level** using the micro:bit's built-in ==accelerometer== and LED display.
You'll physically rotate the microbit to *move* the digital "bubble" on the display!

#### Project Goals:
* Display a numeric "tilt" value from the accelerometer.
* *Scale* the raw tilt value to show **0-9**, indicating 0&deg; to 90&deg; incline.
* Replace the number display with a **graphical** *bubble* simulation!
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class ShowTilt extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Tilt-o-Matic {lesson-title}
___
#### First step is to read the ==accelerometer==.
You will be using the X-axis value to detect orientation as shown here:
`}
<img src={AccelX} alt=""
    style={{
      width: '33%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
The **raw value** of **X** will be in the range of **-1023 to +1023** when the micro:bit is *not moving.*
> When it's *at rest*, the only **acceleration** it *feels* is the earth's gravity.

*Earth's gravity is the ultimate authority on "Level" after all.* {centered}

If you *move or shake* the micro:bit, you can create *larger* acceleration values! In the next step, you'll
add an \`if\` statement to make sure out-of-range values don't mess things up.
`}
<LegendMessageKeyboard>
{`* Create a new file and name it **Spirit_Level**
* Write a program that displays the accelerometer's X value.
\`\`\`
from microbit import *

while True:
    # Get the raw value (at rest it's 0-1023)
    tilt = accelerometer.get_x()
    display.scroll(str(tilt))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
`}
</LegendMessageRun>
<StepComplete
  prompt="Are you seeing +/- numbers as you tilt the micro:bit?"
  xp={25}
  successMessage=
{`## Rock On! {centered}
### You've already made something useful...
Just a little bit of code, and I can already see how this is gonna work!
`}
  reqImports={['microbit',]}
  reqCalls={['accelerometer.get_x', 'display.scroll', 'str']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class ScaleDegrees extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Scale to Degrees {lesson-title}
___
#### To get an accurate *digital* reading, you need *real* units of measure!
`}
<img src={ProtractorImg} alt=""
    style={{
      width: '35%', margin: 'auto', display:'block', float:'right', marginLeft:20
    }}
/>
{`If a protractor is placed horizontally on a level surface, **0&deg; is level**.   {centered}

**Make a *digital level* that shows *degrees***

> To keep the display simple and fast, **show just a single digit, 0 to 9** to indicate 0&deg; - 90&deg;.
`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`The tricky part is **converting** from the raw value to degrees.
* In the code below, do you see how \`tilt / 1024\` will be a fraction between -1 and +1?
* **Be sure to delete the \`display.scroll\` line!**
\`\`\`
from microbit import *

while True:
    # Get the raw value (at rest it's 0-1023)
    tilt = accelerometer.get_x()

$$$
$    # Scale the value to 0-9
$    scaled = (tilt / 1024) * 10

$    # Just an Integer, please
$    scaled = int(scaled)

$    # Keep positive, and cap value at 9
$    if scaled < 0:
$        scaled = -scaled
$    if scaled > 9:
$        scaled = 9

$$$
$    display.show(str(scaled))
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why is \`tilt\` divided by **1024** in the code above?
`}
  </Markdown>
  )}
  id={"Scaling values"}
  xp={5}
  answerRight={"1024 is larger than the expected values for tilt, so tilt/1024 will be a proper fraction."}
  answerWrong={["1024 is the same as 1k.", "There are 1024 accelerons per degree.", "1024 is the universal gravity wave coefficient."]}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What does the line \`scaled = -scaled\` do in the code above?
`}
  </Markdown>
  )}
  id={"Unary negation"}
  xp={5}
  answerRight={"Changes the sign of the 'scaled' variable from negative to positive."}
  answerWrong={["Adds a 'dash' to the scaled value.", "Keeps 'scaled' below 9."]}
/>
<LegendMessageRun>
<Markdown>
<img src={StandingBits} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right'
    }}
/>
{`Take your **Digital Level** for a spin!

You should see a reading of **0** (0&deg;) when the edge connector is on a level surface, and **9** (90&deg;) when it's rotated to stand on its end.
`}
</Markdown>
</LegendMessageRun>
<StepComplete
  prompt="Is your level behaving as expected?"
  xp={25}
  successMessage=
{`## Real Measurements! {centered}
### This is more than a toy program...
If my robot SATNAV unit fails, I could use this for *Celestial Navigation*!
`}
  reqImports={['microbit',]}
  reqCalls={['accelerometer.get_x', 'display.show', 'int', 'str']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class BubblePower extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Bubble Power! {lesson-title}
___
`}
<img src={LedMatrix} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:10,
    }}
/>
{`#### Now for the *real Spirit Level*
*For this, you have to get **graphical***

* Use the bottom row of LEDs, **row = 4**.
* Each LED column will be 10&deg; of tilt.
* The *CENTER column* of the LED matrix is **2**, so subtract your \`scaled\` value from that.
  * When \`scaled == 0\`, you'll set \`column = 2\`
`}
<LegendMessageKeyboard>
{`Modify your code.
> The new **column** calculation goes right after you get the **scaled** integer degrees.
\`\`\`
from microbit import *

$CENTER = 2  # Center pixel of Display

while True:
    # Get the raw value (at rest it's 0-1023)
    tilt = accelerometer.get_x()

    # Scale the value to 0-9
    scaled = (tilt / 1024) * 10

$$$
$    # Just an Integer, please
    scaled = int(scaled)

$    # Use +/- scaled value to set column.
$    # Bubble at center of display when scaled is 0.
$    column = CENTER - scaled

$    # Don't go past edges
$    if column > 4:
$        column = 4
$    elif column < 0:
$        column = 0

$    display.clear()

$    # Show bubble as an LED on row 4
$    display.set_pixel(column, 4, 9)

$$$
\`\`\`
`}
</LegendMessageKeyboard>
{`Notice that the code above has a couple of *magic numbers* in the \`set_pixel()\` function call.
* *row* is **4**, and *brightness* is **9**
* It works, but you should define *constants* to replace these!
`}
<LegendMessageRun>
{`This is it - your first *simulated bubble*.
> ***Enjoy!***
`}
</LegendMessageRun>
<StepComplete
  prompt="Does your BUBBLE have spirit?"
  xp={25}
  successMessage=
{`## Awesome! {centered}
### You should be in good spirits!
`}
  reqImports={['microbit',]}
  reqCalls={['display.set_pixel', 'display.clear', 'accelerometer.get_x', 'int']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Really, Level With Me! {lesson-title}
___
Take few minutes to play with the spirit level.
If you disconnect the USB cable and connect the battery pack, you can take the level with you anywhere you want!

Accelerometers used as *tilt sensors* are important and used every day for:
`}
<img src={SolarTrack} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
* Controlling your phone screen (landscape or portrait)
* Building a house
* Flying Airplanes
* Keeping Solar Panels pointed at the Sun
`}
<div style={{clear:'right'}}>
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Display a *special symbol* when level is at the **zero** mark.
  * Example: \`Image.TRIANGLE\`.
* Adjust the sensitivity, so you can measure **precise** levels near **0&deg;**.
  * Hint: \`scaled = (tilt / 1024) * 100\`
`}
</LegendMessageTry>
</div>
<StepComplete
  prompt="Congratulations, you’re leveling-up!"
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>

        </Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
SpiritLevel.stepId = 'SpiritLevel'
ShowTilt.stepId = 'ShowTilt'
ScaleDegrees.stepId = 'ScaleDegrees'
BubblePower.stepId = 'BubblePower'
Applications.stepId = 'Applications'

export const spiritLevel = [
  SpiritLevel,
  ShowTilt,
  ScaleDegrees,
  BubblePower,
  Applications,
]
