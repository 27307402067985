
import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'material-ui/Typography'
import Button from 'material-ui/Button'
import { moduleEnums } from '../../lessons/CurriculumModules'
import { DashboardData } from '../DataSources'


class AvailableCurriculaSelector extends React.PureComponent {
  static propTypes = {
    updateRoute: PropTypes.func.isRequired,
    data: PropTypes.instanceOf(DashboardData).isRequired,
  }

  makeCurriculumButton = (curNum) => {
    const currVal = Object.keys(moduleEnums).find(key => moduleEnums[key] === curNum)
    const displayName = this.props.data.codespace[currVal].name
    const routeLink = `curriculum-${currVal.toString()}`

    return (
      <Button
        color="secondary"
        key={curNum.toString()}
        onClick={() => this.props.updateRoute(`home/${routeLink}`)}
      >
        <img
          src={this.props.data.codespace[currVal].icon}
          alt=""
          style={{
            height: 64,
            margin: 'auto',
            display: 'block',
            float: 'left',
            marginRight: 10,
            marginLeft: 10,
          }}
        />
        {displayName}
      </Button>
    )
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', paddingTop: 16, paddingLeft: 16 }}>
          <Typography variant="subheading">
            Quick Filter
          </Typography>
        </div>
        {
          this.props.data.owner.availableCurricula.map(this.makeCurriculumButton)
        }
      </div>
    )
  }
}


export default AvailableCurriculaSelector
