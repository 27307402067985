import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import classNames from 'classnames'
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'material-ui/Dialog'
import Tooltip from 'material-ui/Tooltip'
import Button from 'material-ui/Button'
import IconButton from 'material-ui/IconButton'
import { CircularProgress } from 'material-ui/Progress'
import UndoIcon from 'material-ui-icons/Undo'
import RedoIcon from 'material-ui-icons/Redo'
import SearchIcon from 'material-ui-icons/Search'
import PlayIcon from 'material-ui-icons/PlayArrow'
import PauseIcon from 'material-ui-icons/Pause'
import StopIcon from 'material-ui-icons/Stop'
import DebugIcon from 'material-ui-icons/BugReport'
import SkipNext from 'material-ui-icons/SkipNext'
import SchoolIcon from 'material-ui-icons/School'
import CheckIcon from 'material-ui-icons/PlaylistAddCheck'
import RemoveIcon from 'material-ui-icons/DeleteSweep'
import StepOverPic from './assets/StepOver.png'
import StepIntoPic from './assets/StepInto.png'
import StepOutPic from './assets/StepOut.png'
import { editorInst } from './CodeEditor'
import debugController from './DebugController'
import mb from './mbEnum'
import Markdown from './lessons/cbl-remarkable'
import { LegendCollapsed } from './lessons/Legends'

const styles = () => ({
  image: {
    padding: 5,
    width: 18,
    height: 18,
  },
  imageHover: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
      color: 'rgb(0, 0, 0)',
    },
  },
  usbIconSpacing: {
    marginTop: 1,
  },
  iconBtn: {
    height: 'inherit',
    width: 'inherit',
    margin: '4px 1px 0px 1px',
  },
})


const ToolDivider = props => (
  <div
    style={{
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderColor: 'lightgray',
      height: '40px',
      margin: '0px 10px 0px 10px',
      ...props,
    }}
  />
)

class TbIconButtonBase extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.node,
    image: PropTypes.string,
    toggled: PropTypes.bool,
    id: PropTypes.string,
    tooltip: PropTypes.string,
    classes: PropTypes.shape(styles).isRequired,
  }

  static defaultProps = {
    icon: null,
    image: null,
    toggled: false,
    id: null,
    tooltip: null,
  }

  getImg = () => (
    this.props.image ?
      <img
        src={this.props.image}
        alt=""
        style={{
            opacity: this.props.disabled ? '.3' : '1.0',
            backgroundColor: this.props.toggled ? 'rgba(0, 0, 0, 0.12)' : null,
          }}
        className={classNames(this.props.classes.image, this.props.disabled ? null : this.props.classes.imageHover)}
      />
      :
      React.cloneElement(this.props.icon, {
        className: classNames(this.props.classes.image, this.props.disabled ? null : this.props.classes.imageHover),
      })
  )

  render() {
    return (
      <Tooltip title={this.props.tooltip}>
        <div>
          <IconButton
            id={this.props.id}
            className={this.props.classes.iconBtn}
            onClick={this.props.onClick}
            disabled={this.props.disabled}
          >
            {this.getImg()}
          </IconButton>
        </div>
      </Tooltip>
    )
  }
}

export const TbIconButton = withStyles(styles)(TbIconButtonBase)

class ToolBar extends Component {
  static propTypes = {
    onDebugClick: PropTypes.func.isRequired,
    showAdvDebug: PropTypes.bool.isRequired,
    debugging: PropTypes.bool.isRequired,
    downloading: PropTypes.bool.isRequired,
    onLessonClick: PropTypes.func.isRequired,
    showDebugPanel: PropTypes.bool.isRequired,
    showLessonPanel: PropTypes.bool.isRequired,
    usbConnected: PropTypes.bool.isRequired,
    onWebusbConnectClick: PropTypes.func.isRequired,
    classes: PropTypes.shape(styles).isRequired,
    mbState: PropTypes.number.isRequired,
    mbMode: PropTypes.number.isRequired,
    onCheckCodeClick: PropTypes.func.isRequired,
    onShowFirmwareClick: PropTypes.func.isRequired,
    onEraseDeviceClick: PropTypes.func.isRequired,
    isActivated: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showUsbDialog: false,
    }
  }

  componentDidUpdate = () => {
    if (this.props.usbConnected && this.state.showUsbDialog) {
      this.setState({
        showUsbDialog: false,
      })
      editorInst.focus()
    }
  }

  handleUndoClick = () => {
    editorInst.undo()
  }

  handleRedoClick = () => {
    editorInst.redo()
  }

  handleSearchClick = () => {
    editorInst.execCommand('find')
  }

  handleDebugClick = (event) => {
    this.props.onDebugClick(event)
  }

  handleLessonClick = (event) => {
    this.props.onLessonClick(event)
  }

  handleDebugBtnClick = (event) => {
    if (!this.props.usbConnected) {
      this.setState({
        showUsbDialog: true,
      })
      return
    }

    switch (event.currentTarget.id) {
      case 'tb-run':
        debugController.play()
        break
      case 'tb-pause':
        debugController.break()
        break
      case 'tb-stop':
        debugController.stop()
        break
      case 'tb-step-in-adv':
      case 'tb-step-in':
        debugController.stepInto()
        break
      case 'tb-step-next':
        debugController.stepNext()
        break
      case 'tb-step-out':
        debugController.stepOut()
        break
      default:
        break
    }
  }

  // Maintain focus in Editor amidst Toolbar clicks
  areaClick = () => {
    editorInst.focus()
  }

  render() {
    return (
      <div
        style={{ display: 'flex' }}
        onClick={this.areaClick}
        role="none"
      >
        <div style={{ display: 'flex', width: 40 }}>
          <CircularProgress
            style={{
            margin: 'auto',
            width: 20,
            height: 20,
            color: 'green',
            display: this.props.downloading ? 'block' : 'none',
          }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TbIconButton
            tooltip="Undo"
            icon={<UndoIcon className={this.props.classes.image} />}
            onClick={this.handleUndoClick}
            disabled={false}
          />
          <TbIconButton
            tooltip="Redo"
            icon={<RedoIcon className={this.props.classes.image} />}
            onClick={this.handleRedoClick}
            disabled={false}
          />
          <TbIconButton
            tooltip="Search"
            icon={<SearchIcon className={this.props.classes.image} />}
            onClick={this.handleSearchClick}
            disabled={false}
          />
        </div>
        <ToolDivider />
        <div style={{ display: 'flex' }}>
          <TbIconButton
            id="tb-remove-code"
            tooltip="Remove Code from Device"
            icon={<RemoveIcon className={this.props.classes.image} />}
            onClick={this.props.onEraseDeviceClick}
            disabled={this.props.downloading || this.props.mbState !== mb.state.IDLE || this.props.mbMode === mb.mode.DEBUG}
          />
          <TbIconButton
            id="tb-check-code"
            tooltip="Check Code"
            icon={<CheckIcon className={this.props.classes.image} />}
            onClick={this.props.onCheckCodeClick}
            disabled={false}
          />
        </div>
        <ToolDivider />
        <div id="tb-debug-group" style={{ display: 'flex' }}>
          <TbIconButton
            id="tb-run"
            tooltip="Run program"
            onClick={this.handleDebugBtnClick}
            icon={<PlayIcon className={this.props.classes.image} />}
            disabled={this.props.downloading || (this.props.mbState === mb.state.RUNNING)}
          />
          {/* TODO Removed until pause/break is implemented on microbit side. */}
          <TbIconButton
            id="tb-pause"
            tooltip="Pause program"
            onClick={this.handleDebugBtnClick}
            icon={<PauseIcon className={this.props.classes.image} />}
            disabled={(true || this.props.mbState === mb.state.IDLE)}
          />
          <TbIconButton
            id="tb-stop"
            tooltip="Stop program"
            onClick={this.handleDebugBtnClick}
            icon={<StopIcon className={this.props.classes.image} />}
            disabled={(this.props.mbMode !== mb.mode.DEBUG && this.props.mbState === mb.state.IDLE)}
          />
          <TbIconButton
            id="tb-step-in"
            tooltip="Step into"
            onClick={this.handleDebugBtnClick}
            icon={<SkipNext className={this.props.classes.image} />}
            handleDebugBtnClick={!this.props.debugging}
            disabled={(this.props.downloading || (this.props.mbState === mb.state.RUNNING)) || (!this.props.isActivated)}
          />
        </div>
        <ToolDivider display={this.props.showAdvDebug ? 'flex' : 'none'} />
        <div style={{ display: this.props.showAdvDebug ? 'flex' : 'none' }}>
          <TbIconButton
            id="tb-step-next"
            tooltip="Step next"
            onClick={this.handleDebugBtnClick}
            image={StepOverPic}
            disabled={
              (this.props.downloading ||
              (this.props.mbState === mb.state.RUNNING)) ||
              (this.props.mbMode === mb.mode.REPL) ||
              (!this.props.isActivated)
            }
          />
          <TbIconButton
            id="tb-step-in-adv"
            tooltip="Step into"
            onClick={this.handleDebugBtnClick}
            image={StepIntoPic}
            disabled={(this.props.downloading || (this.props.mbState === mb.state.RUNNING)) || (!this.props.isActivated)}
          />
          <TbIconButton
            id="tb-step-out"
            tooltip="Step out of current function"
            onClick={this.handleDebugBtnClick}
            image={StepOutPic}
            disabled={!this.props.debugging || (!this.props.isActivated)}
          />
        </div>
        <ToolDivider />
        <div style={{ display: 'flex' }}>
          <TbIconButton
            id="tb-toggle-debug"
            tooltip="Show/Hide Debug Panel"
            onClick={this.handleDebugClick}
            icon={
              <DebugIcon
                className={this.props.classes.image}
                style={{ backgroundColor: this.props.showDebugPanel ? 'rgba(0, 0, 0, 0.12)' : null }}
              />}
            disabled={!this.props.isActivated}
          />
          <TbIconButton
            id="tb-toggle-lesson"
            tooltip="Show/Hide Lesson Panel"
            onClick={this.handleLessonClick}
            icon={
              <SchoolIcon
                className={this.props.classes.image}
                style={{ backgroundColor: this.props.showLessonPanel ? 'rgba(0, 0, 0, 0.12)' : null }}
              />}
            disabled={false}
          />
        </div>
        <Dialog
          open={this.state.showUsbDialog}
        >
          <DialogTitle>USB Connection</DialogTitle>
          <DialogContent>
            <Markdown>
{`
1. Verify the USB cable is connected between your device and the computer.
1. After clicking "OK", a dialog from your browser should appear. Please select your device in the list and press the **connect** button.

* A **micro:bit** will be called **CodeBot Py REPL** *or* **LPC 1768**.
* A **CodeBot** will be called **CodeBot CB2** *or* **STM32F407**.
`}
<LegendCollapsed
summary="First time connecting with a non- Firia Labs micro:bit?"
summaryStyle={{fontSize:'1em', fontWeight:'normal', fontStyle:'italic', marginLeft:'8px'}}
details={
  <Markdown>
{`
If your **micro:bit** is *not* from a ***Firia Labs*** kit, and hasn't connected to *CodeSpace* before, you'll need to
<a href="https://firialabs.com/blogs/support-forum/full-upgrade-or-onboarding-a-new-micro-bit" target="_blank" rel="noopener noreferrer">update</a>
its firmware first.
`}
  </Markdown>
}
>
</LegendCollapsed>
            </Markdown>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
              this.setState({ showUsbDialog: false })
              this.props.onWebusbConnectClick()
            }}
              color="primary"
            >
            Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(ToolBar)
