import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LinearProgress } from 'material-ui/Progress'
import KeyboardArrowLeft from 'material-ui-icons/KeyboardArrowLeft'
import IconToolbox from 'material-ui-icons/BusinessCenter'
import IconReturnToLesson from 'material-ui-icons/ArrowBack'
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight'
import ExpandMore from 'material-ui-icons/ExpandMore'
import Menu, { MenuItem } from 'material-ui/Menu'
import Button from 'material-ui/Button'
import ResizeObserver from 'resize-observer-polyfill'
import CheckIcon from 'material-ui-icons/Done'
import { ListItemIcon, ListItemText } from 'material-ui/List'
import { TbIconButton } from './ToolBar'
import userProgress from './UserProgress'
import helpTools from './lessons/HelpTools'
import Markdown from './lessons/cbl-remarkable'
import ScrollIndicator from './ScrollIndicator'
import Toolbox from './Toolbox'
import tracker from "./tracking"
import {hintCategory} from './lessons/GeneralHelpHints'
import HelpHintPopover from './HelpHintPopover'
import { moduleList, moduleIndex, moduleDescription } from './lessons/CurriculumModules'

class LessonPanel extends Component {
  static propTypes = {
    showLessonPanel: PropTypes.bool.isRequired,
    windowHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    showDebugPanel: PropTypes.bool.isRequired,
    onDebugClick: PropTypes.func.isRequired,
    mbMode: PropTypes.number.isRequired,
    mbState: PropTypes.number.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
    isActivated: PropTypes.bool.isRequired,
    currentModule: PropTypes.string.isRequired,
    onShowFirmwareClick: PropTypes.func.isRequired,
    gFileId: PropTypes.string,
    onSelectCurriculum: PropTypes.func.isRequired,
    initialLicenseLoadComplete: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentProjectNum: 0,
      currentLessonNum: 0,
      openProjMenu: false,
      anchorEl: null,
      lessonModuleInst: null,
      helpElement: null,
      showToolbox: false,
      numHintsCompleted: 0,
    }
    this.restored = false
    this.restoreProjNum = 0
    this.restoreLessonNum = 0
    this.categoryScroll = null

    // A promise that resolves when Lesson progress is fully restored
    this.restoredPromise = new Promise((resolve, reject) => {
      this.resolveRestoredPromise = resolve
    }).then(() => userProgress.userDataLoaded)

    userProgress.newAccountPromise().then(() => {
      this.resolveRestoredPromise()
    }).catch(() => {
      // Nothing special for existing accounts
    })
    userProgress.registerOnChangeCallback(this.handleUserProgressChange)
  }

  componentDidMount() {
    // Update lesson content on resize events
    this.ro = new ResizeObserver(() => {
      // console.log("resize")
      this.forceUpdate()
    })
    this.ro.observe(this.content)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentModule !== this.props.currentModule) {
      //console.log("module changed: ", nextProps.currentModule)
      this.setState({
        currentProjectNum: 0,
        currentLessonNum: 0,
      })

      if (this.restored) {
        this.restoreProgress(nextProps.currentModule)
      }
    }
  }

  restoreProgress = (restoreModule) => {
    this.restored = true

    const newModule = restoreModule || this.props.currentModule
    const progress = userProgress.getModuleData(newModule)
    //console.log(`Lessons: restoreProgress for module ${newModule}`, progress)

    if (progress) {
      this.restoreProjNum = progress.lastProject || 0
      this.restoreLessonNum = progress.lastLesson || 0

      // In case we've reloaded and are restoring progress into the currently rendered module, apply settings now.
      if (userProgress.lastModule === newModule && this.state.lessonModuleInst) {
        this.applyRestoredProjectSettings(this.state.lessonModuleInst)
      }
      // Else if we're _changing_ modules the applyRestoredProjectSettings() will be called on render from getLessonRef()
    }
    this.resolveRestoredPromise()
  }

  handleUserProgressChange = (userProgressObj, isUserData) => {
    if (!isUserData) {
      if (!this.restored) {
        this.restoreProgress()
      }

      // Update state tied to progress
      this.setState({
        numHintsCompleted: userProgress.numberHintsCompleted(),
      })

      // TODO: Update remaining userProgress related state here, rather than calling-out from render()
      // e.g. Completed steps and projects
    }
  }

  constrainRestoredProjectIndices = (lessonModuleInst) => {
    // Handle case where we DELETE projects/steps, and try to restore past end of content!!
    if (lessonModuleInst &&
        (this.restoreProjNum >= lessonModuleInst.numProjects() ||
        this.restoreLessonNum >= lessonModuleInst.numLessons(this.restoreProjNum))) {

      //console.log("Lessons: resetting restored progress to beginning")
      return ({currentProjectNum: 0, currentLessonNum: 0})
    }

    return ({currentProjectNum: this.restoreProjNum, currentLessonNum: this.restoreLessonNum})
  }

  applyRestoredProjectSettings = (lessonModuleInst) => {
    //console.log("Lessons: applyRestoredProjectSettings", {currentProjectNum: this.restoreProjNum,currentLessonNum: this.restoreLessonNum})
    const settings = this.constrainRestoredProjectIndices(lessonModuleInst)
    this.setState(settings)
  }

  getLessonRef = (inst) => {
    // Called from render() to capture reference to current <Module> instance
    this.setState({
      lessonModuleInst: inst
    })

    // Now that we have a Module instance, we can restore user progress we've received from DB.
    this.applyRestoredProjectSettings(inst)
  }

  curNumLessons = () => this.state.lessonModuleInst.numLessons(this.state.currentProjectNum)

  onNavLeft = () => {
    const prev = this.prevStep()
    if (prev) {
      this.setState({ currentProjectNum: prev.project, currentLessonNum: prev.lesson })
      this.lessonScrollIndicator.scrollTop()
      userProgress.onLessonChange(this.props.currentModule, prev.lesson, prev.project)
    }
  }

  onNavRight = () => {
    const next = this.nextStep()
    if (next) {
      userProgress.onLessonChange(this.props.currentModule, next.lesson, next.project)
      this.setState({ currentProjectNum: next.project, currentLessonNum: next.lesson })
      this.lessonScrollIndicator.scrollTop()
    }
  }

  onSelectCurriculum = () => {
    this.props.onSelectCurriculum()
  }

  getXp = () => userProgress.xp

  hasTourRun = () => userProgress.tourHasRun
  testSetTourRun = async () => {
    const autoTourJustRan = await userProgress.testSetTourRun()
    return autoTourJustRan
  }

  nextStep = () => {
    let step = {
      project: this.state.currentProjectNum,
      lesson: 0,
    }

    if (this.state.currentLessonNum < this.curNumLessons() - 1) {
      step.lesson = this.state.currentLessonNum + 1
    } else if (this.state.currentProjectNum < this.state.lessonModuleInst.numProjects() - 1) {
      step.project = this.state.currentProjectNum + 1
    } else {
      step = null
    }

    return step
  }

  prevStep = () => {
    let step = {
      project: this.state.currentProjectNum,
      lesson: 0,
    }

    if (this.state.currentLessonNum > 0) {
      step.lesson = this.state.currentLessonNum - 1
    } else if (this.state.currentProjectNum > 0) {
      step.project = this.state.currentProjectNum - 1
      step.lesson = this.state.lessonModuleInst.numLessons(step.project) - 1
    } else {
      step = null
    }

    return step
  }

  handleClickProject = (event) => {
    this.setState({ openProjMenu: true, anchorEl: event.currentTarget })
  }

  handleRequestProjMenuClose = () => {
    this.setState({ openProjMenu: false })
  }

  handleProjMenuItemClick = (event, index) => {
    this.setState({ currentProjectNum: index, currentLessonNum: 0, openProjMenu: false })
    userProgress.onLessonChange(this.props.currentModule, 0, index)
    this.lessonScrollIndicator.scrollTop()
  }

  handleToolboxMenuItemClick = (event) => {
    this.categoryScroll = event.target.innerHTML
    this.setState({ openProjMenu: false })
  }

  handleProjMenuExited = () => {
    if (this.categoryScroll) {
      if (this.toolboxRef) {
        this.toolboxRef.scrollToCategory(this.categoryScroll)
      }
      this.categoryScroll = null
    }
  }

  emphasizeToolbox = () => {
    // Restart animation to "emphasize" each change
    const id = document.getElementById('toolbox-button')
    if (id) {
      id.style.removeProperty('animation-name')
      setTimeout(() => id.style.setProperty('animation-name', 'emphasize-animation-shake'), 50)
    }
  }

  lessonContentClick = (ev) => {
    // User clicked in lesson content - display help/hint if available
    // Currently we're just using the <mark> tag for this - may add custom CSS classes later,
    // and potentially style (in componentDidUpdate func) based on help being available.
    if (ev.target && ev.target.parentElement && (ev.target.tagName === 'MARK' || ev.target.parentElement.tagName === 'MARK')) {
      // console.log("Display help for ", ev.target.innerText)
      const helpObj = helpTools.getHelp(ev.target.innerText)
      if (helpObj) {
        const helpElement = {
          content: helpObj.hint,
          name: helpObj.name,
          desc: helpObj.desc,
          ref: ev.target,
        }
        this.setState({ helpElement })

        const newTool = userProgress.completedHint(helpObj.name, 5)  // Currently fixed at +5XP
        if (newTool) {
          this.emphasizeToolbox()
        }
      } else {
        tracker.addBreadcrumb(`Failed lookup on content term: ${ev.target.innerText}`)
      }
    }
  }

  stepCompleteIcon = () => {
    if (!this.state.lessonModuleInst) {
      return null
    }
    const projectName = this.state.lessonModuleInst.projectNames[this.state.currentProjectNum]
    const stepName = this.state.lessonModuleInst.lessonName(this.state.currentProjectNum, this.state.currentLessonNum)
    if (userProgress.isStepComplete(this.props.currentModule, projectName, stepName)) {
      return (
        <ListItemIcon>
          <CheckIcon
            style={{fill:'green', width:20}}
          />
        </ListItemIcon>
      )
    } else {
      return null
    }
  }

  menuItemIcon = (projectIndex) => {
    if (!this.state.lessonModuleInst) {
      return null
    }
    const projectName = this.state.lessonModuleInst.projectNames[projectIndex]
    const stepList = this.state.lessonModuleInst.lessonNames(projectIndex)
    if (stepList.includes(undefined)) {
      console.error(`Missing stepId in project ${projectName}`, stepList)
    }
    if (userProgress.isProjectComplete(this.props.currentModule, projectName, stepList)) {
      return (
        <ListItemIcon>
          <CheckIcon
            style={{fill:'green'}}
          />
        </ListItemIcon>
      )
    } else {
      return null
    }
  }

  completedCurrentStep = (xpCredit) => {
    const projectName = this.state.lessonModuleInst.projectNames[this.state.currentProjectNum]
    const stepName = this.state.lessonModuleInst.lessonName(this.state.currentProjectNum, this.state.currentLessonNum)
    userProgress.completedStep(this.props.currentModule, projectName, stepName, xpCredit)
  }

  isQuizComplete = (quizName) => {
    return userProgress.isQuizComplete(this.props.currentModule, quizName)
  }

  completedQuiz = (quizName, xp) => {
    userProgress.completedQuiz(this.props.currentModule, quizName, xp)
  }

  getCurrentLessonQuizNames = () => {
    let names = []
    let findQuizName = (children) => {
      children.forEach(child => {
        if (child.getAttribute('quizname')) {
          names.push(child.getAttribute('quizname'))
        }
        if (child.children) {
          findQuizName(Array.from(child.children))
        }
      })
    }
    findQuizName(Array.from(this.content.children))
    return names
  }

  curNumLessons = () => this.state.lessonModuleInst.numLessons(this.state.currentProjectNum)

  toggleToolbox = () => {
    this.setState({
      showToolbox: !this.state.showToolbox,
      openProjMenu: false
    })
    this.restoreProjNum = this.state.currentProjectNum || 0
    this.restoreLessonNum = this.state.currentLessonNum || 0
  }

  saveToolboxRef = (el) => {
    this.toolboxRef = el
  }

  render() {
    const CurrentModule = moduleList[this.props.currentModule]
    const currentModuleIndex = moduleIndex.get(this.props.currentModule)
    const currentModuleDescriptor = currentModuleIndex === undefined ? null : moduleDescription[currentModuleIndex]

    let pageContent = null
    let menuContent = null

    if (this.state.showToolbox) {
      pageContent = <Toolbox onRef={this.saveToolboxRef}/>
      menuContent = []
      for (let key in hintCategory) {
        menuContent.push(
          <MenuItem
            key={key}
            onClick={event => this.handleToolboxMenuItemClick(event)}
          >
            <ListItemText inset primary = {hintCategory[key]} />
          </MenuItem>
        )
      }
    } else if (!this.props.initialLicenseLoadComplete) {
      pageContent = <div style={{width:'80%', margin:'5%'}}>
        Loading curriculum...
        <LinearProgress  color="secondary" variant="query" />
      </div>
    } else {
      pageContent = <CurrentModule
        showProjectNum={this.state.currentProjectNum}
        showLessonNum={this.state.currentLessonNum}
        lessons={this}
        ref2={this.getLessonRef}
        showDebugPanel={this.props.showDebugPanel}
        onDebugClick={this.props.onDebugClick}
        interceptErrorCb={this.props.interceptErrorCb}
        gFileId={this.props.gFileId}
      />
      menuContent = this.state.lessonModuleInst ? this.state.lessonModuleInst.projectNames.map((name, index) => (
        <MenuItem
          key={name}
          selected={index === this.state.currentProjectNum}
          onClick={event => this.handleProjMenuItemClick(event, index)}
        >
          {this.menuItemIcon(index)}
          <ListItemText inset primary = {`${index + 1} - ${name}`} />
        </MenuItem>
      ))
      : ''
    }

    return (
      <div
        style={{
          display: this.props.showLessonPanel ? 'flex' : 'none',
          height: '100%',
          width: '100%',
          backgroundColor: '#EEEEEE',
          // Looking for something other than WHITE... getting a little crazy with the gradientz :-)
          // background: 'radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(200,200,200,1) 100%)',
          flexDirection: 'column',
          userSelect: 'none',
        }}
      >

        <div
          style={{
            height: 35,
            borderColor: 'lightgray',
            borderWidth: 1,
            borderTopStyle: 'solid',
            borderBottomStyle: 'solid',
            borderTopWidth: 1,
            backgroundColor: '#FAFAFA',
          }}
        >

          {/* Nav-bar */}
          <div
            style={{
              display: 'flex',
              height: 25,
            }}
          >
            {/* Left side icons */}
            <div style={{display:'flex', marginRight:'auto'}}>

              {/* Back button */}
              <div
                id="lesson-back"
                style={{
                  visibility: this.state.lessonModuleInst ? 'visible' : 'hidden',
                  marginRight:10,
                }}
              >
                <TbIconButton
                  tooltip="Previous"
                  icon={<KeyboardArrowLeft />}
                  onClick={this.onNavLeft}
                  disabled={!this.state.lessonModuleInst || this.prevStep() === null}
                />
              </div>

              {/* Curriculum Selector button */}
              <div
                style={{
                  width:0  // prevents right margin pushing ProjectList off center
                }}
              >
                <Button
                  onClick={this.onSelectCurriculum}
                  style={{
                    margin: '2px 10px',
                    padding: '0px',
                    fontWeight: 'normal',
                    fontSize: '.5em',
                    height: '20px',
                    width:'100px',
                    textTransform:'none',
                  }}
                  size='small'
                  id='select-curriculum'
                >
                  <React.Fragment>
                    <img src={currentModuleDescriptor.icon} alt=""
                      style={{
                        height:24, margin: 'auto', display:'block', float:'left', marginRight:4, marginLeft: 4
                      }}
                    />
                    <span style={{marginRight:'4px'}}>{currentModuleDescriptor.name}</span>
                  </React.Fragment>
                </Button>

              </div>
            </div>

            {/* Project List */}
            <div
              id="project-list"
              style={{ marginRight: 'auto', whiteSpace: 'nowrap' }}
            >
              <Button
                aria-owns={this.state.open ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClickProject}
              >
                {this.stepCompleteIcon()}
                {this.state.showToolbox ? 'My Toolbox' : (this.state.lessonModuleInst ? `${this.state.currentProjectNum + 1}.${this.state.currentLessonNum + 1} ${this.state.lessonModuleInst.projectNames[this.state.currentProjectNum]}`
                 : '...')}
                <ExpandMore style={{marginLeft:10, marginRight:-5, marginTop:-2, color:'slategray'}} />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.openProjMenu}
                onClose={this.handleRequestProjMenuClose}
                onExited={this.handleProjMenuExited}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,  // Scroll if content exceeds (Height * nLines)
                  },
                }}
              >
                {menuContent}
              </Menu>
            </div>

            {/* Right side icons */}
            <div style={{display:'flex'}}>

              {/* Toolbox button */}
              <div
                id="toolbox-button"
                style={{
                  marginRight: '0.5em',
                  animationName: 'emphasize-animation-shake',
                  animationDuration: '1s',
                  animationIterationCount: '1',
                }}
              >
                <TbIconButton
                  tooltip={this.state.showToolbox ? "Return to Lesson" : "Toolbox"}
                  icon={this.state.showToolbox ? <IconReturnToLesson /> : <IconToolbox />}
                  onClick={this.toggleToolbox}
                  disabled={false}
                />
                <div
                  style={{
                    position: 'relative',
                    bottom: 12,
                    left: 20,
                    fontSize: '6pt',
                    color: 'rgb(52, 125, 51)',
                    fontWeight: 'bold',
                    pointerEvents: 'none',
                    padding: '0.2em',
                    visibility: this.state.lessonModuleInst ? 'visible' : 'hidden',
                  }}
                >
                  {this.state.numHintsCompleted}
                </div>
              </div>

              {/* Forward button */}
              <div
                id="lesson-fwd"
                style={{
                  visibility: this.state.lessonModuleInst ? 'visible' : 'hidden',
                }}
              >
                <TbIconButton
                  tooltip="Advance"
                  icon={<KeyboardArrowRight />}
                  onClick={this.onNavRight}
                  disabled={!this.state.lessonModuleInst || this.nextStep() === null}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Progress bar */}
        <div
          id="lesson-progress"
          style={{
            height: 10,
            marginBottom: 2,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={this.state.lessonModuleInst ? (this.state.currentLessonNum / (this.curNumLessons() - 1)) * 100 : 0}
          />
        </div>

        {/* Lesson Content */}
        <div
          id='lesson-content'
          ref={(elem => {this.content = elem})}
          onClick={this.lessonContentClick}
          role="none"
          style={{
            flex: 1,
            minHeight: '50px',
            width: '100%',
          }}
        >
          <ScrollIndicator
            ref={(component) => {this.lessonScrollIndicator = component}}
            keepScrollBar={this.state.showToolbox}
          >
            <div
              style={{
                padding: '0 5px 10px 5px',
              }}
            >
              {pageContent}
            </div>
          </ScrollIndicator>
        </div>


        {/* Help / Hint Context Popover */}
        <HelpHintPopover
          open={Boolean(this.state.helpElement)}
          anchorEl={this.state.helpElement && this.state.helpElement.ref}
          onClose={() => { this.setState({ helpElement: null }) }}
          onClick={this.lessonContentClick} // allow links to other hints
          maxWidth={this.props.windowWidth / 2}
          maxHeight={this.props.windowHeight * 0.75}
          hintName={this.state.helpElement ? this.state.helpElement.name : null}
        >
          {this.state.helpElement ? (<div>
            <Markdown>{
`### ${this.state.helpElement.name}
${this.state.helpElement.desc ? `#### ${this.state.helpElement.desc}` : ''}`
            }</Markdown>
            {this.state.helpElement.content}
            </div>
          ) : <div></div>}
        </HelpHintPopover>

      </div>
    )
  }
}

export default LessonPanel
