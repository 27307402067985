// Project: Alarm

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  //LegendMessageWarning,
  LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'
import HomeSecurity from './assets/HomeSecurity.jpg'
import Annunciator from './assets/Annunciator.jpg'
import SpeakerConnectPic from './assets/speaker-conn.jpg'
import SpeakerClipsPic from './assets/speaker-clips.png'
import MicrobitUnplugged from './assets/microbit-unplugged.gif'
import TripWire from './assets/TripWire.jpg'
import MagneticSwitch from './assets/MagneticSwitch.gif'
import SpeakerCutaway from './assets/SpeakerCutawayCyl.png'
import Surveillance from './assets/Surveillance.jpg'

export const AlarmImg = HomeSecurity

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Alarm System {lesson-title}
___
`}
<img src={AlarmImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`### Secure your stuff!
Can you build a ***capable security system*** with some micro:bits and **code**?

***Yes, you can!*** {centered}

#### Security System Building Blocks:
* **Sensors:**
  * You have already learned about a few *sensor* types that'll be useful for **alarm detection**, like the ==accelerometer==.
  * *There are more **sensors** in store for you in this project!*
* **Annunciators:**
  * ***Sirens*** and ***Flashing Lights*** - you have *speakers* and *LEDs* to make that happen!
`}
<div style={{clear:'left'}} />
{`
#### Project Goals:
* Make an **Alarm Annunciator**
  * Sound an alarm on the *speaker*.
  * Display the **cause** of the *alarm* on the ==Display==.
  * ***Make it Wireless***, *naturally!*
* Make a **Trip Wire** detector to fence-in an area.
* Make a **Magnetic Switch** detector to protect drawers, doors, and windows.
* Make a **Motion Detector** sensitive enough to trigger on the *slightest movement*.
`}
</Markdown>
        <StepComplete
          prompt="Press Next and Be Alarmed..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class Alarm1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Alarm Annunciator {lesson-title}
___
`}
<img src={Annunciator} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`First you'll build the ***Lights and Sound*** part of the *Alarm System*.
* In professional security systems, this is called the **Annunciator**.

Now it's time to connect the **speaker**:
`}
<LegendMessageInteract title="Connect Wires">
{`Use two **alligator clip** wires from your *kit* to connect the **speaker** to the micro:bit as shown below.

**Be sure to connect *only* to the \`0\` and \`GND\` metal pads!**
`}
</LegendMessageInteract>
<img src={SpeakerClipsPic} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float:'left'
    }}
/>
<br />
<img src={SpeakerConnectPic} alt=""
    style={{
      width: '60%', margin: 'auto', display:'block', float:'right'
    }}
/>
<div style={{clear:'left'}} />
{`#### Your code should do the following:
* **BUTTON A** &rarr; ***Reset** stops the Alarm, and stays armed and ready for the next one.*
* **BUTTON B** &rarr; ***Test** tests the Alarm.*
`}
<LegendMessageKeyboard>
{`Create a new File and name it **Annunciator**.

You'll need a ==loop== that checks ==buttons==.
* That's something you've done a few times now. Try it on your own!

Define *two* functions: \`start_alarm()\` and \`stop_alarm()\`.
* Make sure the Alarm ***keeps going*** until it is **Reset**.
* Notice how the \`display.scroll()\` and \`music.play()\` both have \`wait=False, loop=True\` so they go on **forever**... until you *reset* them.
* Check out the \`stop_alarm()\` function to see how the *reset* is achieved.
\`\`\`
# Alarm Annunciator
from microbit import *
import music

def start_alarm(cause):
    # Keep playing and displaying until told to stop!
    music.play(music.POWER_UP, wait=False, loop=True)
    display.scroll(cause, wait=False, loop=True)

def stop_alarm():
    # Reset the music and display.
    music.stop()
    display.clear()

while True:
    # Check buttons: A=RESET, B=TEST
    if button_a.was_pressed():
        stop_alarm()
    elif button_b.was_pressed():
        start_alarm("Test")
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Run a few tests of your **Annunciator**. Make sure you can ***start*** and ***stop*** the Alarm!
* Feel free to try some different sounds if this is not "alarming" enough for you :-)
`}
</LegendMessageRun>
<StepComplete
  prompt="Is your Annunciator announcing alarmingly?"
  xp={25}
  successMessage=
{`## Sound off! {centered}
You sure have **my** attention.
`}
  reqImports={['microbit', 'music']}
  reqCalls={['display.scroll', 'music.play', 'display.clear', 'music.stop', 'button_a.was_pressed',
             'button_b.was_pressed', 'stop_alarm', 'start_alarm']}
  reqFuncdefs={['start_alarm', 'stop_alarm']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Alarm2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Remote Alarm Detection {lesson-title}
___
You're going to be making a few different **Alarm Detectors**.
These **remote sensors** will use the ==Radio== to signal **alarm** to your Annunciator.

#### In *this* step you add *radio receive* code to your *Annunciator*.
  * Then it will be ready to *listen for remote alarms!*

#### Radio Message Protocol:
You wouldn't want *just any* received message to trigger an alarm. Here's a simple *protocol* you can use:
* Alarm **detector** sends the ==string== **"ALARM"** followed by a ==string== indicating the **cause** of the alarm.
`}
<LegendMessageKeyboard>
{`First, import the ==radio== module.
* Initialize the *radio* as usual, like you did in the ***Radio Messenger*** project.

Define a new function \`check_alarm()\` that *decodes* the *Radio Message Protocol* described above.
* See if the first 5 characters of **msg** are **"ALARM"**.
* If so, take everything from the 6th character on as the \`cause\` string for this **alarm detect** event.

The \`while loop\` should still check *buttons*
* But also call \`radio.receive()\` and use \`check_alarm()\` on messages.
* And if an *alarm* is detected, call \`start_alarm()\`!
\`\`\`
# Alarm Annunciator - wireless
from microbit import *
import music
$import radio
$radio.on()
$radio.config(channel=16)  # Channel can be 0-83

def start_alarm(cause):
    music.play(music.POWER_UP, wait=False, loop=True)
    display.scroll(cause, wait=False, loop=True)

def stop_alarm():
    music.stop()
    display.clear()

$def check_alarm(msg):
$    cause = ''
$    if msg[:5] == 'ALARM':
$        cause = msg[5:]
$    return cause

while True:
    # Check buttons: A=RESET, B=TEST
    if button_a.was_pressed():
        stop_alarm()
    elif button_b.was_pressed():
        start_alarm("Test")

$    # Check radio for ALARM message
$    msg = radio.receive()
$    if msg:
$        cause = check_alarm(msg)
$        if cause:
$            start_alarm(cause)

\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Your **Annunciator** should run just as it did before.
* Try the **Reset** and **Test** buttons (**A** and **B**) to make sure they still work.
* You'll need to wait until the next step to test the ==Radio== functions!
`}
</LegendMessageRun>
<img src={MicrobitUnplugged} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginTop: 10,
      transform: 'rotate(-20deg)'
    }}
/>
<LegendMessageInteract>
{`Go ahead and **UN-PLUG** so you can be ready for the next step.
* You'll need a *second micro:bit* for the next step.
`}
</LegendMessageInteract>
<StepComplete
  prompt="Ready for remote detection?"
  xp={25}
  successMessage=
{`## All set for remote detection! {centered}
Can't wait to see some *real* alarm detection...
`}
reqImports={['microbit', 'music', 'radio']}
reqCalls={['display.scroll', 'music.play', 'display.clear', 'music.stop', 'button_a.was_pressed',
           'button_b.was_pressed', 'stop_alarm', 'start_alarm', 'radio.on', 'radio.receive', 'check_alarm']}
reqFuncdefs={['start_alarm', 'stop_alarm', 'check_alarm']}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Alarm3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Trip Wire {lesson-title}
___
`}
<img src={TripWire} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`The first **Detector** you'll build is a **Trip Wire**.
* This is actually a simple electric **switch**.
* When the *connection is broken*, the **alarm** goes off!

As shown in the picture here, you simply use one **alligator clip wire** to connect from \`0\` to \`GND\` on the
micro:bit's ==Edge Connector==.
* You can use more alligator clips connected end-to-end if you want!
* Or attach a *string* and set your *security perimeter fence!*

The **main ==loop==** of your function will call \`pin0.read_digital()\` continuously, and **Alarm** if its value becomes '1'.
`}
<div style={{clear:'right'}} />
<LegendMessageKeyboard>
{`Create a new File and name it **TripWire**.

Initialize \`pin0\` to be a digital ==pin== and use \`set_pull(0)\` so it is *pulled up* to '1' when disconnected.

Define a function \`def alarm_detected(cause):\` that:
* encodes the **"ALARM" + cause** message, and
* \`radio.send()\`s it over the air!
\`\`\`
# Alarm detector - trip wire
from microbit import *
import radio
radio.on()
radio.config(channel=16)  # Channel can be 0-83

# Initialize pin0
pin0.read_digital()
pin0.set_pull(0)    # Pull pin "up" to binary '1' when disconnected

def alarm_detected(cause):
    msg = "ALARM" + cause
    radio.send(msg)
    display.scroll(cause)

while True:
    if pin0.read_digital():
        alarm_detected("Fence")
        sleep(1000)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Load this code into a micro:bit.
* You should see it display the detected alarm on the ==LED== display.
* **AND** it should *trigger* your ***Remote Annunciator*** loaded on the other micro:bit!
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Shouldn't the code above be \`if pin0.read_digital() == 1:\`?'
`}
  </Markdown>
  )}
  id={"Implicit boolean versus integer comparison"}
  xp={5}
  answerRight={"That would work also! As written it works since '1' and '0' become True and False when converted to boolean."}
  answerWrong={["No, the 'read_digital()' function returns a boolean True or False."]}
/>
<StepComplete
  prompt="Got your trip-wire all set?"
  xp={25}
  successMessage=
{`## Trippy! {centered}

`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'pin0.read_digital', 'pin0.set_pull', 'alarm_detected', 'sleep',
           'radio.on', 'radio.send',]}
reqFuncdefs={['alarm_detected']}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Alarm4 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Magnetic Switch Detector {lesson-title}
___
A common *sensor* found in *Alarm Systems* is a **Magnetic Switch**.
`}
<img src={MagneticSwitch} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`
* These devices are often used to detect when **doors** or **windows** are opened, but can also be used for **drawers**, **cabinets**, etc.
* One side contains a **magnet**, and the other side (with wires attached) is a **switch** that closes when the magnet is nearby.

#### But can the micro:bit detect a *magnet*?
Glad you asked!

**Yes, it *can!*** {centered}
`}
<LegendMessageConcept title="Concept: Magnetometer">
{`The micro:bit contains a chip that can detect *magnetic fields* produced by **magnets** or even by the **Earth's *geomagnetic field***.

Because it can be used as a ==compass==, the *magnetometer functions* are in the \`compass\` object.
\`\`\`
# Get the magnetic field strength
compass.get_field_strength()
\`\`\`
`}
</LegendMessageConcept>
<div style={{clear:'right'}} />
{`The **field strength** will be a very large ==integer==, so it will need to be scaled down for a *realistic* **sensitivity threshold** check:
* Dividing the *field* by *100,000* should give a number close to *zero* unless a strong *magnet* is nearby.
\`\`\`
# Read the magnetic field, and scale down
magnet = compass.get_field_strength() / 100000
if magnet > 5:
    # A magnet is very near!!
\`\`\`
`}
<LegendMessageKeyboard>
{`**Copy** your code to a new File and name it **MagnetSwitch**.
* ***Tip:*** Use the **File&rarr;Make a Copy** menu option to **copy** your current program.

This code will be very similar to your **TripWire** code. Even easier, really!
* No *pin initialization* is needed.
* Just call \`compass.get_field_strength()\` and compare to your ***sensitivity*** *threshold*.
  * The value **5** works pretty well if you're using the suggested *magnet* - see below!
\`\`\`
# Alarm detector - magnetic switch
from microbit import *
import radio
radio.on()
radio.config(channel=16)  # Channel can be 0-83

def alarm_detected(cause):
    msg = "ALARM" + cause
    radio.send(msg)
    display.scroll(cause)

$$$
$while True:
$    # Read the magnetic field, and scale down
$    magnet = compass.get_field_strength() / 100000
$
$    # Larger number when magnet is near micro:bit
$    # Change to '<' to alarm when door is open.
$    if magnet > 5:
$        alarm_detected("Door")
$        sleep(1000)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<img src={SpeakerCutaway} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### So, what are you going to use for a *magnet*?
* Did you know there's one right in your **FiriaLabs Kit**?

***SPEEKR HAZ MAGNIT*** {centered}

Take a look at the *cutaway* diagram of the **speaker**. Electric current flows through the coil, creating a *magnetic field*
that pushes against the **magnet**, moving the *diaphram* and making *sound waves* in the air!
* So indeed, the **speaker** has a powerful **magnet** you can use to test your **magnetic switch**.
`}
<LegendMessageRun>
{`Load this code into a micro:bit.
* Take an unconnected **speaker** and move it close to the microbit.
  * Near **Button-B** is the side the ==magnetometer== is on.
* You should the detected alarm on the ==LED== display.
* **AND** it should *trigger* your ***Remote Annunciator*** loaded on the other micro:bit!
`}
</LegendMessageRun>
<StepComplete
  prompt="Is your code detecting the magnetic field?"
  xp={25}
  successMessage=
{`## Magnificent Magnetics! {centered}
Oh, and... sorry for the kitteh speak.
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'compass.get_field_strength', 'alarm_detected', 'sleep',
           'radio.on', 'radio.send',]}
reqFuncdefs={['alarm_detected']}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Alarm5 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Motion Detector {lesson-title}
___
For detecting any sort of **disturbance**, nothing is as *versatile* as a **motion detector**.

You will use the ==accelerometer== to detect when the micro:bit's orientation changes by more than
a preset threshold of *SENSITIVITY*.

#### Detecting Motion
* Capture a **before** value of x,y,z orientation from the accelerometer.
* In your main ==loop==, get the **now** value of orientation for x,y,z.
* Check if **now** has moved from **before** by more than the *SENSITIVITY* threshold.
  * If so, ***Alarm!***
`}
<LegendMessageKeyboard>
{`**Copy** your code to a new File and name it **MotionDetector**.

The basic structure of the code is like the previous *detector* devices.

Define a function \`def check_motion(now, before):\` that will check if the difference between
**now** and **before** is more than the *SENSITIVITY* threshold.
\`\`\`
# Alarm detector - motion detector
from microbit import *
import radio
radio.on()
radio.config(channel=16)  # Channel can be 0-83

def alarm_detected(cause):
    msg = "ALARM" + cause
    radio.send(msg)
    display.scroll(cause)

$$$
$SENSITIVITY = 100
$
$def check_motion(now, before):
$    diff = abs(now - before)
$    if diff > SENSITIVITY:
$        alarm_detected("Motion")
$
$x_before = accelerometer.get_x()
$y_before = accelerometer.get_y()
$z_before = accelerometer.get_z()
$
$while True:
$    x_now = accelerometer.get_x()
$    y_now = accelerometer.get_y()
$    z_now = accelerometer.get_z()
$    check_motion(x_now, x_before)
$    check_motion(y_now, y_before)
$    check_motion(z_now, z_before)
$    x_before = x_now
$    y_before = y_now
$    z_before = z_now
$    sleep(100)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Give your **motion detector** a try.
* Test out some different **SENSITIVITY** values: **LOWER** is ***more*** *sensitive*.
* It's *amazing* how *sensitive* it can be! Your table vibrates more than you think it does...
`}
</LegendMessageRun>
<Quiz
        lessons={this.props.lessons}
        prompt={(
          <Markdown>
          {`What is the purpose of the \`abs()\` function inside \`check_motion()\` above?
          `}
          </Markdown>
        )}
        id={"Absolute value built-in"}
        xp={5}
        answerRight={"Gets the absolute value of (now-before), so diff is always positive."}
        answerWrong={["Shorthand for 'abdominals', it works the core muscular values with positive tension.",
                      "The abs() function changes addition to subtraction operation every other time."]}
/>
<Quiz
        lessons={this.props.lessons}
        prompt={(
          <Markdown>
          {`How **often** does the *Motion Detector* code check for x/y/z movement?
          `}
          </Markdown>
        )}
        id={"Loop delays"}
        xp={5}
        answerRight={"Every 100ms, which is 10 times per second."}
        answerWrong={["Once per second.",
                      "Continuously, with no delays at all."]}
/>
<StepComplete
  prompt="Are you triggering on the slightest motion?"
  xp={25}
  successMessage=
{`## Can't Touch This! {centered}
**Nice *sensing!***
You can guard my secret hideout any day!
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'accelerometer.get_x', 'accelerometer.get_y', 'accelerometer.get_z', 'alarm_detected', 'sleep', 'check_motion',
           'radio.on', 'radio.send', 'abs']}
reqFuncdefs={['alarm_detected', 'check_motion']}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <div>
        <Markdown>
          {
`## You're Running a Tight Ship! {lesson-title}
___
`}
        <img src={Surveillance} alt=""
          style={{
            width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft:10
          }}
        />
{`
### Nice *coding* - bringing all those *sensors* together!
* One of the *best* things about **Coding** is that programs often function in ***systems***!
* Often the different parts of a *system* are coded by ***different people***.
* It is *challenging* and ***fun*** to work with other people to design *interfaces* between all the parts.

> And when it all comes together and **works** *(like your **alarm system** did)* it's an awesome feeling!
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* **Photocell** *detector*: Trigger an **alarm** if the *light level* changes from **dark** to **light**.
  * ***Ex:*** Alarm when **mailbox** is opened... or the **fridge**!
* Have you tried **multiple remote sensors** at a time?
  * What happens if several are *detecting* at the same time?
  * Can you make the ***Annunciator*** save and *display* **all** the *causes*?
`}
</LegendMessageTry>
        </Markdown>
        <StepComplete
          prompt="All safe and sound! The adventure continues..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

Prelude.stepId = 'Prelude'
Alarm1.stepId = 'Alarm1'
Alarm2.stepId = 'Alarm2'
Alarm3.stepId = 'Alarm3'
Alarm4.stepId = 'Alarm4'
Alarm5.stepId = 'Alarm5'
Finale.stepId = 'Finale'

export const alarm = [
  Prelude,
  Alarm1,
  Alarm2,
  Alarm3,
  Alarm4,
  Alarm5,
  Finale,
]
