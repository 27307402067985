// Project: TempSensor

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageInteract,
  //LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'
import TempSensor from './assets/TempDisplay.gif'
import ThermistorConnectPic from './assets/thermistor-conn.jpg'
import ThermistorClipsPic from './assets/pullup-clips.png'
import WeatherStation from './assets/WeatherStation.jpg'
import TempGraph from './assets/TempGraph.jpg'
import TempCal32 from './assets/TempCal32.jpg'
import TempCal72 from './assets/TempCal72.jpg'
import RelaxMath from './assets/RelaxMath.gif'
import Fridge from './assets/Fridge.jpg'
import MicrobitBatt from './assets/microbit-batt.gif'

export const TempSensorImg = TempSensor

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Temperature Sensor {lesson-title}
___
`}
<img src={TempSensor} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### In this project you'll code a *Temperature Sensor*.
This **exact** type of sensor is used in a lot of places:
* Thermostats that you see on the walls of homes, offices, schools, etc.
* Digital thermometers
* Medical applications
* Food storage and cooking
* Machine monitoring - computers, cars, etc.
* Weather Stations
* ...many more! **Thermistors** are one of the most prevalent *sensor* types in the world.

#### Project Goals:
* Connect a **thermistor** to your micro:bit so you it can measure *thermal energy*.
* Display raw ==ADC== values for temperature.
  * Calibrate your temperature sensor with *real temperature measurements*.
* Build a real **thermometer**!
  * Use your calibration measurements to calculate *actual* temperature in Fahrenheit or Celsius.
* Make your *thermometer* **remote** with the ==radio==.
`}
</Markdown>
        <StepComplete
          prompt="Press Next to get THERMAL..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class ConnectThermistor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <div>
<Markdown>
{
`## Connect the Thermistor {lesson-title}
___
#### Your thermistor operates a lot like the **photocell** you've already seen

Both these **sensor** types are a kind of variable **resistor**
* A **resistor** is an component that *resists* the flow of electric current.
* The **photocell** resistance *decreases* as the **light gets brighter**.
* The **thermistor** resistance *decreases* as the **temperature gets warmer**.

Connected as shown below, this means the micro:bit ==ADC== reading will ***increase* as the temperature rises**.
`}
<LegendMessageInteract title="Connect Wires">
{`Use two **alligator clip** wires from your *kit* to connect the **thermistor** to the micro:bit as shown below.

**Be sure to connect *only* to the \`0\` and \`3V\` metal pads!**
`}
</LegendMessageInteract>
<img src={ThermistorClipsPic} alt=""
    style={{
      width: 200, margin: 'auto', display:'block'
    }}
/>
<br />
<img src={ThermistorConnectPic} alt=""
    style={{
      width: 400, margin: 'auto', display:'block'
    }}
/>
</Markdown>
        <StepComplete
          prompt="All connected?"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class TS1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const typeErrorRegex = /TypeError: can't convert 'int' object to str implicitly/
    const dialogContent = (
      <div>
        Hey! <b>display.scroll()</b> needs a <em>string</em>, not an <em>int</em>!
        That's what this <b>TypeError</b> message is about.

        Remember how to convert the integer to a string?
        <ul>
          <li>
             <b>str()</b> to the rescue!
          </li>
        </ul>
      </div>
    )

    this.props.interceptErrorCb([typeErrorRegex, dialogContent, "scroll WHAT?!"])
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Get Digital With It! {lesson-title}
___
Remember when you first connected the **photocell**?
* The first step with an **analog** sensor like this is to read the ==ADC==.

The ==loop== below just reads the ADC and displays the *raw* value.
`}
<LegendMessageKeyboard>
{`Create a new File and name it **TempSensor**.

Make a \`while loop\` that continuously *reads* and *displays* the value.
\`\`\`
from microbit import *
pin0.read_digital()  # Setup Pin0

while True:
    # Read pin0: returns 0=cold to 1023=hot
    value = pin0.read_analog()
    display.scroll(value)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageWarning>
{`There's an error in the code above. Do you need to do something to \`value\` before handing it to \`display.scroll()\`?
`}
</LegendMessageWarning>
<LegendMessageRun>
{`After you fix the bug, take a look at the displayed **ADC value**.
* What *value* is displayed when it's at **room temperature**?
* Gently hold the **thermistor** with your fingers.
* Do you see the value **increase**?
  * Now let go... Does the value **decrease**?
`}
</LegendMessageRun>
<StepComplete
  prompt="Is the displayed value tracking the temperature?"
  xp={25}
  successMessage=
{`## You're getting WARMER! {centered}
#### Sensors give your code super-powers.
Something about converting ==Analog== to ==Digital== makes my circuits sing!
`}
  reqImports={['microbit']}
  reqCalls={['display.scroll', 'pin0.read_digital', 'pin0.read_analog', 'str']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class TS2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Calibration Time! {lesson-title}
___
`}
<img src={TempCal72} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float: 'right', marginLeft: 10, marginRight: 10
    }}
/>
{`#### What's the *temperature* in here?
* My micro:bit reads **535**. How about yours?
  * ***...what?!?***

The ***values*** change with temperature, but the **units** are meaningless!
* Those numbers are just "raw" **ADC** values.
* Next step is to convert them to **standard Units** like *Celsius* or *Fahrenheit*.
`}
<div style={{clear:'right'}} />
<img src={TempCal32} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float: 'right', marginLeft: 10, marginRight: 10, marginTop: 10
    }}
/>
{`
#### Converting to *Real* Temperature
Can you write ***code*** that converts from *ADC* values to *real* units?

***Of Course!*** {centered}

First you're going to need some "known" temperatures. Say you measure the ADC value at *room temperature*, and then at *freezing*.
* The pictures at *right* show two measurements:
  * Room temperature: **72&deg;F**  &rarr; \`value = 535\`
  * Ice water (freezing): **32&deg;F**  &rarr; \`value = 320\`

Does the *thermistor* change value **equally** for every temperature step?
* If so, the **straight line** below shows the *relationship* between: **&deg;F** &harr; **ADC** value.
  * ...Okay, real thermistors don't *exactly* follow a straight line, but you can use this "*linear approximation*" and get pretty close! *(Doesn't "linear" sound much cooler than "straight line"??)*
`}
<img src={TempGraph} alt=""
    style={{
      width: "80%", margin: 'auto', display:'block', marginTop: 10
    }}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`From the graph above, what is the **Temperature** when the **ADC Reading** is **470**?
`}
  </Markdown>
  )}
  id={"Linear approximation"}
  xp={5}
  answerRight={"60⁰F"}
  answerWrong={["40⁰F","50⁰F", "70⁰F"]}
/>
{`
#### But how are you going to *CODE* this?
You need a function that takes in the **ADC reading** and returns the **temperature** in &deg;F.
> \`def get_temp(adc_reading):\`

For the **graph** above, *Temperature* is the **y-axis** and *ADC Reading* is the **x-axis**.
Do you know the *slope-intercept form* of the equation of a line?
`}
<img src={RelaxMath} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', marginLeft: 10, marginRight: 10, float: 'right'
    }}
/>
{`
> \`y = mx + b\` , (*m* = slope, *b* = y-intercept)
* How does this relate to **Temperature** and **ADC reading**?
  * **y** is the *Temperature* in &deg;F you want to calculate.
  * **x** is the *ADC reading*.
  * **m** is the *slope* of the line: \`m = (y2 - y1) / (x2 - x1)\`
  * **b** is the *Y-intercept*: \`b = y1 - m * x1\`

In the pictures above, the **calibration measurements** were:
* x1 = 320, y1 = 32
* x2 = 535, y2 = 72
> So the **slope** would be: \`m = (72 - 32) / (535 - 320)\`

But *you* don't have to **calculate** this stuff! Write some *code* to do it!
`}
<LegendMessageKeyboard>
{`Define a function that calculates **temperature** based on the calibration values you have measured.
* You can *calibrate* your own measurements in *Fahrenheit* or *Celsius*!
* *Or* you can just use the values above.

**Note:**
* Use the built-in Python function \`round()\` to *round* the temperature value to a whole number for display.
\`\`\`
from microbit import *
pin0.read_digital()  # Setup Pin0

$def get_temp(adc_reading, x1, y1, x2, y2):
$    x = adc_reading
$    m = (y2 - y1) / (x2 - x1)  # Calculate the slope
$    b = y1 - m * x1            # Calculate the y-intercept
$    y = m * x + b              # Calculate y
$    return y

while True:
    # Read pin0: returns 0=cold to 1023=hot
    value = pin0.read_analog()

$    # Use calibration values to get Temperature (adcX1, tempY1, adcX2, tempY2)
$    temp = get_temp(value, 320, 32, 535, 72)
$    temp = round(temp)
$    display.scroll(str(temp))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Now you have a **real *Digital Thermometer!***

> Take it for a spin and test it out!
* Warm it up!
* Cool it down...
`}
</LegendMessageRun>
<StepComplete
  prompt="Is your thermometer measuring magnificently?"
  xp={25}
  successMessage=
{`## Hot Diggity! {centered}
#### Your temperature sensor is fantastic.
This an **extremely** useful sensor. I can think of about 1,284,182,488 things in the world that need
temperature control right now! *(give me a second and I'll think of lots more...)*
> ***Thanks for the thrilling thermal throwdown :)***
`}
  reqImports={['microbit']}
  reqCalls={['display.scroll', 'pin0.read_analog', 'pin0.read_digital', 'get_temp', 'round']}
  reqFuncdefs={['get_temp']}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class TS3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Remote Thermometer {lesson-title}
___
`}
<img src={Fridge} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', marginLeft: 10, marginRight: 10, float: 'right'
    }}
/>
{`Combine your ==radio== knowledge with your new **thermistor** sensor to create a practical scientific instrument!
* How cold is it in the **refrigerator**?
* How about the **freezer**?
* Inside a **car** in the hot sun?

#### Create a *remote thermometer* by adding ==Radio== **send** capability to your *Thermometer* code.
* You are building the **transmitter**, so you only need to *add* the \`radio.send()\` part.
* Use the **RadioCounter** program from the ***RADIO MESSENGER*** project as your **receiver**.
  * Load that code into *another* micro:bit. No *thermistor* needed on the *receiver!*

`}
<LegendMessageInteract title="Wireless Setup">
<Markdown>
<img src={MicrobitBatt} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', marginLeft: 10, marginRight: 10, float: 'right'
    }}
/>
{`#### Grab a second *micro:bit* and a *battery pack!*
First, load **two** micro:bits with the **RadioCounter** code from your *Radio Messenger* project.
* Verify that you can ***remotely control*** each micro:bit's count with the other one's buttons!
* Leave one micro:bit on batteries, ready to be your ***temperature receiver***.

Now you're ready to write the *temperature transmitter*...
`}
</Markdown>
</LegendMessageInteract>
<LegendMessageKeyboard>
{`Load the ***TempSensor*** code back into your connected micro:bit.

Add the **radio** code shown below to the **thermometer** code you've already written.
* Notice the \`radio.send()\` is *before* the \`display.scroll()\`.
* ...that's so the code doesn't have to wait until after the ***scroll*** to send the *temperature*.
\`\`\`
from microbit import *
$import radio

pin0.read_digital()  # Setup Pin0
$radio.on()
$radio.config(channel=16)

def get_temp(adc_reading, x1, y1, x2, y2):
    x = adc_reading
    m = (y2 - y1) / (x2 - x1)  # Calculate the slope
    b = y1 - m * x1            # Calculate the y-intercept
    y = m * x + b              # Calculate y
    return y

while True:
    # Read pin0: returns 0=cold to 1023=hot
    value = pin0.read_analog()

    # Use calibration values to get Temperature (adcX1, tempY1, adcX2, tempY2)
    temp = get_temp(value, 320, 32, 535, 72)
    temp = round(temp)
$    radio.send(str(temp))
$    display.scroll(str(temp))
$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Load this code into your **micro:bit**!
* **And** load a **second** micro:bit with the **RadioCounter** code, which will **display** received *temperature string* data.
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What limits how fast the temperature is transmitted?
`}
  </Markdown>
  )}
  id={"Program delay via blocking function"}
  xp={5}
  answerRight={"The time needed for display.scroll() to finish."}
  answerWrong={["Nothing. Temperature is sent constantly, bombarding the airwaves.",
                "The sleep(500) code after each radio.send() limits it to 2 transmits per second."]}
/>
<StepComplete
  prompt="Are you remotely monitoring temperatures?"
  xp={25}
  successMessage=
{`## Wireless Sensor Networks! {centered}
Hey buckaroo! Reckon you could monitor a head o' cattle with that there sensor?
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'pin0.read_analog', 'pin0.read_digital', 'get_temp', 'radio.send', 'radio.on', 'round']}
reqFuncdefs={['get_temp']}
reqStatements={['while']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <div>
        <Markdown>
          {
`## Temperatura Thermodynamica! {lesson-title}
___
`}
        <img src={WeatherStation} alt=""
          style={{
            width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft:10
          }}
        />
{`
### You have mastered an *important* sensor!
* There are *other* electronic temperature sensors, but none more plentiful than the ***thermistor*** you're using now.
* What's more, the techniques you are using are common to *many* other analog sensors.

Imagine building a **Weather Station**:
* You can measure **Temperature**.
* You can measure **Sunlight**.
* What about **Wind speed and direction**?
* How about **Rainfall**?

***Of course, there are *sensors* for all these!***
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* **Fridge** door-open monitor.
  * Add a **speaker** to the remote **receiver**
  * Beep an *alarm tone* when the temperature is too high!
* Does the light really go off in the fridge?
  * Connect the **photocell** to \`pin0\` and \`3V\`
  * Use the same remote ==radio== code as the *remote thermometer*.
  * Finally settle this *age-old mystery!!*
`}
</LegendMessageTry>
        </Markdown>
        <StepComplete
          prompt="Sensors are so SENSATIONAL. So, segue..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

Prelude.stepId = 'Prelude'
ConnectThermistor.stepId = 'ConnectThermistor'
TS1.stepId = 'TS1'
TS2.stepId = 'TS2'
TS3.stepId = 'TS3'
Finale.stepId = 'Finale'

export const tempSensor = [
  Prelude,
  ConnectThermistor,
  TS1,
  TS2,
  TS3,
  Finale,
]
