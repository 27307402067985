import React, { Component, Fragment } from 'react'
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles'

import { googleApi } from '../Gapi'
import ErrorBoundary from '../ErrorBoundary'
import { cblFirebase } from '../myfirebase'
import userProgress from '../UserProgress'
import Loading from '../Loading'
import UserSession from '../UserSession'

import { Database, data } from './DataSources'
import RootPanel from './RootPanel.jsx'


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#80e27e',
      main: '#4caf50', // same green as CodeSpace
      dark: '#087f23',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff64f',
      main: '#ffc400', // similar yellow to Codee
      dark: '#c79400',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
    },
  },
})

class Dashboard extends Component {
  static defaultProps = {
    match: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      gSignedIn: googleApi.gapiAuthIsSignedIn,
      gLoaded: false,
      userAttributes: null,
      needCodespaceData: false,
      needGroupsData: true,
    }

    // Update favicon
    document.getElementById('favicon').href = 'DashIcon.png'
    document.documentElement.style.height = '100%'
    document.body.style.height = '100%'
    document.getElementById('root').style.height = '100%'

    // here to prevent errors in the UserSession load
    userProgress.newAccountPromise().then(() => {
      console.log("non existing account")
    }).catch(() => {
      console.log("existing account")
    })
  }

  componentDidMount = () => {
    if (cblFirebase.userDoc) {
      Database.getGroupsData().then(this.gotGroupsData)
      console.log('component mounted user doc')
    } else {
      cblFirebase.registerDocRetrievedCallback(() => {
        Database.getGroupsData().then(this.gotGroupsData)
        console.log('component mounted user doc')
      })
    }

    googleApi.notifyOnAuthChange(this.handleGoogleSignedInNotify)
    googleApi.gapiLoadedPromise.then(() => {
      this.setState({ gLoaded: true })
    })

    userProgress.registerOnChangeCallback(this.handleUserProgressChange)
  }

  componentWillUnmount = () => {
    userProgress.unregisterOnChangeCallback(this.handleUserProgressChange)
    googleApi.unregisterNotifyOnAuthChange(this.handleGoogleSignedInNotify)
  }

  handleGoogleSignedInNotify = (signedIn) => {
    this.setState({ gSignedIn: signedIn })
  }

  gotGroupsData = (d) => {
    // console.log(d.groupsData, d.availableCurricula)
    data.owner = { availableCurricula: d.availableCurricula }
    data.groups = d.groupsData
    this.setState({ needGroupsData: false })
  }

  handleUserProgressChange = (userProgressObj, isUserData) => {
    if (isUserData) {
      this.setState({ userAttributes: <div/> }) // this causes the UserProgress block to rerender in the header and show email address
    }
  }

  render() {
    return (
      /* App */
      this.state.needCodespaceData || this.state.needGroupsData || this.state.userAttributes === null ?
        <div>
          <Loading />
        </div> :
        <MuiThemeProvider theme={theme}>
          <ErrorBoundary>
            <RootPanel
              getUserDisplay={
                <Fragment>
                  <style>
                    {
                      '#profile-email > span {border-top:4px solid white !important;}' // causes UserSession arrow to be white
                    }
                  </style>
                  <UserSession
                    signedIn={this.state.gSignedIn}
                    gLoaded={this.state.gLoaded}
                    userAttributes={this.state.userAttributes}
                  />
                </Fragment>
              }
            />
          </ErrorBoundary>
        </MuiThemeProvider>
    )
  }
}

export default Dashboard
