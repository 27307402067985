class MultiTabCommunication {
    _broadcastChannel
    _handleMultipleTabsOpenCallback
    _excludePaths = new Set(["/dashboard"])

    constructor() {
      if (this._excludePaths.has(window.location.pathname)) {
        return
      }

      this._broadcastChannel = new window.BroadcastChannel("CodeSpace_MultiTab")
      this._broadcastChannel.onmessage = this._handleMessage

      // Send: rebroadcast=true
      this._broadcastChannel.postMessage(true)
    }

    setMultipleTabsOpenCallback(callback) {
      if (this._broadcastChannel) {
        this._handleMultipleTabsOpenCallback = callback
        // No harm in sending again!
        this._broadcastChannel.postMessage(true)
      }
    }

    _handleMessage = (message) => {
      // console.log(`Got message data: ${message.data}`)
      this._handleMultipleTabsOpenCallback && this._handleMultipleTabsOpenCallback()
      if (message.data === true) {
        // Rebroadcast so sender can alert their window also.
        this._broadcastChannel.postMessage(false)
      }
    }
}

export default new MultiTabCommunication()