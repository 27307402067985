import React from 'react'
import PropTypes from 'prop-types'
import Dialog, {DialogContent, DialogTitle} from 'material-ui/Dialog'

export class MessageDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
  }
  render() {
    let {title, ...otherProps} = this.props
    return (
      <Dialog
        {...otherProps}
        open={this.props.open}
        onClose={this.props.onClose}
        style={this.props.style}
        keepMounted
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
        {this.props.children}
        </DialogContent>
      </Dialog>
    )
  }
}

export default MessageDialog
