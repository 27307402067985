// Project: Reaction Time Game

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  // LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  // LegendMessageWarning,
  LegendMessageConcept,
  // LegendMessageInteract,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import heroImg from './assets/stopwatch.png'
import PropTypes from 'prop-types'
import WatchImg from './assets/watch.svg'
import DrumMachineImg from './assets/DrumMachine.gif'
import Quiz from '../Quiz'


export const ReactionTimeImg = heroImg

class ReactionTime extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }


  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Reaction Tester {lesson-title}
___
#### *How fast is your reaction time? Let's find out!* {centered}
### In this project, you will be creating a game to measure your reaction response time!
`}
<img src={heroImg} alt=""
    style={{
      width: 200, margin: 'auto', display:'block', float:'right'
    }}
/>
{`You will create a game that measures the time between:
* the **display** lighting up, and
* a **button** being pressed.

After the measurement is complete, this time will be scrolled across the display
until a button is pressed to restart the game.
> **Who** has the fastest reaction time? With a little coding, you're about to find out!

#### Project Goals: *Gameplay*
* Give the player a 3-2-1 countdown.
* Wait a *random* delay, so they can't "guess" the timing.
* Show a *Target* Image on the LED display.
* Measure the time until a button press occurs.
* Scroll the reaction time across the display.
* Wait for a button press, then restart the game.
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class StartingImage extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## The Countdown {lesson-title}
___
The game starts by giving the player time to prepare:
* Scroll a countdown "3 - 2 - 1" on the *display*

**Then, the display should go dark.** {centered}

The player waits, **keen to press the button** the instant an *Image* is displayed!
* Sleep for a random time period, then display an Image.
`}
<LegendMessageKeyboard>
{`
* Create a new file and name it **"Reaction_Time"**.
* Type in the code below:
\`\`\`
from microbit import *
import random

# Show countdown
display.scroll("321")

# Wait a few, tense moments...
delay_time = random.randrange(1000, 5000)
sleep(delay_time)

# Display an Image!
display.show(Image.TARGET)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun></LegendMessageRun>
<StepComplete
  prompt="Got your countdown and starting Image?"
  xp={25}
  successMessage=
{`## Game ON! {centered}
### This is going to be fun!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'display.scroll', 'random.randrange', 'sleep']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class MeasureTime extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Measure the Time {lesson-title}
___
#### Now add the code to **measure** the time between when the display turns **ON** and when a button is pressed.
`}
<LegendMessageConcept title="Concept: Computer Clocks">
<Markdown>
{`Computers rely on electronic **clock** circuits. Each **tick** of the micro:bit's speedy internal **clock** moves it through your
code *one step at a time*. It's really the *heartbeat* of the computer!
`}
<img src={WatchImg} alt=""
    style={{
      width: '20%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
What else does the **clock** drive?
* Time delays in \`sleep()\` functions.
* Musical tempo in \`music.play()\` songs.
* ...everything *timing* related on the computer!

From the moment you turn ON the micro:bit, the clock is *always running*.

Here's a built-in **function** that returns exactly how many *milliseconds* have elapsed since the last ==reboot==:
\`\`\`
# Return number of milliseconds since reboot
running_time()
\`\`\`
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageKeyboard>
{`After your code displays the starting \`Image\`, *add code* to:
* Save the current \`running_time()\` in a *variable* called **start_time**.
* Loop, waiting for a button press (A or B).
* After a button press, calculate and scroll the elapsed time.
\`\`\`
from microbit import *
import random

# Show countdown
display.scroll("321")

# Wait a few, tense moments...
delay_time = random.randrange(1000, 5000)
sleep(delay_time)

# Display an Image!
display.show(Image.TARGET)

$$$
$# Save start time
$start_time = running_time()
$
$# Wait for a button press
$while True:
$    if button_a.was_pressed() or button_b.was_pressed():
$        break
$
$# Calculate the reaction time
$reaction_time = running_time() - start_time
$
$# Display the result
$display.scroll(str(reaction_time))
$
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`#### Give it a try!!
How's your reaction time? Can you get below 300 milliseconds?
`}
</LegendMessageRun>
<StepComplete
  prompt="Are you measuring reaction times?"
  xp={25}
  successMessage=
{`## That was FAST! {centered}
### This is where it starts getting competitive...
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'display.scroll', 'random.randrange', 'sleep', 'button_a.was_pressed',
             'button_b.was_pressed', 'str']}
  reqStatements={['while', 'if', 'or', 'break']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class LoopDisplay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Continuous Scrolling {lesson-title}
___
#### Show off your amazing reaction time!!!
Oh, wait... it scrolled off the display.  -- No, *really*. It was, like, **LOW**.

*There is a problem with your game.* {centered}

**Change your code to *continuously scroll* the last reaction time!**

**How?** Check out the ==display== options for the \`display.scroll()\` function.
* The \`loop = True\` parameter makes the scroll *continuous*.
* **Also** notice the \`wait\` parameter. Set \`wait = False\`, or your code will **wait forever** for the scrolling to stop!
`}
<LegendMessageKeyboard>
{`Add the \`loop = True\` parameter to your \`display.scroll()\` function.

Also add \`wait = False\`, so the code can *continue* even while the display scrolls.
\`\`\`
from microbit import *
import random

# Show countdown
display.scroll("321")

# Wait a few, tense moments...
delay_time = random.randrange(1000, 5000)
sleep(delay_time)

# Display an Image!
display.show(Image.TARGET)

# Save start time
start_time = running_time()

# Wait for a button press
while True:
    if button_a.was_pressed() or button_b.was_pressed():
        break

# Calculate the reaction time
reaction_time = running_time() - start_time

$$$
$# Display result, and keep scrolling
$display.scroll(str(reaction_time), loop = True, wait = False)

$$$
\`\`\`
`}
</LegendMessageKeyboard>
{`
**Note:** Since your program *ends* after the \`display.scroll()\`, it would still work even without \`wait = False\`.
> **But** for the next step in the project you'll need it!
`}
<LegendMessageRun>
</LegendMessageRun>
<StepComplete
  prompt="Is your display getting loopy?"
  xp={25}
  successMessage=
{`## Scroll like the WIND! {centered}
### You're like the Marquis de Marquee ;-)
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'display.scroll', 'random.randrange', 'sleep', 'button_a.was_pressed',
             'button_b.was_pressed', 'str']}
  reqStatements={['while', 'if', 'or', 'break']}
  reqArgs={['loop=True', 'wait=False']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class KeepPlaying extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Let's Keep Playing {lesson-title}
___
Great job so far! The game is fun, but what if you want to play more than once?
#### Make the game *wait for a button press*, then start again!
* You need a **loop** that contains *all the code* from "3-2-1" on down.
* Add code to *wait for a button press* before continuing the loop.

**Hey!** You *already* have code that **waits for a button press...**
`}
<LegendMessageKeyboard>
{`Change your code:
* Place everything after \`import random\` inside a \`while\` loop.
  * *remember you can select it all and use the TAB key to indent a whole block!*
\`\`\`
from microbit import *
import random

$$$
$while True:
$    # Show countdown
    display.scroll("321")

$    # Wait a few, tense moments...
    delay_time = random.randrange(1000, 5000)
    sleep(delay_time)

$    # Display an Image!
    display.show(Image.TARGET)

$    # Save start time
    start_time = running_time()

$    # Wait for a button press
    while True:
        if button_a.was_pressed() or button_b.was_pressed():
            break

$    # Calculate the reaction time
    reaction_time = running_time() - start_time

$    # Display result, and keep scrolling
    display.scroll(str(reaction_time), loop = True, wait = False)

$    # Wait for a button press
$    while True:
$        if button_a.was_pressed() or button_b.was_pressed():
$            break

$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun></LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`How many loops are you using above?
`}
  </Markdown>
  )}
  id={"Count the loops"}
  xp={5}
  answerRight={"Three"}
  answerWrong={["One", "Two", "Four"]}
/>
        </Markdown>
<StepComplete
  prompt="Resetting the game in style now?"
  xp={25}
  successMessage=
{`## Again! Again! {centered}
### Hang on, I *know* I can get *faster!*
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'display.scroll', 'random.randrange', 'sleep', 'button_a.was_pressed',
             'button_b.was_pressed', 'str']}
  reqArgs={['loop=True', 'wait=False']}
  reqStatements={['while', 'if', 'or', 'break']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>

      </div>
    )
  }
}

class KeepDry extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Reduce Repetition {lesson-title}
___
#### Take a look at your code.

> *Do you notice a block of code that's **repeated?***

It works just fine, **but** you can make this code more *readable* and *maintainable*.
`}
<LegendMessageConcept title="Concept: Don't Repeat Yourself (DRY)">
{`
Here is ancient coding wisdom:

*Never write the same code twice.* {centered}

Okay, alright, a little *repetition* isn't awful, *but* if you find yourself typing the same code over and over, just
think how much work it will be to **change** it (or fix a **bug** in it) in the future.
> Instead, let your *programming tools* (like ==functions==) do the work!
`}
</LegendMessageConcept>
<LegendMessageKeyboard>
{`Change your code:
* Define a function called: **wait_button()**
  * Put it **above** your big \`while True\` loop.
* Replace **both** places you're checking for button presses with *calls* to your new function.
\`\`\`
$$$
from microbit import *
import random

$def wait_button():
$    # Wait until button_a or button_b was pressed
$    while True:
$        if button_a.was_pressed() or button_b.was_pressed():
$            break

$while True:
$    # Show countdown
$$$
    display.scroll("321")

$    # Wait a few, tense moments...
    delay_time = random.randrange(1000, 5000)
    sleep(delay_time)

$    # Display an Image!
    display.show(Image.TARGET)

$    # Save start time
    start_time = running_time()

$    # Wait for a button press
$    wait_button()

$    # Calculate the reaction time
    reaction_time = running_time() - start_time

$    # Display result, and keep scrolling
    display.scroll(str(reaction_time), loop = True, wait = False)

$    # Wait for a button press
$    wait_button()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun></LegendMessageRun>
        </Markdown>
<StepComplete
  prompt="Function finally factored?"
  xp={25}
  successMessage=
{`## Clean Code! {centered}
### It's so much easier to read now.
If you ever need to check for button presses, this is a nice function to remember!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'display.scroll', 'random.randrange', 'sleep', 'button_a.was_pressed',
             'button_b.was_pressed', 'str', 'wait_button']}
  reqStatements={['while', 'if', 'or', 'break']}
  reqArgs={['loop=True', 'wait=False']}
  reqDefs={['wait_button']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>

      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Getting Real! {lesson-title}
___
Computers *measure time* in all types of applications.
`}
<img src={DrumMachineImg} alt=""
    style={{
      width: '40%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
* Football *play clocks*, and *stop watches* for other sports.
* Electronic Drum Machines
* Microwave Oven timers
* Alarm clocks
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Level-Up with **TWO** different *Images* - "**A**" and "**B**".
  * Require player to hit the **matching button** for score!
* Try using a **Sound** rather than an **Image**
  * Is *Ear-Hand* reaction faster or slower than *Eye-hand* reaction time??
`}
</LegendMessageTry>
<StepComplete
  prompt="Ready to move on?"
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>

        </Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ReactionTime.stepId = 'ReactionTime'
StartingImage.stepId = 'StartingImage'
MeasureTime.stepId = 'MeasureTime'
LoopDisplay.stepId = 'LoopDisplay'
KeepPlaying.stepId = 'KeepPlaying'
KeepDry.stepId = 'KeepDry'
Applications.stepId = 'Applications'

export const reactionTime = [
  ReactionTime,
  StartingImage,
  MeasureTime,
  LoopDisplay,
  KeepPlaying,
  KeepDry,
  Applications,
]
