
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'
import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'
import FinalAlertsImg from './assets/iStock-1185282377.jpg'
import FirstAlertImg from './assets/iStock-1167039323.jpg'
import AlertSystemIntroImg from './assets/iStock-1284009639.jpg'

import AdvancedDebugImg from '../PythonWithRobots/assets/advanced_debug.jpg'
import ReplStartImg from '../PythonWithRobots/assets/repl_start.jpg'
import StreamValsImg from './assets/iStock-504311226.jpg'
import BlinkRedImg from './assets/pwm.jpg'
import RawTempImg from './assets/peripherals/pk_temp.jpg'
import DegreesCImg from './assets/iStock-1243760572.jpg'
import HighTempImg from './assets/iStock-514996764.jpg'
import ExplosionImg from './assets/peripherals/pk_audio.jpg'
import SoundWaveImg from './assets/iStock-1253641776.jpg'
import SoundWaveExplanationImg from './assets/sinwave.jpg'
import CalculationImg from './assets/iStock-1285270580.jpg'

import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageDebug,
  LegendMessageInteract,
} from '../Legends'

export const AlertSystemImg = GoogleClassroomImg
class ProjectAlerts extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Alert System! {lesson-title}
___
### This project shows you how to read multiple analog sensors and display alerts to the crew!
`}
<img src={AlertSystemIntroImg} alt=""
    style={{
      width: '40%', margin: '0.5em', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

Time is of the essence if the ship has technical problems. The crew will need an alerting system to tell them if something has gone wrong.

**A risk analysis determined that two potential dangers exist and must be monitored:**
1. There are multiple electronics devices operating in the ship's electronics bay. The crew must be notified immediately if **temperature** in the electronics bay exceeds a **normal** operating range.
2. Sections of the ship must be cordoned off in the event of a hull breach. The crew must be notified immediately if an **explosion** is detected.

**Project: Alert System** will guide you through informing the crew if there is an emergency.

#### Project Goals:
* Use multiple analog sensors
* Learn how to use the REPL
* Learn to convert temperature values
* Use an average calculation
* Alert the crew if you detect an emergency situation!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class RedLightOn extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Red Light {lesson-title}
___
`}
<img src={FirstAlertImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The first step is preparing our system to display an alert. {centered}
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **AlertSystem**
`}
</LegendMessageInteract>
{`
#### Now add an alert system framework!
* The red LED will be the alerting mechanism. When the LED is ON the crew needs to take immediate action.
* You will add **sensors** later. *First step is to get the LED working!*
`}
<LegendOctoHeader 
  pin={0}
  peripheral={6} // 6 = Red LED
  markdown={`Connect the **red LED** to **position 0** on your ==octopus:bit==!`}
/>
{`#### Coding a *Sensor / Alert* framework
It would be easy to just turn the LED ON.
* But you know you'll soon need the following ==loop==:
  * Read Sensors
  * Check Sensor Values
  * Set Alert Output
  * *Repeat!*
`}
<LegendMessageKeyboard>
{`Code your basic Sensor / Alert framework.
1. Import the \`microbit\` ==module==.
2. Define ==variables== for \`LED_ON\` and \`LED_OFF\`.
3. Add a ==function== \`def set_red_led(val)\` that can take a ==digital== value.
4. Add a while ==loop==.
    * For now it will continuously \`set_red_led(LED_ON)\`.
    * Soon you will add some *sensors!*

\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

while True:
    # Read Sensors
    # TODO

    # Set Alert Output
    set_red_led(LED_ON)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
**Make sure your LED is always turned ON.**
`}
</LegendMessageRun>
</Markdown>
          <StepComplete
            prompt="Did your red LED turn on and stay on?"
            xp={5}
            successMessage=
{`## Deep Red! {centered}
### You have established a framework for the alerting system!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_red_led']}
            reqCalls={['pin0.write_digital','set_red_led']}
            reqArgs={['LED_ON']}
            reqNames={['LED_ON', 'LED_OFF']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class FirstAlert extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## First Alert {lesson-title}
___
#### The next step is simulating a sensor for your testing purposes. {centered}

The potentiometer will simulate the first sensor.
* If the knob is rotated past half way it is time to alert the crew!
`}
<LegendOctoHeader 
  pin={1}
  peripheral={8} // 8 = Potentiometer
  markdown={`Connect the **potentiometer** to **position 1** on your ==octopus:bit==!`}
/>
{`
The potentiometer is an ==analog== sensor. Read it with the \`read_analog()\` function.
* The value returned is between 0 and 1024.
* So if the \`value > 511\` &rarr; the knob has crossed the half-way point.

`}
<LegendMessageKeyboard>
{`
1. Add a function \`def read_knob():\` that returns the analog value of the potentiometer.
2. Add a ==branching== statement to your \`while\` ==loop==.
    * \`if\` the knob value is greater than 511 (50%), turn **ON** the red LED .
    * \`else\` turn **OFF** the red LED.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

$$$
$def read_knob():
$    return pin1.read_analog()

while True:
$    # Read Sensors
$    knob_val = read_knob()

$    # Set Alert Output
$    if knob_val > 511:
$        set_red_led(LED_ON)
$    else:
$        set_red_led(LED_OFF)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Twist the knob back and forth and watch the red LED." />
</Markdown>
          <StepComplete
            prompt="Is the LED turning ON when you rotate the knob past half-way?"
            xp={5}
            successMessage=
{`## Simulated Emergency! {centered}
### You have simulated the shuttle's first alert!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_knob']}
            reqCalls={['pin0.write_digital','set_red_led','read_knob','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqNames={['LED_ON', 'LED_OFF', 'knob_val']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}


class BlinkRed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Blinking Red Alert {lesson-title}
___
#### Use what you learned about ==PWM== to *blink* the red LED when it is ON.
`}
<img src={BlinkRedImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
**Remember:** You need to set the **Frequency** and **Duty-Cycle**.
#### Frequency
To set your LED to **blink** two times per second *(or once every 500 milliseconds)*:
\`\`\` 
# Blink 2 times per second
pin0.set_analog_period(500)
\`\`\` 

#### Duty-Cycle
Set the % ON time each period with this function:
\`\`\` 
pin0.write_analog(511) # 50% of max 1023
\`\`\` 
`}
<LegendMessageKeyboard>
{`Modify your \`set_red_led(val)\` function to **blink** when \`val == LED_ON\`.
* \`if\` the \`val\` ==parameter== is \`LED_ON\` set a 50% duty cycle
* \`else\` turn the LED **OFF**

\`\`\` collapsed Try yourself first...
from microbit import *

LED_ON = 1
LED_OFF = 0

$$$
$pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
$    if val == LED_ON:
$        pin0.write_analog(511) # 50%
$    else:
$        pin0.write_analog(0)   # 0% 
$$$

def read_knob():
    return pin1.read_analog()
    
while True:
    knob_val = read_knob()

    if knob_val > 511:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageInteract title="Twist the knob back and forth and watch the red LED." />
<LegendMessageRun  />
</Markdown>
          <StepComplete
            prompt="Is the LED blinking when you rotate the knob past half-way?"
            xp={5}
            successMessage=
{`## Flashing Lights! {centered}
### You are using ==PWM== again!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_knob']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','set_red_led','read_knob','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511']}
            reqNames={['LED_ON', 'LED_OFF', 'knob_val']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class ReplPrint extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## REPL What? {lesson-title}
___
### *Text messages* from your *Code*
*Wouldn't it be nice is there was a way to ***continuously display sensor values to a screen***?*
`}
<img src={AdvancedDebugImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:25, marginLeft: 10, marginBottom: 10
    }}
/>
{`
*Python's built-in \`print()\` function is made for that!*
* You'll need to enable the **Debug Panel** menu option in *CodeSpace* to use this feature.
* You'll also need to enable the **Advanced Debug** menu option.
* Use the **View** menu to enable them, as shown here &rarr;
`}
<div style={{clear:'both'}} />
{`The **Debug Console** panel will appear on the **left-hand** side next to the **Variables** panel.
`}
<img src={ReplStartImg} alt=""
    style={{
      width: "50%", margin: 'auto', display:'block', float:'left', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
Ignore any text that's already there for now - those messages are a side effect of *stopping and starting your code*.

If you ***click*** on this panel you can type-in **Python** statements directly. This is called the ==REPL==.
`}
<div style={{clear:'both'}} />
{`
`}
<LegendMessageConcept title="Concept: REPL">
{`#### *"Read Evaluate Print Loop"*
A name for the "command line" that languages like **Python** offer. If you were to code your own "REPL" in Python,
it might look something like this:
\`\`\`
while True:
    # Read a statement from the Keyboard. (press ENTER)
    # Evaluate the statement. (execute the Python code!)
    # Print the result to the Console.
\`\`\`
Besides being a place to see \`print()\` statement *output*, the ==REPL== is a great way to test out snippets of code, language features, and APIs
as you decide how to use them in your code.
`}
</LegendMessageConcept>
{`
Now, ***go back to your code in the Editor*** and try adding a \`print()\` statement to display on the **Debug Console**!
`}
<LegendMessageKeyboard>
{`Add a \`print()\` statement to display the ==string== \`"Alert, Alert, Alert!"\` on the *Debug Console* \`if\` the knob is rotated past 50%.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def read_knob():
    return pin1.read_analog()

$$$
while True:
    knob_val = read_knob()

    if knob_val > 511:
        set_red_led(LED_ON)
$        print("Alert, Alert, Alert!")
    else:
        set_red_led(LED_OFF)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
When you run this, you should see ***Alert, Alert, Alert!*** printed on the *Debug Console* whenever the knob is rotated past 50%!

**NOTE:** it is going to print every time it loops so it will be printing very **fast**!!
`}
</LegendMessageRun>
<StepComplete
  prompt="Got alert messages displaying on your Console?"
  xp={5}
  successMessage=
{`## Hello, World! {centered}
The \`print()\` statement can show you information while your code is running.

*Now, learn how to make it output **values**!*
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_knob']} 
            reqCalls={['print','pin0.write_analog','pin0.set_analog_period','set_red_led','read_knob','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511']}
            reqNames={['LED_ON', 'LED_OFF', 'knob_val']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class PrintValues extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Show me the Values! {lesson-title}
___
`}
<img src={StreamValsImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
### Streaming values from your sensor

The \`print()\` statement can do a lot more than just display text. You can give it multiple ==arguments==:
* ==strings==
* ==integers==
* ==lists==
* etc.

**Ex:**
\`print("Your name is ", name, " and your address is ", address)\`
`}
<LegendMessageKeyboard>
{`
Modify your code to \`print()\` a **label** and your knob's ==analog== **value** each time the system loops.
* The output should look something like: ***"Knob value = 325"***

You should also remove the \`print("Alert, Alert, Alert!")\`.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def read_knob():
    return pin1.read_analog()
    
$$$
while True:
    knob_val = read_knob()
$    print("Knob value = ", knob_val)

$$$
    if knob_val > 511:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageDebug title="Open up the Advanced Debug Panel." />
<LegendMessageRun />
<LegendMessageInteract title="Twist the knob">
{`Rotate the knob back and forth and watch the values in the debug panel.
* Now you can see *exactly* what the value of your knob is!
`}
</LegendMessageInteract>
<StepComplete
  prompt="Got streaming values on your Console?"
  xp={5}
  successMessage=
{`## Great Progress! {centered}
The \`print()\` statement is an excellent *debug* tool.

*Now for some more **fun** with **sensors!***
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_knob']}
            reqCalls={['print','pin0.write_analog','pin0.set_analog_period','set_red_led','read_knob','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511', 'knob_val']}
            reqNames={['LED_ON', 'LED_OFF', 'knob_val']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class TempSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Raw Temperature! {lesson-title}
___
`}
<img src={RawTempImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The first danger the crew should be alerted to is a high temperature in the electronics bay.

**High temperatures can lead to many potential issues:**
* Electronics could be irreversibly damaged.
* The devices could catch fire.
* Certain devices have the potential for explosion.

You should now adjust your alerting system to monitor **temperature** in the ship's electronics bay.
* Then you can alert the crew if the temperature exceeds a **threshold**!
`}
<LegendMessageInteract title="Disconnect the potentiometer from your octopus:bit!" />
<LegendOctoHeader 
  pin={1}
  peripheral={5} // 5 = Temp Sensor
  markdown={`Connect the **Temperature Sensor** to **position 1** on your ==octopus:bit==!`}
/>
{`### Know Your *Sensor*
The temperature sensor is quite **accurate**.
* It is typically accurate within **± 2° Celsius** over a wide range (-40°C to +125°C).
* It's also *pre-calibrated at the factory*.
  * It outputs exactly **0.75 Volts at 25°C**
  * The output changes by **± 0.01 Volts per degree C**

#### Wait! *Volts?*
This sensor's output is given in *Volts*, but the \`read_analog()\` function just gives a **Raw** ==ADC== *value* from 0 to 1023.
* You will need to convert the **Raw** value to *real units*: Volts &rarr; °C.
* But first, just display the Raw values!

The temperature sensor is an ==analog== sensor **Just Like the Potentiometer!!**
* So your code won't have to change much for this :-)
`}
<LegendMessageKeyboard>
{`
**Modify your code to read the raw temperature value instead of the potentiometer.**
1. Change the name of your \`read_knob()\` function to \`read_temp()\`.
2. Change the ==variable== \`knob_val\` to \`temp_val\`
3. Change your \`print()\` statement to read: ***"Raw temp = "*** \`temp_val\`

\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

$$$
$def read_temp():
    return pin1.read_analog()
    
while True:
$    temp_val = read_temp()
$    print("Raw temp = ", temp_val)

$    if temp_val > 511:
        set_red_led(LED_ON)
$$$
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you are watching the raw temp values.
`}
</LegendMessageRun>
<LegendMessageInteract title="Change the Temperature">
{`*Squeeze* the temp sensor between your fingers and watch the temperature *rise!*
* Release the sensor to watch the values *fall* back down.
  * They should slowly return back near where they started.
`}
</LegendMessageInteract>

        </Markdown>
        <StepComplete
  prompt="Is the REPL showing raw temperature values?"
  xp={5}
  successMessage=
{`## You're Heating Up! {centered}
#### Now you need to put some meaning behind those raw values!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp']}
            reqCalls={['print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','set_red_led','read_temp','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511', 'temp_val']}
            reqNames={['LED_ON', 'LED_OFF', 'temp_val']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class DegreesC extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Convert to Degrees Celsius! {lesson-title}
___
`}
<img src={DegreesCImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### Raw temperature values won't mean much to the crew.
You'll need to write some *conversion code!*
* How can you convert a (0 - 1023) ==ADC== value to °C?

1. Convert the ==ADC== value to Volts
$$
v = \\Vref \\cdot frac{adc_value}{1023}
$$


This calculation was provided by the sensor manufacturer.
* It converts the raw temperature values to degrees Celsius.

\`\`\`
def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50
\`\`\`
`}
<LegendMessageKeyboard>
{`
**Now, modify your while ==loop== to:**
1. Convert your raw temp reading to a \`degrees_c\` ==variable==.

2. Update your \`print\` to output degrees Celsius.

3. Use \`degrees_c\` in the check for high temperature. 

\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

$def convert_temp_to_c(raw_temp):
$    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
$    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()
    
while True:
    temp_val = read_temp()

$    degrees_c = convert_temp_to_c(temp_val)
$    print("Degrees C = ", degrees_c)

$    if degrees_c > 511:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you are watching the Celsius temp values.
`}
</LegendMessageRun>
<LegendMessageInteract title="Squeeze the temp sensor between your fingers and watch temperature rise!" >
{`
You will not be able to make it rise above the limit yet!
`}
</LegendMessageInteract>
</Markdown>

<StepComplete
  prompt="Is the REPL showing degrees Celsius values?"
  xp={5}
  successMessage=
{`## That is Useful! {centered}
#### Degrees Celsius is far more useful to the crew than raw temp values!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c']}
            reqCalls={['convert_temp_to_c','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','set_red_led','read_temp','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511', 'temp_val']}
            reqNames={['LED_ON', 'LED_OFF', 'temp_val']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class TempLimit extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## It's Getting Hot in Here! {lesson-title}
___
`}
<img src={HighTempImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### Now finish the temperature alerting system.

You should setup your temperature sensor that it can actually trigger an alert.

Add a \`TEMP_THRESHOLD\` variable and set it to \`3\`.
* This will allow you to squeeze the temp sensor to trigger an alert.
* It should trigger 3 degrees above the sensor's first reading.

\`\`\`
TEMP_THRESHOLD = 3 # degrees C
\`\`\`

Next, create a \`temp_limit\` variable above your \`while\` ==loop==.
* Set the \`temp_limit\` to the Celsius value of your first sensor reading plus the \`TEMP_THRESHOLD\`.

\`\`\`
# temp limit is TEMP_THRESHOLD above first sensor value
first_temp = read_temp()
temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD
\`\`\`

You should also setup your code to stop when a high temperature is detected.
* You can use a \`break\` statement when \`degrees_c\` exceeds the \`temp_limit\`!
* **The crew will need to reset the system after they handle the emergency!**
`}
<LegendMessageKeyboard>
{`
**Now, use your new temperature limit to trigger the alert and blink the red LED.**
* Modify your while ==loop== to use the new \`temp_limit\` to trigger the alert.

\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

$TEMP_THRESHOLD = 3 # degrees C

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()

$first_temp = read_temp()
$temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD

while True:
    temp_val = read_temp()

    degrees_c = convert_temp_to_c(temp_val)
    print("Degrees C = ", degrees_c)

$    if degrees_c > temp_limit:
$        print("Temperature high!")
        set_red_led(LED_ON)
$        break
    else:
        set_red_led(LED_OFF)
\`\`\`

`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you are watching the raw temp values.

**Reminder:** Your program will stop once the \`temp_limit\` has been exceeded!
`}
</LegendMessageRun>
<LegendMessageInteract title="Squeeze the temp sensor between your fingers until the temperature rises about your temp_limit!" />
</Markdown>

<StepComplete
  prompt="Were you able to trigger the temperature alert?"
  xp={5}
  successMessage=
{`## Great Job! {centered}
#### You have created an excellent temperature alerting system!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c']}
            reqCalls={['convert_temp_to_c','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','set_red_led','read_temp','pin1.read_analog']}
            reqArgs={['LED_ON', 'LED_OFF','500','511', 'temp_val','first_temp']}
            reqNames={['LED_ON', 'LED_OFF', 'temp_val','first_temp','temp_limit','TEMP_THRESHOLD']}
            reqStatements={['while', 'if', 'else','break']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class NoiseSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Explosion Detection? {lesson-title}
___
`}
<img src={ExplosionImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### A second potential for danger, aboard the shuttle, is the risk of explosion or hull breach.

*But, what can you use to detect an explosion?*

**How about listening for loud sounds!**

The **Sound Sensor** is essentially a small microphone.
* It measures the **intensity** of the sound (whether the sound is quiet or loud).
* This particular sensor is not accurate enough to analyze speech patterns.
* The **sound sensor** is an **analog** sensor similar to the **temperature sensor**.
`}
<LegendOctoHeader 
  pin={2}
  peripheral={9} // 9 = Sound Detector
  markdown={`Connect the **Sound Sensor** to **position 2** on your ==octopus:bit==!`}
/>

<Quiz
        lessons={this.props.lessons}
        prompt="The sound sensor is NOT accurate enough to detect?"
        xp={5}
        answerRight={"A Specific Word"}
        answerWrong={["Two Claps", "An Explosion"]}
      />
        </Markdown>
        <StepComplete
          prompt="Press NEXT to learn how the sound sensor works!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}


class SoundVsTemp extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Sound Detector vs Temperature Sensor {lesson-title}
___
`}
<img src={SoundWaveImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The sound detector is an analog sensor and the temperature sensor is an analog sensor.

*They should work the same way... Right?* 

Well, not exactly...

Add a \`read_sound\` ==function== that ==returns== the **analog** value from the sound sensor.

\`\`\`
def read_sound():
    return pin2.read_analog()
\`\`\`
`}
<LegendMessageKeyboard>
{`
Use your new read_sound function to read to a \`sound_val\` ==variable== in your \`while\` loop.

Then, change the \`print\` statement to output: **Raw sound =** \`sound_val\` instead of the temperature.

\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

TEMP_THRESHOLD = 3 # degrees C

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()

$def read_sound():
$    return pin2.read_analog()

first_temp = read_temp()
temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD

while True:
$    sound_val = read_sound()
$    print("Raw sound = ", sound_val)

    temp_val = read_temp()
    degrees_c = convert_temp_to_c(temp_val)

    if degrees_c > temp_limit:
        print("Temperature high!")
        set_red_led(LED_ON)
        break
    else:
        set_red_led(LED_OFF)
\`\`\`

`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you are watching the raw sound values.
`}
</LegendMessageRun>
<LegendMessageInteract title="Now clap your hands!" >
{`
Now clap your hands close to the Sound Detector and watch the sound values.
`}
</LegendMessageInteract>
{`
*Did you notice a momentary blip in the raw values?*
It probably happened so fast that you barely noticed the change.

The sound detector only detects **momentary** changes in sound levels.

**Make note of the average values on the ==REPL==.**
`}
<LegendMessageKeyboard>
{`
**It is time for an experiment.**

Use an \`if\` statement to only print the \`sound_val\` when the value is **LESS THAN** the average value by more than 150.

**Ex.** if the average is \`750\` then only print if the value is below \`600\`.
\`\`\`
while True:
    sound_val = read_sound() 
    if sound_val < 600: # average value is 750
        print("Raw sound = ", sound_val)
\`\`\`

This should **never** trigger if the sound sensor is just like the temperature sensor.
* Values should only ever get higher when a loud noise happens.
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you are watching the raw sound values.
`}
</LegendMessageRun>
<LegendMessageInteract title="Now clap your hands!" >
{`
Now clap your hands close to the Sound Detector and watch to see if you capture any values.
`}
</LegendMessageInteract>
{`
*Wait! The sound detector gives lower values for louder sounds?*

Hmm... What is happening here?
`}
        </Markdown>
        <StepComplete
  prompt="Were you able to capture some low values with a loud noise?"
  xp={5}
  successMessage=
{`## Interesting! {centered}
#### Why did the sound sensor output low values with a loud noise?
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c','read_sound']}
            reqCalls={['convert_temp_to_c','pin2.read_analog','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','set_red_led','read_temp','pin1.read_analog','read_sound']}
            reqArgs={['LED_ON', 'LED_OFF','500','511', 'temp_val','first_temp','sound_val']}
            reqNames={['LED_ON', 'LED_OFF', 'temp_val','first_temp','temp_limit','TEMP_THRESHOLD','sound_val']}
            reqStatements={['while', 'if', 'else','break']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class SoundWave extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Sound Wave {lesson-title}
___
#### The sound detector outputs raw values that mimic a sound wave!

*What does that mean?* 

* A **louder** sound causes a **HIGHER** value 
**AND**
* A **louder** sound causes a **LOWER** value

Take a look at the following diagram:
`}
<img src={SoundWaveExplanationImg} alt=""
    style={{
      width: "500px", margin: 'auto',
    }}
/>
{`
**Some NOTES about the sound sensor:**
* It will output values similar to the diagram above.
* Its average values are **centered** around a **quiet** sound level.
* The **faster** you read it, the more the data looks like a wave.
* It is more likely to read both high **and** low values the longer a sound lasts.
`}
<Quiz
        lessons={this.props.lessons}
        prompt="The sound sensor will return a _____ value when the sound intensity increases?"
        xp={5}
        answerRight={"Higher and Lower"}
        answerWrong={["Lower", "Higher"]}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt="The temperature sensor will return a _____ value when the temperature increases?"
        xp={5}
        answerRight={"Higher"}
        answerWrong={["Lower", "Higher and Lower"]}
      />
        </Markdown>
        <StepComplete
          prompt="Press NEXT if you understand the sound sensor's data!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class CalcSoundAvg extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Just an Average Calculation {lesson-title}
___
#### It is too unpredicable to determine the **QUIET** level of the sound sensor beforehand!
`}
<img src={CalculationImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
The average (or quiet) value is not always the same.

**The sound detector average values change based on:**
* Temperature
* Barometric Pressure

*So, how can I write code that automatically determines the average level for me?* 

There are **MANY** different ways to calculate an average, and each works better in different situations.

**You can try the *Exponential Moving Average (EMA)* calculation for this project.**
* This type of average puts more **significance** on the last sample than previous samples.
* It is a simple calculation.
* The way sound waves go high and low at the same time means the average should stay consistent.
`}
<LegendMessageKeyboard>
{`
Add the following code below your \`read_sound\` function:
\`\`\`  
SAMPLE_WT = 0.02 # each new value has a 2% significance

# calculates average of the values
def calc_avg(avg, new_val):
    return  avg * (1 - SAMPLE_WT) + new_val * SAMPLE_WT

avg_sound = read_sound() # starts at the first sensor value
\`\`\`
`}
</LegendMessageKeyboard>
{`
Now, you can use the new averaging ==function== to let your code do the calculation.
`}
<LegendMessageKeyboard>
{`
Replace your \`while\` loop with the following.
* You can add the temperature readings back in later:
\`\`\`  
while True:
    sound_val = read_sound()
    print("New Val: ", sound_val, " Avg Val: ", avg_sound)
    avg_sound = calc_avg(avg_sound, sound_val)
    sleep(500)
\`\`\`

\`\`\` collapsed The complete code is here...
from microbit import *

LED_ON = 1
LED_OFF = 0

TEMP_THRESHOLD = 3 # degrees C

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()

def read_sound():
    return pin2.read_analog()

first_temp = read_temp()
temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD

$SAMPLE_WT = 0.02 # each new value has a 2% significance

$# calculates average of the values
$def calc_avg(avg, new_val):
$    return  avg * (1 - SAMPLE_WT) + new_val * SAMPLE_WT

$avg_sound = read_sound() # starts at the first sensor value

$while True:
$    sound_val = read_sound()
$    print("New Val: ", sound_val, " Avg Val: ", avg_sound)
$    avg_sound = calc_avg(avg_sound, sound_val)
$    sleep(500)
\`\`\`

`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open and you watch the average values for a bit.
`}
</LegendMessageRun>
        </Markdown>
        <StepComplete
  prompt="Did you see the values come to a pretty stable average?"
  xp={5}
  successMessage=
{`## Average Joe! {centered}
#### Learn how to use those average values next!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c','read_sound','calc_avg']}
            reqCalls={['sleep','convert_temp_to_c','pin2.read_analog','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','read_temp','pin1.read_analog','read_sound','calc_avg']}
            reqArgs={['500','511','first_temp','sound_val','avg_sound']}
            reqNames={['LED_ON', 'LED_OFF','first_temp','temp_limit','TEMP_THRESHOLD','sound_val','avg_sound']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class SoundVariation extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Use Variation {lesson-title}
___
#### Now you have an average so you should use it!

First, you need to set a threshold. Add the \`LOUD_THRESHOLD\` to the top of your file.
\`\`\` 
LOUD_THRESHOLD = 50
\`\`\`

Next, you need to calculate the **variation** of the last sound value from the average.

You simply subtract the average from the value to get variation:
\`\`\` 
variation = sound_val - avg_sound
\`\`\`

Finally, you can test for **loud** sounds:
* sensor value is **HIGH** \`if variation > LOUD_THRESHOLD\`
* sensor value is **LOW** \`if variation < -LOUD_THRESHOLD\`
`}
<LegendMessageKeyboard>
{`
Modify the contents of your \`while\` loop to check for variation:
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

TEMP_THRESHOLD = 3 # degrees C

$LOUD_THRESHOLD = 50

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()

def read_sound():
    return pin2.read_analog()

first_temp = read_temp()
temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD

SAMPLE_WT = 0.02 # each new value has a 2% significance

# calculates average of the values
def calc_avg(avg, new_val):
    return  avg * (1 - SAMPLE_WT) + new_val * SAMPLE_WT

avg_sound = read_sound() # starts at the first sensor value

while True:
    sound_val = read_sound()
$    variation = sound_val - avg_sound

$    if variation > LOUD_THRESHOLD:
$        print("You exceeded the threshold HIGH, value: ", sound_val)

$    if variation < -LOUD_THRESHOLD:
$        print("You exceeded the threshold LOW, value: ", sound_val)

    avg_sound = calc_avg(avg_sound, sound_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open so that you can watch new values and how they affect the average.
`}
</LegendMessageRun>
<LegendMessageInteract title="Now clap your hands!" >
{`
Now clap your hands close to the Sound Detector and watch alerts happen.

You are hoping to see both **HIGH** and **LOW** alerts!
`}
</LegendMessageInteract>
        </Markdown>
        <StepComplete
  prompt="Were you able to capture a loud sounds?"
  xp={5}
  successMessage=
{`## Great Work! {centered}
#### You have just done some pretty tough stuff!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c','read_sound','calc_avg']}
            reqCalls={['convert_temp_to_c','pin2.read_analog','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','read_temp','pin1.read_analog','read_sound','calc_avg']}
            reqArgs={['500','511','first_temp','sound_val','avg_sound']}
            reqNames={['LED_ON', 'LED_OFF','first_temp','temp_limit','TEMP_THRESHOLD','sound_val','avg_sound','variation','LOUD_THRESHOLD']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class RedAlert extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Complete Alert System {lesson-title}
___
#### You can now put the whole alerting system together!

Start by modifying your noise alert:
1. Combine the two **LOUD** noise detections into a single \`if\` statement.

2. Exit the ==loop== when a loud noise is detected.
\`\`\` 
if variation > LOUD_THRESHOLD or variation < -LOUD_THRESHOLD:
    print("Explosion detected!")
    set_red_led(LED_ON)
    break
\`\`\`
`}
<LegendMessageKeyboard>
{`
**Now, bring the temperature alert back into your code!**
\`\`\` 
from microbit import *

LED_ON = 1
LED_OFF = 0

TEMP_THRESHOLD = 3 # degrees C

LOUD_THRESHOLD = 50

pin0.set_analog_period(500) # 2 times per second

def set_red_led(val):
    if val == LED_ON:
        pin0.write_analog(511) # duty cycle 0 to 1023
    else:
        pin0.write_digital(val)

def convert_temp_to_c(raw_temp):
    # deg C = ((raw temp / 1023) * 3000 mV  - 500 mV) / 10 deg per mV
    return 0.29326 * raw_temp - 50

def read_temp():
    return pin1.read_analog()

def read_sound():
    return pin2.read_analog()

first_temp = read_temp()
temp_limit = convert_temp_to_c(first_temp) + TEMP_THRESHOLD

SAMPLE_WT = 0.02 # each new value has a 2% significance

# calculates average of the values
def calc_avg(avg, new_val):
    return  avg * (1 - SAMPLE_WT) + new_val * SAMPLE_WT

avg_sound = read_sound() # starts at the first sensor value

while True:
    sound_val = read_sound()
$    temp_val = read_temp()

    variation = sound_val - avg_sound

$    degrees_c = convert_temp_to_c(temp_val)
    
$    if variation > LOUD_THRESHOLD or variation < -LOUD_THRESHOLD:
$        print("Explosion detected!")
$        set_red_led(LED_ON)
$        break
        
$    if degrees_c > temp_limit:
$        print("Temperature High!")
$        set_red_led(LED_ON)
$        break
        
    avg_sound = calc_avg(avg_sound, sound_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Make sure the ==REPL== is open so that you can see which problem caused the alert.
`}
</LegendMessageRun>
<LegendMessageInteract title="Clap your hands next to the sound detector!" />
<LegendMessageRun>
{`
Make sure the ==REPL== is open so that you can see which problem caused the alert.
`}
</LegendMessageRun>
<LegendMessageInteract title="Squeeze the temperature sensor between your fingers!" />
        </Markdown>
        <StepComplete
  prompt="Were you able to trigger both alerts?"
  xp={25}
  successMessage=
{`## Awesome! {centered}
#### The crew can rest easy with their new alert system!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_red_led', 'read_temp','convert_temp_to_c','read_sound','calc_avg']}
            reqCalls={['set_red_led','convert_temp_to_c','pin2.read_analog','print','pin0.write_digital','pin0.write_analog','pin0.set_analog_period','read_temp','pin1.read_analog','read_sound','calc_avg']}
            reqArgs={['500','511','first_temp','sound_val','avg_sound','temp_val']}
            reqNames={['LED_ON', 'LED_OFF','first_temp','temp_limit','TEMP_THRESHOLD','sound_val','avg_sound','variation','LOUD_THRESHOLD','temp_val','degrees_c']}
            reqStatements={['while', 'if', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class Alerted extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Alerted! {lesson-title}
___
### You've completed project Alert System!
You have improved the overall safety of the ship! The crew will rest easier knowing that their safety critical systems are being monitored.
`}
        <img src={FinalAlertsImg} alt="" style={{margin: 'auto', width:'100%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="The shuttle is now a much safer environment for the crew.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectAlerts.stepId = 'ProjectAlerts'
RedLightOn.stepId = 'RedLightOn'
FirstAlert.stepId = 'FirstAlert'
BlinkRed.stepId = 'BlinkRed'
ReplPrint.stepId = 'ReplPrint'
PrintValues.stepId = 'PrintValues'
TempSensor.stepId = 'TempSensor'
DegreesC.stepId = 'DegreesC'
TempLimit.stepId = 'TempLimit'
NoiseSensor.stepId = 'NoiseSensor'
SoundVsTemp.stepId = 'SoundVsTemp'
SoundWave.stepId = 'SoundWave'
CalcSoundAvg.stepId = 'CalcSoundAvg'
SoundVariation.stepId = 'SoundVariation'
RedAlert.stepId = 'RedAlert'
Alerted.stepId = 'Alerted'

export const alertSystem = [
  ProjectAlerts,
  RedLightOn,
  FirstAlert,
  BlinkRed,
  ReplPrint,
  PrintValues,
  TempSensor,
  DegreesC,
  TempLimit,
  NoiseSensor,
  SoundVsTemp,
  SoundWave,
  CalcSoundAvg,
  SoundVariation,
  RedAlert,
  Alerted,
]
