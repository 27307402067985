import React from 'react'
import PropTypes from 'prop-types'
import Table from 'material-ui/Table'
import TableBody from 'material-ui/Table/TableBody'
import TableCell from 'material-ui/Table/TableCell'
import TableHead from 'material-ui/Table/TableHead'
import TablePagination from 'material-ui/Table/TablePagination'
import TableRow from 'material-ui/Table/TableRow'
import TableSortLabel from 'material-ui/Table/TableSortLabel'

import { moduleEnums } from '../../lessons/CurriculumModules'
import { getDateObj } from '../DataSources'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  { id: 'groupName', numeric: false, disablePadding: false, label: 'Class Name' },
  { id: 'curriculum', numeric: false, disablePadding: false, label: 'Curriculum' },
  { id: 'joinCode', numeric: false, disablePadding: false, label: 'Join Code' },
  { id: 'joinActive', numeric: false, disablePadding: false, label: 'Join Active' },
  { id: 'msForDateCreated', numeric: false, disablePadding: false, label: 'Date Created' },
]

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headerCells } = props
  const createSortHandler = property => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headerCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1 }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headerCells: PropTypes.arrayOf(Object).isRequired,
}

class GroupsTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'curriculum',
    selected: [],
    page: 0,
    rowsPerPage: 5,
  }

  handleRequestSort = (event, property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc' })
    this.setState({ orderBy: property })
  }

  handleDoubleClick = (event, groupId) => {
    this.props.onSelectGroup(groupId)
    this.props.onDoubleClick(groupId)
  }

  handleClick = (event, groupId) => {
    const selectedIndex = this.state.selected.indexOf(groupId)
    let newSelected = [groupId]

    if (selectedIndex !== -1) {
      newSelected = []
    }
    this.setState({ selected: newSelected })
    this.props.onSelectGroup(groupId)
    this.props.onDoubleClick(groupId)
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value })
    this.setState({ page: 0 })
  }

  getRowData = (baseRow) => {
    const newRow = baseRow
    const x = getDateObj(baseRow, 'created')
    newRow.msForDateCreated = x.getTime()
    return newRow
  }

  render() {
    const isSelected = groupId => this.state.selected.indexOf(groupId) !== -1

    const fullRows = this.props.rowData.map(baseRow => this.getRowData(baseRow))

    return (
      <div style={{ width: '100%', paddingTop: '4px', display: 'flex', flexDirection: 'column', minHeight: '0' }}>
        <div style={{ overflow: 'auto' }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headerCells={headCells}
            />
            <TableBody>
              {stableSort(fullRows, getSorting(this.state.order, this.state.orderBy))
                  .slice(this.state.page * this.state.rowsPerPage, (this.state.page * this.state.rowsPerPage) + this.state.rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.groupId)
                    const labelId = `enhanced-table-checkbox-${index}`
                    const currVal = Object.keys(moduleEnums).find(key => moduleEnums[key] === row.curriculum)
                    if (currVal === undefined) {
                      return null
                    }
                    const curriculumName = this.props.data.codespace[currVal].name
                    const day = new Date(row.msForDateCreated)

                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, row.groupId)}
                      onDoubleClick={event => this.handleDoubleClick(event, row.groupId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.groupId}
                      selected={isItemSelected}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row">{row.groupName}</TableCell>
                      <TableCell align="left">{curriculumName}</TableCell>
                      <TableCell align="left">{row.joinCode}</TableCell>
                      <TableCell align="left">{row.joinActive ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="left">{day.toLocaleDateString('en-US')}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          style={{ fontSize: '14px' }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.props.rowData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{ 'aria-label': 'previous page' }}
          nextIconButtonProps={{ 'aria-label': 'next page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    )
  }
}

export default GroupsTable
export { EnhancedTableHead, stableSort, getSorting }
