
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from 'material-ui/Typography'
import Button from 'material-ui/Button'
import { withStyles } from 'material-ui'
import { data } from '../DataSources'


const styles = () => ({
  linkFontSize: {
    fontSize: 18,
    font: 'inherit',
  },
})

class GroupSelectionBreadcrumbs extends React.PureComponent {
  static propTypes = {
    groupData: PropTypes.instanceOf(Array).isRequired,
    route: PropTypes.string.isRequired,
    updateRoute: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  }

    getClassname = value => this.props.groupData.find(x => x.groupId === value).groupName

    createLink = (value, last, route, index) => {
      let displayName = 'My Classrooms'
      if (value.substr(0, 10) === 'curriculum') {
        const currIdx = parseInt(value.substring(11), 10)
        displayName = data.codespace[currIdx].name
      } else if (value !== 'home') {
        return (
          <Typography style={{ padding: '8px 14px', minWidth: '88px' }} key={index} className={this.props.classes.linkFontSize} color="default">
            {this.getClassname(value)}
          </Typography>
        )
      }
      if (last) {
        return (
          <Typography style={{ padding: '8px 14px', minWidth: '88px' }} key={index} className={this.props.classes.linkFontSize} color="default">
            {displayName}
          </Typography>
        )
      }
      return (
        <Fragment key={index}>
          <Button style={{ textTransform: 'none', color: 'rgba(0, 0, 0, 0.54)' }} key={1} className={this.props.classes.linkFontSize} color="inherit" onClick={() => { this.props.updateRoute(route) }}>
            {displayName}
          </Button>
          <Typography
            style={{
              padding: '8px 0px',
              color: 'rgba(0, 0, 0, 0.54)',
              minWidth: '10px',
              margin: '0',
            }}
            key={0}
            className={this.props.classes.linkFontSize}
          >
            {'>'}
          </Typography>
        </Fragment>
      )
    }

    render() {
      const pathnames = this.props.route.split('/').filter(x => x)

      return (
        <div style={{ display: 'flex' }}>
          {
          pathnames.map((value, index) => {
            const last = index + 1 === pathnames.length
            const fullRoute = `/${pathnames.slice(0, index + 1).join('/')}`
            return this.createLink(value, last, fullRoute, index)
          })
          }
        </div>
      )
    }
}

export default withStyles(styles)(GroupSelectionBreadcrumbs)
