// Provide in-lesson review for additional XP

import React from 'react'
import PropTypes from 'prop-types'
import Dialog, {DialogContent, DialogTitle} from 'material-ui/Dialog'
import Button from 'material-ui/Button'
import Confetti from 'react-confetti'
import {LegendQuiz} from './Legends'

export class MessageDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        keepMounted
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
        {this.props.children}
        </DialogContent>
      </Dialog>
    )
  }
}

/* Check for lesson material comprehension.

    <Quiz
      prompt="What component of the micro:bit does display.show() control?"
      xp={5}
      answerRight={"The LED Display"}
      answerWrong={["The Buttons", "The Radio"]}
    />
*/
export class Quiz extends React.Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    prompt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    xp: PropTypes.number,
    answerRight: PropTypes.string,
    answerWrong: PropTypes.array,
    id: PropTypes.string,           // Required IF prompt is Element type
  }
  static defaultProps = {
    xp: 5,
    answerRight: "",
    answerWrong: [""],
    id: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      showDialog: false,
      xp: this.props.xp,
      disabled: [],
      runConfetti: false,
    }

    this.choice_array = this.props.answerWrong.concat(this.props.answerRight)
    this.shuffle(this.choice_array)

    this.id = this.props.id
    if (!this.id) {
      this.id = this.props.prompt.replace(/[\W]/g, '') + '-id'
    }

    // Dialog content to be set prior to 'showDialog'
    this.dialogTitle = ""
    this.dialogContent = null
  }

  componentDidUpdate = () => {
    let elem = document.getElementById(this.id)
    if (elem) {
      let rect = elem.getBoundingClientRect()
      this.confettiWidth = rect.width
      this.confettiHeight = rect.height
    }
  }

  onWrongAns = (event) => {
    this.setState({disabled: [...this.state.disabled, event.currentTarget.getAttribute('response')]})
    this.setState({xp: this.state.xp - 1})
  }

  onRightAns = () => {
    this.props.lessons.completedQuiz(this.id, this.state.xp)
    this.setState({disabled: this.state.disabled.concat(this.props.answerWrong)})
    this.setState({runConfetti: true})
  }

  shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  roundButton = (text, color, onClick, disabled, response, completed) => (
    <Button
      color={color}
      onClick={onClick}
      disabled={disabled}
      response={response}
      key={response}
      style={disabled && !completed ?
             {transition: 'text-decoration 500ms', textTransform:'none', textDecoration: 'line-through', textDecorationColor:'red', textAlign:'left'} :
             {transition: 'text-decoration 500ms', textTransform:'none', textAlign:'left'}
            }
    >
      {text}
    </Button>
  )

  render() {
    let choices = this.choice_array.map((response, index) => {
      let label = String.fromCharCode('A'.charCodeAt(0)+index)
      let disabled = false
      let callback = null
      let completed = false

      if (this.props.lessons.isQuizComplete(this.id)) {
        completed = true
        if (response === this.props.answerRight) {
          label = '✓'
        } else {
          label = '✗'
          disabled = true
        }
      } else {
        disabled = this.state.disabled.includes(response)
        if (disabled) {
          label = '✗'
        }
        if (response === this.props.answerRight) {
          callback = this.onRightAns
        } else {
          callback = this.onWrongAns
        }
      }

      return (<div
                style={{flex: '0 1 auto'}}
                key={index}
              >
                {this.roundButton(label + '.  ' + response, 'primary',
                 callback, disabled, response, completed)
                }
              </div>)
    })

    let confetti = this.state.runConfetti ? <Confetti
      width={this.confettiWidth || 200}
      height={this.confettiHeight || 50}
      confettiSource={{x: 0, y: 0, w: this.confettiWidth || 200, h: 0}}
      run={true}
      recycle={false}
      gravity={0.2}
    /> : null

    return (
      <LegendQuiz
        title={null}
        id={this.id}
        quizname={this.id}
      >
        {confetti}
        <div>
          <b style={{float:'right'}}>{`+${this.state.xp} XP`}</b>
          <b>Test Your Knowledge</b>
        </div>
        {typeof(this.props.prompt) === 'string' && <br />}
        <div>
          {this.props.prompt}
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {choices}
        </div>
      </LegendQuiz>
    )
  }
}

export default Quiz
