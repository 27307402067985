import React, { Component } from 'react'
import PropTypes from 'prop-types'
import licensor from './CodeSpaceLicensor'
import TextField from 'material-ui/TextField'
import Button from 'material-ui/Button'
import { CircularProgress } from 'material-ui/Progress'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog'
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'material-ui/Table'
import Divider from 'material-ui/Divider'
import { withStyles } from 'material-ui/styles'
import CheckIcon from 'material-ui-icons/Done'
import { moduleDescription, moduleIndex, moduleList } from './lessons/CurriculumModules'
import Joyride from './lessons/cbl-joyride'
import Markdown from './lessons/cbl-remarkable'
import HelpCircle from 'material-ui-icons/Help'

export const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#673ab7',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
  },
  row: {
    height: 24,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowHeader: {
    height: 24,
  }

});

class ProductTable extends React.Component {
  static propTypes = {
    productList: PropTypes.any.isRequired,
    currentProductSeat: PropTypes.string.isRequired,
    onSelectRow: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedRowProductName: '',
    }
  }

  handleRowClick = (productName) => {
    // Toggle selected product on row click
    if (productName === this.state.selectedRowProductName) {
      productName = ''
    }
    this.setState({selectedRowProductName: productName})
    this.props.onSelectRow(productName)
  }

  render() {
    const {classes, productList, currentProductSeat} = this.props

    return (
    <Table id="curriculum-select-table" className={classes.table}>
      <TableHead>
        <TableRow className={classes.rowHeader} >
          <CustomTableCell>Active</CustomTableCell>
          <CustomTableCell>Module</CustomTableCell>
          <CustomTableCell>Seats Available / Licensed</CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{cursor:'pointer'}}>
        {productList.map(val => (
          <TableRow
            style={this.state.selectedRowProductName === val.product ? {backgroundColor:'gold'} : {} }
            className={classes.row}
            key={val.product}
            onClick={ () => this.handleRowClick(val.product)}
          >
            <CustomTableCell>
              <CheckIcon
                style={{fill:'green', width:20, visibility: (val.product === currentProductSeat) ? 'visible' : 'hidden' }}
              />
            </CustomTableCell>
            <CustomTableCell>
              {moduleDescription[moduleIndex.get(val.product)].name}
            </CustomTableCell>
            <CustomTableCell>
              {val.seatsAvailable >= 0 ? `${val.seatsAvailable} / ${val.seatsLicensed}` : (<div style={{fontSize:'22px', lineHeight:'14px'}}>&infin;</div>)}
            </CustomTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    )
  }
}
const StyledProductTable = withStyles(styles)(ProductTable)


class EnterShareTokenDialog extends React.Component {
  static propTypes = {
    open : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      addingFailed: false,
      failText: '',
      isAdding: false,
      dialogValue: ''
    }
  }

  requestClose = () => {
    this.setState({
      dialogValue: '',
      isAdding: false,
      addingFailed: false,
      failText: '',
    })
    this.props.onClose()
  }

  handleLineChange = (e) => {
    let line = e.target.value
    // Remove whitespace
    //line = line.trim()
    this.setState({
      dialogValue: line,
      addingFailed: false,
    })
  }

  onValidationFailed = (reason) => {
    this.setState({
      addingFailed: true,
      failText: reason,
      isAdding: false,
    })
  }

  doEnterShareToken = async () => {
    //console.log("Enter Share Token: ", this.state.dialogValue)

    const newToken = this.state.dialogValue.toLowerCase().replace(/\s/, '')
    if (!newToken) {
      this.onValidationFailed('Empty token not allowed')
      return
    }

    this.setState({
      isAdding: true,
    })

    let added = []
    let reason = 'Token lookup failed'
    try {
      added = await licensor.listSeats(newToken)
    }
    catch (err) {
      console.error("ProductSelect: listSeats.", err)
      reason = `Network error: ${err}`
    }

    if (added.length > 0) {
      this.requestClose()
    } else {
      this.onValidationFailed(reason)
    }
  }

  render() {
    return (
    <Dialog
      open={this.props.open}
    >
    <DialogTitle>Enter Share Token</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        fullWidth
        label="Share Token"
        style={{ marginTop: '1em' }}
        error={this.state.addingFailed}
        helperText={this.state.addingFailed ? this.state.failText : null}
        disabled={this.state.isAdding}
        value={this.state.dialogValue}
        onChange={this.handleLineChange}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            this.doEnterShareToken()
            ev.preventDefault()
          }
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button
        onClick={this.doEnterShareToken}
        color="primary"
        disabled={this.state.isAdding}
        variant='raised'
      >
        {this.state.isAdding ? (
          <div style={{ display: 'flex', height: '1.4em' }}>
            <CircularProgress
              style={{
                margin: 'auto',
                width: 20,
                height: 20,
                color: 'green',
              }}
            />
          </div>
        ) : 'Validate'}
      </Button>
      <Button
        onClick={this.requestClose}
        disabled={this.state.isAdding}
        variant='raised'
      >
        Close
      </Button>
    </DialogActions>
    </Dialog>
    )
  }
}

// Licensing Tours
const tourLicensingVirgin = [
  {
    title: "Let's get you activated!",
    text: (
     <Markdown>
{`First you need a **Share Token**.
* That's a *two-word phrase* generated by a *license holder*, usually a **teacher**.
`}
     </Markdown>
    ),
    selector: '#share-token-status',
    position: 'left',
  },
  {
    title: 'Enter your Token',
    text: (
      <Markdown>
{`Are you a ***student?***
* You'll click here to enter the *Share Token* provided by your teacher.
* That will unlock **Seats Available** in the table above!
> *...Then you'll need to ***select and ACTIVATE*** the course in the table.*
`}
      </Markdown>
    ),
    selector: '#enter-share-btn',
    position: 'bottom-right',
  },
  {
    title: 'Adding a New License?',
    text: (
      <Markdown>
{`If you are a ***teacher*** or ***independent*** user of CodeSpace:
* When you purchase a *kit* or *curriculum module* you'll receive an **email** from *Firia Labs*, containing a **License Key**.
*Email **support@firialabs.com** if you need help finding your key.*
* You need to add the **License Key** to your account in CodeSpace!
* This is also where you *generate* a *Share Token* for your students.

Click here if you need to *add a new **License Key***.
* *After that, come back and enter your new Share Token as shown in the previous step!*
`}
      </Markdown>
    ),
    selector: '#manage-licenses-btn',
    position: 'bottom-left',
  },

]

const tourLicensingWithToken = [
  {
    title: "Choose a Course!",
    text: (
     <Markdown>
{`This table shows the available *Curriculum Modules*.
* The **Seats Available** count is based on your *Share Token*.

Click on a **Row** of this table to select a course **module**.
`}
     </Markdown>
    ),
    selector: '#curriculum-select-table',
    position: 'left',
    allowClicksThruHole: true,
  },
  {
    title: 'Activate the Curriculum',
    text: (
      <Markdown>
{`Click here to **activate** the selected *Curriculum Module*.
`}
      </Markdown>
    ),
    selector: '#activate-btn',
    position: 'right',
    allowClicksThruHole: true,
  },
]


/* Product Select Dialog:
   Initially show table of all possible modules, with zero seats.
   Query licensor.listSeats() and fill in the available seats under current Share Token.
*/
class ProductSelectDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedProductName: '',
      isChangeRequestPending: false,
      productList: licensor.allModules,
      currentProductSeat: licensor.productSeat,
      openEnterShareTokenDialog: false,
      runTour: false,
      tourKey: 0,
    }
  }

  componentDidMount() {
    // Note: the initial 'listSeats()' happens when CodeSpaceLicensor gets initial notification of userDoc contents (including shareToken)
    licensor.registerOnChangeCallback(this.onLicenseChange)
  }

  componentWillUnmount() {
    licensor.unregisterOnChangeCallback(this.onLicenseChange)
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      if (nextProps.open) {
        // Dialog Open actions
        await licensor.listSeats()  // invokes onLicenseChange() callback
      }
    }
  }

  onLicenseChange = () => {
    //console.log(`ProductSelect:onLicenseChange productList=`, licensor.allModules)
    this.setState({
      productList: licensor.allModules,
      currentProductSeat: licensor.productSeat,
    })
  }

  changeProduct = async () => {
    this.setState({isChangeRequestPending: true})

    let gotSeat = false
    try {
      // Request seat of selected product
      gotSeat = await licensor.takeSeat(this.state.selectedProductName)
      // Licensor takes care of switching the Lesson module.
      // Refresh productList to reflect new seat count
      await licensor.listSeats()

    } catch (err) {
      console.error("ProductSelect: changeProduct take/list seats.", err)
    }

    if (gotSeat) {
      this.requestClose()
    } else {
      // Alert user that seat was not acquired
      // TODO: toast? For now they just see that available seats went to zero...
    }
    this.setState({isChangeRequestPending: false})
  }

  enterShareToken = () => {
    this.setState({openEnterShareTokenDialog: true})
  }

  closeEnterShareTokenDialog = () => {
    this.setState({openEnterShareTokenDialog: false})
    if (licensor.isActivated) {
      this.handleRunTour()
    }
  }

  requestClose = () => {
    this.setState({runTour:false})
    this.props.onClose(false)
  }

  doManageLicenses = () => {
    this.props.onClose(true)
  }

  handleSelectRow = (product) => {
    this.setState({selectedProductName: product})
  }

  hasSeatsAvailable = (product) => {
    const prod = this.state.productList.find((val) => {
      return val.product === product
    })
    return prod ? prod.seatsAvailable !== 0 : false
  }

  handleRunTour = () => {
    this.tour.reset(true)
    this.setState({runTour: true, tourKey: this.state.tourKey + 1})
  }

  render() {
    const selectedModuleIndex = moduleIndex.get(this.state.selectedProductName)
    const selectedDescriptor = selectedModuleIndex === undefined ? null : moduleDescription[selectedModuleIndex]

    return (
      <div>
        <Joyride
          key={this.state.tourKey}
          ref2={inst => {this.tour = inst}}
          steps={licensor.isActivated ? tourLicensingWithToken : tourLicensingVirgin}
          run={this.state.runTour}
        />

        <Dialog
          open={this.props.open}
          maxWidth={'md'}
          fullWidth={true}
          scroll={'paper'}
        >
          <DialogTitle>Curriculum Module Selection</DialogTitle>
          {licensor.isActivated ? ( licensor.productSeat.substring(0,4) !== 'Free' ? null :
              <DialogActions style={{justifyContent:'center'}}>
              <span style={{color:'red'}}>You're on a Free Trial... Click here to &rarr;</span>
              <Button
                onClick={this.handleRunTour}
                variant='raised'
                color='primary'
              >
                Choose Curriculum Module
              </Button>
            </DialogActions>
           )
           :
              <DialogActions style={{justifyContent:'center'}}>
                <span style={{color:'red'}}>You're on a Free Trial... Click here to enable all content &rarr;</span>
                <Button
                  onClick={this.handleRunTour}
                  variant='raised'
                  color='primary'
                >
                  Activate your License!
                </Button>
              </DialogActions>
            }

          <DialogContent style={{overflow:'hidden', display:'flex', flexDirection:'column', minHeight:200}}>
            <DialogContentText>
              <b>Click a row</b> in this table and press <b>ACTIVATE COURSE</b> to select a different curriculum module.<br />
              <em>Only one can be active, but your progress is saved when switching between modules.</em>
            </DialogContentText>
            <div style={{overflowY:'auto', minHeight:'5em'}}>
              <StyledProductTable
                productList={this.state.productList}
                currentProductSeat={this.state.currentProductSeat}
                onSelectRow={this.handleSelectRow}
              />
            </div>
          </DialogContent>
          <DialogContent  style={{overflowX:'hidden', minHeight:64}}>
            <DialogActions style={{float:'right'}}>
              <Button
                id="activate-btn"
                onClick={this.changeProduct}
                color="primary"
                disabled={!this.state.selectedProductName ||
                          this.state.selectedProductName === licensor.productSeat ||
                          !this.hasSeatsAvailable(this.state.selectedProductName) ||
                          !(this.state.selectedProductName in moduleList)
                        }
                variant='raised'
              >
                {this.state.isChangeRequestPending ? (
                  <div style={{ display: 'flex', height: '1.4em' }}>
                    <CircularProgress
                      style={{
                        margin: 'auto',
                        width: 20,
                        height: 20,
                        color: 'green',
                      }}
                    />
                  </div>
                ) : 'Activate Course'}
              </Button>
            </DialogActions>

            {selectedDescriptor ?
              <DialogContentText>
                <img src={selectedDescriptor.icon} alt=""
                    style={{
                      height:64, margin: 'auto', display:'block', float:'left', marginRight:10, marginLeft: 10
                    }}
                />
                <span style={{margin:2, fontStyle:'italic', fontWeight:'bold'}}>{selectedDescriptor.name}</span>
                <br />
                {selectedDescriptor.description}
                <em><a style={{marginLeft:5}} href={selectedDescriptor.url} target="_blank" rel="noopener noreferrer">(more...)</a></em>
              </DialogContentText>
              : null
            }
            </DialogContent>

          <Divider style={{margin:10}} />

          <DialogContent style={{paddingBottom: 0, minHeight:50}}>
            <EnterShareTokenDialog
              open={this.state.openEnterShareTokenDialog}
              onClose={this.closeEnterShareTokenDialog}
            />
              <DialogContentText id="share-token-status">
                  A <b>Share Token</b> unlocks access to curriculum module <em>seats</em>.<br />
                  {licensor.isActivated ? <span>Your current Share Token is <b>"{`${licensor.lastUsedActivationCode}`}"</b></span> :
                                          <span style={{color:'red'}}>You don't currently have an active Share Token.</span> }
              </DialogContentText>
          </DialogContent>
          <DialogActions style={{marginLeft:20, justifyContent:'left'}}>
            <Button
              id = "enter-share-btn"
              onClick={this.enterShareToken}
              color={licensor.isActivated ? "default" : "primary"}
              variant='raised'
            >
              {licensor.isActivated ? "Change Share Token" : "Enter Share Token"}
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={this.handleRunTour}
            >
              <span>Help</span>
              <HelpCircle style={{marginLeft:4, color:'#673ab7'}} />
            </Button>
            <Button
              id="manage-licenses-btn"
              style={{marginRight:20}}
              onClick={this.doManageLicenses}
              disabled={this.state.isAdding}
              variant='raised'
            >
              Manage Licenses...
            </Button>
            <Button
              onClick={this.requestClose}
              disabled={this.state.isAdding}
              variant='raised'
            >
              Close
            </Button>
          </DialogActions>

        </Dialog>
      </div>
    )
  }
}

export default ProductSelectDialog