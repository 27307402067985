// A free-tier lesson Module plugin for CodeSpace

import React from 'react'
import CodingModuleBase from '../CodingModuleBase'
import {jumpStartIntro} from './JumpstartIntro'
import {displayGamesFree} from './DisplayGames'
import {finale} from './FreeFinale'

export default function(props) {
  let {ref2, ...other} = props
  return (
  <CodingModuleBase
    {...other}
    ref={ref2}
    projectNames = {[
      'First Steps',
      'Display Games',
      'Keep Coding!',
    ]}
    projects = {[
      jumpStartIntro,
      displayGamesFree,
      finale,
    ]}
  />)
}
