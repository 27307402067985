// HelpTools - Manage the Help / Toolbox content, independent of loaded Lesson Module(s)
import {genHelpObjs} from './GeneralHelpHints'

class HelpTools {
  constructor(props, context) {
    this.genHelpDict = {}
    this.generateHelpDict()
  }

  generateHelpDict = () => {
    for (const prop in genHelpObjs) {
      let obj = genHelpObjs[prop]
      this.genHelpDict[obj.name.toLowerCase()] = obj
      for (const match in obj.matches) {
        let text = obj.matches[match]
        this.genHelpDict[text] = obj
      }
    }
  }

  getHelp = (clickText) => {
    //console.log("Seeking help on ", clickText)
    // Currently we just look in general help dictionary
    const seekText = clickText.toLowerCase()
    if (seekText in this.genHelpDict) {
      return this.genHelpDict[seekText]
    }
    // Check for plural form
    if (seekText[seekText.length - 1] === 's') {
      const singularSeek = seekText.substring(0, seekText.length - 1)
      if (singularSeek in this.genHelpDict) {
        return this.genHelpDict[singularSeek]
      }
    }
    return null
  }

}

const helpTools = new HelpTools()
export default helpTools
