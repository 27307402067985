import firebase, { cblFirebase } from './myfirebase'
import { tracker } from './tracking'
import { moduleEnums, defaultModule, moduleList } from './lessons/CurriculumModules'

// Use 'cloudfunctions.net' URL during dev/debug of cloud functions prior to deploying to firebase hosting at makebit.firialabs.com
// const BASE_LICENSOR_URL = 'https://us-central1-make-firialabs.cloudfunctions.net'
const BASE_LICENSOR_URL = 'https://makebit.firialabs.com'

const ACTIVITY_REFRESH_INTERVAL = 50 * 1000


class CodeSpaceLicensor {
  constructor() {
    cblFirebase.registerUserDocChangesCallback(this.onUserDocChange)
    cblFirebase.registerFirebasAuthChangeCallback(this.onAuthChange)
    cblFirebase.registerDocRetrievedCallback(this.onUserDocRetrieved)

    this.openOnErrors = true  // Allow access to unlimited license seats if Cloud Functions error-out.

    this.activityRefreshStarted = false
    this.lastUsedActivationCode = null
    this.lastGenActivationCode = []
    this.licenseKeys = []

    this.initialLoadComplete = false   // Set true when license info has been loaded
    this.licenses = {}
    this.isActivated = false   // Simply means we have a valid Share Token
    this.productSeat = defaultModule     // Name of product we're consuming a seat of, if any.
    this.lastFetchJson = {}
    this.lastFetchError = ''
    this.userWasSignedIn = true  // Init to 'true' for proper detection of login event on page load/reload

    this.onChangeCallbacks = new Set()

    // Sort free and premium modules
    //   Product names are tracked in CurriculumModules.js as 'moduleEnums'.
    //   Free modules' moduleEnums all start with "Free".
    this.freeModules = []
    this.premiumModules = []
    Object.values(moduleEnums).forEach((name) => {
      if (name.substring(0,4) === 'Free') {
        this.freeModules.push({product: name, seatsLicensed: -1, seatsAvailable: -1})  // -1 indicates unlimited seats
      }
      else {
        this.premiumModules.push({product: name, seatsLicensed: 0, seatsAvailable: 0})
      }
    })
    this.allModules = this.freeModules.concat(this.premiumModules)
  }

  registerOnChangeCallback = (cb) => {
    this.onChangeCallbacks.add(cb)
  }

  unregisterOnChangeCallback = (cb) => {
    this.onChangeCallbacks.delete(cb)
  }

  onUserDocChange = async (doc) => {
    const userDocData = doc.data()
    let hasChanges = false

    if (!userDocData) {
      return
    }

    // Start periodic activity check-in if not already running
    if (!this.activityRefreshStarted) {
      this.activityRefreshStarted = true
      this.activityRefresh()
    }

    const lastModule = (userDocData.lastModule && userDocData.lastModule in moduleList) ? userDocData.lastModule : defaultModule

    // On initial load, assume user will successfully take the last seat they occupied.
    // This avoids waiting to get a license seat (Firebase can be slow... several seconds),
    // with the drawback of bouncing them visibly back to default free module if license was lost.
    if (!this.initialLoadComplete) {
      if (lastModule !== this.productSeat) {
        this.changeLoadedModule(lastModule)
      }
      this.initialLoadComplete = true
      this.hasChanges = true
    }

    // Has share token changed?
    if (this.lastUsedActivationCode !== userDocData.lastUsedActivationCode) {
      this.lastUsedActivationCode = userDocData.lastUsedActivationCode

      try {
        // Get the list of licenses available under the new shareToken
        await this.listSeats()

        // Try to acquire seat of last used module
        await this.takeSeat(lastModule)  // Will set this.productSeat if successful

      } catch (err) {
        console.error("CodeSpaceLicensor list/take seats", err)

        // If we have a cloud function outage, open up access
        if (this.openOnErrors) {
            console.log("Open Access due to cloud function errors")
            const prem = this.premiumModules.map((mod) => {
                mod.seatsAvailable = -1
                return mod
            })
            this.allModules = this.freeModules.concat(prem)
            this.isActivated = true
            this.changeLoadedModule(lastModule)
        }
      }

      // On first load, we can now display curriculum (Above list/take may take several seconds to complete!).
      // Note: currently we're short-circuiting this (see above) to optimize common case where license is available.
      this.initialLoadComplete = true

      hasChanges = true
    }

    // License keys added/removed?
    if (userDocData.licenseKeys && (this.licenseKeys.length !== userDocData.licenseKeys.length)) {
      this.licenseKeys = userDocData.licenseKeys
      await this.refreshUserLicenses()
      hasChanges = true
    }

    // Generated share token change?
    if (userDocData.lastGenActivationCode && (this.lastGenActivationCode.toString() !== userDocData.lastGenActivationCode.toString())) {
      this.lastGenActivationCode = userDocData.lastGenActivationCode
      hasChanges = true
    }

    if (hasChanges) this.notifyAboutChange()
  }

  onUserDocRetrieved = async (doc) => {
    //console.log("userDocRetrieved: ", doc)
    this.onUserDocChange(doc)
  }

  onAuthChange = async (isSignedIn) => {
    //console.log("onAuthChange isSignedIn=", isSignedIn)
    if (isSignedIn && !this.userWasSignedIn) {
      //console.log("New sign-in... loading license.")
      this.lastUsedActivationCode = null  // force next onUserDocRetrieved to reload seat
      this.licenseKeys = []
    }

    this.userWasSignedIn = isSignedIn
  }

  // Fetch all the License docs for this user's keys
  refreshUserLicenses = async () => {
    try {
      this.licenses = {}
      if (this.licenseKeys) {
        const licenseDocRefs = await Promise.all(this.licenseKeys.map(key => cblFirebase.db.collection('licenses').doc(key).get()))
        this.licenses = licenseDocRefs.map(docRef => docRef.data())
        this.notifyAboutChange()
      }
    } catch (err) {
      tracker.addBreadcrumb('An error occurred while retrieving user licenses', 'licensor', 'error', err)
    }

    return this.licenses
  }

  parseJsonError = () => {
    let error = ''
    let reason = ''
    const json = this.lastFetchJson
    if (json.hasOwnProperty('error')) {
      reason = json.error.errors[0].reason
      if (reason === 'doesNotExist') {
        error = 'Share Token does not exist.'
      } else if (reason === 'noLongerValid') {
        error = 'Share Token is no longer valid.'
      }
    }
    if (this.lastFetchError !== error) {
      this.lastFetchError = error
    }
    return reason
  }

  activityRefresh = async () => {
    try {
      if (cblFirebase.userDoc) {
        await cblFirebase.userDoc.ref.update({lastActivity: firebase.firestore.FieldValue.serverTimestamp()})
      }
    } catch (err) {
      tracker.addBreadcrumb('Error in activityRefresh', 'licensor', 'error', err)
    }

    // Re-schedule periodically
    setTimeout(this.activityRefresh, ACTIVITY_REFRESH_INTERVAL)
  }

  notifyAboutChange = () => {
    try {
      this.onChangeCallbacks.forEach((cb) => {
        cb()
      })
    } catch (err) {
      tracker.addBreadcrumb('An error occurred while notifying about license changes', 'licensor', 'error', err)
    }
  }

  post = async (url, data) => {
    //console.log(`post: url=${url}, data=`,data)
    return await fetch( url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  }

  // Trigger UI to change user's current module view in Lesson panel.
  changeLoadedModule = (moduleName) => {
    //console.log(`Change module to ${moduleName}`)
    this.productSeat = moduleName
    this.notifyAboutChange()
  }

  // Try to get a seat of the given product. Return true if successful.
  takeSeat = async (productName) => {
    if (!cblFirebase.userDoc) {
      return false
    }

    // Prepare to take seat by getting fresh list of available seats
    //await this.listSeats()
    await this.activityRefresh()  // Ensure we won't be bumped for inactivity whilst taking a seat.

    for (const mod of this.allModules) {
      if (mod.product === productName) {
        if (mod.seatsAvailable < 0) {
          // Free module
          // Update DB reference to current seat, which also vacates licensed product seat user may be on.
          await cblFirebase.userDoc.ref.update({productSeat:productName, lastModule:productName, seatLicenseHash:'free'})
          this.changeLoadedModule(productName)
          return true
        }
        else {
          // Attempt to get a seat. Even if mod.seatsAvailable == 0, we try since it could be that this user already
          // occupies one of those seats, and just reloaded the browser window.
          try {
            const reqBody = {
              uid: cblFirebase.userDoc.id,
              shareToken: this.lastUsedActivationCode,
              productName: productName
            }
            const response = await this.post(`${BASE_LICENSOR_URL}/takeSeat`, reqBody)
            //console.log("takeSeat response: ", response)

            if (response.ok && response.status === 200) {
              this.changeLoadedModule(productName)
              return true
            }

          } catch (err) {
            tracker.addBreadcrumb('Error calling /takeSeat', 'licensor', 'error', err)
          }
        }

        console.log(`CodeSpaceLicensor: failed to takeSeat(${productName}) - no seats available`)

        // Was user trying to re-acquire the seat they're currently on?
        // (happens on logout/login scenario, when shareToken invalidated)
        if (productName === this.productSeat) {
          console.log(`CodeSpaceLicensor: lost seat on module ${productName}`)
          this.changeLoadedModule(defaultModule)
        }
        return false
      }
    }

    console.log(`CodeSpaceLicensor: failed to takeSeat(${productName}) - no such module`)
    return false
  }

  // Return a list of products available under the current or provided Share Token.
  listSeats = async (newShareToken = undefined) => {
    if (!cblFirebase.userDoc) {
      return []
    }

    // Go fetch licensed modules under this Share Token
    let licensedModules = []
    try {
      const shareToken = newShareToken ? newShareToken : this.lastUsedActivationCode
      if (shareToken) {
        const reqBody = {
          shareToken: shareToken,
          uid: cblFirebase.userDoc.id,
        }
        const response = await this.post(`${BASE_LICENSOR_URL}/listSeats`, reqBody)
        const headers = response.headers.get('content-type')
        if (headers && headers.includes('application/json')) {
          this.lastFetchJson = await response.json()
        } else {
          this.lastFetchJson = {}
        }

        if (response.ok && response.status === 200) {
          //console.log("listSeats success:", this.lastFetchJson)
          licensedModules = this.lastFetchJson

          if (newShareToken) {
            if (licensedModules.length > 0) {
              //console.log("listSeats: Saving token to user lastUsedActivationCode")
              // User-supplied token is valid - save to DB as the last used token!
              await cblFirebase.userDoc.ref.update({lastUsedActivationCode: newShareToken})
              this.lastUsedActivationCode = newShareToken
            }
            else {
              // Reject user-supplied token
              return []
            }
          }
        }
        else {
          console.log("listSeats error:", this.lastFetchJson)
          return []
        }
      }
    } catch (err) {
      tracker.addBreadcrumb('Error calling /listSeats', 'licensor', 'error', err)
      return []
    }

    // This function returns ALL modules: Licensed, Unlicensed (0-seat), and Free
    this.allModules = []
    this.premiumModules.forEach((noLicense) => {
      let gotLicense = licensedModules.find((licVal) => {
        return (licVal.product === noLicense.product)
      })
      this.allModules.push(gotLicense ? gotLicense : noLicense)
    })

    this.allModules = this.allModules.concat(this.freeModules)

    // Set "isActivated" if there are ANY premium modules granted by this shareToken
    this.isActivated = licensedModules.length > 0
    this.notifyAboutChange()

    return this.allModules
  }

  removeLicense = async (licenseKey) => {
  try {
      const response = await this.post(`${BASE_LICENSOR_URL}/removeLicense`, {
        uid: cblFirebase.userDoc.id,
        licenseKey,
      })
      return response.ok && response.status === 200
    } catch (err) {
      tracker.addBreadcrumb('Error calling /removeLicense', 'licensor', 'error', err)
      return false
    }
  }

  addLicense = async (licenseKey) => {
    try {
      const response = await this.post(`${BASE_LICENSOR_URL}/addLicense`, {
        uid: cblFirebase.userDoc.id,
        licenseKey,
      })
      return response.ok && response.status === 200
    } catch (err) {
      tracker.addBreadcrumb('Error calling /addLicense', 'licensor', 'error', err)
      return false
    }
  }

  generateShareToken = async () => {
    try {
      const response = await this.post(`${BASE_LICENSOR_URL}/genCode`, {
        uid: cblFirebase.userDoc.id,
      })

      if (response.ok && response.status === 200) {
        const responseData = await response.json()
        this.lastGenActivationCode = responseData.activationCodeWords
        this.notifyAboutChange()
        return true
      }
    } catch (err) {
      tracker.addBreadcrumb('Error calling /generateShareToken', 'licensor', 'error', err)
    }

    return false
  }
}

export const licensor = new CodeSpaceLicensor()

export default licensor

export { BASE_LICENSOR_URL }
