// Project: FreeFinale - not really a project, but an "End of the Line" notice AND Teaser for Full version

import React, {Component} from 'react'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import SoundWaves from './assets/SoundWaves.svg'
import TuneClips from './assets/speaker-clips.png'
import Spinner from './assets/spinner.png'
import Level from './assets/Level.png'
import Photocell from './assets/photocell-conn.jpg'
import StandingBits from './assets/StandingBits.gif'

class FreeFinale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Keep Coding! {lesson-title}
___
***Thank You** for using CodeSpace! We hope you've enjoyed this sample of the **JumpStart Python** curriculum!*
{centered}

### Continue Your Coding Journey
* **Explore and *Build*** - CodeSpace is a full-strength software development platform.
  * That means the sample projects are just the beginning - you can build *whatever you dream up*!
* Share your creations with us on your favorite social media ***@FIRIALABS!***
### Upgrade to the full <span style="font-family:'impact'">CodeSpace </span>*<a href="https://firialabs.com/collections/software" target="_blank"  rel="noopener noreferrer">JumpStart Python</a>* curriculum:
* Experience *advanced* **debugging** capability
  * **Step** through your code to understand *exactly* what it's doing.
  * **Visualize** variables and control-flow as your code runs.
* Learn with a **comprehensive** set of *Python tutorial projects*, covering many more **micro:bit** capabilities and **Python** language features.
  * Access a growing set of **bonus projects** *crafted with love* by the team at **Firia Labs**!

**A Sample of the *Amazing Projects* in the Full JumpStart curriculum:**

`}
<img src={TuneClips} alt=""
  style={{width: "20%", margin: 'auto', display:'block', float:'right'}}
/>
{`* Make **Music** with the micro:bit's built-in song collection, *and* **compose** your own melodies!
`}
<img src={SoundWaves} alt=""
  style={{width: "50%", margin: 'auto', display:'block'}}
/>
<div style={{clear:'right'}}></div>
<br />

<div style={{display:'flex'}}>
  <img src={Spinner} alt=""
    style={{flex: 1, height:'20%', width:'20%'}}
  />
  <div style={{flex: 4}}>
    <Markdown>
{`
* Create a working **Game Spinner** to explore **animation** and **random numbers**.
  * Learn about Python *lists*
  * Define your own *functions*
`}
    </Markdown>
  </div>
</div>

<br />
<img src={Level} alt=""
  style={{width: "50%", margin: 'auto', display:'block', float:'right'}}
/>
{`
* Explore the **Accelerometer** by building a real, sensitive **Spirit Level**!
  * Use *pixel functions* to simulate a floating bubble
  * Learn how to *scale* sensor values to *degrees* units
<div style="clear:right"></div>
`}

<br />
<div style={{display:'flex'}}>
  <img src={Photocell} alt=""
    style={{flex: 1, height:'30%', width:'30%'}}
  />
  <div style={{flex: 4}}>
    <Markdown>
{`
* Make a *Night Light* controlled by a *photocell*
  * Understand **analog inputs**
  * Program a preset *threshold*, or *proportional dimming* output
`}
    </Markdown>
  </div>
</div>

<br />
<img src={StandingBits} alt=""
  style={{width: "50%", margin: 'auto', display:'block', float:'right'}}
/>
{`
* Get **connected with the IoT** *(Internet of Things)* using the micro:bit **radio**
  * Send **remote sensor** readings: light, temperature, digital on/off.
  * Build your own *radio remote controller* and *remote monitor* devices.
<div style="clear:right"></div>

### ...And MANY more engaging, *real-world* projects
* In-depth **Python programming** instruction.
* Real projects, with *unlimited* applications.
* Powerful **debugging** tools to tackle complex code!

**What are you waiting for?** Level up to the full <a href="https://firialabs.com/collections/software" target="_blank"  rel="noopener noreferrer">JumpStart Curriculum</a> now!
{centered}
`}

</Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)

FreeFinale.stepId = 'FreeFinale'

export const finale = [
  FreeFinale,
]
