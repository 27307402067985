// Project: Game Spinner

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  // LegendMessageWarning,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
// import Button from 'material-ui/Button'
import 'react-html5video/dist/styles.css'
import heroImg from './assets/spinner.png'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'

export const GameSpinnerImg = heroImg


class GameSpinnerIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Game Spinner {lesson-title}
___
`}
<img src={heroImg} alt=""
    style={{
      width: 200, margin: 'auto', display:'block',
    }}
/>
{`#### In this project, you’ll make a Game Spinner that can:
* **Choose** the next person to tell a story in a group of friends
* **Navigate** every turn in a Crazy Compass game
* **Decide** which pizza slice to eat next
* Provide an element for a game **you create**!

Your game spinner will show a spinning arrow on the LED display when you press Button A or B, and then slow down and stop in one of 8 random directions.

**Project Goals:**
* Display an *Arrow* in a random direction
* Detect button A **or** B to trigger the *Arrow spin*
* **Animate** an Arrow *spinning around*
* Make the Arrow *gradually slow* rather than stopping abruptly
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class RandomArrow extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Random Arrow {lesson-title}
___
The microbit has a set of "Compass Arrow" Images that are perfect for your spinner. There’s even a built-in list with **ALL** the **ARROWS**:
\`\`\`
# This list is ALREADY provided!
# (DO NOT TYPE THIS IN!)
Image.ALL_ARROWS = [
  Image.ARROW_N,
  Image.ARROW_NE,
  Image.ARROW_E,
  Image.ARROW_SE,
  Image.ARROW_S,
  Image.ARROW_SW,
  Image.ARROW_W,
  Image.ARROW_NW
]
\`\`\`
Displaying an ARROW from the ==list== is simple.
> There are 8 arrows, so the list *index* goes from 0 to 7:
\`\`\`
display.show(Image.ALL_ARROWS[num])
\`\`\`
Your Game Spinner needs to land on a *random* direction
> Use the ==random== module to choose which ARROW to display. The following sets **num** to a random number:
\`\`\`
num = random.randrange(8)
\`\`\`
`}
<LegendMessageKeyboard>
{`* Create a new file and name it **Game_Spinner**
* Write a program that displays a random arrow.
\`\`\`
from microbit import *
import random

num = random.randrange(8)
display.show(Image.ALL_ARROWS[num])
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What are the possible values of \`num\` ?
`}
  </Markdown>
  )}
  id={"More values for range"}
  xp={5}
  answerRight={"0,1,2,3,4,5,6,7"}
  answerWrong={["1,2,3,4,5,6,7,8", "-3,-2,-1,0,1,2,3,4"]}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Which image is displayed by: \`display.show(Image.ALL_ARROWS[3])\` ?
`}
  </Markdown>
  )}
  id={"Index an Array"}
  xp={5}
  answerRight={"Image.ARROW_SE"}
  answerWrong={["Image.ARROW_E", "Image.ARROW_S", "Image.ARROW_SW"]}
/>
<StepComplete
  prompt="Does the LED display show a random arrow direction?"
  xp={25}
  successMessage=
{`## I think there's an Arrow in your program. {centered}
------------------------------>  X 'ouch!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'random.randrange']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class ClickFlick extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Click to Flick {lesson-title}
___
The classic Game Spinner has a metal arrow that you finger-flick to spin.
* Make *your* micro:bit **spin** whenever Button **A** or **B** is pressed.

The program should run forever, so wrap your code in an infinite loop, checking *both* buttons.

> *There are two new concepts that will help with this task!*
`}
<LegendMessageConcept title="Concept: Instantaneous Button Polling">
{`The function \`button_a.is_pressed()\` returns \`True\` if button **A** is **currently** held down.

> *Your code can quickly check the **state** of the buttons with this function.*

Check out all the button functions here: ==buttons==
___
### Concept: Logical Operators
You've seen how **branching** and **loops** control the flow of your program with **True** / **False** decisions:
* Functions like \`buttton_a.is_pressed()\` that *return* **True** or **False**,
* Comparison operations like \`x > 51\` (which are also **True** or **False**)

What if you have *multiple items* to compare - like **two buttons**, testing if *either one **or** the other* is **True**?
* That's where ==logical operators==: \`and\`, \`or\`, and \`not\` come into play.
`}
</LegendMessageConcept>
{`*It's time to **apply** these new concepts, to make your spinner respond to either button!*
`}
<LegendMessageKeyboard>
{`Modify your code to show a random ARROW **when either button is pressed**.
> Notice code below loops forever, always checking if either button **A or B** *is pressed*.
\`\`\`
from microbit import *
import random

$while True:
$    if button_a.is_pressed() or button_b.is_pressed():
        num = random.randrange(8)
        display.show(Image.ALL_ARROWS[num])
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
{`Before you move on, let's talk about all that **indentation**!
`}
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why is the \`if\` statement **indented** beneath the \`while\` ?
`}
  </Markdown>
  )}
  id={"Indenting beneath while"}
  xp={5}
  answerRight={"So that it runs completely inside the loop."}
  answerWrong={["Because 'if' statements have to be indented.", "So that the arrow is only displayed when a button is pressed."]}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why are the \`num = ...\` and \`display.show(...)\` statements **indented** under the \`if\` ?
`}
  </Markdown>
  )}
  id={"Indenting beneath if"}
  xp={5}
  answerRight={"So that they only run if button_a or button_b is pressed."}
  answerWrong={["So that they run completely inside the loop.", "So that 'num' will be a valid index into the ALL_ARROWS list."]}
/>
<StepComplete
  prompt="Does your microbit display a random arrow when either button is pressed?"
  xp={25}
  successMessage=
{`## Good Coding! {centered}
### Checking the *instantaneous* button state is SO *snappy*!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.show', 'button_a.is_pressed', 'button_b.is_pressed', 'random.randrange']}
  reqStatements={['while', 'if', 'or']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class FunctionsIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Fun Functions {lesson-title}
___
Your program does the job, but you can **improve** it with *realism*!
> *Realistic* spinning action would be awesome!

Next step will be to add **animation** – to make the arrow spin around before it lands on the random choice!

So the program will be like this:  *(example - don't type this in)*
\`\`\`
while True:
    if button_a.is_pressed() or button_b.is_pressed():
        # animate arrow spinning around
        # ...

        # show random arrow
        # ...
\`\`\`
**Yikes!** It may take a few lines of code to do the animation, so the above code could get messy.
Too bad there aren’t built-in functions like \`spin_animation()\` and \`show_random_arrow()\`.
> Alas, those functions are not built-in!

But you *can* make your **own** ==functions==!

### Making new functions is like creating your *own* language!
> Ready to try it?
`}
<LegendMessageKeyboard>
{`Change your program to **define** and **call** a \`show_random_arrow()\` function.
1. Move the code *out of* the loop and *into* your new function! (use the ==editor shortcuts== to *cut* and *paste*)
2. Now **call** the new function from inside your loop, using its name: \`show_random_arrow()\`.
\`\`\`
from microbit import *
import random

$$$
$def show_random_arrow():
$    num = random.randrange(8)
$    display.show(Image.ALL_ARROWS[num])

$$$
while True:
    if button_a.is_pressed() or button_b.is_pressed():
$        show_random_arrow()
\`\`\`
The keyword \`def\` means "define function". After the def statement runs, the named function can be *called* just like a built-in function!
* Dividing code into logical functions can make it much more *readable*.

**Note:**

==Functions== must be **defined** *before* they are used, so make sure to put the \`def\` **above** your \`while\` loop!
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<StepComplete
  prompt="Does your micro:bit still show random arrows?"
  xp={75}
  successMessage=
{`## Amazingly Functional! {centered}
You can **def**ine functions to break down a job into easy steps! It's a great tool for ==divide and conquer==!
`}
  reqImports={['microbit', 'random']}
  reqFuncdefs={['show_random_arrow']}
  reqCalls={['display.show', 'button_a.is_pressed', 'button_b.is_pressed', 'random.randrange', 'show_random_arrow']}
  reqStatements={['while', 'if', 'or']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Animation extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Animation {lesson-title}
___
**Animation** is achieved with a rapid sequence of images, and you’ve already seen that the micro:bit is quite good
at displaying a list of images quickly. Sometimes even too quickly! You have to slow it down to see all the images clearly.

To animate a **spinning arrow** you just need to cycle through all 8 positions (0-7), with a short delay between each.
\`\`\`
display.show(Image.ALL_ARROWS[0])
sleep(100)
display.show(Image.ALL_ARROWS[1])
sleep(100)
# ...Wait! There has to be a better way.
\`\`\`
While the above would work, there **is** a better way. A ==loop==!
> But **not** an *infinite* loop. You only need to repeat **8** times.

Study the code below. Do you see how the variable \`index\` starts at 0 and counts up each time the loop repeats?
`}
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What ==condition== **stops** the loop?
`}
  </Markdown>
  )}
  id={"Terminal loop condition"}
  xp={5}
  answerRight={"The loop stops when index reaches 8."}
  answerWrong={["The loop stops when index reaches 0.", "The statement 'index = index + 1' ends the loop.", "An infinite loop never stops."]}
/>
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *
import random

$$$
$def spin_animation():
$    index = 0
$    while index < 8:
$        display.show(Image.ALL_ARROWS[index])
$        sleep(100)
$        index = index + 1

$$$
def show_random_arrow():
    num = random.randrange(8)
    display.show(Image.ALL_ARROWS[num])

while True:
    if button_a.is_pressed() or button_b.is_pressed():
$        spin_animation()
        show_random_arrow()
\`\`\`
Add this new \`spin_animation()\` **function** to your code.
Call the new function from your main loop, right before you call \`show_random_arrow()\`.
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageTry title="Experiment with your animation!">
{`
* What would happen if you changed \`sleep(100)\` to a *smaller* value?
* Could the arrow be made to spin the other direction?
`}
</LegendMessageTry>
<StepComplete
  prompt="Is your Arrow spinning happily?"
  xp={35}
  successMessage=
{`## Dizzyingly Dazzling! {centered}
### Animation puts a new spin on things.
`}
  reqImports={['microbit', 'random']}
  reqFuncdefs={['show_random_arrow', 'spin_animation']}
  reqCalls={['display.show', 'button_a.is_pressed', 'button_b.is_pressed', 'random.randrange', 'show_random_arrow', 'spin_animation']}
  reqStatements={['while', 'if', 'or']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Physics1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const errorRegex = /IndexError:/
    const dialogContent = (
      <Markdown>
{`**Surprise!** There's an *intentional* bug in this code...

Start the program again, this time with the **Step** button.
* Turn on the **Debug Panel** to watch the value of \`index\` change in each *loop*.
* What was the \`index\` value when the error happens?
* Can you see what the problem is?

(The *Next* step in the project will help you fix it!)
`}
      </Markdown>
    )
    this.props.interceptErrorCb([errorRegex, dialogContent, "Index Error"])
  }


  render() {
    return (
      <div>
        <Markdown>
{`## Style Points - Physics part 1 {lesson-title}
___
Your **animation** is nice, but it needs more *realism*! A real *Game Spinner* starts out **fast**, and
**slows down** gradually before it **stops**.
> You *could* measure the weight and friction properties of a real spinner, and code an exact ==simulation==...

For now just use a *rough approximation* of real-world physics – slowing the spin animation *gradually*.
> Your first step is to make the animation spin **longer**.

Right now the Arrow goes through 8 positions. *Can you make it keep going around?*
`}
<LegendMessageKeyboard>
{`Change your \`spin_animation()\` function as follows:
\`\`\`
from microbit import *
import random

def show_random_arrow():
    num = random.randrange(8)
    display.show(Image.ALL_ARROWS[num])

$$$
$def spin_animation(count):
$    index = 0
$    while index < count:
$        display.show(Image.ALL_ARROWS[index])
$        sleep(100)
$        index = index + 1

$$$
while True:
    if button_a.is_pressed() or button_b.is_pressed():
$        spin_animation(15)
        show_random_arrow()

\`\`\`
Instead of **8** you are passing \`count\`, which can be any number of *loops* you want!
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Make sure to change where you call the function: \`spin_animation()\` so it loops more than 8 times!
`}
</LegendMessageRun>
<LegendMessageDebug>
{`Step into the code, and open the **Debug Pane** to inspect your **variables**.

What value does the \`index\` variable have when the error occurs?
`}
</LegendMessageDebug>
{`
`}
<StepComplete
  prompt="Next step is to fix the bug!"
  lessons={this.props.lessons}
  btnNext={true}
/>
        </Markdown>
      </div>
    )
  }
}

class PhysicsFixBug extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Taming the Unruly Index {lesson-title}
___
### Have you found the error?
The list \`Image.ALL_ARROWS\` has just 8 elements, numbered 0 through 7.

When your \`index\` variable reaches **8**, it is *past the end* of the list!

> **How can you keep \`index\` in the range 0-7 ?**

Here's an idea:
* Use *another variable* called \`loops\` to count the *total* number of repeats.
* Keep using \`index\` too, but *reset* it back to **0** when it reaches **8**.
`}
<LegendMessageKeyboard>
{`Change your \`spin_animation()\` function to implement the plan above.
* Add the \`loops\` variable to count the total repeats.
* Each time you increment \`index\`, reset to **0** *if* it hits **8**.

**Note:** Beware of the difference between \`=\` *assignment* and \`==\` ==comparison operations== !
\`\`\`
from microbit import *
import random

def show_random_arrow():
    num = random.randrange(8)
    display.show(Image.ALL_ARROWS[num])

$$$
def spin_animation(count):
    index = 0
$    loops = 0
$    while loops < count:
$       loops = loops + 1
       display.show(Image.ALL_ARROWS[index])
       sleep(100)
       index = index + 1
$       if index == 8:
$           index = 0

$$$
while True:
    if button_a.is_pressed() or button_b.is_pressed():
        spin_animation(10)
        show_random_arrow()

\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Try running your code, and make sure the Arrow spins more than one complete cycle!

**Step** through the code also - **watch \`index\` and \`loops\` variables change...**
`}
</LegendMessageRun>
<StepComplete
  prompt="Is your code running properly now?"
  xp={65}
  successMessage=
{`## Another bug bites the dust! {centered}
Those "one past the end" errors are pretty common in the world of code.

**Nice Work!** {centered}
`}
  reqImports={['microbit', 'random']}
  reqFuncdefs={['show_random_arrow', 'spin_animation']}
  reqCalls={['display.show', 'button_a.is_pressed', 'button_b.is_pressed', 'random.randrange', 'show_random_arrow', 'spin_animation']}
  reqStatements={['while', 'if', 'or']}
  reqParams={["count"]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Physics2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Style Points - Physics part 2 {lesson-title}
___
Now that you can make the animation spin as long as you like, it’s time to make the arrow gradually **slow down**.
> **What controls the speed of your animation now?**

The \`sleep(100)\` needs to change, to create *longer* delays while the loop repeats.
* Right now your code uses the value \`100\`
* Now it needs to use a ==variable==!
`}
<LegendMessageKeyboard>
{`
Add a variable called \`delay\` to your \`spin_animation\` function, and use it to control the ==sleep== time.
\`\`\`
from microbit import *
import random

def show_random_arrow():
    num = random.randrange(8)
    display.show(Image.ALL_ARROWS[num])

$$$
def spin_animation(count):
$    delay = 50
    index = 0
    loops = 0
    while loops < count:
       loops = loops + 1
       display.show(Image.ALL_ARROWS[index])
$       sleep(delay)
$       delay = delay + 5
       index = index + 1
       if index == 8:
           index = 0

while True:
    if button_a.is_pressed() or button_b.is_pressed():
$        spin_animation(30)
        show_random_arrow()
$$$
\`\`\`
* Start the delay at 50
* Add 5 to delay after every ==sleep==
* Increase the number of spins, so you can easily see the effect!
`}
</LegendMessageKeyboard>
{`
`}
<LegendMessageRun></LegendMessageRun>
<LegendMessageDebug>
{`There's a *lot* going on in this program! Step through and **watch the variables** as each animation loop runs.
> Make sure you understand what's happening with the *functions* and *variables* in your program!
`}
</LegendMessageDebug>
<StepComplete
  prompt="Can you see the Arrow sloooow doooown gradually?"
  xp={65}
  successMessage=
{`## Fantastic Friction! {centered}
There are **many more** cool *physical properties* you can simulate in software.
`}
  reqImports={['microbit', 'random']}
  reqFuncdefs={['show_random_arrow', 'spin_animation']}
  reqCalls={['display.show', 'button_a.is_pressed', 'button_b.is_pressed', 'random.randrange', 'show_random_arrow', 'spin_animation']}
  reqStatements={['while', 'if', 'or']}
  reqParams={["count"]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Applications {lesson-title}
___
Take your **Game Spinner** for a Spin!

Besides being a useful tool for random selection, this project gave **you** some great tools for making **much**
more powerful programs!

Breaking your program down into ==functions== allows you to do really complex tasks in software,
while keeping your code readable.

==Divide and Conquer==!
{centered}

Fast button inputs, animation, and simulation! **That’s how you code:**
* Video games
* Flight Simulators
* Virtual Reality
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Change the **direction** the Arrow spins: go *counter-clockwise*!
  * Will you do this by counting the \`index\` backwards, **7** to **0**?
  * **or** will you make a new *list* of Arrows instead of using the built-in ALL_ARROWS?
* Make the *spin direction* depend on which button is pressed!
* Add sound - maybe a click or beep each time the Arrow moves.
  * *Check out the ==music== \`pitch()\` function.*
`}
</LegendMessageTry>
<StepComplete
  prompt="Excellent work!! Ready for more?"
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>
        </Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
GameSpinnerIntro.stepId = 'GameSpinnerIntro'
RandomArrow.stepId = 'RandomArrow'
ClickFlick.stepId = 'ClickFlick'
FunctionsIntro.stepId = 'FunctionsIntro'
Animation.stepId = 'Animation'
Physics1.stepId = 'Physics1'
PhysicsFixBug.stepId = 'PhysicsFixBug'
Physics2.stepId = 'Physics2'
Applications.stepId = 'Applications'

export const gameSpinner = [
  GameSpinnerIntro,
  RandomArrow,
  ClickFlick,
  FunctionsIntro,
  Animation,
  Physics1,
  PhysicsFixBug,
  Physics2,
  Applications,
]
