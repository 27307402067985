// Project: Music

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  //LegendMessageTry,
  LegendMessageWarning,
  LegendMessageInteract,
  //LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import SoundWaves from './assets/SoundWaves.svg'
import SpectrumMusic from './assets/SpectrumMusic.svg'
import SpeakerConnectPic from './assets/speaker-conn.jpg'
import SpeakerClipsPic from './assets/speaker-clips.png'


export const MusicImg = SoundWaves

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Micro Musician! {lesson-title}
___
#### This project makes music with the micro:bit
`}
<img src={SoundWaves} alt=""
    style={{
      width: "100%", margin: 'auto', display:'block'
    }}
/>
{`*Computers and music go great together!* {centered}

**Musicians often use computers to help create music**
* Drum Machines
* Keyboard synthesizers
* Recording and Mixing with Digital Audio Workstation (DAW) Software
* *...lots more ways - can you think of some?*

This is a short project, just to give you a taste of what the micro:bit can do
in the area of *electronic music*.

As with the LED display, your software can take **complete** control over the output
of the micro:bit, and in *future* projects you will do more customization!

#### Project Goals:
* Connect a **speaker** to the micro:bit ==edge connector==
* Play some of the micro:bit's built-in songs
`}
</Markdown>
        <StepComplete
          prompt="Ready to make some noise? Press Next..."
          lessons={this.props.lessons}
          btnNext={true}
        />

      </div>
    )
  }
}

class ConnectSpeaker extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Connect the Speaker {lesson-title}
___
#### This step uses the *speaker* component from your *Firia Labs Kit*.
`}
<LegendMessageInteract title="Connect Wires">
{`Use two **alligator clip** wires from your *kit* to connect the **speaker** to the micro:bit as shown below.
> Any **color** alligator clip wires will work - they're all electrically the same!


**Be sure to connect *only* to the \`0\` and \`GND\` metal pads!**
`}
</LegendMessageInteract>
<img src={SpeakerClipsPic} alt=""
    style={{
      width: 200, margin: 'auto', display:'block'
    }}
/>
<br />
<img src={SpeakerConnectPic} alt=""
    style={{
      width: 400, margin: 'auto', display:'block'
    }}
/>
<LegendMessageWarning title="Keep 'em Straight!">
{`Make sure the metal "jaws" of each alligator clip **only** touch the specified pad.

If an alligator clip shifts around or gets diagonal, straighten it out. Otherwise your music may not play!
`}
</LegendMessageWarning>
</Markdown>
        <StepComplete
          prompt="All connected? Get ready to compose some code..."
          lessons={this.props.lessons}
          btnNext={true}
        />

      </div>
    )
  }
}

class PlaySongs extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Micro Tunes {lesson-title}
___
Now that your **speaker** is connected, it's time to write code to play some tunes!

You will use a built-in module called \`music\` for your first songs.
`}
<LegendMessageKeyboard>
{`
* Create a new file (click **File** menu at top-left of screen)
* Name it **Music1**
#### Type this code into the Editor:
\`\`\`
import music
music.play(music.NYAN)
\`\`\`
`}
</LegendMessageKeyboard>
{`That's pretty simple code!

**Notice** the \`import\` statement is different than your first programs. You are *only* using the \`music\` module,
so that's all you need to ==import== for this code.
`}
<LegendMessageRun />
        <StepComplete
          prompt="Are you hearing the enchanting melody?"
          xp={10}
          successMessage=
{`## Sounds Great! {centered}
### ...but I think it needs a little more cowbell.
`}
          reqImports={['music']}
          reqCalls={['music.play']}
          reqArgs={['music.NYAN']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
        />

      </Markdown>
    </div>
    )
  }
}

class ExtendedPlay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Once More, With Feeling {lesson-title}
___
**You may want to spend more time experimenting with the micro:bit's built-in ==music== collection...**
* In a *future* lesson, you will learn how to create your own tunes!
`}
<LegendMessageKeyboard>
{`Now edit your code with the following changes. You don't want the **display** to be dark while those
inspiring tunes are playing!
\`\`\`
$from microbit import *
import music

$display.show(Image.MUSIC_QUAVERS)
music.play(music.BLUES)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Try some different songs!
`}
</LegendMessageRun>
        <StepComplete
          prompt="Enjoying the sounds and lights?"
          xp={10}
          successMessage=
{`## Multi-Media! {centered}
### This is the start of something really cool.
`}
          reqImports={['music', 'microbit']}
          reqCalls={['music.play', 'display.show']}
          reqArgs={['Image.MUSIC_QUAVERS']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
        />

      </Markdown>
    </div>
    )
  }
}

class Finissimo extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Sound Off! {lesson-title}
___
### You can do MUCH more with sounds on the micro:bit

In future lessons, you will explore all the capabilities AND compose your **own** songs!
`}
        </Markdown>
        <img src={SpectrumMusic} alt=""
          style={{
            width: "100%", margin: 'auto', display:'block'
          }}
        />
        <StepComplete
          prompt="Well done! Move ahead to more coding fun..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)

Prelude.stepId = 'Prelude'
ConnectSpeaker.stepId = 'ConnectSpeaker'
PlaySongs.stepId = 'PlaySongs'
ExtendedPlay.stepId = 'ExtendedPlay'
Finissimo.stepId = 'Finissimo'

export const music = [
  Prelude,
  ConnectSpeaker,
  PlaySongs,
  ExtendedPlay,
  Finissimo,
]
