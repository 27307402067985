import React, { Fragment } from 'react'
import Drawer from 'material-ui/Drawer'
import List from 'material-ui/List'
import ListItem from 'material-ui/List/ListItem'
import ListItemIcon from 'material-ui/List/ListItemIcon'
import ListItemText from 'material-ui/List/ListItemText'
import Typography from 'material-ui/Typography'
import Button from 'material-ui/Button'
import Divider from 'material-ui/Divider'
import GroupIcon from 'material-ui-icons/Group'
import AddIcon from 'material-ui-icons/Add'
import NewGroupModal from './modals/NewGroupModal.jsx'
import JoinGroupModal from './modals/JoinGroupModal.jsx'
import { Database } from './DataSources'

const SIDEBAR_INDEXES = {
  MY_GROUPS: 0,
}

const sidebarNames = ['My Classrooms']

class SideBar extends React.Component {
  state = {
    newGroupModalOpen: false,
    joinGroupModalOpen: false,
    groupsJoinedData: [],
  }

  setNewGroupModalVis = (open) => {
    this.setState({ newGroupModalOpen: open })
  }

  setJoinGroupModalVis = (open) => {
    this.setState({ joinGroupModalOpen: open })
  }

  handleNewGroupButtonClick = () => {
    this.setNewGroupModalVis(true)
  }

  handleListItemClick = (event, index) => {
    this.props.selectionChanged(index)
  }

  handleJoinClassButtonClick = () => {
    this.props.disableUi(true)
    Database.getGroupsJoined().then((resp) => {
      this.props.disableUi(false)
      this.setState({ groupsJoinedData: resp.groups })
    }).catch((d) => {
      this.setState({ groupsJoinedData: [] })
      this.props.disableUi(false)
    })
    this.setJoinGroupModalVis(true)
  }

  render() {
    return (
      <Fragment>
        <Drawer
          className={this.props.classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.props.open}
          classes={{
              paper: this.props.classes.drawerPaper,
            }}
        >
          <div>
            <div className={this.props.classes.headerHeight} />
            <Divider />
            <Button
              color="primary"
              variant="fab"
              aria-label="add"
              size="large"
              style={{ margin: '8px', textTransform: 'none', width: 'auto', height: '48px', padding: '0 16px', borderRadius: '24px' }}
              className={this.props.classes.fab}
              onClick={event => this.handleNewGroupButtonClick(event)}
            >
              <AddIcon className={this.props.classes.extendedIcon} />
                New Classroom
            </Button>
            <List>
              <ListItem
                button
                disableGutters
                style={{ padding: 14, backgroundColor: this.props.selectedIndex === SIDEBAR_INDEXES.MY_GROUPS ? 'rgba(0, 0, 0, 0.14)' : 'white' }}
                key={SIDEBAR_INDEXES.MY_GROUPS}
                onClick={event => this.handleListItemClick(event, SIDEBAR_INDEXES.MY_GROUPS)}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText inset={false} disableTypography primary={<Typography type="body2" >{sidebarNames[SIDEBAR_INDEXES.MY_GROUPS]}</Typography>} />
              </ListItem>
            </List>
            <Button
              style={{ marginLeft: '40px' }}
              onClick={event => this.handleJoinClassButtonClick(event)}
            >
              Join Class
            </Button>
          </div>
        </Drawer>
        <NewGroupModal
          setVisibility={this.setNewGroupModalVis}
          refreshData={this.props.refreshData}
          open={this.state.newGroupModalOpen}
          disableUi={this.props.disableUi}
          uiDisabled={this.props.uiDisabled}
        />
        <JoinGroupModal
          setVisibility={this.setJoinGroupModalVis}
          open={this.state.joinGroupModalOpen}
          disableUi={this.props.disableUi}
          uiDisabled={this.props.uiDisabled}
          groupsJoinedData={this.state.groupsJoinedData}
        />
      </Fragment>
    )
  }
}

export default SideBar
export { SIDEBAR_INDEXES }
