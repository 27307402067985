import React, { Fragment } from 'react'
import Dialog from 'material-ui/Dialog'
import DialogActions from 'material-ui/Dialog/DialogActions'
import DialogContent from 'material-ui/Dialog/DialogContent'
import DialogTitle from 'material-ui/Dialog/DialogTitle'
import Button from 'material-ui/Button'


import Table, {
  TableBody,
  TableCell,
  TableRow,
} from 'material-ui/Table'

class LicensePortalModal extends React.Component {
  handleClose = () => {
    this.props.handleClose()
  }

  handleSend = () => {
    this.props.handleSend()
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        scroll="paper"
      >
        <DialogTitle>Validate Data</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow >
                  <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Curriculum:</TableCell>
                  <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.curriculum}</TableCell>
                </TableRow>
              <TableRow >
                <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Seats:</TableCell>
                <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.seats}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Send Email:</TableCell>
                <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.sendEmail ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              {
                this.props.sendEmail ?
                  <Fragment>
                    <TableRow >
                      <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Email:</TableCell>
                      <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.email}</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Name:</TableCell>
                      <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.name}</TableCell>
                    </TableRow>
                  </Fragment> :
                  <TableRow >
                    <TableCell style={{ fontWeight: 'bold', padding: 2 }}>Amazon Order ID:</TableCell>
                    <TableCell style={{ padding: 2, paddingLeft: 6 }}>{this.props.amazonOrderId}</TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} variant="raised">
            Cancel
          </Button>
          <Button onClick={this.handleSend} variant="raised">
            {this.props.sendEmail ? 'Send' : 'Get License'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default LicensePortalModal

