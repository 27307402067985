import React, { PureComponent } from 'react'
import {genHelpObjs, hintCategory} from './lessons/GeneralHelpHints'
import userProgress from './UserProgress'
import ExpansionPanel, {ExpansionPanelDetails, ExpansionPanelSummary} from 'material-ui/ExpansionPanel'
import Markdown from './lessons/cbl-remarkable'
import ExpandMoreIcon from 'material-ui-icons/ExpandMore'
import { withStyles } from 'material-ui/styles'

const styles = theme => ({
  content: {
    marginTop: '4px',
    marginBottom: '4px',
  }
})

class Toolbox extends PureComponent {
  constructor() {
    super()
    this.state = {
      openPanel: null,
    }
    this.collectedTools = genHelpObjs
    .filter((obj) => userProgress.isHintComplete(obj.name))
    .sort((a,b) => a.name < b.name ? -1 : (a.name === b.name ? 0 : +1))
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleExpandTransitionEnd = (e) => {
    if (e.propertyName === 'flex-basis') {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
      }
      this.scrollTimeout = setTimeout(this.scrollToExpanded, 50)
    }
  }

  handleToolboxExpansionPanel = panel => (event, expanded) => {
    this.setState({
      openPanel: expanded ? panel : false
    })
  }

  scrollToElement = (elem) => {
    if (elem) {
      elem.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'})
    }
  }

  getPanelId = (category, name) => {
    return 'toolbox-panel-' + category.replace(/\s/g, '-') + '-' + name.replace(/\s/g, '-')
  }

  getCategoryId = (category) => {
    return 'toolbox-title-' + category.replace(/\s/g, '-')
  }

  scrollToExpanded = () => {
    if (this.state.openPanel && typeof(this.state.openPanel) === 'string') {
      const elem = document.getElementById(this.state.openPanel)
      this.scrollToElement(elem)
    }
  }

  scrollToCategory = (category) => {
    const elem = document.getElementById(this.getCategoryId(category))
    this.scrollToElement(elem)
  }

  render() {
    const {classes} = this.props
    const styles = {
      name: {
        flex: '0 0 14em',
        marginTop: 'auto',
        minWidth: '50px',
        width: '0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        transition: 'all 0.3s',
        // backgroundColor: 'rgba(193, 23, 76, 0.2)',
      },
      largeName: {
        flex: '1 0 100%',
        marginTop: 'auto',
        fontWeight: 'bold',
        fontSize: '2em',
        transition: 'all 0.3s',
        // backgroundColor: 'rgba(193, 23, 76, 0.2)',
      },
      desc: {
        flex: '1 1 0%',
        maxHeight: '32px',
        minWidth: '0',
        width: '0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        opacity: '0.75',
        fontStyle: 'italic',
        transition: 'all 0.3s',
        // backgroundColor: 'rgba(66, 157, 187, 0.2)',
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        width: '100%',
        // backgroundColor: 'rgba(156, 252, 95, 0.2)',
      },
    }

    if (this.collectedTools.length > 0) {
      return (
        Object.entries(hintCategory).map((category) => {
          // Return null if this category is empty
          if (Object.keys(this.collectedTools).find(key => this.collectedTools[key].category === category[1]) === undefined) {
            return (null)
          }
          return (
            <div key={category[1]}>
            <div
              id={`toolbox-title-${category[1].replace(/\s/g,'-')}`}
              style={{
                fontSize: '1.5em',
                textAlign: 'center',
                marginTop: '0.75em',
                marginBottom: '0.25em',
              }}
            >
              {category[1]}
            </div>
            {this.collectedTools.map((obj, i) => {
              if (category[1] === obj.category) {
                let textDesc = null
                if (obj.desc) {
                  textDesc = obj.desc.replace(/\*/g, '')
                }
                const panelId = this.getPanelId(obj.category, obj.name)
                return (
                  <ExpansionPanel
                    key={i}
                    expanded={this.state.openPanel === panelId}
                    onChange={this.handleToolboxExpansionPanel(panelId)}
                    onTransitionEnd={this.handleExpandTransitionEnd}
                    id={panelId}
                  >
                    <ExpansionPanelSummary
                     expandIcon={<ExpandMoreIcon />}
                     style={{minHeight: 24}}
                     classes={classes}
                    >
                      <div style={styles.wrapper}>

                        {(this.state.openPanel !== panelId) ? (
                          <div style={styles.name}>{obj.name}</div>
                        ) : (
                          <div style={styles.largeName}>{obj.name}</div>
                        )}

                        {(textDesc && this.state.openPanel !== panelId) ? (
                          <div style={styles.desc} title={textDesc}>{textDesc}</div>
                        ) : (
                          <div
                            style={Object.assign({}, styles.desc, {opacity: '0', visibility: 'hidden'})}
                            title={textDesc}
                          >
                            {textDesc}
                          </div>
                        )}

                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      { this.state.openPanel === panelId ? (
                      <div style={{width: '100%'}} >
                      <Markdown>
        {obj.desc ? `#### ${obj.desc} {margin-none}` : ``}
                      </Markdown>
                      {obj.hint}
                      </div>
                      ) : <React.Fragment></React.Fragment> }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              } else {
                return (null)
              }

          })}
        </div>
          )
        })

    )} else {
      return (
        <Markdown>
{`
## Your toolbox is empty! {centered}
___
Be on the lookout for <mark>new concepts</mark> in the lessons. Click on one when you see it and it will be added here!
`}
        </Markdown>
      )
    }

  }
}

export default withStyles(styles)(Toolbox)
