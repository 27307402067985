// General help and hints content for CodeSpace

import React from 'react'
import Markdown from './cbl-remarkable'
import MicrobitHeart from './StartCoding/assets/microbit-heart-trans.gif'
import BootPic from './StartCoding/assets/boot.gif'
import HoppersMoth from './StartCoding/assets/hoppers-moth.jpg'
import MicrobitAxesImg from './StartCoding/assets/AccelAxes.gif'
import StepOverPic from '../assets/StepOver.png'
import StepIntoPic from '../assets/StepInto.png'
import StepOutPic from '../assets/StepOut.png'
import SimulationImg from './StartCoding/assets/VRsim.jpg'
import LedMatrixImg from './StartCoding/assets/LedMatrix.gif'
import CrossroadsImg from './StartCoding/assets/Crossroads.jpg'
import LoopImg from './StartCoding/assets/Loop.gif'
import PixelImg from './StartCoding/assets/sword-min.svg'
import MbSwordImg from './StartCoding/assets/mb-sword.gif'
import EdgeConnImg from './StartCoding/assets/edgeConn.gif'
import DigitalLandImg from './StartCoding/assets/DigitalLand.jpg'
import Binary1bitImg from './StartCoding/assets/binary-1bit.gif'
import Binary2bitImg from './StartCoding/assets/binary-2bit.gif'
import LocalGlobal from './StartCoding/assets/LocalGlobal.gif'
import RadioModulation from './StartCoding/assets/RadioModulation.jpg'
import DecoderRing from './StartCoding/assets/DecoderRing.gif'
import ByteImg from './StartCoding/assets/Byte.png'
import MemoryBytes from './StartCoding/assets/MemoryBytes.png'
import CbUserLeds from './PythonWithRobots/assets/user_leds.jpg'
import ImageAccel from './PythonWithRobots/assets/accel.jpg'
import ImageUserLeds from './PythonWithRobots/assets/user_leds.jpg'
import ImageLsLeds from './PythonWithRobots/assets/ls_leds.jpg'
import ImageIrEmitter from './PythonWithRobots/assets/ir_emitter.jpg'
import ImageProxSensor from './PythonWithRobots/assets/IR_sensor.gif'
import ImageEncoder from './PythonWithRobots/assets/encoders.gif'
import ImageLineSensors from './PythonWithRobots/assets/line_sensors.jpg'
import ImageReplStart from './PythonWithRobots/assets/repl_start.jpg'
import Button from 'material-ui/Button'
import LaunchIcon from 'material-ui-icons/Launch'
import ImageMotorAssy from './PythonWithRobots/assets/motor_assy.gif'
import PushButton from './PythonWithRobots/assets/pushbutton.jpg'
import PhotoreflectiveSwitch from './PythonWithRobots/assets/photoreflective_switch.gif'
import ImageSpeakerCutaway from './StartCoding/assets/SpeakerCutawayCyl.png'
import ImageStopwatch from './StartCoding/assets/stopwatch.png'
import ImageCalculator from './assets/Calculator.gif'
import OctopusBitAlternateFunctions from './LiftOffPeripherals/assets/octo_alt_funcs.jpg'
import OctopusBitInserted from './LiftOffPeripherals/assets/octo_connection.jpg'
import peripheralsKitData from './LiftOffPeripherals/PeripheralsData'
import ImageDutyCycles from './LiftOffPeripherals/assets/duty_cycles.png'
import ImageKnob from './PythonWithRobots/assets/Knob.jpg'
import TrafficLightStateDiagramImg from './LiftOffPeripherals/assets/traffic_light_state_diagram.jpg'

export const hintCategory = Object.freeze({
  LANGUAGE: 'Python Language',
  PY_LIBRARIES: 'Python Libraries',
  MB_LIBRARIES: 'Microbit Libraries',
  CB_LIBRARIES: 'CodeBot Libraries',
  LORE: 'Computer Science',
  CODESPACE: 'CodeSpace',
  LIFTOFF: 'LiftOff Peripherals',
  // MICROBIT: 'micro:bit',
})

// Button component for reference to external CodeBot docs.
function DocsButton(props) {
  const style = Object.assign({float:'right', margin:5}, props.style)
  return (
    <div style={style}>
      <Button color="primary" onClick={() => window.open(props.href, '_blank')}>
        {props.name || 'Docs'}
        <LaunchIcon style={{width:20, marginLeft:5}} />
      </Button>
    </div>
  )
}

// Button component for reference to external Python/Micropython docs.
// TODO: Provide pyref AND additional mpref which if present gives a separate mini-button to open
//       corresponding micropython docs page.
function PyDocsButton(props) {
  const style = Object.assign({float:'right', margin:5}, props.style)
  return (
    <div style={style}>
      <Button color="primary" onClick={() => window.open(props.pyref, '_blank')}>
        {props.name + ' - Python' || 'Python Docs'}
        <LaunchIcon style={{width: 20, marginLeft: 5}} />
      </Button>
      {props.mpref && (
        <Button color="primary" onClick={() => window.open(props.mpref, '_blank')}>
          {props.name + ' - MicroPython' || 'MicroPython Docs'}
          <LaunchIcon style={{width: 20, marginLeft: 5}} />
        </Button>
      )}
    </div>
  )
}

const helpMathOperations = {
  name: 'Math Operators',
  desc: 'Math operators, precedence, and math Built-ins',
  category: hintCategory.LANGUAGE,
  matches: ['math', 'operator', 'precedence'],
  hint: (
<Markdown>
{`Python has features for doing basic mathematical operations **built-in** to the language!
* In addition, there are ***many*** more math features in the standard ==math module==.

An example of an **operator** is the symbol * for **multiplication**.
\`\`\`
a = 5
b = 2
product = a * b  # multiplication operator
\`\`\`
But if you invite *another **operator*** to the party, you have to worry about ***precedence***.
\`\`\`
product = 100 + a * b  # Hmmm... 210 or 110?
\`\`\`
Consulting the ***table below***, you find that * is **higher precedence** than +.
* So *multiplication* **binds first**, then *addition*. *(product is 110)*
* Consider using **parenthesis** to improve *readability* in code like this.
  * Notice which operator in the *table* has the ***highest precedence!***

### Operator Precedence Table
The table below lists common Python operators in order from ***lowest* to *highest* precedence**.
* Operators toward the top are *lower* precedence (least binding).
* Operators in the same box have the same precedence.

| Operator             | Description                         |
|----------------------|-------------------------------------|
| if - else            | Conditional Expression              |
| or                   | Boolean OR                          |
| and                  | Boolean AND                         |
| not x                | Boolean NOT                         |
| <, <=, >, >=, !=, == | Comparisons                         |
| &#124;                    | Bitwise OR                          |
| ^                    | Bitwise XOR                         |
| &                    | Bitwise AND                         |
| <<, >>               | Shifts                              |
| +, -                 | Addition and subtraction            |
| *, /, %              | Multiplication, division, remainder |
| +x, -x, ~x           | Positive, negative, bitwise NOT     |
| **                   | Exponentiation (power)              |
| x[index], x(args)    | Subscript, function call            |
| (expressions,...)    | Parenthesis                         |

`}
<PyDocsButton name="Operator Precedence" pyref="https://docs.python.org/3/reference/expressions.html#operator-precedence" />
<div style={{clear:'both'}} />
{`
### Built-in Math Functions
Python has a number of *built-in functions* that are available to your code with no module \`import\` needed.
Below are some of the *math oriented* ones:
\`\`\`
abs(x)   # Return absolute value of x.
divmod(a, b)   # Divide a/b, return (quotient, remainder).
max(arg1, arg2,...)   # Return maximum value of given arguments.
min(arg1, arg2,...)   # Return minimum value of given arguments.
round(x [, ndigits])  # Return x rounded to ndigits precision
                      # after the decimal point.
\`\`\`
`}
<PyDocsButton
  name="Built-ins"
  pyref="https://docs.python.org/3/library/functions.html#built-in-functions"
  mpref="https://docs.micropython.org/en/latest/library/builtins.html"
/>
</Markdown>
      )
}

const helpComments = {
  name: 'Comments',
  desc: 'Notes to the human readers of your code',
  category: hintCategory.LANGUAGE,
  matches: ['comment'],
  hint: (
<Markdown>
{`**Comments** are a way to put notes in your code that are **ignored** by the computer.
In Python, the **#** symbol is used for comments.
* Any text *to the right* of the **#** is *ignored.*
\`\`\`
# Check to see if we've reached combustion temperature
if temperature > 451:
    extinguish = True  # Activate the sprinkler system
\`\`\`

#### If comments are *ignored*, then what's the point?
Sometimes it is very obvious *what* a section of code does, but *sometimes* it is not!
And very often it is not clear **why** this piece of code exists!
* Comments are notes to your future self, after you've forgotten all about this code.
* Comments are notes to another person who is trying to figure out what this code does.

#### Guideline: *Big Scope &rarr; Big Comment*
If the *scope* of the code is small, then a *small* comment or *none at all* may suffice. Strive to always
*comment about the reason* rather than the coding details. And avoid comments that are *doomed* to become **untrue** one day!
* Which of the following comments is more useful to the reader?
* Which one will still be accurate if the line of code is changed to something besides **5**?
\`\`\`
# Set value to 5.
value = 5

# Start with an initial maximum value.
value = 5
\`\`\`

If the *scope* is big, like the ***whole program/module*** or a ***whole ==function==***, then a bigger comment may be good.
* What is the purpose of this program? This function?

> *Python has a special type of **comment** used to document functions and modules (file-level)*
### Documentation Strings *(docstrings)*
Multiline comments surrounded by triple-quotes are used at the top of a file, or after a function definition, to
document ***what this code does***.
\`\`\`
"""This program makes the CodeBot rotate counterclockwise.
   You can start or stop the rotation by pressing BTN-0.
"""

def check_press():
    """Check to see if BTN-0 has been pressed."""
    return buttons.was_pressed(0)
\`\`\`
There are ***Automatic Documentation Generator*** tools that can use **docstrings** to create *browsable* documentation for your code!
`}
</Markdown>
      )
}

const helpCodeBotLeds = {
  name: 'CodeBot LEDs',
  desc: 'Lighting up CodeBot',
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot led', 'line sensor led', 'user led'],
  hint: (
<Markdown>
{`The *red* **USER** ==LEDs== are CodeBot's way of communicating a *BYTE* of information to you, the **User**.
`}
<div style={{textAlign:'center'}}>
  <img src={ImageUserLeds} alt="LEDs" style={{width: '35%', float:'right', margin:10}} />
</div>
{`
* There are 8 LEDs, numbered 0 through 7.
* Your code can use these LEDs to show a ==binary== number from 0 to 255.
* In computer science this is called a **BYTE**!
`}
{`Of course, since these LEDs are completely controlled by Python code that you write, you can program
them to display any pattern you want!

**The code below shows *three ways* to control the USER LEDs:**
* By individual bit-number
* With a binary integer. *(you can also use hex or decimal)*
* With a sequence of ==bools==.  *(a ==list== or ==tuple==)*
\`\`\`
leds.user_num(3, True)  # Turn on USER LED number 3
leds.user(0b11110000)   # Turn on USER LEDs 4, 5, 6 and 7

# Turn on USER LEDs 0, 1, and 2
leds.user([True, True, True, False, False, False, False, False])
\`\`\`
#### Line Sensor LEDs
`}
<div style={{textAlign:'center'}}>
  <img src={ImageLsLeds} alt="LS LEDs" style={{width: '35%', float:'right', margin:10}} />
</div>
{`The five *green* **Line Sensor** LEDs are positioned directly above the five ==line sensors== on the bottom side of the board.
* You can write code to light these LEDs based on line sensor readings.
* ...Or you can use them for any other purpose you choose - they are completely under your code's control!

Control these LEDs just like the **USER** LEDs, but with \`leds.ls\` rather than \`leds.user\`.
\`\`\`
leds.ls(0b00011)  # Turn on LS LEDs 0 and 1
\`\`\`
#### Other LEDs
Check the **botcore** docs to see how to control other LEDs on your 'bot!
`}
<DocsButton name="botcore LED docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.CB_LEDs"  />
</Markdown>
      )
}

const helpCodeBotAccel = {
  name: 'Accelerometer',
  desc: 'Sensing orientation and impact.',
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot accelerometer'],
  hint: (
<Markdown>
{`Just like a game controller or phone that can sense tilting or shaking, this sensor chip lets the robot
detect motion, impacts, and orientation.

An **accelerometer** is a device that measures *proper acceleration*.
One way you commonly experience *acceleration* is when you're moving and your speed or direction of motion
changes. Think of speeding up in a car, the force you feel pushing you back in your seat or sideways when going
through a turn. The forces you're feeling are due to *acceleration*.
> So if you're standing still, acceleration is *zero*, right?

**No!** Well, not unless you're floating in space!

*Gravity* also causes acceleration. So even if your accelerometer is sitting still on your desk, it will measure
an acceleration due to the force of gravity pulling straight down toward the floor.

In a *zero-g* environment, like outer space, or if an object is in *free-fall*, the accelerometer will measure *zero* acceleration
in all directions.

CodeBot's accelerometer measures the force of acceleration in 3-directions:
> In the picture below, if CodeBot's circuit board is sitting horizontally and motionless on Earth, it will measure *gravitational acceleration* (-1g) in
the **Z** direction, but *zero* in the **X** and **Y** directions.
`}
  <div style={{textAlign:'center'}}>
    <img src={ImageAccel} alt="Accel" style={{width: 200}} />
  </div>
<DocsButton name="botcore Accelerometer docs" href="https://docs.firialabs.com/codebot/lis2hh.html"  />
</Markdown>
  )
}

const helpCodeBotProx = {
  name: 'Proximity Sensors',
  desc: 'Detecting objects with infrared light',
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot proximity', 'proximity sensor'],
  hint: (
<Markdown>
{`Dual Proximity Sensors give CodeBot the ability to detect nearby objects so you can write code to:
`}
    <div style={{float:'right'}}>
      <img src={ImageProxSensor} alt="Prox" style={{width: 200}} />
    </div>
{`
* Avoid obstacles
* Pursue moving targets
* Detect walls

These IR (infrared) sensors can also be used to wirelessly communicate between teams of CodeBots. Let the swarm begin!
`}
<div style={{float:'right'}}>
  <img src={ImageIrEmitter} alt="IR Emitter" style={{width: 150}} />
</div>
{`The IR emitter is like a very bright "Headlight" for CodeBot, that lights up objects in front of it.
* It emits light in the **infrared** spectrum, which is *invisible to humans*.
* CodeBot uses it together with the *Proximity Sensors* to detect objects based on reflected IR light.

#### The example code below continously reads the *proximity* sensors and displays the \`(bool, bool)\` results on the *prox* ==CodeBot LEDs==.
\`\`\`
while True:
    p = prox.detect()
    leds.prox(p)
\`\`\`
`}
<DocsButton name="botcore Proximity docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.Proximity" />
</Markdown>
    )
}

const helpCodeBotButtons = {
  name: 'Buttons',
  desc: 'User interface push buttons 0 and 1',
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot button'],
  hint: (
<Markdown>
<div style={{float:'right'}}>
  <img src={PushButton} alt="" style={{width: 150}} />
</div>
{`There are two *momentary push buttons* you can **read** from your Python code.
* The buttons are labeled like *bits* of a binary number, **0** and **1**.

The **botcore** library provides functions to read *buttons*:
* Your code can check the ***current state*** of the buttons with the \`buttons.is_pressed()\` function.
* The library also monitors button presses so your code can check if a button \`buttons.was_pressed()\` since the last time you checked.
`}
<div style={{clear:'both'}} />
<DocsButton name="botcore Button docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.Buttons" />
</Markdown>
  )
}

const helpCodeBotSpeaker = {
  name: 'Speaker',
  desc: 'Audio output for alert tones, music, and more.',
  category: hintCategory.CB_LIBRARIES,
  matches: ['speaker'],
  hint: (
<Markdown>
<img src={ImageSpeakerCutaway} alt="" style={{width: '20%', float:'right'}} />
{`CodeBot's *speaker* can be programmed to play simple audio frequency **pitches**, or controlled
in much more sophisticated ways through onboard *Digital to Analog Converter (DAC)* or *Pulse Width Modulation* hardware.
### I'm a real *speaker* - not a "buzzer"!
Your code sends electric current through the **Coil** causing the **Diaphram** to move, which vibrates the air to produce **sound**!
* Just like *headphones* or *loudspeakers* in a sound system!

**The example code below plays a "2-tone beep":**
\`\`\`
$from botcore import spkr
from time import sleep
$spkr.pitch(500)   # Start playing 500 Hz tone.
sleep(0.1)
$spkr.pitch(1000)  # Change to 1000 Hz tone.
sleep(0.1)
$spkr.off()        # Stop playing sound.
\`\`\`
`}
<div style={{clear:'both'}} />
<DocsButton name="botcore Speaker docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.Speaker"  />
</Markdown>
  )
}

const helpCodeBotLS = {
  name: 'Line Sensors',
  desc: "Detecting lines and boundaries beneath your 'bot",
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot line sensor', 'line sensor', 'ls'],
  hint: (
<Markdown>
{`Under CodeBot's front edge are 5 *line sensors*. These little black boxes are known as *photo reflective sensors*.
`}
<img src={PhotoreflectiveSwitch} alt="" style={{width: '30%', float:'right', marginRight:10}} />
{`
* They have an **infrared LED** that *emits* light, *and*
* A **phototransistor** that *detects* light.

Can you see the two sections in each of these sensors?

Your Python code can use these sensors to detect *how much light is reflected* by the surface your 'bot is on.
This gives CodeBot the ability to follow lines, detect boundaries, and more!
`}
  <div style={{textAlign:'center'}}>
    <img src={ImageLineSensors} alt="LineSensors" style={{width: 400}} />
  </div>
{`
**Example *API* usage:**
\`\`\`
# Read a single line sensor
val = ls.read(2)  # Returns analog reading of sensor 2

# Read all line sensors
thresh = 2500  # Threshold between 'line' and 'ground' on this surface.
is_reflective = False  # Black lines
vals = ls.check(thresh, is_reflective)  # Returns a tuple of bools
\`\`\`
`}
<DocsButton name="botcore LineSensor docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.LineSensors" />
</Markdown>
  )
}

const helpCodeBotMotors = {
  name: 'Motors',
  desc: "Electric motors to power your 'bots wheels",
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot motor', 'motor'],
  hint: (
<Markdown>
<img src={ImageMotorAssy} alt="" style={{width:'25%', float:'right'}} />
{`Electric motors convert electricity into rotational motion.
* CodeBot's motors and connection terminals are under a black protective sleeve.
* Inside the yellow rectangular block are **gears** that reduce the fast motor rotation to the speed and power range CodeBot needs.
`}
{`
Your Python code can control how much electric power is delivered to each motor, over a full range -100% to +100%:
* 0% = stop
* +100% = full speed forward
* -100% = full speed reverse

**Example -** *rotate* CodeBot *clockwise* for 1 second:
\`\`\`
$from botcore import *
from time import sleep
$motors.enable(True)
$motors.run(LEFT, 50)
$motors.run(RIGHT, -50)
sleep(1.0)
$motors.enable(False)
\`\`\`
`}
<div style={{clear:'both'}} />
<DocsButton name="botcore Motor docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.Motors" />
</Markdown>
  )
}

const helpCodeBotEncoders = {
  name: 'Wheel Encoders',
  desc: 'Sensing rotation',
  category: hintCategory.CB_LIBRARIES,
  matches: ['encoder', 'wheel encoder'],
  hint: (
<Markdown>
{`Your code can control the *power* applied to the motors, but to know exactly how far the wheels have turned you'll
need to *sense rotation*. That's the job of the **Wheel Encoders**!
`}
    <div style={{textAlign:'center'}}>
      <img src={ImageEncoder} alt="Encoder" style={{width: 200}} />
    </div>
{`As the encoder disc rotates, an invisible IR (infrared) light beam passes through its slots.
Your code can count the **pulses** of light to see how much the wheel has rotated.

**Example:** Show LEFT *encoder* value on the Debug Console.
\`\`\`
from botcore import *
from time import sleep

while True:
    val = enc.read(LEFT)
    print(val)
    sleep(0.5)
\`\`\`
`}
<DocsButton name="botcore Encoder docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.Encoders"  />
</Markdown>
  )
}

const helpCodeBotSystem = {
  name: 'System Status Monitors',
  desc: 'CodeBot system sensors and status reporting',
  category: hintCategory.CB_LIBRARIES,
  matches: ['codebot system', 'system sensor'],
  hint: (
<Markdown>
{`Robots need to be aware of their **internal** environment, in addition to having sensors for *external* stuff!

CodeBot has sensors for:
* CPU Temperature (\`temp_C()\` and \`temp_F()\` ==functions==)
* Power supply input voltage
* Power switch position (USB / Battery)

**Example:** A function that lights the **BATT** LED when the battery is low.
\`\`\`
def check_batt():
    if not system.pwr_is_usb():
        # Read batt voltage under load
        leds.user(0x0f)
        v = system.pwr_volts()
        leds.user(0x00)
        
        # Below 25% capacity, turn on BATT LED!
        batt_low = v < 4.5
        leds.pwr(batt_low)
\`\`\`
`}
<DocsButton name="botcore System docs" href="https://docs.firialabs.com/codebot/botcore.html#botcore.System" />
</Markdown>
  )
}

const helpUnderscore = {
  name: 'Underscore',
  desc: 'Making names more readable',
  category: hintCategory.LANGUAGE,
  matches: ['underscore', '__underscore'],
  hint: (
<Markdown>
{
`A common way to make descriptive names in code is to join words with *underscore*.

> On your keyboard, hold down SHIFT and press the dash '**-**' key.

You'll get used to joining words together like this to make your own unique and meaningful names
where needed in your code. The micro:bit gives us plenty of practice with names like *button_a* and *was_pressed*.
`
}
</Markdown>
  )
}

const helpLocalGlobal = {
  name: 'Locals and Globals',
  desc: 'Variable scope and lifetime',
  category: hintCategory.LANGUAGE,
  matches: ['local', 'global', 'locals and globals', 'local variable'],
  hint: (
<Markdown>
<img src={LocalGlobal} alt="" width={'30%'} style={{display:'block', margin:'auto', marginRight:10, float:'right'}} />
{
`
Variables that you define outside of a function are called **global** variables.
* Their "lifetime" (how long they retain value) is the whole time the program is running, and their "scope"
(where they can be seen/used by code) is the whole file. That's *global*, dude!

The other kind of variable is **local**. Variables created inside **functions** are *local*.
* They only exist while the function is running, then they go away. That's *local* scope.

#### What if I want to change a global variable from inside a function?
* You have to declare it in the function with the \`global\` keyword, like:
\`\`\`
count = 0

def check_buttons():
    global count      # <--------- Tells Python to use the GLOBAL
                      #            variable, not to make a LOCAL one.
    if button_b.was_pressed():
        count = count + 1
        return str(count)
\`\`\`

Now when you assign to \`count\`, you're assigning to the **global** one rather than creating a new **local** one.

#### Note:
Variables that are *referenced* inside a ==function== but **not** assigned a value within it are assumed to be global. So your
code can *access* any global variable it can see! Only when you need to ***change*** the variable is a \`global\` declaration required.
`
}
</Markdown>
  )
}

const helpISM = {
  name: 'Radio Frequencies',
  desc: 'Wireless communication for computers',
  category: hintCategory.LORE,
  matches: ['ism', '2.4ghz'],
  hint: (
<Markdown>
<img src={RadioModulation} alt="" width={'40%'} style={{display:'block', margin:'auto', marginRight:10, float:'right'}} />
{`You have probably heard of WiFi and Bluetooth. Those are just two of the **wireless standards** that
enable the computers and mobile devices you use every day to communicate.
> There are many other standard (and non-standard) wireless methods used in homes, schools, offices, and industrial applications.

Digital radios work by encoding information (like Python strings!) and sending it as *high-frequency* pulses of electrical energy into an antenna, which
in turn creates a pulsating ("modulated") *electromagnetic field* that radiates through space and can be received by other antennas.

#### Radio Frequency (RF) - what does *high-frequency* mean?
Well, first consider *frequencies* that you can hear - *audio frequencies*.
* For example, a 1000 Hz tone you can play on a speaker. That's kind of a high pitch, but you *might* be able to whistle that high...
* In case you didn't know, **Hz** stands for "Hertz" which is the unit for *frequency* meaning **cycles per second**.

**Standard Bluetooth and WiFi frequency is 2.4 GHz**
* That's *giga Hertz* -- 2.4 *billion* pulses per second!
* Okay, that's *way* too high to whistle :-)
`
}
</Markdown>
  )
}

const helpPunctuation = {
  name: 'Punctuation',
  desc: 'Symbols used in coding',
  category: hintCategory.LANGUAGE,
  matches: ['punctuation?', 'punctuation'],
  hint: (
<Markdown>
{
`Strange looking *punctuation* can take some getting used to when you first start coding!

For example, these two lines simply display a HEART image, but look at the *asterisk*, *dots*, and *parentheses* - oh my!
\`\`\`
from microbit import *
display.show(Image.HEART)
\`\`\`
Well, all this *punctuation* has a *purpose*.
* We are using the **microbit module** - pre-loaded code that makes it easier to
 do things with the micro:bit.
* The * means "import **everything**" from that module (it's called a *wildcard*).
* The microbit module provides **display** and **Image** "objects".
* When we type: \`display.show\` we are using the **show** property of the **display** object.
  * The **.** dot is a way of accessing part of the display object.
  * The **parentheses ()** tell the computer to **run** a particular piece of code, optionally passing it some information (inside the parentheses).

So, \`display.show(Image.HEART)\` means
* Run the *display* object's **show** code, passing it the *Image* object's **HEART** property.
`
}
</Markdown>
  )
}

const helpUsb = {
  name: 'USB',
  desc: 'Universal Serial Bus',
  category: hintCategory.LORE,
  matches: ['usb'],
  hint: (
<Markdown>
{
`You may recognize this connection as one that's used for charging many mobile phones, headsets, and other devices.
In addition to providing power, USB can also send information!

Connected devices can be *powered* by USB, but also it's the main way that CodeSpace *communicates* with your device's CPU.
`
}
</Markdown>
  )
}

const helpReboot = {
  name: 'Reboot',
  desc: 'Start over',
  category: hintCategory.LORE,
  matches: ['reboot', 'boot'],
  hint: (
<div>
<Markdown>
{
`When a computer is first powered ON, it's called **Booting Up**^1^. Your device has a push-button that *resets* the main CPU,
causing it to *start back from the beginning* and run whatever code is loaded again.

^1^ The term comes from the phrase: "Pull yourself up by your bootstraps" - you know, those loops at the back of boots that
help you pull them onto your feet! Think of the computer putting its boots on, after it wakes up first thing in the morning!
 `
}
</Markdown>
<img src={BootPic} alt="boot strap" width={100} style={{margin: 'auto', display: 'block'}} />
</div>
  )
}

const helpLed = {
  name: 'LED',
  desc: 'Light Emitting Diode',
  category: hintCategory.LORE,
  matches: ['led'],
  hint: (
<div>
<Markdown>
{
`A **diode** is a fundamental *electronic component*. There are lots of them inside every computer! LEDs are a special kind of
diode, packaged in a clear case so the light they emit can shine out.
`}
<img src={MicrobitHeart} alt="microbit display" width={100} style={{margin: 'auto', display: 'block', float:'right'}} />
{`
The micro:bit's LED display has 5x5 = 25 LEDs that you can
control to make images, letters, and animations.

The CodeBot CB2 has 17 *visible* LEDs (red, green, and yellow) as well as 8 *infrared* LEDs it uses for sensors.
`}
<img src={CbUserLeds} alt="" width={300} style={{margin: 'auto', display: 'block'}} />
</Markdown>
</div>
  )
}

const helpVariable = {
  name: 'Variables',
  desc: 'Memory space for storing things',
  category: hintCategory.LANGUAGE,
  matches: ['variable'],
  hint: (
<Markdown>
{
`You might think of **variables** as *boxes* with *labels* on them, that you can put stuff in. That *stuff* might be
numbers, text, an Image,... pretty much any of the objects your code needs to work with!

**Ex:** Store the number 73 in a new *variable* called *my_favorite_number*
> my_favorite_number = 73

Now you can use the variable name instead of the number. And if you decide to change *my_favorite_number* to something different,
you can change it as often as you like! The *variable* lets you store and retrieve *data* to and from the computer's *memory*.

### Naming
Variable *names* in Python have to follow certain rules:
* Must begin with a letter or _ , not a number
* Can contain letters, numbers, and _
`
}
</Markdown>
  )
}

const helpBooleans = {
  name: 'bool',
  desc: 'Booleans, True or False',
  category: hintCategory.LANGUAGE,
  matches: ['boolean', 'condition', 'bool'],
  hint: (
  <Markdown>
{
`The flow of your code often depends on whether a **condition** is True or False.

**True** and **False** are called **Boolean** values, named for a famous Englishman, *George Boole* - one of the great mathematical
geniuses of his day. Back in the mid 1800's he invented the way we express logic in computing today. I think he would have *loved* CodeSpace!

The values \`True\` and \`False\` are special *Python* keywords too.
* Your code will often test conditions, like *"Is count > 22 ?"*
* The **result** of this sort of test will be either \`True\` or \`False\`!

A **bool** is a value that can be **True** or **False**.

You can define your own **bool** variables:
\`\`\`
$game_over = False
$while game_over == False:
$    # Continue playing game!
    next_level()
\`\`\`

In Python, you can convert other types like ==string== or ==int== to **bool** with \`bool()\`.

#### "Truthy" or "Falsy"?
How does Python decide if a non-bool type is \`True\` or \`False\`?
* *Zero* values and *empty strings or lists* are \`False\`.
* Other values are \`True\`.

**Ex:**
\`\`\`
bool(0) # is False
bool(1) # is True

bool("") # is False
bool("Hello, World!") # is True
\`\`\`

#### Need an expression that's *always* \`True\` ? You could write:
* \`True is True\`
* ...But it's easier just to use \`True\` by itself!

**Ex:**
\`\`\`
# The classic infinite loop in Python
while True:
    do_something_forever()
\`\`\`
`}
<PyDocsButton name="Boolean Values" pyref="https://docs.python.org/3/library/stdtypes.html#boolean-values" />
  </Markdown>
  )
}

const helpBranching = {
  name: 'Branching',
  desc: 'Decision points in code',
  category: hintCategory.LANGUAGE,
  matches: ['if condition', 'branching', 'branch', 'control flow', 'elif'],
  hint: (
  <Markdown>
{
`As your code runs, one line at a time, there will be points where a decision has to be made.
`}
<img src={CrossroadsImg} alt="" width={'70%'} style={{display:'block', margin:'auto'}} />
{`
Your code will take **a different branch** depending on the value of \`x\` or some other ==condition==.

The \`if condition_A\` statement tells Python to only run the block of code ==indented== beneath it
if *condition_A* is \`True\`.

An \`if\` statement can be followed by one or more \`elif\` statements. That's short for *"else if"*, and means the
following block will run *only* if the prior \`if\` or \`elif\` was \`False\`.
\`\`\`
$if condition_A:
$    # Do something amazing...
$$$
$elif condition_B:
$    # Do this only if condition_B is True
$    # (...and condition_A was False!)
$$$
\`\`\`

An \`if\` statement can end with an \`else\` statement:
\`\`\`
$if condition_A:
$    # Do something amazing...
$elif condition_B:
$    # Do this only if condition_B is True
$    # (...and condition_A was False!)
$$$
$else:
$    # Finally, do this if all of the prior if/elif conditions were False
$$$
\`\`\`
`
}
  </Markdown>
  )
}

const helpPins = {
  name: 'Pins',
  desc: 'Input / Output connections',
  category: hintCategory.MB_LIBRARIES,
  matches: ['pin', 'edge connector'],
  hint: (
    <div>
<img src={EdgeConnImg} alt="" style={{width:'40%', height:'40%', marginRight:10, float:'left'}} />
  <Markdown>
{`Take a look at the micro:bit's **Edge Connector**.

Those metal stripes along the bottom edge are electronic terminals you can wire to sensors, motors, lights, displays, speakers, and more!

There are 25 electrical connections along the bottom edge of the micro:bit. These connections (called "pins") give your **code** the
ability to *sense* and *control* things in the outside world!

> See **below** for functions to interact with the **pins**
`}
</Markdown>
<div style={{clear:'left'}}>
<Markdown>
{`
#### The functions below can be used with \`pin0\` through \`pin20\`.
*(Replace \`pin\` in the code below with your pin of choice)*
\`\`\`
# Output value can be 0, 1, False, or True
pin.write_digital(value)

# Read pin as Digital Input (returns 1 or 0)
pin.read_digital()

# Read pin as Analog Input (returns an integer between 0 and 1023)
pin.read_analog()

# Set internal pullup
pin.set_pull(mode)  # 0=pull up, 1=pull down (def)

# Test if touch-capable pin is touched (returns True or False)
# (pins 0, 1, and 2 are touch-capable)
pin.is_touched()

# sets the period of the PWM output of the pin in milliseconds
# (see https://en.wikipedia.org/wiki/Pulse-width_modulation)
pin.set_analog_period(int)
# sets the period of the PWM output of the pin in microseconds
# (see https://en.wikipedia.org/wiki/Pulse-width_modulation)
pin.set_analog_period_microseconds(int)

# Output analog (PWM) value between 0 and 1023
pin.write_analog(value)
\`\`\`
`}
  </Markdown>
  </div>
  </div>
  )
}

const helpLoops = {
  name: 'Loops',
  desc: 'Repeating sections of code',
  category: hintCategory.LANGUAGE,
  matches: ['loop', 'while'],
  hint: (
  <Markdown>
<img src={LoopImg} alt="" width={'20%'} style={{display:'block', margin:'auto', float:'right'}} />
{
`Ever feel like you're going in circles?

While *people* don't generally like to do the same thing over and over again, *computers* are really great at it!

**Loops** let you change the flow of your code so it **repeats** a block of code again, subject to a ==condition== you give.
`}
{`
#### *while* loop
The \`while condition:\` statement **repeats** the ==indented== block of code as long as **condition** is \`True\`.

*Example:*
> This code displays the numbers 0 through 4
\`\`\`
from microbit import *
i = 0
while i < 5:
    display.show(str(i))
    sleep(1000)
    i = i + 1
\`\`\`

#### *for* loop
The \`for value in iterable:\` statement **repeats** the ==indented== block of code once for each **value** of the **iterable** collection (string, list, etc.) you give.

*Examples:*
> This code counts the number of 's' characters in a string.
\`\`\`
from microbit import *
text = "This is a test"
count = 0
for letter in text:
    if letter == 's':
        count = count + 1

display.scroll(str(count))
\`\`\`

> This code displays the numbers 0 through 4
\`\`\`
from microbit import *
for i in range(5):
    display.show(str(i))
    sleep(1000)
\`\`\`

#### Break OUT!
A loop will continue until the ==condition== becomes **False**. But what if you want to break out early?
\`\`\`
$# Wait until button A is pressed
$while True:
$    if button_a.is_pressed():
$$$
$        break
$$$
\`\`\`
That's what \`break\` is for! Use it to break out of the nearest enclosing loop.
`
}
  </Markdown>
  )
}

const helpIndentation = {
  name: 'Indentation',
  desc: 'Structuring blocks of Python',
  category: hintCategory.LANGUAGE,
  matches: ['indent', 'indentation', 'indented', 'block'],
  hint: (
  <Markdown>
  {
`See the **block** of code below the \`while\`?
\`\`\`
while True:
    display.show(Image.DUCK)
    sleep(200)

    display.clear()
    sleep(200)

display.show(Image.HAPPY)  # this line will never run!
\`\`\`
The **indented** code is offset to the right with four spaces (TAB key). This is how the *Python* language
organizes blocks of code.

Statements ending in a colon (**:**), like \`while condition:\`, always operate on the **block** of code indented just beneath them.

#### Be careful and *consistent* with your *indentation*!
* Use the **TAB** key on the keyboard to help with this.
* Make sure every line of code in a *block* is "lined up" properly on the left!

**Note**
* If you've seen other programming languages, you might have noticed they have a *different syntax* to define blocks
of code - often {braces} are used. But with *Python* it's all about the indentation!
`
  }
  </Markdown>
  )
}

const helpUserInterface = {
  name: 'UI',
  desc: 'User Interface',
  category: hintCategory.LORE,
  matches: ['user interface'],
  hint: (
  <Markdown>
  {
`In computer lingo, the **User Interface** or **UI** is the part of a computer that *humans* interact with directly.

Usual examples would be a **keyboard**, **mouse**, and **screen**.

On the micro:bit, CodeBot, and similar *physical computing* devices, the main built-in UI consists of **buttons** and **LEDs**.
But there *are* other UI parts too...
Soon you'll explore how users can also interact with the **accelerometer** and **external connectors**!
`
  }
  </Markdown>
  )
}

const helpSyntaxHighlighting = {
  name: 'Syntax Highlighting',
  desc: 'Why the code has colors',
  category: hintCategory.CODESPACE,
  matches: ['color'],
  hint: (
  <Markdown>
  {
`Wondering how to add colors to the Text Editor panel?
> No problem!

The Editor **automatically** colors Python keywords and other *language syntax* to make your code easier to read.
This is called *syntax highlighting*, and it's a common feature of text editors built for coding.

**Syntax?**
That's just a fancy word for the "rules" about how words and phrases go together to form a language. Computer languages
and human languages alike have *syntax* rules to follow if you want to be understood!
`
  }
  </Markdown>
  )
}

const helpAdvancedDebugging = {
  name: 'Advanced Debugging',
  desc: 'Additional debugger features',
  category: hintCategory.CODESPACE,
  matches: ['advanced debugging'],
  hint: (
  <Markdown>
  {
`CodeSpace includes advanced debugging tools to give more control over a program being debugged.

To enable advanced debugging, use the view menu (View &rarr; Show Advanced Debug). Three new buttons will
appear on the toolbar:
`}
<img src={StepOverPic} alt="" width={18} style={{float:'left', margin:4}} />
{`
#### Step Next
* The **Step Next** button will step to the next line, *not* entering function calls.

`}
<img src={StepIntoPic} alt="" width={18} style={{float:'left', margin:4}} />
{`
#### Step Into
* The **Step Into** button will step to the next line, *and* into functions along the way.
  * This is the same *Step* function you're used to!

`}
<img src={StepOutPic} alt="" width={18} style={{float:'left', margin:4, marginTop:6}} />
{`
#### Step Out
* The **Step Out** button *returns* from the current function, letting you *step* from where it was called.
`
  }
  </Markdown>
  )
}

const helpBlankLines = {
  name: 'Blank Lines and Whitespace',
  desc: 'Spaced out code',
  category: hintCategory.LANGUAGE,
  matches: ['blank line', 'whitespace'],
  hint: (
<Markdown>
{`Like most programming languages, *Python* **ignores** blank lines in your code.

Adding blank lines can help make your code more *readable*. A good practice is to use blank lines to separate
logical sections of your code.

Additional *Whitespace* (spaces) in your code should be used in keeping with the accepted style of the language.
The example code in *CodeSpace* follows the official Python Style Guide (PEP-008).
`}
<PyDocsButton name="Style Guide" pyref="https://www.python.org/dev/peps/pep-0008/" />
</Markdown>
  )
}

const helpDebugging = {
  name: 'Debugging',
  desc: 'Fixing your code',
  category: hintCategory.LORE,
  matches: ['debug', 'debugging'],
  hint: (
  <div>
  <Markdown>
  {
`#### What is a 'bug'?
When your program doesn't do what you intended, it's called a **bug**.

Actually, most of the time the computer is doing *exactly* what you told it to do! But as a program gets bigger and more complex,
it gets harder for us humans to understand. **Debugging** is the process of understanding what the computer is *actually* doing, so you
can change the code to do what you *want* it to do.
___
`}
<img src={HoppersMoth} id='graceHopperBug' alt="Grace Hopper's Bug" width={300} style={{float: 'right', marginLeft: 8}} />
{`
On rare occasions there may be a bug that's a problem in *hardware* rather than your code. One famous bug was noted by renowned
computer scientist Admiral Grace Hopper, in her logbook shown here, while doing research using a US Navy computer in 1947.

Yes, that's an *actual* bug - a moth was stuck in one of the computer's mechanical relays!

#### How are bugs fixed?

Apart from checking the computer for moths hanging around, programmers often use additional software, called a *debugger*.
Debuggers allow *stepping* through a program and viewing its progress, variables, etc., one line at a time.

Watching how each statement changes the program's ==control flow== and ==variables== makes bugs much easier to find and fix.
`
  }
  </Markdown>
  </div>
  )
}

const helpFunction = {
  name: 'Functions',
  desc: 'Reusable chunks of code',
  category: hintCategory.LANGUAGE,
  matches: ['function'],
  hint: (
  <Markdown>
  {
`A function is a named chunk of code you can run anytime just by calling its name!

In other programming languages functions are sometimes called **procedures**. Functions can
also be bundled with *objects*, where they're referred to as **methods**. Whatever you call them, they are a good
way to package up useful sections of code you can use over and over again!

In Python you can define a new function like this:
\`\`\`
def flashSmile():
    display.show(Image.HAPPY)
    sleep(500)
    display.clear()
    sleep(500)
\`\`\`

Once that's defined, you can call the function whenever you like:
\`\`\`
while True:
    flashSmile()
\`\`\`

You can also pass ==parameters== to a function, and get a ==return== value back:
\`\`\`
def addTen(x):
    return x + 10

result = addTen(2)
# Now result is 12
\`\`\`
`
  }
  </Markdown>
  )
}

const helpParameters = {
  name: "Function Parameters and Returns",
  desc: 'Getting things in and out of functions',
  category: hintCategory.LANGUAGE,
  matches: ['parameter', 'return', 'argument'],
  hint: (
  <Markdown>
  {
`### Functions are a two-way street!
* You can pass things **IN** to a function when you *call* it.
  * Send values to a function inside *parenthesis*.
* When it finishes, it can pass things **OUT** - back to your code that *called* it.
  * A function can use the \`return\` statement to send a *value* back to the caller.
  * The \`return\` statement ends the function.
  * Your code substitutes the *return* **value** for the \`call()\`.

Here's a simple function that calculates the *square* of the number that's passed to it:
\`\`\`
def square(n):
    return n * n
\`\`\`

Now we can call this function whenever we need it:
\`\`\`
area = square(5)
\`\`\`
...In this case, **area** will be 25.

**Terms:**

The \`return\` statement:
* *Exits* the function
* Replaces the *calling* statement with the returned value

When you **define** a new function, you can declare a list of *names* in parenthesis.
* These are called function **parameters**
* Inside the function, they act like *local variables*

When you **call** a function, you can *pass* it a list of **values** in parenthesis.
* These are called function **arguments**
`
  }
  </Markdown>
  )
}

const helpComparisonOperators = {
  name: 'Comparison Operators',
  desc: 'Testing different conditions',
  category: hintCategory.LANGUAGE,
  matches: ['comparison operator', 'comparison operation', 'comparison'],
  hint: (
  <Markdown>
  {
`Expressions like \`x > 9\` and \`name == "Guido van Rossum"\` let you **compare** two values.

* The result of a *comparison* is a **True** or **False** ==boolean== value.

**For Example:** given that the value assigned to \`x\` is \`5\` :
> \`x > 10\` is \`False\`

> \`x < 10\` is \`True\`

> \`x == 5\` is \`True\`

Another example: ==branching==
\`\`\`
if var_happiness > 10:
    display.show(Image.HAPPY)
\`\`\`
In the example above, \`>\` is the "greater than" operator, and it makes the expression \`var_happiness > 10\` evaluate to True if the value of \`var_happiness\` is greater than 10.

You can read the expression almost like a sentence: "If var_happiness is *greater than* 10, run the code below."

**Comparison Operators:**
| Operator | Description                |
|----------|----------------------------|
| >        | Greater than               |
| <        | Less than                  |
| ==       | Equal to                   |
| !=       | Not equal to               |
| >=       | Greater than *or* Equal to |
| <=       | Less than *or* Equal to    |

*Read about other ==Operators== too!*
`}
<PyDocsButton
  name="Comparisons"
  pyref="https://docs.python.org/3/library/stdtypes.html#comparisons"
/>
  </Markdown>
  )
}

const helpRNG = {
  name: 'Random Numbers',
  desc: 'Making code unpredictable',
  category: hintCategory.PY_LIBRARIES,
  matches: ['random number', 'random'],
  hint: (
  <Markdown>
  {
`Normally a computer can be relied on to be very **predictable**.

Each time you run a program it goes through the same sequence, starting from the first line of code
and taking one *predictable* step at a time.

But some applications need **randomness**, or *unpredictable* results:
* Games, where there shouldn't be an obvious *pattern* for the human player to learn.
* Cryptography, where randomness helps secure stored passwords and messages.
* Scientific studies, where *statistical sampling* requires random selection.

The micro:bit uses a *pseudo random number generator*, which means the "random" numbers it provides
are really just a fixed sequence that's meant to have an *unpredictable* pattern.

To use the built-in functions, they must first be imported from the \`random\` module:
\`\`\`
import random
\`\`\`

If you want to set the starting point in that sequence, use the function \`random.seed()\`:
\`\`\`
# Seed the random number generator
random.seed(1234)
\`\`\`
After *seeding* the random number generator, the following code will always start at the same point in the sequence of "random" numbers.

To generate a random number, use \`randrange\` or one of the other awesome functions in the *random* module:
\`\`\`
# Returns a random integer from 0-9
random.randrange(10)

# You can also specify start and stop for the range: [start, stop)
random.randrange(start, stop)

# Return the next random floating point number in the range [0.0, 1.0)
random.random()

# Got a list of items to choose from?
# Return a random item from the given sequence.
random.choice(my_sequence)
\`\`\`
`}
<PyDocsButton
  name="random"
  pyref="https://docs.python.org/3/library/random.html#module-random"
  mpref="https://microbit-micropython.readthedocs.io/en/latest/random.html"
/>
  </Markdown>
  )
}

const helpMathModule = {
  name: 'Math Module',
  desc: 'A scientific calculator for your code',
  category: hintCategory.PY_LIBRARIES,
  matches: ['math module'],
  hint: (
  <Markdown>
<img src={ImageCalculator} alt="" style={{width: '15%', float:'right', marginRight:10, marginLeft:10}} />
{`Sometimes your *coding challenges* require a little **mathematics**.

Python's **math** module includes a set of mathematical ==functions== and ==constants==.
* Trigonometric operations
* Logarithms and exponents
* ...and more!

\`\`\`
import math

# Show off some 'math' module features.
print("pi = ", math.pi)
print("base of the natural logarithm: e = ", math.e)
print("sin(pi/2) = ", math.sin(math.pi / 2))
\`\`\`
`}
<PyDocsButton
  name="math"
  pyref="https://docs.python.org/3/library/math.html#module-math"
  mpref="https://docs.micropython.org/en/latest/library/math.html"
/>
  </Markdown>
  )
}

const helpTimeModule = {
  name: 'Time Module',
  desc: 'Waiting, measuring, and watching the clock tick by.',
  category: hintCategory.PY_LIBRARIES,
  matches: ['time module', 'timing'],
  hint: (
  <Markdown>
<img src={ImageStopwatch} alt="" style={{width: '20%', float:'right', marginRight:10, marginLeft:10}} />
{`Python's **time** module provides various time-related ==functions==.

Note that many *embedded systems* like CodeBot and micro:bit don't have a "Real Time Clock" enabled, so some of 
the functions dealing with *"time of day"* are not available.
* Full implementations of the **time** module have many more features than the MicroPython version. *See the links below for details.*

For your *embedded MicroPython* applications, the most useful *time* functions are:
* Waiting for specified *delays* with \`sleep_XX()\` functions.
  * These functions *block* - that is, they don't return until the specified delay has elapsed.
* Checking the current *elapsed time* with \`ticks_XX()\` functions.
  * These functions return *immediately* with the current elapsed time count.

**Note:**
* The \`ticks()\` functions are very useful for marking the passage of time in your code.
* These counters start at **zero** when your device ==boots==, and keep counting *up* from there while it's running.
* *BUT* they can't keep counting to *infinity!* At some point the counters will **wrap-around** back to **zero**.
  * If you're doing calculations based on **ticks** your code needs to account for that! That's where \`ticks_add()\` and \`ticks_diff()\` come in handy.
\`\`\`
import time

# Delay functions: block program execution for specified time interval.
time.sleep(seconds)
time.sleep_ms(milliseconds)
time.sleep_us(microseconds)

# Counter functions: return instantaneous time counts.
time.ticks_ms()   # millisecond counter
time.ticks_us()   # microsecond counter

# Math that compensates for ticks "wrap-around-to-zero"
time.ticks_add(ticks, delta)  # returns (ticks + delta)
time.ticks_diff(ticks1, ticks2)  # returns (ticks1 - ticks2)
\`\`\`
`}
<PyDocsButton
  name="time"
  pyref="https://docs.python.org/3/library/time.html"
  mpref="https://docs.micropython.org/en/latest/library/utime.html"
/>
  </Markdown>
  )
}

const helpTypes = {
  name: 'Data Types',
  desc: 'str, int, float, and friends',
  category: hintCategory.LANGUAGE,
  matches: ['type', 'data type'],
  hint: (
  <Markdown>
  {
`**"Data"** just means *information* your code works with:
* whole numbers (integers),
* text (strings),
* and even the <mark>\`Image\`</mark> type you first used to display a \`HEART\` on the micro:bit.

Every variable has a type!

\`\`\`
zipcode = 35758     # an integer type
city = "Madison"    # a string type
temperature = 98.6  # a float type
hot = True          # a bool type
\`\`\`

You can use the \`type()\` builtin function to read a variable's type name.

Check out the builtin types: ==str==, ==int==, ==float==, ==bool==
`}
  </Markdown>
  )
}

const helpPixel = {
  name: 'Pixel',
  desc: 'Elements of a picture',
  category: hintCategory.LORE,
  matches: ['pixel'],
  hint: (
  <Markdown>
  <img src={PixelImg} alt="" width={'10%'} style={{float:'right', transform: 'rotate(180deg)'}} />
  {
`The term **pixel** is short for "picture element". In computer graphics, pixels are the small "dots" used to compose larger images.

When you're looking at your computer or phone screen, you may not be able to **see** the *tiny dots* that
make up all the images and text, without a magnifying glass or microscope.

*But they **are** there!*  {centered}

The micro:bit's *pixels* are easy to spot. 25 LEDs at your command!
`}
<img src={MbSwordImg} alt="" width={'50%'} style={{display:'block', margin:'auto'}} />
  </Markdown>
  )
}

const helpLogicalOperators = {
  name: 'Logical Operators',
  desc: 'and, or, not',
  category: hintCategory.LANGUAGE,
  matches: ['or', 'logical operator'],
  hint: (
  <Markdown>
  {
`You've seen how ==branching== and ==loops== control the flow of your program with **True** / **False** decision points such as:
* ==Comparison operations== like \`x > 51\` (which are also **True** or **False**)
* ==Functions== like \`buttton_a.is_pressed()\` that *return* **True** or **False**,

What if you have *multiple conditions* to compare - like **two buttons**, testing if *either one **or** the other **or** both* is **True**?
\`\`\`
if button_a.is_pressed() or button_b.is_pressed():
    display.scroll("A button was pressed!")
\`\`\`

Python provides the following **logical operators** to handle combinations of ==Boolean== results:
\`\`\`
and
or
not
\`\`\`

*Read about other ==Operators== too!*
`}
<PyDocsButton
  name="Boolean operations"
  pyref="https://docs.python.org/3/library/stdtypes.html#boolean-operations-and-or-not"
/>
  </Markdown>
  )
}

const helpButtonFunctions = {
  name: 'Buttons',
  desc: 'Reading buttons A and B',
  category: hintCategory.MB_LIBRARIES,
  matches: ['button'],
  hint: (
  <Markdown>
  {
`The micro:bit has several built-in functions to check the state of **Buttons A and B**.

To use these functions, they must first be imported from the \`microbit\` module:
\`\`\`
from microbit import *
\`\`\`

Then they may be used with either button to collect user input:
\`\`\`
# Returns True if button A has been pressed since last call
button_a.was_pressed()

# Returns True if button B is currently pressed
button_b.is_pressed()

# Returns the number of times Button A was pressed since the last call
button_a.get_presses()
\`\`\`
`
  }
  </Markdown>
  )
}

const helpRadioFunctions = {
  name: 'Radio',
  desc: 'Wireless Communication',
  category: hintCategory.MB_LIBRARIES,
  matches: ['radio'],
  hint: (
  <Markdown>
  {
`The micro:bit has a built-in **radio** that can be used to send and receive messages with other micro:bits.

To use the radio, first import the \`radio\` module:
\`\`\`
import radio
\`\`\`

This module provides functions like \`radio.send()\` and \`radio.receive()\` that let you send and receive message strings.
There's also a \`radio.config()\` function to configure the channel and other optional settings.
* Valid channel numbers are 0-83.
* micro:bits have to be set to the *same channel* to hear each other!
* But, if there are other people using micro:bit radios nearby, you may want to set each pair of micro:bits to a *unique* channel
  so that radio tests don't interfere with one another.

\`\`\`
# Turn the radio ON
radio.on()

# Send a message string over the air.
radio.send(message)

# Return a message string if one has been received, or an empty string if nothing has been received.
radio.receive()

# Set configuration parameters. For example, 'channel' can be set to a value from 0-83.
radio.config(channel=N)

# There are several more advanced functions available.
# -- See full micro:bit documentation in the Help menu for more details.
\`\`\`

The micro:bit radio hardware uses the ==2.4GHz== license-free frequency band to communicate over the air!
`
  }
  </Markdown>
  )
}

const helpSimulations = {
  name: 'Computer Simulations',
  desc: 'Creating virtual worlds with code',
  category: hintCategory.LORE,
  matches: ['simulation'],
  hint: (
  <Markdown>
  {
`A computer simulation is code that builds a *model* of something, and lets you play with that model.

> In a video game, the *model* could be a **Race Car**, **Football Team**, or a **Fictional Creature**.

Simulations let you explore "virtual" situations, both realistic and imaginary, that might be difficult or impossible to
do in the real world.
`}
<img src={SimulationImg} alt="" width={300} style={{float: 'right'}} />
{`
#### Simulations are used in a huge variety of applications:
* Designing and testing of airplanes and spacecraft
* Video games
* Running flight simulators to train pilots
* Forecasting the weather
* Testing traffic adjustments to design better roadways
* Investigating soil chemistry in agriculture
* Validating electrical circuits
* Testing bridges and buildings before construction
`
  }
  </Markdown>
  )
}

const helpQuoteDivideAndConquer = {
  name: 'Divide and Conquer',
  desc: 'Handling complexity',
  category: hintCategory.LORE,
  matches: ['divide and conquer'],
  hint: (
  <Markdown>
  {
`
*"The most fundamental problem in software development is complexity. There is only one basic way of dealing with complexity: **divide and conquer**."*

-- Bjarne Stroustrup, *Creator of the C++ programming language*
`
  }
  </Markdown>
  )
}

const helpAccelerometer = {
  name: 'Accelerometer',
  desc: 'Sensing Acceleration',
  category: hintCategory.MB_LIBRARIES,
  matches: ['accelerometer'],
  hint: (
  <Markdown>
  {
`
An accelerometer is a device that measures *proper acceleration*.
One way you commonly experience *acceleration* is when you're moving and your speed or direction of motion
changes. Think of speeding up in a car, the force you feel pushing you back in your seat or sideways when going
through a turn. The forces you're feeling are due to *acceleration*.
> So if you're standing still, acceleration is *zero*, right?

**No!** Well, not unless you're floating in space!

*Gravity* also causes acceleration. So even if your accelerometer is sitting still on your desk, it will measure
an acceleration due to the force of gravity pulling straight down toward the floor.

In a *zero-g* environment, like outer space, or if an object is in *free-fall*, the accelerometer will measure *zero* acceleration
in all directions.

The micro:bit's accelerometer measures the force of acceleration in 3-directions:
> In the picture below, if this micro:bit is standing still on Earth, it will measure *gravitational acceleration* (1g) in
the **Y** direction, but *zero* in the **X** and **Z** directions.
`}
<img src={MicrobitAxesImg} alt="" width={'50%'} style={{display:'block', margin:'auto'}} />
{`
Accelerometer functions are in the **microbit** module.
\`\`\`
from microbit import *

x = accelerometer.get_x()
y = accelerometer.get_y()
z = accelerometer.get_z()
\`\`\`
The values are in **milli-g**, so a value of 1000 is approximately 1g (the Earth's gravitational acceleration).
> The full range of values measured is from -2048 to +2047, which is about +/- 2g.

*Gotta shake the micro:bit to see values higher than 1g!*

The micro:bit accelerometer library also has functions to detect **gestures**:
\`\`\`
gesture = accelerometer.current_gesture()
\`\`\`

Gestures can be any of:
\`\`\`
"up"
"down"
"left"
"right"
"face up"
"face down"
"freefall"
"shake"
"3g"
"6g"
"8g"
\`\`\`
`
  }
  </Markdown>
  )
}

const helpMagnetometer = {
  name: 'Magnetometer',
  desc: 'Sensing magnetic fields',
  category: hintCategory.MB_LIBRARIES,
  matches: ['magnetometer', 'compass'],
  hint: (
  <Markdown>
  <img src={MicrobitAxesImg} alt="" width={'30%'} style={{display:'block', margin:'auto', float:'right'}} />
{`
A magnetometer is a device that measures *magnetic field strength and direction*.

The micro:bit *magnetometer* chip can detect *magnetic fields* produced by **magnets** or even by the **Earth's *geomagnetic field***.

Because it can be used as a **compass**, the *magnetometer functions* are in the \`compass\` object.
\`\`\`
from microbit import *

# Get the magnetic field strength around the device in nano-Tesla
compass.get_field_strength()

# Get magnetic field strength along the X, Y, and Z axes.
compass.get_x()
compass.get_y()
compass.get_z()
\`\`\`
The micro:bit is able to determine its direction relative to *Magnetic North* after a **calibration**.
* The \`compass\` object has some helper functions to do this also:
\`\`\`
# Starts compass calibration, which includes a small game to turn the micro:bit and fill a circle.
compass.calibrate()

# Returns the compass heading with values 0 to 360, with 0 as North.
compass.heading()
\`\`\`
`
  }
  </Markdown>
  )
}

const helpInt = {
  name: 'int',
  desc: 'Integer number type',
  category: hintCategory.LANGUAGE,
  matches: ['int', 'integer'],
  hint: (
  <Markdown>
  {
`An **integer** is a whole number (no fractions) that can be positive, negative, or even *zero*.

You define an **int** using a number *without* a decimal point.
\`\`\`
num_trombones = 76
\`\`\`

In Python, you can convert a decimal number or ==string== to an **integer** with \`int()\`

Examples:
\`\`\`
int(7.9) # Returns 7

int("25") # Returns 25
\`\`\`
Notice that \`int()\` does **not** *round* up. It just chops off the *fractional* part!

### Binary and Hexadecimal too!
By default when you write an *integer literal* Python interprets it as a decimal (base-10) number.
But you can also define an integer in ==binary== (base-2) or **hex** (base-16) by using *prefixes* \`0b\` or \`0x\` as shown below:
\`\`\`
# Set value to 12 (that's 1100 in binary)
value = 0b1100

# Set value to 12 (that's 0C in hexadecimal)
value = 0x0C
\`\`\`
The above statements produce *exactly* the same result as:
\`\`\`
# Set value to 12
value = 12
\`\`\`
`}
<PyDocsButton
  name="Numeric Types"
  pyref="https://docs.python.org/3/library/stdtypes.html#numeric-types-int-float-complex"
/>
  </Markdown>
  )
}

const helpFloat = {
  name: 'float',
  desc: 'Floating point number type',
  category: hintCategory.LANGUAGE,
  matches: ['float'],
  hint: (
  <Markdown>
{`A **float** is a *real* number with a decimal point. It can hold a fraction, like:
\`\`\`
PI = 3.14
body_temp = 98.6
\`\`\`
In Python, you can convert an ==integer== or ==string== to a **float** with \`float()\`

Examples:
\`\`\`
float(7) # Returns 7.0

float("2.71828") # Returns 2.71828
\`\`\`
`}
<PyDocsButton
  name="Numeric Types"
  pyref="https://docs.python.org/3/library/stdtypes.html#numeric-types-int-float-complex"
/>
  </Markdown>
  )
}

const helpASCII = {
  name: 'Character Encoding',
  desc: 'From numbers to symbols and back (ASCII and friends)',
  category: hintCategory.LORE,
  matches: ['ascii', 'character encoding'],
  hint: (
  <Markdown>
<img src={DecoderRing} alt="" width={'25%'} style={{display:'block', margin:'auto', marginRight:10, float:'right'}} />
{`#### Computer memory is just a sequence of ==bytes==
* ...and each *byte* is a number from 0-255.

So how does the computer *encode* a bunch of numbers into text strings like "Hello, World"?
* *Simple!* Each *letter* of the alphabet has its own assigned number.
* That goes for *punctuation* and other symbols too. Altogether they're called **characters**.

#### Here's an example of how you might encode the first three letters of the alphabet:
* 'A' &rarr; 65
* 'B' &rarr; 66
* 'C' &rarr; 67

*Those numbers may seem random*, but they're the actual values used for A, B, & C in the **ASCII** character set,
which is the basic character encoding used by most computers.
* ASCII stands for *"American Standard Code for Information Interchange"*.
* Many other *character encodings* are available today, but ASCII is usually the basis for the English (Latin) alphabet.
* The **Unicode** standard covers *character encoding* for most of the world's written languages.

Python ==strings== can be translated *to and from* **ASCII integers** using the functions:
\`\`\`
ord(c)   # Return the integer Character Encoding for
         # the single-character string 'c'.

chr(n)   # Return the string whose Character Encoding
         # is the integer 'n'
\`\`\`
#### Table of Printable ASCII Characters
<pre>
ord()     chr()
----------------------------------------
 32       SP (Space)
 33       !  (exclamation mark)
 34       "  (double quote)
 35       #  (number sign)
 36       $  (dollar sign)
 37       %  (percent)
 38       &  (ampersand)
 39       '  (single quote)
 40       (  (left opening parenthesis)
 41       )  (right closing parenthesis
 42       *  (asterisk))
 43       +  (plus)
 44       ,  (comma)
 45       -  (minus or dash)
 46       .  (dot)
 47       /  (forward slash)
 48       0
 49       1
 50       2
 51       3
 52       4
 53       5
 54       6
 55       7
 56       8
 57       9
 58       :  (colon)
 59       ;  (semi-colon)
 60       <  (less than sign)
 61       =  (equal sign)
 62       >  (greater than sign)
 63       ?  (question mark)
 64       @  (AT symbol)
 65       A
 66       B
 67       C
 68       D
 69       E
 70       F
 71       G
 72       H
 73       I
 74       J
 75       K
 76       L
 77       M
 78       N
 79       O
 80       P
 81       Q
 82       R
 83       S
 84       T
 85       U
 86       V
 87       W
 88       X
 89       Y
 90       Z
 91       [  (left opening bracket)
 92       \\  (back slash)
 93       ]  (right closing bracket)
 94       ^  (caret cirumflex)
 95       _  (underscore)
 96       \` (backtick)
 97       a
 98       b
 99       c
100       d
101       e
102       f
103       g
104       h
105       i
106       j
107       k
108       l
109       m
110       n
111       o
112       p
113       q
114       r
115       s
116       t
117       u
118       v
119       w
120       x
121       y
122       z
123       {  (left opening brace)
124       |  (vertical bar)
125       }  (right closing brace)
126       ~  (tilde)
</pre>
`
  }
  </Markdown>
  )
}

const helpString = {
  name: 'str',
  desc: 'String type',
  category: hintCategory.LANGUAGE,
  matches: ['string', 'str', 'escape sequence', 'str()'],
  hint: (
  <Markdown>
{`A **string** is a sequence of characters, like words or sentences.
\`\`\`
name = "Firia"
occupation = "Teaching Robot"
\`\`\`
Notice that you surround characters in **"** quotes to create **strings**.

Actually, you can use *single* or *double* quotes:
\`\`\`
name = 'Firia'

# Use different types of quote-marks to enclose quotations:
salutation = 'Just call me "Firia" if you like.'
\`\`\`
You can convert other ==types== to *string* with \`str()\`

Examples:
\`\`\`
str(7) # Returns "7"
\`\`\`
#### More *string* features:
There are a lot more capabilities of *string types*. Below are a few that you might find useful:
\`\`\`
# Example string
text = "This is a test"

text[i]   # Return a single-character at position 'i' in the string
          # Ex: text[0] is 'T', and text[1] is 'h'

text[i:j]  # slice of string from index i up to but not including j
           # Ex: text[1:3] is "hi"

text[i:]   # slice of string from index i to end of string.
           # Ex: text[5:] is "is a test"

text[:j]   # slice of string from 0 up to but not including j.
           # Ex: text[:4] is "This"

len(text)  # (14)  Number of characters in string

text = text + "er"  # Append another string
                    # Now text is "This is a tester"

# Convert between the ASCII value (ord) and Character symbol (chr) of a 1-char string
ord('A')   # 65
chr(65)    # 'A'
\`\`\`
*See ==Character Encoding== for more details on \`ord()\` and \`chr()\`*

There's much more to learn about **strings** - for example, you can insert special characters in your strings by
using **Escape Sequences** which begin with the *backslash* **&#92;** character.
#### Example Escape Sequences:
<pre>
&#92;&#92;      Backslash
&#92;'      Single Quote
&#92;"      Double Quote
&#92;n      New Line
</pre>
`}
<PyDocsButton
  name="String"
  pyref="https://docs.python.org/3/library/stdtypes.html#text-sequence-type-str"
/>
  </Markdown>
  )
}

const helpList = {
  name: 'list',
  desc: 'List type',
  category: hintCategory.LANGUAGE,
  matches: ['list'],
  hint: (
  <Markdown>
{`A **list** is a sequence of items that you can access with an *index*.
\`\`\`
# Define a list of 3 color strings
colors = ["Red", "Green", "Blue"]

colors[0]  # "Red"
colors[1]  # "Green"
colors[2]  # "Blue"

len(colors) # 3
\`\`\`
Use *square brackets* **[]** to define a *list*, **and** to *index* an item.
> **Notice:** The *first* item in a list is at ***index = 0*** !

#### Lists can be *modified*:
Here are a few ways. Consult Python *sequence* docs (below) for more!
\`\`\`
# Replace an item:
colors[1] = "Orange"  # New colors == ["Red", "Orange", "Blue"]

# Delete an item:
del colors[1]  # New colors == ["Red", "Blue"]

# Append an item:
colors.append("Yellow")  # New colors == ["Red", "Blue", "Yellow"]

# Insert an item at index i: insert(i, new_item)
colors.insert(0, "Black")  # New colors == ["Black", Red", "Blue", "Yellow"]
\`\`\`

#### More *list* features:
There are a lot more capabilities of *sequence types* like lists. Below are a few that you might find useful:
\`\`\`
# Example list
seq = [100, 101, 102, 103]

seq[i:j]  # slice of seq from index i up to but not including j
seq[1:3]  # [101, 102]

len(seq)  # (4)  Number of items in list
min(seq)  # (100) Smallest item
max(seq)  # (103) Largest item

101 in seq  # (True) True if an item of seq is equal to 101

# Make a copy of seq
new_seq = seq.copy()
\`\`\`
`}
<PyDocsButton
  name="Sequences"
  pyref="https://docs.python.org/3/library/stdtypes.html#sequence-types-list-tuple-range"
/>
  </Markdown>
  )
}

const helpTuple = {
  name: 'tuple',
  desc: 'Tuple type',
  category: hintCategory.LANGUAGE,
  matches: ['tuple'],
  hint: (
  <Markdown>
{`A **tuple** is an *immutable* sequence of items that you can access with an *index*.
* The word **immutable** just means the contents can't be changed.
* Think of it as a *read-only* version of a ==list==.
\`\`\`
# Define tuples of (left_speed, right_speed)
forward = (50, 50)
reverse = (-50,-50)
rotate_right = (35, -35)
rotate_left = (-35, 35)

# Move forward
motors.run(LEFT, forward[0])
motors.run(RIGHT, forward[1])

\`\`\`
* Use *parenthesis* **()** to define a *tuple*.
* Use *square brackets* **[]** to *index* an item.

> **Note:** The *first* item in a tuple is at ***index = 0*** !

### More *tuple* features:
Tuples support the common capabilities of ***sequence types***, like:
\`\`\`
# Example tuple
seq = (100, 101, 102, 103)

seq[i:j]  # slice of seq from index i up to but not including j
seq[1:3]  # (101, 102)

len(seq)  # (4)  Number of items in list
min(seq)  # (100) Smallest item
max(seq)  # (103) Largest item

101 in seq  # (True) True if an item of seq is equal to 101
\`\`\`
`}
<PyDocsButton
  name="Sequences"
  pyref="https://docs.python.org/3/library/stdtypes.html#sequence-types-list-tuple-range"
/>
  </Markdown>
  )
}

const helpEditor = {
  name: 'Editor Shortcuts',
  desc: 'Keyboard hotkeys to write code faster',
  category: hintCategory.CODESPACE,
  matches: ['editor shortcut'],
  hint: (
  <Markdown>
  {
`Hotkeys in CodeSpace are combinations of keys which complete a task.
They are written with a '**-**' between two key names, like **CTRL-Z**,
which you would use by pressing the **Control** key *and* **z** at the same time, then releasing.

#### CTRL-X  Cut
* Cutting text will **remove** it from the editor, and store it to be Pasted later.
* In the Editor you can **select** text using the mouse, or hold down shift and use the arrow keys on your keyboard.
* **Cut** the selected text using **CTRL-X** on the keyboard, or the *Edit* menu.

#### CTRL-C  Copy
* Copying text will **not change** what's in the editor, but will store a copy to be Pasted later.
* In the Editor you can **select** text using the mouse, or hold down shift and use the arrow keys on your keyboard.
* **Copy** the selected text using **CTRL-C** on the keyboard, or the *Edit* menu.

#### CTRL-V  Paste
* Put the editor cursor where you want to insert text, and press **CTRL-V** or use the *Edit* menu to **Paste**.

#### CTRL-Z  Undo
* **Undo** the last change to your code using **CTRL-Z** on the keyboard, or the **Undo** toolbar button.

#### CTRL-F  Search
* **Search** for text in your program using **CTRL-F** on the keyboard, or the **Search** toolbar button.

#### CTRL-H  Replace
* **Search and Replace** text in your program using **CTRL-H** on the keyboard.

#### TAB or SHIFT-TAB  Indent
* **Indent** or **Unindent** a selected block of text in your program.
`
  }
  </Markdown>
  )
}

const helpCpuPeripherals = {
  name: 'CPU and Peripherals',
  desc: 'Parts of the Computer',
  category: hintCategory.LORE,
  matches: ['peripherals', 'computer peripherals', 'cpu', 'peripheral'],
  hint: (
  <Markdown>
  {
`The "brain" of the computer that *executes* your code is the Central Processing Unit (CPU) and memory system.
When you interact with a computer, it is the **Peripheral** devices that you touch, see, and hear.

**Common Peripherals:**
* LED lights
* Display monitor
* Push buttons
* Keyboard
* Mouse / Trackpad
* Speakers
* Printers (2D, 3D)

Peripherals that bring information **INTO** the CPU are called **Input** devices. Those that send information **OUT** of
the computer are **Output** devices. Some peripherals have both *input* and *output* functions.

*Think of the computer peripherals you interact with every day...*

*If your brain is your body's CPU, what are its peripherals?*
`
  }
  </Markdown>
  )
}

const helpMusic = {
  name: 'Music',
  desc: 'micro:bit melodies',
  category: hintCategory.MB_LIBRARIES,
  matches: ['music'],
  hint: (
  <Markdown>
  {
`
To use these tunes, they must first be imported from the \`music\` module:
\`\`\`
import music
\`\`\`

The built-in melodies:

* \`music.DADADADUM\`
* \`music.ENTERTAINER\`
* \`music.PRELUDE\`
* \`music.ODE\`
* \`music.NYAN\`
* \`music.RINGTONE\`
* \`music.FUNK\`
* \`music.BLUES\`
* \`music.BIRTHDAY\`
* \`music.WEDDING\`
* \`music.FUNERAL\`
* \`music.PUNCHLINE\`
* \`music.PYTHON\`
* \`music.BADDY\`
* \`music.CHASE\`
* \`music.BA_DING\`
* \`music.WAWAWAWAA\`
* \`music.JUMP_UP\`
* \`music.JUMP_DOWN\`
* \`music.POWER_UP\`
* \`music.POWER_DOWN\`

To play a song, pass it to \`music.play\`
\`\`\`
music.play(music.NYAN)
\`\`\`
Defining your own songs is easy too:
\`\`\`
# A song is a list of notes: ['NOTE:TICKS', ...]
# in Scientific Pitch Notation
arpeggio = ['c1:4', 'e:2', 'g', 'c2:4']
music.play(arpeggio)

# Set tempo to 4 ticks per beat, 120 beats per minute
music.set_tempo(ticks=4, bpm=120)

# Additional params for music.play
music.play(music, pin=microbit.pin0, wait=True, loop=False)

# Play a sound for 'duration' milliseconds. -1 means continuous.
music.pitch(frequency, duration=-1, pin=microbit.pin0, wait=True)

# Stop music on given pin
music.stop(pin=microbit.pin0)
\`\`\`
`
  }
  </Markdown>
  )
}

const helpDisplay = {
  name: 'Display',
  desc: 'micro:bit LED display',
  category: hintCategory.MB_LIBRARIES,
  matches: ['display'],
  hint: (
  <Markdown>
{`The LED display is arranged as a 5x5 grid, which can display ==Images==, ==strings==, or ==pixels==.
`}
<img src={LedMatrixImg} alt="" width={250} style={{margin: 'auto', display: 'block'}} />
{`
> *The display continues the last action your code tells it to do, even **after** your program ends.*

To use the display, import the \`microbit\` module:
\`\`\`
from microbit import *

# Display an image (ex: HEART)
display.show(Image.HEART)

# More params for show
display.show(images, delay=400, wait=True, loop=False, clear=False)

# Scroll text across the display
display.scroll(string, delay=150, wait=True, loop=False, monospace=False)

# Turn all LEDs off
display.clear()

# Set single LED on to brightness=value (0=OFF, 9=MAX)
display.set_pixel(x, y, value)

# Read the brighness of single LED
display.get_pixel(x, y)
\`\`\`
`
  }
  </Markdown>
  )
}

const helpImages = {
  name: 'Images',
  desc: 'micro:bit Images',
  category: hintCategory.MB_LIBRARIES,
  matches: ['image'],
  hint: (
  <Markdown>
  {
`The micro:bit can show Images on its ==LED== matrix using the ==display== functions.

To use **Images**, first import the \`microbit\` module:
\`\`\`
from microbit import *
\`\`\`

An \`Image\` can be stored in a variable, just like a *built-in* Python ==type== such as **int**.
* \`Image\` is called a **class**, and you can use it a bit like a *function* to make your own pictures.
* As you've seen from your very first \`Image.HEART\`, the \`Image\` class has lots of ==built-in Images== too.

To make a *custom* image, use a ==string== that looks just like the display:
* A number (0-9) for each ==pixel== (LED).
  * 0=OFF, 9=MAX
* Use 25 numbers, since the display has 5x5 = 25 pixels.
* After each set of 5 numbers, add a colon to end the row.

**Ex:** Zero means OFF, so this would be totally dark:
> \`dark = Image("00000:00000:00000:00000:00000:")\`

**Ex:** Here's a big X:
> \`big_x = Image("90009:09090:00900:09090:90009:")\`

**Wait!** Those strings don't look *anything* like the LED display!
> **Fortunately** there's a convenient way to span a string across multiple lines:
\`\`\`
big_x = Image("90009:"
              "09090:"
              "00900:"
              "09090:"
              "90009")
\`\`\`
Can you see the **X** shape above? You could replace the 9's with a lower number to get a *dimmer* version of the **X**.

Here's the full code for this *custom Image* example:
\`\`\`
from microbit import *

# Construct a custom Image object, and display it.
big_x = Image("90009:"
              "09090:"
              "00900:"
              "09090:"
              "90009")
display.show(big_x)
\`\`\`

#### The \`Image\` class also provides functions for modifying pixels and making new Images:
\`\`\`
# Set or get an individual pixel in the image (just like display)
set_pixel(x, y, value)
get_pixel(x, y)

# Set all pixels in image to the given brightness level
fill(level)

# Create a new "shifted" version of the image, and return it.
#   Note: these don't modify the image itself. Instead they return a new one.
shift_up(n)
shift_down(n)
shift_left(n)
shift_right(n)

# Create a new image with opposite brightness level for all pixels
invert()
\`\`\`
`
  }
  </Markdown>
  )
}

const helpBuiltinImages = {
  name: 'Built-in Images',
  desc: 'micro:bit picture gallery',
  category: hintCategory.MB_LIBRARIES,
  matches: ['built-in image'],
  hint: (
  <Markdown>
  {
`The micro:bit can show ==Images== on its LED matrix using the ==display== functions.

To use **Images**, first import the \`microbit\` module:
\`\`\`
from microbit import *
\`\`\`

Here are **ALL** the micro:bit's pre-defined Images:
* \`Image.HEART\`
* \`Image.HEART_SMALL\`
* \`Image.HAPPY\`
* \`Image.SMILE\`
* \`Image.SAD\`
* \`Image.CONFUSED\`
* \`Image.ANGRY\`
* \`Image.ASLEEP\`
* \`Image.SURPRISED\`
* \`Image.SILLY\`
* \`Image.FABULOUS\`
* \`Image.MEH\`
* \`Image.YES\`
* \`Image.NO\`
* \`Image.CLOCK12\`
* \`Image.CLOCK1\`
* \`Image.CLOCK2\`
* \`Image.CLOCK3\`
* \`Image.CLOCK4\`
* \`Image.CLOCK5\`
* \`Image.CLOCK6\`
* \`Image.CLOCK7\`
* \`Image.CLOCK8\`
* \`Image.CLOCK9\`
* \`Image.CLOCK10\`
* \`Image.CLOCK11\`
* \`Image.ARROW_N\`
* \`Image.ARROW_NE\`
* \`Image.ARROW_E\`
* \`Image.ARROW_SE\`
* \`Image.ARROW_S\`
* \`Image.ARROW_SW\`
* \`Image.ARROW_W\`
* \`Image.ARROW_NW\`
* \`Image.TRIANGLE\`
* \`Image.TRIANGLE_LEFT\`
* \`Image.CHESSBOARD\`
* \`Image.DIAMOND\`
* \`Image.DIAMOND_SMALL\`
* \`Image.SQUARE\`
* \`Image.SQUARE_SMALL\`
* \`Image.RABBIT\`
* \`Image.COW\`
* \`Image.MUSIC_CROTCHET\`
* \`Image.MUSIC_QUAVER\`
* \`Image.MUSIC_QUAVERS\`
* \`Image.PITCHFORK\`
* \`Image.XMAS\`
* \`Image.PACMAN\`
* \`Image.TARGET\`
* \`Image.ALL_CLOCKS\`
* \`Image.ALL_ARROWS\`
* \`Image.TSHIRT\`
* \`Image.ROLLERSKATE\`
* \`Image.DUCK\`
* \`Image.HOUSE\`
* \`Image.TORTOISE\`
* \`Image.BUTTERFLY\`
* \`Image.STICKFIGURE\`
* \`Image.GHOST\`
* \`Image.SWORD\`
* \`Image.GIRAFFE\`
* \`Image.SKULL\`
* \`Image.UMBRELLA\`
* \`Image.SNAKE\`
`
  }
  </Markdown>
  )
}

const helpADC = {
  name: 'Analog to Digital Conversion',
  desc: 'You Live in an Analog World',
  category: hintCategory.LORE,
  matches: ['adc', 'analog'],
  hint: (
  <Markdown>
<img src={DigitalLandImg} alt="" width={300} style={{margin: 'auto', display: 'block', float: 'right'}} />
{`> From complete darkness .... to bright sunlight.

> From the coldest glacier .... to the hottest desert.

"**Analog**" means **infinite variation** from *dark to light*, *cold to hot*, and so on.

But what if you want to measure something like **temperature** with a computer?
* A digital computer can't handle an *infinite* number of temperature levels.
* So it converts **analog** measurements to just a few **digits**

**For example:**
* The micro:bit function \`read_analog()\` converts an *analog* sensor input into a **number** from **0 to 1023**.

> **Aw! Instead of *infinite* temperature variations, we get just *1024* levels!!**

> Why **1024**? The computer deals in ==binary== numbers, and has a 10-bit ADC: 2<sup>10</sup> = 1024.

Fortunately the digital *approximation* of analog measurements is perfectly fine for many applications, like sensing
light or temperature with the micro:bit.

> Think about the online **video** and **music** performances you've seen. They all started as **analog** and were converted
to **digital** so we could process, store, and distribute them using computers and **code**!
  `}
  </Markdown>
  )
}

const helpBinary = {
  name: 'Binary Numbers',
  desc: 'How computers deal with digits',
  category: hintCategory.LORE,
  matches: ['binary', 'bit', 'digital'],
  hint: (
  <Markdown>
<img src={Binary1bitImg} alt="" width={200} style={{margin: 'auto', display: 'block', float: 'right'}} />
{`Inside the computer, all the tiny electrical connections that store information are like the **light switches** shown here.
* Each connection can be **ON** or **OFF**
* That's just 2 states!
* The "Bi-" in "Binary" means **2**, just like a "Bicycle" has 2 wheels!

Say you only have **one switch**, and you want to use it to show a number to someone.
* One switch can show one *binary digit*.
* So you can show *2 possible numbers*: **0** (off), or **1** (on)

*(This is why it's called a **DIGITAL** computer!)*
`}
<div style={{clear:'right'}} />
<hr />
<img src={Binary2bitImg} alt="" width={300} style={{margin: 'auto', display: 'block', float: 'right'}} />
{`### What if you have 2 switches?
* There are **4 combinations**!
* Each *switch* is like a *binary digit*
  * It's called a **bit** for short :-)

Look at the switches to the right. See how all 4 combinations are used to make the numbers 0 through 3?
* It's like a **secret code**!
* How big a number could you show with 8 switches? 10 switches? See below...

### The powers of 2
Binary means "base 2", so here's how you calculate the numbers you can make with a given set of switches (bits):
* 1 bit &rarr; 2<sup>1</sup> = 2 numbers
* 2 bits &rarr; 2<sup>2</sup> = 4 numbers
* 8 bits &rarr; 2<sup>8</sup> = 256 numbers
* 10 bits &rarr; 2<sup>10</sup> = 1024 numbers

You might have heard of a ==byte==. That's just another name for an **8-bit** number!

### Binary in Python
Usually your code deals with numbers in **decimal**, even though the computer stores them internally in binary form. But Python
does have a way to write ==integer== values directly in *binary* by prefixing the number with **0b**:
\`\`\`
# Set value to 9 (that's 1001 in binary!)
value = 0b1001
\`\`\`
`}
  </Markdown>
  )
}

const helpByte = {
  name: 'Byte',
  desc: 'A unit of computer memory.',
  category: hintCategory.LORE,
  matches: ['byte'],
  hint: (
  <Markdown>
{`A **byte** is made up of 8 ==bits==.

With 8 bits you can represent 2<sup>8</sup> = 256 numbers.

Computer memory and storage devices are often described by how many **bytes** of data they can store.
* A single **"gigabyte"** or *"gig"* is actually 1,073,741,824 bytes! *(that's over 1 billion)*
* Most computers access **memory addresses** *one byte at a time*.

Here's an example of the *memory layout* of the word **"HELLO"** in a standard ==character encoding==:
`}
<img src={MemoryBytes} alt="" width={"40%"} style={{margin: 'auto', display: 'block'}} />
{`Below is the ==binary== layout of bits in a computer, counting from 0, 1, ...up to **255**, which is the largest number
that can be stored in a single **byte**.
`}
<img src={ByteImg} alt="" width={"60%"} style={{margin: 'auto', display: 'block'}} />
  </Markdown>
  )
}

const helpConstants = {
  name: 'Constants',
  desc: 'Unchanging values',
  category: hintCategory.LANGUAGE,
  matches: ['constant','upper case'],
  hint: (
  <Markdown>
{`**Constants** are names, just like ==variables==, that can represent data in your code.
* Like a **variable**, a **constant** has a *name*, *data-type*, and *value*.
* But *unlike* a variable, a **constant's** *value should **never** change*.

Unlike some other programming languages, Python does **not** have a built-in way to declare data "constant".
___
In Python, **constants** are just **variables** that *you promise not to change*.  {centered}
___

By convention, Python constants are named with **ALL_CAPITAL_LETTERS**.
* But remember, the language does not *prevent* code from changing those variables.

**Ex:**[^first]
\`\`\`
PI = 3.14
\`\`\`
Naming them with **ALL_CAPS** is a reminder that those values shouldn't change.

[^first]: You should *really* use the ==math module== for this particular *constant* though!
`}
  </Markdown>
  )
}

const helpTiming = {
  name: 'Timing',
  desc: 'Controlling the pace of actions',
  category: hintCategory.MB_LIBRARIES,
  matches: ['sleep', 'time', 'running_time', 'delay'],
  hint: (
  <Markdown>
  {
`Usually a computer program runs as fast as it can, but sometimes you want to slow things down.
To use delay functions, they must first be imported from a Python module:
\`\`\`
# For the micro:bit
from microbit import *

# For the CodeBot or Codex
from time import sleep
\`\`\`

The first function \`sleep()\` should only be used when there is nothing else you want to accomplish during the pause.
* Functions like this are referred to as *blocking functions*, and calling \`sleep()\` directly is an example of a *blocking delay*.
\`\`\`
# Sleep for the given number of milliseconds on a micro:bit.
sleep(ms)

# Sleep for the given number of seconds on a CodeBot or Codex
sleep(sec)
\`\`\`
As an example of how \`sleep()\` can be used to control the pacing of your program:
\`\`\`
# Dramatic build up...
display.show("3")
sleep(1000)
display.show("2")
sleep(1000)
display.show("1")
sleep(1000)
# Do something impressive here...
\`\`\`
A second function on the micro:bit, \`running_time()\` does not pause your program directly, but it still enables control of pacing.
\`\`\`
# Returns the number of milliseconds since the micro:bit was
# last powered up or reset.
running_time()
\`\`\`
As an example of how \`running_time()\` can be used to control the pacing of your program:
\`\`\`
display.scroll("Ready...")

# Make a note of the time (in milliseconds) the delay started...
start_time = running_time()

# Compute when the delay should end
end_time = start_time + 3000 # using a 3 second delay for this example

# By checking the value of running_time() against end_time in a loop,
# you can do other things until the necessary time has elapsed.
while running_time() < end_time:
    # Do something here, such as a calculation or a sensor check
    scan_for_lifeforms() # <- this is a made up example...

# when we get to here, the desired time has elapsed
display.scroll("Go!")
\`\`\`
The *while loop* shown above is an example of a *non-blocking delay*.
`
  }
  </Markdown>
  )
}

const helpImport = {
  name: 'import',
  desc: 'Referencing other code - library modules',
  category: hintCategory.LANGUAGE,
  matches: ['import', 'library', 'libraries', 'module'],
  hint: (
<Markdown>
{
`\`import\` statements in Python let you use code from outside your own source file.
These external sources are called *modules* in Python. You will also sometimes hear *modules* referred to as *libraries*.

This is a very important feature of the language, as it let's you leverage the work of others.
Example micro:bit *modules* include \`microbit\`, \`random\`, \`music\`, \`speech\`, and \`neopixel\`.

\`import\` statements can have different forms. Throughout this course you have written code like:
\`\`\`
from microbit import *
import music
from random import randint
\`\`\`
and you may have wondered at the slight differences in each statement:
* Why do some of the import statments say *from* but others do not?
* Why do some of the import statements have a * in them but other statements do not?
* Why is the word \`import\` sometimes on the right side of the module name instead of the left side?

These variations in the different \`import\` statements affect two different behaviors:
* **How much** of the *module* is imported
* The exact **naming** of the features you imported

First let's talk about the "how much", using the \`random\` *module* as an example.
The \`random\` module contains about eight functions, and one possible way to import them all would be:
\`\`\`
from random import getrandbits
from random import seed
from random import randint
from random import randrange
from random import choice
from random import random
from random import uniform
\`\`\`
If you only want one or two features, specific individual imports like these are fine.
However, if you want most or all of the features from a *module*, you might as well import **all** of them, and there is a handy shortcut to do just that:
\`\`\`
from random import * # We now have access to everything the random module can do
\`\`\`
The * character in this context is shorthand for "everything". In computer lingo this is referred to as a *wild-card*.
The \`microbit\` *module* is an example of a module containing so many features we usually just import them all with one statement, and ignore the ones we don't need.

This brings us to the difference between \`from random import *\` and \`import random\`.
Both import statements bring in the same **quantity** of features (it imports **all** of them).
The difference is in how those features are utilized afterwards.
Features imported via \`import random\` must be referenced using what is called a *fully qualified name*.
For example, to set the random *seed* you would have to type:
\`\`\`
random.seed(123)
\`\`\`
Features imported via \`from random import *\` can be referenced without the leading qualifier:
\`\`\`
seed(123)
\`\`\`
Which form of import you use is sometimes a matter of personal taste, and sometimes about avoiding naming conflicts.
For example, imagine if your program included the following code snippet:
\`\`\`
def seed(type_of_crop, rows_to_plant, watering_interval):
    # Pretend there is more Python code here
\`\`\`
Now imagine *also* wanting to use the \`seed()\` function from the \`random()\` module.
This is an example where you would want to use \`import random\` so you could do things like:
\`\`\`
random.seed(123) # make random numbers be consistent for my testing
seed('corn', 124, 'daily') # plant the first crop
\`\`\`
`
}
</Markdown>
  )
}

const helpLiftOffKit = {
  name: 'LiftOff Kit',
  desc: 'Components in the LiftOff Kit',
  category: hintCategory.LIFTOFF,
  matches: ['liftoff kit'],
  hint: (
  <Markdown>
{`
The kit was designed to expand the range of available projects for the **micro:bit**.

More information on peripherals with example code is here: 
`}
<DocsButton 
    name="docs.firialabs.com"
    href="https://docs.firialabs.com/peripherals_kit/peripherals.html"
    style={{float:"none"}}
/>
{`
The kit contains an ==octopus:bit== input / output expander for the **micro:bit** and some or all of the following ==peripherals==:
`}
{
  peripheralsKitData.map(periph => {
    return (
      <div key={periph.title}>
      <hr />
      <h3>{periph.title}</h3>
      <img src={periph.image} alt={periph.title} style={{width:"150px"}}/>
      <p>{periph.description}</p>
      <p>{"Voltage Range: " + periph.powerInput}</p>
      </div>
    )
  })
}
  </Markdown>

  )
}

const helpOctopusBit = {
  name: 'Octopus:bit',
  desc: 'The micro:bit Input / Output Expander',
  category: hintCategory.LIFTOFF,
  matches: ['octopus:bit','alternate pin', 'alternate functions'],
  hint: (
  <Markdown>
{`
The ==octopus:bit== is provided in the Firia Labs ==LiftOff Kit==. It allows you to attach wires to the **micro:bit's** input / output pins using color-coded cables.
It has an *easily accessible* header and color-coded positions to simplify incorporating ==peripherals== into a project.

All of the micro:bit pins and pin **alternate functions**, as provided on the ==octopus:bit==, are shown here:
`}
<img src={OctopusBitAlternateFunctions} alt="octpus bit alternate functions" style={{display:'block', margin:'auto'}} />
{`
The micro:bit gets inserted into the ==octopus:bit== with its buttons facing forward like this:
`}
<img src={OctopusBitInserted} alt="octopus bit inserted" width={'40%'} style={{display:'block', margin:'auto'}} />
{`
There is a **VCC Switch** that allows you to switch the **blue** connector positions between 3.3 Volt and 5 Volt operation.

A **PWR LED** lights up when the device is connected properly to power.
`}
  </Markdown>
  )
}

const helpPWM = {
  name: 'PWM',
  desc: 'Pulse-width Modulation',
  category: hintCategory.LORE,
  matches: ['pwm', 'pulse-width modulation'],
  hint: (
<Markdown>
<img src={ImageKnob} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`How can you vary the amount of *electric power* sent to a **motor** or **light**?
* Is it *possible* if you only have a ==binary== output?
* All you can do is turn it *fully* **ON** or **OFF**, right?
* But what if you want to set a light or motor to 50% power?
  * You need ==analog== control!

> ...Actually you *CAN* vary the amount of power using **pulses**!
`}
<div style={{clear:'both'}} />
<img src={ImageDutyCycles} alt=""
    style={{
      width: "50%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`### Duty Cycle
With Pulse Width Modulation (PWM) you are turning the power ON and OFF rapidly.
* The percentage of **ON** time is called the **Duty Cycle**.
  * Full power = 100% 
  * Half power = 50%
  * Zero power = 0%

### Frequency
#### How *rapidly* should you pulse?
The answer depends on the type of device you're controlling.

For example, many PWM applications run at frequencies around **1kHz**.
* That means a pulse is sent every **1ms**
* Pulse-width of 0.1ms &rarr; 10% duty cycle.
* Pulse-width of 0.9ms &rarr; 90% duty cycle.

#### *PWM* is used widely to control many types of devices.
The *microcontroller* you're using has built-in hardware to do PWM.
* By adjusting the pulse **frequency** and **duty-cycle** your code has 
complete control of this powerful capability!
`}
</Markdown>
  )
}

const helpREPL = {
  name: 'REPL',
  desc: 'Read Evaluate Print Loop',
  category: hintCategory.LORE,
  matches: ['repl'],
  hint: (
<Markdown>
{`The **REPL** gives you a way to *interactively* enter commands and view outputs in a text format. You can:
* Call built-in and user-defined Python ==functions==.
* Check values of ==global== ==variables==.
* See output from \`print()\` statements in running code.
`}
<img src={ImageReplStart} alt=""
    style={{
      width: "50%", margin: 'auto', display:'block', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`### Is *"REPL"* really a good name for this?
Well, if you were to code your own "REPL" in Python, it might look something like this:
\`\`\`
while True:
    # R ead a statement from the Keyboard. (press ENTER)
    # E valuate the statement. (execute the Python code!)
    # P rint the result to the Console.
    # L oop (this is a while loop after all...)
\`\`\`
Besides being a place to see \`print()\` statement *output*, the **REPL** is a great way to test out snippets of code, language features, and APIs
as you decide how to use them in your code!
`}
</Markdown>
  )
}

const helpState = {
  name: 'State',
  desc: 'Status of a system with transitions',
  category: hintCategory.LORE,
  matches: ['state', 'finite-state machine', 'state machine', 'transition'],
  hint: (
<Markdown>
{`
A **Finite-State Machine**, sometimes called simply a **State Machine**, is a key concept in software and hardware systems.

Your program can only be in **one of a known set** of "states" at any given time. Usually *state* is based on 
what's in memory - the contents of ==variables== in your code.

Keeping track of states helps you as a programmer understand and manage your code.
* As code bases grow and more features are added, the "state" of a device can get complicated.
* There may be hundreds of states and multiple different paths to enter and exit each one.
* Each state might have its own set of ==conditions== that it is tracking.

Moving between states is called a **transition**.
* The program can **transition** from one state to another when certain ==conditions== are met.

**An example of a finite-state machine is a traffic light.**

Most traffic lights are three colors:
* Red
* Yellow
* Green

Traffic lights, in the United States of America, generally only have three states:
* GREEN = Traffic can go
* YELLOW = Caution the light will soon be red
* RED = Traffic must stop

The transitions are simple and all transitions occur after a time delay:
* GREEN always transitions to YELLOW
* YELLOW always transitions to RED
* RED always transitions to GREEN

Here is a visual representation of a state machine for a traffic light in the USA:
`}
<img src={TrafficLightStateDiagramImg} alt=""
    style={{
      width: '341px', margin: 'auto', display:'block'
    }}
/>
`}
</Markdown>
  )
}

const helpDebouncing = {
  name: 'Debouncing',
  desc: 'Prevent multiple analog triggers',
  category: hintCategory.LORE,
  matches: ['debounce', 'debouncing', 'bouncing'],
  hint: (
<Markdown>
{`
Real-world sensors are almost never perfect
* Analog inputs can easily **bounce** around between one value and the next.

Your program almost always expects a digital input but the world is mostly analog!

**Real-world example**

The button press is probably the most common example of bouncing:
When a person presses a button they usually expect that the button press will cause an action... **ONE**, **SINGLE** action.

Unfortunately, the electrical contact may not close instantly or electricity could arc prior to the contact closing.

The single, physical button press could register multiple times in your program.

This can cause *unexpected* behavior - maybe even **dangerous** behavior.

**The solution:**

The best solution is normally just to add a ==delay==.

Waiting in-between readings will give the analog sensor time to settle into an "expected" value.

It can also give a physical contact the opportunity to fully close before reading again!

You can get more complex with your debouncing:
* You could use an average value to smooth out your analog readings.
* You could add a hardware circuit to automate the debounce.
`}
</Markdown>
  )
}

const helpServo = {
  name: 'Servos',
  desc: 'DC Servo Motors',
  category: hintCategory.LIFTOFF,
  matches: ['servo'],
  hint: (
  <Markdown>
{`
**What is a servo?**

A servo is more than just a motor. It contains:
* A DC motor
* A controller circuit
* An internal feedback mechanism
* An amplifier (or gearbox)

**How do I make the servo go?**

Servo motors require an **analog control signal** to operate.
* You can send an analog control signal using ==PWM==!

Nearly all servos operate with a **50 Hertz (Hz)** control signal.
* 50 Hz = 50 times per second = 20 millisecond analog period.

50 Hz became a standard long ago due to the simplicity of the hardware design.

**Types of Servos**

* The **360 Continuous Rotation Servo** which can rotate continuously backward and forward.

| Duty Cycle (ms) | Speed | Direction             |
|-----------------|-------|-----------------------|
|       0.5       | 100%  |    Clockwise          |
|       0.75      |  75%  |    Clockwise          |
|       1.0       |  50%  |    Clockwise          |
|       1.25      |  25%  |    Clockwise          |
|       1.5       |   0%  |    Stopped            |
|       1.75      |  25%  |    Counterclockwise   |
|       2.0       |  50%  |    Counterclockwise   |
|       2.25      |  75%  |    Counterclockwise   |
|       2.5       | 100%  |    Counterclockwise   |

* The **180 Positional Servo** which can move to a specified position and hold in place.

| Duty Cycle (ms) | Position             |
|-----------------|-----------------------|
|       0.5       |    90 Degrees Clockwise          |
|       1.0       |    45 Degrees Clockwise          |
|       1.5       |    Centered            |
|       2.0       |    45 Degrees Counterclockwise   |
|       2.5       |    90 Degrees Counterclockwise   |

**Positional Servos Stay in Position**

There is no OFF position for the 180 servo like there is for a 360 servo.
* The 180 servo is always working to stay in position
* If you push it either direction it will always come back to its set position
`}
  </Markdown>
  )
}

// Lesson content "help clicks" will perform lookup in this dictionary.
// Values should be Renderable elements to display within a Popover.
// Seek text is forced to LowerCase!
export const genHelpObjs = [
  helpMathOperations,
  helpComments,
  helpCodeBotLeds,
  helpCodeBotAccel,
  helpCodeBotProx,
  helpCodeBotButtons,
  helpCodeBotLS,
  helpCodeBotMotors,
  helpCodeBotSpeaker,
  helpCodeBotEncoders,
  helpCodeBotSystem,
  helpUnderscore,
  helpPunctuation,
  helpUsb,
  helpReboot,
  helpLed,
  helpVariable,
  helpBinary,
  helpBooleans,
  helpBranching,
  helpLoops,
  helpIndentation,
  helpUserInterface,
  helpSyntaxHighlighting,
  helpAdvancedDebugging,
  helpBlankLines,
  helpDebugging,
  helpFunction,
  helpParameters,
  helpComparisonOperators,
  helpRNG,
  helpMathModule,
  helpTimeModule,
  helpTypes,
  helpLogicalOperators,
  helpButtonFunctions,
  helpSimulations,
  helpQuoteDivideAndConquer,
  helpAccelerometer,
  helpInt,
  helpFloat,
  helpString,
  helpEditor,
  helpCpuPeripherals,
  helpMusic,
  helpImages,
  helpADC,
  helpConstants,
  helpDisplay,
  helpList,
  helpTuple,
  helpPixel,
  helpBuiltinImages,
  helpPins,
  helpTiming,
  helpImport,
  helpLocalGlobal,
  helpRadioFunctions,
  helpISM,
  helpASCII,
  helpByte,
  helpMagnetometer,
  helpLiftOffKit,
  helpOctopusBit,
  helpPWM,
  helpREPL,
  helpDebouncing,
  helpState,
  helpServo,
]


// Help Jail - these are serving time till they can be revised, reconsidered, resurrected
/*

const helpNeoPixel = {
  name: 'Neopixels',
  desc: 'Controlling RGB LEDs from the micro:bit.',
  category: hintCategory.MB_LIBRARIES,
  matches: ['neopixel'],
  hint: (
  <Markdown>
  {
`The micro:bit is able to drive multi-colored LEDs from its pins. To use these functions, they must first be imported from the \`neopixel\` module:
\`\`\`
import neopixel
\`\`\`

Then they may be used with the neopixels:
\`\`\`
# Drive 8 LEDs from pin0. From here on use 'np' to work with the Neopixels.
np = neopixel.NeoPixel(pin0, 8)

# Set LED 0 to be red on next update. The parameters are (RED, GREEN, BLUE) as values from 0 to 255.
np[0] = [255, 0, 0]

# Update the LEDs to show new color values
np.show()

# Turn off all LEDs
np.clear()
\`\`\`
`
  }
  </Markdown>
  )
}

const helpDigitalIO = {
  name: 'Digital IO',
  desc: 'Input and Output with the micro:bit\'s pins.',
  category: hintCategory.LIBRARIES,
  matches: ['digital io'],
  hint: (
  <Markdown>
  {
`The micro:bit is has metal strips (pins) along the bottom edge which allow it to interact with circuits you build onto it. There are several built-in functions that allow your code to use these pins.

To use these functions, they must first be imported from the \`microbit\` module:
\`\`\`
from microbit import *
\`\`\`

Then they may be used with the pins:
\`\`\`
# Returns True if one hand touches pin0 while the other hand holds GND.
pin0.is_touched()

# Returns 1 of the pin is high (+3V), or 0 is it's low (0V).
pin0.read_digital(0)

# Sets pin0 to +3V
pin0.write_digital(1)

# Sets pin0 to 0V.
pin0.write_digital(0)
\`\`\`
`
  }
  </Markdown>
  )
}

const helpQuote640K = {
  name: 'Quote: Enough',
  desc: null,
  category: hintCategory.LORE,
  matches: ['enough memory'],
  hint: (
  <Markdown>
  {
`
*"640K ought to be enough for anybody."*

-- W. Gates
`
  }
  </Markdown>
  )
}

const helpQuotePressOn = {
  name: 'Quote: Press on',
  desc: null,
  category: hintCategory.LORE,
  matches: ['press on'],
  hint: (
  <Markdown>
  {
`
*"Press on. Nothing in the world can take the place of persistence. Talent will not; nothing is more common than unsuccessful men with talent. Genius will not; unrewarded genius is almost a proverb. Education alone will not; the world is full of educated derelicts. Persistence and determination alone are omnipotent."*

-- Calvin Coolidge [30th President of the United States]
`
  }
  </Markdown>
  )
}

const helpQuoteRightNames = {
  name: 'Quote: First step toward wisdom',
  desc: null,
  category: hintCategory.LORE,
  matches: ['right names'],
  hint: (
  <Markdown>
  {
`
*"The first step towards wisdom is calling things by their right names."*

-- Anonymous Chinese Proverb
`
  }
  </Markdown>
  )
}

const helpQuoteKindsOfPeople = {
  name: 'Quote: Kinds of people',
  desc: null,
  category: hintCategory.LORE,
  matches: ['kinds of people'],
  hint: (
  <Markdown>
  {
`
*"There are only 10 different kinds of people in the world: those who know ==binary== and those who don't."*

-- Anonymous
`
  }
  </Markdown>
  )
}

const helpQuoteReadability = {
  name: 'Quote: Code for people',
  desc: null,
  category: hintCategory.LORE,
  matches: ['readability'],
  hint: (
  <Markdown>
  {
`
*"Programs must be written for **people** to read, and only incidentally for **machines** to execute."*

-- H. Abelson and G. Sussman
`
  }
  </Markdown>
  )
}


*/
