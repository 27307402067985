// Project: Heartbeat

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  //LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import Joyride from '../cbl-joyride'
import Button from 'material-ui/Button'
import HeartPic from './assets/heart.svg'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'


export const HeartbeatImg = HeartPic

class ShowHeart extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Heart Beat {lesson-title}
___
#### In this project you’ll give the micro:bit a *beating heart*.
`}
      <img src={HeartPic} alt=""
          style={{
            width: 200, margin: 'auto', display:'block',
            animationName: 'pulse-scale, slide-right',
            animationDelay: '0s, 4s',
            animationIterationCount: '4, 1',
            animationDuration: '1s, 0.3s',
            animationTimingFunction: 'linear',
            animationFillMode: 'none, forwards',
          }}
      />
{
`Okay, not a *real* heart – that would be a little too messy!
**But** using the <mark>LED</mark> display you can give the micro:bit its own *digital* heart,
and even make it speed up and slow down just like your own heart does.

#### Project Goals:
* Code an animated heartbeat, pulsing on the LED display
* Learn how to make your code **LOOP** forever
  * And how to break out of it!
* Make the heartbeat speed *adjustable* using the **micro:bit buttons**
  * **A** = *slower* and **B** = *faster*
`}
<LegendMessageKeyboard>
{`
* Create a new file (click **File** menu at top-left of screen)
* Name it **Heart2**
#### Type this code into the Editor:
\`\`\`
from microbit import *
display.show(Image.HEART)
\`\`\`
`}
</LegendMessageKeyboard>
{`You might recognize this is the same code as your first project. Don't worry, you're going to add a *lot* of new features soon!
`}
<LegendMessageRun />
        <StepComplete
          prompt="Do you see a HEART image on the LED display?"
          xp={10}
          successMessage=
{`## Nice! {centered}
### That was easy.
`}
          reqImports={['microbit']}
          reqCalls={['display.show']}
          reqArgs={['Image.HEART']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
          gFileId={this.props.gFileId}
        />

      </Markdown>
    </div>
    )
  }
}

class CleanTheBeat extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Clean Code {lesson-title}
___
#### Good code is easy to read (by *humans*, not just computers!)
As your programs get longer, take care to make them *readable*.
You can add ==blank lines== anywhere in your code to separate portions without affecting how
it works. The computer ignores blank lines.

Try adding a **blank line** in between your lines of code.
`}
<LegendMessageKeyboard>
{`
#### Update your code to look like this:
\`\`\`
from microbit import *

display.show(Image.HEART)
\`\`\`
`}
</LegendMessageKeyboard>
{`
Try running your code and make sure it works the same as before!
`}
<LegendMessageRun />
        </Markdown>
        <StepComplete
          prompt={<div>Proceed to the Next lesson to make the heart beat!</div>}
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class OneBeat extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Pump It UP - Second Heart Image {lesson-title}
___
To make a *Heartbeat* animation, the display needs to alternate between two images:
* The first image you're already showing, \`Image.HEART\`
* ... then switch to \`Image.HEART_SMALL\`

> Each **beat** will be a *big / small* cycle, to make the Heart appear to *pulse*!

In this step, you should start with a **single** beat.
`}
<LegendMessageKeyboard>
{`
#### Add the second Image:
\`\`\`
from microbit import *

display.show(Image.HEART)
$display.show(Image.HEART_SMALL)
\`\`\`
`}
</LegendMessageKeyboard>
{`
So we *should* be able to show one heartbeat cycle with the code above, right?
`}
<LegendMessageWarning title="Can You Spot the Problem?">
{`How much time will the *first* \`Image.HEART\` be shown

> ...before the *second* image replaces it?
`}
</LegendMessageWarning>
<LegendMessageRun>
{`The first Image goes by so fast, you can't really see it!

If you have done the previous lessons, you already know how to fix this problem. You're gonna
need some \`sleep()\`!
`}
</LegendMessageRun>
        </Markdown>
        <StepComplete
          prompt={<div>Proceed to the Next lesson to fix your code!</div>}
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class OneBeatSleep extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Adding Delays{lesson-title}
___
#### At this point, your code:
* **displays \`HEART\`**....and then *really quickly* .... **displays \`HEART_SMALL\`**

Since the program seems to just be running too quickly to see the \`HEART\`, you need some code to slow things down a bit.
\`\`\`
sleep(1000)
\`\`\`

The above line of code will cause the micro:bit to *delay* for 1000 milliseconds (thousandths of a second)
before going to the next line. That equals 1 second of delay - plenty of time to see the HEART displayed.

`}
<LegendMessageKeyboard
  title={`Update your Code!`}
>
{`Add a line with \`sleep(1000)\` on the *next* line of code **after** each \`display.show()\`.

\`\`\`
from microbit import *

display.show(Image.HEART)
$sleep(1000)

display.show(Image.HEART_SMALL)
$sleep(1000)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />

          <StepComplete
            prompt="Did your micro:bit display both the HEART and then HEART_SMALL?"
            xp={25}
            successMessage=
{`## Excellent! {centered}
### Steady as she goes!
`}
            reqImports={['microbit',]}
            reqCalls={['display.show', 'sleep']}
            reqArgs={['Image.HEART', 'Image.HEART_SMALL']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />

        </Markdown>
      </div>
    )
  }
}

class KeepBeating extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Animated Beats {lesson-title}
___
Now that you have a *single* **beat**, can you make it repeat?
`}
<LegendMessageKeyboard>
{`
Go ahead, **change your code** to make the HEART beat several times!

(no need to repeat \`from microbit import *\` - you only need that once!)

#### Just repeat these lines in your code a few more times:
\`\`\`
from microbit import *

display.show(Image.HEART)
sleep(1000)

display.show(Image.HEART_SMALL)
sleep(1000)

$$$
display.show(Image.HEART)
sleep(1000)

display.show(Image.HEART_SMALL)
sleep(1000)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
{`Fingers tired of typing? **Learn about the ==Editor Shortcuts==!**
`}
<LegendMessageRun>
{`You can code a few *beats* this way, but the program would get *really* long
if you had to **copy/paste** the code to make the \`HEART\` beats go on much longer!

> ...there **must** be a better way!
`}
</LegendMessageRun>
        </Markdown>
        <StepComplete
          prompt="Awesome new concept ahead..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class BeatWhile extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Hearts Forever {lesson-title}
___
*A few beats is a good start...*

### But your Heartbeat animation needs to run forever!
Instead of copying the same code *over and over and over...* there **must** be a way to tell the computer to
just **repeat** that code!

*YES* there is! It's called a ==LOOP==.

Here's your original code inside a \`while True\` loop:
\`\`\`
from microbit import *

while True:
    display.show(Image.HEART)
    sleep(1000)

    display.show(Image.HEART_SMALL)
    sleep(1000)
\`\`\`

Can you tell what this code might do? **Study the definitions below**, then move to the next step to try it out.
`}
<LegendMessageConcept title={'Concept: While Loops'}>
  <Markdown>
{`A \`while condition:\` statement tells Python to repeat the block of code ==indented== beneath it as long as the
given *==condition==* is **True**.

In the code above we used the *literal* value \`True\` as the condition, so we have
an **infinite loop** - one that never ends, because \`True\` is always... **True**!`}
  </Markdown>
</LegendMessageConcept>
        </Markdown>
        <StepComplete
          prompt="Next step is to try it..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class KeepBeating2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Endless Looping Beats {lesson-title}
___
The code that runs *inside* your \`while\` loop must be indented. When you type in the code,
take care of your indentation!
`}
<LegendMessageConcept title={'Concept: Indentation'}>
<Markdown>
{`#### Notice that the lines under \`while True\` are ==indented==!
* Use the **TAB** key on your keyboard to **indent** code
* ...it's the same as 4-spaces, but easier
* Be *consistent* and *neat* with ==indented== levels of code
`}
</Markdown>
</LegendMessageConcept>

<LegendMessageKeyboard title={'Update your code'}>
{`
Add a \`while\` loop so the entire program looks like this:
> *Notice just one beat cycle inside loop!*
\`\`\`
from microbit import *

while True:
    display.show(Image.HEART)
    sleep(1000)

    display.show(Image.HEART_SMALL)
    sleep(1000)
\`\`\`
**Wait!** Are those last two lines inside the loop?
* **Yes!** The blank line is ignored, and the last two lines **are indented** inside the \`while:\` loop.
`}
</LegendMessageKeyboard>

<LegendMessageRun>
  You should now have a beating heart that doesn't stop!
</LegendMessageRun>
          <StepComplete
            prompt="Does your micro:bit's heart beat continuously?"
            xp={40}
            successMessage=
{`## Loopy! {centered}
### Nothing can stop you now!
`}
            reqImports={['microbit',]}
            reqCalls={['display.show', 'sleep']}
            reqArgs={['Image.HEART', 'Image.HEART_SMALL']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />

        </Markdown>
      </div>
    )
  }
}

const tourStop = [
    {
      title: 'The Stop Button',
      text: (
  <Markdown>
  {
`Tells the micro:bit to STOP your code.

Some programs run quickly to the end, so there's really no need to "stop" them.
*But* programs with **loops** may keep running for a long time.

**You have to *STOP* them before you can load new code!**
`     }
  </Markdown>
      ),
      selector: '#tb-stop',
      position: 'top',
      allowClicksThruHole: true,
      style: {
        width: 400
      }
    },
]

class StopBeating extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  handleRunTour = () => {
    this.tour.reset(true)
    this.setState({runTour: true})
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={tourStop}
          run={this.state.runTour}
        />
        <Markdown>
{`## Stop It! Change Speeds{lesson-title}
___
Now that your program doesn't just run straight through and finish, you need a way to **STOP** it...
`}
<LegendMessageConcept
  title={'Concept: Stopping'}
>
<Markdown>
{`
You can exit the running code by using the **STOP** button.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <Button
    variant='raised'
    onClick={this.handleRunTour}
  >
    Show me how to STOP the code...
  </Button>
</div>
<br />
</LegendMessageConcept>
{`### Feeling the Need for Speed??

Want to make the HEART beat *faster*?  ...or *slower*?

* All you have to do is change the \`sleep(1000)\` to a different delay.
`}
<LegendMessageTry>
{`Click the **STOP** button so you can edit your code!

**Now**, play around with a few different values, like \`sleep(200)\`.

**Run** and then **Stop** your program a few times, trying different speeds until you're ready to move on.
`}
</LegendMessageTry>
<StepComplete
  prompt="Ready for a break? Press Next..."
  lessons={this.props.lessons}
  btnNext={true}
/>
        </Markdown>
      </div>
    )
  }
}

class KillSwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Heart *Break* {lesson-title}
___
Now that you have coded an *infinite loop* and learned how to manually exit it, you're probably wondering: *"is there a way to break out of it with **code**?"*

Glad you asked! To break out of a loop, use a statement called... wait for it...

\`break\` {centered}

**Your new assignment:**
* Program one of the micro:bit's buttons as a *"kill switch"* to stop the ever-beating heart.
`}
<LegendMessageKeyboard title={'Update your code!'}>
{`Add the following **inside** your \`while\` loop. It can go at the end, after the last \`sleep(1000)\`.
* Be sure to indent the \`if\` at the same level as the \`sleep\`.
\`\`\`
from microbit import *

while True:
    display.show(Image.HEART)
    sleep(1000)

    display.show(Image.HEART_SMALL)
    sleep(1000)

$$$
$    if button_a.was_pressed():
$        break
$$$
\`\`\`
`}
</LegendMessageKeyboard>

          <LegendMessageRun>
{`Try running your code - you should still have a beating heart... *UNTIL* you press **Button A** on the **micro:bit**.
`}
          </LegendMessageRun>
          <StepComplete
            prompt="Are you able to break your micro:bit's heart? (...kindly)"
            xp={40}
            successMessage=
{`## Well Done! {centered}
### Sometimes it's good to take a break.
`}
            reqImports={['microbit',]}
            reqCalls={['display.show', 'sleep', 'button_a.was_pressed']}
            reqArgs={['Image.HEART', 'Image.HEART_SMALL']}
            reqStatements={['while', 'if', 'break']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />

        </Markdown>
      </div>
    )
  }
}

class ExploreTheBeat extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Explore the Beat {lesson-title}
___
Now your **micro:bit** is *interactive*!

The last step introduced two new concepts, **input** and **branching**, which enabled the code to do something
*different* when a button was pressed.
`}
<LegendMessageConcept>
  <Markdown>
{`
#### Branching with an \`if\` statement:

The ==\`if condition\`== statement tells Python to only run the *block* of code indented beneath it
if the *condition* is \`True\`.

#### Input from a button:
The \`button_a.was_pressed()\` *==function==* will be \`True\` if **Button A** on the micro:bit
was pressed since the last time the function was used.
`}
  </Markdown>
</LegendMessageConcept>
{`

`}
          <LegendMessageDebug title="Step Into Your Code!">
{`
**Experiment with this** code until you *really* understand how it works.

**Stop** the code, then *use the **Step** button* to run it *one line at a time*.

Press **Button A** while the program is paused on a line, then **Step** and watch what happens next time \`button_a.was_pressed()\`
runs. See how the computer follows a different *branch* of the code based on the \`if\`?
`}
          </LegendMessageDebug>

          <Quiz
            lessons={this.props.lessons}
            prompt="What happens if you press button 'A' when stepping?"
            xp={5}
            answerRight={"Next button_a.was_pressed() will be True"}
            answerWrong={["Next button_a.was_pressed() will be False", "Buttons are ignored when stepping and paused"]}
          />
          <StepComplete
            prompt='"if" you are ready, click Next...'
            lessons={this.props.lessons}
            btnNext={true}
          />

        </Markdown>
      </div>
    )
  }
}

class VariableSpeed1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Variable Speed Control {lesson-title}
___
**Are you feeling the excitement?!**

Without a doubt, the micro:bit's heart should be *racing* by now - **there's a new coder on the scene!**

*...but it's going to take **more** coding on your part to make its heart beat faster.*

### What controls the speed in our code so far?
\`\`\`
sleep(1000)
\`\`\`
The number controlling the speed is \`1000\`.
* That's a 1000 millisecond (1 second) ==sleep==.
* To beat twice as fast, we could delay half as much: \`sleep(500)\` - Easy!

But NO - we want a **variable** heartbeat, not a *constant* one!
> Fortunately, it's easy to **create a** ==variable== in Python!

### Don't type the code in yet - *read below* first:
`}
<LegendMessageConcept title={`Concept: Variables`}>
{`
To define a ==variable==:
* choose a name, like *\`delay\`*
* assign a value to it, like  *\`delay = 1000\`*
* use \`delay\` in your code just like any other value!

Notice in the code snippet below we *declare a variable* **delay** and use it instead of the *constant* 1000.
\`\`\`
delay = 1000
sleep(delay)
\`\`\`
`}
</LegendMessageConcept>
<StepComplete
  prompt="Next you will try it out..."
  lessons={this.props.lessons}
  btnNext={true}
/>

        </Markdown>
      </div>
    )
  }
}

class VariableSpeed1_1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Enter the Variable {lesson-title}
___
**Your new code will look like the following:**

\`\`\`
from microbit import *
$delay = 1000

while True:
    display.show(Image.HEART)
$    sleep(delay)

    display.show(Image.HEART_SMALL)
$    sleep(delay)

    if button_a.was_pressed():
        break
\`\`\`
`}
<LegendMessageKeyboard>
  <Markdown>
{`Create a variable called \`delay\` at the top of your code as shown above.
Then replace the \`1000\` everywhere in your code with \`delay\`.`}
  </Markdown>
</LegendMessageKeyboard>
{`
The code works exactly the same as before, but now there's *one* place to change the \`delay\` value.
`}
<LegendMessageRun>
{`
**Now**, *test your code* by changing to \`delay = 500\` and **Run** again.
`}
</LegendMessageRun>

          <StepComplete
            prompt="Are you running with a variable?"
            xp={20}
            successMessage=
{`## Cool! {centered}
### The code is looking much nicer
Now it's easy to change the delay - next you'll add buttons to change it while running!
`}
            reqImports={['microbit',]}
            reqCalls={['display.show', 'sleep']}
            reqArgs={['Image.HEART', 'Image.HEART_SMALL']}
            reqStatements={['while']}
            reqNames={['delay']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />

        </Markdown>
      </div>
    )
  }
}

class VariableSpeed2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

    render() {
      return (
        <div>
          <Markdown>
{`## Brake! Not "break"... {lesson-title}
___
You can now change the heartbeat speed...
* But only by modifying the **code**
  * ...and the micro:bit won't *always* be connected to your PC
* You need to change the heartbeat **while it's running** - *unplugged!*

### Can you make the speed change using buttons A and B?

Check out the code snippet below (but *don't* type it in yet!)
\`\`\`
if button_a.was_pressed():
    delay = delay + 200
\`\`\`
Rather than \`break\` when button_a is pressed, we:
* Add \`200\` to the original \`delay\` value. (1000 + 200 \`= 1200\`)
* Store this new value (1200) back in \`delay\`
* So every time **button_a** is pressed, +200ms is added to \`delay\`.
  * (that's two tenths of a second)

`}
<StepComplete
  prompt="Next step is to CODE it!"
  lessons={this.props.lessons}
  btnNext={true}
/>
          </Markdown>
        </div>
      )
    }
  }

class VariableSpeed2_1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

    render() {
      return (
        <div>
          <Markdown>
{`## Variable Speed Heart {lesson-title}
___
`}
<LegendMessageKeyboard>
  <Markdown>
{`#### Modify your program to change speed using buttons A and B
* Be sure to *subtract* from \`delay\` when **button_b** is pressed!
`}
  </Markdown>
</LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

delay = 1000

$$$
while True:
    display.show(Image.HEART)
    sleep(delay)

    display.show(Image.HEART_SMALL)
    sleep(delay)

$    if button_a.was_pressed():
$        delay = delay + 200

    if button_b.was_pressed():
        delay = delay - 200
$$$
\`\`\`
`}
            <LegendMessageRun>
              <Markdown>
{`
Try running your code - watch the micro:bit get *stoked* when you press **button_b** a few times, and *calm it back down* with **button_a**!
<br /><br />
**Whoa!** What's up with the ==display== when the speed gets fast?
 * When your Python code changes the display, the LEDs are *not* changed immediately!
   * The LEDs are updated at a specific rate, *usually* too fast for you to notice!
 * When your code changes the display **faster** than the LED update rate you start seeing strange interactions!
 `}
              </Markdown>
            </LegendMessageRun>
            <StepComplete
              prompt="Can you make the heart beat FASTER and SLOWER?"
              xp={40}
              successMessage=
  {`## Vary Good!! {centered}
  (Sorry for the grammar pun - couldn't help myself...)
  ### You have learned **so** much!
  This code is the foundation for **lots** of great projects.
  `}
              reqImports={['microbit',]}
              reqCalls={['display.show', 'sleep', 'button_a.was_pressed', 'button_a.was_pressed']}
              reqArgs={['Image.HEART', 'Image.HEART_SMALL']}
              reqStatements={['while', 'if']}
              reqNames={['delay']}
              lessons={this.props.lessons}
              btnYes={true}
              btnNo={true}
            />

          </Markdown>
        </div>
      )
    }
  }

  class Checkpoint1 extends Component {
    static propTypes = {
      lessons: PropTypes.object.isRequired,
    }

    render() {
      return (
        <div>
  <Markdown>
{`## Checkpoint {lesson-title}
___
`}
        <Quiz
          lessons={this.props.lessons}
          prompt={(
          <Markdown>
{`Why does the heartbeat blink faster when you subtract time?
`}
          </Markdown>
          )}
          id={"Frequency inverse of period"}
          xp={5}
          answerRight={"A smaller delay in each loop cycle makes a faster blink rate."}
          answerWrong={["Smaller hearts beat faster than larger ones.", "Negative numbers are always faster than positive ones."]}
        />
{`
`}
        <Quiz
          lessons={this.props.lessons}
          prompt={(
          <Markdown>
  {`What is the limit on how fast the heartbeat can blink?
  `}
          </Markdown>
          )}
          id={"IO Speed Heartbeat"}
          xp={5}
          answerRight={"It's limited by the speed of the micro:bit computer's internal clock."}
          answerWrong={["It's not limited at all.", "As fast as you can press the buttons on the micro:bit.", "The speed of light."]}
        />

        <Quiz
          lessons={this.props.lessons}
          prompt={(
          <Markdown>
  {`What does the \`break\` statement do?
  `}
          </Markdown>
          )}
          id={"Break Statement"}
          xp={5}
          answerRight={"Breaks out of a loop."}
          answerWrong={["Causes the code to stop.", "Crashes the program.", "Jumps over the next line of code."]}
        />

  <StepComplete
    prompt="Excellent progress..."
    lessons={this.props.lessons}
    btnNext={true}
  />
  </Markdown>
        </div>
    )
  }
}

class Conclusion extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Recap with Comments {lesson-title}
___
Congratulations! You've learned a lot of the building blocks that **all the software you use** is made with.

There's one more topic to cover in this Project:
### Readability and Comments {centered}

*As you write code, imagine that someone who has never seen it before will have to read it and figure it out.*
* A year from now, you might even pick up your **own** code and say: "what was I thinking!?"

**Readability**: Making code easy to understand for **humans**.
* Use descriptive variable names
* Use ==Comments== - notes in the code about what you're doing

In Python, anything that follows a \`# to the end of the line\`
> ...is a ==comment==, meaning it is *ignored* by the computer.

Here's your last program again, with \`# comments\` added:
\`\`\`
# Import everything (*) from the pre-written micro:bit code module
from microbit import *

# Declare a variable, and store an initial value in it
delay = 1000

# Keep displaying and checking buttons forever (infinite loop)
while True:
    # Display HEART and wait...
    display.show(Image.HEART)
    sleep(delay)

    # Display HEART_SMALL and wait...
    display.show(Image.HEART_SMALL)
    sleep(delay)

    # If button A pressed, go slower
    if button_a.was_pressed():
        delay = delay + 200

    # If button B pressed, go faster
    if button_b.was_pressed():
        delay = delay - 200
\`\`\`

### In the following projects, *you can decide* if you want to type in the ==comments== provided in the lessons, or omit them.

Feel free to *add your own comments*, to help you understand and remember what your code was meant to do!
`}
        </Markdown>
        <StepComplete
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Real World Applications {lesson-title}
___
Clicking buttons to make the speed **faster** and **slower**?

#### That code's EVERYWHERE! {centered}

* TV Remote Controls (volume +/-)
* Game controllers
* Lighting Dimmers
* Microwave Ovens
* Vehicle Cruise Controls

Right now you've built a great **Visual Metronome** that could be used to set the tempo for a band...

Imagine what else you might create! {centered}

In the next project we'll learn even more! When you're ready, click the NEXT button to advance!
`}
        </Markdown>
        <StepComplete
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ShowHeart.stepId = 'ShowHeart'
OneBeat.stepId = 'OneBeat'
CleanTheBeat.stepId = 'CleanTheBeat'
OneBeatSleep.stepId = 'OneBeatSleep'
KeepBeating.stepId = 'KeepBeating'
BeatWhile.stepId = 'BeatWhile'
KeepBeating2.stepId = 'KeepBeating2'
StopBeating.stepId = 'StopBeating'
KillSwitch.stepId = 'KillSwitch'
ExploreTheBeat.stepId = 'ExploreTheBeat'
VariableSpeed1.stepId = 'VariableSpeed1'
VariableSpeed1_1.stepId = 'VariableSpeed1_1'
VariableSpeed2.stepId = 'VariableSpeed2'
VariableSpeed2_1.stepId = 'VariableSpeed2_1'
Checkpoint1.stepId = 'Checkpoint1'
Conclusion.stepId = 'Conclusion'
Applications.stepId = 'Applications'

export const heartBeat = [
  ShowHeart,
  CleanTheBeat,
  OneBeat,
  OneBeatSleep,
  KeepBeating,
  BeatWhile,
  KeepBeating2,
  StopBeating,
  KillSwitch,
  ExploreTheBeat,
  VariableSpeed1,
  VariableSpeed1_1,
  VariableSpeed2,
  VariableSpeed2_1,
  Checkpoint1,
  Conclusion,
  Applications,
]
