
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'
import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'
import FinalEnergyImg from './assets/iStock-475702355.jpg'
import ConserveEnergyIntroImg from './assets/iStock-1129829504.gif'

import PirImage from './assets/peripherals/pk_pir.jpg'
import PotentiometerImage from './assets/peripherals/pk_pot.jpg'
import MotionImg from './assets/iStock-1250688024.gif'
import LightingSystemImg from './assets/iStock-905835162.jpg'
import DimmerImg from './assets/iStock-118262294.jpg'
import DimLightImg from './assets/iStock-506288468.jpg'
import BlackoutImg from './assets/iStock-927825486.jpg'
import PwmImg from './assets/pwm.jpg'
import WatchImg from '../StartCoding/assets/watch.svg'


import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
  LegendMessageTry,
} from '../Legends'

export const ConserveEnergyImg = GoogleClassroomImg

export class ProjectConserve extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Conserve Energy! {lesson-title}
___
### This project introduces a digital sensor and shows how to dim an LED with software!
`}
<img src={ConserveEnergyIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The crew's rocket ship is hurtling through space. Energy conservation will be **critical** to surviving the long journey to **Mars**.

**Project: Conserve Energy** will guide you through reducing power consumption in the ship's lighting system.

You will need to *detect motion* in the crew compartment to turn **on** the lights and then *reduce power* by
dimming them down when no motion is detected.

#### Project Goals:
* Use a **digital sensor** (the PIR Motion Sensor)
* Learn software techniques to dim an LED
* Take an analog input from a **potentiometer** (knob)
* Conserve critical energy for mission success!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class LightingSystem extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Lighting System {lesson-title}
___
`}
<img src={LightingSystemImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The first step is constructing a lighting system for the shuttle. {centered}
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Use the ***File &rarr; New*** menu to create a new file and name it "ConserveEnergy".
`}
</LegendMessageInteract>
{`
#### Now add the ship's main lights!
* The white LED will be the lighting system for the shuttle's main room
`}
<LegendOctoHeader 
  pin={0}
  peripheral={2} // 2 = White LED
  markdown={`Connect the **white LED** to **position 0** on your ==octopus:bit==!`}
/>
<LegendMessageKeyboard>
{`
1. Import the \`microbit\` module
2. Define ==variables== for \`LED_ON\` and \`LED_OFF\`
3. Add a ==function== \`set_white_led(val)\` that can take a parameter
4. Turn **ON** the white LED using your function
\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_white_led(val):
    pin0.write_digital(val)

set_white_led(LED_ON)
\`\`\`
`}
</LegendMessageKeyboard>

<LegendMessageRun  />
</Markdown>
          <StepComplete
            prompt="Did your white LED turn on and stay on?"
            xp={5}
            successMessage=
{`## Bright Start! {centered}
### You are on your way to designing the ship's lighting system!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led']}
            reqCalls={['pin0.write_digital','set_white_led']}
            reqArgs={['LED_ON']}
            reqNames={['LED_ON', 'LED_OFF']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class OnOffOnOff extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Power Cycle the Lights? {lesson-title}
___
`}
<img src={DimLightImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### LEDs can be large energy consumers. **Especially** in battery powered devices.
*What can you do to save energy in the lighting system?*

There are many ways to reduce consumption of an LED.
* The simplest way is to turn it **OFF**
* But that kinda defeats the purpose of having an LED!

You already know how to turn the LED OFF, but what if you could save energy by **dimming** it?
> *If you **cycle** the LED ON and OFF fast enough, it will appear to be ON... just dimmer!*

Add the following ==import== under \`from microbit import *\`:
\`\`\`
from time import sleep_us
\`\`\`
`}
<LegendMessageConcept title="Fast Timers">
{`You've been using the \`sleep()\` function from the \`import microbit\` library module.
* There are even *more* timer features available in Python's **==time module==**!

#### For example:
\`sleep_us()\` is a ==delay== just like \`sleep()\` but with *microseconds (us)* instead of *milliseconds (ms)*.
* 1000 microseconds (us) = 1 millisecond (ms)
* 1000000 microseconds (us) = 1 second
`}
</LegendMessageConcept>
<LegendMessageWarning title="Do not stare directly into the LED!">
{`
Try to look at the side of the LED to protect your eyes. It is extremely bright.
`}
</LegendMessageWarning>
{`### Okay, now it's time to Dim the Lights!
This is going to be fun :-)
`}
<LegendMessageKeyboard>
{`Add a \`while True:\` loop to your program.
* In the loop, *power-cycle* your LED by turning it ON and OFF.
* Use the new \`sleep_us()\` function to set delays.
\`\`\`
from microbit import *
$from time import sleep_us

LED_ON = 1
LED_OFF = 0

def set_white_led(val):
    pin0.write_digital(val)

$while True:
$    set_white_led(LED_ON)
$    sleep_us(1000000) # 1 second
$    set_white_led(LED_OFF)
$    sleep_us(1000000) # 1 second
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun  />
{`
You just saved **50%** of the power!!! That's great... except, turning the lights off every second is not going to be very pleasant for the crew!

> But is your code fast enough to turn the LED **OFF** and then back **ON** so fast their eyes couldn't detect it?
`}
<LegendMessageKeyboard>
{`To find out, update your sleep function arguments:
\`\`\`
from microbit import *
$from time import sleep_us

LED_ON = 1
LED_OFF = 0

def set_white_led(val):
    pin0.write_digital(val)

$$$
while True:
    set_white_led(LED_ON)
$    sleep_us(250) # 250 microseconds
    set_white_led(LED_OFF)
$    sleep_us(750) # 750 microseconds
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Your LED is only ON for 250 out of every 1000 microseconds.
* That's a 25% *duty-cycle.*
* *Meaning it uses **25% of the energy** of a full-brightness LED!*
`}
</LegendMessageRun>
{`
**How does your LED look now?**

**Here are some fun facts:**
* In human vision there is a *"Critical Flicker-Fusion Frequency"*
  * ...anything flashing faster than *that* will not be perceived as flickering!
* Your ==loop== cycles every 1000us &rarr; $freq=\\frac{1}{0.001sec}=1000 Hz$
  * That's well above the *Critical Flicker-Fusion Frequency!*
* **AND** you just **reduced** energy consumption by 75%!!
* The LED appears **dimmer** than it was because there is less total energy put into the LED.
`}
<LegendMessageTry>
{`Try different sleep values to see how the light changes.
* Experiment to find the *"Critical Flicker-Fusion Frequency"!*
`}
</LegendMessageTry>

</Markdown>
          <StepComplete
            prompt="Did your white LED appear steady even though you were turning it off?"
            xp={5}
            successMessage=
{`## Saving Power! {centered}
### You have reduced the power on your LED!
`}
            reqImports={['microbit', 'time']}
            reqFuncdefs={['set_white_led']}
            reqCalls={['pin0.write_digital','set_white_led','sleep_us']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqNames={['LED_ON', 'LED_OFF']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class PwmLed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## PWM (Pulse-width Modulation) {lesson-title}
___
#### It could get **complicated** to add mulitple \`sleep_us()\` calls in your ==loop==.

*Is there a way to make the ON and OFF happen automatically?*

**YES!!** You can use ==Pulse-width Modulation== (PWM).
* PWM allows you to turn the power **ON** and **OFF** behind the scenes at a **fast rate** by just setting a few values. 
`}
<LegendMessageConcept title="Concept: PWM">
{`
==PWM== is used in many applications such as:
* Computer Fans
* Lights
* DC Motor Controls
* Electric Cookers
* Voltage Regulators
* Audio Effects
`}
</LegendMessageConcept>
<img src={PwmImg} alt="" style={{width:"40%", float:'right', margin: '0.5em'}} />
{`
==PWM== is one of the many capabilities of the **micro:bit** input / output ==pins==.
* However, it is only available on the **analog** positions of the ==octopus:bit==.
* ON/OFF pulses are sent at a constant rate, set by the \`set_analog_period()\` function.
  * This sets the *PWM frequency* based on the given period in ms.
* The pulse width is set by the \`write_analog()\` function.
  * This sets the *PWM duty-cycle* (% power) based on a 0-1023 ==int== value.
`}
<LegendMessageKeyboard>
{`
1. **Remove** the \`from time import sleep_us\` line from your code.
2. Just before your \`while loop\` set the PWM period to the **minimum** value (1ms):
\`\`\`
pin0.set_analog_period(1) # ms
\`\`\`
3. Remove your \`while\` ==loop== completely and replace it with a \`write_analog()\` call to set the LED at ***50% brightness.***

* Use the \`write_analog(duty)\` function from the **micro:bit** library to activate ==PWM== on a ==pin==
using an ==integer== argument between 0 and 1023 to set the **duty cycle**:
  * duty=\`0\` &rarr; 0% ON time each period
  * duty=\`511\` &rarr; 50% ON time each period
  * duty=\`1023\` &rarr; 100% ON time each period
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

$pin0.set_analog_period(1) # ms

def set_white_led(val):
    pin0.write_digital(val)

$pin0.write_analog(511) # duty cycle => 50% brightness
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageTry>
{`Try changing the duty cycle to see how the LED dims.
* Can you still see the LED illuminate with \`write_analog(1)\`?
  * That would be 1/1023, about 0.1% power!
`}
</LegendMessageTry>

</Markdown>

<StepComplete
            prompt="Did your white LED turn on at about 50% brightness?"
            xp={5}
            successMessage=
{`## Analog Out! {centered}
### You have sent an analog signal to your LED!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital']}
            reqArgs={['1']}
            reqNames={['LED_ON', 'LED_OFF']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class AnalogInput extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Analog Input - The Potentiometer {lesson-title}
___
Your **code** can *dim* the LED, but...
#### It would be nice to be able to dim the LED with an external ==peripheral==!
`}
<img src={PotentiometerImage} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`How about an **analog** input?
* The simplest ==analog== sensor in the ==LiftOff kit== is the **potentiometer**.

**NOTE:** The potentiometer must be connected to an **analog** input position on the ==octopus:bit==.
`}
<LegendOctoHeader 
  pin={1}
  peripheral={8} // 8 = Potentiometer
  markdown={`Connect the **Potentiometer** to **position 1** on your ==octopus:bit==!`}
/>
{`
*Now how do I read it with **code?***

Use the \`read_analog()\` function from the **micro:bit** library.
* \`read_analog()\` returns a value from 0 to 1023.
* Rotating the **potentiometer** changes the **analog** value.

**NOTE:** The potentiometer may not get all the way down to 0 or up to 1023.

Before you use \`read_analog()\` you should setup your ==pin== as an *input*. You can use a single \`read_digital()\` function call to set it up:
\`\`\`
pin1.read_digital() # setup pin as input
\`\`\`

Now you can read the value of the potentiometer. Define a new \`read_knob()\` ==function== that ==return==s the ==analog== value of the **potentiometer**.
\`\`\`
def read_knob():
    return pin1.read_analog()
\`\`\`

**Ooh! This is perfect!!** 
* Your new \`read_knob()\` function returns a value between 0 and 1023
* and the \`write_analog(value)\` function takes a \`value\` between 0 and 1023
  * *SO...* you can directly ***write*** the value that you ***read!***
* *Soon you'll be rotating the knob to dim the LED!*
`}
<LegendMessageKeyboard>
{`
1. Add the line \`pin1.read_digital()\` to set the pin as an *input*.
2. Define a new function \`def read_knob():\` that returns the ==analog== value of the potentiometer.
3. Replace your *hard-coded* \`write_analog(X)\` function call with **two** lines of code:
    * Read and save the current potentiometer value with \`knob_val = read_knob()\`
    * Set the LED analog value with \`pin0.write_analog(knob_val)\`
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(1) # ms
$pin1.read_digital() # setup pin as input

$def read_knob():
$    return pin1.read_analog()

def set_white_led(val):
    pin0.write_digital(val)

$knob_val = read_knob()
$pin0.write_analog(knob_val) # duty cycle => 0 to 1023
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
**REMINDER:** This program does not loop. It will read the potentiometer ***one*** time, set the LED, and end.
`}
</LegendMessageRun>
<LegendMessageInteract title="Rotate the knob and run your program again.">
{`Try *both ends* of the potentiometer's range - *clockwise* and *counterclockwise.*
* You should be able to see a difference!
* But maybe not as much as you'd expect...
  * Your eye's *perception* of brightness doesn't exactly match the power level of the light (lumens).
`}
</LegendMessageInteract>
</Markdown>
<StepComplete
            prompt="Did your LED change brightness with different knob settings?"
            xp={5}
            successMessage=
{`## Rotational Control! {centered}
### You're reading analog inputs and dimming LEDs!
Hey, ***light dimmers*** are only the beginning :-)
* You have some fundamental *electronic control* techniques in your toolbelt now!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','read_knob']}
            reqArgs={['knob_val']}
            reqNames={['LED_ON', 'LED_OFF','knob_val']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class DimmerKnob extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Dimmer {lesson-title}
___
#### Now, make your knob dim the LED without having to re-run your code.
You know what to do... It's time to get *loopy!*
`}
<img src={DimmerImg} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
<LegendMessageKeyboard>
{`
* Move the ==analog== *read* and *write* into a \`while\` ==loop== to make your **LED** dim in ***real-time.***
\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.set_analog_period(1) # ms
pin1.read_digital() # setup pin as input

def read_knob():
    return pin1.read_analog()

def set_white_led(val):
    pin0.write_digital(val)

$$$
$while True:
$    knob_val = read_knob()
$    pin0.write_analog(knob_val) # duty cycle => 0 to 1023
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Rotate the knob back and forth a few times and watch the LED."></LegendMessageInteract>

</Markdown>
<StepComplete
            prompt="Does your LED constantly change brightness when you rotate the knob?"
            xp={5}
            successMessage=
{`## Congratulations! {centered}
### You built a custom dimmer!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','read_knob']}
            reqArgs={['knob_val']}
            reqNames={['LED_ON', 'LED_OFF','knob_val']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class MotionAndDim extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Motion Detect and Dim Together {lesson-title}
___
#### Add the LED dimming back into your program.
`}
<LegendMessageKeyboard>
{`
Split your \`set_white_led(val)\` ==function== into two ==branch==es:
1. If \`val\` is \`LED_ON\`, use \`read_knob()\` to set the **LED**.
2. Otherwise, turn the **LED** off.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

MOTION_DETECTED = 1

pin0.set_analog_period(1) # ms
pin1.read_digital() # setup pin as input

def read_knob():
    return pin1.read_analog()

$$$
$def set_white_led(val):
$    if val == LED_ON:
$        knob_val = read_knob()
$        pin0.write_analog(knob_val) # duty cycle => 0 to 1023
$    else:
$        pin0.write_digital(LED_OFF)
$$$

def read_motion():
    return pin2.read_digital()

while True:
    motion_val = read_motion()

    if motion_val == MOTION_DETECTED:
        set_white_led(LED_ON)
    else:
        set_white_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Let the motion sensor work and use the knob to change the brightness."></LegendMessageInteract>
</Markdown>
<StepComplete
            prompt="Is your dimmer working while motion is detected?"
            xp={5}
            successMessage=
{`## Whoop! {centered}
### Multi-Sensor Mastery
Your **lighting system** is *really* coming together!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob','read_motion']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','read_knob','pin2.read_digital','read_motion']}
            reqArgs={['LED_ON', 'LED_OFF','knob_val']}
            reqNames={['LED_ON', 'LED_OFF','MOTION_DETECTED','knob_val','motion_val']}
            reqStatements={['while','if','else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class MotionSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Motion Detector!! {lesson-title}
___
`}
<img src={PirImage} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### You can save even energy if you only turn on the lights when people are in the room. {centered}
*But how can you detect when people are in the room?*
* You can start by sensing **motion**.

The PIR sensor in the ==LiftOff Kit== is a Passive Infrared (PIR) motion sensor:
* It detects changes in **infrared (IR) light** from objects in its **field of view**.
* It can tell you that something ***moved!***
* ...but not *what* moved. Just assume it's an astronaut :-)
`}
<LegendOctoHeader 
  pin={2}
  peripheral={1} // 1 = PIR Sensor
  markdown={`Connect the **Motion Sensor** to **position 2** on your ==octopus:bit==!`}
/>
{`### Note:
This sensor has its own **Status LED**!
* Do you see a *blue LED* light up next to the connector?
* It is showing you the status of ***motion detection.***
  * *That could be a handy debugging tool!*
`}
<Quiz
        lessons={this.props.lessons}
        prompt="Which could you detect with a Passive IR Sensor?"
        xp={5}
        answerRight={"An animal moving"}
        answerWrong={["A person sitting still", "Radio waves"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if the PIR sensor is connected!"
      />
    </div>
  )
  }
}

export class DetectMotion extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Detect Motion!! {lesson-title}
___
`}
<img src={MotionImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
Most sensors in the LiftOff kit provide ==analog== values. 

But the **Motion Sensor** is ==digital==.
* You use the \`read_digital()\` ==function== in the **micro:bit** ==library==.
  * Just like reading an external *button*!!

The **Motion Sensor** will output:
* a **HIGH** value when it detects motion
* a **LOW** value when there is no motion

**NOTE:** The sensor will remain **HIGH** for a brief period after motion has stopped.
`}
<div style={{clear:'both'}} />
<LegendMessageKeyboard>
{`
1. Declare a ==constant== that will mean *motion detected.*
\`\`\`
MOTION_DETECTED = 1
\`\`\`
2. Define a \`def read_motion():\` ==function== that returns the ==digital== value of the motion sensor.
3. Completely replace the contents of your current \`while True:\` ==loop== with the following *algorithm*:
    * Read the motion sensor using the \`read_motion()\` ==function==
    * \`if\` motion is detected turn the LED **ON**
    * \`else\` turn the LED **OFF**

\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

$MOTION_DETECTED = 1

pin0.set_analog_period(1) # ms
pin1.read_digital() # setup pin as input

def read_knob():
    return pin1.read_analog()

def set_white_led(val):
    pin0.write_digital(val)

$def read_motion():
$    return pin2.read_digital()

while True:
$    motion_val = read_motion()

$    if motion_val == MOTION_DETECTED:
$        set_white_led(LED_ON)
$    else:
$        set_white_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Don't Move!">
{`
### Be still... wait until your LED turns *off*.
**NOTE:** You may need to block the sensor if there is a lot of motion in your area.
`}
</LegendMessageInteract>
<LegendMessageInteract title="Wave your arm to turn the LED on." />
</Markdown>
<StepComplete
            prompt="Is the white LED turning on when you detect motion?"
            xp={5}
            successMessage=
{`## Move and Capture! {centered}
### You are detecting motion with the PIR sensor!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob','read_motion']}
            reqCalls={['pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','pin2.read_digital','read_motion','set_white_led']}
            reqArgs={['LED_ON','LED_OFF']}
            reqNames={['LED_ON', 'LED_OFF','MOTION_DETECTED','motion_val']}
            reqStatements={['while','if','else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}


export class DelayShutdown extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Delayed Shutdown {lesson-title}
___
#### It would probably be annoying if your lights shut off every time you stopped moving.

*What if you want the lights to stay on a while each time motion is detected?*

* You could use a ==delay== like \`sleep()\` to keep the LED on.
* Start by defining how long you want the lights to stay on in **milliseconds**. 

This ==constant== will keep the lights ON for 10 seconds:
\`\`\`
ON_TIME = 10000 # 10 seconds
\`\`\`
`}
<LegendMessageKeyboard>
{`
1. Add the \`ON_TIME\` declaration near the top of your code.
2. Add a ==delay== for \`ON_TIME\` to your ==loop== whenever motion is detected:
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

MOTION_DETECTED = 1

$ON_TIME = 10000 # 10 seconds

pin0.set_analog_period(1) # ms
pin1.read_digital() # setup pin as input

def read_knob():
    return pin1.read_analog()

def set_white_led(val):
    if val == LED_ON:
        knob_val = read_knob()
        pin0.write_analog(knob_val) # duty cycle => 0 to 1023
    else:
        pin0.write_digital(LED_OFF)

def read_motion():
    return pin2.read_digital()

$$$
while True:
    motion_val = read_motion()

    if motion_val == MOTION_DETECTED:
        set_white_led(LED_ON)
$        sleep(ON_TIME)
    else:
        set_white_led(LED_OFF)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Move it!" >
{`Create ***motion*** to turn ON your LED then stop moving long enough for the LED to turn OFF.
* Your LED should stay ON for \`ON_TIME\` after the sensor detects motion.
`}
</LegendMessageInteract>
{`
*Did you notice any problems with the new delay?*

**Your *knob* "works"... but will only dim the light when motion is first detected.**
* The **knob** stops responding when your code is in \`sleep()\`.

Test this to confirm.
`}
<LegendMessageInteract title="Create motion again to turn on your LED." >
{`
Try to dim the LED during the 10 second \`ON_TIME\`.
`}
</LegendMessageInteract>
{`
**Oh No! It stopped responding!!**

You will need some way to prevent the ==delay== from **blocking** your dimmer.
`}
</Markdown>
<StepComplete
            prompt="Did your lights stay on for ON_TIME after motion was detected?"
            xp={5}
            successMessage=
{`## All These Delays! {centered}
### Next, you need to prevent the program from blocking your dimmer!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob','read_motion']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','read_knob','pin2.read_digital','read_motion','sleep']}
            reqArgs={['LED_ON', 'LED_OFF','knob_val','ON_TIME']}
            reqNames={['LED_ON', 'LED_OFF','MOTION_DETECTED','knob_val','motion_val','ON_TIME']}
            reqStatements={['while','if','else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

export class TimerShutdown extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Time to Blackout {lesson-title}
___
#### Make your *LED dimming knob* work all the time!
`}
<img src={BlackoutImg} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
The \`sleep()\` in your ==loop== is blocking your ability to change the brightness.
* The \`sleep()\` function doesn't return until *after* the requested time has elapsed.
* So your code is ***stopped in its tracks*** while it waits for \`sleep()\` to finish!

If you want the **knob** to work all the time, your code needs to continuously **read** it in a ==loop==.
* Is there a way to also *read* the ***time*** while you're looping?
`}
<LegendMessageConcept title="Concept: Non-Blocking Timer">
<img src={WatchImg} alt=""
    style={{
      width: '20%', margin: 'auto', display:'block', float:'right'
    }}
/>
<Markdown>
{`Going to sleep **blocks** your code from doing anything else!
* Luckily there's a built-in **Timer** hardware ==peripheral== in your micro:bit.
* Your code can **check the time** whenever it needs to see how much time has elapsed.
  * Like glancing at your watch... *"Is it time yet?"*

From the moment you turn ON the micro:bit, the **Timer** is *always running*.

Here's a built-in **function** that returns exactly how many *milliseconds* have elapsed since the last ==reboot==:
\`\`\`
# Return number of milliseconds since reboot
running_time()
\`\`\`
`}
</Markdown>
</LegendMessageConcept>
{`### Keeping track of time
*Imagine **you** are the computer...*

When motion is detected, you need to glance at your watch.
* *"Okay, 10 seconds from now I should turn the LED off."*
  * Um, better write down the *"turn-off time"* somewhere.
* Then keep checking the **knob** like always...
* But also keep an eye on your watch.
  * And \`if\` the time is up, ***turn the LED off!***

So there's your **Algorithm**. Now you just have to *code* it!
`}
<LegendMessageKeyboard>
{`
1. Add this ==variable== near the top of your file:
\`\`\`
turn_off_time = running_time()
\`\`\`
2. When motion is detected, update the \`turn_off_time\` to \`running_time()\` + \`ON_TIME\`.
3. \`if running_time() > turn_off_time:\` &rarr; Turn **OFF** the LED.
4. \`else:\` &rarr; Turn **ON** the LED.  *(No more \`sleep()\`!)*

\`\`\` collapsed Peek here if you need help...
from microbit import *

LED_ON = 1
LED_OFF = 0

MOTION_DETECTED = 1

ON_TIME = 10000 # 10 seconds
$turn_off_time = running_time()

pin0.set_analog_period(1) # ms
pin1.read_digital() # setup pin as input

def read_knob():
    return pin1.read_analog()

def set_white_led(val):
    if val == LED_ON:
        knob_val = read_knob()
        pin0.write_analog(knob_val) # duty cycle => 0 to 1023
    else:
        pin0.write_digital(LED_OFF)

def read_motion():
    return pin2.read_digital()

$$$
while True:
    motion_val = read_motion()

$    if motion_val == MOTION_DETECTED:
$        # Make a note of when to turn off LED.
$        turn_off_time = running_time() + ON_TIME
$
$    if running_time() > turn_off_time:
$        set_white_led(LED_OFF)
$    else:
$        set_white_led(LED_ON)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Try to change the brightness of your LED in the ON_TIME window:" >
{`
* After motion is detected
* Before the LED turns OFF
`}
</LegendMessageInteract>
</Markdown>
<StepComplete
            prompt="Is your lighting system working as expected?"
            xp={25}
            successMessage=
{`## Complete Lighting System! {centered}
### You have created a quality lighting system ready for the crew!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_knob','read_motion']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin0.write_digital','pin1.read_digital','pin1.read_analog','read_knob','pin2.read_digital','read_motion','running_time']}
            reqArgs={['LED_ON', 'LED_OFF','knob_val']}
            reqNames={['LED_ON', 'LED_OFF','MOTION_DETECTED','knob_val','motion_val','ON_TIME','turn_off_time']}
            reqStatements={['while','if','else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class LongLasting extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## It just keeps going and going! {lesson-title}
___

### You've completed project *Conserve Energy!*
...and you helped save enough energy to get the crew to Mars.

What else does the mission require?
* ***Plenty!*** 
And you're developing the *coding skills* to make it happen!
`}
        <img src={FinalEnergyImg} alt="" style={{margin: 'auto', width:'100%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="The crew thanks you for the tremendous lighting.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectConserve.stepId = 'ProjectConserve'
LightingSystem.stepId = 'LightingSystem'
OnOffOnOff.stepId = 'OnOffOnOff'
PwmLed.stepId = 'PwmLed'
AnalogInput.stepId = 'AnalogInput'
DimmerKnob.stepId = 'DimmerKnob'
MotionAndDim.stepId = 'MotionAndDim'
MotionSensor.stepId = 'MotionSensor'
DetectMotion.stepId = 'DetectMotion'
DelayShutdown.stepId = 'DelayShutdown'
TimerShutdown.stepId = 'TimerShutdown'
LongLasting.stepId = 'LongLasting'

export const conserveEnergy = [
  ProjectConserve,
  LightingSystem,
  OnOffOnOff,
  PwmLed,
  AnalogInput,
  DimmerKnob,
  MotionSensor,
  DetectMotion,
  MotionAndDim,
  DelayShutdown,
  TimerShutdown,
  LongLasting,
]
