import React from 'react'
import PropTypes from 'prop-types'

import AppBar from 'material-ui/AppBar'
import Toolbar from 'material-ui/Toolbar'
import Typography from 'material-ui/Typography'
import logo from '../assets/bot.min.svg'


const HeaderIcon = () => (
  <div style={{
      width: 35,
      marginLeft: 4,
      marginRight: 4,
      paddingRight: 0,
      flex: '0 0 auto',
    }}
  >
    <img
      src={logo}
      style={{
        height: 40,
        marginTop: 10,
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 0,
      }}
      alt=""
    />
  </div>
)

const HeaderTitle = () => (
  <div style={{ display: 'flex', flex: '1 0 auto' }}>
    <Typography variant="title" noWrap color="inherit">
      Teacher Dashboard
    </Typography>
  </div>
)

class HeaderBar extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    getUserDisplay: PropTypes.element.isRequired,
  }

  render() {
    return (
      <AppBar
        position="fixed"
        className={this.props.classes.appBar}
      >
        <Toolbar className={this.props.classes.headerHeight} color="primary">
          <HeaderIcon />
          <HeaderTitle />
          {
            this.props.getUserDisplay
          }
        </Toolbar>
      </AppBar>
    )
  }
}

export default HeaderBar
