import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import DialogActions from 'material-ui/Dialog/DialogActions'
import DialogContent from 'material-ui/Dialog/DialogContent'
import DialogTitle from 'material-ui/Dialog/DialogTitle'
import Button from 'material-ui/Button'
import TextField from 'material-ui/TextField'
import Typeography from 'material-ui/Typography'
import MenuItem from 'material-ui/Menu/MenuItem'
import { CircularProgress } from 'material-ui/Progress'
import { data, Database } from '../DataSources'
import { moduleEnums } from '../../lessons/CurriculumModules'


class NewGroupModal extends React.Component {
  static propTypes = {
    setVisibility: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    refreshData: PropTypes.func.isRequired,
    uiDisabled: PropTypes.bool.isRequired,
    disableUi: PropTypes.func.isRequired,
  }

  state = {
    groupName: 'Default Classroom Name',
    curriculum: data.owner.availableCurricula[0],
    errorMsg: '',
  }

  handleClose = () => {
    this.setState({ errorMsg: '' })
    this.props.setVisibility(false)
  }

  handleCreate = () => {
    this.setState({ errorMsg: '' })
    if (this.state.groupName !== '') {
      this.props.disableUi(true)
      Database.createGroup(this.state.groupName, this.state.curriculum).then(() => {
        this.props.refreshData()
        this.handleClose()
        this.props.disableUi(false)
      }).catch((d) => {
        this.props.disableUi(false)
        this.setState({ errorMsg: d.message })
      })
    } else {
      this.setState({ errorMsg: 'You must input a group name' })
    }
  }

  updateField = (ev) => {
    this.setState({ [ev.target.name]: ev.target.value })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        scroll="paper"
        maxWidth={false}
      >
        <DialogTitle>Create a New Classroom</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            autoFocus
            margin="dense"
            id="groupName"
            name="groupName"
            label="Classroom Name"
            type="text"
            value={this.state.groupName}
            onChange={this.updateField}
            fullWidth
            disabled={this.props.uiDisabled}
          />
          <TextField
            id="curriculum"
            name="curriculum"
            select
            label="Curriculum"
            value={this.state.curriculum}
            onChange={this.updateField}
            disabled={this.props.uiDisabled}
          >
            {data.owner.availableCurricula.map((option) => {
              const currVal = Object.keys(moduleEnums).find(key => moduleEnums[key] === option)

              return (
                <MenuItem key={option} value={option}>
                  {data.codespace[currVal].name}
                </MenuItem>
              )
            })}
          </TextField>
          <Typeography style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
            { this.state.errorMsg }
          </Typeography>
        </DialogContent>
        <DialogActions>
          <Button disabled={this.props.uiDisabled} onClick={this.handleCreate} color="primary">
            {
              this.props.uiDisabled ?
                <CircularProgress
                  style={{
                    margin: 'auto',
                    width: 20,
                    height: 20,
                  }}
                /> :
                'Create'
            }
          </Button>
          <Button disabled={this.props.uiDisabled} onClick={this.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default NewGroupModal
