
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'

import LifeSupportIntroImg from './assets/iStock-829469376.jpg'
import ServosImg from './assets/iStock-1263999064.jpg'
import FinalLifeSupportImg from './assets/iStock-1292243111.jpg'

import ContinuousServoImg from './assets/peripherals/pk_360_serv.jpg'
import PositionalServoImg from './assets/peripherals/pk_180_serv.jpg'
import Servo360Img from './assets/servo_360_img.jpg'
import PwmImg from './assets/pwm.jpg'
import PowerSwitchImg from './assets/iStock-478272220.jpg'

import TrafficLightStateDiagramImg from './assets/traffic_light_state_diagram.jpg'
import CirculationStateDiagramImg from './assets/circulation_state_diagram.jpg'


import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'


import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
//  LegendMessageTry,
} from '../Legends'

export const LifeSupportImg = GoogleClassroomImg

class ProjectLifeSupport extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Life Support! {lesson-title}
___
### This project shows you how to operate a 360 continuous servo to circulate life-giving air through the ship!
`}
<img src={LifeSupportIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The air we breathe is one of the most fundametal elements of human life.
* The spacecraft life support systems must be operational to guarantee mission success!

**The circulation of air is critical to survival in space.**
* It mixes oxygen with carbon dioxide to guarantee breathable air.
* It collects condensation on the ship's hull and recycles it.
* It mixes warm and cold air for the perfect environment for both crew and electronics.

**Project: Life Support** will guide you through rotating fans to guarantee proper air circulation on the ship.

#### Project Goals:
* Learn about 360 Continuous Rotation Servos
* Understand the capabilities of a 360 Servo
* Add a power switch to the life support system
* Explore the concept of a finite-state machine (FSM)
* Circulate air to support the crew on their journey through space!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class ServosIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Introduction to Servos {lesson-title}
___
`}
<img src={ServosImg} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
#### You will need fans to operate the ship's life support system and circulate air. {centered}

When you think about a ==servo== motor you probably assume that it is nothing more than a DC, electrically operated motor.

This is sort of true.

A DC motor can come in various configurations, but in the end it is just a motor.
* When power is applied the motor turns.

**A ==servo== is more than just a motor. It contains:**
* A DC motor
* A controller circuit
* An internal feedback mechanism
* A gearbox

**Small hobby servos generally come it two types:**
* The **360 Continuous Rotation Servo** which can rotate continuously backward and forward.
`}
<img src={ContinuousServoImg} alt="" style={{margin: 'auto', width:'30%', display: 'block'}} />
{`
* The **180 Positional Servo** which can move to a specified position and hold in place.
`}
<img src={PositionalServoImg} alt="" style={{margin: 'auto', width:'30%', display: 'block'}} />

{`### Mechanical Connection to Servos
The rotating drive-shaft of a servo is has ridges called *splines* that allow to you securely
attach *wheels*, *arms*, or *servo-horns* to move things.
* There's a servo-horn in your kit!
* Try attaching it to your servo so you can better see the motion.
`}

<Quiz
        lessons={this.props.lessons}
        prompt="Which type of device moves to a specified position and holds in place?"
        xp={5}
        answerRight={"180 Servo"}
        answerWrong={["360 Servo", "Water Pump"]}
      />
<Quiz
        lessons={this.props.lessons}
        prompt="Which is NOT a part of a servo motor?"
        xp={5}
        answerRight={"Speaker"}
        answerWrong={["DC Motor", "Controller Circuit"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to get started with servos!"
      />
    </div>
  )
  }
}

class ContinuousServo extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## 360 Servo {lesson-title}
___
#### You will need fans to operate the ship's life support system and circulate air. {centered}
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **LifeSupport**
`}
</LegendMessageInteract>
{`
#### Now attach the 360 servo!
* The 360 servo will drive a fan to circulate air through the spacecraft.
`}
<LegendOctoHeader 
  pin={0}
  peripheral={7} // 7 = 360 Servo
  markdown={`
  Connect the **360 Servo** to **position 0** on your ==octopus:bit==!

  **NOTE:** Your ==servo== will have orange, red, and brown wires.  Make sure:
  * The **ORANGE** wire is connected to the **YELLOW** pin
  * The **RED** wire is connected to the **RED** pin
  * The **BROWN** wire is connected to the **BLACK** pin
  `}
/>
{`
**Continous Rotation Servos and DC Motors could have many REAL uses on a space mission:**
* Motors for rover wheels
* Fans for life support
* Hydraulic pump operation
* Spin antennas / dishes
* Reel in mechanical components

**360 Servos** have no sense of **position**, but they have the ability to rotate forward or backward continuously.
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to drive your 360 Servo!"
      />
    </div>
  )
  }
}

class MovingForward extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Moving Forward {lesson-title}
___
`}
<img src={Servo360Img} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
#### Start by turning the servo clockwise. {centered}

*How do I make the servo go?*

==Servo== motors require an **analog control signal** to operate.
* You can send an analog control signal using ==PWM==!
* You will learn how the control signal works later in the project.
* Start by getting it moving!!
`}
<LegendMessageKeyboard>
{`
This will run the servo motor clockwise for 5 seconds and then stop it.
\`\`\`
from microbit import *

pin0.set_analog_period(20)

# clockwise 50% speed
pin0.write_analog(1023 * 1.0 / 20)

sleep(5000)

# stopped
pin0.write_analog(1023 * 1.5 / 20)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Did your servo spin clockwise?"
            xp={5}
            successMessage=
{`## Moving Forward! {centered}
### You are driving a servo. Move ahead to learn how it works!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period']}
            reqArgs={['20']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class AnalogControl extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Analog Control Signal {lesson-title}
___
`}
<img src={PwmImg} alt=""
    style={{
      width: '50%', margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
#### Time to Learn about Servo Analog Control! {centered}

*How did your servo driving code work?*

Start with this line:
\`\`\`
pin0.set_analog_period(20)
\`\`\`

You are setting the analog period to 20 milliseconds.

Nearly all servos operate with a **50 Hertz (Hz)** control signal.
* 50 Hz = 50 times per second = 20 millisecond analog period.

50 Hz became a standard long ago due to the simplicity of the hardware design.
* And, well, it just stuck...

Now, look at this line:
\`\`\`
pin0.write_analog(1023 * 1.0 / 20)
\`\`\`

The micro:bit function \`write_analog\` takes a value between 0 and 1023.

**In each analog period, if you want the signal to be...**
* **ON** for 1 millisecond
* **OFF** for 19 milliseconds

You will need to set your duty cycle to 1/20th of 1023!

**The control signal can be set to duty cycles between 0.5 milliseconds and 2.5 milliseconds.**
`}
<Quiz
        lessons={this.props.lessons}
        prompt="What is the standard analog period for a servo's control signal?"
        xp={5}
        answerRight={"50 Hz"}
        answerWrong={["20 Hz", "50 milliseconds"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to learn how different duty cycles affect speed!"
      />
    </div>
  )
  }
}

class ReverseDirection extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Reverse Direction {lesson-title}
___
`}
<img src={PwmImg} alt=""
    style={{
      width: '50%', margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
#### What are the settings to reverse the servo? {centered}

The duty cycle for a 360 servo determines which direction and speed it turns.
* Your servo is designed to operate with a duty cycle between 0.5 milliseconds and 2.5 milliseconds.

**This table shows duty cycles with speed and direction:**

| Duty Cycle (ms) | Speed | Direction             |
|-----------------|-------|-----------------------|
|       0.5       | 100%  |    Clockwise          |
|       0.75      |  75%  |    Clockwise          |
|       1.0       |  50%  |    Clockwise          |
|       1.25      |  25%  |    Clockwise          |
|       1.5       |   0%  |    Stopped            |
|       1.75      |  25%  |    Counterclockwise   |
|       2.0       |  50%  |    Counterclockwise   |
|       2.25      |  75%  |    Counterclockwise   |
|       2.5       | 100%  |    Counterclockwise   |

Add a function to set the **duty cycle** for your servo.
\`\`\`
def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)
\`\`\`
`}
<LegendMessageWarning>
{`
Your servo may **fail** to operate at a **2.5 ms** duty cycle using this \`set_servo\` function.
* The servo has a maximum limit of 2.5 milliseconds.
* You could exceed the maximum parameters of the servo due to rounding issues.

**This is not a big deal!**
* The servo will just stop moving if the limit is exceeded.

You can always set your maximum to 2.4 milliseconds OR round your value down to the nearest full number.
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`
Use your new function to run the servo motor counterclockwise for 5 seconds and then stop it.
\`\`\` collapsed Try it yourself first...
from microbit import *

pin0.set_analog_period(20)

$def set_servo(ms_duty_cycle):
$    pin0.write_analog(1023 * ms_duty_cycle / 20)

$# counterclockwise 50% speed
$set_servo(2.0) 

sleep(5000)

# stopped
$set_servo(1.5) 
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Did your servo spin counterclockwise?"
            xp={5}
            successMessage=
{`## Backing Up! {centered}
### You now understand how to control the 360 servo!
`}
            reqImports={['microbit']}
            reqCalls={['sleep','pin0.write_analog','pin0.set_analog_period','set_servo']}
            reqArgs={['20','1.5']}
            reqFuncdefs={['set_servo']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class AddASwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Down for Maintenance! {lesson-title}
___
`}
<img src={PowerSwitchImg} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
#### The crew needs the ability to shut down the fan periodically to perform maintenance.

You can use a switch to disable the circulation system temporarily.
`}
<LegendOctoHeader 
  pin={1}
  peripheral={4} // 7 = 360 Servo
  markdown={`Connect the **Switch** to **position 1** on your ==octopus:bit==!`}
/>
{`
Add \`POWER_ON\` and \`POWER_OFF\` ==variables== to compare the switch position:
\`\`\`
POWER_ON = 0
POWER_OFF = 1
\`\`\`

Now, create a ==function== to read the power switch:
\`\`\`
def read_power_switch():
    return pin1.read_digital()
\`\`\`

`}
<LegendMessageKeyboard>
{`
Finally, put your code in a \`while\` ==loop==.
1. Read the power switch

2. \`if\` the power is **OFF** stop the servo

3. \`if\` the power is **ON** rotate the servo clockwise at 50% speed

\`\`\`
from microbit import *

$POWER_ON = 0
$POWER_OFF = 1

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

$def read_power_switch():
$    return pin1.read_digital()

$while True:
$    switch_val = read_power_switch()

$    if switch_val == POWER_OFF:
$        set_servo(1.5) # stopped
$    if switch_val == POWER_ON:
$        set_servo(1.0) # 50% speed clockwise
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the switch IN to the ON position." />
<LegendMessageInteract title="Press the switch OUT to the OFF position." />

</Markdown>
<StepComplete
            prompt="Is your circulation system turning ON and OFF?"
            xp={5}
            successMessage=
{`## Great Job! {centered}
### You have incorporated a power switch!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin1.read_digital','read_power_switch','set_servo']}
            reqArgs={['20', '1.5', '1.0']}
            reqFuncdefs={['set_servo','read_power_switch']}
            reqNames={['POWER_ON', 'POWER_OFF', 'switch_val']}
            reqStatements={['while','if']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class FiniteStateMachine extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Software States! {lesson-title}
___
### Time to add more definition to the system's modes.

The circulation system should have two different operating modes:
* Active
* Maintenance

You have already added a switch to transition between the modes:
* \`if\` the switch is **ON** - Run the fan (Active Mode)
* \`elif\` the switch is **OFF** - Stop the fan (Maintenance Mode)

*But, what if you wanted to add a third mode... or a fourth... and there were many ==conditions== involved?*

*What if you wanted one of those modes to only transition to and from another specific mode?*
* You could use a concept called the ==Finite-State Machine==.
`}
<LegendMessageConcept>
<Markdown>
{`
### Finite-State Machine

A ==Finite-State Machine==, sometimes called simply a ==State Machine==, is a key concept in software and hardware systems.

It is the idea that a program can only be in **one of a known set** of ==states== or "modes" at any given time.

Keeping track of ==states== helps you as a programmer understand and manage your code.

The software can ==transition== from one state to another when certain ==conditions== are met.

**An example of this is a traffic light.**

Most traffic lights are three colors:
* Red
* Yellow
* Green

Traffic lights, in the United States of America, generally only have three states:
* GREEN = Traffic can go
* YELLOW = Caution the light will soon be red
* RED = Traffic must stop

The ==transitions== are simple and all transitions occur after a time delay:
* GREEN only transitions to YELLOW
* YELLOW only transitions to RED
* RED only transitions to GREEN

Here is a visual representation of a ==state machine== for a traffic light in the USA:
`}
<img src={TrafficLightStateDiagramImg} alt=""
    style={{
      width: '341px', margin: 'auto', display:'block'
    }}
/>
</Markdown>
</LegendMessageConcept>
<Quiz
        lessons={this.props.lessons}
        prompt="What is it called when a program moves into a new state?"
        xp={5}
        answerRight={"transition"}
        answerWrong={["switch", "change"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to apply a State Machine to the circulation system!"
      />
    </div>
  )
  }
}


class FirstStateMachine extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Your First State Machine! {lesson-title}
___
### Time to Apply a ==Finite-State Machine== to the Circulation System.

Remember, the circulation system should have two different states:
* \`'active'\`
* \`'maintenance'\`

The ==transitions== are simple:
* \`'active'\` transitions to \`'maintenance'\` when the switch changes to \`POWER_OFF\`
* \`'maintenance'\` transitions to \`'active'\` when the switch changes to \`POWER_ON\`

Here is a visual depication of the ==state== machine:
`}
<img src={CirculationStateDiagramImg} alt=""
    style={{
      width: '365px', margin: 'auto', display:'block'
    }}
/>
{`
The system should begin in the \`'active'\` state.

Start by creating a ==variable== called \`state\` near the top of your file.

You can make your \`state\` variable a ==string== ==type== for now so that it is easy to read:
\`\`\`
state = 'active'
\`\`\`
`}

<LegendMessageKeyboard>
{`
Now, modify your \`while\` ==loop== to use the \`state\` rather than just the power switch.
\`\`\`
from microbit import *

POWER_ON = 0
POWER_OFF = 1

$state = 'active'

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def read_power_switch():
    return pin1.read_digital()

while True:
    switch_val = read_power_switch()

$    if state == 'active':
$        if switch_val == POWER_OFF:
$            state = 'maintenance' # transition
$            set_servo(1.5) # stopped
$    elif state == 'maintenance':
$        if switch_val == POWER_ON:
$            state = 'active' # transition
$            set_servo(1.0) # 50% speed clockwise
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the switch IN to the ON position." />
<LegendMessageInteract title="Press the switch OUT to the OFF position." />
{`
This is a basic example of a ==finite-state machine==.  

You will get to expand on your knowledge of a ==state== machine in future lessons!
`}
</Markdown>
<StepComplete
            prompt="Is your circulation system transitioning your state machine?"
            xp={5}
            successMessage=
{`## Great Job! {centered}
### You have created a superb circulation system!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','pin1.read_digital','read_power_switch','set_servo']}
            reqArgs={['20', '1.5', '1.0']}
            reqFuncdefs={['set_servo','read_power_switch']}
            reqNames={['POWER_ON', 'POWER_OFF', 'switch_val', 'state']}
            reqStatements={['while','if','elif']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class LifeSupportFinal extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Life-Giving Air! {lesson-title}
___
### You've completed project Life Support!
You got a chance to work with your first servo. The crew is thankful for the perfectly climate controlled cabin. They can now survive the long trip through space!

**Great work!!**
`}
        <img src={FinalLifeSupportImg} alt="" style={{margin: 'auto', width:'40%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="You provided the crew with a suitable environment for the long trip.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectLifeSupport.stepId = 'ProjectLifeSupport'
ServosIntro.stepId = 'ServosIntro'
ContinuousServo.stepId = 'ContinuousServo'
MovingForward.stepId = 'MovingForward'
AnalogControl.stepId = 'AnalogControl'
ReverseDirection.stepId = 'ReverseDirection'
FiniteStateMachine.stepId = 'FiniteStateMachine'
FirstStateMachine.stepId = 'FirstStateMachine'
AddASwitch.stepId = 'AddASwitch'
LifeSupportFinal.stepId = 'LifeSupportFinal'

export const lifeSupport = [
  ProjectLifeSupport,
  ServosIntro,
  ContinuousServo,
  MovingForward,
  AnalogControl,
  ReverseDirection,
  AddASwitch,
  FiniteStateMachine,
  FirstStateMachine,
  LifeSupportFinal,
]
