// Diagnostic flags, etc. accessible from Chrome dev console.
// Usage:
//   At dev console, type window.firiaDiag() to see object fields.
//   To install default flags, set window.firia = window.firiaDiag()

// Note: bool flags should default to false since when diags aren't enabled they'll return false.
class FiriaDiagnostics {
  rawConsole = false
}

window.firiaDiag = () => new FiriaDiagnostics()

export function diagField(field) {
    return window.firia && window.firia[field]
}

export function setDiagField(field, value) {
    if (window.firia) {
        window.firia[field] = value
    }
}