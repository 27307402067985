import React, { Component } from 'react'

import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/Menu/MenuItem'
import Typeography from 'material-ui/Typography'
import Button from 'material-ui/Button'

import { CircularProgress } from 'material-ui/Progress'

import LicensePortalValidationModal from './LicensePortalModal'

import { moduleEnums, moduleDescription } from '../lessons/CurriculumModules'

import { cblFirebase } from '../myfirebase'

import { BASE_LICENSOR_URL } from '../CodeSpaceLicensor'

class LicensePortalForm extends Component {
  state = {
    email: '',
    name: '',
    curriculum: '1',
    seats: 1,
    errorMsg: '',
    validationPopupIsOpen: false,
    buttonDisabled: false,
    color: 'red',
    sendEmail: false,
    lastGenKey: '',
  }

  openValidationPopup = () => {
    this.setState({ validationPopupIsOpen: true })
  }

  closeValidationPopup = () => {
    this.setState({ validationPopupIsOpen: false })
  }

  cloudReturnFail = (jsonData) => {
    let msg = 'Unknown error in cloud function'
    if (jsonData !== null && jsonData.message !== 'undefined') {
      msg = jsonData.message
    }
    this.setState({ color: 'red', errorMsg: msg, buttonDisabled: false })
  }

  cloudReturnSuccess = (jsonData) => {
    this.setState({
      color: 'green',
      errorMsg: jsonData.message,
      buttonDisabled: false,
      email: '',
      name: '',
      curriculum: '3',
      seats: 1,
      lastGenKey: jsonData.licenseKey,
      amazonOrderId: '',
    })
  }

  callCloudFunc = () => {
    const expiresAt = new Date()
    expiresAt.setFullYear(expiresAt.getFullYear() + 3000)
    const data = {
      uid: cblFirebase.userDoc.id,
      fullName: this.state.name,
      email: this.state.email,
      curriculumName: moduleDescription[this.state.curriculum].name,
      curriculumId: moduleEnums[this.state.curriculum],
      numSeats: this.state.seats,
      expirationTime: (expiresAt).getTime(),
      sendEmail: this.state.sendEmail,
      amazonOrderId: this.state.amazonOrderId,
    }

    fetch(
      `${BASE_LICENSOR_URL}/licensePortal`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + accessToken
        },
        body: JSON.stringify(data),
      },
    )
      .then((response) => {
        response.json().then((jsonData) => {
          if (!response.ok) {
            this.cloudReturnFail(jsonData)
          } else {
            this.cloudReturnSuccess(jsonData)
          }
        })
      }).catch(() => {
        this.cloudReturnFail(null)
      })
  }

  dataValidated = () => {
    this.setState({ buttonDisabled: true })
    this.closeValidationPopup()
    this.callCloudFunc()
  }

  checkAmazonForm = () => {
    if (isNaN(this.state.seats) || !Number.isInteger(Number(this.state.seats))) {
      this.setState({ errorMsg: 'The number of seats must be an integer' })
    } else if (this.state.amazonOrderId === '') {
      this.setState({ errorMsg: 'You must input an Amazon Order ID' })
    } else {
      return true
    }
    return false
  }

  checkSendForm = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.name === '') {
      this.setState({ errorMsg: 'You must input a name' })
    } else if (isNaN(this.state.seats) || !Number.isInteger(Number(this.state.seats))) {
      this.setState({ errorMsg: 'The number of seats must be an integer' })
    } else if (this.state.email === '') {
      this.setState({ errorMsg: 'You must input an email address' })
    } else if (!this.state.email.includes('@')) {
      this.setState({ errorMsg: 'The email must contain an @ symbol' })
    } else if (!this.state.email.match(mailformat)) {
      this.setState({ errorMsg: 'The email address is improperly formatted' })
    } else {
      return true
    }
    return false
  }

  sendButtonPressed = () => {
    this.setState({ errorMsg: '', color: 'red', sendEmail: true, lastGenKey: '' })
    if (this.checkSendForm()) {
      this.openValidationPopup()
    }
  }

  amazonButtonPressed = () => {
    this.setState({ errorMsg: '', color: 'red', sendEmail: false, lastGenKey: '' })
    if (this.checkAmazonForm()) {
      this.openValidationPopup()
    }
  }

  render() {
    const codespacePayModules = []
    Object.keys(moduleDescription).forEach((prjId) => {
      if (moduleDescription[prjId].name.substring(0, 4) !== 'Free') {
        codespacePayModules.push({
          id: prjId,
          enum: prjId in moduleEnums ? moduleEnums[prjId] : '',
          name: moduleDescription[prjId].name,
        })
      }
    })

    return (
      <div>
        <div style={{ padding: '5px' }}>
          <Typeography variant="subheading" style={{ marginBottom: '5px', fontWeight: 'bolder' }} >Generate a License Key Form</Typeography>
          <TextField
            label="Curriculum"
            select
            margin="dense"
            value={this.state.curriculum}
            onChange={ev => this.setState({ curriculum: ev.target.value })}
            fullWidth
            style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}
          >
            {
              codespacePayModules.map(opt => <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>)
            }
          </TextField>
          <TextField
            label="Number of Seats"
            type="number"
            margin="dense"
            value={this.state.seats}
            onChange={ev => this.setState({ seats: ev.target.value })}
            fullWidth
            style={{ width: '100%', marginBottom: '80px' }}
          />
          <TextField
            label="Email Address"
            margin="dense"
            value={this.state.email}
            onChange={ev => this.setState({ email: ev.target.value })}
            fullWidth
            style={{ width: '100%', marginBottom: '5px' }}
            autoFocus
          />
          <TextField
            label="Full Name"
            margin="dense"
            value={this.state.name}
            onChange={ev => this.setState({ name: ev.target.value })}
            fullWidth
            style={{ width: '100%', marginBottom: '30px' }}
          />
          <Button color="primary" onClick={this.sendButtonPressed} disabled={this.state.buttonDisabled}>{this.state.buttonDisabled ? <CircularProgress /> : 'Send Email'}</Button>
          <TextField
            label="Amazon Order ID"
            margin="dense"
            value={this.state.amazonOrderId}
            onChange={ev => this.setState({ amazonOrderId: ev.target.value })}
            fullWidth
            style={{ width: '100%', marginTop: '30px', marginBottom: '30px' }}
          />
          <Button color="primary" onClick={this.amazonButtonPressed} disabled={this.state.buttonDisabled}>{this.state.buttonDisabled ? <CircularProgress /> : 'Amazon Order'}</Button>
          <Typeography variant="subheading" style={{ marginTop: '20px', color: this.state.color }}>{this.state.errorMsg}</Typeography>
          <TextField
            label="Last Generated License Key"
            margin="dense"
            value={this.state.lastGenKey}
            fullWidth
            style={{ width: '100%', marginTop: '20px', marginBottom: '5px' }}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <LicensePortalValidationModal
          open={this.state.validationPopupIsOpen}
          handleClose={this.closeValidationPopup}
          handleSend={this.dataValidated}
          email={this.state.email}
          name={this.state.name}
          curriculum={moduleDescription[this.state.curriculum].name}
          seats={this.state.seats}
          sendEmail={this.state.sendEmail}
          amazonOrderId={this.state.amazonOrderId}
        />
      </div>
    )
  }
}

export default LicensePortalForm

