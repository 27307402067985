import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'

const animationId = 'cssloader'
const animationId2 = 'cssloaderound'


// From: https://github.com/lukehaas/css-loaders
const styles = () => ({
  loader: {
    color: '#4ab848',
    fontSize: 90,
    textIndent: '-9999em',
    overflow: 'hidden',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    margin: '72px auto',
    position: 'relative',
    transform: 'translateZ(0)',
    animation: `${animationId} 1.7s infinite ease, ${animationId2} 1.7s infinite ease`,
  },
  [`@keyframes ${animationId}`]: {
    '0%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    '5%, 95%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
    '10%, 59%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em',
    },
    '20%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em',
    },
    '38%': {
      boxShadow: '0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em',
    },
    '100%': {
      boxShadow: '0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em',
    },
  },
  [`@keyframes ${animationId2}`]: {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
})


class Loading extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape(styles).isRequired,
  }

  render() {
    return (
      <div
        style={{
            display: 'flex',
            height: '100vh',
            width: '100wh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
      >
        <div id="loader" className={this.props.classes.loader}>Loading...</div>
      </div>
    )
  }
}

export default withStyles(styles)(Loading)
