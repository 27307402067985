// Firia Labs wrapper and extensions for react-joyride tour package

import React, {Component} from 'react'
import Joyride from 'react-joyride'
import PropTypes from 'prop-types'
import 'react-joyride/lib/react-joyride-compiled.css'
import './joy-override.css'

export const tourButtons = {
  back: 'Back',
  close: 'Close',
  last: 'Done',
  next: 'Next',
  skip: 'Skip'
}

class JoyrideOnRails extends Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    run: PropTypes.bool.isRequired,
    callback: PropTypes.func,
    ref2: PropTypes.func.isRequired,
  }

  componentWillUnmount = () => {
    // Add callback for when tour is destroyed, since the provided (ev.type === 'finished') won't always be called.
    if (this.props.run && this.props.callback) {
      this.props.callback({
        action: '',
        index: 0,
        type: 'terminated',
        step: 0
      })
    }
  }

  render() {
    return (
      <Joyride
        ref={this.props.ref2}
        steps={this.props.steps}
        run={this.props.run}
        autoStart={true}
        type={"continuous"}
        callback={this.props.callback}
        locale={tourButtons}
        disableOverlay={true}
      />
    )
  }
}

export default JoyrideOnRails
