import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { moduleProjects } from './lessons/CurriculumModules'

export default class GooglePreviewHelper extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        moduleId: PropTypes.string,
        projectId: PropTypes.string,
      }).isRequired,
    }).isRequired, // 'match' is injected by react-router
  }

  render() {
    try {
      // Lookup module/project based on provided params
      const projectList = moduleProjects[this.props.match.params.moduleId]
      const project = projectList[parseInt(this.props.match.params.projectId, 10) - 1]

      return (
        <img
          src={project.img}
          alt="Project"
        />
      )
    }
    catch (err) {
      if (err instanceof Error) {
        console.error(err)
      } else {
        console.error(new Error(err))
      }
      return null
    }
  }
}
