import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import './tracking'
import './index.css'
import Router from './Router'
import { unregister } from './registerServiceWorker'


ReactDOM.render(<Router />, document.getElementById('root'))
unregister()
