/* eslint-disable */
// Generated from src/Python3.g4 by ANTLR 4.7
// jshint ignore: start
var antlr4 = require('antlr4/index');
var Python3Listener = require('./Python3Listener').Python3Listener;
var grammarFileName = "Python3.g4";

var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003`\u0422\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f\u0004",
    "\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010\u0004",
    "\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013\u0004\u0014\t",
    "\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017\t\u0017\u0004",
    "\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a\u0004\u001b\t",
    "\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e\t\u001e\u0004",
    "\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0004#\t#\u0004$\t$\u0004",
    "%\t%\u0004&\t&\u0004\'\t\'\u0004(\t(\u0004)\t)\u0004*\t*\u0004+\t+\u0004",
    ",\t,\u0004-\t-\u0004.\t.\u0004/\t/\u00040\t0\u00041\t1\u00042\t2\u0004",
    "3\t3\u00044\t4\u00045\t5\u00046\t6\u00047\t7\u00048\t8\u00049\t9\u0004",
    ":\t:\u0004;\t;\u0004<\t<\u0004=\t=\u0004>\t>\u0004?\t?\u0004@\t@\u0004",
    "A\tA\u0004B\tB\u0004C\tC\u0004D\tD\u0004E\tE\u0004F\tF\u0004G\tG\u0004",
    "H\tH\u0004I\tI\u0004J\tJ\u0004K\tK\u0004L\tL\u0004M\tM\u0004N\tN\u0004",
    "O\tO\u0004P\tP\u0004Q\tQ\u0004R\tR\u0004S\tS\u0004T\tT\u0004U\tU\u0003",
    "\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0005\u0002\u00b0",
    "\n\u0002\u0003\u0003\u0003\u0003\u0007\u0003\u00b4\n\u0003\f\u0003\u000e",
    "\u0003\u00b7\u000b\u0003\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004",
    "\u0007\u0004\u00bd\n\u0004\f\u0004\u000e\u0004\u00c0\u000b\u0004\u0003",
    "\u0004\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0005",
    "\u0005\u00c8\n\u0005\u0003\u0005\u0005\u0005\u00cb\n\u0005\u0003\u0005",
    "\u0003\u0005\u0003\u0006\u0006\u0006\u00d0\n\u0006\r\u0006\u000e\u0006",
    "\u00d1\u0003\u0007\u0003\u0007\u0003\u0007\u0005\u0007\u00d7\n\u0007",
    "\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0005\b\u00de\n\b\u0003\b\u0003",
    "\b\u0003\b\u0003\t\u0003\t\u0005\t\u00e5\n\t\u0003\t\u0003\t\u0003\n",
    "\u0003\n\u0003\n\u0005\n\u00ec\n\n\u0003\n\u0003\n\u0003\n\u0003\n\u0005",
    "\n\u00f2\n\n\u0007\n\u00f4\n\n\f\n\u000e\n\u00f7\u000b\n\u0003\n\u0003",
    "\n\u0003\n\u0005\n\u00fc\n\n\u0003\n\u0003\n\u0003\n\u0003\n\u0005\n",
    "\u0102\n\n\u0007\n\u0104\n\n\f\n\u000e\n\u0107\u000b\n\u0003\n\u0003",
    "\n\u0003\n\u0005\n\u010c\n\n\u0003\n\u0003\n\u0005\n\u0110\n\n\u0005",
    "\n\u0112\n\n\u0003\n\u0003\n\u0005\n\u0116\n\n\u0003\n\u0003\n\u0003",
    "\n\u0003\n\u0005\n\u011c\n\n\u0007\n\u011e\n\n\f\n\u000e\n\u0121\u000b",
    "\n\u0003\n\u0003\n\u0003\n\u0005\n\u0126\n\n\u0003\n\u0003\n\u0005\n",
    "\u012a\n\n\u0003\u000b\u0003\u000b\u0003\u000b\u0005\u000b\u012f\n\u000b",
    "\u0003\f\u0003\f\u0003\f\u0005\f\u0134\n\f\u0003\f\u0003\f\u0003\f\u0003",
    "\f\u0005\f\u013a\n\f\u0007\f\u013c\n\f\f\f\u000e\f\u013f\u000b\f\u0003",
    "\f\u0003\f\u0003\f\u0005\f\u0144\n\f\u0003\f\u0003\f\u0003\f\u0003\f",
    "\u0005\f\u014a\n\f\u0007\f\u014c\n\f\f\f\u000e\f\u014f\u000b\f\u0003",
    "\f\u0003\f\u0003\f\u0005\f\u0154\n\f\u0003\f\u0003\f\u0005\f\u0158\n",
    "\f\u0005\f\u015a\n\f\u0003\f\u0003\f\u0005\f\u015e\n\f\u0003\f\u0003",
    "\f\u0003\f\u0003\f\u0005\f\u0164\n\f\u0007\f\u0166\n\f\f\f\u000e\f\u0169",
    "\u000b\f\u0003\f\u0003\f\u0003\f\u0005\f\u016e\n\f\u0003\f\u0003\f\u0005",
    "\f\u0172\n\f\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0005\u000e\u0178",
    "\n\u000e\u0003\u000f\u0003\u000f\u0003\u000f\u0007\u000f\u017d\n\u000f",
    "\f\u000f\u000e\u000f\u0180\u000b\u000f\u0003\u000f\u0005\u000f\u0183",
    "\n\u000f\u0003\u000f\u0003\u000f\u0003\u0010\u0003\u0010\u0003\u0010",
    "\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0005\u0010",
    "\u018f\n\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0005",
    "\u0011\u0195\n\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0005\u0011",
    "\u019a\n\u0011\u0007\u0011\u019c\n\u0011\f\u0011\u000e\u0011\u019f\u000b",
    "\u0011\u0005\u0011\u01a1\n\u0011\u0003\u0012\u0003\u0012\u0005\u0012",
    "\u01a5\n\u0012\u0003\u0012\u0003\u0012\u0003\u0012\u0005\u0012\u01aa",
    "\n\u0012\u0007\u0012\u01ac\n\u0012\f\u0012\u000e\u0012\u01af\u000b\u0012",
    "\u0003\u0012\u0005\u0012\u01b2\n\u0012\u0003\u0013\u0003\u0013\u0003",
    "\u0014\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0016\u0003",
    "\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0005\u0016\u01c0\n\u0016",
    "\u0003\u0017\u0003\u0017\u0003\u0018\u0003\u0018\u0003\u0019\u0003\u0019",
    "\u0005\u0019\u01c8\n\u0019\u0003\u001a\u0003\u001a\u0003\u001b\u0003",
    "\u001b\u0003\u001b\u0003\u001b\u0005\u001b\u01d0\n\u001b\u0005\u001b",
    "\u01d2\n\u001b\u0003\u001c\u0003\u001c\u0005\u001c\u01d6\n\u001c\u0003",
    "\u001d\u0003\u001d\u0003\u001d\u0003\u001e\u0003\u001e\u0007\u001e\u01dd",
    "\n\u001e\f\u001e\u000e\u001e\u01e0\u000b\u001e\u0003\u001e\u0003\u001e",
    "\u0006\u001e\u01e4\n\u001e\r\u001e\u000e\u001e\u01e5\u0005\u001e\u01e8",
    "\n\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e",
    "\u0003\u001e\u0003\u001e\u0005\u001e\u01f1\n\u001e\u0003\u001f\u0003",
    "\u001f\u0003\u001f\u0005\u001f\u01f6\n\u001f\u0003 \u0003 \u0003 \u0005",
    " \u01fb\n \u0003!\u0003!\u0003!\u0007!\u0200\n!\f!\u000e!\u0203\u000b",
    "!\u0003!\u0005!\u0206\n!\u0003\"\u0003\"\u0003\"\u0007\"\u020b\n\"\f",
    "\"\u000e\"\u020e\u000b\"\u0003#\u0003#\u0003#\u0007#\u0213\n#\f#\u000e",
    "#\u0216\u000b#\u0003$\u0003$\u0003$\u0003$\u0007$\u021c\n$\f$\u000e",
    "$\u021f\u000b$\u0003%\u0003%\u0003%\u0003%\u0007%\u0225\n%\f%\u000e",
    "%\u0228\u000b%\u0003&\u0003&\u0003&\u0003&\u0005&\u022e\n&\u0003\'\u0003",
    "\'\u0003\'\u0003\'\u0003\'\u0003\'\u0003\'\u0003\'\u0005\'\u0238\n\'",
    "\u0003(\u0003(\u0003(\u0003(\u0003(\u0003(\u0003(\u0003(\u0003(\u0007",
    "(\u0243\n(\f(\u000e(\u0246\u000b(\u0003(\u0003(\u0003(\u0005(\u024b",
    "\n(\u0003)\u0003)\u0003)\u0003)\u0003)\u0003)\u0003)\u0005)\u0254\n",
    ")\u0003*\u0003*\u0003*\u0003*\u0003*\u0003*\u0003*\u0003*\u0003*\u0005",
    "*\u025f\n*\u0003+\u0003+\u0003+\u0003+\u0003+\u0003+\u0003+\u0006+\u0268",
    "\n+\r+\u000e+\u0269\u0003+\u0003+\u0003+\u0005+\u026f\n+\u0003+\u0003",
    "+\u0003+\u0005+\u0274\n+\u0003+\u0003+\u0003+\u0005+\u0279\n+\u0003",
    ",\u0003,\u0003,\u0003,\u0007,\u027f\n,\f,\u000e,\u0282\u000b,\u0003",
    ",\u0003,\u0003,\u0003-\u0003-\u0003-\u0005-\u028a\n-\u0003.\u0003.\u0003",
    ".\u0003.\u0005.\u0290\n.\u0005.\u0292\n.\u0003/\u0003/\u0003/\u0003",
    "/\u0006/\u0298\n/\r/\u000e/\u0299\u0003/\u0003/\u0005/\u029e\n/\u0003",
    "0\u00030\u00030\u00030\u00030\u00030\u00050\u02a6\n0\u00030\u00050\u02a9",
    "\n0\u00031\u00031\u00051\u02ad\n1\u00032\u00032\u00052\u02b1\n2\u0003",
    "2\u00032\u00032\u00033\u00033\u00053\u02b8\n3\u00033\u00033\u00033\u0003",
    "4\u00034\u00034\u00074\u02c0\n4\f4\u000e4\u02c3\u000b4\u00035\u0003",
    "5\u00035\u00075\u02c8\n5\f5\u000e5\u02cb\u000b5\u00036\u00036\u0003",
    "6\u00056\u02d0\n6\u00037\u00037\u00037\u00037\u00077\u02d6\n7\f7\u000e",
    "7\u02d9\u000b7\u00038\u00038\u00038\u00038\u00038\u00038\u00038\u0003",
    "8\u00038\u00038\u00038\u00038\u00038\u00058\u02e8\n8\u00039\u00059\u02eb",
    "\n9\u00039\u00039\u0003:\u0003:\u0003:\u0007:\u02f2\n:\f:\u000e:\u02f5",
    "\u000b:\u0003;\u0003;\u0003;\u0007;\u02fa\n;\f;\u000e;\u02fd\u000b;",
    "\u0003<\u0003<\u0003<\u0007<\u0302\n<\f<\u000e<\u0305\u000b<\u0003=",
    "\u0003=\u0003=\u0003=\u0003=\u0007=\u030c\n=\f=\u000e=\u030f\u000b=",
    "\u0003>\u0003>\u0003>\u0003>\u0003>\u0007>\u0316\n>\f>\u000e>\u0319",
    "\u000b>\u0003?\u0003?\u0003?\u0003?\u0003?\u0003?\u0003?\u0003?\u0003",
    "?\u0003?\u0003?\u0007?\u0326\n?\f?\u000e?\u0329\u000b?\u0003@\u0003",
    "@\u0003@\u0003@\u0003@\u0003@\u0003@\u0005@\u0332\n@\u0003A\u0003A\u0007",
    "A\u0336\nA\fA\u000eA\u0339\u000bA\u0003A\u0003A\u0005A\u033d\nA\u0003",
    "B\u0003B\u0003B\u0005B\u0342\nB\u0003B\u0003B\u0003B\u0005B\u0347\n",
    "B\u0003B\u0003B\u0003B\u0005B\u034c\nB\u0003B\u0003B\u0003B\u0003B\u0006",
    "B\u0352\nB\rB\u000eB\u0353\u0003B\u0003B\u0003B\u0003B\u0005B\u035a",
    "\nB\u0003C\u0003C\u0003C\u0003C\u0007C\u0360\nC\fC\u000eC\u0363\u000b",
    "C\u0003C\u0005C\u0366\nC\u0005C\u0368\nC\u0003D\u0003D\u0005D\u036c",
    "\nD\u0003D\u0003D\u0003D\u0003D\u0003D\u0003D\u0003D\u0005D\u0375\n",
    "D\u0003E\u0003E\u0003E\u0007E\u037a\nE\fE\u000eE\u037d\u000bE\u0003",
    "E\u0005E\u0380\nE\u0003F\u0003F\u0005F\u0384\nF\u0003F\u0003F\u0005",
    "F\u0388\nF\u0003F\u0005F\u038b\nF\u0005F\u038d\nF\u0003G\u0003G\u0005",
    "G\u0391\nG\u0003H\u0003H\u0003H\u0007H\u0396\nH\fH\u000eH\u0399\u000b",
    "H\u0003H\u0005H\u039c\nH\u0003I\u0003I\u0003I\u0007I\u03a1\nI\fI\u000e",
    "I\u03a4\u000bI\u0003I\u0005I\u03a7\nI\u0003J\u0003J\u0003J\u0003J\u0003",
    "J\u0003J\u0003J\u0003J\u0003J\u0007J\u03b2\nJ\fJ\u000eJ\u03b5\u000b",
    "J\u0003J\u0005J\u03b8\nJ\u0005J\u03ba\nJ\u0003J\u0003J\u0003J\u0003",
    "J\u0007J\u03c0\nJ\fJ\u000eJ\u03c3\u000bJ\u0003J\u0005J\u03c6\nJ\u0005",
    "J\u03c8\nJ\u0005J\u03ca\nJ\u0003K\u0003K\u0003K\u0003K\u0005K\u03d0",
    "\nK\u0003K\u0005K\u03d3\nK\u0003K\u0003K\u0003K\u0003L\u0003L\u0003",
    "L\u0007L\u03db\nL\fL\u000eL\u03de\u000bL\u0003L\u0003L\u0005L\u03e2",
    "\nL\u0003L\u0003L\u0003L\u0003L\u0007L\u03e8\nL\fL\u000eL\u03eb\u000b",
    "L\u0003L\u0003L\u0003L\u0005L\u03f0\nL\u0003L\u0003L\u0005L\u03f4\n",
    "L\u0003M\u0003M\u0005M\u03f8\nM\u0003M\u0003M\u0003M\u0003M\u0005M\u03fe",
    "\nM\u0003N\u0003N\u0005N\u0402\nN\u0003O\u0003O\u0003O\u0003O\u0003",
    "O\u0005O\u0409\nO\u0003P\u0003P\u0003P\u0005P\u040e\nP\u0003Q\u0003",
    "Q\u0005Q\u0412\nQ\u0003R\u0003R\u0003R\u0005R\u0417\nR\u0003S\u0003",
    "S\u0003T\u0003T\u0003T\u0005T\u041e\nT\u0003U\u0003U\u0003U\u0002\u0002",
    "V\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012\u0014\u0016\u0018\u001a",
    "\u001c\u001e \"$&(*,.02468:<>@BDFHJLNPRTVXZ\\^`bdfhjlnprtvxz|~\u0080",
    "\u0082\u0084\u0086\u0088\u008a\u008c\u008e\u0090\u0092\u0094\u0096\u0098",
    "\u009a\u009c\u009e\u00a0\u00a2\u00a4\u00a6\u00a8\u0002\u0006\u0003\u0002",
    "P\\\u0003\u0002./\u0003\u0002&\'\u0003\u0002(+\u0002\u0492\u0002\u00af",
    "\u0003\u0002\u0002\u0002\u0004\u00b5\u0003\u0002\u0002\u0002\u0006\u00ba",
    "\u0003\u0002\u0002\u0002\b\u00c3\u0003\u0002\u0002\u0002\n\u00cf\u0003",
    "\u0002\u0002\u0002\f\u00d3\u0003\u0002\u0002\u0002\u000e\u00d8\u0003",
    "\u0002\u0002\u0002\u0010\u00e2\u0003\u0002\u0002\u0002\u0012\u0129\u0003",
    "\u0002\u0002\u0002\u0014\u012b\u0003\u0002\u0002\u0002\u0016\u0171\u0003",
    "\u0002\u0002\u0002\u0018\u0173\u0003\u0002\u0002\u0002\u001a\u0177\u0003",
    "\u0002\u0002\u0002\u001c\u0179\u0003\u0002\u0002\u0002\u001e\u018e\u0003",
    "\u0002\u0002\u0002 \u0190\u0003\u0002\u0002\u0002\"\u01a4\u0003\u0002",
    "\u0002\u0002$\u01b3\u0003\u0002\u0002\u0002&\u01b5\u0003\u0002\u0002",
    "\u0002(\u01b8\u0003\u0002\u0002\u0002*\u01bf\u0003\u0002\u0002\u0002",
    ",\u01c1\u0003\u0002\u0002\u0002.\u01c3\u0003\u0002\u0002\u00020\u01c5",
    "\u0003\u0002\u0002\u00022\u01c9\u0003\u0002\u0002\u00024\u01cb\u0003",
    "\u0002\u0002\u00026\u01d5\u0003\u0002\u0002\u00028\u01d7\u0003\u0002",
    "\u0002\u0002:\u01da\u0003\u0002\u0002\u0002<\u01f2\u0003\u0002\u0002",
    "\u0002>\u01f7\u0003\u0002\u0002\u0002@\u01fc\u0003\u0002\u0002\u0002",
    "B\u0207\u0003\u0002\u0002\u0002D\u020f\u0003\u0002\u0002\u0002F\u0217",
    "\u0003\u0002\u0002\u0002H\u0220\u0003\u0002\u0002\u0002J\u0229\u0003",
    "\u0002\u0002\u0002L\u0237\u0003\u0002\u0002\u0002N\u0239\u0003\u0002",
    "\u0002\u0002P\u024c\u0003\u0002\u0002\u0002R\u0255\u0003\u0002\u0002",
    "\u0002T\u0260\u0003\u0002\u0002\u0002V\u027a\u0003\u0002\u0002\u0002",
    "X\u0286\u0003\u0002\u0002\u0002Z\u028b\u0003\u0002\u0002\u0002\\\u029d",
    "\u0003\u0002\u0002\u0002^\u02a8\u0003\u0002\u0002\u0002`\u02ac\u0003",
    "\u0002\u0002\u0002b\u02ae\u0003\u0002\u0002\u0002d\u02b5\u0003\u0002",
    "\u0002\u0002f\u02bc\u0003\u0002\u0002\u0002h\u02c4\u0003\u0002\u0002",
    "\u0002j\u02cf\u0003\u0002\u0002\u0002l\u02d1\u0003\u0002\u0002\u0002",
    "n\u02e7\u0003\u0002\u0002\u0002p\u02ea\u0003\u0002\u0002\u0002r\u02ee",
    "\u0003\u0002\u0002\u0002t\u02f6\u0003\u0002\u0002\u0002v\u02fe\u0003",
    "\u0002\u0002\u0002x\u0306\u0003\u0002\u0002\u0002z\u0310\u0003\u0002",
    "\u0002\u0002|\u031a\u0003\u0002\u0002\u0002~\u0331\u0003\u0002\u0002",
    "\u0002\u0080\u0333\u0003\u0002\u0002\u0002\u0082\u0359\u0003\u0002\u0002",
    "\u0002\u0084\u035b\u0003\u0002\u0002\u0002\u0086\u0374\u0003\u0002\u0002",
    "\u0002\u0088\u0376\u0003\u0002\u0002\u0002\u008a\u038c\u0003\u0002\u0002",
    "\u0002\u008c\u038e\u0003\u0002\u0002\u0002\u008e\u0392\u0003\u0002\u0002",
    "\u0002\u0090\u039d\u0003\u0002\u0002\u0002\u0092\u03c9\u0003\u0002\u0002",
    "\u0002\u0094\u03cb\u0003\u0002\u0002\u0002\u0096\u03dc\u0003\u0002\u0002",
    "\u0002\u0098\u03fd\u0003\u0002\u0002\u0002\u009a\u0401\u0003\u0002\u0002",
    "\u0002\u009c\u0403\u0003\u0002\u0002\u0002\u009e\u040a\u0003\u0002\u0002",
    "\u0002\u00a0\u040f\u0003\u0002\u0002\u0002\u00a2\u0416\u0003\u0002\u0002",
    "\u0002\u00a4\u0418\u0003\u0002\u0002\u0002\u00a6\u041d\u0003\u0002\u0002",
    "\u0002\u00a8\u041f\u0003\u0002\u0002\u0002\u00aa\u00b0\u0007$\u0002",
    "\u0002\u00ab\u00b0\u0005\u001c\u000f\u0002\u00ac\u00ad\u0005L\'\u0002",
    "\u00ad\u00ae\u0007$\u0002\u0002\u00ae\u00b0\u0003\u0002\u0002\u0002",
    "\u00af\u00aa\u0003\u0002\u0002\u0002\u00af\u00ab\u0003\u0002\u0002\u0002",
    "\u00af\u00ac\u0003\u0002\u0002\u0002\u00b0\u0003\u0003\u0002\u0002\u0002",
    "\u00b1\u00b4\u0007$\u0002\u0002\u00b2\u00b4\u0005\u001a\u000e\u0002",
    "\u00b3\u00b1\u0003\u0002\u0002\u0002\u00b3\u00b2\u0003\u0002\u0002\u0002",
    "\u00b4\u00b7\u0003\u0002\u0002\u0002\u00b5\u00b3\u0003\u0002\u0002\u0002",
    "\u00b5\u00b6\u0003\u0002\u0002\u0002\u00b6\u00b8\u0003\u0002\u0002\u0002",
    "\u00b7\u00b5\u0003\u0002\u0002\u0002\u00b8\u00b9\u0007\u0002\u0002\u0003",
    "\u00b9\u0005\u0003\u0002\u0002\u0002\u00ba\u00be\u0005\u0090I\u0002",
    "\u00bb\u00bd\u0007$\u0002\u0002\u00bc\u00bb\u0003\u0002\u0002\u0002",
    "\u00bd\u00c0\u0003\u0002\u0002\u0002\u00be\u00bc\u0003\u0002\u0002\u0002",
    "\u00be\u00bf\u0003\u0002\u0002\u0002\u00bf\u00c1\u0003\u0002\u0002\u0002",
    "\u00c0\u00be\u0003\u0002\u0002\u0002\u00c1\u00c2\u0007\u0002\u0002\u0003",
    "\u00c2\u0007\u0003\u0002\u0002\u0002\u00c3\u00c4\u0007N\u0002\u0002",
    "\u00c4\u00ca\u0005D#\u0002\u00c5\u00c7\u00071\u0002\u0002\u00c6\u00c8",
    "\u0005\u0096L\u0002\u00c7\u00c6\u0003\u0002\u0002\u0002\u00c7\u00c8",
    "\u0003\u0002\u0002\u0002\u00c8\u00c9\u0003\u0002\u0002\u0002\u00c9\u00cb",
    "\u00072\u0002\u0002\u00ca\u00c5\u0003\u0002\u0002\u0002\u00ca\u00cb",
    "\u0003\u0002\u0002\u0002\u00cb\u00cc\u0003\u0002\u0002\u0002\u00cc\u00cd",
    "\u0007$\u0002\u0002\u00cd\t\u0003\u0002\u0002\u0002\u00ce\u00d0\u0005",
    "\b\u0005\u0002\u00cf\u00ce\u0003\u0002\u0002\u0002\u00d0\u00d1\u0003",
    "\u0002\u0002\u0002\u00d1\u00cf\u0003\u0002\u0002\u0002\u00d1\u00d2\u0003",
    "\u0002\u0002\u0002\u00d2\u000b\u0003\u0002\u0002\u0002\u00d3\u00d6\u0005",
    "\n\u0006\u0002\u00d4\u00d7\u0005\u0094K\u0002\u00d5\u00d7\u0005\u000e",
    "\b\u0002\u00d6\u00d4\u0003\u0002\u0002\u0002\u00d6\u00d5\u0003\u0002",
    "\u0002\u0002\u00d7\r\u0003\u0002\u0002\u0002\u00d8\u00d9\u0007\u0003",
    "\u0002\u0002\u00d9\u00da\u0007%\u0002\u0002\u00da\u00dd\u0005\u0010",
    "\t\u0002\u00db\u00dc\u0007O\u0002\u0002\u00dc\u00de\u0005^0\u0002\u00dd",
    "\u00db\u0003\u0002\u0002\u0002\u00dd\u00de\u0003\u0002\u0002\u0002\u00de",
    "\u00df\u0003\u0002\u0002\u0002\u00df\u00e0\u00074\u0002\u0002\u00e0",
    "\u00e1\u0005\\/\u0002\u00e1\u000f\u0003\u0002\u0002\u0002\u00e2\u00e4",
    "\u00071\u0002\u0002\u00e3\u00e5\u0005\u0012\n\u0002\u00e4\u00e3\u0003",
    "\u0002\u0002\u0002\u00e4\u00e5\u0003\u0002\u0002\u0002\u00e5\u00e6\u0003",
    "\u0002\u0002\u0002\u00e6\u00e7\u00072\u0002\u0002\u00e7\u0011\u0003",
    "\u0002\u0002\u0002\u00e8\u00eb\u0005\u0014\u000b\u0002\u00e9\u00ea\u0007",
    "7\u0002\u0002\u00ea\u00ec\u0005^0\u0002\u00eb\u00e9\u0003\u0002\u0002",
    "\u0002\u00eb\u00ec\u0003\u0002\u0002\u0002\u00ec\u00f5\u0003\u0002\u0002",
    "\u0002\u00ed\u00ee\u00073\u0002\u0002\u00ee\u00f1\u0005\u0014\u000b",
    "\u0002\u00ef\u00f0\u00077\u0002\u0002\u00f0\u00f2\u0005^0\u0002\u00f1",
    "\u00ef\u0003\u0002\u0002\u0002\u00f1\u00f2\u0003\u0002\u0002\u0002\u00f2",
    "\u00f4\u0003\u0002\u0002\u0002\u00f3\u00ed\u0003\u0002\u0002\u0002\u00f4",
    "\u00f7\u0003\u0002\u0002\u0002\u00f5\u00f3\u0003\u0002\u0002\u0002\u00f5",
    "\u00f6\u0003\u0002\u0002\u0002\u00f6\u0111\u0003\u0002\u0002\u0002\u00f7",
    "\u00f5\u0003\u0002\u0002\u0002\u00f8\u010f\u00073\u0002\u0002\u00f9",
    "\u00fb\u00070\u0002\u0002\u00fa\u00fc\u0005\u0014\u000b\u0002\u00fb",
    "\u00fa\u0003\u0002\u0002\u0002\u00fb\u00fc\u0003\u0002\u0002\u0002\u00fc",
    "\u0105\u0003\u0002\u0002\u0002\u00fd\u00fe\u00073\u0002\u0002\u00fe",
    "\u0101\u0005\u0014\u000b\u0002\u00ff\u0100\u00077\u0002\u0002\u0100",
    "\u0102\u0005^0\u0002\u0101\u00ff\u0003\u0002\u0002\u0002\u0101\u0102",
    "\u0003\u0002\u0002\u0002\u0102\u0104\u0003\u0002\u0002\u0002\u0103\u00fd",
    "\u0003\u0002\u0002\u0002\u0104\u0107\u0003\u0002\u0002\u0002\u0105\u0103",
    "\u0003\u0002\u0002\u0002\u0105\u0106\u0003\u0002\u0002\u0002\u0106\u010b",
    "\u0003\u0002\u0002\u0002\u0107\u0105\u0003\u0002\u0002\u0002\u0108\u0109",
    "\u00073\u0002\u0002\u0109\u010a\u00076\u0002\u0002\u010a\u010c\u0005",
    "\u0014\u000b\u0002\u010b\u0108\u0003\u0002\u0002\u0002\u010b\u010c\u0003",
    "\u0002\u0002\u0002\u010c\u0110\u0003\u0002\u0002\u0002\u010d\u010e\u0007",
    "6\u0002\u0002\u010e\u0110\u0005\u0014\u000b\u0002\u010f\u00f9\u0003",
    "\u0002\u0002\u0002\u010f\u010d\u0003\u0002\u0002\u0002\u010f\u0110\u0003",
    "\u0002\u0002\u0002\u0110\u0112\u0003\u0002\u0002\u0002\u0111\u00f8\u0003",
    "\u0002\u0002\u0002\u0111\u0112\u0003\u0002\u0002\u0002\u0112\u012a\u0003",
    "\u0002\u0002\u0002\u0113\u0115\u00070\u0002\u0002\u0114\u0116\u0005",
    "\u0014\u000b\u0002\u0115\u0114\u0003\u0002\u0002\u0002\u0115\u0116\u0003",
    "\u0002\u0002\u0002\u0116\u011f\u0003\u0002\u0002\u0002\u0117\u0118\u0007",
    "3\u0002\u0002\u0118\u011b\u0005\u0014\u000b\u0002\u0119\u011a\u0007",
    "7\u0002\u0002\u011a\u011c\u0005^0\u0002\u011b\u0119\u0003\u0002\u0002",
    "\u0002\u011b\u011c\u0003\u0002\u0002\u0002\u011c\u011e\u0003\u0002\u0002",
    "\u0002\u011d\u0117\u0003\u0002\u0002\u0002\u011e\u0121\u0003\u0002\u0002",
    "\u0002\u011f\u011d\u0003\u0002\u0002\u0002\u011f\u0120\u0003\u0002\u0002",
    "\u0002\u0120\u0125\u0003\u0002\u0002\u0002\u0121\u011f\u0003\u0002\u0002",
    "\u0002\u0122\u0123\u00073\u0002\u0002\u0123\u0124\u00076\u0002\u0002",
    "\u0124\u0126\u0005\u0014\u000b\u0002\u0125\u0122\u0003\u0002\u0002\u0002",
    "\u0125\u0126\u0003\u0002\u0002\u0002\u0126\u012a\u0003\u0002\u0002\u0002",
    "\u0127\u0128\u00076\u0002\u0002\u0128\u012a\u0005\u0014\u000b\u0002",
    "\u0129\u00e8\u0003\u0002\u0002\u0002\u0129\u0113\u0003\u0002\u0002\u0002",
    "\u0129\u0127\u0003\u0002\u0002\u0002\u012a\u0013\u0003\u0002\u0002\u0002",
    "\u012b\u012e\u0007%\u0002\u0002\u012c\u012d\u00074\u0002\u0002\u012d",
    "\u012f\u0005^0\u0002\u012e\u012c\u0003\u0002\u0002\u0002\u012e\u012f",
    "\u0003\u0002\u0002\u0002\u012f\u0015\u0003\u0002\u0002\u0002\u0130\u0133",
    "\u0005\u0018\r\u0002\u0131\u0132\u00077\u0002\u0002\u0132\u0134\u0005",
    "^0\u0002\u0133\u0131\u0003\u0002\u0002\u0002\u0133\u0134\u0003\u0002",
    "\u0002\u0002\u0134\u013d\u0003\u0002\u0002\u0002\u0135\u0136\u00073",
    "\u0002\u0002\u0136\u0139\u0005\u0018\r\u0002\u0137\u0138\u00077\u0002",
    "\u0002\u0138\u013a\u0005^0\u0002\u0139\u0137\u0003\u0002\u0002\u0002",
    "\u0139\u013a\u0003\u0002\u0002\u0002\u013a\u013c\u0003\u0002\u0002\u0002",
    "\u013b\u0135\u0003\u0002\u0002\u0002\u013c\u013f\u0003\u0002\u0002\u0002",
    "\u013d\u013b\u0003\u0002\u0002\u0002\u013d\u013e\u0003\u0002\u0002\u0002",
    "\u013e\u0159\u0003\u0002\u0002\u0002\u013f\u013d\u0003\u0002\u0002\u0002",
    "\u0140\u0157\u00073\u0002\u0002\u0141\u0143\u00070\u0002\u0002\u0142",
    "\u0144\u0005\u0018\r\u0002\u0143\u0142\u0003\u0002\u0002\u0002\u0143",
    "\u0144\u0003\u0002\u0002\u0002\u0144\u014d\u0003\u0002\u0002\u0002\u0145",
    "\u0146\u00073\u0002\u0002\u0146\u0149\u0005\u0018\r\u0002\u0147\u0148",
    "\u00077\u0002\u0002\u0148\u014a\u0005^0\u0002\u0149\u0147\u0003\u0002",
    "\u0002\u0002\u0149\u014a\u0003\u0002\u0002\u0002\u014a\u014c\u0003\u0002",
    "\u0002\u0002\u014b\u0145\u0003\u0002\u0002\u0002\u014c\u014f\u0003\u0002",
    "\u0002\u0002\u014d\u014b\u0003\u0002\u0002\u0002\u014d\u014e\u0003\u0002",
    "\u0002\u0002\u014e\u0153\u0003\u0002\u0002\u0002\u014f\u014d\u0003\u0002",
    "\u0002\u0002\u0150\u0151\u00073\u0002\u0002\u0151\u0152\u00076\u0002",
    "\u0002\u0152\u0154\u0005\u0018\r\u0002\u0153\u0150\u0003\u0002\u0002",
    "\u0002\u0153\u0154\u0003\u0002\u0002\u0002\u0154\u0158\u0003\u0002\u0002",
    "\u0002\u0155\u0156\u00076\u0002\u0002\u0156\u0158\u0005\u0018\r\u0002",
    "\u0157\u0141\u0003\u0002\u0002\u0002\u0157\u0155\u0003\u0002\u0002\u0002",
    "\u0157\u0158\u0003\u0002\u0002\u0002\u0158\u015a\u0003\u0002\u0002\u0002",
    "\u0159\u0140\u0003\u0002\u0002\u0002\u0159\u015a\u0003\u0002\u0002\u0002",
    "\u015a\u0172\u0003\u0002\u0002\u0002\u015b\u015d\u00070\u0002\u0002",
    "\u015c\u015e\u0005\u0018\r\u0002\u015d\u015c\u0003\u0002\u0002\u0002",
    "\u015d\u015e\u0003\u0002\u0002\u0002\u015e\u0167\u0003\u0002\u0002\u0002",
    "\u015f\u0160\u00073\u0002\u0002\u0160\u0163\u0005\u0018\r\u0002\u0161",
    "\u0162\u00077\u0002\u0002\u0162\u0164\u0005^0\u0002\u0163\u0161\u0003",
    "\u0002\u0002\u0002\u0163\u0164\u0003\u0002\u0002\u0002\u0164\u0166\u0003",
    "\u0002\u0002\u0002\u0165\u015f\u0003\u0002\u0002\u0002\u0166\u0169\u0003",
    "\u0002\u0002\u0002\u0167\u0165\u0003\u0002\u0002\u0002\u0167\u0168\u0003",
    "\u0002\u0002\u0002\u0168\u016d\u0003\u0002\u0002\u0002\u0169\u0167\u0003",
    "\u0002\u0002\u0002\u016a\u016b\u00073\u0002\u0002\u016b\u016c\u0007",
    "6\u0002\u0002\u016c\u016e\u0005\u0018\r\u0002\u016d\u016a\u0003\u0002",
    "\u0002\u0002\u016d\u016e\u0003\u0002\u0002\u0002\u016e\u0172\u0003\u0002",
    "\u0002\u0002\u016f\u0170\u00076\u0002\u0002\u0170\u0172\u0005\u0018",
    "\r\u0002\u0171\u0130\u0003\u0002\u0002\u0002\u0171\u015b\u0003\u0002",
    "\u0002\u0002\u0171\u016f\u0003\u0002\u0002\u0002\u0172\u0017\u0003\u0002",
    "\u0002\u0002\u0173\u0174\u0007%\u0002\u0002\u0174\u0019\u0003\u0002",
    "\u0002\u0002\u0175\u0178\u0005\u001c\u000f\u0002\u0176\u0178\u0005L",
    "\'\u0002\u0177\u0175\u0003\u0002\u0002\u0002\u0177\u0176\u0003\u0002",
    "\u0002\u0002\u0178\u001b\u0003\u0002\u0002\u0002\u0179\u017e\u0005\u001e",
    "\u0010\u0002\u017a\u017b\u00075\u0002\u0002\u017b\u017d\u0005\u001e",
    "\u0010\u0002\u017c\u017a\u0003\u0002\u0002\u0002\u017d\u0180\u0003\u0002",
    "\u0002\u0002\u017e\u017c\u0003\u0002\u0002\u0002\u017e\u017f\u0003\u0002",
    "\u0002\u0002\u017f\u0182\u0003\u0002\u0002\u0002\u0180\u017e\u0003\u0002",
    "\u0002\u0002\u0181\u0183\u00075\u0002\u0002\u0182\u0181\u0003\u0002",
    "\u0002\u0002\u0182\u0183\u0003\u0002\u0002\u0002\u0183\u0184\u0003\u0002",
    "\u0002\u0002\u0184\u0185\u0007$\u0002\u0002\u0185\u001d\u0003\u0002",
    "\u0002\u0002\u0186\u018f\u0005 \u0011\u0002\u0187\u018f\u0005&\u0014",
    "\u0002\u0188\u018f\u0005(\u0015\u0002\u0189\u018f\u0005*\u0016\u0002",
    "\u018a\u018f\u00056\u001c\u0002\u018b\u018f\u0005F$\u0002\u018c\u018f",
    "\u0005H%\u0002\u018d\u018f\u0005J&\u0002\u018e\u0186\u0003\u0002\u0002",
    "\u0002\u018e\u0187\u0003\u0002\u0002\u0002\u018e\u0188\u0003\u0002\u0002",
    "\u0002\u018e\u0189\u0003\u0002\u0002\u0002\u018e\u018a\u0003\u0002\u0002",
    "\u0002\u018e\u018b\u0003\u0002\u0002\u0002\u018e\u018c\u0003\u0002\u0002",
    "\u0002\u018e\u018d\u0003\u0002\u0002\u0002\u018f\u001f\u0003\u0002\u0002",
    "\u0002\u0190\u01a0\u0005\"\u0012\u0002\u0191\u0194\u0005$\u0013\u0002",
    "\u0192\u0195\u0005\u00a0Q\u0002\u0193\u0195\u0005\u0090I\u0002\u0194",
    "\u0192\u0003\u0002\u0002\u0002\u0194\u0193\u0003\u0002\u0002\u0002\u0195",
    "\u01a1\u0003\u0002\u0002\u0002\u0196\u0199\u00077\u0002\u0002\u0197",
    "\u019a\u0005\u00a0Q\u0002\u0198\u019a\u0005\"\u0012\u0002\u0199\u0197",
    "\u0003\u0002\u0002\u0002\u0199\u0198\u0003\u0002\u0002\u0002\u019a\u019c",
    "\u0003\u0002\u0002\u0002\u019b\u0196\u0003\u0002\u0002\u0002\u019c\u019f",
    "\u0003\u0002\u0002\u0002\u019d\u019b\u0003\u0002\u0002\u0002\u019d\u019e",
    "\u0003\u0002\u0002\u0002\u019e\u01a1\u0003\u0002\u0002\u0002\u019f\u019d",
    "\u0003\u0002\u0002\u0002\u01a0\u0191\u0003\u0002\u0002\u0002\u01a0\u019d",
    "\u0003\u0002\u0002\u0002\u01a1!\u0003\u0002\u0002\u0002\u01a2\u01a5",
    "\u0005^0\u0002\u01a3\u01a5\u0005p9\u0002\u01a4\u01a2\u0003\u0002\u0002",
    "\u0002\u01a4\u01a3\u0003\u0002\u0002\u0002\u01a5\u01ad\u0003\u0002\u0002",
    "\u0002\u01a6\u01a9\u00073\u0002\u0002\u01a7\u01aa\u0005^0\u0002\u01a8",
    "\u01aa\u0005p9\u0002\u01a9\u01a7\u0003\u0002\u0002\u0002\u01a9\u01a8",
    "\u0003\u0002\u0002\u0002\u01aa\u01ac\u0003\u0002\u0002\u0002\u01ab\u01a6",
    "\u0003\u0002\u0002\u0002\u01ac\u01af\u0003\u0002\u0002\u0002\u01ad\u01ab",
    "\u0003\u0002\u0002\u0002\u01ad\u01ae\u0003\u0002\u0002\u0002\u01ae\u01b1",
    "\u0003\u0002\u0002\u0002\u01af\u01ad\u0003\u0002\u0002\u0002\u01b0\u01b2",
    "\u00073\u0002\u0002\u01b1\u01b0\u0003\u0002\u0002\u0002\u01b1\u01b2",
    "\u0003\u0002\u0002\u0002\u01b2#\u0003\u0002\u0002\u0002\u01b3\u01b4",
    "\t\u0002\u0002\u0002\u01b4%\u0003\u0002\u0002\u0002\u01b5\u01b6\u0007",
    " \u0002\u0002\u01b6\u01b7\u0005\u008eH\u0002\u01b7\'\u0003\u0002\u0002",
    "\u0002\u01b8\u01b9\u0007!\u0002\u0002\u01b9)\u0003\u0002\u0002\u0002",
    "\u01ba\u01c0\u0005,\u0017\u0002\u01bb\u01c0\u0005.\u0018\u0002\u01bc",
    "\u01c0\u00050\u0019\u0002\u01bd\u01c0\u00054\u001b\u0002\u01be\u01c0",
    "\u00052\u001a\u0002\u01bf\u01ba\u0003\u0002\u0002\u0002\u01bf\u01bb",
    "\u0003\u0002\u0002\u0002\u01bf\u01bc\u0003\u0002\u0002\u0002\u01bf\u01bd",
    "\u0003\u0002\u0002\u0002\u01bf\u01be\u0003\u0002\u0002\u0002\u01c0+",
    "\u0003\u0002\u0002\u0002\u01c1\u01c2\u0007#\u0002\u0002\u01c2-\u0003",
    "\u0002\u0002\u0002\u01c3\u01c4\u0007\"\u0002\u0002\u01c4/\u0003\u0002",
    "\u0002\u0002\u01c5\u01c7\u0007\u0004\u0002\u0002\u01c6\u01c8\u0005\u0090",
    "I\u0002\u01c7\u01c6\u0003\u0002\u0002\u0002\u01c7\u01c8\u0003\u0002",
    "\u0002\u0002\u01c81\u0003\u0002\u0002\u0002\u01c9\u01ca\u0005\u00a0",
    "Q\u0002\u01ca3\u0003\u0002\u0002\u0002\u01cb\u01d1\u0007\u0005\u0002",
    "\u0002\u01cc\u01cf\u0005^0\u0002\u01cd\u01ce\u0007\u0006\u0002\u0002",
    "\u01ce\u01d0\u0005^0\u0002\u01cf\u01cd\u0003\u0002\u0002\u0002\u01cf",
    "\u01d0\u0003\u0002\u0002\u0002\u01d0\u01d2\u0003\u0002\u0002\u0002\u01d1",
    "\u01cc\u0003\u0002\u0002\u0002\u01d1\u01d2\u0003\u0002\u0002\u0002\u01d2",
    "5\u0003\u0002\u0002\u0002\u01d3\u01d6\u00058\u001d\u0002\u01d4\u01d6",
    "\u0005:\u001e\u0002\u01d5\u01d3\u0003\u0002\u0002\u0002\u01d5\u01d4",
    "\u0003\u0002\u0002\u0002\u01d67\u0003\u0002\u0002\u0002\u01d7\u01d8",
    "\u0007\u0007\u0002\u0002\u01d8\u01d9\u0005B\"\u0002\u01d99\u0003\u0002",
    "\u0002\u0002\u01da\u01e7\u0007\u0006\u0002\u0002\u01db\u01dd\t\u0003",
    "\u0002\u0002\u01dc\u01db\u0003\u0002\u0002\u0002\u01dd\u01e0\u0003\u0002",
    "\u0002\u0002\u01de\u01dc\u0003\u0002\u0002\u0002\u01de\u01df\u0003\u0002",
    "\u0002\u0002\u01df\u01e1\u0003\u0002\u0002\u0002\u01e0\u01de\u0003\u0002",
    "\u0002\u0002\u01e1\u01e8\u0005D#\u0002\u01e2\u01e4\t\u0003\u0002\u0002",
    "\u01e3\u01e2\u0003\u0002\u0002\u0002\u01e4\u01e5\u0003\u0002\u0002\u0002",
    "\u01e5\u01e3\u0003\u0002\u0002\u0002\u01e5\u01e6\u0003\u0002\u0002\u0002",
    "\u01e6\u01e8\u0003\u0002\u0002\u0002\u01e7\u01de\u0003\u0002\u0002\u0002",
    "\u01e7\u01e3\u0003\u0002\u0002\u0002\u01e8\u01e9\u0003\u0002\u0002\u0002",
    "\u01e9\u01f0\u0007\u0007\u0002\u0002\u01ea\u01f1\u00070\u0002\u0002",
    "\u01eb\u01ec\u00071\u0002\u0002\u01ec\u01ed\u0005@!\u0002\u01ed\u01ee",
    "\u00072\u0002\u0002\u01ee\u01f1\u0003\u0002\u0002\u0002\u01ef\u01f1",
    "\u0005@!\u0002\u01f0\u01ea\u0003\u0002\u0002\u0002\u01f0\u01eb\u0003",
    "\u0002\u0002\u0002\u01f0\u01ef\u0003\u0002\u0002\u0002\u01f1;\u0003",
    "\u0002\u0002\u0002\u01f2\u01f5\u0007%\u0002\u0002\u01f3\u01f4\u0007",
    "\b\u0002\u0002\u01f4\u01f6\u0007%\u0002\u0002\u01f5\u01f3\u0003\u0002",
    "\u0002\u0002\u01f5\u01f6\u0003\u0002\u0002\u0002\u01f6=\u0003\u0002",
    "\u0002\u0002\u01f7\u01fa\u0005D#\u0002\u01f8\u01f9\u0007\b\u0002\u0002",
    "\u01f9\u01fb\u0007%\u0002\u0002\u01fa\u01f8\u0003\u0002\u0002\u0002",
    "\u01fa\u01fb\u0003\u0002\u0002\u0002\u01fb?\u0003\u0002\u0002\u0002",
    "\u01fc\u0201\u0005<\u001f\u0002\u01fd\u01fe\u00073\u0002\u0002\u01fe",
    "\u0200\u0005<\u001f\u0002\u01ff\u01fd\u0003\u0002\u0002\u0002\u0200",
    "\u0203\u0003\u0002\u0002\u0002\u0201\u01ff\u0003\u0002\u0002\u0002\u0201",
    "\u0202\u0003\u0002\u0002\u0002\u0202\u0205\u0003\u0002\u0002\u0002\u0203",
    "\u0201\u0003\u0002\u0002\u0002\u0204\u0206\u00073\u0002\u0002\u0205",
    "\u0204\u0003\u0002\u0002\u0002\u0205\u0206\u0003\u0002\u0002\u0002\u0206",
    "A\u0003\u0002\u0002\u0002\u0207\u020c\u0005> \u0002\u0208\u0209\u0007",
    "3\u0002\u0002\u0209\u020b\u0005> \u0002\u020a\u0208\u0003\u0002\u0002",
    "\u0002\u020b\u020e\u0003\u0002\u0002\u0002\u020c\u020a\u0003\u0002\u0002",
    "\u0002\u020c\u020d\u0003\u0002\u0002\u0002\u020dC\u0003\u0002\u0002",
    "\u0002\u020e\u020c\u0003\u0002\u0002\u0002\u020f\u0214\u0007%\u0002",
    "\u0002\u0210\u0211\u0007.\u0002\u0002\u0211\u0213\u0007%\u0002\u0002",
    "\u0212\u0210\u0003\u0002\u0002\u0002\u0213\u0216\u0003\u0002\u0002\u0002",
    "\u0214\u0212\u0003\u0002\u0002\u0002\u0214\u0215\u0003\u0002\u0002\u0002",
    "\u0215E\u0003\u0002\u0002\u0002\u0216\u0214\u0003\u0002\u0002\u0002",
    "\u0217\u0218\u0007\t\u0002\u0002\u0218\u021d\u0007%\u0002\u0002\u0219",
    "\u021a\u00073\u0002\u0002\u021a\u021c\u0007%\u0002\u0002\u021b\u0219",
    "\u0003\u0002\u0002\u0002\u021c\u021f\u0003\u0002\u0002\u0002\u021d\u021b",
    "\u0003\u0002\u0002\u0002\u021d\u021e\u0003\u0002\u0002\u0002\u021eG",
    "\u0003\u0002\u0002\u0002\u021f\u021d\u0003\u0002\u0002\u0002\u0220\u0221",
    "\u0007\n\u0002\u0002\u0221\u0226\u0007%\u0002\u0002\u0222\u0223\u0007",
    "3\u0002\u0002\u0223\u0225\u0007%\u0002\u0002\u0224\u0222\u0003\u0002",
    "\u0002\u0002\u0225\u0228\u0003\u0002\u0002\u0002\u0226\u0224\u0003\u0002",
    "\u0002\u0002\u0226\u0227\u0003\u0002\u0002\u0002\u0227I\u0003\u0002",
    "\u0002\u0002\u0228\u0226\u0003\u0002\u0002\u0002\u0229\u022a\u0007\u000b",
    "\u0002\u0002\u022a\u022d\u0005^0\u0002\u022b\u022c\u00073\u0002\u0002",
    "\u022c\u022e\u0005^0\u0002\u022d\u022b\u0003\u0002\u0002\u0002\u022d",
    "\u022e\u0003\u0002\u0002\u0002\u022eK\u0003\u0002\u0002\u0002\u022f",
    "\u0238\u0005N(\u0002\u0230\u0238\u0005P)\u0002\u0231\u0238\u0005R*\u0002",
    "\u0232\u0238\u0005T+\u0002\u0233\u0238\u0005V,\u0002\u0234\u0238\u0005",
    "\u000e\b\u0002\u0235\u0238\u0005\u0094K\u0002\u0236\u0238\u0005\f\u0007",
    "\u0002\u0237\u022f\u0003\u0002\u0002\u0002\u0237\u0230\u0003\u0002\u0002",
    "\u0002\u0237\u0231\u0003\u0002\u0002\u0002\u0237\u0232\u0003\u0002\u0002",
    "\u0002\u0237\u0233\u0003\u0002\u0002\u0002\u0237\u0234\u0003\u0002\u0002",
    "\u0002\u0237\u0235\u0003\u0002\u0002\u0002\u0237\u0236\u0003\u0002\u0002",
    "\u0002\u0238M\u0003\u0002\u0002\u0002\u0239\u023a\u0007\f\u0002\u0002",
    "\u023a\u023b\u0005^0\u0002\u023b\u023c\u00074\u0002\u0002\u023c\u0244",
    "\u0005\\/\u0002\u023d\u023e\u0007\r\u0002\u0002\u023e\u023f\u0005^0",
    "\u0002\u023f\u0240\u00074\u0002\u0002\u0240\u0241\u0005\\/\u0002\u0241",
    "\u0243\u0003\u0002\u0002\u0002\u0242\u023d\u0003\u0002\u0002\u0002\u0243",
    "\u0246\u0003\u0002\u0002\u0002\u0244\u0242\u0003\u0002\u0002\u0002\u0244",
    "\u0245\u0003\u0002\u0002\u0002\u0245\u024a\u0003\u0002\u0002\u0002\u0246",
    "\u0244\u0003\u0002\u0002\u0002\u0247\u0248\u0007\u000e\u0002\u0002\u0248",
    "\u0249\u00074\u0002\u0002\u0249\u024b\u0005\\/\u0002\u024a\u0247\u0003",
    "\u0002\u0002\u0002\u024a\u024b\u0003\u0002\u0002\u0002\u024bO\u0003",
    "\u0002\u0002\u0002\u024c\u024d\u0007\u000f\u0002\u0002\u024d\u024e\u0005",
    "^0\u0002\u024e\u024f\u00074\u0002\u0002\u024f\u0253\u0005\\/\u0002\u0250",
    "\u0251\u0007\u000e\u0002\u0002\u0251\u0252\u00074\u0002\u0002\u0252",
    "\u0254\u0005\\/\u0002\u0253\u0250\u0003\u0002\u0002\u0002\u0253\u0254",
    "\u0003\u0002\u0002\u0002\u0254Q\u0003\u0002\u0002\u0002\u0255\u0256",
    "\u0007\u0010\u0002\u0002\u0256\u0257\u0005\u008eH\u0002\u0257\u0258",
    "\u0007\u0011\u0002\u0002\u0258\u0259\u0005\u0090I\u0002\u0259\u025a",
    "\u00074\u0002\u0002\u025a\u025e\u0005\\/\u0002\u025b\u025c\u0007\u000e",
    "\u0002\u0002\u025c\u025d\u00074\u0002\u0002\u025d\u025f\u0005\\/\u0002",
    "\u025e\u025b\u0003\u0002\u0002\u0002\u025e\u025f\u0003\u0002\u0002\u0002",
    "\u025fS\u0003\u0002\u0002\u0002\u0260\u0261\u0007\u0012\u0002\u0002",
    "\u0261\u0262\u00074\u0002\u0002\u0262\u0278\u0005\\/\u0002\u0263\u0264",
    "\u0005Z.\u0002\u0264\u0265\u00074\u0002\u0002\u0265\u0266\u0005\\/\u0002",
    "\u0266\u0268\u0003\u0002\u0002\u0002\u0267\u0263\u0003\u0002\u0002\u0002",
    "\u0268\u0269\u0003\u0002\u0002\u0002\u0269\u0267\u0003\u0002\u0002\u0002",
    "\u0269\u026a\u0003\u0002\u0002\u0002\u026a\u026e\u0003\u0002\u0002\u0002",
    "\u026b\u026c\u0007\u000e\u0002\u0002\u026c\u026d\u00074\u0002\u0002",
    "\u026d\u026f\u0005\\/\u0002\u026e\u026b\u0003\u0002\u0002\u0002\u026e",
    "\u026f\u0003\u0002\u0002\u0002\u026f\u0273\u0003\u0002\u0002\u0002\u0270",
    "\u0271\u0007\u0013\u0002\u0002\u0271\u0272\u00074\u0002\u0002\u0272",
    "\u0274\u0005\\/\u0002\u0273\u0270\u0003\u0002\u0002\u0002\u0273\u0274",
    "\u0003\u0002\u0002\u0002\u0274\u0279\u0003\u0002\u0002\u0002\u0275\u0276",
    "\u0007\u0013\u0002\u0002\u0276\u0277\u00074\u0002\u0002\u0277\u0279",
    "\u0005\\/\u0002\u0278\u0267\u0003\u0002\u0002\u0002\u0278\u0275\u0003",
    "\u0002\u0002\u0002\u0279U\u0003\u0002\u0002\u0002\u027a\u027b\u0007",
    "\u0014\u0002\u0002\u027b\u0280\u0005X-\u0002\u027c\u027d\u00073\u0002",
    "\u0002\u027d\u027f\u0005X-\u0002\u027e\u027c\u0003\u0002\u0002\u0002",
    "\u027f\u0282\u0003\u0002\u0002\u0002\u0280\u027e\u0003\u0002\u0002\u0002",
    "\u0280\u0281\u0003\u0002\u0002\u0002\u0281\u0283\u0003\u0002\u0002\u0002",
    "\u0282\u0280\u0003\u0002\u0002\u0002\u0283\u0284\u00074\u0002\u0002",
    "\u0284\u0285\u0005\\/\u0002\u0285W\u0003\u0002\u0002\u0002\u0286\u0289",
    "\u0005^0\u0002\u0287\u0288\u0007\b\u0002\u0002\u0288\u028a\u0005r:\u0002",
    "\u0289\u0287\u0003\u0002\u0002\u0002\u0289\u028a\u0003\u0002\u0002\u0002",
    "\u028aY\u0003\u0002\u0002\u0002\u028b\u0291\u0007\u0015\u0002\u0002",
    "\u028c\u028f\u0005^0\u0002\u028d\u028e\u0007\b\u0002\u0002\u028e\u0290",
    "\u0007%\u0002\u0002\u028f\u028d\u0003\u0002\u0002\u0002\u028f\u0290",
    "\u0003\u0002\u0002\u0002\u0290\u0292\u0003\u0002\u0002\u0002\u0291\u028c",
    "\u0003\u0002\u0002\u0002\u0291\u0292\u0003\u0002\u0002\u0002\u0292[",
    "\u0003\u0002\u0002\u0002\u0293\u029e\u0005\u001c\u000f\u0002\u0294\u0295",
    "\u0007$\u0002\u0002\u0295\u0297\u0007_\u0002\u0002\u0296\u0298\u0005",
    "\u001a\u000e\u0002\u0297\u0296\u0003\u0002\u0002\u0002\u0298\u0299\u0003",
    "\u0002\u0002\u0002\u0299\u0297\u0003\u0002\u0002\u0002\u0299\u029a\u0003",
    "\u0002\u0002\u0002\u029a\u029b\u0003\u0002\u0002\u0002\u029b\u029c\u0007",
    "`\u0002\u0002\u029c\u029e\u0003\u0002\u0002\u0002\u029d\u0293\u0003",
    "\u0002\u0002\u0002\u029d\u0294\u0003\u0002\u0002\u0002\u029e]\u0003",
    "\u0002\u0002\u0002\u029f\u02a5\u0005f4\u0002\u02a0\u02a1\u0007\f\u0002",
    "\u0002\u02a1\u02a2\u0005f4\u0002\u02a2\u02a3\u0007\u000e\u0002\u0002",
    "\u02a3\u02a4\u0005^0\u0002\u02a4\u02a6\u0003\u0002\u0002\u0002\u02a5",
    "\u02a0\u0003\u0002\u0002\u0002\u02a5\u02a6\u0003\u0002\u0002\u0002\u02a6",
    "\u02a9\u0003\u0002\u0002\u0002\u02a7\u02a9\u0005b2\u0002\u02a8\u029f",
    "\u0003\u0002\u0002\u0002\u02a8\u02a7\u0003\u0002\u0002\u0002\u02a9_",
    "\u0003\u0002\u0002\u0002\u02aa\u02ad\u0005f4\u0002\u02ab\u02ad\u0005",
    "d3\u0002\u02ac\u02aa\u0003\u0002\u0002\u0002\u02ac\u02ab\u0003\u0002",
    "\u0002\u0002\u02ada\u0003\u0002\u0002\u0002\u02ae\u02b0\u0007\u0016",
    "\u0002\u0002\u02af\u02b1\u0005\u0016\f\u0002\u02b0\u02af\u0003\u0002",
    "\u0002\u0002\u02b0\u02b1\u0003\u0002\u0002\u0002\u02b1\u02b2\u0003\u0002",
    "\u0002\u0002\u02b2\u02b3\u00074\u0002\u0002\u02b3\u02b4\u0005^0\u0002",
    "\u02b4c\u0003\u0002\u0002\u0002\u02b5\u02b7\u0007\u0016\u0002\u0002",
    "\u02b6\u02b8\u0005\u0016\f\u0002\u02b7\u02b6\u0003\u0002\u0002\u0002",
    "\u02b7\u02b8\u0003\u0002\u0002\u0002\u02b8\u02b9\u0003\u0002\u0002\u0002",
    "\u02b9\u02ba\u00074\u0002\u0002\u02ba\u02bb\u0005`1\u0002\u02bbe\u0003",
    "\u0002\u0002\u0002\u02bc\u02c1\u0005h5\u0002\u02bd\u02be\u0007\u0017",
    "\u0002\u0002\u02be\u02c0\u0005h5\u0002\u02bf\u02bd\u0003\u0002\u0002",
    "\u0002\u02c0\u02c3\u0003\u0002\u0002\u0002\u02c1\u02bf\u0003\u0002\u0002",
    "\u0002\u02c1\u02c2\u0003\u0002\u0002\u0002\u02c2g\u0003\u0002\u0002",
    "\u0002\u02c3\u02c1\u0003\u0002\u0002\u0002\u02c4\u02c9\u0005j6\u0002",
    "\u02c5\u02c6\u0007\u0018\u0002\u0002\u02c6\u02c8\u0005j6\u0002\u02c7",
    "\u02c5\u0003\u0002\u0002\u0002\u02c8\u02cb\u0003\u0002\u0002\u0002\u02c9",
    "\u02c7\u0003\u0002\u0002\u0002\u02c9\u02ca\u0003\u0002\u0002\u0002\u02ca",
    "i\u0003\u0002\u0002\u0002\u02cb\u02c9\u0003\u0002\u0002\u0002\u02cc",
    "\u02cd\u0007\u0019\u0002\u0002\u02cd\u02d0\u0005j6\u0002\u02ce\u02d0",
    "\u0005l7\u0002\u02cf\u02cc\u0003\u0002\u0002\u0002\u02cf\u02ce\u0003",
    "\u0002\u0002\u0002\u02d0k\u0003\u0002\u0002\u0002\u02d1\u02d7\u0005",
    "p9\u0002\u02d2\u02d3\u0005n8\u0002\u02d3\u02d4\u0005p9\u0002\u02d4\u02d6",
    "\u0003\u0002\u0002\u0002\u02d5\u02d2\u0003\u0002\u0002\u0002\u02d6\u02d9",
    "\u0003\u0002\u0002\u0002\u02d7\u02d5\u0003\u0002\u0002\u0002\u02d7\u02d8",
    "\u0003\u0002\u0002\u0002\u02d8m\u0003\u0002\u0002\u0002\u02d9\u02d7",
    "\u0003\u0002\u0002\u0002\u02da\u02e8\u0007G\u0002\u0002\u02db\u02e8",
    "\u0007H\u0002\u0002\u02dc\u02e8\u0007I\u0002\u0002\u02dd\u02e8\u0007",
    "J\u0002\u0002\u02de\u02e8\u0007K\u0002\u0002\u02df\u02e8\u0007L\u0002",
    "\u0002\u02e0\u02e8\u0007M\u0002\u0002\u02e1\u02e8\u0007\u0011\u0002",
    "\u0002\u02e2\u02e3\u0007\u0019\u0002\u0002\u02e3\u02e8\u0007\u0011\u0002",
    "\u0002\u02e4\u02e8\u0007\u001a\u0002\u0002\u02e5\u02e6\u0007\u001a\u0002",
    "\u0002\u02e6\u02e8\u0007\u0019\u0002\u0002\u02e7\u02da\u0003\u0002\u0002",
    "\u0002\u02e7\u02db\u0003\u0002\u0002\u0002\u02e7\u02dc\u0003\u0002\u0002",
    "\u0002\u02e7\u02dd\u0003\u0002\u0002\u0002\u02e7\u02de\u0003\u0002\u0002",
    "\u0002\u02e7\u02df\u0003\u0002\u0002\u0002\u02e7\u02e0\u0003\u0002\u0002",
    "\u0002\u02e7\u02e1\u0003\u0002\u0002\u0002\u02e7\u02e2\u0003\u0002\u0002",
    "\u0002\u02e7\u02e4\u0003\u0002\u0002\u0002\u02e7\u02e5\u0003\u0002\u0002",
    "\u0002\u02e8o\u0003\u0002\u0002\u0002\u02e9\u02eb\u00070\u0002\u0002",
    "\u02ea\u02e9\u0003\u0002\u0002\u0002\u02ea\u02eb\u0003\u0002\u0002\u0002",
    "\u02eb\u02ec\u0003\u0002\u0002\u0002\u02ec\u02ed\u0005r:\u0002\u02ed",
    "q\u0003\u0002\u0002\u0002\u02ee\u02f3\u0005t;\u0002\u02ef\u02f0\u0007",
    ":\u0002\u0002\u02f0\u02f2\u0005t;\u0002\u02f1\u02ef\u0003\u0002\u0002",
    "\u0002\u02f2\u02f5\u0003\u0002\u0002\u0002\u02f3\u02f1\u0003\u0002\u0002",
    "\u0002\u02f3\u02f4\u0003\u0002\u0002\u0002\u02f4s\u0003\u0002\u0002",
    "\u0002\u02f5\u02f3\u0003\u0002\u0002\u0002\u02f6\u02fb\u0005v<\u0002",
    "\u02f7\u02f8\u0007;\u0002\u0002\u02f8\u02fa\u0005v<\u0002\u02f9\u02f7",
    "\u0003\u0002\u0002\u0002\u02fa\u02fd\u0003\u0002\u0002\u0002\u02fb\u02f9",
    "\u0003\u0002\u0002\u0002\u02fb\u02fc\u0003\u0002\u0002\u0002\u02fcu",
    "\u0003\u0002\u0002\u0002\u02fd\u02fb\u0003\u0002\u0002\u0002\u02fe\u0303",
    "\u0005x=\u0002\u02ff\u0300\u0007<\u0002\u0002\u0300\u0302\u0005x=\u0002",
    "\u0301\u02ff\u0003\u0002\u0002\u0002\u0302\u0305\u0003\u0002\u0002\u0002",
    "\u0303\u0301\u0003\u0002\u0002\u0002\u0303\u0304\u0003\u0002\u0002\u0002",
    "\u0304w\u0003\u0002\u0002\u0002\u0305\u0303\u0003\u0002\u0002\u0002",
    "\u0306\u030d\u0005z>\u0002\u0307\u0308\u0007=\u0002\u0002\u0308\u030c",
    "\u0005z>\u0002\u0309\u030a\u0007>\u0002\u0002\u030a\u030c\u0005z>\u0002",
    "\u030b\u0307\u0003\u0002\u0002\u0002\u030b\u0309\u0003\u0002\u0002\u0002",
    "\u030c\u030f\u0003\u0002\u0002\u0002\u030d\u030b\u0003\u0002\u0002\u0002",
    "\u030d\u030e\u0003\u0002\u0002\u0002\u030ey\u0003\u0002\u0002\u0002",
    "\u030f\u030d\u0003\u0002\u0002\u0002\u0310\u0317\u0005|?\u0002\u0311",
    "\u0312\u0007?\u0002\u0002\u0312\u0316\u0005|?\u0002\u0313\u0314\u0007",
    "@\u0002\u0002\u0314\u0316\u0005|?\u0002\u0315\u0311\u0003\u0002\u0002",
    "\u0002\u0315\u0313\u0003\u0002\u0002\u0002\u0316\u0319\u0003\u0002\u0002",
    "\u0002\u0317\u0315\u0003\u0002\u0002\u0002\u0317\u0318\u0003\u0002\u0002",
    "\u0002\u0318{\u0003\u0002\u0002\u0002\u0319\u0317\u0003\u0002\u0002",
    "\u0002\u031a\u0327\u0005~@\u0002\u031b\u031c\u00070\u0002\u0002\u031c",
    "\u0326\u0005~@\u0002\u031d\u031e\u0007A\u0002\u0002\u031e\u0326\u0005",
    "~@\u0002\u031f\u0320\u0007B\u0002\u0002\u0320\u0326\u0005~@\u0002\u0321",
    "\u0322\u0007C\u0002\u0002\u0322\u0326\u0005~@\u0002\u0323\u0324\u0007",
    "N\u0002\u0002\u0324\u0326\u0005~@\u0002\u0325\u031b\u0003\u0002\u0002",
    "\u0002\u0325\u031d\u0003\u0002\u0002\u0002\u0325\u031f\u0003\u0002\u0002",
    "\u0002\u0325\u0321\u0003\u0002\u0002\u0002\u0325\u0323\u0003\u0002\u0002",
    "\u0002\u0326\u0329\u0003\u0002\u0002\u0002\u0327\u0325\u0003\u0002\u0002",
    "\u0002\u0327\u0328\u0003\u0002\u0002\u0002\u0328}\u0003\u0002\u0002",
    "\u0002\u0329\u0327\u0003\u0002\u0002\u0002\u032a\u032b\u0007?\u0002",
    "\u0002\u032b\u0332\u0005~@\u0002\u032c\u032d\u0007@\u0002\u0002\u032d",
    "\u0332\u0005~@\u0002\u032e\u032f\u0007D\u0002\u0002\u032f\u0332\u0005",
    "~@\u0002\u0330\u0332\u0005\u0080A\u0002\u0331\u032a\u0003\u0002\u0002",
    "\u0002\u0331\u032c\u0003\u0002\u0002\u0002\u0331\u032e\u0003\u0002\u0002",
    "\u0002\u0331\u0330\u0003\u0002\u0002\u0002\u0332\u007f\u0003\u0002\u0002",
    "\u0002\u0333\u0337\u0005\u0082B\u0002\u0334\u0336\u0005\u0086D\u0002",
    "\u0335\u0334\u0003\u0002\u0002\u0002\u0336\u0339\u0003\u0002\u0002\u0002",
    "\u0337\u0335\u0003\u0002\u0002\u0002\u0337\u0338\u0003\u0002\u0002\u0002",
    "\u0338\u033c\u0003\u0002\u0002\u0002\u0339\u0337\u0003\u0002\u0002\u0002",
    "\u033a\u033b\u00076\u0002\u0002\u033b\u033d\u0005~@\u0002\u033c\u033a",
    "\u0003\u0002\u0002\u0002\u033c\u033d\u0003\u0002\u0002\u0002\u033d\u0081",
    "\u0003\u0002\u0002\u0002\u033e\u0341\u00071\u0002\u0002\u033f\u0342",
    "\u0005\u00a0Q\u0002\u0340\u0342\u0005\u0084C\u0002\u0341\u033f\u0003",
    "\u0002\u0002\u0002\u0341\u0340\u0003\u0002\u0002\u0002\u0341\u0342\u0003",
    "\u0002\u0002\u0002\u0342\u0343\u0003\u0002\u0002\u0002\u0343\u035a\u0007",
    "2\u0002\u0002\u0344\u0346\u00078\u0002\u0002\u0345\u0347\u0005\u0084",
    "C\u0002\u0346\u0345\u0003\u0002\u0002\u0002\u0346\u0347\u0003\u0002",
    "\u0002\u0002\u0347\u0348\u0003\u0002\u0002\u0002\u0348\u035a\u00079",
    "\u0002\u0002\u0349\u034b\u0007E\u0002\u0002\u034a\u034c\u0005\u0092",
    "J\u0002\u034b\u034a\u0003\u0002\u0002\u0002\u034b\u034c\u0003\u0002",
    "\u0002\u0002\u034c\u034d\u0003\u0002\u0002\u0002\u034d\u035a\u0007F",
    "\u0002\u0002\u034e\u035a\u0007%\u0002\u0002\u034f\u035a\u0005\u00a6",
    "T\u0002\u0350\u0352\u0005\u00a4S\u0002\u0351\u0350\u0003\u0002\u0002",
    "\u0002\u0352\u0353\u0003\u0002\u0002\u0002\u0353\u0351\u0003\u0002\u0002",
    "\u0002\u0353\u0354\u0003\u0002\u0002\u0002\u0354\u035a\u0003\u0002\u0002",
    "\u0002\u0355\u035a\u0007/\u0002\u0002\u0356\u035a\u0007\u001b\u0002",
    "\u0002\u0357\u035a\u0007\u001c\u0002\u0002\u0358\u035a\u0007\u001d\u0002",
    "\u0002\u0359\u033e\u0003\u0002\u0002\u0002\u0359\u0344\u0003\u0002\u0002",
    "\u0002\u0359\u0349\u0003\u0002\u0002\u0002\u0359\u034e\u0003\u0002\u0002",
    "\u0002\u0359\u034f\u0003\u0002\u0002\u0002\u0359\u0351\u0003\u0002\u0002",
    "\u0002\u0359\u0355\u0003\u0002\u0002\u0002\u0359\u0356\u0003\u0002\u0002",
    "\u0002\u0359\u0357\u0003\u0002\u0002\u0002\u0359\u0358\u0003\u0002\u0002",
    "\u0002\u035a\u0083\u0003\u0002\u0002\u0002\u035b\u0367\u0005^0\u0002",
    "\u035c\u0368\u0005\u009cO\u0002\u035d\u035e\u00073\u0002\u0002\u035e",
    "\u0360\u0005^0\u0002\u035f\u035d\u0003\u0002\u0002\u0002\u0360\u0363",
    "\u0003\u0002\u0002\u0002\u0361\u035f\u0003\u0002\u0002\u0002\u0361\u0362",
    "\u0003\u0002\u0002\u0002\u0362\u0365\u0003\u0002\u0002\u0002\u0363\u0361",
    "\u0003\u0002\u0002\u0002\u0364\u0366\u00073\u0002\u0002\u0365\u0364",
    "\u0003\u0002\u0002\u0002\u0365\u0366\u0003\u0002\u0002\u0002\u0366\u0368",
    "\u0003\u0002\u0002\u0002\u0367\u035c\u0003\u0002\u0002\u0002\u0367\u0361",
    "\u0003\u0002\u0002\u0002\u0368\u0085\u0003\u0002\u0002\u0002\u0369\u036b",
    "\u00071\u0002\u0002\u036a\u036c\u0005\u0096L\u0002\u036b\u036a\u0003",
    "\u0002\u0002\u0002\u036b\u036c\u0003\u0002\u0002\u0002\u036c\u036d\u0003",
    "\u0002\u0002\u0002\u036d\u0375\u00072\u0002\u0002\u036e\u036f\u0007",
    "8\u0002\u0002\u036f\u0370\u0005\u0088E\u0002\u0370\u0371\u00079\u0002",
    "\u0002\u0371\u0375\u0003\u0002\u0002\u0002\u0372\u0373\u0007.\u0002",
    "\u0002\u0373\u0375\u0007%\u0002\u0002\u0374\u0369\u0003\u0002\u0002",
    "\u0002\u0374\u036e\u0003\u0002\u0002\u0002\u0374\u0372\u0003\u0002\u0002",
    "\u0002\u0375\u0087\u0003\u0002\u0002\u0002\u0376\u037b\u0005\u008aF",
    "\u0002\u0377\u0378\u00073\u0002\u0002\u0378\u037a\u0005\u008aF\u0002",
    "\u0379\u0377\u0003\u0002\u0002\u0002\u037a\u037d\u0003\u0002\u0002\u0002",
    "\u037b\u0379\u0003\u0002\u0002\u0002\u037b\u037c\u0003\u0002\u0002\u0002",
    "\u037c\u037f\u0003\u0002\u0002\u0002\u037d\u037b\u0003\u0002\u0002\u0002",
    "\u037e\u0380\u00073\u0002\u0002\u037f\u037e\u0003\u0002\u0002\u0002",
    "\u037f\u0380\u0003\u0002\u0002\u0002\u0380\u0089\u0003\u0002\u0002\u0002",
    "\u0381\u038d\u0005^0\u0002\u0382\u0384\u0005^0\u0002\u0383\u0382\u0003",
    "\u0002\u0002\u0002\u0383\u0384\u0003\u0002\u0002\u0002\u0384\u0385\u0003",
    "\u0002\u0002\u0002\u0385\u0387\u00074\u0002\u0002\u0386\u0388\u0005",
    "^0\u0002\u0387\u0386\u0003\u0002\u0002\u0002\u0387\u0388\u0003\u0002",
    "\u0002\u0002\u0388\u038a\u0003\u0002\u0002\u0002\u0389\u038b\u0005\u008c",
    "G\u0002\u038a\u0389\u0003\u0002\u0002\u0002\u038a\u038b\u0003\u0002",
    "\u0002\u0002\u038b\u038d\u0003\u0002\u0002\u0002\u038c\u0381\u0003\u0002",
    "\u0002\u0002\u038c\u0383\u0003\u0002\u0002\u0002\u038d\u008b\u0003\u0002",
    "\u0002\u0002\u038e\u0390\u00074\u0002\u0002\u038f\u0391\u0005^0\u0002",
    "\u0390\u038f\u0003\u0002\u0002\u0002\u0390\u0391\u0003\u0002\u0002\u0002",
    "\u0391\u008d\u0003\u0002\u0002\u0002\u0392\u0397\u0005p9\u0002\u0393",
    "\u0394\u00073\u0002\u0002\u0394\u0396\u0005p9\u0002\u0395\u0393\u0003",
    "\u0002\u0002\u0002\u0396\u0399\u0003\u0002\u0002\u0002\u0397\u0395\u0003",
    "\u0002\u0002\u0002\u0397\u0398\u0003\u0002\u0002\u0002\u0398\u039b\u0003",
    "\u0002\u0002\u0002\u0399\u0397\u0003\u0002\u0002\u0002\u039a\u039c\u0007",
    "3\u0002\u0002\u039b\u039a\u0003\u0002\u0002\u0002\u039b\u039c\u0003",
    "\u0002\u0002\u0002\u039c\u008f\u0003\u0002\u0002\u0002\u039d\u03a2\u0005",
    "^0\u0002\u039e\u039f\u00073\u0002\u0002\u039f\u03a1\u0005^0\u0002\u03a0",
    "\u039e\u0003\u0002\u0002\u0002\u03a1\u03a4\u0003\u0002\u0002\u0002\u03a2",
    "\u03a0\u0003\u0002\u0002\u0002\u03a2\u03a3\u0003\u0002\u0002\u0002\u03a3",
    "\u03a6\u0003\u0002\u0002\u0002\u03a4\u03a2\u0003\u0002\u0002\u0002\u03a5",
    "\u03a7\u00073\u0002\u0002\u03a6\u03a5\u0003\u0002\u0002\u0002\u03a6",
    "\u03a7\u0003\u0002\u0002\u0002\u03a7\u0091\u0003\u0002\u0002\u0002\u03a8",
    "\u03a9\u0005^0\u0002\u03a9\u03aa\u00074\u0002\u0002\u03aa\u03b9\u0005",
    "^0\u0002\u03ab\u03ba\u0005\u009cO\u0002\u03ac\u03ad\u00073\u0002\u0002",
    "\u03ad\u03ae\u0005^0\u0002\u03ae\u03af\u00074\u0002\u0002\u03af\u03b0",
    "\u0005^0\u0002\u03b0\u03b2\u0003\u0002\u0002\u0002\u03b1\u03ac\u0003",
    "\u0002\u0002\u0002\u03b2\u03b5\u0003\u0002\u0002\u0002\u03b3\u03b1\u0003",
    "\u0002\u0002\u0002\u03b3\u03b4\u0003\u0002\u0002\u0002\u03b4\u03b7\u0003",
    "\u0002\u0002\u0002\u03b5\u03b3\u0003\u0002\u0002\u0002\u03b6\u03b8\u0007",
    "3\u0002\u0002\u03b7\u03b6\u0003\u0002\u0002\u0002\u03b7\u03b8\u0003",
    "\u0002\u0002\u0002\u03b8\u03ba\u0003\u0002\u0002\u0002\u03b9\u03ab\u0003",
    "\u0002\u0002\u0002\u03b9\u03b3\u0003\u0002\u0002\u0002\u03ba\u03ca\u0003",
    "\u0002\u0002\u0002\u03bb\u03c7\u0005^0\u0002\u03bc\u03c8\u0005\u009c",
    "O\u0002\u03bd\u03be\u00073\u0002\u0002\u03be\u03c0\u0005^0\u0002\u03bf",
    "\u03bd\u0003\u0002\u0002\u0002\u03c0\u03c3\u0003\u0002\u0002\u0002\u03c1",
    "\u03bf\u0003\u0002\u0002\u0002\u03c1\u03c2\u0003\u0002\u0002\u0002\u03c2",
    "\u03c5\u0003\u0002\u0002\u0002\u03c3\u03c1\u0003\u0002\u0002\u0002\u03c4",
    "\u03c6\u00073\u0002\u0002\u03c5\u03c4\u0003\u0002\u0002\u0002\u03c5",
    "\u03c6\u0003\u0002\u0002\u0002\u03c6\u03c8\u0003\u0002\u0002\u0002\u03c7",
    "\u03bc\u0003\u0002\u0002\u0002\u03c7\u03c1\u0003\u0002\u0002\u0002\u03c8",
    "\u03ca\u0003\u0002\u0002\u0002\u03c9\u03a8\u0003\u0002\u0002\u0002\u03c9",
    "\u03bb\u0003\u0002\u0002\u0002\u03ca\u0093\u0003\u0002\u0002\u0002\u03cb",
    "\u03cc\u0007\u001e\u0002\u0002\u03cc\u03d2\u0007%\u0002\u0002\u03cd",
    "\u03cf\u00071\u0002\u0002\u03ce\u03d0\u0005\u0096L\u0002\u03cf\u03ce",
    "\u0003\u0002\u0002\u0002\u03cf\u03d0\u0003\u0002\u0002\u0002\u03d0\u03d1",
    "\u0003\u0002\u0002\u0002\u03d1\u03d3\u00072\u0002\u0002\u03d2\u03cd",
    "\u0003\u0002\u0002\u0002\u03d2\u03d3\u0003\u0002\u0002\u0002\u03d3\u03d4",
    "\u0003\u0002\u0002\u0002\u03d4\u03d5\u00074\u0002\u0002\u03d5\u03d6",
    "\u0005\\/\u0002\u03d6\u0095\u0003\u0002\u0002\u0002\u03d7\u03d8\u0005",
    "\u0098M\u0002\u03d8\u03d9\u00073\u0002\u0002\u03d9\u03db\u0003\u0002",
    "\u0002\u0002\u03da\u03d7\u0003\u0002\u0002\u0002\u03db\u03de\u0003\u0002",
    "\u0002\u0002\u03dc\u03da\u0003\u0002\u0002\u0002\u03dc\u03dd\u0003\u0002",
    "\u0002\u0002\u03dd\u03f3\u0003\u0002\u0002\u0002\u03de\u03dc\u0003\u0002",
    "\u0002\u0002\u03df\u03e1\u0005\u0098M\u0002\u03e0\u03e2\u00073\u0002",
    "\u0002\u03e1\u03e0\u0003\u0002\u0002\u0002\u03e1\u03e2\u0003\u0002\u0002",
    "\u0002\u03e2\u03f4\u0003\u0002\u0002\u0002\u03e3\u03e4\u00070\u0002",
    "\u0002\u03e4\u03e9\u0005^0\u0002\u03e5\u03e6\u00073\u0002\u0002\u03e6",
    "\u03e8\u0005\u0098M\u0002\u03e7\u03e5\u0003\u0002\u0002\u0002\u03e8",
    "\u03eb\u0003\u0002\u0002\u0002\u03e9\u03e7\u0003\u0002\u0002\u0002\u03e9",
    "\u03ea\u0003\u0002\u0002\u0002\u03ea\u03ef\u0003\u0002\u0002\u0002\u03eb",
    "\u03e9\u0003\u0002\u0002\u0002\u03ec\u03ed\u00073\u0002\u0002\u03ed",
    "\u03ee\u00076\u0002\u0002\u03ee\u03f0\u0005^0\u0002\u03ef\u03ec\u0003",
    "\u0002\u0002\u0002\u03ef\u03f0\u0003\u0002\u0002\u0002\u03f0\u03f4\u0003",
    "\u0002\u0002\u0002\u03f1\u03f2\u00076\u0002\u0002\u03f2\u03f4\u0005",
    "^0\u0002\u03f3\u03df\u0003\u0002\u0002\u0002\u03f3\u03e3\u0003\u0002",
    "\u0002\u0002\u03f3\u03f1\u0003\u0002\u0002\u0002\u03f4\u0097\u0003\u0002",
    "\u0002\u0002\u03f5\u03f7\u0005^0\u0002\u03f6\u03f8\u0005\u009cO\u0002",
    "\u03f7\u03f6\u0003\u0002\u0002\u0002\u03f7\u03f8\u0003\u0002\u0002\u0002",
    "\u03f8\u03fe\u0003\u0002\u0002\u0002\u03f9\u03fa\u0005^0\u0002\u03fa",
    "\u03fb\u00077\u0002\u0002\u03fb\u03fc\u0005^0\u0002\u03fc\u03fe\u0003",
    "\u0002\u0002\u0002\u03fd\u03f5\u0003\u0002\u0002\u0002\u03fd\u03f9\u0003",
    "\u0002\u0002\u0002\u03fe\u0099\u0003\u0002\u0002\u0002\u03ff\u0402\u0005",
    "\u009cO\u0002\u0400\u0402\u0005\u009eP\u0002\u0401\u03ff\u0003\u0002",
    "\u0002\u0002\u0401\u0400\u0003\u0002\u0002\u0002\u0402\u009b\u0003\u0002",
    "\u0002\u0002\u0403\u0404\u0007\u0010\u0002\u0002\u0404\u0405\u0005\u008e",
    "H\u0002\u0405\u0406\u0007\u0011\u0002\u0002\u0406\u0408\u0005f4\u0002",
    "\u0407\u0409\u0005\u009aN\u0002\u0408\u0407\u0003\u0002\u0002\u0002",
    "\u0408\u0409\u0003\u0002\u0002\u0002\u0409\u009d\u0003\u0002\u0002\u0002",
    "\u040a\u040b\u0007\f\u0002\u0002\u040b\u040d\u0005`1\u0002\u040c\u040e",
    "\u0005\u009aN\u0002\u040d\u040c\u0003\u0002\u0002\u0002\u040d\u040e",
    "\u0003\u0002\u0002\u0002\u040e\u009f\u0003\u0002\u0002\u0002\u040f\u0411",
    "\u0007\u001f\u0002\u0002\u0410\u0412\u0005\u00a2R\u0002\u0411\u0410",
    "\u0003\u0002\u0002\u0002\u0411\u0412\u0003\u0002\u0002\u0002\u0412\u00a1",
    "\u0003\u0002\u0002\u0002\u0413\u0414\u0007\u0006\u0002\u0002\u0414\u0417",
    "\u0005^0\u0002\u0415\u0417\u0005\u0090I\u0002\u0416\u0413\u0003\u0002",
    "\u0002\u0002\u0416\u0415\u0003\u0002\u0002\u0002\u0417\u00a3\u0003\u0002",
    "\u0002\u0002\u0418\u0419\t\u0004\u0002\u0002\u0419\u00a5\u0003\u0002",
    "\u0002\u0002\u041a\u041e\u0005\u00a8U\u0002\u041b\u041e\u0007,\u0002",
    "\u0002\u041c\u041e\u0007-\u0002\u0002\u041d\u041a\u0003\u0002\u0002",
    "\u0002\u041d\u041b\u0003\u0002\u0002\u0002\u041d\u041c\u0003\u0002\u0002",
    "\u0002\u041e\u00a7\u0003\u0002\u0002\u0002\u041f\u0420\t\u0005\u0002",
    "\u0002\u0420\u00a9\u0003\u0002\u0002\u0002\u0098\u00af\u00b3\u00b5\u00be",
    "\u00c7\u00ca\u00d1\u00d6\u00dd\u00e4\u00eb\u00f1\u00f5\u00fb\u0101\u0105",
    "\u010b\u010f\u0111\u0115\u011b\u011f\u0125\u0129\u012e\u0133\u0139\u013d",
    "\u0143\u0149\u014d\u0153\u0157\u0159\u015d\u0163\u0167\u016d\u0171\u0177",
    "\u017e\u0182\u018e\u0194\u0199\u019d\u01a0\u01a4\u01a9\u01ad\u01b1\u01bf",
    "\u01c7\u01cf\u01d1\u01d5\u01de\u01e5\u01e7\u01f0\u01f5\u01fa\u0201\u0205",
    "\u020c\u0214\u021d\u0226\u022d\u0237\u0244\u024a\u0253\u025e\u0269\u026e",
    "\u0273\u0278\u0280\u0289\u028f\u0291\u0299\u029d\u02a5\u02a8\u02ac\u02b0",
    "\u02b7\u02c1\u02c9\u02cf\u02d7\u02e7\u02ea\u02f3\u02fb\u0303\u030b\u030d",
    "\u0315\u0317\u0325\u0327\u0331\u0337\u033c\u0341\u0346\u034b\u0353\u0359",
    "\u0361\u0365\u0367\u036b\u0374\u037b\u037f\u0383\u0387\u038a\u038c\u0390",
    "\u0397\u039b\u03a2\u03a6\u03b3\u03b7\u03b9\u03c1\u03c5\u03c7\u03c9\u03cf",
    "\u03d2\u03dc\u03e1\u03e9\u03ef\u03f3\u03f7\u03fd\u0401\u0408\u040d\u0411",
    "\u0416\u041d"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'def'", "'return'", "'raise'", "'from'", "'import'", 
                     "'as'", "'global'", "'nonlocal'", "'assert'", "'if'", 
                     "'elif'", "'else'", "'while'", "'for'", "'in'", "'try'", 
                     "'finally'", "'with'", "'except'", "'lambda'", "'or'", 
                     "'and'", "'not'", "'is'", "'None'", "'True'", "'False'", 
                     "'class'", "'yield'", "'del'", "'pass'", "'continue'", 
                     "'break'", null, null, null, null, null, null, null, 
                     null, null, null, "'.'", "'...'", "'*'", "'('", "')'", 
                     "','", "':'", "';'", "'**'", "'='", "'['", "']'", "'|'", 
                     "'^'", "'&'", "'<<'", "'>>'", "'+'", "'-'", "'/'", 
                     "'%'", "'//'", "'~'", "'{'", "'}'", "'<'", "'>'", "'=='", 
                     "'>='", "'<='", "'<>'", "'!='", "'@'", "'->'", "'+='", 
                     "'-='", "'*='", "'@='", "'/='", "'%='", "'&='", "'|='", 
                     "'^='", "'<<='", "'>>='", "'**='", "'//='" ];

var symbolicNames = [ null, "DEF", "RETURN", "RAISE", "FROM", "IMPORT", 
                      "AS", "GLOBAL", "NONLOCAL", "ASSERT", "IF", "ELIF", 
                      "ELSE", "WHILE", "FOR", "IN", "TRY", "FINALLY", "WITH", 
                      "EXCEPT", "LAMBDA", "OR", "AND", "NOT", "IS", "NONE", 
                      "TRUE", "FALSE", "CLASS", "YIELD", "DEL", "PASS", 
                      "CONTINUE", "BREAK", "NEWLINE", "NAME", "STRING_LITERAL", 
                      "BYTES_LITERAL", "DECIMAL_INTEGER", "OCT_INTEGER", 
                      "HEX_INTEGER", "BIN_INTEGER", "FLOAT_NUMBER", "IMAG_NUMBER", 
                      "DOT", "ELLIPSIS", "STAR", "OPEN_PAREN", "CLOSE_PAREN", 
                      "COMMA", "COLON", "SEMI_COLON", "POWER", "ASSIGN", 
                      "OPEN_BRACK", "CLOSE_BRACK", "OR_OP", "XOR", "AND_OP", 
                      "LEFT_SHIFT", "RIGHT_SHIFT", "ADD", "MINUS", "DIV", 
                      "MOD", "IDIV", "NOT_OP", "OPEN_BRACE", "CLOSE_BRACE", 
                      "LESS_THAN", "GREATER_THAN", "EQUALS", "GT_EQ", "LT_EQ", 
                      "NOT_EQ_1", "NOT_EQ_2", "AT", "ARROW", "ADD_ASSIGN", 
                      "SUB_ASSIGN", "MULT_ASSIGN", "AT_ASSIGN", "DIV_ASSIGN", 
                      "MOD_ASSIGN", "AND_ASSIGN", "OR_ASSIGN", "XOR_ASSIGN", 
                      "LEFT_SHIFT_ASSIGN", "RIGHT_SHIFT_ASSIGN", "POWER_ASSIGN", 
                      "IDIV_ASSIGN", "SKIP_", "UNKNOWN_CHAR", "INDENT", 
                      "DEDENT" ];

var ruleNames =  [ "single_input", "file_input", "eval_input", "decorator", 
                   "decorators", "decorated", "funcdef", "parameters", "typedargslist", 
                   "tfpdef", "varargslist", "vfpdef", "stmt", "simple_stmt", 
                   "small_stmt", "expr_stmt", "testlist_star_expr", "augassign", 
                   "del_stmt", "pass_stmt", "flow_stmt", "break_stmt", "continue_stmt", 
                   "return_stmt", "yield_stmt", "raise_stmt", "import_stmt", 
                   "import_name", "import_from", "import_as_name", "dotted_as_name", 
                   "import_as_names", "dotted_as_names", "dotted_name", 
                   "global_stmt", "nonlocal_stmt", "assert_stmt", "compound_stmt", 
                   "if_stmt", "while_stmt", "for_stmt", "try_stmt", "with_stmt", 
                   "with_item", "except_clause", "suite", "test", "test_nocond", 
                   "lambdef", "lambdef_nocond", "or_test", "and_test", "not_test", 
                   "comparison", "comp_op", "star_expr", "expr", "xor_expr", 
                   "and_expr", "shift_expr", "arith_expr", "term", "factor", 
                   "power", "atom", "testlist_comp", "trailer", "subscriptlist", 
                   "subscript", "sliceop", "exprlist", "testlist", "dictorsetmaker", 
                   "classdef", "arglist", "argument", "comp_iter", "comp_for", 
                   "comp_if", "yield_expr", "yield_arg", "str", "number", 
                   "integer" ];

function Python3Parser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

Python3Parser.prototype = Object.create(antlr4.Parser.prototype);
Python3Parser.prototype.constructor = Python3Parser;

Object.defineProperty(Python3Parser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

Python3Parser.EOF = antlr4.Token.EOF;
Python3Parser.DEF = 1;
Python3Parser.RETURN = 2;
Python3Parser.RAISE = 3;
Python3Parser.FROM = 4;
Python3Parser.IMPORT = 5;
Python3Parser.AS = 6;
Python3Parser.GLOBAL = 7;
Python3Parser.NONLOCAL = 8;
Python3Parser.ASSERT = 9;
Python3Parser.IF = 10;
Python3Parser.ELIF = 11;
Python3Parser.ELSE = 12;
Python3Parser.WHILE = 13;
Python3Parser.FOR = 14;
Python3Parser.IN = 15;
Python3Parser.TRY = 16;
Python3Parser.FINALLY = 17;
Python3Parser.WITH = 18;
Python3Parser.EXCEPT = 19;
Python3Parser.LAMBDA = 20;
Python3Parser.OR = 21;
Python3Parser.AND = 22;
Python3Parser.NOT = 23;
Python3Parser.IS = 24;
Python3Parser.NONE = 25;
Python3Parser.TRUE = 26;
Python3Parser.FALSE = 27;
Python3Parser.CLASS = 28;
Python3Parser.YIELD = 29;
Python3Parser.DEL = 30;
Python3Parser.PASS = 31;
Python3Parser.CONTINUE = 32;
Python3Parser.BREAK = 33;
Python3Parser.NEWLINE = 34;
Python3Parser.NAME = 35;
Python3Parser.STRING_LITERAL = 36;
Python3Parser.BYTES_LITERAL = 37;
Python3Parser.DECIMAL_INTEGER = 38;
Python3Parser.OCT_INTEGER = 39;
Python3Parser.HEX_INTEGER = 40;
Python3Parser.BIN_INTEGER = 41;
Python3Parser.FLOAT_NUMBER = 42;
Python3Parser.IMAG_NUMBER = 43;
Python3Parser.DOT = 44;
Python3Parser.ELLIPSIS = 45;
Python3Parser.STAR = 46;
Python3Parser.OPEN_PAREN = 47;
Python3Parser.CLOSE_PAREN = 48;
Python3Parser.COMMA = 49;
Python3Parser.COLON = 50;
Python3Parser.SEMI_COLON = 51;
Python3Parser.POWER = 52;
Python3Parser.ASSIGN = 53;
Python3Parser.OPEN_BRACK = 54;
Python3Parser.CLOSE_BRACK = 55;
Python3Parser.OR_OP = 56;
Python3Parser.XOR = 57;
Python3Parser.AND_OP = 58;
Python3Parser.LEFT_SHIFT = 59;
Python3Parser.RIGHT_SHIFT = 60;
Python3Parser.ADD = 61;
Python3Parser.MINUS = 62;
Python3Parser.DIV = 63;
Python3Parser.MOD = 64;
Python3Parser.IDIV = 65;
Python3Parser.NOT_OP = 66;
Python3Parser.OPEN_BRACE = 67;
Python3Parser.CLOSE_BRACE = 68;
Python3Parser.LESS_THAN = 69;
Python3Parser.GREATER_THAN = 70;
Python3Parser.EQUALS = 71;
Python3Parser.GT_EQ = 72;
Python3Parser.LT_EQ = 73;
Python3Parser.NOT_EQ_1 = 74;
Python3Parser.NOT_EQ_2 = 75;
Python3Parser.AT = 76;
Python3Parser.ARROW = 77;
Python3Parser.ADD_ASSIGN = 78;
Python3Parser.SUB_ASSIGN = 79;
Python3Parser.MULT_ASSIGN = 80;
Python3Parser.AT_ASSIGN = 81;
Python3Parser.DIV_ASSIGN = 82;
Python3Parser.MOD_ASSIGN = 83;
Python3Parser.AND_ASSIGN = 84;
Python3Parser.OR_ASSIGN = 85;
Python3Parser.XOR_ASSIGN = 86;
Python3Parser.LEFT_SHIFT_ASSIGN = 87;
Python3Parser.RIGHT_SHIFT_ASSIGN = 88;
Python3Parser.POWER_ASSIGN = 89;
Python3Parser.IDIV_ASSIGN = 90;
Python3Parser.SKIP_ = 91;
Python3Parser.UNKNOWN_CHAR = 92;
Python3Parser.INDENT = 93;
Python3Parser.DEDENT = 94;

Python3Parser.RULE_single_input = 0;
Python3Parser.RULE_file_input = 1;
Python3Parser.RULE_eval_input = 2;
Python3Parser.RULE_decorator = 3;
Python3Parser.RULE_decorators = 4;
Python3Parser.RULE_decorated = 5;
Python3Parser.RULE_funcdef = 6;
Python3Parser.RULE_parameters = 7;
Python3Parser.RULE_typedargslist = 8;
Python3Parser.RULE_tfpdef = 9;
Python3Parser.RULE_varargslist = 10;
Python3Parser.RULE_vfpdef = 11;
Python3Parser.RULE_stmt = 12;
Python3Parser.RULE_simple_stmt = 13;
Python3Parser.RULE_small_stmt = 14;
Python3Parser.RULE_expr_stmt = 15;
Python3Parser.RULE_testlist_star_expr = 16;
Python3Parser.RULE_augassign = 17;
Python3Parser.RULE_del_stmt = 18;
Python3Parser.RULE_pass_stmt = 19;
Python3Parser.RULE_flow_stmt = 20;
Python3Parser.RULE_break_stmt = 21;
Python3Parser.RULE_continue_stmt = 22;
Python3Parser.RULE_return_stmt = 23;
Python3Parser.RULE_yield_stmt = 24;
Python3Parser.RULE_raise_stmt = 25;
Python3Parser.RULE_import_stmt = 26;
Python3Parser.RULE_import_name = 27;
Python3Parser.RULE_import_from = 28;
Python3Parser.RULE_import_as_name = 29;
Python3Parser.RULE_dotted_as_name = 30;
Python3Parser.RULE_import_as_names = 31;
Python3Parser.RULE_dotted_as_names = 32;
Python3Parser.RULE_dotted_name = 33;
Python3Parser.RULE_global_stmt = 34;
Python3Parser.RULE_nonlocal_stmt = 35;
Python3Parser.RULE_assert_stmt = 36;
Python3Parser.RULE_compound_stmt = 37;
Python3Parser.RULE_if_stmt = 38;
Python3Parser.RULE_while_stmt = 39;
Python3Parser.RULE_for_stmt = 40;
Python3Parser.RULE_try_stmt = 41;
Python3Parser.RULE_with_stmt = 42;
Python3Parser.RULE_with_item = 43;
Python3Parser.RULE_except_clause = 44;
Python3Parser.RULE_suite = 45;
Python3Parser.RULE_test = 46;
Python3Parser.RULE_test_nocond = 47;
Python3Parser.RULE_lambdef = 48;
Python3Parser.RULE_lambdef_nocond = 49;
Python3Parser.RULE_or_test = 50;
Python3Parser.RULE_and_test = 51;
Python3Parser.RULE_not_test = 52;
Python3Parser.RULE_comparison = 53;
Python3Parser.RULE_comp_op = 54;
Python3Parser.RULE_star_expr = 55;
Python3Parser.RULE_expr = 56;
Python3Parser.RULE_xor_expr = 57;
Python3Parser.RULE_and_expr = 58;
Python3Parser.RULE_shift_expr = 59;
Python3Parser.RULE_arith_expr = 60;
Python3Parser.RULE_term = 61;
Python3Parser.RULE_factor = 62;
Python3Parser.RULE_power = 63;
Python3Parser.RULE_atom = 64;
Python3Parser.RULE_testlist_comp = 65;
Python3Parser.RULE_trailer = 66;
Python3Parser.RULE_subscriptlist = 67;
Python3Parser.RULE_subscript = 68;
Python3Parser.RULE_sliceop = 69;
Python3Parser.RULE_exprlist = 70;
Python3Parser.RULE_testlist = 71;
Python3Parser.RULE_dictorsetmaker = 72;
Python3Parser.RULE_classdef = 73;
Python3Parser.RULE_arglist = 74;
Python3Parser.RULE_argument = 75;
Python3Parser.RULE_comp_iter = 76;
Python3Parser.RULE_comp_for = 77;
Python3Parser.RULE_comp_if = 78;
Python3Parser.RULE_yield_expr = 79;
Python3Parser.RULE_yield_arg = 80;
Python3Parser.RULE_str = 81;
Python3Parser.RULE_number = 82;
Python3Parser.RULE_integer = 83;

function Single_inputContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_single_input;
    return this;
}

Single_inputContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Single_inputContext.prototype.constructor = Single_inputContext;

Single_inputContext.prototype.NEWLINE = function() {
    return this.getToken(Python3Parser.NEWLINE, 0);
};

Single_inputContext.prototype.simple_stmt = function() {
    return this.getTypedRuleContext(Simple_stmtContext,0);
};

Single_inputContext.prototype.compound_stmt = function() {
    return this.getTypedRuleContext(Compound_stmtContext,0);
};

Single_inputContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSingle_input(this);
	}
};

Single_inputContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSingle_input(this);
	}
};




Python3Parser.Single_inputContext = Single_inputContext;

Python3Parser.prototype.single_input = function() {

    var localctx = new Single_inputContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, Python3Parser.RULE_single_input);
    try {
        this.state = 173;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NEWLINE:
            this.enterOuterAlt(localctx, 1);
            this.state = 168;
            this.match(Python3Parser.NEWLINE);
            break;
        case Python3Parser.RETURN:
        case Python3Parser.RAISE:
        case Python3Parser.FROM:
        case Python3Parser.IMPORT:
        case Python3Parser.GLOBAL:
        case Python3Parser.NONLOCAL:
        case Python3Parser.ASSERT:
        case Python3Parser.LAMBDA:
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.YIELD:
        case Python3Parser.DEL:
        case Python3Parser.PASS:
        case Python3Parser.CONTINUE:
        case Python3Parser.BREAK:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 2);
            this.state = 169;
            this.simple_stmt();
            break;
        case Python3Parser.DEF:
        case Python3Parser.IF:
        case Python3Parser.WHILE:
        case Python3Parser.FOR:
        case Python3Parser.TRY:
        case Python3Parser.WITH:
        case Python3Parser.CLASS:
        case Python3Parser.AT:
            this.enterOuterAlt(localctx, 3);
            this.state = 170;
            this.compound_stmt();
            this.state = 171;
            this.match(Python3Parser.NEWLINE);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function File_inputContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_file_input;
    return this;
}

File_inputContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
File_inputContext.prototype.constructor = File_inputContext;

File_inputContext.prototype.EOF = function() {
    return this.getToken(Python3Parser.EOF, 0);
};

File_inputContext.prototype.NEWLINE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NEWLINE);
    } else {
        return this.getToken(Python3Parser.NEWLINE, i);
    }
};


File_inputContext.prototype.stmt = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StmtContext);
    } else {
        return this.getTypedRuleContext(StmtContext,i);
    }
};

File_inputContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterFile_input(this);
	}
};

File_inputContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitFile_input(this);
	}
};




Python3Parser.File_inputContext = File_inputContext;

Python3Parser.prototype.file_input = function() {

    var localctx = new File_inputContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, Python3Parser.RULE_file_input);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 179;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << Python3Parser.DEF) | (1 << Python3Parser.RETURN) | (1 << Python3Parser.RAISE) | (1 << Python3Parser.FROM) | (1 << Python3Parser.IMPORT) | (1 << Python3Parser.GLOBAL) | (1 << Python3Parser.NONLOCAL) | (1 << Python3Parser.ASSERT) | (1 << Python3Parser.IF) | (1 << Python3Parser.WHILE) | (1 << Python3Parser.FOR) | (1 << Python3Parser.TRY) | (1 << Python3Parser.WITH) | (1 << Python3Parser.LAMBDA) | (1 << Python3Parser.NOT) | (1 << Python3Parser.NONE) | (1 << Python3Parser.TRUE) | (1 << Python3Parser.FALSE) | (1 << Python3Parser.CLASS) | (1 << Python3Parser.YIELD) | (1 << Python3Parser.DEL) | (1 << Python3Parser.PASS))) !== 0) || ((((_la - 32)) & ~0x1f) == 0 && ((1 << (_la - 32)) & ((1 << (Python3Parser.CONTINUE - 32)) | (1 << (Python3Parser.BREAK - 32)) | (1 << (Python3Parser.NEWLINE - 32)) | (1 << (Python3Parser.NAME - 32)) | (1 << (Python3Parser.STRING_LITERAL - 32)) | (1 << (Python3Parser.BYTES_LITERAL - 32)) | (1 << (Python3Parser.DECIMAL_INTEGER - 32)) | (1 << (Python3Parser.OCT_INTEGER - 32)) | (1 << (Python3Parser.HEX_INTEGER - 32)) | (1 << (Python3Parser.BIN_INTEGER - 32)) | (1 << (Python3Parser.FLOAT_NUMBER - 32)) | (1 << (Python3Parser.IMAG_NUMBER - 32)) | (1 << (Python3Parser.ELLIPSIS - 32)) | (1 << (Python3Parser.STAR - 32)) | (1 << (Python3Parser.OPEN_PAREN - 32)) | (1 << (Python3Parser.OPEN_BRACK - 32)) | (1 << (Python3Parser.ADD - 32)) | (1 << (Python3Parser.MINUS - 32)))) !== 0) || ((((_la - 66)) & ~0x1f) == 0 && ((1 << (_la - 66)) & ((1 << (Python3Parser.NOT_OP - 66)) | (1 << (Python3Parser.OPEN_BRACE - 66)) | (1 << (Python3Parser.AT - 66)))) !== 0)) {
            this.state = 177;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.NEWLINE:
                this.state = 175;
                this.match(Python3Parser.NEWLINE);
                break;
            case Python3Parser.DEF:
            case Python3Parser.RETURN:
            case Python3Parser.RAISE:
            case Python3Parser.FROM:
            case Python3Parser.IMPORT:
            case Python3Parser.GLOBAL:
            case Python3Parser.NONLOCAL:
            case Python3Parser.ASSERT:
            case Python3Parser.IF:
            case Python3Parser.WHILE:
            case Python3Parser.FOR:
            case Python3Parser.TRY:
            case Python3Parser.WITH:
            case Python3Parser.LAMBDA:
            case Python3Parser.NOT:
            case Python3Parser.NONE:
            case Python3Parser.TRUE:
            case Python3Parser.FALSE:
            case Python3Parser.CLASS:
            case Python3Parser.YIELD:
            case Python3Parser.DEL:
            case Python3Parser.PASS:
            case Python3Parser.CONTINUE:
            case Python3Parser.BREAK:
            case Python3Parser.NAME:
            case Python3Parser.STRING_LITERAL:
            case Python3Parser.BYTES_LITERAL:
            case Python3Parser.DECIMAL_INTEGER:
            case Python3Parser.OCT_INTEGER:
            case Python3Parser.HEX_INTEGER:
            case Python3Parser.BIN_INTEGER:
            case Python3Parser.FLOAT_NUMBER:
            case Python3Parser.IMAG_NUMBER:
            case Python3Parser.ELLIPSIS:
            case Python3Parser.STAR:
            case Python3Parser.OPEN_PAREN:
            case Python3Parser.OPEN_BRACK:
            case Python3Parser.ADD:
            case Python3Parser.MINUS:
            case Python3Parser.NOT_OP:
            case Python3Parser.OPEN_BRACE:
            case Python3Parser.AT:
                this.state = 176;
                this.stmt();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            this.state = 181;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 182;
        this.match(Python3Parser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Eval_inputContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_eval_input;
    return this;
}

Eval_inputContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Eval_inputContext.prototype.constructor = Eval_inputContext;

Eval_inputContext.prototype.testlist = function() {
    return this.getTypedRuleContext(TestlistContext,0);
};

Eval_inputContext.prototype.EOF = function() {
    return this.getToken(Python3Parser.EOF, 0);
};

Eval_inputContext.prototype.NEWLINE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NEWLINE);
    } else {
        return this.getToken(Python3Parser.NEWLINE, i);
    }
};


Eval_inputContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterEval_input(this);
	}
};

Eval_inputContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitEval_input(this);
	}
};




Python3Parser.Eval_inputContext = Eval_inputContext;

Python3Parser.prototype.eval_input = function() {

    var localctx = new Eval_inputContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, Python3Parser.RULE_eval_input);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 184;
        this.testlist();
        this.state = 188;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.NEWLINE) {
            this.state = 185;
            this.match(Python3Parser.NEWLINE);
            this.state = 190;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 191;
        this.match(Python3Parser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DecoratorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_decorator;
    return this;
}

DecoratorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DecoratorContext.prototype.constructor = DecoratorContext;

DecoratorContext.prototype.dotted_name = function() {
    return this.getTypedRuleContext(Dotted_nameContext,0);
};

DecoratorContext.prototype.NEWLINE = function() {
    return this.getToken(Python3Parser.NEWLINE, 0);
};

DecoratorContext.prototype.arglist = function() {
    return this.getTypedRuleContext(ArglistContext,0);
};

DecoratorContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDecorator(this);
	}
};

DecoratorContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDecorator(this);
	}
};




Python3Parser.DecoratorContext = DecoratorContext;

Python3Parser.prototype.decorator = function() {

    var localctx = new DecoratorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, Python3Parser.RULE_decorator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 193;
        this.match(Python3Parser.AT);
        this.state = 194;
        this.dotted_name();
        this.state = 200;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.OPEN_PAREN) {
            this.state = 195;
            this.match(Python3Parser.OPEN_PAREN);
            this.state = 197;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 52)) & ~0x1f) == 0 && ((1 << (_la - 52)) & ((1 << (Python3Parser.POWER - 52)) | (1 << (Python3Parser.OPEN_BRACK - 52)) | (1 << (Python3Parser.ADD - 52)) | (1 << (Python3Parser.MINUS - 52)) | (1 << (Python3Parser.NOT_OP - 52)) | (1 << (Python3Parser.OPEN_BRACE - 52)))) !== 0)) {
                this.state = 196;
                this.arglist();
            }

            this.state = 199;
            this.match(Python3Parser.CLOSE_PAREN);
        }

        this.state = 202;
        this.match(Python3Parser.NEWLINE);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DecoratorsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_decorators;
    return this;
}

DecoratorsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DecoratorsContext.prototype.constructor = DecoratorsContext;

DecoratorsContext.prototype.decorator = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(DecoratorContext);
    } else {
        return this.getTypedRuleContext(DecoratorContext,i);
    }
};

DecoratorsContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDecorators(this);
	}
};

DecoratorsContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDecorators(this);
	}
};




Python3Parser.DecoratorsContext = DecoratorsContext;

Python3Parser.prototype.decorators = function() {

    var localctx = new DecoratorsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, Python3Parser.RULE_decorators);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 205; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 204;
            this.decorator();
            this.state = 207; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while(_la===Python3Parser.AT);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DecoratedContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_decorated;
    return this;
}

DecoratedContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DecoratedContext.prototype.constructor = DecoratedContext;

DecoratedContext.prototype.decorators = function() {
    return this.getTypedRuleContext(DecoratorsContext,0);
};

DecoratedContext.prototype.classdef = function() {
    return this.getTypedRuleContext(ClassdefContext,0);
};

DecoratedContext.prototype.funcdef = function() {
    return this.getTypedRuleContext(FuncdefContext,0);
};

DecoratedContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDecorated(this);
	}
};

DecoratedContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDecorated(this);
	}
};




Python3Parser.DecoratedContext = DecoratedContext;

Python3Parser.prototype.decorated = function() {

    var localctx = new DecoratedContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, Python3Parser.RULE_decorated);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 209;
        this.decorators();
        this.state = 212;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.CLASS:
            this.state = 210;
            this.classdef();
            break;
        case Python3Parser.DEF:
            this.state = 211;
            this.funcdef();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function FuncdefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_funcdef;
    return this;
}

FuncdefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FuncdefContext.prototype.constructor = FuncdefContext;

FuncdefContext.prototype.DEF = function() {
    return this.getToken(Python3Parser.DEF, 0);
};

FuncdefContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

FuncdefContext.prototype.parameters = function() {
    return this.getTypedRuleContext(ParametersContext,0);
};

FuncdefContext.prototype.suite = function() {
    return this.getTypedRuleContext(SuiteContext,0);
};

FuncdefContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

FuncdefContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterFuncdef(this);
	}
};

FuncdefContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitFuncdef(this);
	}
};




Python3Parser.FuncdefContext = FuncdefContext;

Python3Parser.prototype.funcdef = function() {

    var localctx = new FuncdefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, Python3Parser.RULE_funcdef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 214;
        this.match(Python3Parser.DEF);
        this.state = 215;
        this.match(Python3Parser.NAME);
        this.state = 216;
        this.parameters();
        this.state = 219;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.ARROW) {
            this.state = 217;
            this.match(Python3Parser.ARROW);
            this.state = 218;
            this.test();
        }

        this.state = 221;
        this.match(Python3Parser.COLON);
        this.state = 222;
        this.suite();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ParametersContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_parameters;
    return this;
}

ParametersContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ParametersContext.prototype.constructor = ParametersContext;

ParametersContext.prototype.typedargslist = function() {
    return this.getTypedRuleContext(TypedargslistContext,0);
};

ParametersContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterParameters(this);
	}
};

ParametersContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitParameters(this);
	}
};




Python3Parser.ParametersContext = ParametersContext;

Python3Parser.prototype.parameters = function() {

    var localctx = new ParametersContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, Python3Parser.RULE_parameters);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 224;
        this.match(Python3Parser.OPEN_PAREN);
        this.state = 226;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (Python3Parser.NAME - 35)) | (1 << (Python3Parser.STAR - 35)) | (1 << (Python3Parser.POWER - 35)))) !== 0)) {
            this.state = 225;
            this.typedargslist();
        }

        this.state = 228;
        this.match(Python3Parser.CLOSE_PAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TypedargslistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_typedargslist;
    return this;
}

TypedargslistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TypedargslistContext.prototype.constructor = TypedargslistContext;

TypedargslistContext.prototype.tfpdef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TfpdefContext);
    } else {
        return this.getTypedRuleContext(TfpdefContext,i);
    }
};

TypedargslistContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

TypedargslistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTypedargslist(this);
	}
};

TypedargslistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTypedargslist(this);
	}
};




Python3Parser.TypedargslistContext = TypedargslistContext;

Python3Parser.prototype.typedargslist = function() {

    var localctx = new TypedargslistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, Python3Parser.RULE_typedargslist);
    var _la = 0; // Token type
    try {
        this.state = 295;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NAME:
            this.enterOuterAlt(localctx, 1);
            this.state = 230;
            this.tfpdef();
            this.state = 233;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.ASSIGN) {
                this.state = 231;
                this.match(Python3Parser.ASSIGN);
                this.state = 232;
                this.test();
            }

            this.state = 243;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,12,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 235;
                    this.match(Python3Parser.COMMA);
                    this.state = 236;
                    this.tfpdef();
                    this.state = 239;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                    if(_la===Python3Parser.ASSIGN) {
                        this.state = 237;
                        this.match(Python3Parser.ASSIGN);
                        this.state = 238;
                        this.test();
                    }
             
                }
                this.state = 245;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,12,this._ctx);
            }

            this.state = 271;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 246;
                this.match(Python3Parser.COMMA);
                this.state = 269;
                this._errHandler.sync(this);
                switch (this._input.LA(1)) {
                case Python3Parser.STAR:
                	this.state = 247;
                	this.match(Python3Parser.STAR);
                	this.state = 249;
                	this._errHandler.sync(this);
                	_la = this._input.LA(1);
                	if(_la===Python3Parser.NAME) {
                	    this.state = 248;
                	    this.tfpdef();
                	}

                	this.state = 259;
                	this._errHandler.sync(this);
                	var _alt = this._interp.adaptivePredict(this._input,15,this._ctx)
                	while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                	    if(_alt===1) {
                	        this.state = 251;
                	        this.match(Python3Parser.COMMA);
                	        this.state = 252;
                	        this.tfpdef();
                	        this.state = 255;
                	        this._errHandler.sync(this);
                	        _la = this._input.LA(1);
                	        if(_la===Python3Parser.ASSIGN) {
                	            this.state = 253;
                	            this.match(Python3Parser.ASSIGN);
                	            this.state = 254;
                	            this.test();
                	        }
                	 
                	    }
                	    this.state = 261;
                	    this._errHandler.sync(this);
                	    _alt = this._interp.adaptivePredict(this._input,15,this._ctx);
                	}

                	this.state = 265;
                	this._errHandler.sync(this);
                	_la = this._input.LA(1);
                	if(_la===Python3Parser.COMMA) {
                	    this.state = 262;
                	    this.match(Python3Parser.COMMA);
                	    this.state = 263;
                	    this.match(Python3Parser.POWER);
                	    this.state = 264;
                	    this.tfpdef();
                	}

                	break;
                case Python3Parser.POWER:
                	this.state = 267;
                	this.match(Python3Parser.POWER);
                	this.state = 268;
                	this.tfpdef();
                	break;
                case Python3Parser.CLOSE_PAREN:
                	break;
                default:
                	break;
                }
            }

            break;
        case Python3Parser.STAR:
            this.enterOuterAlt(localctx, 2);
            this.state = 273;
            this.match(Python3Parser.STAR);
            this.state = 275;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.NAME) {
                this.state = 274;
                this.tfpdef();
            }

            this.state = 285;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,21,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 277;
                    this.match(Python3Parser.COMMA);
                    this.state = 278;
                    this.tfpdef();
                    this.state = 281;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                    if(_la===Python3Parser.ASSIGN) {
                        this.state = 279;
                        this.match(Python3Parser.ASSIGN);
                        this.state = 280;
                        this.test();
                    }
             
                }
                this.state = 287;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,21,this._ctx);
            }

            this.state = 291;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 288;
                this.match(Python3Parser.COMMA);
                this.state = 289;
                this.match(Python3Parser.POWER);
                this.state = 290;
                this.tfpdef();
            }

            break;
        case Python3Parser.POWER:
            this.enterOuterAlt(localctx, 3);
            this.state = 293;
            this.match(Python3Parser.POWER);
            this.state = 294;
            this.tfpdef();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TfpdefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_tfpdef;
    return this;
}

TfpdefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TfpdefContext.prototype.constructor = TfpdefContext;

TfpdefContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

TfpdefContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

TfpdefContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTfpdef(this);
	}
};

TfpdefContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTfpdef(this);
	}
};




Python3Parser.TfpdefContext = TfpdefContext;

Python3Parser.prototype.tfpdef = function() {

    var localctx = new TfpdefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, Python3Parser.RULE_tfpdef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 297;
        this.match(Python3Parser.NAME);
        this.state = 300;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COLON) {
            this.state = 298;
            this.match(Python3Parser.COLON);
            this.state = 299;
            this.test();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function VarargslistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_varargslist;
    return this;
}

VarargslistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
VarargslistContext.prototype.constructor = VarargslistContext;

VarargslistContext.prototype.vfpdef = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(VfpdefContext);
    } else {
        return this.getTypedRuleContext(VfpdefContext,i);
    }
};

VarargslistContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

VarargslistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterVarargslist(this);
	}
};

VarargslistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitVarargslist(this);
	}
};




Python3Parser.VarargslistContext = VarargslistContext;

Python3Parser.prototype.varargslist = function() {

    var localctx = new VarargslistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, Python3Parser.RULE_varargslist);
    var _la = 0; // Token type
    try {
        this.state = 367;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NAME:
            this.enterOuterAlt(localctx, 1);
            this.state = 302;
            this.vfpdef();
            this.state = 305;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.ASSIGN) {
                this.state = 303;
                this.match(Python3Parser.ASSIGN);
                this.state = 304;
                this.test();
            }

            this.state = 315;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,27,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 307;
                    this.match(Python3Parser.COMMA);
                    this.state = 308;
                    this.vfpdef();
                    this.state = 311;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                    if(_la===Python3Parser.ASSIGN) {
                        this.state = 309;
                        this.match(Python3Parser.ASSIGN);
                        this.state = 310;
                        this.test();
                    }
             
                }
                this.state = 317;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,27,this._ctx);
            }

            this.state = 343;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 318;
                this.match(Python3Parser.COMMA);
                this.state = 341;
                this._errHandler.sync(this);
                switch (this._input.LA(1)) {
                case Python3Parser.STAR:
                	this.state = 319;
                	this.match(Python3Parser.STAR);
                	this.state = 321;
                	this._errHandler.sync(this);
                	_la = this._input.LA(1);
                	if(_la===Python3Parser.NAME) {
                	    this.state = 320;
                	    this.vfpdef();
                	}

                	this.state = 331;
                	this._errHandler.sync(this);
                	var _alt = this._interp.adaptivePredict(this._input,30,this._ctx)
                	while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                	    if(_alt===1) {
                	        this.state = 323;
                	        this.match(Python3Parser.COMMA);
                	        this.state = 324;
                	        this.vfpdef();
                	        this.state = 327;
                	        this._errHandler.sync(this);
                	        _la = this._input.LA(1);
                	        if(_la===Python3Parser.ASSIGN) {
                	            this.state = 325;
                	            this.match(Python3Parser.ASSIGN);
                	            this.state = 326;
                	            this.test();
                	        }
                	 
                	    }
                	    this.state = 333;
                	    this._errHandler.sync(this);
                	    _alt = this._interp.adaptivePredict(this._input,30,this._ctx);
                	}

                	this.state = 337;
                	this._errHandler.sync(this);
                	_la = this._input.LA(1);
                	if(_la===Python3Parser.COMMA) {
                	    this.state = 334;
                	    this.match(Python3Parser.COMMA);
                	    this.state = 335;
                	    this.match(Python3Parser.POWER);
                	    this.state = 336;
                	    this.vfpdef();
                	}

                	break;
                case Python3Parser.POWER:
                	this.state = 339;
                	this.match(Python3Parser.POWER);
                	this.state = 340;
                	this.vfpdef();
                	break;
                case Python3Parser.COLON:
                	break;
                default:
                	break;
                }
            }

            break;
        case Python3Parser.STAR:
            this.enterOuterAlt(localctx, 2);
            this.state = 345;
            this.match(Python3Parser.STAR);
            this.state = 347;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.NAME) {
                this.state = 346;
                this.vfpdef();
            }

            this.state = 357;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,36,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 349;
                    this.match(Python3Parser.COMMA);
                    this.state = 350;
                    this.vfpdef();
                    this.state = 353;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                    if(_la===Python3Parser.ASSIGN) {
                        this.state = 351;
                        this.match(Python3Parser.ASSIGN);
                        this.state = 352;
                        this.test();
                    }
             
                }
                this.state = 359;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,36,this._ctx);
            }

            this.state = 363;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 360;
                this.match(Python3Parser.COMMA);
                this.state = 361;
                this.match(Python3Parser.POWER);
                this.state = 362;
                this.vfpdef();
            }

            break;
        case Python3Parser.POWER:
            this.enterOuterAlt(localctx, 3);
            this.state = 365;
            this.match(Python3Parser.POWER);
            this.state = 366;
            this.vfpdef();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function VfpdefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_vfpdef;
    return this;
}

VfpdefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
VfpdefContext.prototype.constructor = VfpdefContext;

VfpdefContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

VfpdefContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterVfpdef(this);
	}
};

VfpdefContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitVfpdef(this);
	}
};




Python3Parser.VfpdefContext = VfpdefContext;

Python3Parser.prototype.vfpdef = function() {

    var localctx = new VfpdefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, Python3Parser.RULE_vfpdef);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 369;
        this.match(Python3Parser.NAME);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function StmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_stmt;
    return this;
}

StmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StmtContext.prototype.constructor = StmtContext;

StmtContext.prototype.simple_stmt = function() {
    return this.getTypedRuleContext(Simple_stmtContext,0);
};

StmtContext.prototype.compound_stmt = function() {
    return this.getTypedRuleContext(Compound_stmtContext,0);
};

StmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterStmt(this);
	}
};

StmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitStmt(this);
	}
};




Python3Parser.StmtContext = StmtContext;

Python3Parser.prototype.stmt = function() {

    var localctx = new StmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, Python3Parser.RULE_stmt);
    try {
        this.state = 373;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.RETURN:
        case Python3Parser.RAISE:
        case Python3Parser.FROM:
        case Python3Parser.IMPORT:
        case Python3Parser.GLOBAL:
        case Python3Parser.NONLOCAL:
        case Python3Parser.ASSERT:
        case Python3Parser.LAMBDA:
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.YIELD:
        case Python3Parser.DEL:
        case Python3Parser.PASS:
        case Python3Parser.CONTINUE:
        case Python3Parser.BREAK:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 1);
            this.state = 371;
            this.simple_stmt();
            break;
        case Python3Parser.DEF:
        case Python3Parser.IF:
        case Python3Parser.WHILE:
        case Python3Parser.FOR:
        case Python3Parser.TRY:
        case Python3Parser.WITH:
        case Python3Parser.CLASS:
        case Python3Parser.AT:
            this.enterOuterAlt(localctx, 2);
            this.state = 372;
            this.compound_stmt();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Simple_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_simple_stmt;
    return this;
}

Simple_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Simple_stmtContext.prototype.constructor = Simple_stmtContext;

Simple_stmtContext.prototype.small_stmt = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Small_stmtContext);
    } else {
        return this.getTypedRuleContext(Small_stmtContext,i);
    }
};

Simple_stmtContext.prototype.NEWLINE = function() {
    return this.getToken(Python3Parser.NEWLINE, 0);
};

Simple_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSimple_stmt(this);
	}
};

Simple_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSimple_stmt(this);
	}
};




Python3Parser.Simple_stmtContext = Simple_stmtContext;

Python3Parser.prototype.simple_stmt = function() {

    var localctx = new Simple_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, Python3Parser.RULE_simple_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 375;
        this.small_stmt();
        this.state = 380;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,40,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 376;
                this.match(Python3Parser.SEMI_COLON);
                this.state = 377;
                this.small_stmt(); 
            }
            this.state = 382;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,40,this._ctx);
        }

        this.state = 384;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.SEMI_COLON) {
            this.state = 383;
            this.match(Python3Parser.SEMI_COLON);
        }

        this.state = 386;
        this.match(Python3Parser.NEWLINE);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Small_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_small_stmt;
    return this;
}

Small_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Small_stmtContext.prototype.constructor = Small_stmtContext;

Small_stmtContext.prototype.expr_stmt = function() {
    return this.getTypedRuleContext(Expr_stmtContext,0);
};

Small_stmtContext.prototype.del_stmt = function() {
    return this.getTypedRuleContext(Del_stmtContext,0);
};

Small_stmtContext.prototype.pass_stmt = function() {
    return this.getTypedRuleContext(Pass_stmtContext,0);
};

Small_stmtContext.prototype.flow_stmt = function() {
    return this.getTypedRuleContext(Flow_stmtContext,0);
};

Small_stmtContext.prototype.import_stmt = function() {
    return this.getTypedRuleContext(Import_stmtContext,0);
};

Small_stmtContext.prototype.global_stmt = function() {
    return this.getTypedRuleContext(Global_stmtContext,0);
};

Small_stmtContext.prototype.nonlocal_stmt = function() {
    return this.getTypedRuleContext(Nonlocal_stmtContext,0);
};

Small_stmtContext.prototype.assert_stmt = function() {
    return this.getTypedRuleContext(Assert_stmtContext,0);
};

Small_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSmall_stmt(this);
	}
};

Small_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSmall_stmt(this);
	}
};




Python3Parser.Small_stmtContext = Small_stmtContext;

Python3Parser.prototype.small_stmt = function() {

    var localctx = new Small_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, Python3Parser.RULE_small_stmt);
    try {
        this.state = 396;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.LAMBDA:
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 1);
            this.state = 388;
            this.expr_stmt();
            break;
        case Python3Parser.DEL:
            this.enterOuterAlt(localctx, 2);
            this.state = 389;
            this.del_stmt();
            break;
        case Python3Parser.PASS:
            this.enterOuterAlt(localctx, 3);
            this.state = 390;
            this.pass_stmt();
            break;
        case Python3Parser.RETURN:
        case Python3Parser.RAISE:
        case Python3Parser.YIELD:
        case Python3Parser.CONTINUE:
        case Python3Parser.BREAK:
            this.enterOuterAlt(localctx, 4);
            this.state = 391;
            this.flow_stmt();
            break;
        case Python3Parser.FROM:
        case Python3Parser.IMPORT:
            this.enterOuterAlt(localctx, 5);
            this.state = 392;
            this.import_stmt();
            break;
        case Python3Parser.GLOBAL:
            this.enterOuterAlt(localctx, 6);
            this.state = 393;
            this.global_stmt();
            break;
        case Python3Parser.NONLOCAL:
            this.enterOuterAlt(localctx, 7);
            this.state = 394;
            this.nonlocal_stmt();
            break;
        case Python3Parser.ASSERT:
            this.enterOuterAlt(localctx, 8);
            this.state = 395;
            this.assert_stmt();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Expr_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_expr_stmt;
    return this;
}

Expr_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Expr_stmtContext.prototype.constructor = Expr_stmtContext;

Expr_stmtContext.prototype.testlist_star_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Testlist_star_exprContext);
    } else {
        return this.getTypedRuleContext(Testlist_star_exprContext,i);
    }
};

Expr_stmtContext.prototype.augassign = function() {
    return this.getTypedRuleContext(AugassignContext,0);
};

Expr_stmtContext.prototype.yield_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Yield_exprContext);
    } else {
        return this.getTypedRuleContext(Yield_exprContext,i);
    }
};

Expr_stmtContext.prototype.testlist = function() {
    return this.getTypedRuleContext(TestlistContext,0);
};

Expr_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterExpr_stmt(this);
	}
};

Expr_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitExpr_stmt(this);
	}
};




Python3Parser.Expr_stmtContext = Expr_stmtContext;

Python3Parser.prototype.expr_stmt = function() {

    var localctx = new Expr_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, Python3Parser.RULE_expr_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 398;
        this.testlist_star_expr();
        this.state = 414;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.ADD_ASSIGN:
        case Python3Parser.SUB_ASSIGN:
        case Python3Parser.MULT_ASSIGN:
        case Python3Parser.AT_ASSIGN:
        case Python3Parser.DIV_ASSIGN:
        case Python3Parser.MOD_ASSIGN:
        case Python3Parser.AND_ASSIGN:
        case Python3Parser.OR_ASSIGN:
        case Python3Parser.XOR_ASSIGN:
        case Python3Parser.LEFT_SHIFT_ASSIGN:
        case Python3Parser.RIGHT_SHIFT_ASSIGN:
        case Python3Parser.POWER_ASSIGN:
        case Python3Parser.IDIV_ASSIGN:
            this.state = 399;
            this.augassign();
            this.state = 402;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.YIELD:
                this.state = 400;
                this.yield_expr();
                break;
            case Python3Parser.LAMBDA:
            case Python3Parser.NOT:
            case Python3Parser.NONE:
            case Python3Parser.TRUE:
            case Python3Parser.FALSE:
            case Python3Parser.NAME:
            case Python3Parser.STRING_LITERAL:
            case Python3Parser.BYTES_LITERAL:
            case Python3Parser.DECIMAL_INTEGER:
            case Python3Parser.OCT_INTEGER:
            case Python3Parser.HEX_INTEGER:
            case Python3Parser.BIN_INTEGER:
            case Python3Parser.FLOAT_NUMBER:
            case Python3Parser.IMAG_NUMBER:
            case Python3Parser.ELLIPSIS:
            case Python3Parser.STAR:
            case Python3Parser.OPEN_PAREN:
            case Python3Parser.OPEN_BRACK:
            case Python3Parser.ADD:
            case Python3Parser.MINUS:
            case Python3Parser.NOT_OP:
            case Python3Parser.OPEN_BRACE:
                this.state = 401;
                this.testlist();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            break;
        case Python3Parser.NEWLINE:
        case Python3Parser.SEMI_COLON:
        case Python3Parser.ASSIGN:
            this.state = 411;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===Python3Parser.ASSIGN) {
                this.state = 404;
                this.match(Python3Parser.ASSIGN);
                this.state = 407;
                this._errHandler.sync(this);
                switch(this._input.LA(1)) {
                case Python3Parser.YIELD:
                    this.state = 405;
                    this.yield_expr();
                    break;
                case Python3Parser.LAMBDA:
                case Python3Parser.NOT:
                case Python3Parser.NONE:
                case Python3Parser.TRUE:
                case Python3Parser.FALSE:
                case Python3Parser.NAME:
                case Python3Parser.STRING_LITERAL:
                case Python3Parser.BYTES_LITERAL:
                case Python3Parser.DECIMAL_INTEGER:
                case Python3Parser.OCT_INTEGER:
                case Python3Parser.HEX_INTEGER:
                case Python3Parser.BIN_INTEGER:
                case Python3Parser.FLOAT_NUMBER:
                case Python3Parser.IMAG_NUMBER:
                case Python3Parser.ELLIPSIS:
                case Python3Parser.STAR:
                case Python3Parser.OPEN_PAREN:
                case Python3Parser.OPEN_BRACK:
                case Python3Parser.ADD:
                case Python3Parser.MINUS:
                case Python3Parser.NOT_OP:
                case Python3Parser.OPEN_BRACE:
                    this.state = 406;
                    this.testlist_star_expr();
                    break;
                default:
                    throw new antlr4.error.NoViableAltException(this);
                }
                this.state = 413;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Testlist_star_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_testlist_star_expr;
    return this;
}

Testlist_star_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Testlist_star_exprContext.prototype.constructor = Testlist_star_exprContext;

Testlist_star_exprContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

Testlist_star_exprContext.prototype.star_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Star_exprContext);
    } else {
        return this.getTypedRuleContext(Star_exprContext,i);
    }
};

Testlist_star_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTestlist_star_expr(this);
	}
};

Testlist_star_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTestlist_star_expr(this);
	}
};




Python3Parser.Testlist_star_exprContext = Testlist_star_exprContext;

Python3Parser.prototype.testlist_star_expr = function() {

    var localctx = new Testlist_star_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 32, Python3Parser.RULE_testlist_star_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 418;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,47,this._ctx);
        switch(la_) {
        case 1:
            this.state = 416;
            this.test();
            break;

        case 2:
            this.state = 417;
            this.star_expr();
            break;

        }
        this.state = 427;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,49,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 420;
                this.match(Python3Parser.COMMA);
                this.state = 423;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input,48,this._ctx);
                switch(la_) {
                case 1:
                    this.state = 421;
                    this.test();
                    break;

                case 2:
                    this.state = 422;
                    this.star_expr();
                    break;

                } 
            }
            this.state = 429;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,49,this._ctx);
        }

        this.state = 431;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 430;
            this.match(Python3Parser.COMMA);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AugassignContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_augassign;
    return this;
}

AugassignContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AugassignContext.prototype.constructor = AugassignContext;


AugassignContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterAugassign(this);
	}
};

AugassignContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitAugassign(this);
	}
};




Python3Parser.AugassignContext = AugassignContext;

Python3Parser.prototype.augassign = function() {

    var localctx = new AugassignContext(this, this._ctx, this.state);
    this.enterRule(localctx, 34, Python3Parser.RULE_augassign);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 433;
        _la = this._input.LA(1);
        if(!(((((_la - 78)) & ~0x1f) == 0 && ((1 << (_la - 78)) & ((1 << (Python3Parser.ADD_ASSIGN - 78)) | (1 << (Python3Parser.SUB_ASSIGN - 78)) | (1 << (Python3Parser.MULT_ASSIGN - 78)) | (1 << (Python3Parser.AT_ASSIGN - 78)) | (1 << (Python3Parser.DIV_ASSIGN - 78)) | (1 << (Python3Parser.MOD_ASSIGN - 78)) | (1 << (Python3Parser.AND_ASSIGN - 78)) | (1 << (Python3Parser.OR_ASSIGN - 78)) | (1 << (Python3Parser.XOR_ASSIGN - 78)) | (1 << (Python3Parser.LEFT_SHIFT_ASSIGN - 78)) | (1 << (Python3Parser.RIGHT_SHIFT_ASSIGN - 78)) | (1 << (Python3Parser.POWER_ASSIGN - 78)) | (1 << (Python3Parser.IDIV_ASSIGN - 78)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Del_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_del_stmt;
    return this;
}

Del_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Del_stmtContext.prototype.constructor = Del_stmtContext;

Del_stmtContext.prototype.DEL = function() {
    return this.getToken(Python3Parser.DEL, 0);
};

Del_stmtContext.prototype.exprlist = function() {
    return this.getTypedRuleContext(ExprlistContext,0);
};

Del_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDel_stmt(this);
	}
};

Del_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDel_stmt(this);
	}
};




Python3Parser.Del_stmtContext = Del_stmtContext;

Python3Parser.prototype.del_stmt = function() {

    var localctx = new Del_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 36, Python3Parser.RULE_del_stmt);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 435;
        this.match(Python3Parser.DEL);
        this.state = 436;
        this.exprlist();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Pass_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_pass_stmt;
    return this;
}

Pass_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Pass_stmtContext.prototype.constructor = Pass_stmtContext;

Pass_stmtContext.prototype.PASS = function() {
    return this.getToken(Python3Parser.PASS, 0);
};

Pass_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterPass_stmt(this);
	}
};

Pass_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitPass_stmt(this);
	}
};




Python3Parser.Pass_stmtContext = Pass_stmtContext;

Python3Parser.prototype.pass_stmt = function() {

    var localctx = new Pass_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 38, Python3Parser.RULE_pass_stmt);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 438;
        this.match(Python3Parser.PASS);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Flow_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_flow_stmt;
    return this;
}

Flow_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Flow_stmtContext.prototype.constructor = Flow_stmtContext;

Flow_stmtContext.prototype.break_stmt = function() {
    return this.getTypedRuleContext(Break_stmtContext,0);
};

Flow_stmtContext.prototype.continue_stmt = function() {
    return this.getTypedRuleContext(Continue_stmtContext,0);
};

Flow_stmtContext.prototype.return_stmt = function() {
    return this.getTypedRuleContext(Return_stmtContext,0);
};

Flow_stmtContext.prototype.raise_stmt = function() {
    return this.getTypedRuleContext(Raise_stmtContext,0);
};

Flow_stmtContext.prototype.yield_stmt = function() {
    return this.getTypedRuleContext(Yield_stmtContext,0);
};

Flow_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterFlow_stmt(this);
	}
};

Flow_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitFlow_stmt(this);
	}
};




Python3Parser.Flow_stmtContext = Flow_stmtContext;

Python3Parser.prototype.flow_stmt = function() {

    var localctx = new Flow_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 40, Python3Parser.RULE_flow_stmt);
    try {
        this.state = 445;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.BREAK:
            this.enterOuterAlt(localctx, 1);
            this.state = 440;
            this.break_stmt();
            break;
        case Python3Parser.CONTINUE:
            this.enterOuterAlt(localctx, 2);
            this.state = 441;
            this.continue_stmt();
            break;
        case Python3Parser.RETURN:
            this.enterOuterAlt(localctx, 3);
            this.state = 442;
            this.return_stmt();
            break;
        case Python3Parser.RAISE:
            this.enterOuterAlt(localctx, 4);
            this.state = 443;
            this.raise_stmt();
            break;
        case Python3Parser.YIELD:
            this.enterOuterAlt(localctx, 5);
            this.state = 444;
            this.yield_stmt();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Break_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_break_stmt;
    return this;
}

Break_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Break_stmtContext.prototype.constructor = Break_stmtContext;

Break_stmtContext.prototype.BREAK = function() {
    return this.getToken(Python3Parser.BREAK, 0);
};

Break_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterBreak_stmt(this);
	}
};

Break_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitBreak_stmt(this);
	}
};




Python3Parser.Break_stmtContext = Break_stmtContext;

Python3Parser.prototype.break_stmt = function() {

    var localctx = new Break_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 42, Python3Parser.RULE_break_stmt);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 447;
        this.match(Python3Parser.BREAK);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Continue_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_continue_stmt;
    return this;
}

Continue_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Continue_stmtContext.prototype.constructor = Continue_stmtContext;

Continue_stmtContext.prototype.CONTINUE = function() {
    return this.getToken(Python3Parser.CONTINUE, 0);
};

Continue_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterContinue_stmt(this);
	}
};

Continue_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitContinue_stmt(this);
	}
};




Python3Parser.Continue_stmtContext = Continue_stmtContext;

Python3Parser.prototype.continue_stmt = function() {

    var localctx = new Continue_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 44, Python3Parser.RULE_continue_stmt);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 449;
        this.match(Python3Parser.CONTINUE);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Return_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_return_stmt;
    return this;
}

Return_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Return_stmtContext.prototype.constructor = Return_stmtContext;

Return_stmtContext.prototype.RETURN = function() {
    return this.getToken(Python3Parser.RETURN, 0);
};

Return_stmtContext.prototype.testlist = function() {
    return this.getTypedRuleContext(TestlistContext,0);
};

Return_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterReturn_stmt(this);
	}
};

Return_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitReturn_stmt(this);
	}
};




Python3Parser.Return_stmtContext = Return_stmtContext;

Python3Parser.prototype.return_stmt = function() {

    var localctx = new Return_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 46, Python3Parser.RULE_return_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 451;
        this.match(Python3Parser.RETURN);
        this.state = 453;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
            this.state = 452;
            this.testlist();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Yield_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_yield_stmt;
    return this;
}

Yield_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Yield_stmtContext.prototype.constructor = Yield_stmtContext;

Yield_stmtContext.prototype.yield_expr = function() {
    return this.getTypedRuleContext(Yield_exprContext,0);
};

Yield_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterYield_stmt(this);
	}
};

Yield_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitYield_stmt(this);
	}
};




Python3Parser.Yield_stmtContext = Yield_stmtContext;

Python3Parser.prototype.yield_stmt = function() {

    var localctx = new Yield_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 48, Python3Parser.RULE_yield_stmt);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 455;
        this.yield_expr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Raise_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_raise_stmt;
    return this;
}

Raise_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Raise_stmtContext.prototype.constructor = Raise_stmtContext;

Raise_stmtContext.prototype.RAISE = function() {
    return this.getToken(Python3Parser.RAISE, 0);
};

Raise_stmtContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

Raise_stmtContext.prototype.FROM = function() {
    return this.getToken(Python3Parser.FROM, 0);
};

Raise_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterRaise_stmt(this);
	}
};

Raise_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitRaise_stmt(this);
	}
};




Python3Parser.Raise_stmtContext = Raise_stmtContext;

Python3Parser.prototype.raise_stmt = function() {

    var localctx = new Raise_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 50, Python3Parser.RULE_raise_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 457;
        this.match(Python3Parser.RAISE);
        this.state = 463;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
            this.state = 458;
            this.test();
            this.state = 461;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.FROM) {
                this.state = 459;
                this.match(Python3Parser.FROM);
                this.state = 460;
                this.test();
            }

        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Import_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_import_stmt;
    return this;
}

Import_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Import_stmtContext.prototype.constructor = Import_stmtContext;

Import_stmtContext.prototype.import_name = function() {
    return this.getTypedRuleContext(Import_nameContext,0);
};

Import_stmtContext.prototype.import_from = function() {
    return this.getTypedRuleContext(Import_fromContext,0);
};

Import_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterImport_stmt(this);
	}
};

Import_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitImport_stmt(this);
	}
};




Python3Parser.Import_stmtContext = Import_stmtContext;

Python3Parser.prototype.import_stmt = function() {

    var localctx = new Import_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 52, Python3Parser.RULE_import_stmt);
    try {
        this.state = 467;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.IMPORT:
            this.enterOuterAlt(localctx, 1);
            this.state = 465;
            this.import_name();
            break;
        case Python3Parser.FROM:
            this.enterOuterAlt(localctx, 2);
            this.state = 466;
            this.import_from();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Import_nameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_import_name;
    return this;
}

Import_nameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Import_nameContext.prototype.constructor = Import_nameContext;

Import_nameContext.prototype.IMPORT = function() {
    return this.getToken(Python3Parser.IMPORT, 0);
};

Import_nameContext.prototype.dotted_as_names = function() {
    return this.getTypedRuleContext(Dotted_as_namesContext,0);
};

Import_nameContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterImport_name(this);
	}
};

Import_nameContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitImport_name(this);
	}
};




Python3Parser.Import_nameContext = Import_nameContext;

Python3Parser.prototype.import_name = function() {

    var localctx = new Import_nameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 54, Python3Parser.RULE_import_name);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 469;
        this.match(Python3Parser.IMPORT);
        this.state = 470;
        this.dotted_as_names();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Import_fromContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_import_from;
    return this;
}

Import_fromContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Import_fromContext.prototype.constructor = Import_fromContext;

Import_fromContext.prototype.FROM = function() {
    return this.getToken(Python3Parser.FROM, 0);
};

Import_fromContext.prototype.IMPORT = function() {
    return this.getToken(Python3Parser.IMPORT, 0);
};

Import_fromContext.prototype.dotted_name = function() {
    return this.getTypedRuleContext(Dotted_nameContext,0);
};

Import_fromContext.prototype.import_as_names = function() {
    return this.getTypedRuleContext(Import_as_namesContext,0);
};

Import_fromContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterImport_from(this);
	}
};

Import_fromContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitImport_from(this);
	}
};




Python3Parser.Import_fromContext = Import_fromContext;

Python3Parser.prototype.import_from = function() {

    var localctx = new Import_fromContext(this, this._ctx, this.state);
    this.enterRule(localctx, 56, Python3Parser.RULE_import_from);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 472;
        this.match(Python3Parser.FROM);
        this.state = 485;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,58,this._ctx);
        switch(la_) {
        case 1:
            this.state = 476;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            while(_la===Python3Parser.DOT || _la===Python3Parser.ELLIPSIS) {
                this.state = 473;
                _la = this._input.LA(1);
                if(!(_la===Python3Parser.DOT || _la===Python3Parser.ELLIPSIS)) {
                this._errHandler.recoverInline(this);
                }
                else {
                	this._errHandler.reportMatch(this);
                    this.consume();
                }
                this.state = 478;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            }
            this.state = 479;
            this.dotted_name();
            break;

        case 2:
            this.state = 481; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 480;
                _la = this._input.LA(1);
                if(!(_la===Python3Parser.DOT || _la===Python3Parser.ELLIPSIS)) {
                this._errHandler.recoverInline(this);
                }
                else {
                	this._errHandler.reportMatch(this);
                    this.consume();
                }
                this.state = 483; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===Python3Parser.DOT || _la===Python3Parser.ELLIPSIS);
            break;

        }
        this.state = 487;
        this.match(Python3Parser.IMPORT);
        this.state = 494;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.STAR:
            this.state = 488;
            this.match(Python3Parser.STAR);
            break;
        case Python3Parser.OPEN_PAREN:
            this.state = 489;
            this.match(Python3Parser.OPEN_PAREN);
            this.state = 490;
            this.import_as_names();
            this.state = 491;
            this.match(Python3Parser.CLOSE_PAREN);
            break;
        case Python3Parser.NAME:
            this.state = 493;
            this.import_as_names();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Import_as_nameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_import_as_name;
    return this;
}

Import_as_nameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Import_as_nameContext.prototype.constructor = Import_as_nameContext;

Import_as_nameContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NAME);
    } else {
        return this.getToken(Python3Parser.NAME, i);
    }
};


Import_as_nameContext.prototype.AS = function() {
    return this.getToken(Python3Parser.AS, 0);
};

Import_as_nameContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterImport_as_name(this);
	}
};

Import_as_nameContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitImport_as_name(this);
	}
};




Python3Parser.Import_as_nameContext = Import_as_nameContext;

Python3Parser.prototype.import_as_name = function() {

    var localctx = new Import_as_nameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 58, Python3Parser.RULE_import_as_name);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 496;
        this.match(Python3Parser.NAME);
        this.state = 499;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.AS) {
            this.state = 497;
            this.match(Python3Parser.AS);
            this.state = 498;
            this.match(Python3Parser.NAME);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Dotted_as_nameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_dotted_as_name;
    return this;
}

Dotted_as_nameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Dotted_as_nameContext.prototype.constructor = Dotted_as_nameContext;

Dotted_as_nameContext.prototype.dotted_name = function() {
    return this.getTypedRuleContext(Dotted_nameContext,0);
};

Dotted_as_nameContext.prototype.AS = function() {
    return this.getToken(Python3Parser.AS, 0);
};

Dotted_as_nameContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

Dotted_as_nameContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDotted_as_name(this);
	}
};

Dotted_as_nameContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDotted_as_name(this);
	}
};




Python3Parser.Dotted_as_nameContext = Dotted_as_nameContext;

Python3Parser.prototype.dotted_as_name = function() {

    var localctx = new Dotted_as_nameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 60, Python3Parser.RULE_dotted_as_name);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 501;
        this.dotted_name();
        this.state = 504;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.AS) {
            this.state = 502;
            this.match(Python3Parser.AS);
            this.state = 503;
            this.match(Python3Parser.NAME);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Import_as_namesContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_import_as_names;
    return this;
}

Import_as_namesContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Import_as_namesContext.prototype.constructor = Import_as_namesContext;

Import_as_namesContext.prototype.import_as_name = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Import_as_nameContext);
    } else {
        return this.getTypedRuleContext(Import_as_nameContext,i);
    }
};

Import_as_namesContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterImport_as_names(this);
	}
};

Import_as_namesContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitImport_as_names(this);
	}
};




Python3Parser.Import_as_namesContext = Import_as_namesContext;

Python3Parser.prototype.import_as_names = function() {

    var localctx = new Import_as_namesContext(this, this._ctx, this.state);
    this.enterRule(localctx, 62, Python3Parser.RULE_import_as_names);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 506;
        this.import_as_name();
        this.state = 511;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,62,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 507;
                this.match(Python3Parser.COMMA);
                this.state = 508;
                this.import_as_name(); 
            }
            this.state = 513;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,62,this._ctx);
        }

        this.state = 515;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 514;
            this.match(Python3Parser.COMMA);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Dotted_as_namesContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_dotted_as_names;
    return this;
}

Dotted_as_namesContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Dotted_as_namesContext.prototype.constructor = Dotted_as_namesContext;

Dotted_as_namesContext.prototype.dotted_as_name = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Dotted_as_nameContext);
    } else {
        return this.getTypedRuleContext(Dotted_as_nameContext,i);
    }
};

Dotted_as_namesContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDotted_as_names(this);
	}
};

Dotted_as_namesContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDotted_as_names(this);
	}
};




Python3Parser.Dotted_as_namesContext = Dotted_as_namesContext;

Python3Parser.prototype.dotted_as_names = function() {

    var localctx = new Dotted_as_namesContext(this, this._ctx, this.state);
    this.enterRule(localctx, 64, Python3Parser.RULE_dotted_as_names);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 517;
        this.dotted_as_name();
        this.state = 522;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.COMMA) {
            this.state = 518;
            this.match(Python3Parser.COMMA);
            this.state = 519;
            this.dotted_as_name();
            this.state = 524;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Dotted_nameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_dotted_name;
    return this;
}

Dotted_nameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Dotted_nameContext.prototype.constructor = Dotted_nameContext;

Dotted_nameContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NAME);
    } else {
        return this.getToken(Python3Parser.NAME, i);
    }
};


Dotted_nameContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDotted_name(this);
	}
};

Dotted_nameContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDotted_name(this);
	}
};




Python3Parser.Dotted_nameContext = Dotted_nameContext;

Python3Parser.prototype.dotted_name = function() {

    var localctx = new Dotted_nameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 66, Python3Parser.RULE_dotted_name);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 525;
        this.match(Python3Parser.NAME);
        this.state = 530;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.DOT) {
            this.state = 526;
            this.match(Python3Parser.DOT);
            this.state = 527;
            this.match(Python3Parser.NAME);
            this.state = 532;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Global_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_global_stmt;
    return this;
}

Global_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Global_stmtContext.prototype.constructor = Global_stmtContext;

Global_stmtContext.prototype.GLOBAL = function() {
    return this.getToken(Python3Parser.GLOBAL, 0);
};

Global_stmtContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NAME);
    } else {
        return this.getToken(Python3Parser.NAME, i);
    }
};


Global_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterGlobal_stmt(this);
	}
};

Global_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitGlobal_stmt(this);
	}
};




Python3Parser.Global_stmtContext = Global_stmtContext;

Python3Parser.prototype.global_stmt = function() {

    var localctx = new Global_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 68, Python3Parser.RULE_global_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 533;
        this.match(Python3Parser.GLOBAL);
        this.state = 534;
        this.match(Python3Parser.NAME);
        this.state = 539;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.COMMA) {
            this.state = 535;
            this.match(Python3Parser.COMMA);
            this.state = 536;
            this.match(Python3Parser.NAME);
            this.state = 541;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Nonlocal_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_nonlocal_stmt;
    return this;
}

Nonlocal_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Nonlocal_stmtContext.prototype.constructor = Nonlocal_stmtContext;

Nonlocal_stmtContext.prototype.NONLOCAL = function() {
    return this.getToken(Python3Parser.NONLOCAL, 0);
};

Nonlocal_stmtContext.prototype.NAME = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.NAME);
    } else {
        return this.getToken(Python3Parser.NAME, i);
    }
};


Nonlocal_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterNonlocal_stmt(this);
	}
};

Nonlocal_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitNonlocal_stmt(this);
	}
};




Python3Parser.Nonlocal_stmtContext = Nonlocal_stmtContext;

Python3Parser.prototype.nonlocal_stmt = function() {

    var localctx = new Nonlocal_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 70, Python3Parser.RULE_nonlocal_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 542;
        this.match(Python3Parser.NONLOCAL);
        this.state = 543;
        this.match(Python3Parser.NAME);
        this.state = 548;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.COMMA) {
            this.state = 544;
            this.match(Python3Parser.COMMA);
            this.state = 545;
            this.match(Python3Parser.NAME);
            this.state = 550;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Assert_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_assert_stmt;
    return this;
}

Assert_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Assert_stmtContext.prototype.constructor = Assert_stmtContext;

Assert_stmtContext.prototype.ASSERT = function() {
    return this.getToken(Python3Parser.ASSERT, 0);
};

Assert_stmtContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

Assert_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterAssert_stmt(this);
	}
};

Assert_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitAssert_stmt(this);
	}
};




Python3Parser.Assert_stmtContext = Assert_stmtContext;

Python3Parser.prototype.assert_stmt = function() {

    var localctx = new Assert_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 72, Python3Parser.RULE_assert_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 551;
        this.match(Python3Parser.ASSERT);
        this.state = 552;
        this.test();
        this.state = 555;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 553;
            this.match(Python3Parser.COMMA);
            this.state = 554;
            this.test();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Compound_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_compound_stmt;
    return this;
}

Compound_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Compound_stmtContext.prototype.constructor = Compound_stmtContext;

Compound_stmtContext.prototype.if_stmt = function() {
    return this.getTypedRuleContext(If_stmtContext,0);
};

Compound_stmtContext.prototype.while_stmt = function() {
    return this.getTypedRuleContext(While_stmtContext,0);
};

Compound_stmtContext.prototype.for_stmt = function() {
    return this.getTypedRuleContext(For_stmtContext,0);
};

Compound_stmtContext.prototype.try_stmt = function() {
    return this.getTypedRuleContext(Try_stmtContext,0);
};

Compound_stmtContext.prototype.with_stmt = function() {
    return this.getTypedRuleContext(With_stmtContext,0);
};

Compound_stmtContext.prototype.funcdef = function() {
    return this.getTypedRuleContext(FuncdefContext,0);
};

Compound_stmtContext.prototype.classdef = function() {
    return this.getTypedRuleContext(ClassdefContext,0);
};

Compound_stmtContext.prototype.decorated = function() {
    return this.getTypedRuleContext(DecoratedContext,0);
};

Compound_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterCompound_stmt(this);
	}
};

Compound_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitCompound_stmt(this);
	}
};




Python3Parser.Compound_stmtContext = Compound_stmtContext;

Python3Parser.prototype.compound_stmt = function() {

    var localctx = new Compound_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 74, Python3Parser.RULE_compound_stmt);
    try {
        this.state = 565;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.IF:
            this.enterOuterAlt(localctx, 1);
            this.state = 557;
            this.if_stmt();
            break;
        case Python3Parser.WHILE:
            this.enterOuterAlt(localctx, 2);
            this.state = 558;
            this.while_stmt();
            break;
        case Python3Parser.FOR:
            this.enterOuterAlt(localctx, 3);
            this.state = 559;
            this.for_stmt();
            break;
        case Python3Parser.TRY:
            this.enterOuterAlt(localctx, 4);
            this.state = 560;
            this.try_stmt();
            break;
        case Python3Parser.WITH:
            this.enterOuterAlt(localctx, 5);
            this.state = 561;
            this.with_stmt();
            break;
        case Python3Parser.DEF:
            this.enterOuterAlt(localctx, 6);
            this.state = 562;
            this.funcdef();
            break;
        case Python3Parser.CLASS:
            this.enterOuterAlt(localctx, 7);
            this.state = 563;
            this.classdef();
            break;
        case Python3Parser.AT:
            this.enterOuterAlt(localctx, 8);
            this.state = 564;
            this.decorated();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function If_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_if_stmt;
    return this;
}

If_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
If_stmtContext.prototype.constructor = If_stmtContext;

If_stmtContext.prototype.IF = function() {
    return this.getToken(Python3Parser.IF, 0);
};

If_stmtContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

If_stmtContext.prototype.suite = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(SuiteContext);
    } else {
        return this.getTypedRuleContext(SuiteContext,i);
    }
};

If_stmtContext.prototype.ELIF = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.ELIF);
    } else {
        return this.getToken(Python3Parser.ELIF, i);
    }
};


If_stmtContext.prototype.ELSE = function() {
    return this.getToken(Python3Parser.ELSE, 0);
};

If_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterIf_stmt(this);
	}
};

If_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitIf_stmt(this);
	}
};




Python3Parser.If_stmtContext = If_stmtContext;

Python3Parser.prototype.if_stmt = function() {

    var localctx = new If_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 76, Python3Parser.RULE_if_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 567;
        this.match(Python3Parser.IF);
        this.state = 568;
        this.test();
        this.state = 569;
        this.match(Python3Parser.COLON);
        this.state = 570;
        this.suite();
        this.state = 578;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.ELIF) {
            this.state = 571;
            this.match(Python3Parser.ELIF);
            this.state = 572;
            this.test();
            this.state = 573;
            this.match(Python3Parser.COLON);
            this.state = 574;
            this.suite();
            this.state = 580;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 584;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.ELSE) {
            this.state = 581;
            this.match(Python3Parser.ELSE);
            this.state = 582;
            this.match(Python3Parser.COLON);
            this.state = 583;
            this.suite();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function While_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_while_stmt;
    return this;
}

While_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
While_stmtContext.prototype.constructor = While_stmtContext;

While_stmtContext.prototype.WHILE = function() {
    return this.getToken(Python3Parser.WHILE, 0);
};

While_stmtContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

While_stmtContext.prototype.suite = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(SuiteContext);
    } else {
        return this.getTypedRuleContext(SuiteContext,i);
    }
};

While_stmtContext.prototype.ELSE = function() {
    return this.getToken(Python3Parser.ELSE, 0);
};

While_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterWhile_stmt(this);
	}
};

While_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitWhile_stmt(this);
	}
};




Python3Parser.While_stmtContext = While_stmtContext;

Python3Parser.prototype.while_stmt = function() {

    var localctx = new While_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 78, Python3Parser.RULE_while_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 586;
        this.match(Python3Parser.WHILE);
        this.state = 587;
        this.test();
        this.state = 588;
        this.match(Python3Parser.COLON);
        this.state = 589;
        this.suite();
        this.state = 593;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.ELSE) {
            this.state = 590;
            this.match(Python3Parser.ELSE);
            this.state = 591;
            this.match(Python3Parser.COLON);
            this.state = 592;
            this.suite();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function For_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_for_stmt;
    return this;
}

For_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
For_stmtContext.prototype.constructor = For_stmtContext;

For_stmtContext.prototype.FOR = function() {
    return this.getToken(Python3Parser.FOR, 0);
};

For_stmtContext.prototype.exprlist = function() {
    return this.getTypedRuleContext(ExprlistContext,0);
};

For_stmtContext.prototype.IN = function() {
    return this.getToken(Python3Parser.IN, 0);
};

For_stmtContext.prototype.testlist = function() {
    return this.getTypedRuleContext(TestlistContext,0);
};

For_stmtContext.prototype.suite = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(SuiteContext);
    } else {
        return this.getTypedRuleContext(SuiteContext,i);
    }
};

For_stmtContext.prototype.ELSE = function() {
    return this.getToken(Python3Parser.ELSE, 0);
};

For_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterFor_stmt(this);
	}
};

For_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitFor_stmt(this);
	}
};




Python3Parser.For_stmtContext = For_stmtContext;

Python3Parser.prototype.for_stmt = function() {

    var localctx = new For_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 80, Python3Parser.RULE_for_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 595;
        this.match(Python3Parser.FOR);
        this.state = 596;
        this.exprlist();
        this.state = 597;
        this.match(Python3Parser.IN);
        this.state = 598;
        this.testlist();
        this.state = 599;
        this.match(Python3Parser.COLON);
        this.state = 600;
        this.suite();
        this.state = 604;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.ELSE) {
            this.state = 601;
            this.match(Python3Parser.ELSE);
            this.state = 602;
            this.match(Python3Parser.COLON);
            this.state = 603;
            this.suite();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Try_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_try_stmt;
    return this;
}

Try_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Try_stmtContext.prototype.constructor = Try_stmtContext;

Try_stmtContext.prototype.TRY = function() {
    return this.getToken(Python3Parser.TRY, 0);
};

Try_stmtContext.prototype.suite = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(SuiteContext);
    } else {
        return this.getTypedRuleContext(SuiteContext,i);
    }
};

Try_stmtContext.prototype.FINALLY = function() {
    return this.getToken(Python3Parser.FINALLY, 0);
};

Try_stmtContext.prototype.except_clause = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Except_clauseContext);
    } else {
        return this.getTypedRuleContext(Except_clauseContext,i);
    }
};

Try_stmtContext.prototype.ELSE = function() {
    return this.getToken(Python3Parser.ELSE, 0);
};

Try_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTry_stmt(this);
	}
};

Try_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTry_stmt(this);
	}
};




Python3Parser.Try_stmtContext = Try_stmtContext;

Python3Parser.prototype.try_stmt = function() {

    var localctx = new Try_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 82, Python3Parser.RULE_try_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 606;
        this.match(Python3Parser.TRY);
        this.state = 607;
        this.match(Python3Parser.COLON);
        this.state = 608;
        this.suite();
        this.state = 630;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.EXCEPT:
            this.state = 613; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 609;
                this.except_clause();
                this.state = 610;
                this.match(Python3Parser.COLON);
                this.state = 611;
                this.suite();
                this.state = 615; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===Python3Parser.EXCEPT);
            this.state = 620;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.ELSE) {
                this.state = 617;
                this.match(Python3Parser.ELSE);
                this.state = 618;
                this.match(Python3Parser.COLON);
                this.state = 619;
                this.suite();
            }

            this.state = 625;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.FINALLY) {
                this.state = 622;
                this.match(Python3Parser.FINALLY);
                this.state = 623;
                this.match(Python3Parser.COLON);
                this.state = 624;
                this.suite();
            }

            break;
        case Python3Parser.FINALLY:
            this.state = 627;
            this.match(Python3Parser.FINALLY);
            this.state = 628;
            this.match(Python3Parser.COLON);
            this.state = 629;
            this.suite();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function With_stmtContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_with_stmt;
    return this;
}

With_stmtContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
With_stmtContext.prototype.constructor = With_stmtContext;

With_stmtContext.prototype.WITH = function() {
    return this.getToken(Python3Parser.WITH, 0);
};

With_stmtContext.prototype.with_item = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(With_itemContext);
    } else {
        return this.getTypedRuleContext(With_itemContext,i);
    }
};

With_stmtContext.prototype.suite = function() {
    return this.getTypedRuleContext(SuiteContext,0);
};

With_stmtContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterWith_stmt(this);
	}
};

With_stmtContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitWith_stmt(this);
	}
};




Python3Parser.With_stmtContext = With_stmtContext;

Python3Parser.prototype.with_stmt = function() {

    var localctx = new With_stmtContext(this, this._ctx, this.state);
    this.enterRule(localctx, 84, Python3Parser.RULE_with_stmt);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 632;
        this.match(Python3Parser.WITH);
        this.state = 633;
        this.with_item();
        this.state = 638;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.COMMA) {
            this.state = 634;
            this.match(Python3Parser.COMMA);
            this.state = 635;
            this.with_item();
            this.state = 640;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 641;
        this.match(Python3Parser.COLON);
        this.state = 642;
        this.suite();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function With_itemContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_with_item;
    return this;
}

With_itemContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
With_itemContext.prototype.constructor = With_itemContext;

With_itemContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

With_itemContext.prototype.AS = function() {
    return this.getToken(Python3Parser.AS, 0);
};

With_itemContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

With_itemContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterWith_item(this);
	}
};

With_itemContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitWith_item(this);
	}
};




Python3Parser.With_itemContext = With_itemContext;

Python3Parser.prototype.with_item = function() {

    var localctx = new With_itemContext(this, this._ctx, this.state);
    this.enterRule(localctx, 86, Python3Parser.RULE_with_item);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 644;
        this.test();
        this.state = 647;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.AS) {
            this.state = 645;
            this.match(Python3Parser.AS);
            this.state = 646;
            this.expr();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Except_clauseContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_except_clause;
    return this;
}

Except_clauseContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Except_clauseContext.prototype.constructor = Except_clauseContext;

Except_clauseContext.prototype.EXCEPT = function() {
    return this.getToken(Python3Parser.EXCEPT, 0);
};

Except_clauseContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

Except_clauseContext.prototype.AS = function() {
    return this.getToken(Python3Parser.AS, 0);
};

Except_clauseContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

Except_clauseContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterExcept_clause(this);
	}
};

Except_clauseContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitExcept_clause(this);
	}
};




Python3Parser.Except_clauseContext = Except_clauseContext;

Python3Parser.prototype.except_clause = function() {

    var localctx = new Except_clauseContext(this, this._ctx, this.state);
    this.enterRule(localctx, 88, Python3Parser.RULE_except_clause);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 649;
        this.match(Python3Parser.EXCEPT);
        this.state = 655;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
            this.state = 650;
            this.test();
            this.state = 653;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.AS) {
                this.state = 651;
                this.match(Python3Parser.AS);
                this.state = 652;
                this.match(Python3Parser.NAME);
            }

        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SuiteContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_suite;
    return this;
}

SuiteContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SuiteContext.prototype.constructor = SuiteContext;

SuiteContext.prototype.simple_stmt = function() {
    return this.getTypedRuleContext(Simple_stmtContext,0);
};

SuiteContext.prototype.NEWLINE = function() {
    return this.getToken(Python3Parser.NEWLINE, 0);
};

SuiteContext.prototype.INDENT = function() {
    return this.getToken(Python3Parser.INDENT, 0);
};

SuiteContext.prototype.DEDENT = function() {
    return this.getToken(Python3Parser.DEDENT, 0);
};

SuiteContext.prototype.stmt = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StmtContext);
    } else {
        return this.getTypedRuleContext(StmtContext,i);
    }
};

SuiteContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSuite(this);
	}
};

SuiteContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSuite(this);
	}
};




Python3Parser.SuiteContext = SuiteContext;

Python3Parser.prototype.suite = function() {

    var localctx = new SuiteContext(this, this._ctx, this.state);
    this.enterRule(localctx, 90, Python3Parser.RULE_suite);
    var _la = 0; // Token type
    try {
        this.state = 667;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.RETURN:
        case Python3Parser.RAISE:
        case Python3Parser.FROM:
        case Python3Parser.IMPORT:
        case Python3Parser.GLOBAL:
        case Python3Parser.NONLOCAL:
        case Python3Parser.ASSERT:
        case Python3Parser.LAMBDA:
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.YIELD:
        case Python3Parser.DEL:
        case Python3Parser.PASS:
        case Python3Parser.CONTINUE:
        case Python3Parser.BREAK:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 1);
            this.state = 657;
            this.simple_stmt();
            break;
        case Python3Parser.NEWLINE:
            this.enterOuterAlt(localctx, 2);
            this.state = 658;
            this.match(Python3Parser.NEWLINE);
            this.state = 659;
            this.match(Python3Parser.INDENT);
            this.state = 661; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 660;
                this.stmt();
                this.state = 663; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << Python3Parser.DEF) | (1 << Python3Parser.RETURN) | (1 << Python3Parser.RAISE) | (1 << Python3Parser.FROM) | (1 << Python3Parser.IMPORT) | (1 << Python3Parser.GLOBAL) | (1 << Python3Parser.NONLOCAL) | (1 << Python3Parser.ASSERT) | (1 << Python3Parser.IF) | (1 << Python3Parser.WHILE) | (1 << Python3Parser.FOR) | (1 << Python3Parser.TRY) | (1 << Python3Parser.WITH) | (1 << Python3Parser.LAMBDA) | (1 << Python3Parser.NOT) | (1 << Python3Parser.NONE) | (1 << Python3Parser.TRUE) | (1 << Python3Parser.FALSE) | (1 << Python3Parser.CLASS) | (1 << Python3Parser.YIELD) | (1 << Python3Parser.DEL) | (1 << Python3Parser.PASS))) !== 0) || ((((_la - 32)) & ~0x1f) == 0 && ((1 << (_la - 32)) & ((1 << (Python3Parser.CONTINUE - 32)) | (1 << (Python3Parser.BREAK - 32)) | (1 << (Python3Parser.NAME - 32)) | (1 << (Python3Parser.STRING_LITERAL - 32)) | (1 << (Python3Parser.BYTES_LITERAL - 32)) | (1 << (Python3Parser.DECIMAL_INTEGER - 32)) | (1 << (Python3Parser.OCT_INTEGER - 32)) | (1 << (Python3Parser.HEX_INTEGER - 32)) | (1 << (Python3Parser.BIN_INTEGER - 32)) | (1 << (Python3Parser.FLOAT_NUMBER - 32)) | (1 << (Python3Parser.IMAG_NUMBER - 32)) | (1 << (Python3Parser.ELLIPSIS - 32)) | (1 << (Python3Parser.STAR - 32)) | (1 << (Python3Parser.OPEN_PAREN - 32)) | (1 << (Python3Parser.OPEN_BRACK - 32)) | (1 << (Python3Parser.ADD - 32)) | (1 << (Python3Parser.MINUS - 32)))) !== 0) || ((((_la - 66)) & ~0x1f) == 0 && ((1 << (_la - 66)) & ((1 << (Python3Parser.NOT_OP - 66)) | (1 << (Python3Parser.OPEN_BRACE - 66)) | (1 << (Python3Parser.AT - 66)))) !== 0));
            this.state = 665;
            this.match(Python3Parser.DEDENT);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TestContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_test;
    return this;
}

TestContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TestContext.prototype.constructor = TestContext;

TestContext.prototype.or_test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Or_testContext);
    } else {
        return this.getTypedRuleContext(Or_testContext,i);
    }
};

TestContext.prototype.IF = function() {
    return this.getToken(Python3Parser.IF, 0);
};

TestContext.prototype.ELSE = function() {
    return this.getToken(Python3Parser.ELSE, 0);
};

TestContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

TestContext.prototype.lambdef = function() {
    return this.getTypedRuleContext(LambdefContext,0);
};

TestContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTest(this);
	}
};

TestContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTest(this);
	}
};




Python3Parser.TestContext = TestContext;

Python3Parser.prototype.test = function() {

    var localctx = new TestContext(this, this._ctx, this.state);
    this.enterRule(localctx, 92, Python3Parser.RULE_test);
    var _la = 0; // Token type
    try {
        this.state = 678;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 1);
            this.state = 669;
            this.or_test();
            this.state = 675;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.IF) {
                this.state = 670;
                this.match(Python3Parser.IF);
                this.state = 671;
                this.or_test();
                this.state = 672;
                this.match(Python3Parser.ELSE);
                this.state = 673;
                this.test();
            }

            break;
        case Python3Parser.LAMBDA:
            this.enterOuterAlt(localctx, 2);
            this.state = 677;
            this.lambdef();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Test_nocondContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_test_nocond;
    return this;
}

Test_nocondContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Test_nocondContext.prototype.constructor = Test_nocondContext;

Test_nocondContext.prototype.or_test = function() {
    return this.getTypedRuleContext(Or_testContext,0);
};

Test_nocondContext.prototype.lambdef_nocond = function() {
    return this.getTypedRuleContext(Lambdef_nocondContext,0);
};

Test_nocondContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTest_nocond(this);
	}
};

Test_nocondContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTest_nocond(this);
	}
};




Python3Parser.Test_nocondContext = Test_nocondContext;

Python3Parser.prototype.test_nocond = function() {

    var localctx = new Test_nocondContext(this, this._ctx, this.state);
    this.enterRule(localctx, 94, Python3Parser.RULE_test_nocond);
    try {
        this.state = 682;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 1);
            this.state = 680;
            this.or_test();
            break;
        case Python3Parser.LAMBDA:
            this.enterOuterAlt(localctx, 2);
            this.state = 681;
            this.lambdef_nocond();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LambdefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_lambdef;
    return this;
}

LambdefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LambdefContext.prototype.constructor = LambdefContext;

LambdefContext.prototype.LAMBDA = function() {
    return this.getToken(Python3Parser.LAMBDA, 0);
};

LambdefContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

LambdefContext.prototype.varargslist = function() {
    return this.getTypedRuleContext(VarargslistContext,0);
};

LambdefContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterLambdef(this);
	}
};

LambdefContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitLambdef(this);
	}
};




Python3Parser.LambdefContext = LambdefContext;

Python3Parser.prototype.lambdef = function() {

    var localctx = new LambdefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 96, Python3Parser.RULE_lambdef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 684;
        this.match(Python3Parser.LAMBDA);
        this.state = 686;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (Python3Parser.NAME - 35)) | (1 << (Python3Parser.STAR - 35)) | (1 << (Python3Parser.POWER - 35)))) !== 0)) {
            this.state = 685;
            this.varargslist();
        }

        this.state = 688;
        this.match(Python3Parser.COLON);
        this.state = 689;
        this.test();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Lambdef_nocondContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_lambdef_nocond;
    return this;
}

Lambdef_nocondContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Lambdef_nocondContext.prototype.constructor = Lambdef_nocondContext;

Lambdef_nocondContext.prototype.LAMBDA = function() {
    return this.getToken(Python3Parser.LAMBDA, 0);
};

Lambdef_nocondContext.prototype.test_nocond = function() {
    return this.getTypedRuleContext(Test_nocondContext,0);
};

Lambdef_nocondContext.prototype.varargslist = function() {
    return this.getTypedRuleContext(VarargslistContext,0);
};

Lambdef_nocondContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterLambdef_nocond(this);
	}
};

Lambdef_nocondContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitLambdef_nocond(this);
	}
};




Python3Parser.Lambdef_nocondContext = Lambdef_nocondContext;

Python3Parser.prototype.lambdef_nocond = function() {

    var localctx = new Lambdef_nocondContext(this, this._ctx, this.state);
    this.enterRule(localctx, 98, Python3Parser.RULE_lambdef_nocond);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 691;
        this.match(Python3Parser.LAMBDA);
        this.state = 693;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 35)) & ~0x1f) == 0 && ((1 << (_la - 35)) & ((1 << (Python3Parser.NAME - 35)) | (1 << (Python3Parser.STAR - 35)) | (1 << (Python3Parser.POWER - 35)))) !== 0)) {
            this.state = 692;
            this.varargslist();
        }

        this.state = 695;
        this.match(Python3Parser.COLON);
        this.state = 696;
        this.test_nocond();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Or_testContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_or_test;
    return this;
}

Or_testContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Or_testContext.prototype.constructor = Or_testContext;

Or_testContext.prototype.and_test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(And_testContext);
    } else {
        return this.getTypedRuleContext(And_testContext,i);
    }
};

Or_testContext.prototype.OR = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.OR);
    } else {
        return this.getToken(Python3Parser.OR, i);
    }
};


Or_testContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterOr_test(this);
	}
};

Or_testContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitOr_test(this);
	}
};




Python3Parser.Or_testContext = Or_testContext;

Python3Parser.prototype.or_test = function() {

    var localctx = new Or_testContext(this, this._ctx, this.state);
    this.enterRule(localctx, 100, Python3Parser.RULE_or_test);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 698;
        this.and_test();
        this.state = 703;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.OR) {
            this.state = 699;
            this.match(Python3Parser.OR);
            this.state = 700;
            this.and_test();
            this.state = 705;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function And_testContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_and_test;
    return this;
}

And_testContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
And_testContext.prototype.constructor = And_testContext;

And_testContext.prototype.not_test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Not_testContext);
    } else {
        return this.getTypedRuleContext(Not_testContext,i);
    }
};

And_testContext.prototype.AND = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(Python3Parser.AND);
    } else {
        return this.getToken(Python3Parser.AND, i);
    }
};


And_testContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterAnd_test(this);
	}
};

And_testContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitAnd_test(this);
	}
};




Python3Parser.And_testContext = And_testContext;

Python3Parser.prototype.and_test = function() {

    var localctx = new And_testContext(this, this._ctx, this.state);
    this.enterRule(localctx, 102, Python3Parser.RULE_and_test);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 706;
        this.not_test();
        this.state = 711;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.AND) {
            this.state = 707;
            this.match(Python3Parser.AND);
            this.state = 708;
            this.not_test();
            this.state = 713;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Not_testContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_not_test;
    return this;
}

Not_testContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Not_testContext.prototype.constructor = Not_testContext;

Not_testContext.prototype.NOT = function() {
    return this.getToken(Python3Parser.NOT, 0);
};

Not_testContext.prototype.not_test = function() {
    return this.getTypedRuleContext(Not_testContext,0);
};

Not_testContext.prototype.comparison = function() {
    return this.getTypedRuleContext(ComparisonContext,0);
};

Not_testContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterNot_test(this);
	}
};

Not_testContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitNot_test(this);
	}
};




Python3Parser.Not_testContext = Not_testContext;

Python3Parser.prototype.not_test = function() {

    var localctx = new Not_testContext(this, this._ctx, this.state);
    this.enterRule(localctx, 104, Python3Parser.RULE_not_test);
    try {
        this.state = 717;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.NOT:
            this.enterOuterAlt(localctx, 1);
            this.state = 714;
            this.match(Python3Parser.NOT);
            this.state = 715;
            this.not_test();
            break;
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 2);
            this.state = 716;
            this.comparison();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ComparisonContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_comparison;
    return this;
}

ComparisonContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComparisonContext.prototype.constructor = ComparisonContext;

ComparisonContext.prototype.star_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Star_exprContext);
    } else {
        return this.getTypedRuleContext(Star_exprContext,i);
    }
};

ComparisonContext.prototype.comp_op = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Comp_opContext);
    } else {
        return this.getTypedRuleContext(Comp_opContext,i);
    }
};

ComparisonContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterComparison(this);
	}
};

ComparisonContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitComparison(this);
	}
};




Python3Parser.ComparisonContext = ComparisonContext;

Python3Parser.prototype.comparison = function() {

    var localctx = new ComparisonContext(this, this._ctx, this.state);
    this.enterRule(localctx, 106, Python3Parser.RULE_comparison);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 719;
        this.star_expr();
        this.state = 725;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << Python3Parser.IN) | (1 << Python3Parser.NOT) | (1 << Python3Parser.IS))) !== 0) || ((((_la - 69)) & ~0x1f) == 0 && ((1 << (_la - 69)) & ((1 << (Python3Parser.LESS_THAN - 69)) | (1 << (Python3Parser.GREATER_THAN - 69)) | (1 << (Python3Parser.EQUALS - 69)) | (1 << (Python3Parser.GT_EQ - 69)) | (1 << (Python3Parser.LT_EQ - 69)) | (1 << (Python3Parser.NOT_EQ_1 - 69)) | (1 << (Python3Parser.NOT_EQ_2 - 69)))) !== 0)) {
            this.state = 720;
            this.comp_op();
            this.state = 721;
            this.star_expr();
            this.state = 727;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Comp_opContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_comp_op;
    return this;
}

Comp_opContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Comp_opContext.prototype.constructor = Comp_opContext;

Comp_opContext.prototype.IN = function() {
    return this.getToken(Python3Parser.IN, 0);
};

Comp_opContext.prototype.NOT = function() {
    return this.getToken(Python3Parser.NOT, 0);
};

Comp_opContext.prototype.IS = function() {
    return this.getToken(Python3Parser.IS, 0);
};

Comp_opContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterComp_op(this);
	}
};

Comp_opContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitComp_op(this);
	}
};




Python3Parser.Comp_opContext = Comp_opContext;

Python3Parser.prototype.comp_op = function() {

    var localctx = new Comp_opContext(this, this._ctx, this.state);
    this.enterRule(localctx, 108, Python3Parser.RULE_comp_op);
    try {
        this.state = 741;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,93,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 728;
            this.match(Python3Parser.LESS_THAN);
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 729;
            this.match(Python3Parser.GREATER_THAN);
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 730;
            this.match(Python3Parser.EQUALS);
            break;

        case 4:
            this.enterOuterAlt(localctx, 4);
            this.state = 731;
            this.match(Python3Parser.GT_EQ);
            break;

        case 5:
            this.enterOuterAlt(localctx, 5);
            this.state = 732;
            this.match(Python3Parser.LT_EQ);
            break;

        case 6:
            this.enterOuterAlt(localctx, 6);
            this.state = 733;
            this.match(Python3Parser.NOT_EQ_1);
            break;

        case 7:
            this.enterOuterAlt(localctx, 7);
            this.state = 734;
            this.match(Python3Parser.NOT_EQ_2);
            break;

        case 8:
            this.enterOuterAlt(localctx, 8);
            this.state = 735;
            this.match(Python3Parser.IN);
            break;

        case 9:
            this.enterOuterAlt(localctx, 9);
            this.state = 736;
            this.match(Python3Parser.NOT);
            this.state = 737;
            this.match(Python3Parser.IN);
            break;

        case 10:
            this.enterOuterAlt(localctx, 10);
            this.state = 738;
            this.match(Python3Parser.IS);
            break;

        case 11:
            this.enterOuterAlt(localctx, 11);
            this.state = 739;
            this.match(Python3Parser.IS);
            this.state = 740;
            this.match(Python3Parser.NOT);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Star_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_star_expr;
    return this;
}

Star_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Star_exprContext.prototype.constructor = Star_exprContext;

Star_exprContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

Star_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterStar_expr(this);
	}
};

Star_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitStar_expr(this);
	}
};




Python3Parser.Star_exprContext = Star_exprContext;

Python3Parser.prototype.star_expr = function() {

    var localctx = new Star_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 110, Python3Parser.RULE_star_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 744;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.STAR) {
            this.state = 743;
            this.match(Python3Parser.STAR);
        }

        this.state = 746;
        this.expr();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_expr;
    return this;
}

ExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprContext.prototype.constructor = ExprContext;

ExprContext.prototype.xor_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Xor_exprContext);
    } else {
        return this.getTypedRuleContext(Xor_exprContext,i);
    }
};

ExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterExpr(this);
	}
};

ExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitExpr(this);
	}
};




Python3Parser.ExprContext = ExprContext;

Python3Parser.prototype.expr = function() {

    var localctx = new ExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 112, Python3Parser.RULE_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 748;
        this.xor_expr();
        this.state = 753;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.OR_OP) {
            this.state = 749;
            this.match(Python3Parser.OR_OP);
            this.state = 750;
            this.xor_expr();
            this.state = 755;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Xor_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_xor_expr;
    return this;
}

Xor_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Xor_exprContext.prototype.constructor = Xor_exprContext;

Xor_exprContext.prototype.and_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(And_exprContext);
    } else {
        return this.getTypedRuleContext(And_exprContext,i);
    }
};

Xor_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterXor_expr(this);
	}
};

Xor_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitXor_expr(this);
	}
};




Python3Parser.Xor_exprContext = Xor_exprContext;

Python3Parser.prototype.xor_expr = function() {

    var localctx = new Xor_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 114, Python3Parser.RULE_xor_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 756;
        this.and_expr();
        this.state = 761;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.XOR) {
            this.state = 757;
            this.match(Python3Parser.XOR);
            this.state = 758;
            this.and_expr();
            this.state = 763;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function And_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_and_expr;
    return this;
}

And_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
And_exprContext.prototype.constructor = And_exprContext;

And_exprContext.prototype.shift_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Shift_exprContext);
    } else {
        return this.getTypedRuleContext(Shift_exprContext,i);
    }
};

And_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterAnd_expr(this);
	}
};

And_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitAnd_expr(this);
	}
};




Python3Parser.And_exprContext = And_exprContext;

Python3Parser.prototype.and_expr = function() {

    var localctx = new And_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 116, Python3Parser.RULE_and_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 764;
        this.shift_expr();
        this.state = 769;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.AND_OP) {
            this.state = 765;
            this.match(Python3Parser.AND_OP);
            this.state = 766;
            this.shift_expr();
            this.state = 771;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Shift_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_shift_expr;
    return this;
}

Shift_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Shift_exprContext.prototype.constructor = Shift_exprContext;

Shift_exprContext.prototype.arith_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Arith_exprContext);
    } else {
        return this.getTypedRuleContext(Arith_exprContext,i);
    }
};

Shift_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterShift_expr(this);
	}
};

Shift_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitShift_expr(this);
	}
};




Python3Parser.Shift_exprContext = Shift_exprContext;

Python3Parser.prototype.shift_expr = function() {

    var localctx = new Shift_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 118, Python3Parser.RULE_shift_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 772;
        this.arith_expr();
        this.state = 779;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.LEFT_SHIFT || _la===Python3Parser.RIGHT_SHIFT) {
            this.state = 777;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.LEFT_SHIFT:
                this.state = 773;
                this.match(Python3Parser.LEFT_SHIFT);
                this.state = 774;
                this.arith_expr();
                break;
            case Python3Parser.RIGHT_SHIFT:
                this.state = 775;
                this.match(Python3Parser.RIGHT_SHIFT);
                this.state = 776;
                this.arith_expr();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            this.state = 781;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Arith_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_arith_expr;
    return this;
}

Arith_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Arith_exprContext.prototype.constructor = Arith_exprContext;

Arith_exprContext.prototype.term = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TermContext);
    } else {
        return this.getTypedRuleContext(TermContext,i);
    }
};

Arith_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterArith_expr(this);
	}
};

Arith_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitArith_expr(this);
	}
};




Python3Parser.Arith_exprContext = Arith_exprContext;

Python3Parser.prototype.arith_expr = function() {

    var localctx = new Arith_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 120, Python3Parser.RULE_arith_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 782;
        this.term();
        this.state = 789;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===Python3Parser.ADD || _la===Python3Parser.MINUS) {
            this.state = 787;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.ADD:
                this.state = 783;
                this.match(Python3Parser.ADD);
                this.state = 784;
                this.term();
                break;
            case Python3Parser.MINUS:
                this.state = 785;
                this.match(Python3Parser.MINUS);
                this.state = 786;
                this.term();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            this.state = 791;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TermContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_term;
    return this;
}

TermContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TermContext.prototype.constructor = TermContext;

TermContext.prototype.factor = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(FactorContext);
    } else {
        return this.getTypedRuleContext(FactorContext,i);
    }
};

TermContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTerm(this);
	}
};

TermContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTerm(this);
	}
};




Python3Parser.TermContext = TermContext;

Python3Parser.prototype.term = function() {

    var localctx = new TermContext(this, this._ctx, this.state);
    this.enterRule(localctx, 122, Python3Parser.RULE_term);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 792;
        this.factor();
        this.state = 805;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(((((_la - 46)) & ~0x1f) == 0 && ((1 << (_la - 46)) & ((1 << (Python3Parser.STAR - 46)) | (1 << (Python3Parser.DIV - 46)) | (1 << (Python3Parser.MOD - 46)) | (1 << (Python3Parser.IDIV - 46)) | (1 << (Python3Parser.AT - 46)))) !== 0)) {
            this.state = 803;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.STAR:
                this.state = 793;
                this.match(Python3Parser.STAR);
                this.state = 794;
                this.factor();
                break;
            case Python3Parser.DIV:
                this.state = 795;
                this.match(Python3Parser.DIV);
                this.state = 796;
                this.factor();
                break;
            case Python3Parser.MOD:
                this.state = 797;
                this.match(Python3Parser.MOD);
                this.state = 798;
                this.factor();
                break;
            case Python3Parser.IDIV:
                this.state = 799;
                this.match(Python3Parser.IDIV);
                this.state = 800;
                this.factor();
                break;
            case Python3Parser.AT:
                this.state = 801;
                this.match(Python3Parser.AT);
                this.state = 802;
                this.factor();
                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            this.state = 807;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function FactorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_factor;
    return this;
}

FactorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FactorContext.prototype.constructor = FactorContext;

FactorContext.prototype.factor = function() {
    return this.getTypedRuleContext(FactorContext,0);
};

FactorContext.prototype.power = function() {
    return this.getTypedRuleContext(PowerContext,0);
};

FactorContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterFactor(this);
	}
};

FactorContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitFactor(this);
	}
};




Python3Parser.FactorContext = FactorContext;

Python3Parser.prototype.factor = function() {

    var localctx = new FactorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 124, Python3Parser.RULE_factor);
    try {
        this.state = 815;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.ADD:
            this.enterOuterAlt(localctx, 1);
            this.state = 808;
            this.match(Python3Parser.ADD);
            this.state = 809;
            this.factor();
            break;
        case Python3Parser.MINUS:
            this.enterOuterAlt(localctx, 2);
            this.state = 810;
            this.match(Python3Parser.MINUS);
            this.state = 811;
            this.factor();
            break;
        case Python3Parser.NOT_OP:
            this.enterOuterAlt(localctx, 3);
            this.state = 812;
            this.match(Python3Parser.NOT_OP);
            this.state = 813;
            this.factor();
            break;
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 4);
            this.state = 814;
            this.power();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PowerContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_power;
    return this;
}

PowerContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PowerContext.prototype.constructor = PowerContext;

PowerContext.prototype.atom = function() {
    return this.getTypedRuleContext(AtomContext,0);
};

PowerContext.prototype.trailer = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TrailerContext);
    } else {
        return this.getTypedRuleContext(TrailerContext,i);
    }
};

PowerContext.prototype.factor = function() {
    return this.getTypedRuleContext(FactorContext,0);
};

PowerContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterPower(this);
	}
};

PowerContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitPower(this);
	}
};




Python3Parser.PowerContext = PowerContext;

Python3Parser.prototype.power = function() {

    var localctx = new PowerContext(this, this._ctx, this.state);
    this.enterRule(localctx, 126, Python3Parser.RULE_power);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 817;
        this.atom();
        this.state = 821;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(((((_la - 44)) & ~0x1f) == 0 && ((1 << (_la - 44)) & ((1 << (Python3Parser.DOT - 44)) | (1 << (Python3Parser.OPEN_PAREN - 44)) | (1 << (Python3Parser.OPEN_BRACK - 44)))) !== 0)) {
            this.state = 818;
            this.trailer();
            this.state = 823;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 826;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.POWER) {
            this.state = 824;
            this.match(Python3Parser.POWER);
            this.state = 825;
            this.factor();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AtomContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_atom;
    return this;
}

AtomContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AtomContext.prototype.constructor = AtomContext;

AtomContext.prototype.yield_expr = function() {
    return this.getTypedRuleContext(Yield_exprContext,0);
};

AtomContext.prototype.testlist_comp = function() {
    return this.getTypedRuleContext(Testlist_compContext,0);
};

AtomContext.prototype.dictorsetmaker = function() {
    return this.getTypedRuleContext(DictorsetmakerContext,0);
};

AtomContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

AtomContext.prototype.number = function() {
    return this.getTypedRuleContext(NumberContext,0);
};

AtomContext.prototype.str = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StrContext);
    } else {
        return this.getTypedRuleContext(StrContext,i);
    }
};

AtomContext.prototype.NONE = function() {
    return this.getToken(Python3Parser.NONE, 0);
};

AtomContext.prototype.TRUE = function() {
    return this.getToken(Python3Parser.TRUE, 0);
};

AtomContext.prototype.FALSE = function() {
    return this.getToken(Python3Parser.FALSE, 0);
};

AtomContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterAtom(this);
	}
};

AtomContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitAtom(this);
	}
};




Python3Parser.AtomContext = AtomContext;

Python3Parser.prototype.atom = function() {

    var localctx = new AtomContext(this, this._ctx, this.state);
    this.enterRule(localctx, 128, Python3Parser.RULE_atom);
    var _la = 0; // Token type
    try {
        this.state = 855;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.OPEN_PAREN:
            this.enterOuterAlt(localctx, 1);
            this.state = 828;
            this.match(Python3Parser.OPEN_PAREN);
            this.state = 831;
            this._errHandler.sync(this);
            switch (this._input.LA(1)) {
            case Python3Parser.YIELD:
            	this.state = 829;
            	this.yield_expr();
            	break;
            case Python3Parser.LAMBDA:
            case Python3Parser.NOT:
            case Python3Parser.NONE:
            case Python3Parser.TRUE:
            case Python3Parser.FALSE:
            case Python3Parser.NAME:
            case Python3Parser.STRING_LITERAL:
            case Python3Parser.BYTES_LITERAL:
            case Python3Parser.DECIMAL_INTEGER:
            case Python3Parser.OCT_INTEGER:
            case Python3Parser.HEX_INTEGER:
            case Python3Parser.BIN_INTEGER:
            case Python3Parser.FLOAT_NUMBER:
            case Python3Parser.IMAG_NUMBER:
            case Python3Parser.ELLIPSIS:
            case Python3Parser.STAR:
            case Python3Parser.OPEN_PAREN:
            case Python3Parser.OPEN_BRACK:
            case Python3Parser.ADD:
            case Python3Parser.MINUS:
            case Python3Parser.NOT_OP:
            case Python3Parser.OPEN_BRACE:
            	this.state = 830;
            	this.testlist_comp();
            	break;
            case Python3Parser.CLOSE_PAREN:
            	break;
            default:
            	break;
            }
            this.state = 833;
            this.match(Python3Parser.CLOSE_PAREN);
            break;
        case Python3Parser.OPEN_BRACK:
            this.enterOuterAlt(localctx, 2);
            this.state = 834;
            this.match(Python3Parser.OPEN_BRACK);
            this.state = 836;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
                this.state = 835;
                this.testlist_comp();
            }

            this.state = 838;
            this.match(Python3Parser.CLOSE_BRACK);
            break;
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 3);
            this.state = 839;
            this.match(Python3Parser.OPEN_BRACE);
            this.state = 841;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
                this.state = 840;
                this.dictorsetmaker();
            }

            this.state = 843;
            this.match(Python3Parser.CLOSE_BRACE);
            break;
        case Python3Parser.NAME:
            this.enterOuterAlt(localctx, 4);
            this.state = 844;
            this.match(Python3Parser.NAME);
            break;
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
            this.enterOuterAlt(localctx, 5);
            this.state = 845;
            this.number();
            break;
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
            this.enterOuterAlt(localctx, 6);
            this.state = 847; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            do {
                this.state = 846;
                this.str();
                this.state = 849; 
                this._errHandler.sync(this);
                _la = this._input.LA(1);
            } while(_la===Python3Parser.STRING_LITERAL || _la===Python3Parser.BYTES_LITERAL);
            break;
        case Python3Parser.ELLIPSIS:
            this.enterOuterAlt(localctx, 7);
            this.state = 851;
            this.match(Python3Parser.ELLIPSIS);
            break;
        case Python3Parser.NONE:
            this.enterOuterAlt(localctx, 8);
            this.state = 852;
            this.match(Python3Parser.NONE);
            break;
        case Python3Parser.TRUE:
            this.enterOuterAlt(localctx, 9);
            this.state = 853;
            this.match(Python3Parser.TRUE);
            break;
        case Python3Parser.FALSE:
            this.enterOuterAlt(localctx, 10);
            this.state = 854;
            this.match(Python3Parser.FALSE);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Testlist_compContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_testlist_comp;
    return this;
}

Testlist_compContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Testlist_compContext.prototype.constructor = Testlist_compContext;

Testlist_compContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

Testlist_compContext.prototype.comp_for = function() {
    return this.getTypedRuleContext(Comp_forContext,0);
};

Testlist_compContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTestlist_comp(this);
	}
};

Testlist_compContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTestlist_comp(this);
	}
};




Python3Parser.Testlist_compContext = Testlist_compContext;

Python3Parser.prototype.testlist_comp = function() {

    var localctx = new Testlist_compContext(this, this._ctx, this.state);
    this.enterRule(localctx, 130, Python3Parser.RULE_testlist_comp);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 857;
        this.test();
        this.state = 869;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.FOR:
            this.state = 858;
            this.comp_for();
            break;
        case Python3Parser.CLOSE_PAREN:
        case Python3Parser.COMMA:
        case Python3Parser.CLOSE_BRACK:
            this.state = 863;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,112,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 859;
                    this.match(Python3Parser.COMMA);
                    this.state = 860;
                    this.test(); 
                }
                this.state = 865;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,112,this._ctx);
            }

            this.state = 867;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 866;
                this.match(Python3Parser.COMMA);
            }

            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TrailerContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_trailer;
    return this;
}

TrailerContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TrailerContext.prototype.constructor = TrailerContext;

TrailerContext.prototype.arglist = function() {
    return this.getTypedRuleContext(ArglistContext,0);
};

TrailerContext.prototype.subscriptlist = function() {
    return this.getTypedRuleContext(SubscriptlistContext,0);
};

TrailerContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

TrailerContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTrailer(this);
	}
};

TrailerContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTrailer(this);
	}
};




Python3Parser.TrailerContext = TrailerContext;

Python3Parser.prototype.trailer = function() {

    var localctx = new TrailerContext(this, this._ctx, this.state);
    this.enterRule(localctx, 132, Python3Parser.RULE_trailer);
    var _la = 0; // Token type
    try {
        this.state = 882;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.OPEN_PAREN:
            this.enterOuterAlt(localctx, 1);
            this.state = 871;
            this.match(Python3Parser.OPEN_PAREN);
            this.state = 873;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 52)) & ~0x1f) == 0 && ((1 << (_la - 52)) & ((1 << (Python3Parser.POWER - 52)) | (1 << (Python3Parser.OPEN_BRACK - 52)) | (1 << (Python3Parser.ADD - 52)) | (1 << (Python3Parser.MINUS - 52)) | (1 << (Python3Parser.NOT_OP - 52)) | (1 << (Python3Parser.OPEN_BRACE - 52)))) !== 0)) {
                this.state = 872;
                this.arglist();
            }

            this.state = 875;
            this.match(Python3Parser.CLOSE_PAREN);
            break;
        case Python3Parser.OPEN_BRACK:
            this.enterOuterAlt(localctx, 2);
            this.state = 876;
            this.match(Python3Parser.OPEN_BRACK);
            this.state = 877;
            this.subscriptlist();
            this.state = 878;
            this.match(Python3Parser.CLOSE_BRACK);
            break;
        case Python3Parser.DOT:
            this.enterOuterAlt(localctx, 3);
            this.state = 880;
            this.match(Python3Parser.DOT);
            this.state = 881;
            this.match(Python3Parser.NAME);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SubscriptlistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_subscriptlist;
    return this;
}

SubscriptlistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SubscriptlistContext.prototype.constructor = SubscriptlistContext;

SubscriptlistContext.prototype.subscript = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(SubscriptContext);
    } else {
        return this.getTypedRuleContext(SubscriptContext,i);
    }
};

SubscriptlistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSubscriptlist(this);
	}
};

SubscriptlistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSubscriptlist(this);
	}
};




Python3Parser.SubscriptlistContext = SubscriptlistContext;

Python3Parser.prototype.subscriptlist = function() {

    var localctx = new SubscriptlistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 134, Python3Parser.RULE_subscriptlist);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 884;
        this.subscript();
        this.state = 889;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,117,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 885;
                this.match(Python3Parser.COMMA);
                this.state = 886;
                this.subscript(); 
            }
            this.state = 891;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,117,this._ctx);
        }

        this.state = 893;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 892;
            this.match(Python3Parser.COMMA);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SubscriptContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_subscript;
    return this;
}

SubscriptContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SubscriptContext.prototype.constructor = SubscriptContext;

SubscriptContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

SubscriptContext.prototype.sliceop = function() {
    return this.getTypedRuleContext(SliceopContext,0);
};

SubscriptContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSubscript(this);
	}
};

SubscriptContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSubscript(this);
	}
};




Python3Parser.SubscriptContext = SubscriptContext;

Python3Parser.prototype.subscript = function() {

    var localctx = new SubscriptContext(this, this._ctx, this.state);
    this.enterRule(localctx, 136, Python3Parser.RULE_subscript);
    var _la = 0; // Token type
    try {
        this.state = 906;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,122,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 895;
            this.test();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 897;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
                this.state = 896;
                this.test();
            }

            this.state = 899;
            this.match(Python3Parser.COLON);
            this.state = 901;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
                this.state = 900;
                this.test();
            }

            this.state = 904;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COLON) {
                this.state = 903;
                this.sliceop();
            }

            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SliceopContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_sliceop;
    return this;
}

SliceopContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SliceopContext.prototype.constructor = SliceopContext;

SliceopContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

SliceopContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterSliceop(this);
	}
};

SliceopContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitSliceop(this);
	}
};




Python3Parser.SliceopContext = SliceopContext;

Python3Parser.prototype.sliceop = function() {

    var localctx = new SliceopContext(this, this._ctx, this.state);
    this.enterRule(localctx, 138, Python3Parser.RULE_sliceop);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 908;
        this.match(Python3Parser.COLON);
        this.state = 910;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 54)) & ~0x1f) == 0 && ((1 << (_la - 54)) & ((1 << (Python3Parser.OPEN_BRACK - 54)) | (1 << (Python3Parser.ADD - 54)) | (1 << (Python3Parser.MINUS - 54)) | (1 << (Python3Parser.NOT_OP - 54)) | (1 << (Python3Parser.OPEN_BRACE - 54)))) !== 0)) {
            this.state = 909;
            this.test();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ExprlistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_exprlist;
    return this;
}

ExprlistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprlistContext.prototype.constructor = ExprlistContext;

ExprlistContext.prototype.star_expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(Star_exprContext);
    } else {
        return this.getTypedRuleContext(Star_exprContext,i);
    }
};

ExprlistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterExprlist(this);
	}
};

ExprlistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitExprlist(this);
	}
};




Python3Parser.ExprlistContext = ExprlistContext;

Python3Parser.prototype.exprlist = function() {

    var localctx = new ExprlistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 140, Python3Parser.RULE_exprlist);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 912;
        this.star_expr();
        this.state = 917;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,124,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 913;
                this.match(Python3Parser.COMMA);
                this.state = 914;
                this.star_expr(); 
            }
            this.state = 919;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,124,this._ctx);
        }

        this.state = 921;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 920;
            this.match(Python3Parser.COMMA);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function TestlistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_testlist;
    return this;
}

TestlistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TestlistContext.prototype.constructor = TestlistContext;

TestlistContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

TestlistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterTestlist(this);
	}
};

TestlistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitTestlist(this);
	}
};




Python3Parser.TestlistContext = TestlistContext;

Python3Parser.prototype.testlist = function() {

    var localctx = new TestlistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 142, Python3Parser.RULE_testlist);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 923;
        this.test();
        this.state = 928;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,126,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 924;
                this.match(Python3Parser.COMMA);
                this.state = 925;
                this.test(); 
            }
            this.state = 930;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,126,this._ctx);
        }

        this.state = 932;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.COMMA) {
            this.state = 931;
            this.match(Python3Parser.COMMA);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DictorsetmakerContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_dictorsetmaker;
    return this;
}

DictorsetmakerContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DictorsetmakerContext.prototype.constructor = DictorsetmakerContext;

DictorsetmakerContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

DictorsetmakerContext.prototype.comp_for = function() {
    return this.getTypedRuleContext(Comp_forContext,0);
};

DictorsetmakerContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterDictorsetmaker(this);
	}
};

DictorsetmakerContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitDictorsetmaker(this);
	}
};




Python3Parser.DictorsetmakerContext = DictorsetmakerContext;

Python3Parser.prototype.dictorsetmaker = function() {

    var localctx = new DictorsetmakerContext(this, this._ctx, this.state);
    this.enterRule(localctx, 144, Python3Parser.RULE_dictorsetmaker);
    var _la = 0; // Token type
    try {
        this.state = 967;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,134,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 934;
            this.test();
            this.state = 935;
            this.match(Python3Parser.COLON);
            this.state = 936;
            this.test();
            this.state = 951;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.FOR:
                this.state = 937;
                this.comp_for();
                break;
            case Python3Parser.COMMA:
            case Python3Parser.CLOSE_BRACE:
                this.state = 945;
                this._errHandler.sync(this);
                var _alt = this._interp.adaptivePredict(this._input,128,this._ctx)
                while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                    if(_alt===1) {
                        this.state = 938;
                        this.match(Python3Parser.COMMA);
                        this.state = 939;
                        this.test();
                        this.state = 940;
                        this.match(Python3Parser.COLON);
                        this.state = 941;
                        this.test(); 
                    }
                    this.state = 947;
                    this._errHandler.sync(this);
                    _alt = this._interp.adaptivePredict(this._input,128,this._ctx);
                }

                this.state = 949;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                if(_la===Python3Parser.COMMA) {
                    this.state = 948;
                    this.match(Python3Parser.COMMA);
                }

                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 953;
            this.test();
            this.state = 965;
            this._errHandler.sync(this);
            switch(this._input.LA(1)) {
            case Python3Parser.FOR:
                this.state = 954;
                this.comp_for();
                break;
            case Python3Parser.COMMA:
            case Python3Parser.CLOSE_BRACE:
                this.state = 959;
                this._errHandler.sync(this);
                var _alt = this._interp.adaptivePredict(this._input,131,this._ctx)
                while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                    if(_alt===1) {
                        this.state = 955;
                        this.match(Python3Parser.COMMA);
                        this.state = 956;
                        this.test(); 
                    }
                    this.state = 961;
                    this._errHandler.sync(this);
                    _alt = this._interp.adaptivePredict(this._input,131,this._ctx);
                }

                this.state = 963;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
                if(_la===Python3Parser.COMMA) {
                    this.state = 962;
                    this.match(Python3Parser.COMMA);
                }

                break;
            default:
                throw new antlr4.error.NoViableAltException(this);
            }
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ClassdefContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_classdef;
    return this;
}

ClassdefContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ClassdefContext.prototype.constructor = ClassdefContext;

ClassdefContext.prototype.CLASS = function() {
    return this.getToken(Python3Parser.CLASS, 0);
};

ClassdefContext.prototype.NAME = function() {
    return this.getToken(Python3Parser.NAME, 0);
};

ClassdefContext.prototype.suite = function() {
    return this.getTypedRuleContext(SuiteContext,0);
};

ClassdefContext.prototype.arglist = function() {
    return this.getTypedRuleContext(ArglistContext,0);
};

ClassdefContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterClassdef(this);
	}
};

ClassdefContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitClassdef(this);
	}
};




Python3Parser.ClassdefContext = ClassdefContext;

Python3Parser.prototype.classdef = function() {

    var localctx = new ClassdefContext(this, this._ctx, this.state);
    this.enterRule(localctx, 146, Python3Parser.RULE_classdef);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 969;
        this.match(Python3Parser.CLASS);
        this.state = 970;
        this.match(Python3Parser.NAME);
        this.state = 976;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.OPEN_PAREN) {
            this.state = 971;
            this.match(Python3Parser.OPEN_PAREN);
            this.state = 973;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(((((_la - 20)) & ~0x1f) == 0 && ((1 << (_la - 20)) & ((1 << (Python3Parser.LAMBDA - 20)) | (1 << (Python3Parser.NOT - 20)) | (1 << (Python3Parser.NONE - 20)) | (1 << (Python3Parser.TRUE - 20)) | (1 << (Python3Parser.FALSE - 20)) | (1 << (Python3Parser.NAME - 20)) | (1 << (Python3Parser.STRING_LITERAL - 20)) | (1 << (Python3Parser.BYTES_LITERAL - 20)) | (1 << (Python3Parser.DECIMAL_INTEGER - 20)) | (1 << (Python3Parser.OCT_INTEGER - 20)) | (1 << (Python3Parser.HEX_INTEGER - 20)) | (1 << (Python3Parser.BIN_INTEGER - 20)) | (1 << (Python3Parser.FLOAT_NUMBER - 20)) | (1 << (Python3Parser.IMAG_NUMBER - 20)) | (1 << (Python3Parser.ELLIPSIS - 20)) | (1 << (Python3Parser.STAR - 20)) | (1 << (Python3Parser.OPEN_PAREN - 20)))) !== 0) || ((((_la - 52)) & ~0x1f) == 0 && ((1 << (_la - 52)) & ((1 << (Python3Parser.POWER - 52)) | (1 << (Python3Parser.OPEN_BRACK - 52)) | (1 << (Python3Parser.ADD - 52)) | (1 << (Python3Parser.MINUS - 52)) | (1 << (Python3Parser.NOT_OP - 52)) | (1 << (Python3Parser.OPEN_BRACE - 52)))) !== 0)) {
                this.state = 972;
                this.arglist();
            }

            this.state = 975;
            this.match(Python3Parser.CLOSE_PAREN);
        }

        this.state = 978;
        this.match(Python3Parser.COLON);
        this.state = 979;
        this.suite();
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArglistContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_arglist;
    return this;
}

ArglistContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArglistContext.prototype.constructor = ArglistContext;

ArglistContext.prototype.argument = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArgumentContext);
    } else {
        return this.getTypedRuleContext(ArgumentContext,i);
    }
};

ArglistContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

ArglistContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterArglist(this);
	}
};

ArglistContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitArglist(this);
	}
};




Python3Parser.ArglistContext = ArglistContext;

Python3Parser.prototype.arglist = function() {

    var localctx = new ArglistContext(this, this._ctx, this.state);
    this.enterRule(localctx, 148, Python3Parser.RULE_arglist);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 986;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,137,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 981;
                this.argument();
                this.state = 982;
                this.match(Python3Parser.COMMA); 
            }
            this.state = 988;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,137,this._ctx);
        }

        this.state = 1009;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,141,this._ctx);
        switch(la_) {
        case 1:
            this.state = 989;
            this.argument();
            this.state = 991;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 990;
                this.match(Python3Parser.COMMA);
            }

            break;

        case 2:
            this.state = 993;
            this.match(Python3Parser.STAR);
            this.state = 994;
            this.test();
            this.state = 999;
            this._errHandler.sync(this);
            var _alt = this._interp.adaptivePredict(this._input,139,this._ctx)
            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
                if(_alt===1) {
                    this.state = 995;
                    this.match(Python3Parser.COMMA);
                    this.state = 996;
                    this.argument(); 
                }
                this.state = 1001;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input,139,this._ctx);
            }

            this.state = 1005;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.COMMA) {
                this.state = 1002;
                this.match(Python3Parser.COMMA);
                this.state = 1003;
                this.match(Python3Parser.POWER);
                this.state = 1004;
                this.test();
            }

            break;

        case 3:
            this.state = 1007;
            this.match(Python3Parser.POWER);
            this.state = 1008;
            this.test();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function ArgumentContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_argument;
    return this;
}

ArgumentContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArgumentContext.prototype.constructor = ArgumentContext;

ArgumentContext.prototype.test = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TestContext);
    } else {
        return this.getTypedRuleContext(TestContext,i);
    }
};

ArgumentContext.prototype.comp_for = function() {
    return this.getTypedRuleContext(Comp_forContext,0);
};

ArgumentContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterArgument(this);
	}
};

ArgumentContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitArgument(this);
	}
};




Python3Parser.ArgumentContext = ArgumentContext;

Python3Parser.prototype.argument = function() {

    var localctx = new ArgumentContext(this, this._ctx, this.state);
    this.enterRule(localctx, 150, Python3Parser.RULE_argument);
    var _la = 0; // Token type
    try {
        this.state = 1019;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,143,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 1011;
            this.test();
            this.state = 1013;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===Python3Parser.FOR) {
                this.state = 1012;
                this.comp_for();
            }

            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 1015;
            this.test();
            this.state = 1016;
            this.match(Python3Parser.ASSIGN);
            this.state = 1017;
            this.test();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Comp_iterContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_comp_iter;
    return this;
}

Comp_iterContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Comp_iterContext.prototype.constructor = Comp_iterContext;

Comp_iterContext.prototype.comp_for = function() {
    return this.getTypedRuleContext(Comp_forContext,0);
};

Comp_iterContext.prototype.comp_if = function() {
    return this.getTypedRuleContext(Comp_ifContext,0);
};

Comp_iterContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterComp_iter(this);
	}
};

Comp_iterContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitComp_iter(this);
	}
};




Python3Parser.Comp_iterContext = Comp_iterContext;

Python3Parser.prototype.comp_iter = function() {

    var localctx = new Comp_iterContext(this, this._ctx, this.state);
    this.enterRule(localctx, 152, Python3Parser.RULE_comp_iter);
    try {
        this.state = 1023;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.FOR:
            this.enterOuterAlt(localctx, 1);
            this.state = 1021;
            this.comp_for();
            break;
        case Python3Parser.IF:
            this.enterOuterAlt(localctx, 2);
            this.state = 1022;
            this.comp_if();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Comp_forContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_comp_for;
    return this;
}

Comp_forContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Comp_forContext.prototype.constructor = Comp_forContext;

Comp_forContext.prototype.FOR = function() {
    return this.getToken(Python3Parser.FOR, 0);
};

Comp_forContext.prototype.exprlist = function() {
    return this.getTypedRuleContext(ExprlistContext,0);
};

Comp_forContext.prototype.IN = function() {
    return this.getToken(Python3Parser.IN, 0);
};

Comp_forContext.prototype.or_test = function() {
    return this.getTypedRuleContext(Or_testContext,0);
};

Comp_forContext.prototype.comp_iter = function() {
    return this.getTypedRuleContext(Comp_iterContext,0);
};

Comp_forContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterComp_for(this);
	}
};

Comp_forContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitComp_for(this);
	}
};




Python3Parser.Comp_forContext = Comp_forContext;

Python3Parser.prototype.comp_for = function() {

    var localctx = new Comp_forContext(this, this._ctx, this.state);
    this.enterRule(localctx, 154, Python3Parser.RULE_comp_for);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 1025;
        this.match(Python3Parser.FOR);
        this.state = 1026;
        this.exprlist();
        this.state = 1027;
        this.match(Python3Parser.IN);
        this.state = 1028;
        this.or_test();
        this.state = 1030;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.IF || _la===Python3Parser.FOR) {
            this.state = 1029;
            this.comp_iter();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Comp_ifContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_comp_if;
    return this;
}

Comp_ifContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Comp_ifContext.prototype.constructor = Comp_ifContext;

Comp_ifContext.prototype.IF = function() {
    return this.getToken(Python3Parser.IF, 0);
};

Comp_ifContext.prototype.test_nocond = function() {
    return this.getTypedRuleContext(Test_nocondContext,0);
};

Comp_ifContext.prototype.comp_iter = function() {
    return this.getTypedRuleContext(Comp_iterContext,0);
};

Comp_ifContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterComp_if(this);
	}
};

Comp_ifContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitComp_if(this);
	}
};




Python3Parser.Comp_ifContext = Comp_ifContext;

Python3Parser.prototype.comp_if = function() {

    var localctx = new Comp_ifContext(this, this._ctx, this.state);
    this.enterRule(localctx, 156, Python3Parser.RULE_comp_if);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 1032;
        this.match(Python3Parser.IF);
        this.state = 1033;
        this.test_nocond();
        this.state = 1035;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===Python3Parser.IF || _la===Python3Parser.FOR) {
            this.state = 1034;
            this.comp_iter();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Yield_exprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_yield_expr;
    return this;
}

Yield_exprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Yield_exprContext.prototype.constructor = Yield_exprContext;

Yield_exprContext.prototype.YIELD = function() {
    return this.getToken(Python3Parser.YIELD, 0);
};

Yield_exprContext.prototype.yield_arg = function() {
    return this.getTypedRuleContext(Yield_argContext,0);
};

Yield_exprContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterYield_expr(this);
	}
};

Yield_exprContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitYield_expr(this);
	}
};




Python3Parser.Yield_exprContext = Yield_exprContext;

Python3Parser.prototype.yield_expr = function() {

    var localctx = new Yield_exprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 158, Python3Parser.RULE_yield_expr);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 1037;
        this.match(Python3Parser.YIELD);
        this.state = 1039;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(((((_la - 4)) & ~0x1f) == 0 && ((1 << (_la - 4)) & ((1 << (Python3Parser.FROM - 4)) | (1 << (Python3Parser.LAMBDA - 4)) | (1 << (Python3Parser.NOT - 4)) | (1 << (Python3Parser.NONE - 4)) | (1 << (Python3Parser.TRUE - 4)) | (1 << (Python3Parser.FALSE - 4)) | (1 << (Python3Parser.NAME - 4)))) !== 0) || ((((_la - 36)) & ~0x1f) == 0 && ((1 << (_la - 36)) & ((1 << (Python3Parser.STRING_LITERAL - 36)) | (1 << (Python3Parser.BYTES_LITERAL - 36)) | (1 << (Python3Parser.DECIMAL_INTEGER - 36)) | (1 << (Python3Parser.OCT_INTEGER - 36)) | (1 << (Python3Parser.HEX_INTEGER - 36)) | (1 << (Python3Parser.BIN_INTEGER - 36)) | (1 << (Python3Parser.FLOAT_NUMBER - 36)) | (1 << (Python3Parser.IMAG_NUMBER - 36)) | (1 << (Python3Parser.ELLIPSIS - 36)) | (1 << (Python3Parser.STAR - 36)) | (1 << (Python3Parser.OPEN_PAREN - 36)) | (1 << (Python3Parser.OPEN_BRACK - 36)) | (1 << (Python3Parser.ADD - 36)) | (1 << (Python3Parser.MINUS - 36)) | (1 << (Python3Parser.NOT_OP - 36)) | (1 << (Python3Parser.OPEN_BRACE - 36)))) !== 0)) {
            this.state = 1038;
            this.yield_arg();
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Yield_argContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_yield_arg;
    return this;
}

Yield_argContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Yield_argContext.prototype.constructor = Yield_argContext;

Yield_argContext.prototype.FROM = function() {
    return this.getToken(Python3Parser.FROM, 0);
};

Yield_argContext.prototype.test = function() {
    return this.getTypedRuleContext(TestContext,0);
};

Yield_argContext.prototype.testlist = function() {
    return this.getTypedRuleContext(TestlistContext,0);
};

Yield_argContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterYield_arg(this);
	}
};

Yield_argContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitYield_arg(this);
	}
};




Python3Parser.Yield_argContext = Yield_argContext;

Python3Parser.prototype.yield_arg = function() {

    var localctx = new Yield_argContext(this, this._ctx, this.state);
    this.enterRule(localctx, 160, Python3Parser.RULE_yield_arg);
    try {
        this.state = 1044;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.FROM:
            this.enterOuterAlt(localctx, 1);
            this.state = 1041;
            this.match(Python3Parser.FROM);
            this.state = 1042;
            this.test();
            break;
        case Python3Parser.LAMBDA:
        case Python3Parser.NOT:
        case Python3Parser.NONE:
        case Python3Parser.TRUE:
        case Python3Parser.FALSE:
        case Python3Parser.NAME:
        case Python3Parser.STRING_LITERAL:
        case Python3Parser.BYTES_LITERAL:
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
        case Python3Parser.FLOAT_NUMBER:
        case Python3Parser.IMAG_NUMBER:
        case Python3Parser.ELLIPSIS:
        case Python3Parser.STAR:
        case Python3Parser.OPEN_PAREN:
        case Python3Parser.OPEN_BRACK:
        case Python3Parser.ADD:
        case Python3Parser.MINUS:
        case Python3Parser.NOT_OP:
        case Python3Parser.OPEN_BRACE:
            this.enterOuterAlt(localctx, 2);
            this.state = 1043;
            this.testlist();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function StrContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_str;
    return this;
}

StrContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StrContext.prototype.constructor = StrContext;

StrContext.prototype.STRING_LITERAL = function() {
    return this.getToken(Python3Parser.STRING_LITERAL, 0);
};

StrContext.prototype.BYTES_LITERAL = function() {
    return this.getToken(Python3Parser.BYTES_LITERAL, 0);
};

StrContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterStr(this);
	}
};

StrContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitStr(this);
	}
};




Python3Parser.StrContext = StrContext;

Python3Parser.prototype.str = function() {

    var localctx = new StrContext(this, this._ctx, this.state);
    this.enterRule(localctx, 162, Python3Parser.RULE_str);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 1046;
        _la = this._input.LA(1);
        if(!(_la===Python3Parser.STRING_LITERAL || _la===Python3Parser.BYTES_LITERAL)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function NumberContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_number;
    return this;
}

NumberContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NumberContext.prototype.constructor = NumberContext;

NumberContext.prototype.integer = function() {
    return this.getTypedRuleContext(IntegerContext,0);
};

NumberContext.prototype.FLOAT_NUMBER = function() {
    return this.getToken(Python3Parser.FLOAT_NUMBER, 0);
};

NumberContext.prototype.IMAG_NUMBER = function() {
    return this.getToken(Python3Parser.IMAG_NUMBER, 0);
};

NumberContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterNumber(this);
	}
};

NumberContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitNumber(this);
	}
};




Python3Parser.NumberContext = NumberContext;

Python3Parser.prototype.number = function() {

    var localctx = new NumberContext(this, this._ctx, this.state);
    this.enterRule(localctx, 164, Python3Parser.RULE_number);
    try {
        this.state = 1051;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case Python3Parser.DECIMAL_INTEGER:
        case Python3Parser.OCT_INTEGER:
        case Python3Parser.HEX_INTEGER:
        case Python3Parser.BIN_INTEGER:
            this.enterOuterAlt(localctx, 1);
            this.state = 1048;
            this.integer();
            break;
        case Python3Parser.FLOAT_NUMBER:
            this.enterOuterAlt(localctx, 2);
            this.state = 1049;
            this.match(Python3Parser.FLOAT_NUMBER);
            break;
        case Python3Parser.IMAG_NUMBER:
            this.enterOuterAlt(localctx, 3);
            this.state = 1050;
            this.match(Python3Parser.IMAG_NUMBER);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function IntegerContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = Python3Parser.RULE_integer;
    return this;
}

IntegerContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IntegerContext.prototype.constructor = IntegerContext;

IntegerContext.prototype.DECIMAL_INTEGER = function() {
    return this.getToken(Python3Parser.DECIMAL_INTEGER, 0);
};

IntegerContext.prototype.OCT_INTEGER = function() {
    return this.getToken(Python3Parser.OCT_INTEGER, 0);
};

IntegerContext.prototype.HEX_INTEGER = function() {
    return this.getToken(Python3Parser.HEX_INTEGER, 0);
};

IntegerContext.prototype.BIN_INTEGER = function() {
    return this.getToken(Python3Parser.BIN_INTEGER, 0);
};

IntegerContext.prototype.enterRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.enterInteger(this);
	}
};

IntegerContext.prototype.exitRule = function(listener) {
    if(listener instanceof Python3Listener ) {
        listener.exitInteger(this);
	}
};




Python3Parser.IntegerContext = IntegerContext;

Python3Parser.prototype.integer = function() {

    var localctx = new IntegerContext(this, this._ctx, this.state);
    this.enterRule(localctx, 166, Python3Parser.RULE_integer);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 1053;
        _la = this._input.LA(1);
        if(!(((((_la - 38)) & ~0x1f) == 0 && ((1 << (_la - 38)) & ((1 << (Python3Parser.DECIMAL_INTEGER - 38)) | (1 << (Python3Parser.OCT_INTEGER - 38)) | (1 << (Python3Parser.HEX_INTEGER - 38)) | (1 << (Python3Parser.BIN_INTEGER - 38)))) !== 0))) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


exports.Python3Parser = Python3Parser;
