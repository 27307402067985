
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'
import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'
import HatchLockIntroImg from './assets/iStock-1185065140.jpg'
import NeoPixelsImg from './assets/peripherals/pk_neo_pix.jpg'
import MicroswitchImg from './assets/peripherals/pk_crash.jpg'
import FinalHatchImg from './assets/iStock-500199833.jpg'
import RgbMixImg from './assets/iStock-587551046.jpg'
import LcdScreenImg from './assets/iStock-1286297244.jpg'
import FirstNeoImg from './assets/iStock-1167039323.jpg'
import FillAllImg from '../StartCoding/assets/Loop.gif'
import DiscoBallImg from './assets/iStock-489037784.jpg'
import WasPressedImg from './assets/iStock-1201200962.jpg'
import HatchProblemImg from './assets/iStock-1185282377.jpg'
import OctopusSwitchFiveVolts from './assets/JoxT6k21-5v.jpg'


import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
} from '../Legends'

export const HatchLockImg = GoogleClassroomImg

class ProjectHatch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Hatch Lock! {lesson-title}
___
### This project introduces NeoPixels and will show you how to light up different colors!
`}
<img src={HatchLockIntroImg} alt=""
    style={{
      width: '40%', margin: '0.5em', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The crew's shuttle will dock with a larger supporting craft that launched ahead of the mission. 

The supporting craft will have extra fuel, supplies, and larger quarters for the crew to live in.

**There are eight locks that engage on the shuttle's hatch to get a proper seal with the supporting craft.**
* The locks engage when a large mechanical lever is moved.
* The crew will need an indication that all eight locks have engaged.
* The mechanical engineering team has discovered an issue where individual locks only engage 85% of the time.

**Project: Hatch Lock** will guide you through triggering the locks and displaying individual lock information.

#### Project Goals:
* Learn about NeoPixels and RGB colors.
* Understand uses for microswitches.
* Inform the crew when the hatch is properly secured to the supporting craft!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class NeoPixels extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## NeoPixels {lesson-title}
___
`}
<img src={NeoPixelsImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The crew needs to know the status of the 8 locks on the hatch!

The first step is displaying a status.

You can use the NeoPixel ring to the status of each lock in *color!*
* The NeoPixel ring has 8 separate RGB (Red, Green, Blue) LEDs called **pixels**.
* Each pixel can be set to a unique color.
* The pixels can be individually dimmed.
`}
<LegendMessageConcept>
{`
**NeoPixels can come in all sorts of shapes and sizes!**
* There are  NeoPixel strips with 1000's of different pixels
* They can be laid out in strips, rectangles, circles, etc.

**NeoPixels are most commonly used for:**
* Room lighting
* Hobbyist electronic displays
* Clothing
* Jewelry
* Accessories *(like a light-up snowboard!)*
`}
</LegendMessageConcept>
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **HatchLock**
`}
</LegendMessageInteract>
<LegendOctoHeader 
  pin={16}
  peripheral={10} // 10 = NeoPixels 8 RGB
  hideWire={true}
  markdown={`
Connect the **8 RGB NeoPixels** to **position 16** on your ==octopus:bit==!

It can be attached directly to the ==octopus:bit== or you can use the three loose wires provided in the ==peripheral kit==.

**The back side of the NeoPixel ring has three labels:**
* **DI** or *data input* is connected to the **Blue** pin.
* **V** or *voltage* is connected to the **Red** pin
* **G** or *ground* is connected to the **Black** pin
`}
/>
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if your NeoPixel Ring is attached!"
      />
    </div>
  )
  }
}

class FiveVolts extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Five Volts Required {lesson-title}
___
#### NeoPixels require a voltage input between 3.7 and 5.3 Volts.

`}
<LegendMessageConcept>
<Markdown>
  {`
Both 3.3 Volts and 5 Volts will work just fine for *most* of the peripherals in your kit.
* All pins on the **octopus:bit** have the ability to supply 3.3V 
* Only the ***blue IO pins*** can supply **5V**

**Always check the voltage range of the peripheral before applying power!**

**NOTE:** The NeoPixel may still work at 3.3V, but the manufacturer does not recommend it.
* It is always a good idea to stay within the peripheral's limits!!
  `}
</Markdown>
</LegendMessageConcept>
<LegendMessageInteract title={'Switch it to 5V'}>
<Markdown>
{`
Locate the **VCC Switch** on the **octopus:bit** and place it in the **5V** position.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <img src={OctopusSwitchFiveVolts} alt="OctopusBit Switch" style={{width: '30%'}} />
</div>
</LegendMessageInteract>
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if your VCC Switch is set to 5V!"
      />
    </div>
  )
  }
}

class FirstNeoLight extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Turn on a NeoPixel! {lesson-title}
___
`}
<img src={FirstNeoImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### It's time to turn on the first LED on the NeoPixel ring. {centered}

The *micro:bit* has a special library for NeoPixels called...
> \`neopixel\`!!

**Start with the imports:**
\`\`\`
from microbit import *
from neopixel import NeoPixel
\`\`\`

**Now you can create a \`NeoPixel\` ==variable== named \`np\`.**

The \`NeoPixel\` needs two pieces of information when you create it:
1. The output ==pin== the NeoPixel is connected to.
2. The number of **pixels** connected.

**Setup Your NeoPixel variable**
\`\`\`
np = NeoPixel(pin16, 8)  # 8 pixels on pin 16
\`\`\`

**Next you can set the *color* of a *pixel***
* The NeoPixel variable works similar to a Python ==list==.
  * Remember that you index a ==list== using brackets[].
  * Also recall that the index count starts at ***zero!***
* You will learn more about RGB colors and individual pixels later in this project. For now, type in this code:
\`\`\`
np[0] = (20, 0, 0) # RGB for red
\`\`\`

**After changing colors you need to call the \`show()\` function.**
\`\`\`
np.show()
\`\`\`
`}
<LegendMessageKeyboard>
{`
**Your *HatchLock* program should like this:**
\`\`\`
from microbit import *
from neopixel import NeoPixel

np = NeoPixel(pin16, 8)
np[0] = (20, 0, 0) # RGB for red
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Pixel \`0\` is the one nearest the *connector* of your *Neopixel Ring*.
* If you look closely you'll see the pixels are numbered *clockwise* around the ring.
* But the numbering starts with **1**!
  * Don't let that confuse you. In software, the *first* item is usually index \`0\`.

Humans usually count "1, 2, 3, ..."
> Computers count "0, 1, 2, ..."
`}
</LegendMessageRun>
</Markdown>
      <StepComplete
  prompt="Did the first LED on your NeoPixel ring light up red?"
  xp={5}
  successMessage=
{`## NeoPixels have arrived! {centered}
The NeoPixel is a very flexible display tool.

*Now, learn how to change colors with RGB values!*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel']}
  reqNames={['np']}
  reqArgs={['pin16', '8']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class UnderstandRgbs extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## How do NeoPixels Work? {lesson-title}
___
#### Each pixel is made up of three LEDs.
* One is **R**ed
* One is **G**reen
* One is **B**lue
`}
<LegendMessageConcept>
<Markdown>
{`#### NeoPixels create colors similar to how display screens in phones and TVs do it.
* If you could look close enough at those displays you'd see something like this.
* *Lots* of tiny RED/GREEN/BLUE *dots!*
  * A 4K display has 3840x2160 = 8,294,400 pixels!
`}
</Markdown>
  <img src={LcdScreenImg} alt=""
    style={{
      width: "40%", display: 'block', margin: 'auto',
    }}
  />
</LegendMessageConcept>
{`### Color Me a ==tuple== of ==int==s!
So you can make a color by blending (Red, Green, Blue) together?
* Then how would you define a **color** in Python?
* A ==tuple== is a Python ==data type== that holds a sequence of values.
  * Perfect for defining \`color = (red, green, blue)\`.

> *The Neopixel ==library== uses ==tuple==s to set colors!*

### Can you *see* the *R-G-B* ?
When a Neopixel is *brightly lit*, the colors blend together visually.
* But if the LEDs are *very dim*, maybe you can see them individually!
`}
<LegendMessageKeyboard>
{`
**Add the following code to experiment:**
\`\`\`
from microbit import *
from neopixel import NeoPixel

$# Set very dim color values.
$red = 2
$green = 2
$blue = 2
$rgb_color = (red, green, blue)

np = NeoPixel(pin16, 8)
$np[0] = rgb_color
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Now look closely at the first pixel!">
{`
Can you see the 3 LEDs inside?
`}
</LegendMessageInteract>
</Markdown>
      <StepComplete
  prompt="Did you see the 3 individual LEDs?"
  xp={5}
  successMessage=
{`## RGB color combinations are used in nearly all screens! {centered}

*Move to the next step to learn about brightness!*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel']}
  reqNames={['np', 'red', 'green', 'blue', 'rgb_color']}
  reqArgs={['pin16', '8']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class BrightDim extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Dim, Bright? {lesson-title}
___
#### When you are setting an RGB value you are actually setting the brightness of the 3 LEDs.
* The range for a single LED is \`0\` to \`255\`
  * \`0\` is **OFF**
  * \`255\` is *full brightness* **ON**
`}
<Quiz
  lessons={this.props.lessons}
  id={"range 256"}
  prompt={(
<Markdown>
{`There are **256** brightness levels for Neopixel LEDs, including OFF.
* Why 256? Because your code sends each level in ==binary== to the Neopixels.
* For speed's sake, the number of bits is limited.

How many ==binary== **bits** are used for each Neopixel color?
`}
</Markdown>
  )}
  xp={10}
  answerRight={"8"}
  answerWrong={["2", "16", "24", "32"]}
/>
<LegendMessageWarning title="Make sure you don't look directly at bright LEDs!">
{`
The rest of the code in this project will keep the brightness to **20 or less** for an individual LED.

A brightness of 255 is **VERY** bright and you should not look directly at it!
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`
**Set the blue LED to a very dim blue and turn off the other two LEDs:**
\`\`\`
from microbit import *
from neopixel import NeoPixel

# Set very dim color values.
$$$
$red = 0
$green = 0
$blue = 2
rgb_color = (red, green, blue)
$$$

np = NeoPixel(pin16, 8)
np[0] = rgb_color
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageKeyboard>
{`
**Now, make the blue LED brighter:**
\`\`\`
from microbit import *
from neopixel import NeoPixel

# Set very dim color values.
$$$
red = 0
green = 0
$blue = 20
rgb_color = (red, green, blue)
$$$

np = NeoPixel(pin16, 8)
np[0] = rgb_color
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
      <StepComplete
  prompt="Did you notice the change in brightness?"
  xp={5}
  successMessage=
{`## DO NOT stare directly into a bright pixel! {centered}

*Next, you will learn how to blend colors together to change the color of a pixel!*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel']}
  reqNames={['np', 'red', 'green', 'blue', 'rgb_color']}
  reqArgs={['pin16', '8']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class RgbColors extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Color Blend and Change! {lesson-title}
___
`}
<img src={RgbMixImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### You already know that there are 3 LEDs in a single NeoPixel so it is easy to make it *red*, *green*, or *blue*.

*What if you want yellow?*
* The LEDs are close enough together that the colors can blend together and appear as a single color.

**Here are some common RGB colors:**
\`\`\`
RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)
RGB_BLUE = (0, 0, 20)
RGB_MAGENTA = (20, 0, 20)
RGB_YELLOW = (20, 20, 0)
RGB_WHITE = (20, 20, 20)
RGB_OFF = (0, 0, 0)
\`\`\`

`}
<LegendMessageKeyboard>
{`Modify your program to show a ***color change*** from **Red** to **Yellow**.
> *Remember to call \`np.show()\` after each color change.*
1. Start with setting your pixel to \`RGB_RED\`.
2. Then add a ==delay== .
3. Change the color to \`RGB_YELLOW\`.
\`\`\`
from microbit import *
from neopixel import NeoPixel

$RGB_RED = (20, 0, 0)
$RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$# set the pixel to red
$np[0] = RGB_RED
$np.show()

$# delay 1 second
$sleep(1000)

$# change the color to yellow
$np[0] = RGB_YELLOW
$np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
      <StepComplete
  prompt="Did your LED start Red and change to Yellow?"
  xp={5}
  successMessage=
{`
## Colorful! {centered}
#### RGB *LEDs* can be set to any color you want!
* Now you can find your *favorite* **RGB** color...
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel', 'sleep']}
  reqNames={['np', 'RGB_RED', 'RGB_YELLOW']}
  reqArgs={['pin16', '8', '1000']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class NeoPositions extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Light up different positions! {lesson-title}
___
`}
<img src={LcdScreenImg} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### Each pixel in a NeoPixel is individually addressable.

**The pixels are numbered from 0 to the number of pixels minus 1.**

**Ex.** The pixels are numbered 0 to 7 for an 8 pixel NeoPixel.
`}
<LegendMessageKeyboard>
{`
Set pixel **2** to **RED** and pixel **6** to **YELLOW**:
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$# set pixel 2 to red
$np[2] = RGB_RED
np.show()

# delay 1 second
sleep(1000)

$# set pixel 6 to yellow
$np[6] = RGB_YELLOW
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />

{`
**You can also set multiple pixels to different colors before calling \`np.show()\`.**
`}
<LegendMessageKeyboard>
{`
**Set multiple pixels at once!**
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$$$
$# set pixels red
$np[0] = RGB_RED
$np[3] = RGB_RED
$np[5] = RGB_RED
$np.show()

# delay 1 second
sleep(1000)

$# set pixels yellow
$np[1] = RGB_YELLOW
$np[4] = RGB_YELLOW
$np[6] = RGB_YELLOW
$np.show()
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
      <StepComplete
  prompt="Did multiple LEDs light up at the same time?"
  xp={5}
  successMessage=
{`## Dazzling Array! {centered}
#### Setting individual pixels is easy.
* But is there an easy way to *clear* the display?

Hmmm...
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel', 'sleep']}
  reqNames={['np', 'RGB_RED', 'RGB_YELLOW']}
  reqArgs={['pin16', '8', '1000']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class ClearDisplay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Clear the NeoPixels! {lesson-title}
___
#### You may run into a situation where you want to clear the display. {centered}

You can use the \`np.clear()\` function to accomplish that!
`}
<LegendMessageKeyboard>
{`
**Add the \`clear\` function call just after your \`sleep\`.**
* This will turn off the red LEDs before showing the next group!
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

# set pixels red
np[0] = RGB_RED
np[3] = RGB_RED
np[5] = RGB_RED
np.show()

$$$
$# delay 1 second
$sleep(1000)
$np.clear()
$$$

# set pixels yellow
np[1] = RGB_YELLOW
np[4] = RGB_YELLOW
np[6] = RGB_YELLOW
np.show()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
{`### Flashy!
But can you make it flash *continuously??*
* Of course you can!!
`}
<LegendMessageKeyboard>
{`### Modify your code to flash continuously.
You'll need to move the Neopixel and ==delay== code into a \`while loop\`.
* Remember you can use the ==editor shortcuts==.
  * *Select* several lines of code and use **TAB** to ==indent== them under the \`while True:\`.
* You're going to need another \`sleep(1000)\` and \`np.clear()\` also...

*Can you code it without peeking at the solution?*
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$$$
$while True:
    np[0] = RGB_RED
    np[3] = RGB_RED
    np[5] = RGB_RED
    np.show()
$$$
    
    # delay 1 second
    sleep(1000)
    np.clear()
    
    np[1] = RGB_YELLOW
    np[4] = RGB_YELLOW
    np[6] = RGB_YELLOW
    np.show()
    
$    # delay 1 second
$    sleep(1000)
$    np.clear()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
      <StepComplete
  prompt="Is your NeoPixel constantly flashing yellow and red?"
  xp={5}
  successMessage=
{`## Awesome work! {centered}

*You are well on your way to the hatch lock display!*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel', 'sleep', 'np.clear']}
  reqNames={['np', 'RGB_RED', 'RGB_YELLOW']}
  reqArgs={['pin16', '8', '1000']}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class FillEmAll extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Fill all the positions! {lesson-title}
___
`}
<img src={FillAllImg} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### Set all of the pixels to a single color with a ==function==!

Add a ==function== \`set_all_pixels()\` to your code.
* It should take one ==argument== named \`rgb_color\`.
\`\`\`
def set_all_pixels(rgb_color):
    np[0] = rgb_color
    np[1] = rgb_color
    np[2] = rgb_color
    np[3] = rgb_color
    np[4] = rgb_color
    np[5] = rgb_color
    np[6] = rgb_color
    np[7] = rgb_color
    np.show()
\`\`\`
`}
<LegendMessageKeyboard>
{`
**Update your while ==loop== to use the new \`set_all_pixels\` function:**
* **NOTE:** You will not need the \`np.clear()\` anymore as *all* the pixels are getting set!
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$$$
$def set_all_pixels(rgb_color):
$    np[0] = rgb_color
$    np[1] = rgb_color
$    np[2] = rgb_color
$    np[3] = rgb_color
$    np[4] = rgb_color
$    np[5] = rgb_color
$    np[6] = rgb_color
$    np[7] = rgb_color
$    np.show()

while True:
$    set_all_pixels(RGB_RED)
$    sleep(1000)
$    set_all_pixels(RGB_YELLOW)
$    sleep(1000)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
{`#### That is a lot of *repetitive* code to just set the pixels!
*What can you use to reduce the size of your code?*

**You can use a \`for\` ==loop==!!!**
* The ==loop== will need to iterate 8 times.
* Each iteration of the loop will set a pixel.

**Remember... \`range(8)\` will output values from 0 to 7 in your ==loop==!**
`}
<LegendMessageKeyboard>
{`
Update your \`set_all_pixels()\` function to use a \`for\` ==loop==:
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

$$$
def set_all_pixels(rgb_color):
$    for pixel in range(8):
$        np[pixel] = rgb_color
    np.show()
$$$

while True:
    set_all_pixels(RGB_RED)
    sleep(1000)
    set_all_pixels(RGB_YELLOW)
    sleep(1000)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
      <StepComplete
  prompt="Are the NeoPixels all switching between red and yellow?"
  xp={5}
  successMessage=
{`## Nice job! {centered}

*You now have all the basic tools to use NeoPixels!*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel', 'sleep', 'set_all_pixels', 'range']}
  reqNames={['np', 'RGB_RED', 'RGB_YELLOW']}
  reqArgs={['pin16', '8', '1000', 'RGB_RED', 'RGB_YELLOW']}
  reqStatements={['while', 'for']}
  reqFuncdefs={['set_all_pixels']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class RandomFun extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Random Fun! {lesson-title}
___
`}
<img src={DiscoBallImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### Unlock the potential of the NeoPixels - make a disco ball!

*Even space travelers might need a little fun!!*

**Import the \`random\` library at the top of your file.**
* You will only need the \`randint()\` function.
\`\`\`
from random import randint
\`\`\`

### Now *cue the music* and get your *code* on!
`}
<div style={{clear:'both'}} />
<LegendMessageKeyboard>
{`
**Replace your \`while\` ==loop== with this fun example:**
* This will choose a random value for the red, green, and blue LEDs!
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
RGB_YELLOW = (20, 20, 0)

np = NeoPixel(pin16, 8)

def set_all_pixels(rgb_color):
    for pixel in range(8):
        np[pixel] = rgb_color
    np.show()

$$$
$while True:
$    for pixel in range(8):
$        # Choose a random color
$        red = randint(0, 20)
$        green = randint(0, 20)
$        blue = randint(0, 20)
$        np[pixel] = (red, green, blue)
$
$    np.show()
$    sleep(200)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<Quiz
        lessons={this.props.lessons}
        prompt="What color LED is lit up in this RGB value (0, 20, 0)?"
        xp={5}
        answerRight={"Green"}
        answerWrong={["Red", "Blue"]}
      />
<Quiz
        lessons={this.props.lessons}
        prompt="How many LEDs make up a single pixel in a NeoPixel?"
        xp={5}
        answerRight={"3"}
        answerWrong={["1", "2"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if you lit up the dance floor!"
      />
    </div>
  )
  }
}

class Microswitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Microswitch! {lesson-title}
___
`}
<img src={MicroswitchImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### That was fun... Now it is time to get back to work.

*Your newfound NeoPixel skills are needed to show the status of 8 locks on the space shuttle's hatch!*

You will also need one more tool.

**You will need something to trigger the system to check the locks.**
* You can use a ***microswitch!!***

***Note:** The **microswitch** might be labeled "Crash Sensor" in your kit.* 
`}
<LegendMessageConcept>
  <Markdown>
{`
**A microswitch is really just a *button* that's not intended for direct human interaction.**
* They are generally found in closed mechanical systems.

**Microswitches are used in a vast number of products such as:**
* Appliances
* Factory equipment
* Automobiles
* Touch detection for robots
`}
  </Markdown>
</LegendMessageConcept>
<LegendOctoHeader 
  pin={0}
  peripheral={12} // 12 = Microswitch
  markdown={`Connect the **Microswitch** to **position 0** on your ==octopus:bit==!`}
/>
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if your microswitch is connected!"
      />
    </div>
  )
  }
}

class LockHatch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Lock the Hatch! {lesson-title}
___
#### Tell the crew if the hatch is closed by lighting up the NeoPixels.

Your "Hatch Check" algorithm will be simple:
1. Read the microswitch.
    * *If a microswitch is just a button... You can use \`read_digital()\` to get its value!*
2. Display the HATCH STATUS:
    * If HATCH is OPEN, set all the pixels to **RED**.
    * Else if it's CLOSED, set all the pixels to **GREEN**.
3. Repeat!

`}
<LegendMessageKeyboard>
{`
* Add a \`def read_microswitch():\` function to your code.
  * A nice, *meaningful* way for your code to check the switch.
* Create ==constants== for \`HATCH_CLOSED\` and \`HATCH_OPEN\`.
* Replace the \`RGB_YELLOW\` constant with \`RGB_GREEN\`
* Finally, update your \`while\` loop to set all the pixels
  * \`if\` the hatch is **closed** &rarr; **green**
  * \`else\` &rarr; **red**
\`\`\` collapsed Peek here if you need help...
from microbit import *
from neopixel import NeoPixel

RGB_RED = (20, 0, 0)
$RGB_GREEN = (0, 20, 0)

$HATCH_CLOSED = 0
$HATCH_OPEN = 1

np = NeoPixel(pin16, 8)

def set_all_pixels(rgb_color):
    for pixel in range(8):
        np[pixel] = rgb_color
    np.show()

$def read_microswitch():
$    return pin0.read_digital()

while True:
$    switch_val = read_microswitch()

$    if switch_val == HATCH_CLOSED:
$        set_all_pixels(RGB_GREEN)
$    else:
$        set_all_pixels(RGB_RED)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the microswitch to simulate closing the hatch!" >
{`
***Verify that your code is setting the status of the locks!***
`}
</LegendMessageInteract>
</Markdown>
      <StepComplete
  prompt="Is your microswitch operational?"
  xp={5}
  successMessage=
{`## Hatch is closed... wait no it's open! {centered}

**Microswitches are used in many applications!**

*Fundamentally, they are just buttons.*
`}
  reqImports={['microbit', 'neopixel']}
  reqCalls={['np.show', 'NeoPixel', 'range', 'set_all_pixels', 'pin0.read_digital', 'read_microswitch']}
  reqNames={['np', 'RGB_GREEN', 'RGB_RED', 'HATCH_CLOSED', 'HATCH_OPEN', 'switch_val']}
  reqArgs={['pin16', '8', 'RGB_GREEN', 'RGB_RED']}
  reqStatements={['while', 'for', 'if', 'else']}
  reqFuncdefs={['set_all_pixels', 'read_microswitch']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class RandomIssue extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Problem with the Locks! {lesson-title}
___
`}
<img src={HatchProblemImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
#### The engineers have told you that the locks will only lock properly 85% of the time.
* That means there is a 15% failure rate.
* You need to show the crew which locks have failed!!
  * Right now your code **assumes** all 8 locks are working (GREEN) when the **microswitch** is pressed.
  * The *microswitch* just means **"Hatch Closed"**...
  * You need a new ==function== to ***check the locks!***

**According to a local statistician:**
* All 8 locks will successfully engage only 27.2% of the time.

*Modify your code to **accurately** simulate the **Hatch Lock!***
`}
<LegendMessageKeyboard>
{`
* Add a function \`def is_lock_failed():\` to simulate the check of each lock.
  * It will randomly determine if the lock successfully engaged!
  * You'll need \`from random import randint\` to simulate *random* failures.
\`\`\`
def is_lock_failed():
  return randint(0, 99) < 15 # 15% failure
\`\`\`
* Add a ==function== \`def check_all_locks():\` that you'll call when the hatch is **CLOSED**.
  * It will check if each individual lock (0 - 7) has failed and then light up the corresponding *LED* **RED** or **GREEN**.
* Finally, update the \`HATCH_CLOSED\` branch in your ==loop== to call \`check_all_locks()\` instead of \`set_all_pixels(RGB_GREEN)\`.
\`\`\` collapsed Peek here if you need help...
from microbit import *
from neopixel import NeoPixel
from random import randint

RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)

HATCH_CLOSED = 0
HATCH_OPEN = 1

np = NeoPixel(pin16, 8)

def set_all_pixels(rgb_color):
    for pixel in range(8):
        np[pixel] = rgb_color
    np.show()

def read_microswitch():
    return pin0.read_digital()
    
$def is_lock_failed():
$    return randint(0, 99) < 15 # 15% failure
    
$def check_all_locks():
$    for pixel in range(8):
$        if is_lock_failed():
$            np[pixel] = RGB_RED
$        else:
$            np[pixel] = RGB_GREEN
$    np.show()

while True:
    switch_val = read_microswitch()

    if switch_val == HATCH_CLOSED:
$        check_all_locks()
    else:
        set_all_pixels(RGB_RED)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press and hold the microswitch to simulate closing the hatch!" />
{`
**Hmmm... something is wrong. The pixels should show up red if the locks failed but they seem to stay green.**
* If you watch closely you can see them flickering red.

*What is happening?*
* When you call \`check_all_locks()\` it's like running a ==random== ==simulation==.
* And your *random simulation* is happening *every time* the loop repeats!
* So you see constant flickering-failures...

### This is a bug.
The simulation should only run *once* when the hatch is closed.
* You will need to fix this.
* Can your code keep track of whether the microswitch is *currently pressed?*

If so, you can run the \`check_all_locks()\` simulation just **once** when the *microswitch* is closed.
`}
</Markdown>
      <StepComplete
  prompt="Are your lights green but flickering red?"
  xp={5}
  successMessage=
{`## Locks are failing! {centered}

*Next, learn to track the **state** of your microswitch!*
`}
  reqImports={['microbit', 'neopixel', 'random']}
  reqCalls={['np.show', 'NeoPixel', 'range', 'set_all_pixels', 'pin0.read_digital', 'read_microswitch', 'is_lock_failed', 'check_all_locks']}
  reqNames={['np', 'RGB_GREEN', 'RGB_RED', 'HATCH_CLOSED', 'HATCH_OPEN', 'switch_val']}
  reqArgs={['pin16', '8', 'RGB_RED']}
  reqStatements={['while', 'for', 'if', 'else']}
  reqFuncdefs={['set_all_pixels', 'read_microswitch', 'is_lock_failed', 'check_all_locks']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}

class WasPressed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Was the Button Pressed? {lesson-title}
___
`}
<img src={WasPressedImg} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`### Track the *state* of your microswitch.
Sometimes your code needs to have ***memory.***
* Right now, it doesn't remember whether the *microswitch* is currently pressed or not.
* You can give your code *memory* with a ==variable==.

#### Use a *variable* to track whether the microswitch is *currently pressed.*
\`\`\`
currently_pressed = False
\`\`\`
* Update this variable inside your ==loop== ***only*** when the microswitch *changes.*
`}
<LegendMessageKeyboard>
{`
1. Add a new ==global== ==variable== \`currently_pressed = False\`.
    * This should be *outside* any ==function==.
    * Just above your \`while True:\` loop is a good location.
2. Change the condition inside your loop to *also* check \`currently_pressed\`:
    * Note: Use the \`and\` ==logical operator== :
\`\`\`
if switch_val == HATCH_CLOSED and currently_pressed == False:
\`\`\`
3. Change the \`else:\` to an \`elif switch_val == HATCH_OPEN:\`.
    * Otherwise this branch will happen whenever \`currently_pressed == True\`!
4. Add code to update \`currently_pressed\` in each ==branch==.
\`\`\`
from microbit import *
from neopixel import NeoPixel
from random import randint

RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)

HATCH_CLOSED = 0
HATCH_OPEN = 1

np = NeoPixel(pin16, 8)

def set_all_pixels(rgb_color):
    for pixel in range(8):
        np[pixel] = rgb_color
    np.show()

def read_microswitch():
    return pin0.read_digital()
    
def is_lock_failed():
    return randint(0, 99) < 15 # 15% failure
    
def check_all_locks():
    for pixel in range(8):
        if is_lock_failed():
            np[pixel] = RGB_RED
        else:
            np[pixel] = RGB_GREEN
    np.show()
    
$$$
$currently_pressed = False

while True:
    switch_val = read_microswitch()

$    if switch_val == HATCH_CLOSED and currently_pressed == False:
$        currently_pressed = True
        check_all_locks()
$    elif switch_val == HATCH_OPEN:
$        currently_pressed = False
        set_all_pixels(RGB_RED)

$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Press the *microswitch* a few times and watch the ***Neopixels!***
`}
</LegendMessageRun>
<LegendMessageInteract title="Press the microswitch repeatedly until all 8 pixels are green!" />
</Markdown>
      <StepComplete
  prompt="Could you get all 8 pixels to turn green at the same time?"
  xp={5}
  successMessage=
{`## Awesome!! {centered}

*You have completed Project: Hatch Lock!*
`}
  reqImports={['microbit', 'neopixel', 'random']}
  reqCalls={['np.show', 'NeoPixel', 'range', 'set_all_pixels', 'pin0.read_digital', 'read_microswitch', 'is_lock_failed','check_all_locks']}
  reqNames={['np', 'RGB_GREEN', 'RGB_RED', 'HATCH_CLOSED', 'HATCH_OPEN', 'currently_pressed', 'switch_val']}
  reqArgs={['pin16', '8', 'RGB_RED']}
  reqStatements={['while', 'for', 'if', 'elif']}
  reqFuncdefs={['set_all_pixels', 'read_microswitch', 'is_lock_failed', 'check_all_locks']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
      />
    </div>
  )
  }
}
class HatchSafe extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Hatch Locked! {lesson-title}
___
### You've completed project Hatch Lock!
You now know all sorts of information about NeoPixels. You have also given the crew a safe, secure way to determine the position of the hatch locks!

**Great work!!**
`}
        <img src={FinalHatchImg} alt="" style={{margin: 'auto', width:'100%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="The hatch is secured and the crew will be able to visualize the locks.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectHatch.stepId = 'ProjectHatch'
NeoPixels.stepId = 'NeoPixels'
FiveVolts.stepId = 'FiveVolts'
FirstNeoLight.stepId = 'FirstNeoLight'
UnderstandRgbs.stepId = 'UnderstandRgbs'
BrightDim.stepId = 'BrightDim'
RgbColors.stepId = 'RgbColors'
NeoPositions.stepId = 'NeoPositions'
ClearDisplay.stepId = 'ClearDisplay'
FillEmAll.stepId = 'FillEmAll'
RandomFun.stepId = 'RandomFun'
Microswitch.stepId = 'Microswitch'
LockHatch.stepId = 'LockHatch'
RandomIssue.stepId = 'RandomIssue'
WasPressed.stepId = 'WasPressed'
HatchSafe.stepId = 'HatchSafe'

export const hatchLock = [
  ProjectHatch,
  NeoPixels,
  FiveVolts,
  FirstNeoLight,
  UnderstandRgbs,
  BrightDim,
  RgbColors,
  NeoPositions,
  ClearDisplay,
  FillEmAll,
  RandomFun,
  Microswitch,
  LockHatch,
  RandomIssue,
  WasPressed,
  HatchSafe,
]
