// Provide Student "Achievement Reward" or "Extra Help" upon completion of a Project Step.
// See 'StepComplete' class below for details.

import React from 'react'
import PropTypes from 'prop-types'
import Button from 'material-ui/Button'
import Markdown from './cbl-remarkable'
import {debuggingQuotes} from './InspiringQuotes'
import {celebrationAnimations} from './CelebrationAnimations'
import SkipNext from 'material-ui-icons/SkipNext'
import PlayIcon from 'material-ui-icons/PlayArrow'
import IconHelp from 'material-ui-icons/Help'
import syntaxChecker from '../SyntaxChecker'
import debugController, {traceback} from '../DebugController'
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight'
import ErrorHelper from './../ErrorHelper'
import {MessageDialog} from './../Dialogs'
import {ShareToGoogleClassroom} from '../GoogleClassroom'
import userProgress from "../UserProgress"


function selectRand(srcArray) {
  return srcArray[Math.floor(Math.random() * srcArray.length)]
}

/* Check for completeness of a Lesson Step
   If "Yes" is pressed and specified requirements haven't been met, display same message dialog as "No"
   On success, display random celebration!

    <StepComplete
      prompt="Do you see a HEART image on the micro:bit LED display?"
      xp={10}  // "experience points" - used to advance toward "5th degree black-bot" mastery
      reqSyntax={true}  // default
      reqImports={['microbit',]}
      reqCalls={['display.show', 'sleep']}
      reqFuncdefs={[]}  // default
      reqNames={['HEART']}
    />
*/
export class StepComplete extends React.Component {
  static propTypes = {
    prompt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    xp: PropTypes.number,  // "experience points" - used to advance toward "5th degree black-bot" mastery
    successMessage: PropTypes.string,
    reqSyntax: PropTypes.bool,
    reqImports: PropTypes.arrayOf(PropTypes.string),
    reqCalls: PropTypes.arrayOf(PropTypes.string),
    reqFuncdefs: PropTypes.arrayOf(PropTypes.string),
    reqNames: PropTypes.arrayOf(PropTypes.string),
    reqStatements: PropTypes.arrayOf(PropTypes.string),
    reqParams: PropTypes.arrayOf(PropTypes.string),
    reqArgs: PropTypes.arrayOf(PropTypes.string),
    lessons: PropTypes.object.isRequired,
    btnYes: PropTypes.bool,
    btnNo: PropTypes.bool,
    btnNext: PropTypes.bool,
    id: PropTypes.string,
    btnGClassroom: PropTypes.bool,
    gFileId: PropTypes.string,
  }
  static defaultProps = {
    prompt: "",
    xp: 10,       // Easy=10, Medium=50, Challenge=99
    successMessage: `Nice work!`,
    reqSyntax: true,
    reqImports: [],
    reqCalls: [],
    reqFuncdefs: [],
    reqNames: [],    // catch-all
    reqStatements: [],   // 'while', 'if', 'for', 'break', 'continue'
    reqParams: [],  // call params
    reqArgs: [],    // def func(args)
    lessons: null,
    btnYes: false,
    btnNo: false,
    btnNext: false,
    id: null,
    btnGClassroom: false,
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      showDialog: false,
      showGClassroomSubmit: true,
    }

    // Dialog content to be set prior to 'showDialog'
    this.dialogTitle = ""
    this.dialogContent = null
  }

  onYes = () => {

    // Check requirements, then show celebration/help dialog
    const errs = this.checkProgram()

    if (errs === null) {
      this.props.lessons.completedCurrentStep(this.props.xp)
      if(this.checkQuizCompletion()) {
        this.showQuizIncompleteDialog()
      } else {
        this.showCelebrationDialog()
      }
    }
    else {
      this.showHelpDialog(errs)
    }
  }

  onNext = () => {
    if(this.checkQuizCompletion()) {
      this.showQuizIncompleteDialog()
    } else {
      this.props.lessons.completedCurrentStep(this.props.xp)
      this.props.lessons.onNavRight()
    }
  }

  checkReqContent(errObj, reqPropName, syntaxChkName) {
    const found = syntaxChecker.parseResults[syntaxChkName]
    const req = this.props[reqPropName]
    const missing = [...req].filter(x => !found.includes(x))
    if (missing.length) {
      errObj[reqPropName] = missing
    }
  }

  checkQuizCompletion = () => {
    let quizzes = this.props.lessons.getCurrentLessonQuizNames()
    let incomplete = 0
    quizzes.forEach(q => {
      if (!this.props.lessons.isQuizComplete(q)) {
        incomplete++
      }
    })
    return incomplete
  }

  checkProgram = () => {
    // First determine if we detect any errors or missing requirements
    const runtimeErrs = traceback
    const needsRun = !debugController.isLatestCodeLoaded()
    const syntaxErrs = (runtimeErrs && !needsRun) ? false : syntaxChecker.check()

    // Build errs object to return
    const errs = {}
    if (syntaxErrs > 0) {
      errs.syntaxErrs = syntaxErrs
    }
    if (needsRun) {
      errs.needsRun = true
    }
    if (runtimeErrs !== null) {
      errs.runtimeErrs = runtimeErrs

      // Define the symbols that you explicitly want to check
      let errorHelper = new ErrorHelper()
      const reqNames = this.props.reqImports.concat(this.props.reqCalls,this.props.reqFuncdefs,this.props.reqNames,
        this.props.reqStatements, this.props.reqParams, this.props.reqArgs)
      // Build array of all names, splitting dotted names
      let symbols = []
      reqNames.forEach(e => symbols = symbols.concat(e.split('.')))
      const caps = errorHelper.checkCapitalization(runtimeErrs.msg, symbols)

      if (caps.length !== 0) {
        errs.capitalization = caps
      }
    }
    if (syntaxChecker.parseResults !== null) {
      this.checkReqContent(errs, 'reqImports', 'imports')
      this.checkReqContent(errs, 'reqFuncdefs', 'defs')
      this.checkReqContent(errs, 'reqCalls', 'calls')
      this.checkReqContent(errs, 'reqNames', 'names')
      this.checkReqContent(errs, 'reqStatements', 'statements')
      this.checkReqContent(errs, 'reqParams', 'params')
      this.checkReqContent(errs, 'reqArgs', 'args')
    }

    if (Object.keys(errs).length === 0) {
      // No errors
      return null
    }

    return errs
  }

  onNo = () => {
    // Check requirements, then show wtf/help dialog
    const errs = this.checkProgram()

    if (errs === null) {
      this.showWtfDialog()
    }
    else {
      this.showHelpDialog(errs)
    }
  }

  // Student has left a Quiz unanswered
  showQuizIncompleteDialog = () => {
    this.dialogContent = (
      <div>
        <Markdown>
          {React.createElement(IconHelp, {
            style: {
              fill: 'rgb(237, 79, 79)',
              height: '1.5em',
              filter: 'drop-shadow(2px 2px 2px #999999)',
              float:'left',
              marginTop: -4,
              marginRight: 5,
            }
          })}
  {`Check the lesson and make sure all the **Test Your Knowledge** Quizzes are answered!
  `}
        </Markdown>
      </div>
    )
    this.dialogTitle = "Answer Quizzes First"

    this.setState({showDialog: true})
  }

  // Student appears to have met requirements, but clicks "No"
  showWtfDialog = () => {
    // Select a random quote
    const quote = selectRand(debuggingQuotes)
    const quoteStr =
`"${quote.quote}"  {margin-none}
>  *${quote.author}*`

    // Try stepping through your code...
    this.dialogContent = (
      <div>
        {/* Helpful tip */}
        <Markdown>
        <SkipNext style={{float:'left', marginTop: -4}} />
{`**Step** through your code to find where it's not doing what you expected.<br /><br />
`}
        </Markdown>

        {/* Debug Quote */}
        <div style={{backgroundColor: 'beige', padding: 10, borderRadius:10}}>
          <Markdown >
            {quoteStr}
          </Markdown>
        </div>
      </div>
    )
    this.dialogTitle = "Debugging Your Program"

    this.setState({showDialog: true})
  }

  showCelebrationDialog = () => {
    const celeb = selectRand(celebrationAnimations)

    this.dialogContent = (
      <Markdown>
        {celeb}
        {this.props.successMessage}
        <div style={{textAlign: 'right'}}>
          <Button
          color="primary"
          onClick={() => setTimeout(this.props.lessons.onNavRight, 0)}>  {/* Defer so scrollLessonTop() works */}
            Next Step
            <KeyboardArrowRight />
          </Button>
        </div>
      </Markdown>
    )

    this.dialogTitle = `Success!`
    if (this.props.xp) {
      this.dialogTitle += `  +${this.props.xp} XP`
    }

    this.setState({showDialog: true})
  }

  showHelpDialog = (errs) => {
    this.dialogContent = (
      <div>
      {('syntaxErrs' in errs) && (
        <Markdown>
          <div className='ace_gutter-cell ace_error' style={{height:20, float: 'left', transform:'scale(1.5)'}} />
{`### Check the Text Editor for *syntax error* markers!
CodeSpace won't load code onto your device if it doesn't seem to be well-formed Python.
Look on the **left** side of the **Editor** panel, and you'll see a *marker*.
If you *hover* your mouse over it, an error message will appear!
* **Note** Error *line number* is where the error was *noticed* - but keep in mind, the actual error
could be on a different line!
`}
        </Markdown>
      )}

      {('needsRun' in errs) && (
        <Markdown>
          <PlayIcon style={{float:'left', marginTop: -4}} />
{`### Run the code!
Looks like you haven't run the latest code shown in the Editor panel.
`}
        </Markdown>
      )}

      {('runtimeErrs' in errs) && (
        <Markdown>
          <div className='ace_gutter-cell ace_warning' style={{height:20, float: 'left', transform:'scale(1.5)'}} />
{`### Check the Text Editor for *runtime error* markers!
Some errors are only found while your code runs. Look on the **left** side of the **Editor** panel, and you'll see
a *marker*. If you *hover* your mouse over it, an error message will appear!
* **Note** Error *line number* is where the error was *noticed* - but keep in mind, the actual error
could be on a different line!
`}
        </Markdown>
      )}

      <hr />

      {('capitalization' in errs) && (
        <Markdown>
{`**capitalization** - check the capitalization of these symbols:
* ${errs.capitalization.map(n=>`\`${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqImports' in errs) && (
        <Markdown>
{`**imports** - verify that you have *imported* the following module(s):
* ${errs.reqImports.map(n=>`\`import ${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqFuncdefs' in errs) && (
        <Markdown>
{`**functions** - not finding these function(s) **def**ined:
* ${errs.reqFuncdefs.map(n=>`\`def ${n}()\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqCalls' in errs) && (
        <Markdown>
{`**calls** - are you calling the function(s) below?
* ${errs.reqCalls.map(n=>`\`${n}()\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqNames' in errs) && (
        <Markdown>
{`Not seeing you use:
* ${errs.reqNames.map(n=>`\`${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqStatements' in errs) && (
        <Markdown>
{`**statements** - expected to see you use:
* ${errs.reqStatements.map(n=>`\`${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqParams' in errs) && (
        <Markdown>
{`**parameters** - expected parameters(s) defined for your functions:
* ${errs.reqParams.map(n=>`\`${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      {('reqArgs' in errs) && (
        <Markdown>
{`**arguments** - you should be passing function arguments:
* ${errs.reqArgs.map(n=>`\`${n}\``).join('\n* ')}
`}
        </Markdown>
      )}

      </div>
    )
    this.dialogTitle = "Fix Errors"
    this.setState({showDialog: true})
  }

  roundButton = (text, color, onClick) => (
    <Button
      variant='fab'
      color={color}
      onClick={onClick}
      style={{
        marginRight: 10,
        fontSize: 18,
      }}
    >
      {text}
    </Button>
  )

  render() {
    let buttonNext = null
    let buttonYes = null
    let buttonNo = null
    let projectIsCompleted = false

    if (this.props.btnNext) {
      buttonNext = (
        <div
          style={{textAlign: 'right'}}
        >
          <Button
            color="primary"
            onClick={this.onNext}
            id={this.props.id ? this.props.id + '-next' : null}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        </div>
      )
    }
    if (this.props.btnYes) {
      buttonYes = this.roundButton('Yes', 'primary', this.onYes)
    }
    if (this.props.btnNo) {
      buttonNo = this.roundButton('No', 'default', this.onNo)
    }
    if (this.props.lessons.state.lessonModuleInst) {
      projectIsCompleted = userProgress.isProjectComplete(
        this.props.lessons.props.currentModule,
        this.props.lessons.state.lessonModuleInst.projectNames[this.props.lessons.state.currentProjectNum],
        this.props.lessons.state.lessonModuleInst.lessonNames(this.props.lessons.state.currentProjectNum).slice(0, this.props.lessons.state.currentLessonNum),
      )
    }

    return (
      <div style={{textAlign: 'center'}} id={this.props.id}>
        <h3>{this.props.prompt}</h3>

        {this.state.showGClassroomSubmit && this.props.btnGClassroom && this.props.lessons.state.lessonModuleInst && this.props.gFileId ?
          <ShareToGoogleClassroom
            gFileId={this.props.gFileId}
            stepName={`${this.props.lessons.state.currentProjectNum + 1}.${this.props.lessons.state.currentLessonNum + 1} ${this.props.lessons.state.lessonModuleInst.projectNames[this.props.lessons.state.currentProjectNum]}`}
            stepNext={this.onNext}
            projectIsCompleted={projectIsCompleted}
            currentProjectNum={this.props.lessons.state.currentProjectNum}
          />
          :
          null
        }
        {buttonNext}
        {buttonYes}
        {buttonNo}

        <MessageDialog
          title={this.dialogTitle}
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
        >
         {this.dialogContent}
        </MessageDialog>

      </div>
    )
  }
}

export default StepComplete
