// Project: Personal Billboard

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import Joyride from '../cbl-joyride'
import Button from 'material-ui/Button'
import 'react-html5video/dist/styles.css'
import heroImg from './assets/microbit-heart-trans.gif'
import choiceMp4 from './assets/Proj3-choice.mp4'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'
import futureMenuImg from './assets/FutureMenu.jpg'


export const BillboardImg = heroImg

class Billboard extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Personal Billboard {lesson-title}
___
#### In this project you’ll use the micro:bit LED display and buttons to make a *billboard* that shows others how you're feeling, a fun picture, or a short message.
`}
<img src={heroImg} alt=""
    style={{
      width: 200, margin: 'auto', display:'block',
    }}
/>
{`**Your code will be able to:**
* Show **images** that match your current mood.
* Scroll through **text** messages.
* Select between different images **and** messages while the code is running.

> On battery-power, you could make the micro:bit into a *wearable* electronic **badge** or a
**portable sign** for a wall or desk!
{centered}

**Project Goals:**
* Program the **buttons** to select from a series of Images to show.
* Make it easy to add lots more Images.
* Add the ability to mix **Text** messages with Image selection.
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class ImageSelector extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Image Selector {lesson-title}
___
As you've seen already, the micro:bit has lots of built-in icons like \`Image.HAPPY\` and \`Image.HEART\`.

Your first task is to make an image display that lets users select an **emotion** to match their mood by
pressing \`button_a\` and \`button_b\` to cycle through the choices.

*Can you use the coding ingredients from the last project to do this?*
`}
<LegendMessageKeyboard>
{`
* Create a new file (click **File** menu at top-left of screen, then select **New**)
* Name it **Billboard**
* Type the following code into the Editor
* ...remember, typing the \`# comments\` is optional!
\`\`\`
from microbit import *

# Loop forever – keep reading buttons and showing images!
while True:
    if button_a.was_pressed():
        display.show(Image.HAPPY)

    if button_b.was_pressed():
        display.show(Image.SAD)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
  {`While the micro:bit is running, press button A or B to select an image.`}
</LegendMessageRun>
<StepComplete
  prompt="Did your micro:bit display both the HAPPY and SAD images?"
  xp={25}
  successMessage=
{`## Way to Emote! {centered}
### Buttons and Icons in perfect harmony...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_a.was_pressed', 'button_b.was_pressed']}
  reqArgs={['Image.HAPPY', 'Image.SAD']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class MoreIcons extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Select More Images {lesson-title}
___
To make the buttons scroll through **more than two** choices, you must keep track of the **choice** in your code.

You could use a **number** to track which choice should be displayed, like this:
`}
<video src={choiceMp4} width="200" style={{margin: 'auto', display: "block"}} autoPlay="true" loop="true">
</video>
{`
How should you display an \`Image\` based on a **number** choice?
> Try using an \`if\` statement for each one!
\`\`\`
from microbit import *

$$$
$choice = 0

$while True:
$    if choice == 0:
$        display.show(Image.HAPPY)
$    if choice == 1:
$        display.show(Image.SAD)
$$$
    if choice == 2:
        display.show(Image.HEART)
    if choice == 3:
        display.show(Image.ASLEEP)

    if button_b.was_pressed():
        choice = choice + 1
\`\`\`
Add two more Images, so you have 4 choices in all!
`}
<LegendMessageConcept title="Concept: double equals sign">
{`
Why is there a "double equal" sign in the code?
* A "single equal" would mean "assignment".
  * Like assigning \`choice = 0\` at the top of the program.
* A "double equal" is a ==comparison operator==, just like \`>\` and friends.
`}
</LegendMessageConcept>
<LegendMessageKeyboard>
{`**Try to write this code without peeking at the full solution.**

Make a variable \`choice\` that adds +1 each time \`button_b\` was pressed.
> Be sure to indent all the \`if\` statements at the same level!
\`\`\`
from microbit import *

choice = 0

while True:
    if choice == 0:
        display.show(Image.HAPPY)
    if choice == 1:
        display.show(Image.SAD)
    if choice == 2:
        display.show(Image.HEART)
    if choice == 3:
        display.show(Image.ASLEEP)

$$$
$    if button_b.was_pressed():
$        choice = choice + 1
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<StepComplete
  prompt="Did your micro:bit display scroll through the images when button_b was pressed?"
  xp={25}
  successMessage=
{`## Selectomatic! {centered}
### Counting up with a variable is a good choice.
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_b.was_pressed']}
  reqArgs={['Image.HAPPY', 'Image.SAD']}
  reqStatements={['while', 'if']}
  reqNames={['choice==0',]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

const tourVariables = [
  {
    title: 'Show/Hide Debug Panel',
    text: "Click this button to show or hide the debug panel.",
    selector: '#tb-toggle-debug',
    position: 'bottom',
    style: {
      width: 200
    }
  },
  {
    title: 'Debug Panel',
    text: (
      <Markdown>
{`
This is where you can view the active ==variables== while *stepping through* your code.

Variables are listed by name, along with their ==data type== and **value**.

You can watch variable values change *as you step*!
> This can be a HUGE help when debugging...
`}
      </Markdown>
    ),
    selector: '#variable-pane-wrapper',
    position: 'top',
    style: {
      width: 400
    }
  },
]

class IconScroll extends Component {
  static propTypes = {
    showDebugPanel: PropTypes.bool.isRequired,
    onDebugClick: PropTypes.func.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  handleRunTour = () => {
    // console.log(this.props.showDebugPanel)
    if (!this.props.showDebugPanel) {
      this.props.onDebugClick()
    }
    this.tour.reset(true)
    this.setState({runTour: true})
  }

    render() {
    return (
      <div>
        <Joyride
        ref2={inst => {this.tour = inst}}
        steps={tourVariables}
        run={this.state.runTour}
        callback={this.tourCb}
        />
        <Markdown>
          {
`## Scroll Both Directions {lesson-title}
___
Can you add code to scroll **back** when **button A** is pressed?
`}
<LegendMessageKeyboard>
{`
Using what you’ve already learned, use \`if button_a.was_pressed()\` to **subtract 1** from choice.
\`\`\`
from microbit import *

choice = 0

while True:
    if choice == 0:
        display.show(Image.HAPPY)
    if choice == 1:
        display.show(Image.SAD)
    if choice == 2:
        display.show(Image.HEART)
    if choice == 3:
        display.show(Image.ASLEEP)

    if button_b.was_pressed():
        choice = choice + 1
$$$
$    if button_a.was_pressed():
$        choice = choice - 1
$$$
\`\`\`
`}
</LegendMessageKeyboard>
{`
Let me show you another awesome capability of the CodeSpace debugger: **Viewing your variables**.
`}
<div style={{textAlign: 'center'}}>
<Button
  variant='raised'
  onClick={this.handleRunTour}
>
  Take a Tour of the Variables Area
</Button>
</div>
<LegendMessageDebug
  title={`Watch your Variables`}
>
{`
Try **stepping through the code**. Press button **A** or **B** while the code is waiting somewhere in the while loop,
and watch the value of \`choice\` change as you step through the next pass of the loop.
`}
</LegendMessageDebug>
<StepComplete
  prompt="Does your micro:bit display cycle in both directions now?"
  xp={25}
  successMessage=
{`
## Excellent! {centered}
### This is a step in the right direction...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_a.was_pressed', 'button_b.was_pressed']}
  reqArgs={['Image.HAPPY', 'Image.SAD']}
  reqStatements={['while', 'if']}
  reqNames={['choice==0',]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Checkpoint1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Checkpoint-1 {lesson-title}
___
`}
      <Quiz
        lessons={this.props.lessons}
        prompt={(
        <Markdown>
{`What happens when you press **button_a** or **button_b** on the micro:bit while paused on a line of code in the debugger?
`}
        </Markdown>
        )}
        id={"Buttons while Stepping"}
        xp={5}
        answerRight={"The button was_pressed() is True on the next step."}
        answerWrong={["The button press is lost.", "The program advances to the next line of code."]}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt={(
        <Markdown>
{`What does your "scroll both directions" program do when you keep pressing **button_b** after \`Image.ASLEEP\` is shown?
`}
        </Markdown>
        )}
        id={"Scroll past the end"}
        xp={5}
        answerRight={"The 'choice' variable goes to 4 and keeps counting up."}
        answerWrong={["The 'choice' variable stops at 3 which is the number of the last Image.", "The 'choice' variable starts over at zero."]}
      />

      <Quiz
        lessons={this.props.lessons}
        prompt={(
        <Markdown>
{`What does the double-equals sign mean in \`if choice == 0\` ?
`}
        </Markdown>
        )}
        id={"Double Equals"}
        xp={5}
        answerRight={"Compares 'choice' to zero, branching when choice is zero."}
        answerWrong={["Assigns the variable 'choice' a value of zero.", "Selects a choice of either the symbol '==' or '0'."]}
      />

<StepComplete
  prompt="Your code is logical! Carry on."
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
  )
}
}

class WrapAround extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Wrap Around {lesson-title}
___
As you’ve probably noticed, if you keep pressing buttons, \`choice\` can go *past the ends* of your Image list. That might confuse users!

**Can you improve the program, and avoid this problem?**
* Make the \`choice\` variable wrap back around to the first Image (choice = 0) and keep advancing from there?
`}
<LegendMessageKeyboard>
{`Write the code to prevent \`choice\` going *above 3*.
> **...also** add code to keep it from going *below 0* !
\`\`\`
from microbit import *

choice = 0

while True:
    if choice == 0:
        display.show(Image.HAPPY)
    if choice == 1:
        display.show(Image.SAD)
    if choice == 2:
        display.show(Image.HEART)
    if choice == 3:
        display.show(Image.ASLEEP)

    if button_b.was_pressed():
        choice = choice + 1
$$$
$        if choice > 3:
$            choice = 0

    if button_a.was_pressed():
        choice = choice - 1
$        if choice < 0:
$            choice = 3
$$$
\`\`\`
`}
</LegendMessageKeyboard>
{`
`}
<LegendMessageRun />

<StepComplete
  prompt="Got a never-ending circle of Images in both directions?"
  xp={25}
  successMessage=
{`## Excellent! {centered}
### Nice coding! I'm getting dizzy...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_a.was_pressed', 'button_b.was_pressed']}
  reqArgs={['Image.HAPPY', 'Image.SAD']}
  reqStatements={['while', 'if']}
  reqNames={['choice==0',]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class EvenMoreImages extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Even More Images! {lesson-title}
___
### So many Images to display
> **...but it takes *so much code* to add more!**

It takes *two lines of code* for each Image you add, so your program is long and your fingers grow tired...

Wouldn’t it be great if you could just make a **list** of Images like this for your code?
\`\`\`
my_list = [Image.HAPPY, Image.SAD, Image.HEART]
\`\`\`
Well, in fact you can! It’s called... wait for it... a ==list==.
`}
<LegendMessageConcept title="Concept: Lists">
{`In Python you initialize a ==list== just as shown above, using **square brackets**.

> \`example_list = [item_0, item_1, item_2]\`

The **order** of each item in the list is *important*!
* Items are counted starting with **zero**.
* An item's order in the list is called its **index**.
* You can get any item from a list using its index!
> \`first = example_list[0]\`
`}
</LegendMessageConcept>
{`
To access one of the items in a list, use brackets like:
\`\`\`
# Assign the first item in the list to my_image
my_image = my_list[0]
\`\`\`
Lists have other cool features, including the ability to get the number of items:
\`\`\`
# Get the length of the list
num_choices = len(my_list)
\`\`\`
Can you think how you might improve your code using a list?
`}

<StepComplete
  prompt="Next step is to try it!"
  lessons={this.props.lessons}
  btnNext={true}
/>
        </Markdown>
      </div>
    )
  }
}

class EvenMoreImages2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Image List {lesson-title}
___
### Ready to improve your *Image Selector* code with a **list**?
* The "display choice" section of your code will be *much* simpler.
* All those \`if choice == X:\` statements go away!
`}
<LegendMessageKeyboard>
{`Now change your code to use a list instead of \`if\` statements.

**Create** your list **above** the while loop in your code.
> After all, the list only needs to get *created* **once** – not *every time* you loop.
\`\`\`
$$$
from microbit import *

choice = 0

$my_list = [Image.HAPPY, Image.SAD, Image.HEART, Image.ASLEEP]

while True:
$    my_image = my_list[choice]
$    display.show(my_image)

$$$
    if button_b.was_pressed():
        choice = choice + 1
        if choice > 3:
            choice = 0

    if button_a.was_pressed():
        choice = choice - 1
        if choice < 0:
            choice = 3
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun/>
<StepComplete
  prompt="Is your micro:bit properly displaying images from the list?"
  xp={40}
  successMessage=
{`## Simple and Clean! {centered}
### Removing lines of code is always nice.
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_b.was_pressed', 'button_a.was_pressed']}
  reqStatements={['while', 'if']}
  reqArgs={['my_image']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>

        </Markdown>
      </div>
    )
  }
}

class ListUsage extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## No *Magic Numbers*! {lesson-title}
___
### Use built-in *list* features to enhance your code!
Notice how the **length** of your list is *"baked into"* your code?

The **"magic number"** of **3** for the *length* of your list is making your code harder to read and maintain.
* If you add Images to the list, you have to modify the rest of the code.
* People reading your code might not know *why* the numbers **3** and **0** are being used to compare and assign \`choice\`.
`}
<LegendMessageKeyboard>
{`Make your code more *readable* **and** *maintainable*:
* Define variables for \`FIRST_CHOICE\` and \`LAST_CHOICE\` to replace **0** and **3**.  (*Why are these variables ==upper case==?*)
* Use the built-in \`len(my_list)\` to automatically get the length based on list content.
\`\`\`
from microbit import *

choice = 0

my_list = [
  Image.HAPPY,
  Image.SAD,
  Image.HEART,
  Image.ASLEEP,
  Image.SURPRISED
]

$$$
$# Define first and last indexes
$FIRST_CHOICE = 0
$LAST_CHOICE = len(my_list) - 1

while True:
    my_image = my_list[choice]
    display.show(my_image)

    if button_b.was_pressed():
        choice = choice + 1
$        if choice > LAST_CHOICE:
$            choice = FIRST_CHOICE

    if button_a.was_pressed():
        choice = choice - 1
$        if choice < FIRST_CHOICE:
$            choice = LAST_CHOICE
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Go ahead, add some *more* **Images** to your list.
* Lists make it *so* easy!
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why do you have to **subtract 1** from \`len(my_list)\` to get \`LAST_CHOICE\`?
`}
  </Markdown>
  )}
  id={"Subtract 1 from len"}
  xp={5}
  answerRight={"List indexes start at 0, so the index of the last item is len(my_list)-1."}
  answerWrong={["List indexes are negative numbers, so len(my_list)-1 is required.", "Because the program needs to count up as well as down in the list."]}
/>
<StepComplete
  prompt="Are your new-and-improved code features working as expected?"
  xp={40}
  successMessage=
{`## Awesome Code! {centered}
### Built-in features give your code *Super Powers!*
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_b.was_pressed', 'button_a.was_pressed', 'len']}
  reqStatements={['while', 'if']}
  reqArgs={['my_image', 'my_list']}
  reqNames={['LAST_CHOICE', 'FIRST_CHOICE']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class AddingText extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Text Time! {lesson-title}
___
Images are expressive, but **TEXT** can say much more!

You can define any message you want, by putting it in quotes:
\`\`\`
my_message = "Hi there!"
\`\`\`
The computer doesn’t care what’s between the quotation marks – it’s just a *string of characters*.

Changing your program to display text messages is very simple:
* \`display.show()\` doesn’t just accept Images – it can also handle **string** ==types==.
`}
<LegendMessageKeyboard>
{`
Try modifying your program to add a personalized message string to the list.

**Hint:** Keep messages short – you must wait for each to finish before the next button-press is handled.
> *Example:*
\`\`\`
from microbit import *

choice = 0

$$$
$my_list = [
$  "Ahoy",
$  Image.HAPPY,
$  Image.SAD,
$  Image.HEART,
$  Image.ASLEEP,
$  Image.SURPRISED
$]

$$$
# Define first and last indexes
FIRST_CHOICE = 0
LAST_CHOICE = len(my_list) - 1

while True:
    my_image = my_list[choice]
    display.show(my_image)

    if button_b.was_pressed():
        choice = choice + 1
        if choice > LAST_CHOICE:
            choice = FIRST_CHOICE

    if button_a.was_pressed():
        choice = choice - 1
        if choice < FIRST_CHOICE:
            choice = LAST_CHOICE
\`\`\`

`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Press **A** and **B** *buttons* on the *micro:bit*, and see if your **text** shows up when selected.

Test it and make sure you can select the **Images** also!
`}
</LegendMessageRun>
<StepComplete
  prompt="Got some TEXT mixed in with your Images?"
  xp={40}
  successMessage=
{`## Stringy! {centered}
### Images AND strings... Pretty cool when a function can handle different data types!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_b.was_pressed', 'button_a.was_pressed', 'len']}
  reqStatements={['while', 'if']}
  reqArgs={['my_image', 'my_list']}
  reqNames={['LAST_CHOICE', 'FIRST_CHOICE']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Scrolling extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const typeErrorRegex = /TypeError: can't convert 'MicroBitImage' object to str implicitly/
    const dialogContent = (
      <Markdown>
{`**Aha!** You have your answer!

> The \`display.scroll()\` function cannot accept an **Image**.
It only works with **string** types.

Continue the lesson to learn how to work around this limitation...
`}
      </Markdown>
    )
    this.props.interceptErrorCb([typeErrorRegex, dialogContent, "Type Error"])
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Smooth Scrolling {lesson-title}
___
Reading text on the micro:bit one .c.h.a.r.a.c.t.e.r. .a.t. .a. .t.i.m.e. gets *tiring*!

**You** can fix that!
{centered}

Take a look at the *Toolbox* info on the ==display== functions.
* Instead of using \`display.show(my_image)\`, use a different function:
\`\`\`
display.scroll(my_image)  # scroll a string horizontally
\`\`\`
`}
<LegendMessageWarning title="Heads Up!">
{`Certainly \`display.scroll("Ahoy")\` works for strings.

> *But* what will \`display.scroll(Image.HAPPY)\` do? That's not a **string**, it's an **Image** type.

*You could be headed for trouble here!*
`}
</LegendMessageWarning>
{`
...there's only **one** way to find out!
`}
<LegendMessageKeyboard>
{`
Change \`display.show()\` in your code to \`display.scroll()\`.

Press Button **A** or **B** and see if the \`Image\` works.
> (Remember you have to *wait* for the string to scroll before the button is detected.)
`}
</LegendMessageKeyboard>
<LegendMessageRun />

<StepComplete
  prompt="Press Next to fix the error..."
  lessons={this.props.lessons}
  btnNext={true}
/>

        </Markdown>
      </div>
    )
  }
}

class Scrolling2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Smooth Scrolling {lesson-title}
___
You could avoid this problem by replacing ALL your Images with strings.
> But **no!** You need both!

*Here’s a plan that might work:*

When you’re about to display something, check to see if it’s a string rather than an image. If it *is* a string, call \`display.scroll()\`, otherwise call \`display.show()\`.
`}
<LegendMessageKeyboard>
{`Replace your \`display.show(my_image)\` as shown below to do just that:
* **Wait! Don't just *copy* the code.** Think about where this fits in **your** code, and make the change without peeking below!
\`\`\`
from microbit import *

my_list = [
  "Ahoy",
  Image.HAPPY,
  Image.SAD,
  Image.HEART,
  Image.ASLEEP,
  Image.SURPRISED
]

# Define first and last indexes
FIRST_CHOICE = 0
LAST_CHOICE = len(my_list) - 1

choice = 0

while True:
    my_image = my_list[choice]

$$$
$    if type(my_image) == str:
$        display.scroll(my_image)
$    else:
$        display.show(my_image)

$$$
    if button_b.was_pressed():
        choice = choice + 1
        if choice > LAST_CHOICE:
            choice = FIRST_CHOICE

    if button_a.was_pressed():
        choice = choice - 1
        if choice < FIRST_CHOICE:
            choice = LAST_CHOICE
\`\`\`
`}
</LegendMessageKeyboard>

<LegendMessageConcept title="Concept: type checking">
{`
The built-in function \`type()\` is used to read the *type* of object a variable refers to.
* Each ==data type== has a name, such as **str** for strings and **int** for integers (whole numbers).
* The \`type()\` function returns the type name of the object it is given.
`}
</LegendMessageConcept>
<LegendMessageRun>
{`
Now test your program with a mixture of text strings and Images.
`}
</LegendMessageRun>
<StepComplete
  prompt="Mixing it up with some scrolling too?"
  xp={80}
  successMessage=
{`## Images and Scrolling text - Sweet! {centered}
No matter what *type* of data I throw at you,
### ...you can handle it! {centered}
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'button_b.was_pressed', 'button_a.was_pressed', 'len', 'type']}
  reqStatements={['while', 'if']}
  reqArgs={['my_image', 'my_list']}
  reqNames={['LAST_CHOICE', 'FIRST_CHOICE', 'str']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

  class Conclusion extends Component {

  render() {
    return (
      <div>
        <Markdown>
{`## Conclusion {lesson-title}
___
### Congratulations! There was a LOT to learn in this project!
`}
<img src={futureMenuImg} alt=""
  style={{
    width: "40%", margin: 'auto', display:'block', float:'right', marginLeft:10
  }}
/>
{`

**Just a few of the new *Tools* you used:**
* Managing **lists** of information.
* Handling "list index overflow/wrap" case.
* Inspecting different data types.
* Viewing *variables* in the **Debug Panel**.

**And you have built real-world code!**
* A *scrolling menu system* like the one you built is found in a lot of devices and
applications, from medical equipment, to toys.
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Rather than *wrap-around*, **stop** at each end of your list.
  * Display special "Begin/End" Images at either end.
* Add **music** to your selection "playlist"
  * *Hint*: the **type** for songs is ==tuple==.
`}
</LegendMessageTry>
{`
### Ready for another project?
`}
<StepComplete
  prompt="Press the Next button to advance..."
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>
        </Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
Billboard.stepId = 'Billboard'
ImageSelector.stepId = 'ImageSelector'
MoreIcons.stepId = 'MoreIcons'
IconScroll.stepId = 'IconScroll'
Checkpoint1.stepId = 'Checkpoint1'
WrapAround.stepId = 'WrapAround'
EvenMoreImages.stepId = 'EvenMoreImages'
EvenMoreImages2.stepId = 'EvenMoreImages2'
ListUsage.stepId = 'ListUsage'
AddingText.stepId = 'AddingText'
Scrolling.stepId = 'Scrolling'
Scrolling2.stepId = 'Scrolling2'
Conclusion.stepId = 'Conclusion'

export const billboard = [
  Billboard,
  ImageSelector,
  MoreIcons,
  IconScroll,
  Checkpoint1,
  WrapAround,
  EvenMoreImages,
  EvenMoreImages2,
  ListUsage,
  AddingText,
  Scrolling,
  Scrolling2,
  Conclusion,
]
