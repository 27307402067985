// Project: SoundsFun

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  //LegendMessageWarning,
  //LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
//import Quiz from './Quiz'
import SoundBoard from './assets/SoundBoard.jpg'
import Composer from './assets/Composer.jpg'
import Siren from './assets/Siren.jpg'
import PeaceOut from './assets/PeaceOut.jpg'
import Concert from './assets/Concert.jpg'
import SpeakerClipsPic from './assets/speaker-clips.png'

export const SoundsFunImg = SoundBoard

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Sounds *Fun!* {lesson-title}
___
`}
<img src={SoundBoard} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`#### In this project you'll take a deep dive into making your own **Music** and **Sound Effects**.
You've already learned how to attach a *speaker* to the micro:bit and play some built-in ==music==.
* Naturally you'd like to write your **own songs**!
* And what about other sound effects?

The ==music== module has some really cool functions that let you take full control and *really* customize your sounds.

> It's time to ***get creative*** and *make some noise!*
`}
<div style={{clear:'left'}} />
{`
#### Project Goals:
* Change the ***tempo*** for some of your favorite **micro:tunes**.
* Code your **own** *favorite song* using *Scientific Pitch Notation*.
* Experiment with **pitch *frequencies*** and how they relate to **music**.
* Make a **Siren** that could ***stop traffic*** *...with just a few more Watts!*
* Build a *spacy* **musical instrument**!

### Grab your speaker and connect-up!

*You know the drill by now.* {centered}
`}
<img src={SpeakerClipsPic} alt=""
    style={{
      width: '40%', margin: 'auto', display:'block',
    }}
/>
</Markdown>
        <StepComplete
          prompt="Be ready, it might get loud..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class Sounds1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Music Composed with Code {lesson-title}
___
`}
<img src={Composer} alt=""
    style={{
      width: "35%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`The first new function to try from the ==music== module is \`set_tempo()\`:
* This sets the ***tempo*** of \`music.play()\`
  * **ticks** is ticks per beat.
  * **bpm** is beats per minute.

> \`music.set_tempo(ticks=4, bpm=120)\`

If you're familiar with music, you might recognize **beats per minute** as the unit of **tempo**.
> *Think of it as the **speed** of a song.*

You'll examine **ticks** in the next step. For now, just try experimenting with **bpm** in the following code.
`}
<LegendMessageKeyboard>
{`Create a new file and name it **SoundCheck**.

Change the **bpm** value, and see how it affects your *favorite* song.
* Higher values are *faster!*
\`\`\`
import music

# Try changing the bpm - "beats per minute"
music.set_tempo(ticks=4, bpm=120)
music.play(music.NYAN)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`You'll probably want to try a few values of **bpm**.
* How fast can it go?
* How *slow* can you ***endure***?
`}
</LegendMessageRun>
{`#### Now to make your own *melody*:
Songs for \`music.play()\` are actually just ==lists== of ***notes***!
* Each note is a ==string== describing **pitch** and **duration**.
* The **pitch** is in *Scientific Pitch Notation*, just the *letter* and *octave* of the note.
  * *Ex:* **c4** is *middle-C*. **c5** is *one octave* up, etc.
* The **duration** is how many ***ticks*** this note gets.
  * See \`set_tempo()\` above for details on that.
  * *Ex:* A 2-tick note would play for *half* a beat with *4-ticks* per beat.

The ==string== to play a middle-C for 2-ticks is: **"c4:2"**

To make it easier to write songs, you can omit the **octave** and/or **ticks** if they're the same as the prior note.
The following example *tune* will illustrate:
\`\`\`
tune = [
  'c5:4',  # play the C above middle-C for 4-ticks
  'e:2',   # play E in the same octave for 2-ticks
  'g',     # play G - same octave, same ticks (2)
  'c6:4'   # play C an octave higher, 4-ticks
]
\`\`\`
`}
<LegendMessageKeyboard>
{`Erase your *tempo* change code and try *custom* tunes.

The tune below is a simple musical *arpeggio*.
* *(Same as the example above, just on a single line!)*
\`\`\`
import music

$tune = ['c5:4', 'e:2', 'g', 'c6:4']
$music.play(tune)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Play the arpeggio... But try some other tunes!
* This is your chance to *compose* your own *masterpiece!*
* Here's a famous one:
> \`tune = ['g4:1', 'c5:1', 'e:1', 'g:2', 'e:1', 'g:6']\`
`}
</LegendMessageRun>
<StepComplete
  prompt="Have you escaped the built-in tunes?"
  xp={25}
  successMessage=
{`## Freeeeeedom!! {centered}
Now you can make ***any*** melody you can imagine!

Hmmm... except it *would* be nice to ***bend*** notes like the *Blues* masters. Maybe in the next step...
`}
  reqImports={['music']}
  reqCalls={['music.play']}
  reqFuncdefs={[]}
  reqStatements={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Sounds2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Pitch Perfect {lesson-title}
___
What defines a **note** in the *tunes* you've played on the micro:bit?
* The **pitch** &rarr; the ***frequency*** of the sound.
* The **duration** &rarr; how ***long*** it is played.

But sometimes you want ***more control*** over the *sound* than you can get with the standard \`music.play()\` song format.
* The \`music\` module delivers! With the \`music.pitch()\` function you control:
  * **frequency** in Hertz (cycles per second)
  * **duration** in milliseconds (thousandths of a second, or -1 for "continuous")
> \`music.pitch(frequency, duration)\`

#### How do *notes* relate to *frequencies*?
A common pitch used by orchestras for tuning is **a4**, the A above middle-C.
* This is **440 Hertz**, also known as **A440** *Concert Pitch*

Can you prove that *440 Hertz* is really the same as **a4**?
`}
<LegendMessageKeyboard>
{`Write **code** to test *Concert Pitch* on the micro:bit.
* First play an **a4** using \`music.play()\`.
* Then use \`music.pitch()\` to play a 440 Hz tone. *(Hz is the abbrevation for Hertz)*
\`\`\`
import music

music.play(['a4:4'])
music.pitch(440, duration=500)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Play it a couple of times.
* Do the pitches sound the same?
`}
</LegendMessageRun>
<StepComplete
  prompt="Have you verified Concert Pitch?"
  xp={25}
  successMessage=
{`## Right in Tune! {centered}
There's a symphony that I hear in your code, sets my circuits reeling!
`}
  reqImports={['music']}
  reqCalls={['music.play', 'music.pitch']}
  reqFuncdefs={[]}
  reqStatements={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Sounds3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## The Frequency Spectrum {lesson-title}
___
With \`music.pitch()\` you aren't limited to just the frequencies of *keys on a piano*.
* You can specify *any frequency* you want!
  * ...provided it's in the micro:bit's **range** of: ***1Hz &rarr; 3906Hz***

Using a \`for\` loop and the Python \`range()\` function, write a program to *sweep* across the
spectrum of possible frequencies.
`}
<LegendMessageConcept title={'Concept: range()'}>
{`#### A *range* of numbers to ==loop== through
Variations of this helpful Python *built-in*:
\`\`\`
range(stop)  # The numbers from 0 to 'stop' - 1
range(start, stop)  # The numbers from start to 'stop' - 1
range(start, stop, step)  # The numbers from 'start',
                          # stepping by 'step' intervals,
                          # stopping before 'stop'.
\`\`\`
***Examples:***
* **range(5)** &rarr; [0, 1, 2, 3, 4]
* **range(2, 4)** &rarr; [2, 3]
* **range(20, 30, 3)** &rarr; [20, 23, 26, 29]
* **range(12, 5, -2)** &rarr; [12, 10, 8, 6]
`}
</LegendMessageConcept>
{`***Now it's your turn!*** {centered}
`}
<LegendMessageKeyboard>
{`Sweep across the frequency range of the micro:bit.
* Use **-1** for the *duration* of the pitch, *meaning **forever***
* Don't forget the \`music.stop()\` at the end, or you'll have a *constant tone* when the program ends.
\`\`\`
import music
# Sweep across the frequency range
for freq in range(3906):
    music.pitch(freq, -1)
music.stop()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`From *low* to *high*, *one Hertz at a time!*
`}
</LegendMessageRun>
<StepComplete
  prompt="Are you hitting those high and low notes?"
  xp={25}
  successMessage=
{`## Nice **range()**! {centered}

`}
reqImports={['music']}
reqCalls={['music.pitch', 'range', 'music.stop']}
reqFuncdefs={[]}
reqStatements={['for']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Sounds4 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Emergency Siren {lesson-title}
___
`}
<img src={Siren} alt=""
    style={{
      width: "35%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### Time to put those *frequency sweeping* skills to good use!
* How many different kinds of **siren** have you heard?
* They use a *range of frequencies* to get attention!

Maybe ***you*** can *get some attention* with a realistic **Siren**
sound effect. {centered}

**Program Design:**
* Only play the *siren* when **Button A** is *held down.*
* The siren should *sweep up* then *sweep down* over a *frequency range*
`}
<LegendMessageKeyboard>
{`Create a new file and name it **Siren**.

Code a \`while loop\` checking if **button_a** is pressed.
* If the button is pressed, start the siren:
  * Use *two* \`for loops\` to *sweep up* and *sweep down*
\`\`\`
# Siren
from microbit import *
import music
while True:
    if button_a.is_pressed():
        for freq in range(400, 500):
            music.pitch(freq, -1)
            sleep(10)
        for freq in range(500, 400, -1):
            music.pitch(freq, -1)
            sleep(10)
    else:
        music.stop()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
`}
</LegendMessageRun>
<StepComplete
  prompt="Having fun with your siren?"
  xp={25}
  successMessage=
{`## Nice Effect! {centered}
#### Maybe you should add this to your **Alarm System**!
`}
reqImports={['music', 'microbit']}
reqCalls={['music.pitch', 'range', 'music.stop', 'button_a.is_pressed']}
reqFuncdefs={[]}
reqStatements={['for', 'while']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Sounds5 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Freaky Wavy Instrument {lesson-title}
___
`}
<img src={PeaceOut} alt=""
    style={{
      width: "35%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### Are you ready to make a *Musical Instrument*?
Well, this won't exactly be a *traditional* instrument.
* And it, um, may not be very *melodic*...
* **BUT** it's pretty cool, and very versatile :-)

***Maybe with a bit more code it could be amazing!*** {centered}

This **Wavy** device is inspired by the ***Theremin***, an electronic instrument where the *pitch* and *volume* are controlled
by waving your hands near two *antennas*.
* Your version will use the ==Accelerometer== to control **pitch**

**Program Design:**
* A *contintuous tone* should play from the speaker.
  * Vary the **pitch** from 10 Hz up to around 2000Hz based on the ***tilt*** in the **X-axis**.
* If **Button A** is held, *silence the speaker.* (rest!)
* If **Both Buttons** are held, *end the program*.
`}
<LegendMessageKeyboard>
{`Create a new file and name it **WavySound**.

Inside the main \`while loop\`:
* Check if **button_a** is pressed, to *stop the sound*.
  * ...and if **both buttons** are pressed, \`break\` out of the loop!

The main task is to read the accelerometer **X-axis** value, and convert it to a range from
*10 to 2048 Hz*.
* Simply adding *1024* to the acceleration value might work, but it could dip below **10**.
* Use an \`if\` test to keep the *minimum frequency* at **10**.
\`\`\`
from microbit import *
import music

while True:
  if button_a.is_pressed():
      music.stop()  # Pause sound if A
      if button_b.is_pressed():
          break  # End program if A & B
  else:
      # Get x-axis value
      # At rest it's -1024 to +1024
      x = accelerometer.get_x()

      # Make it range 10 to 2048 Hz
      freq = x + 1024
      if freq < 10:
          freq = 10

      music.pitch(freq, -1)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Play your **Freaky Wavy Instrument** with *pride*!!
`}
</LegendMessageRun>
<StepComplete
  prompt="Ready for your audition?"
  xp={25}
  successMessage=
{`## GROOOOOVY MAN!! {centered}
Your ***code*** is **Far Out!**
`}
reqImports={['music', 'microbit']}
reqCalls={['music.pitch', 'music.stop', 'button_a.is_pressed', 'button_b.is_pressed', 'accelerometer.get_x']}
reqFuncdefs={[]}
reqStatements={['while', 'break', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <div>
        <Markdown>
          {
`## And the crowd goes wild! {lesson-title}
___
`}
        <img src={Concert} alt=""
          style={{
            width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft:10
          }}
        />
{`
### You have leveled-up your **computer sound expertise!**
* You can adjust the tempo of *built-in* or *custom* ==music==.
* You can create your *own* ***tunes*** for any program you write.
* **AND** you have *endless* possibilities for **sound effects** with ***code***!

### Practical applications for this type of code *abound!*
* Electronic musical instruments.
* Sound effects for *games* and *movies*.
* *Alerting* sounds - like *sirens* and *annunciators*.
* User feedback sounds for computer applications.
* Virtual reality and ==simulation== programs.
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Code a favorite song as a custom \`music.play()\` **tune**.
* In the **Siren** program, make **button_b** cycle through different types of *siren* sounds.
* Use the ==Radio== to synchronize sounds among *two or more* micro:bits.
  * Play a concert *in harmony*!
* Make the **Freaky Wavy Instrument** a little less ***Freaky***:
  * Use the *accelerometer* to select from a ==list== of **notes**, rather than a continous frequency range.
  * More like a *flute* than a *theremin*...
`}
</LegendMessageTry>
        </Markdown>
        <StepComplete
          prompt="Rock on completely..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

Prelude.stepId = 'Prelude'
Sounds1.stepId = 'Sounds1'
Sounds2.stepId = 'Sounds2'
Sounds3.stepId = 'Sounds3'
Sounds4.stepId = 'Sounds4'
Sounds5.stepId = 'Sounds5'
Finale.stepId = 'Finale'

export const soundsFun = [
  Prelude,
  Sounds1,
  Sounds2,
  Sounds3,
  Sounds4,
  Sounds5,
  Finale,
]
