// Project: Random Answers Bot

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  // LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageConcept,
  LegendMessageInteract,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import heroImg from './assets/Questions.gif'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'

export const RandAnsImg = heroImg

class RandAns extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Answer Bot {lesson-title}
___
`}
<img src={heroImg} alt=""
  style={{
    width: 200, margin: 'auto', display:'block', float:'right'
  }}
/>
{`
#### In this project you will create a random answer generator.

Instead of selecting messages yourself, like in the previous project, this time you'll have the
computer decide for you!

You'll never have to waste time on all those unimportant, random decisions of life again.
Just press the button and let your **Answer Bot** decide :-)

**Project Goals:**
* Program the micro:bit to choose and display a ==random number== when a button is pressed.
* Change the program to display a random text message from a list of possible answers.
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class DisplayNumber extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const typeErrorRegex = /TypeError: /
    const dialogContent = (
      <Markdown>
{`**Oh!** You might have guessed this would happen!

> The \`display.show()\` and \`display.scroll()\` functions cannot accept an **int**.

They definitely work with **string** types, though.

**Continue to the next step of the lesson** to learn how to work around this limitation...
`}
      </Markdown>
    )
    this.props.interceptErrorCb([typeErrorRegex, dialogContent, "Type Error"])
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Display a Number {lesson-title}
___
To begin, you will pick a fixed number and show it on the display.
`}
<LegendMessageWarning title={`Heads up!`}>
{`The code below has an error which you will correct later.`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`Create a new file and name it **"Answer_Bot"**

> Type in the code below.
\`\`\`
from microbit import *

number = 1
display.scroll(number)
\`\`\`
`}
</LegendMessageKeyboard>
{`Can you spot the error before you run it? You *may* remember seeing this in a previous project!
`}
<LegendMessageRun>
{`Try running the code. Make sure you see the **Error** pop-up.
`}
</LegendMessageRun>
<StepComplete
  prompt="Show me how to fix the code!"
  lessons={this.props.lessons}
  btnNext={true}
/>
        </Markdown>
      </div>
    )
  }
}

class FixUp extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Fix it Up {lesson-title}
___
#### It’s time to fix the code and make the number show up on the display!
To do this, you must convert the **integer** type into a **string** type.

> The built-in function ==\`str()\`== is made for that!
`}
<LegendMessageKeyboard>
{`
Modify the code to use \`str()\` to convert the \`number\` variable to **string**.
\`\`\`
from microbit import *

number = 1
$display.scroll(str(number))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Try some different numbers, just for fun!
`}
</LegendMessageRun>
<StepComplete
  prompt="Does the number scroll across your display? "
  xp={25}
  successMessage=
{`## Nice Numerals! {centered}
### It's good to get a firm footing before bringing in the new stuff...
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str']}
  reqArgs={['str(number)', 'number']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Randomize extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Randomize! {lesson-title}
___
### It's time to get *RANDOM* in here!
You'll be using the **random** Python module, so look for a new ==import== statement.
`}
<LegendMessageConcept title="Concept: random">
{`Python's ==random== module makes it easy to work with random numbers.
\`\`\`
import random
# Get random number from 0 to 9
x = random.randrange(10)
\`\`\`
**Notice:** Just like **lists** count from **0**, so does \`randrange(N)\`.
> So a *range* of **10** numbers gives values **0** through **9**.
`}
</LegendMessageConcept>
<LegendMessageKeyboard>
{`
Modify your code to display a **random number** rather than your fixed number from the previous step.
\`\`\`
from microbit import *
$import random

$number = random.randrange(10)
display.scroll(str(number))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`**You should see a *random* number when you run the program.**

Run the program a few times, to make sure you see different numbers. Sometimes you may see
the same number repeat, but that's all part of the randomness!
`}
</LegendMessageRun>
<Quiz
        lessons={this.props.lessons}
        prompt={(
        <Markdown>
{`**Hold up!** Why does \`range(10)\` only go up to **9**??
`}
        </Markdown>
        )}
        id={"Zero based Ranges"}
        xp={5}
        answerRight={"Ranges start at 0 (zero), and there are 10 values from 0-9."}
        answerWrong={["The variable itself consumes one integer, so there are only 9 values in range(10).", "The range(10) also includes -1 so the max is 9."]}
/>
</Markdown>
<StepComplete
  prompt="Are you seeing various numbers?"
  xp={25}
  successMessage=
{`## That's SO *random!* {centered}
### ...in a good way, naturally.
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.scroll', 'random.randrange', 'str', ]}
  reqArgs={['str(number)']}
  reqStatements={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class RandomIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Mix Things Up a micro:bit {lesson-title}
___
**Re-starting** the program every time you want a new random number isn't very user-friendly.
> *You can fix that!*
`}
<LegendMessageKeyboard>
{`Modify your code to randomly select a number from 0 to 9 each time **Button A** is pressed.

*Make sure to ==indent== your code inside a loop, checking for button presses.*
\`\`\`
from microbit import *
import random

$while True:
$    if button_a.was_pressed():
        number = random.randrange(10)
        display.scroll(str(number))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun></LegendMessageRun>
<LegendMessageInteract>
{`Press **Button A** a few times. A random number should show up each time.
`}
</LegendMessageInteract>
        </Markdown>
<StepComplete
  prompt="Does a random number show up when Button A is pressed?"
  xp={25}
  successMessage=
{`## Rand-on-Command! {centered}
I love it when a plan comes together!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.scroll', 'button_a.was_pressed', 'random.randrange', 'str', ]}
  reqArgs={['str(number)']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
      </div>
    )
  }
}

class AddResponses extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Give the Robot an Opinion {lesson-title}
___
Now that you have made an *amazing* **random number display**, it’s time to make the micro:bit answer a question like:
**"What is your favorite food?"**  ...and display a *random* answer.

### This a perfect place for a *list*!
* Use the *random number* as an **index** into a *list* of **Answers**.
`}
<LegendMessageTry title="Personalize Me!">
{`
This is **your** *Answer Bot*, so you can make it answer a *different* question:
* Favorite sports team
* Best dance moves
* Magic 8 ball answers...
* *You decide!*
`}
</LegendMessageTry>
{`**Now**, get your list of *Answers* ready and code this thing!
`}
<LegendMessageKeyboard>
{`Make **Button A** display a random **string** from *your* list
\`\`\`
from microbit import *
import random

$answers = [
$  "Pizza",
$  "Burger",
$  "Salad"
$]

while True:
    if button_a.was_pressed():
$        count = len(answers)
$        index = random.randrange(count)
$        display.scroll(answers[index])
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<Quiz
        lessons={this.props.lessons}
        prompt={(
        <Markdown>
{`What is the \`count\` variable doing for you in this program?
`}
        </Markdown>
        )}
        id={"Scratchpad Variables"}
        xp={5}
        answerRight={"The 'count' variable stores len(answers) to give to the 'randrange' function."}
        answerWrong={["The 'count' variable automatically scans the list and counts the number of items.", "The 'Count' is a beloved character in educational television."]}
/>
<LegendMessageInteract>
{`
Press **Button A** and see what the micro:bit has to say!
`}
</LegendMessageInteract>
<StepComplete
  prompt="Are you finally getting some answers?"
  xp={25}
  successMessage=
{`## Insert Random Quote Here! {centered}
"*Coding* + *Creativity* = *Crazy Delicious!*"
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.scroll', 'button_a.was_pressed', 'random.randrange', 'len']}
  reqArgs={['answers', 'count']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Real World Applications {lesson-title}
___
#### Now you know how that "Random Quote of the Day" website does it.

But seriously, the *fundamentals* of this code are really important to a **lot** of applications!
{centered}

**==Random number== code is crucial for:**
* Video games
* Secure password encryption
* Real-world simulator trainers
* Scientific statistical sampling
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Add random **Images** to the list.
* Create the "Magic 8-ball".
* Make **Button B** choose from a *different* list.
* Remove the *button* control, and just **continuously** cycle random messages.
  * *(delay for effect in between)*
* Change your code to use \`random.choice()\` to pick from your list. See the ==random== Toolbox info.
`}
</LegendMessageTry>
<StepComplete
  prompt="Ready for the next project?"
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>
        </Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
RandAns.stepId = 'RandAns'
DisplayNumber.stepId = 'DisplayNumber'
FixUp.stepId = 'FixUp'
Randomize.stepId = 'Randomize'
RandomIntro.stepId = 'RandomIntro'
AddResponses.stepId = 'AddResponses'
Applications.stepId = 'Applications'

export const randAns = [
  RandAns,
  DisplayNumber,
  FixUp,
  Randomize,
  RandomIntro,
  AddResponses,
  Applications,
]
