
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'

import PrepareLanderIntroImg from './assets/iStock-698621996.jpg'
import FinalPrepareLanderImg from './assets/iStock-698623358.jpg'
import PrepareStateLanderImg from './assets/iStock-647072096.jpg'

import PullUpImg from './assets/iStock-1159168752.jpg'
import LandingGearImg from './assets/iStock-846534414.jpg'
import DetectGroundImg from './assets/iStock-1205554119.jpg'
import CrewDisplayImg from './assets/iStock-1052892762.jpg'
import TouchdownImg from './assets/iStock-135649264.jpg'

import LanderStateDiagramImg from './assets/lander_state_diagram.jpg'

import ObjectSensorLabelsImg from './assets/object_sensor_labels.jpg'

import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'
import OctopusSwitchFiveVolts from './assets/JoxT6k21-5v.jpg'


import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
//  LegendMessageWarning,
  LegendMessageInteract,
//  LegendMessageTry,
} from '../Legends'

export const PrepareLanderImg = GoogleClassroomImg

class ProjectPrepareLander extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Prepare Lander! {lesson-title}
___
### This project provides a tutorial on the object sensor and ties in multiple different peripherals!
`}
<img src={PrepareLanderIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The crew has survived their long trip to Mars. 
* Now they need to enter the atmosphere and land safely on the surface.

Your task is to incorporate a few systems to prepare the lander, sense the surface, and provide notifications!

**You can make the crew's first experience on Mars a great one with a smooth landing sequence!**

**Project: Prepare Lander** will guide you through preparing the lander for atmospheric entry and landing.

#### Project Goals:
* Learn about the Object Sensor
* Tie in together a servo and the NeoPixels in a landing system
* Create a simple ==state machine==
* Ease the crew's entry sequence for landing on Mars!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class LanderPhases extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Plan of Attack {lesson-title}
___
#### **Project: Prepare Lander** will require many different components!

You should start by taking a look at the different phases (or states) of the landing on Mars:
`}
<div style={{ margin: 'auto', display:'flex', marginRight:10, marginLeft: 10, marginBottom: 10 }}>
<img src={PrepareStateLanderImg} alt="" style={{ width: "30%", marginBottom: 10 }} />
<div style={{ marginLeft: 10, flex: 1 }} >
<Markdown>
{`
**The first phase - "Init" phase**
* In this phase the lander will be about to enter the Mars Atmosphere
* The crew will be notified with a yellow light
* The landing gear will be completely retracted into the craft
`}
</Markdown>
</div>
</div>
<div style={{ margin: 'auto', display:'flex', marginRight:10, marginLeft: 10, marginBottom: 10 }}>
<img src={PrepareLanderIntroImg} alt="" style={{ width: "30%", marginBottom: 10 }} />
<div style={{ marginLeft: 10, flex: 1  }} >
<Markdown>
{`
**The second phase - "Prepare" phase**
* This phase will be entered when the craft detects the ground
* The crew will be notified with a red light
* The landing gear will be extended and ready for landing
`}
</Markdown>
</div>
</div>
<div style={{ margin: 'auto', display:'flex', marginRight:10, marginLeft: 10, marginBottom: 10 }}>
<img src={FinalPrepareLanderImg} alt="" style={{ width: "30%", marginBottom: 10 }} />
<div style={{ marginLeft: 10, flex: 1  }} >
<Markdown>
{`
**The third phase  - "Landed" phase**
* This will be entered when the craft touches down on the surface
* The crew will be notified with a green light
`}
</Markdown>
</div>
</div>
</Markdown>
        <StepComplete
          prompt="Press NEXT to get started!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}


class ObjectSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Connect the Object Sensor! {lesson-title}
___
### Prepare the platform to build your Mars lander!

The Mars lander will be able to:
* Display landing state to the crew with lights
* Sense the surface and extend the landing gear
* Detect touchdown on the planet
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **PrepareLander**
`}
</LegendMessageInteract>
<LegendOctoHeader 
  pin={0}
  peripheral={14} // 14 = Object Sensor
  markdown={`
  Connect the **Object Sensor** to **position 0** on your ==octopus:bit==!

  The **Object Sensor** is sometimes called a **Hunt Sensor**.
  `}
/>
{`
The object sensor is a ==peripheral== that:
1. Emits an IR (Infrared) light
2. Detects the reflected IR energy with a phototransistor
3. Outputs a digital 1 (HIGH) for Detected or 0 (LOW) for Not Detected
`}
<img src={ObjectSensorLabelsImg} alt="Object Sensor Labels" style={{width: '40%', display: 'block', margin: 'auto'}} />

{`
It can be used to:
* Detect nearby obstacles
* Distinguish between black and white colors (Line Sensing)
* Count pulses as an object passes by (Wheel Encoders)
`}
<Quiz
        lessons={this.props.lessons}
        prompt="What is one thing the object sensor CANNOT detect?"
        xp={5}
        answerRight={"Motion 10 Feet Away"}
        answerWrong={["Close Obstacles", "Black Line on a White Background"]}
      />

</Markdown>
        <StepComplete
          prompt="Press NEXT if the object sensor is connected!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class AdjustDistance extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Adjust the Object Distance {lesson-title}
___
#### Set the Ideal Distance for the Object Sensor!
`}
<img src={ObjectSensorLabelsImg} alt="Object Sensor Labels"     style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`
The object sensor contains a small blue potentiometer on the top side.
* This has a white phillips (cross) screw inside
* Adjusting the screw will make the sensor more or less "sensitive"

The goal is to adjust to the correct distance.
* The ideal distance for the object sensor is 2.5 millimeters (this distance was determined by the chip manufacturer)

Adjusting the object sensor:
* Full Clockwise = Not Sensitive Enough
* Full Counterclockwise = Too Sensitive
`}
<LegendMessageInteract>
<Markdown>
{`
Get a small phillips (cross) screwdriver to adjust the sensor.

Start by turning the phillips screw halfway between full clockwise and full counterclockwise.

**Adjust your object sensor's sensitivity:**
1. Hold the sensor above a white surface (with the blue potentiometer facing up and the IR sensor facing down).
2. Move the sensor slowly up and down.
3. Adjust the phillips screw until the Red LED next to the potentiometer turns ON precisely at the threshold of 2.5 millimeters.

`}
</Markdown>
</LegendMessageInteract>

<Quiz
        lessons={this.props.lessons}
        prompt="What is the ideal distance to adjust the object sensor to?"
        xp={5}
        answerRight={"2.5 mm"}
        answerWrong={["5 mm", "1 inch"]}
      />

</Markdown>
        <StepComplete
          prompt="Press NEXT if you have adjusted the object sensor's sensitivity!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}


class CrewDisplay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Crew Display! {lesson-title}
___
### Attach the NeoPixels to create a display for the crew!
`}
<img src={CrewDisplayImg} alt="Crew Display"     style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`

You will start by showing the status of the object sensor.
* Later, you will be able to use the multi-color display to show different Lander States!
`}
<LegendOctoHeader 
  pin={16}
  peripheral={10} // 10 = NeoPixels 8 RGB
  hideWire={true}
  markdown={`
Connect the **8 RGB NeoPixels** to **position 16** on your ==octopus:bit==!

It can be attached directly to the ==octopus:bit== or you can use the three loose wires provided in the ==peripheral kit==.

**The back side of the NeoPixel ring has three labels:**
* **DI** or *data input* is connected to the **Blue** pin.
* **V** or *voltage* is connected to the **Red** pin
* **G** or *ground* is connected to the **Black** pin
`}
/>
<LegendMessageInteract title={'Switch it to 5V'}>
<Markdown>
{`
Locate the **VCC Switch** on the **octopus:bit** and place it in the **5V** position.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <img src={OctopusSwitchFiveVolts} alt="OctopusBit Switch" style={{width: '30%'}} />
</div>
</LegendMessageInteract>

<LegendMessageKeyboard>
<Markdown>

{`
Add code to set the NeoPixel display to yellow:
1. Import \`microbit\` and \`neopixel\` at the top of your file
2. Add the following RGB Colors as variables:
    * \`RGB_YELLOW = (20, 20, 0)\`
    * \`RGB_RED = (20, 0, 0)\`
    * \`RGB_GREEN = (0, 20, 0)\`
3. Instantiate a \`np\` NeoPixel variable for pin16
4. Create a \`set_lighting\` ==function== that take an \`rgb_color\` ==parameter== and turns the fourth pixel a color
5. Call the \`set_lighting\` function and turn the NeoPixel \`RGB_YELLOW\`
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0)
RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)

np = NeoPixel(pin16, 8)

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()
    
set_lighting(RGB_YELLOW)
\`\`\`
`}
</Markdown>
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
          <StepComplete
            prompt="Is the crew display Yellow?"
            xp={5}
            successMessage=
{`## Display is hooked up! {centered}
### You are ready to integrate the display into the Lander!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting']}
            reqCalls={['set_lighting', 'np.show']}
            reqArgs={['pin16', '8', 'RGB_YELLOW']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class DetectObjects extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Detect the Ground! {lesson-title}
___
#### Add some code to read the digital output of the object sensor!
`}
<img src={DetectGroundImg} alt="Detect Ground"     style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`
Create a \`read_ground_sensor\` ==function== to read the object sensor.
\`\`\`
def read_ground_sensor():
    return pin0.read_digital()
\`\`\`

Add a \`while\` ==loop== to constantly read the ground sensor.

Last, change the color of the NeoPixel from Yellow to Green when the object sensor detects ground!
* The object sensor will output a 1 (HIGH) value when \`GROUND_DETECTED\`

**NOTE** 
* You should expect the red light built into your Object sensor to go OFF when ground is detected

**Don't worry if your NeoPixel doesn't change!**
* **IT WILL PROBABLY STAY YELLOW...**  
* But you will learn why soon!
`}
<LegendMessageKeyboard>
<Markdown>
{`
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0)
RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)

$GROUND_DETECTED = 1

np = NeoPixel(pin16, 8)
    
$def read_ground_sensor():
$    return pin0.read_digital()

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()

$while(True):
$    ground_sensor_val = read_ground_sensor()

$    if ground_sensor_val == GROUND_DETECTED:
$        set_lighting(RGB_GREEN)
$    else:
$        set_lighting(RGB_YELLOW)
\`\`\`
`}
</Markdown>
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Move the Object Sensor Closer and Further from a Surface" />
{`
**Is your NeoPixel turning GREEN?**
* No... well why not?
`}
</Markdown>
          <StepComplete
            prompt="Are you ready to learn why it isn't changing?"
            xp={5}
            successMessage=
{`## Detecting the Ground! {centered}
### The crew will need to know when to prepare for landing!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting', 'read_ground_sensor']}
            reqCalls={['set_lighting', 'np.show', 'read_ground_sensor', 'pin0.read_digital']}
            reqArgs={['pin16', '8', 'RGB_YELLOW', 'RGB_GREEN']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'GROUND_DETECTED', 'ground_sensor_val']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}


class PullUpPin extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Pull Up! {lesson-title}
___
#### Why was the object sensor not providing the right value?
`}
<img src={PullUpImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
The object sensor has a very weak ==boolean== **HIGH** value.
* It is necessary to give the input a little boost.
* You can boost the level by setting \`pin0.set_pull(pin0.PULL_UP)\`
`}
<LegendMessageConcept>
<Markdown>
{`
What is a pull on an input pin?
* The pull determines the default value of a ==pin== when nothing is connected.
* Pull values are very weak can be overcome by applying an external HIGH or LOW voltage.

The pull of a ==pin== can be set to:
* PULL_UP = weak pull toward 3 Volts (HIGH Logic)
* PULL_DOWN = weak pull toward 0 Volts (LOW Logic)
* NO_PULL = the pin has no default and can drift between high and low

The pull of all micro:bit pins can be set except pins 5 and 11. 
* Those pins are always pulled HIGH and are used for the buttons on the micro:bit

Most ==pins== on the micro:bit default to \`NO_PULL\`.

The downside to using a pull is the external source has to be able to overcome the **strength** of the pull on that pin.
* Most devices that require a pull up can easily overcome that by dragging the voltage down to 0.

**NOTE** the buttons in the peripheral kit all have built in pull ups and do not require setting a value on the micro:bit.
* Other buttons or sensors may require some external (or internal) pull direction.

**Many peripherals in the real-world require you to use pulls on your input pins!**
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageKeyboard>
<Markdown>
{`
Switch pin0 to a \`PULL_UP\`!
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0)
RGB_RED = (20, 0, 0)
RGB_GREEN = (0, 20, 0)

GROUND_DETECTED = 1

np = NeoPixel(pin16, 8)

$pin0.set_pull(pin0.PULL_UP)
    
def read_ground_sensor():
    return pin0.read_digital()

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()

while(True):
    ground_sensor_val = read_ground_sensor()

    if ground_sensor_val == GROUND_DETECTED:
        set_lighting(RGB_GREEN)
    else:
        set_lighting(RGB_YELLOW)
\`\`\`
`}
</Markdown>
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Move the Object Sensor Closer and Further from a Surface" />
</Markdown>
          <StepComplete
            prompt="Is your crew display switching between green and yellow?"
            xp={5}
            successMessage=
{`## Pulling Up! {centered}
### Great work deciphering the PULL_UP!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting', 'read_ground_sensor']}
            reqCalls={['set_lighting', 'np.show', 'read_ground_sensor', 'pin0.read_digital', 'pin0.set_pull']}
            reqArgs={['pin16', '8', 'RGB_YELLOW', 'RGB_GREEN', 'pin0.PULL_UP']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'GROUND_DETECTED', 'ground_sensor_val']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class LanderStates extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Lander States {lesson-title}
___
#### Time to apply a finite-state machine to the lander!

The lander ==states== should mimic the phases of landing almost identically.
`}
<img src={LanderStateDiagramImg} alt="Lander State Diagram"     style={{
      width: "387", margin: 'auto', display:'block', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`
This state diagram is pretty simple...
*Why would I want to add states to my system? Wouldn't that just make the code more complex?*

Say as a remix idea you wanted to:
* Use a button to abort the landing and transition from the \`'prepare'\` state back to the \`'init'\` state?
* Add a fourth ==state== before the \`'init'\` state that required some crew action to ==transition==?

**In this project, you will utilize ==states== to avoid extra processor effort!**
* You will only check the sensors that are meaningful in your current ==state==.

For example: 
* You will only check the microswitch (touchdown sensor) when you are in the \`'prepare'\` state.
* The touchdown sensor would not have any meaning in the \`'init'\` state.
* If you had 100 touchdown sensors:
    * You wouldn't want to be checking those sensors constantly on your entire trip from Earth...
`}
<LegendMessageKeyboard>
{`
Using the ==state== diagram above you can set up states in your code.
1. Create a \`lander_state\` variable and initialize it to \`'init'\`.
2. Set the lighting for the \`'init'\` ==state== to \`RGB_YELLOW\`
3. Perform sensor checks for the appropriate sensors in your \`while\` ==loop== based on your current state.
4. Set the lighting for the \`'prepare'\` ==state== to \`RGB_RED\`
5. For now, just immediately transition from the \`'prepare'\` state to the \`'landed'\` state.
    * You will add a touchdown sensor later!
6. Set the lighting for the \`'landed'\` ==state== to \`RGB_GREEN\`
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0) # prepare state
RGB_RED = (20, 0, 0) # landing state
RGB_GREEN = (0, 20, 0) # done state

GROUND_DETECTED = 1

np = NeoPixel(pin16, 8)

pin0.set_pull(pin0.PULL_UP)
    
def read_ground_sensor():
    return pin0.read_digital()

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()  

$# setup the initial state of the lander 
$# states are 'init', 'prepare', 'landed'
$lander_state = 'init'
$set_lighting(RGB_YELLOW)

$sleep(1000) # give object sensor time to settle
        
while(True):
$    if lander_state == 'init':
$        ground_sensor_val = read_ground_sensor()

$        if ground_sensor_val == GROUND_DETECTED:
$            set_lighting(RGB_RED)
$            lander_state = 'prepare'
$    elif lander_state == 'prepare':
$        set_lighting(RGB_GREEN)
$        lander_state = 'landed'
$    elif lander_state == 'landed':
$        break
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
          <StepComplete
            prompt="Have you implemented the state diagram?"
            xp={5}
            successMessage=
{`## Your knowledge is Incredible! {centered}
### Time to add another sensor!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting', 'read_ground_sensor']}
            reqCalls={['set_lighting', 'np.show', 'read_ground_sensor', 'pin0.read_digital', 'pin0.set_pull', 'sleep']}
            reqArgs={['pin16', '8', 'RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'pin0.PULL_UP']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'GROUND_DETECTED', 'ground_sensor_val', 'lander_state']}
            reqStatements={['while', 'if', 'elif', 'break']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class TouchdownSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Sense Touchdown! {lesson-title}
___
### The Lander needs to know when it hits the surface!
`}
<img src={TouchdownImg} alt="Touchdown"     style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`
You can use the Microswitch as your touchdown sensor!
* The microswitch could be placed in hundreds of locations to detect impact with the surface
* In a safety critical application you might want multiple different microswitches!
`}
<LegendOctoHeader 
  pin={1}
  peripheral={12} // 12 = Microswitch
  markdown={`
  Connect the **Microswitch** to **position 1** on your ==octopus:bit==!
  `}
/>
{`
Now, read the microswitch with a \`read_touchdown_sensor\` function.
\`\`\`
def read_touchdown_sensor():
    return pin1.read_digital()
\`\`\`

Remember, the microswitch is ==boolean== 0 (LOW) when pressed. So...
\`\`\`
TOUCHDOWN_DETECTED = 0
\`\`\`

Last, fill out the \`'prepare'\` ==state==.
* Create a \`touchdown_sensor_val\` ==variable== which only gets used in the \`'prepare'\` ==state==.
* \`if\` \`TOUCHDOWN_DETECTED\` then change the display to green and switch to the \`landed\` ==state==.
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0) # init state
RGB_RED = (20, 0, 0) # prepare state
RGB_GREEN = (0, 20, 0) # landed state

GROUND_DETECTED = 1
$TOUCHDOWN_DETECTED = 0

np = NeoPixel(pin16, 8)

pin0.set_pull(pin0.PULL_UP)
    
def read_ground_sensor():
    return pin0.read_digital()

$def read_touchdown_sensor():
$    return pin1.read_digital()

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()  

# setup the initial state of the lander 
# states are 'init', 'prepare', 'landed'
lander_state = 'init'
set_lighting(RGB_YELLOW)

sleep(1000) # give object sensor time to settle
        
while(True):
    if lander_state == 'init':
        ground_sensor_val = read_ground_sensor()

        if ground_sensor_val == GROUND_DETECTED:
            set_lighting(RGB_RED)
            lander_state = 'prepare'
    elif lander_state == 'prepare':
$        touchdown_sensor_val = read_touchdown_sensor()

$        if touchdown_sensor_val == TOUCHDOWN_DETECTED:
$            set_lighting(RGB_GREEN)
$            lander_state = 'landed'
    elif lander_state == 'landed':
        break
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Transition between the three states" >
<Markdown>
{`
1. Hold the object sensor well above the surface to stay in the 'init' state.
2. Move the object sensor toward the surface until it senses the ground to enter the 'prepare' state.
3. Press the microswitch to the surface to sense touchdown and transition to the 'landed' state!!
`}
</Markdown>
</LegendMessageInteract>
</Markdown>
          <StepComplete
            prompt="Can you transition between all three states?"
            xp={5}
            successMessage=
{`## Transitioning Like a Pro! {centered}
### All the lander states are coming together!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting', 'read_ground_sensor', 'read_touchdown_sensor']}
            reqCalls={['set_lighting', 'np.show', 'read_ground_sensor', 'pin0.read_digital', 'pin0.set_pull', 'sleep', 'pin1.read_digital', 'read_touchdown_sensor']}
            reqArgs={['pin16', '8', 'RGB_YELLOW', 'RGB_GREEN', 'RGB_RED', 'pin0.PULL_UP']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'GROUND_DETECTED', 'TOUCHDOWN_DETECTED', 'touchdown_sensor_val', 'ground_sensor_val', 'lander_state']}
            reqStatements={['while', 'if', 'elif', 'break']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class LandingGear extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Set up the Landing Gear! {lesson-title}
___
### Connect the landing gear to your lander!
`}
<img src={LandingGearImg} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float:'right'
    }}
/>
{`
The positional (180) servo is the right tool for moving a landing gear.

You want the landing gear to:
* Move to a known position (either **extended** or **retracted**)
* Stay in position once it has moved

For a bit of fun, you could tape a picture of landing gear to the positional servo so that you can see them move!!
`}
<LegendOctoHeader 
  pin={2}
  peripheral={3} // 3 = 180 Servo
  markdown={`
  Connect the **180 Servo** to **position 2** on your ==octopus:bit==!

  **NOTE:** Your servo will have orange, red, and brown wires.  Make sure:
  * The **ORANGE** wire is connected to the **YELLOW** pin
  * The **RED** wire is connected to the **RED** pin
  * The **BROWN** wire is connected to the **BLACK** pin
  `}
/>
{`
The landing gear should start in the **retracted** position when the lander is the \`'init'\` ==state==.
* It should only be **extended** when you enter the \`'prepare'\` ==state==.
* This will keep the gear from dragging through the atmosphere on reentry!

Create two functions an \`extend_landing_gear\` function and a \`retract_landing_gear\` function to move the servo!

Don't forget to add \`pin2.set_analog_period(20)\` to setup the servo for 50 Hz!
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *
from neopixel import NeoPixel

RGB_YELLOW = (20, 20, 0) # prepare state
RGB_RED = (20, 0, 0) # landing state
RGB_GREEN = (0, 20, 0) # done state

GROUND_DETECTED = 1
TOUCHDOWN_DETECTED = 0

np = NeoPixel(pin16, 8)

pin0.set_pull(pin0.PULL_UP)

$pin2.set_analog_period(20)

$def extend_landing_gear():
$    pin2.write_analog(1023 * 2.0 / 20)
    
$def retract_landing_gear():
$    pin2.write_analog(1023 * 1.0 / 20)
    
def read_ground_sensor():
    return pin0.read_digital()

def read_touchdown_sensor():
    return pin1.read_digital()

def set_lighting(rgb_color):
    np[4] = rgb_color
    np.show()  

# setup the initial state of the lander 
# states are 'init', 'prepare', 'landed'
lander_state = 'init'
set_lighting(RGB_YELLOW)
$retract_landing_gear()

sleep(1000) # give object sensor time to settle
        
while(True):
    if lander_state == 'init':
        ground_sensor_val = read_ground_sensor()

        if ground_sensor_val == GROUND_DETECTED:
            set_lighting(RGB_RED)
$            extend_landing_gear()
            lander_state = 'prepare'
    elif lander_state == 'prepare':
        touchdown_sensor_val = read_touchdown_sensor()

        if touchdown_sensor_val == TOUCHDOWN_DETECTED:
            set_lighting(RGB_GREEN)
            lander_state = 'landed'
    elif lander_state == 'landed':
        break
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Transition between the three states" >
<Markdown>
{`
1. Hold the object sensor well above the surface to stay in the 'init' state.
2. Move the object sensor toward the surface until it senses the ground to enter the 'prepare' state.
3. Press the microswitch to the surface to sense touchdown and transition to the 'landed' state!!
`}
</Markdown>
</LegendMessageInteract>
</Markdown>
          <StepComplete
            prompt="Is the landing gear functional?"
            xp={5}
            successMessage=
{`## Great! {centered}
### It looks like you are ready for landing!
`}
            reqImports={['microbit', 'neopixel']}
            reqFuncdefs={['set_lighting', 'read_ground_sensor', 'read_touchdown_sensor', 'extend_landing_gear', 'retract_landing_gear']}
            reqCalls={['set_lighting', 'np.show', 'read_ground_sensor', 'pin0.read_digital', 'pin0.set_pull', 'sleep', 'pin1.read_digital', 'read_touchdown_sensor', 'extend_landing_gear', 'retract_landing_gear', 'pin2.write_analog', 'pin2.set_analog_period']}
            reqArgs={['pin16', '8', 'RGB_YELLOW', 'RGB_GREEN', 'RGB_RED', 'pin0.PULL_UP']}
            reqNames={['RGB_YELLOW', 'RGB_RED', 'RGB_GREEN', 'GROUND_DETECTED', 'TOUCHDOWN_DETECTED', 'touchdown_sensor_val', 'ground_sensor_val', 'lander_state']}
            reqStatements={['while', 'if', 'elif', 'break']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class PrepareLanderFinal extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Prepared for Landing! {lesson-title}
___
### You've completed project Prepare Lander!
You can sense nearby objects and determine the state of the landing craft. 

The lander is prepared for entry into the atmosphere! The crew is extremely pleased with the new lander's ground sensing system.

**Congratulations! You have finished preparations to send the crew to Mars!!**
`}
        <img src={FinalPrepareLanderImg} alt="" style={{margin: 'auto', width:'100%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="The mission is ready for lift-off! Thank you for your extraordinary efforts! Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectPrepareLander.stepId = 'ProjectPrepareLander'
LanderPhases.stepId = 'LanderPhases'
ObjectSensor.stepId = 'ObjectSensor'
AdjustDistance.stepId = 'AdjustDistance'
CrewDisplay.stepId = 'CrewDisplay'
DetectObjects.stepId = 'DetectObjects'
PullUpPin.stepId = 'PullUpPin'
LanderStates.stepId = 'LanderStates'
TouchdownSensor.stepId = 'TouchdownSensor'
LandingGear.stepId = 'LandingGear'
PrepareLanderFinal.stepId = 'PrepareLanderFinal'

export const prepareLander = [
  ProjectPrepareLander,
  LanderPhases,
  ObjectSensor,
  AdjustDistance,
  CrewDisplay,
  DetectObjects,
  PullUpPin,
  LanderStates,
  TouchdownSensor,
  LandingGear,
  PrepareLanderFinal,
]
