
import React, { Fragment }from 'react'
import PropTypes from 'prop-types'
import AppBar from 'material-ui/AppBar'
import Tabs from 'material-ui/Tabs'
import Tab from 'material-ui/Tabs/Tab'
import Typography from 'material-ui/Typography'
import { LinearProgress } from 'material-ui/Progress'

import { Database, data } from '../DataSources'
import OverviewTable from './OverviewTable'
import ProgressTable from './ProgressTable'


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

class MembersPanel extends React.PureComponent {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    disableUi: PropTypes.func.isRequired,
    uiDisabled: PropTypes.bool.isRequired,
    curriculum: PropTypes.string.isRequired,
  }

  state = {
    value: 0,
    groupMembers: [],
    groupId: '',
    loading: true,
  }

  componentWillMount = () => {
    if (this.state.groupId !== this.props.groupId) {
      this.setState({ groupId: this.props.groupId, groupMembers: [], loading: true })
      this.props.disableUi(true)
      Database.getGroupMembers(this.props.groupId).then((retData) => {
        this.setState({ groupMembers: retData.users, loading: false })
        this.props.disableUi(false)
      })
    }
  }

  onRemoveUser = (uid) => {
    this.props.disableUi(true)
    // TODO: could do some error checking in a .then or a .catch
    //       what if the database couldnt be reached?
    Database.removeGroupMember(uid, this.state.groupId).then(() => {
      this.props.disableUi(false)
    }).catch(() => {
      this.props.disableUi(false)
    })

    const arr = [...this.state.groupMembers]
    const index = arr.findIndex(member => member.uid === uid)
    if (index > -1) {
      arr.splice(index, 1)
      this.setState({ groupMembers: arr })
    }
  }

  onUserDoubleClicked = (uid) => {
    console.log(uid, ' double clicked')
  }

  onUserSelected = (uid) => {
    console.log(uid, ' selected')
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  }

  render() {
    const fullObj = Object.values(data.codespace).filter((module) => {
      return module.enum === this.props.curriculum
    })
    const projects = fullObj[0].projects

    return (
      <Fragment>
        <style jsx="true">
          {`
            .myTablesTab { display: flex; flex-direction: column; flex-grow: 1; min-height: 0; }
            .myTablesTab > div { display: flex; flex-direction: column; flex-grow: 1; min-height: 0; }
          `}
        </style>
        <AppBar position="static">
          <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
            <Tab label="Overview" />
            <Tab label="Progress" />
          </Tabs>
        </AppBar>
        {
          this.state.loading ?
            <div style={{ paddingLeft: '32px', paddingRight: '32px' }}>
              <Typography style={{ padding: '28px 0px', margin: '0', fontSize: 18 }} >
                {'Loading Student Data'}
              </Typography>
              <LinearProgress />
            </div> :
            <div style={{ height: 'calc(100% - 48px)', display: 'flex', flexDirection: 'column' }}>
              <TabPanel className={this.state.value === 0 ? 'myTablesTab' : ''} value={this.state.value} index={0}>
                <OverviewTable
                  rowData={this.state.groupMembers}
                  onDoubleClick={this.onUserDoubleClicked}
                  onSelected={this.onUserSelected}
                  onRemove={this.onRemoveUser}
                  uiDisabled={this.props.uiDisabled}
                  projects={projects}
                />
              </TabPanel>
              <TabPanel className={this.state.value === 1 ? 'myTablesTab' : ''} value={this.state.value} index={1}>
                <ProgressTable
                  projects={projects}
                  rowData={this.state.groupMembers}
                  onDoubleClick={this.onUserDoubleClicked}
                  onSelected={this.onUserSelected}
                />
              </TabPanel>
            </div>
        }
      </Fragment>
    )
  }
}

export default MembersPanel
