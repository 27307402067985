import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import Button from 'material-ui/Button'
import TextField from 'material-ui/TextField'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog'
import { googleApi } from './Gapi'


export default class SentryBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      showErrorDialog: false,
    }
    this.errorTextRef = null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      showErrorDialog: true,
    })
    Raven.captureException(error, { extra: errorInfo })
  }

  render() {
    if (this.state.error) {
      return (
        <Dialog
          open={this.state.showErrorDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Oops... that was unexpected!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We&#39;re sorry &#173; something&#39;s gone wrong but our illustrious team has been notified! Would you like to create
              a report to provide us with more details about the problem?
              <br />
              <TextField
                id="user-report"
                multiline
                rows={5}
                placeholder="Optional error description"
                fullWidth
                autoFocus
                disableUnderline
                inputRef={(el) => { this.errorTextRef = el }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                const errorId = Raven.lastEventId()
                const user = googleApi.getCurrentUser()
                const name = user ? user.getBasicProfile().getName() : 'Unknown'
                const email = user ? user.getBasicProfile().getEmail() : 'mark@firia.com'
                const formData = new FormData()
                formData.append('name', name)
                formData.append('email', email)
                formData.append('comments', this.errorTextRef.value)
                fetch(`https://sentry.io/api/embed/error-page/?eventId=${errorId}&dsn=https%3A%2F%2Febf6435497a34daf8720257d345fcbf5%40sentry.io%2F260953`, {
                  method: 'POST',
                  body: formData,
                }).then((response) => {
                  // console.log(response)
                  window.location.reload()
                })
              }}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    return this.props.children
  }
}
