import React from 'react'
import PropTypes from 'prop-types'
import Button from 'material-ui/Button'
import Typography from 'material-ui/Typography'
import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/Menu/MenuItem'
import { data } from '../DataSources'
import { moduleEnums } from '../../lessons/CurriculumModules'


class EditGroupForm extends React.PureComponent {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    groupModified: PropTypes.func.isRequired,
    groupDeleted: PropTypes.func.isRequired,
    showForm: PropTypes.bool.isRequired,
    curriculum: PropTypes.string.isRequired,
    joinCode: PropTypes.string.isRequired,
    joinActive: PropTypes.bool.isRequired,
    created: PropTypes.instanceOf(Date).isRequired,
    groupName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  state = {
    groupName: '',
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.groupId !== '' && newProps.groupId !== this.props.groupId) {
      this.setState({ groupName: newProps.groupName })
    }
  }

  render() {
    return (
      this.props.showForm ?
        <div style={{ padding: 20 }}>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="Classroom Name"
            type="text"
            value={this.state.groupName}
            onChange={(ev) => {
              this.setState({ groupName: ev.target.value })
            }}
            onFocus={() => {
              this.setState({ groupName: this.props.groupName })
            }}
            onBlur={(ev) => {
              if (ev.target.value === '') {
                this.setState({ groupName: this.props.groupName })
              } else if (this.props.groupName !== ev.target.value) {
                this.props.groupModified(this.props.groupId, 'groupName', ev.target.value)
              }
            }}
            fullWidth
            disabled={this.props.disabled}
          />
          <TextField
            id="join"
            select
            margin="normal"
            label="Allow Classroom Join"
            value={this.props.joinActive ? 1 : 0}
            onChange={(ev) => {
              this.props.groupModified(this.props.groupId, 'joinActive', ev.target.value === 1)
            }}
            fullWidth
            disabled={this.props.disabled}
          >
            <MenuItem key={1} value={1}>
              Yes
            </MenuItem>
            <MenuItem key={0} value={0}>
              No
            </MenuItem>
          </TextField>
          <Typography
            variant="body1"
            align="center"
            style={{ marginTop: 15, marginBottom: 0 }}
          >
            Join Code: {this.props.joinCode}
          </Typography>
          <div style={{ paddingLeft: 64, marginBottom: 10 }}>
            <Button
              color="primary"
              onClick={() => {
                  this.props.groupModified(this.props.groupId, 'joinCode', null)
              }}
              disabled={this.props.disabled}
            >
              Get New Join Code
            </Button>
          </div>
          <TextField
            id="curriculum"
            select
            margin="normal"
            label="Curriculum"
            disabled
            value={this.props.curriculum}
            fullWidth
          >
            {data.owner.availableCurricula.map((option) => {
              const currVal = Object.keys(moduleEnums).find(key => moduleEnums[key] === option)
              return (
                <MenuItem key={option} value={option}>
                  {data.codespace[currVal].name}
                </MenuItem>
              )
            })}
          </TextField>
          <div style={{ paddingLeft: 82, marginBottom: 10, marginTop: 5 }}>
            <Button
              color="primary"
              onClick={() => {
                this.props.groupDeleted(this.props.groupId)
              }}
              disabled={this.props.disabled}
            >
              Delete Class
            </Button>
          </div>
          <Typography
            variant="body2"
            align="center"
            style={{ marginTop: 15 }}
          >
            ID: { this.props.groupId }
          </Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginTop: 15 }}
          >
            Date Created: {this.props.created.toLocaleDateString('en-US')}
          </Typography>
        </div> :
        <Typography
          variant="body1"
          align="left"
          style={{ padding: 20 }}
        >
          Select a class from the table to edit the data for that class.
        </Typography>
    )
  }
}

export default EditGroupForm
