// DO NOT EDIT - this file is automatically generated by GenFirmwareLinks.js

import DAPLinkFirmware from './firmware/maintenance_ab9b0b3.hex'
import MicroPythonFirmware from './firmware/microbit-micropython_712309a.hex'

export const FirmwareFiles = [
    DAPLinkFirmware,
    MicroPythonFirmware
  ]

  const DAPLink = "ab9b0b3"
  const MicroPython = "712309a"
  export class FirmwareHashes {
    constructor() {
      this.DAPLink = DAPLink
      this.MicroPython = MicroPython
      this.DigetSize = 7
    }
  }