import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import DialogActions from 'material-ui/Dialog/DialogActions'
import DialogContent from 'material-ui/Dialog/DialogContent'
import DialogTitle from 'material-ui/Dialog/DialogTitle'
import Button from 'material-ui/Button'
import Typeography from 'material-ui/Typography'
import { CircularProgress } from 'material-ui/Progress'
import { withStyles } from 'material-ui/styles'
import DialogContentText from 'material-ui/Dialog/DialogContentText'

import FormControl from 'material-ui/Form/FormControl'
import FormHelperText from 'material-ui/Form/FormHelperText'
import Input from 'material-ui/Input'
import InputLabel from 'material-ui/Input/InputLabel'

import Table, {
  TableBody,
  TableHead,
  TableRow,
} from 'material-ui/Table'

import { Database, data } from '../DataSources'
import { CustomTableCell } from '../../ProductSelect'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  row: {
    height: 36,
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
  },
  rowHeader: {
    height: 24,
  },
  table: {
    marginBottom: '30px',
  },
})

const UserGroupsTable = (props) => {
  const rows = props.groupsList
  if (props.justJoined !== null) {
    rows.forEach((row, idx) => {
      if (row.enum === props.justJoined.curriculum) {
        rows[idx].groupName = props.justJoined.groupName
        rows[idx].status = 'Active'
      }
    })
  }
  return (
    <Table className={props.classes.table}>
      <TableHead>
        <TableRow className={props.classes.rowHeader} >
          <CustomTableCell>Curriculum</CustomTableCell>
          <CustomTableCell>Status</CustomTableCell>
          <CustomTableCell>My Classroom</CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(grp => (
          <TableRow className={props.classes.row} key={grp.enum} >
            <CustomTableCell>{grp.curriculum}</CustomTableCell>
            <CustomTableCell>{grp.status}</CustomTableCell>
            <CustomTableCell>{grp.groupName}</CustomTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const StyledGroupsTable = withStyles(styles)(UserGroupsTable)

class JoinGroupModal extends React.Component {
  static propTypes = {
    setVisibility: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    uiDisabled: PropTypes.bool.isRequired,
    disableUi: PropTypes.func.isRequired,
  }

  state = {
    joinCode: '',
    errorMsg: '',
    joinFinished: false,
    inError: false,
    completeMsg: '',
    justJoined: null,
  }

  handleClose = () => {
    this.props.setVisibility(false)
    this.setState({ joinFinished: false, errorMsg: '', completeMsg: '', justJoined: null })
  }

  handleJoin = () => {
    this.setState({ errorMsg: '' })
    if (this.state.joinCode === '') {
      this.setState({ inError: true, errorMsg: 'You must input a Join Code.' })
    } else {
      this.props.disableUi(true)
      Database.joinGroup(this.state.joinCode.trim()).then((resp) => {
        this.props.disableUi(false)
        if (resp.joinStatusCode === 1) {
          this.setState({
            joinFinished: true,
            inError: false,
            completeMsg: `Success! You joined - ${resp.groupName}`,
            justJoined: {
              groupName: resp.groupName,
              curriculum: resp.curriculum,
            },
          })
        } else if (resp.joinStatusCode === 3) {
          this.setState({ inError: true, errorMsg: 'Multiple classes found with the same Join Code.' })
        } else if (resp.joinStatusCode === 4) {
          this.setState({ inError: true, errorMsg: `The class - ${resp.groupName} is not allowing new members.` })
        } else if (resp.joinStatusCode === 5) {
          this.setState({ inError: true, errorMsg: `You are already a member of the class - ${resp.groupName}` })
        } else {
          this.setState({ inError: true, errorMsg: 'Could not find that classroom. Check Spelling?' })
        }
      }).catch((d) => {
        this.props.disableUi(false)
        this.setState({ inError: true, errorMsg: 'Unknown Error. Check your network connection?' })
      })
    }
  }

  updateField = (ev) => {
    this.setState({ joinCode: ev.target.value })
  }

  render() {
    const rows = []

    Object.keys(data.codespace).forEach((key) => {
      const module = data.codespace[key]
      if (module.enum.substring(0, 4) !== 'Free') {
        const newRow = {
          enum: module.enum,
          status: 'None',
          curriculum: module.name,
          groupName: '',
        }

        this.props.groupsJoinedData.forEach((grp) => {
          if (grp.curriculum === module.enum) {
            if (grp.userStatusCode === 1) {
              newRow.status = 'Active'
              newRow.groupName = grp.groupName
            } else if (grp.userStatusCode === 2) {
              newRow.status = 'Removed'
              newRow.groupName = grp.groupName
            }
          }
        })

        rows.push(newRow)
      }
    })

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        scroll="paper"
        maxWidth={false}
      >
        <DialogTitle>My Classes</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledGroupsTable groupsList={rows} justJoined={this.state.justJoined} />
          <DialogContentText style={{ marginBottom:  '30px' }}>
              Enter a Join Code below to join a class and share your progress with the classroom moderator.
          </DialogContentText>
          <FormControl error={this.state.inError} >
            <InputLabel>Enter Join Code Here</InputLabel>
            <Input
              margin="dense"
              disabled={this.props.uiDisabled}
              value={this.state.joinCode}
              onChange={this.updateField}
              fullWidth
              autoFocus
            />
            <FormHelperText>{this.state.errorMsg}</FormHelperText>
          </FormControl>
          <Typeography style={{ color: 'green', textAlign: 'center', marginTop: '10px' }}>
            { this.state.completeMsg }
          </Typeography>
        </DialogContent>
        <DialogActions>
          {
            this.state.joinFinished ?
              <Fragment /> :
              <Button style={{ marginRight: '20px' }} disabled={this.props.uiDisabled} onClick={this.handleJoin} variant="raised">
                {
                  this.props.uiDisabled ?
                    <CircularProgress
                      style={{
                        margin: 'auto',
                        width: 20,
                        height: 20,
                        color: 'green',
                      }}
                    /> :
                    'Join'
                }
              </Button>
          }
          <Button disabled={this.props.uiDisabled} onClick={this.handleClose} variant="raised">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default JoinGroupModal
