import React, { Component } from 'react'
import PropTypes from 'prop-types'


class VariablesPane extends Component {
  static propTypes = {
    localVariables: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      val: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
    globalVariables: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      val: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })).isRequired,
  }

  listVariables = (variables) => {
    let i = 0

    if (variables.length === 0) {
      variables.push({
        name: 'none',
        val: '',
        type: '',
      })
    } else {
      variables.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }

    return (
      <div
        style={{
          flex: '1 1 auto',
          overflowY: 'auto',
        }}
      >
        {
          variables.map((item) => {
            if (item.val === '(value undefined)') return null
            i += 1
            return (
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  margin: '0 0.5em 0 0.5em',
                  backgroundColor: i % 2 ? '#eee' : 'inherit',
                }}
                key={item.name}
              >
                <div style={{ flex: 1, overflowX: 'hidden', padding: '0.25em 0 0.25em 1em' }} title={item.name}>
                  {item.name}
                </div>
                <div
                  style={{
flex: 1, overflowX: 'hidden', padding: '0.25em 0 0.25em 1em', borderLeft: '1px solid #d9d9d9', borderRight: '1px solid #d9d9d9',
}}
                  title={item.val}
                >
                  {item.val}
                </div>
                <div style={{ flex: 1, overflowX: 'hidden', padding: '0.25em 0 0.25em 1em' }} title={item.type}>
                  {item.type}
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          display: 'flex',
          backgroundColor: 'white',
          fontFamily: 'arial, sans-serif',
          fontSize: '0.9em',
          margin: '10px',
          marginLeft: '5px',
          border: '1px solid #d9d9d9',
          overflowY: 'auto',
        }}
      >
        <div style={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <div
            style={{
              fontFamily: 'arial, sans-serif',
              margin: '0.5em',
              borderBottom: '1px solid #d9d9d9',
              paddingBottom: '5px',
              marginTop: '1em',
              marginBottom: '0',
            }}
          >
            LOCAL VARIABLES
          </div>
          {this.listVariables(this.props.localVariables)}
        </div>

        <div style={{
          flex: '1 0 auto',
          flexDirection: 'column',
          display: 'flex',
        }}
        >
          <div
            style={{
              fontFamily: 'arial, sans-serif',
              margin: '0.5em',
              flex: '0 0 auto',
              borderBottom: '1px solid #d9d9d9',
              paddingBottom: '5px',
              marginTop: '1em',
              marginBottom: '0',
            }}
          >
            GLOBAL VARIABLES
          </div>
          {this.listVariables(this.props.globalVariables)}
        </div>
      </div>
    )
  }
}


export default VariablesPane
