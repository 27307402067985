/* global gapi, google*/

// Client ID and API key from the Developer Console
export const CLIENT_ID = '225751792763-t155mn9m0gmpbkv00jp30731mglgcj6g.apps.googleusercontent.com'
export const API_KEY = 'AIzaSyB4laxciKYAm7yeUFsIztKXX9QIkgaAS30'

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const CBL_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive.file'
const GOOGLE_DRIVE_FULL_SCOPE = 'https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive'
const SIGNIN_SCOPES = `profile email openid ${CBL_DRIVE_SCOPE}`
export const CLASSROOM_STUDENT_SCOPES = 'https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly'
export const CLASSROOM_SCOPES = 'https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.rosters.readonly'

const DISCOVERY_DOCS = ["https://classroom.googleapis.com/$discovery/rest?version=v1"];

const boundary = '9c0d4c3b-fe16-4f81-9a3a-65d898cc0be7'  // Could be anything unique so I'm using a UUID4
const delimiter = "\r\n--" + boundary + "\r\n"
const close_delim = "\r\n--" + boundary + "--"

const DEFAULT_MIME_TYPE = "text/plain"

export const REFRESH_WATERMARK = 960 // 15 minutes: https://developers.google.com/api-client-library/javascript/help/faq


class GoogleApi {
  constructor() {
    this.gapiAuthIsSignedIn = false
    this.gapiLoadError = null

    this.authCallbacks = new Set()
    this.gapiLoadedPromise = new Promise((resolve, reject) => {
      this._gapiLoadedResolve = resolve
      this._gapiLoadedReject = reject
    })
    this.authLoadedPromise = new Promise((resolve, reject) => {
      this._authLoadedResolve = resolve
      this._authLoadedReject = reject
    })

    this._auth2Loaded = false
    this.gapiLoaded = false

    // Check connectivity to google APIs URL.
    fetch('https://www.googleapis.com/auth/drive', {mode: 'no-cors'})
    .catch((err) => {
      this._gapiLoadedReject('googleapis.com blocked')
    })

    // Check ssl.gstatic.com, used by GAPI sign-in iFrame.
    fetch('https://ssl.gstatic.com/generate_204', {mode: 'no-cors'})
    .catch((err) => {
      this._gapiLoadedReject('ssl.gstatic.com blocked')
    })

    if (!('gapi' in global)) {
      const meta = document.createElement('meta')
      meta.name = 'google-signin-client_id'
      meta.content = CLIENT_ID
      document.head.appendChild(meta)

      const script = document.createElement("script")
      script.async = false  // maintain order of javascript library loading
      script.src = "https://apis.google.com/js/client.js"
      script.onload = this.onGapiLoad
      // script.crossOrigin = ""
      script.onerror = (ev) => {
        console.error("Error loading apis.google.com: ", ev)
        this._gapiLoadedReject("Error loading apis.google.com")
      }

      document.body.appendChild(script)
    }
  }

  updateSigninState = (isSignedIn) => {
    this.gapiAuthIsSignedIn = isSignedIn
    this.authCallbacks.forEach((callback) => {
      callback(isSignedIn)
    })
  }

  onGapiLoad = () => {
    gapi.load('client:auth2:picker:signin2', {
      callback: () => {
        gapi.client.init({
          clientId: CLIENT_ID,
          apiKey: API_KEY,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SIGNIN_SCOPES,
        }).then(() => {
          // console.log('GAPI Loaded')
          return gapi.auth2.init({
            clientId: CLIENT_ID,
            scope: SIGNIN_SCOPES,
          }).then()  // gets a Promise from the GoogleAuth object
        }).then(() => {
            // console.log('GAPI:AUTH2 Loaded')
            this._auth2Loaded = true

            // Listen for sign-in state changes.
            gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninState)

            // Handle the initial sign-in state.
            this.updateSigninState(gapi.auth2.getAuthInstance().isSignedIn.get())
            this._gapiLoadedResolve(true)
            this._authLoadedResolve(true)
        }).then((response) => {
          // console.log(response.result)
        }).catch((err) => {
          // console.log('Gapi Error:', err)
          this._gapiLoadedReject(err)
          this._authLoadedReject(err)
          this.gapiLoadError = err
          this._auth2Loaded = false
        })},

      // (DBE) Never seeing these fire.
      onerror: function () {
        // Handle loading error.
        alert('gapi.client failed to load!')
      },
      timeout: 10000, // ms
      ontimeout: function () {
        // Handle timeout.
        alert('gapi.client could not load in a timely manner!')
      }  
    })
  }

  signIn = () => {
    const options = new gapi.auth2.SigninOptionsBuilder();
    options.setPrompt('select_account');
    gapi.auth2.getAuthInstance().signIn(options)
  }

  signOut = () => {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            auth2.disconnect()
        })
  }

  getCurrentUser = () => {
    if (this.gapiAuthIsSignedIn) {
      return gapi.auth2.getAuthInstance().currentUser.get()
    }
  }

  getIdToken = () => (
    gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token
  )

  notifyOnAuthChange = (callback) => {
    this.authCallbacks.add(callback)
  }

  unregisterNotifyOnAuthChange = (callback) => {
    this.authCallbacks.delete(callback)
  }

  _driveSaveCommon = (path, method, metadata, dataContentType, data) => {
    return new Promise((resolve, reject) => {
      var multipartRequestBody =
        delimiter +
        'Content-Type: application/json; charset=UTF-8\r\n\r\n' +
        JSON.stringify(metadata)

      if (data) {
        multipartRequestBody +=
          delimiter +
          'Content-Type: ' + dataContentType + '\r\n\r\n' +
          data
      }

      multipartRequestBody += close_delim

      var request = gapi.client.request({
        'path': path,
        'method': method,
        'params': {
          'uploadType': 'multipart'
        },
        'headers': {
          'Content-Type': 'multipart/related; boundary="' + boundary + '"'
        },
        'body': multipartRequestBody
      })

      request.then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  driveSaveAs = (fileName, data, contentType = DEFAULT_MIME_TYPE) => {
    return this._driveSaveCommon(
      '/upload/drive/v3/files',
      'POST',
      {
        'name': fileName,
        'mimeType': contentType
      },
      contentType,
      data
    )
  }

  driveSave = (fileId, data, contentType = DEFAULT_MIME_TYPE) => {
    return this._driveSaveCommon(
      '/upload/drive/v3/files/' + fileId,
      'PATCH',
      {
        'mimeType': contentType
      },
      contentType,
      data
    )
  }

  driveRename = (fileId, fileName) => {
    return this._driveSaveCommon(
      // '/drive/v3/files/' + fileId,
      '/upload/drive/v3/files/' + fileId,   // DBE - as of 7/2022 seems we need /upload/ in path here.
      'PATCH',
      {
        'name': fileName
      }
    )
  }

  driveLoad = (fileId) => {
    return new Promise((resolve, reject) => {
      gapi.client.request({
        path: `/drive/v3/files/${fileId}?alt=media`,
        method: 'GET',
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  driveGetMetadata = (fileId, fields) => {
    return new Promise((resolve, reject) => {
      gapi.client.request({
        path: `/drive/v3/files/${fileId}?fields=${fields}`,
        method: 'GET',
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  driveMove = (fileId, newFolderId) => {
    return new Promise((resolve, reject) => {
      gapi.client.request({
        'path': '/drive/v3/files/' + fileId + '?fields=parents',
        'method': 'GET'
      }).then((response) => {
        // console.log(response)
        gapi.client.request({
          'path': '/drive/v3/files/' + fileId + '?addParents=' + newFolderId + '&removeParents=' + encodeURIComponent(response.result.parents),
          'method': 'PATCH'
        }).then((response) => {
          // console.log(response)
          resolve(response)
        }, (error) => {
          reject(error)
        })
      }, (error) => {
        reject(error)
      })
    })
  }

  driveCopy = (fileId, name, folderId = null) => {
    return new Promise((resolve, reject) => {
      const body = {
        name,
      }

      if (folderId) {
        body.parents = [folderId]
      }

      gapi.client.request({
        path: `/drive/v3/files/${fileId}/copy`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((response) => {
        // console.log(response)
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCBLFilePicker = (pickerCallback) => {
    return new google.picker.PickerBuilder()
    // .addViewGroup(
    //   new google.picker.ViewGroup(google.picker.ViewId.FOLDERS)
    //     .addView(new google.picker.DocsView(google.picker.ViewId.DOCS)
    //       .setMode(google.picker.DocsViewMode.LIST)
    //       .setMimeTypes(DEFAULT_MIME_TYPE))
    //     .addView(google.picker.ViewId.FOLDERS)
    // )
      .addView(new google.picker.DocsView(google.picker.ViewId.FOLDERS)
        .setIncludeFolders(true)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setMimeTypes(DEFAULT_MIME_TYPE)
        .setOwnedByMe(true))
      .addView(new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setMode(google.picker.DocsViewMode.LIST)
        .setMimeTypes(DEFAULT_MIME_TYPE))
      .addView(google.picker.ViewId.RECENTLY_PICKED)
      .addView(new google.picker.DocsUploadView()
        .setIncludeFolders(true))
      .setOAuthToken(this.getCurrentUser().getAuthResponse().access_token)
      .setDeveloperKey(API_KEY)
      .setCallback(pickerCallback)
      .build()
  }

  getFolderPicker = (pickerCallback) => {
    return new google.picker.PickerBuilder()
      .addView(new google.picker.DocsView(google.picker.ViewId.FOLDERS)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setSelectFolderEnabled(true)
        .setOwnedByMe(true))
      .setOAuthToken(this.getCurrentUser().getAuthResponse().access_token)
      // .setDeveloperKey(API_KEY)
      .setCallback(pickerCallback)
      .setTitle('Select a folder')
      .build()
  }

  renderSigninButton = (id) => {
    gapi.signin2.render(id, {
      'scope': SIGNIN_SCOPES,
      'width': 240,
      'height': 50,
      'longtitle': true,
      'theme': 'dark',
      client_id: CLIENT_ID,
      response_type: 'code token id_token',
      // redirect_uri: 'http://localhost:3000/login-callback',
      // 'onsuccess': (result) => {
      //   console.log(result)
      // },
      // 'onfailure': (err) => {
      //   console.log(err)
      // }
    })
    // console.log('gapi.signin2.render called')
  }

  getGrantedScopes = () => {
    if (!this.gapiAuthIsSignedIn) {
      return ''
    }
    return this.getCurrentUser().getGrantedScopes()
  }

  hasDriveScope = () => {
    return this.getGrantedScopes().indexOf(CBL_DRIVE_SCOPE) >= 0
  }

  hasDriveFullScope = () => {
    for (const scope of GOOGLE_DRIVE_FULL_SCOPE.split(' ')) {
      if (this.getGrantedScopes().indexOf(scope) < 0) {
        return false
      }
    }

    return true
  }

  requestDriveScope = (successCallback) => {
    const user = this.getCurrentUser()
    if (user) {
      return user.grant(new gapi.auth2.SigninOptionsBuilder({'scope': CBL_DRIVE_SCOPE})).then((response) => {
          successCallback(response)
        },
        () => {
        })
    }
    return gapi.auth2.getAuthInstance().signIn()
  }

  requestDriveFullScope = (successCallback) => {
    const user = this.getCurrentUser()
    if (user) {
      return user.grant(new gapi.auth2.SigninOptionsBuilder({'scope': GOOGLE_DRIVE_FULL_SCOPE})).then((response) => {
          successCallback(response)
        },
        () => {
        })
    }
    return gapi.auth2.getAuthInstance().signIn()
  }

  authorize = () => {
    gapi.auth2.authorize({
      client_id: CLIENT_ID,
      scope: SIGNIN_SCOPES,
      response_type: 'code',
      prompt: 'none',
    }, (response) => {
      console.log(response)
    }) // There is no "error" callback.
    console.log('gapi.auth2.authorize called')
  }

  renderClassroomShareBtn = (container) => {
    gapi.sharetoclassroom.render(container, {
      url: null, //'https://www.github.com/',
      title: 'Test Titke',
      body: 'Test body',
    })
  }

  getCourses = () => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.list().then((reponse) => {
        resolve(reponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  getMyTeachingCourses = () => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.list({teacherId: 'me'}).then((reponse) => {
        resolve(reponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCourseWork = (courseId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.courseWork.list({courseId}).then((response) => {
        response.courseId = courseId
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCourseWorkSubmissions = (courseId, courseWorkId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.courseWork.studentSubmissions.list({courseId, courseWorkId}).then((response) => {
        const editedResponse = response
        editedResponse.courseId = courseId
        editedResponse.courseWorkId = courseWorkId
        resolve(editedResponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCourseTeachers = (courseId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.teachers.list({courseId}).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  addCourseWorkSubmissionAttachment = (courseId, courseWorkId, submissionId, attachemnts) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.courseWork.studentSubmissions.modifyAttachments({
        courseId,
        courseWorkId,
        id: submissionId,
        addAttachments: attachemnts,
      }).then((reponse) => {
        resolve(reponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  addCourseWorkSubmissionTurnIn = (courseId, courseWorkId, submissionId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.courseWork.studentSubmissions.turnIn({
        courseId,
        courseWorkId,
        id: submissionId,
      }).then((reponse) => {
        resolve(reponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  createCourseWork = (courseId, courseWork) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.courseWork.create({
        courseId,
        ...courseWork,
      }).then((reponse) => {
        resolve(reponse)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCourseStudent = (courseId, userId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.students.get({courseId, userId}).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCourseAllStudents = (courseId) => {
    return new Promise((resolve, reject) => {
      gapi.client.classroom.courses.students.list({ courseId }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }
}

export const googleApi = new GoogleApi()
export default googleApi
