// Project: RadioMessenger

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  //LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  //LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import RadioMsg from './assets/RadioMsg.jpg'
import IoTWorld from './assets/IoTworld.jpg'
import MicrobitBLE from './assets/MicrobitBLE.jpg'
import Quiz from '../Quiz'
import OnAir from './assets/OnAir.jpg'
import FilmCount from './assets/FilmCount.svg'
import MicrobitBatt from './assets/microbit-batt.gif'

export const RadioMessengerImg = RadioMsg

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Radio Messenger {lesson-title}
___
`}
<img src={RadioMsg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### This project introduces the micro:bit's *Radio*
*Wireless connected devices - the Internet of Things!* {centered}

Take a close look at your micro:bit. Can you locate the **antenna**?
> It's a *trace* on the *printed circuit board* (PCB) that's exactly the right length to tune-in ==2.4GHz== radio waves!
`}
<img src={MicrobitBLE} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'left', marginLeft: 10, marginRight:30
    }}
/>
<div style={{clear:'right'}} />

{`
**Lots of devices are connected with wireless radios:**
* Mobile phones and computers
* Wireless headphones and speakers
* Remote lighting for houses
* Fitness trackers
* Remote locks for cars
`}
<div style={{clear:'left'}} />
{`
#### Project Goals:
* **Transmit** over the air:
  * Control a remote counter *up/down* when buttons A or B are pressed.
* **Receive** radio messages:
  * Display received strings on the LED matrix.
`}
</Markdown>
        <StepComplete
          prompt="Ready to get wireless? Press Next..."
          lessons={this.props.lessons}
          btnNext={true}
        />

      </div>
    )
  }
}

class Radio1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Count-Up {lesson-title}
___
#### First step to *radio-land* is to make some test messages.
**Counting** 1-2-3 when you press the *buttons* will make a nice message to transmit over the air.
* Radio communication happens at the **speed of light**!
* You need a counter that will send *instantly* to experience the *speed of radio*.
`}
<LegendMessageKeyboard>
{`Create a new file and name it **RadioCounter**
* Write a program that counts up when button B is pressed.
* For now, just **display** the count.
\`\`\`
from microbit import *

count = 0

while True:
    if button_b.was_pressed():
        count = count + 1
        display.scroll(str(count))
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`The code above should be familiar to you... Do you understand every part of this program?
`}
</LegendMessageRun>
<StepComplete
  prompt="Can you count-up by pressing button-B?"
  xp={25}
  successMessage=
{`## I knew I could COUNT on you! {centered}
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str', 'button_b.was_pressed']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Radio2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## A Quicker Counter! {lesson-title}
___
#### Okay, that was sorta unsatisfying... The counter is too SLOW!
Every time you press a button you have to **wait** for the display to finish scrolling before pressing again.
* Scrolling is nice, but **waiting** just won't do!

Take a look at the details of \`display.scroll()\` in the ==Display== documentation.

\`\`\`
# Scroll text across the display
display.scroll(string, delay=150, wait=True, loop=False, monospace=False)
\`\`\`

Notice how all the parameters after \`string\` have default values assigned? Those are *"optional parameters"*,
that you can **override** with your own.
> **In this case you want to set \`wait=False\` and \`loop=True\`.**
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

count = 0

while True:
    if button_b.was_pressed():
        count = count + 1
$$$
$        display.scroll(str(count), wait=False, loop=True)
$
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
`}
</LegendMessageRun>
<StepComplete
  prompt="Counting a bit faster now?"
  xp={25}
  successMessage=
{`## That's some nimble clicking! {centered}
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str', 'button_b.was_pressed']}
  reqArgs={['wait=False', 'loop=True']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Radio3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Count-Down {lesson-title}
___
`}
<img src={FilmCount} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', float:'right', marginLeft: 10, marginRight:10
    }}
/>
{`
#### You have super-speedy *up-counting*. But you need a way to count back *down*!
Naturally it's **button A** to the rescue :-)
> Detect those \`button_a\` presses and subtract from the counter.
`}
<div style={{clear:'right'}} />
<LegendMessageKeyboard>
{`You can probably do this without peeking. **Give it a try on your own!**
\`\`\` collapsed (click if you need help)
from microbit import *

count = 0

while True:
    # Check buttons
    if button_b.was_pressed():
        count = count + 1
        display.scroll(str(count), wait=False, loop=True)
$$$
    elif button_a.was_pressed():
        count = count - 1
        display.scroll(str(count), wait=False, loop=True)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What does the \`elif\` statement mean in the code above?
`}
  </Markdown>
  )}
  id={"elif"}
  xp={5}
  answerRight={"Short for \"else if\", it means check condition only if the previous statements are false."}
  answerWrong={["It is the Elvish form of \"if\"", "EL is for Extra Long \"if\" statement, which allows more characters in the line."]}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What if you wanted **Button A** to **ZERO** the counter?
`}
  </Markdown>
  )}
  id={"zero the counter"}
  xp={5}
  answerRight={"When button_a.was_pressed(), set count = 0 instead of subtracting 1 from it."}
  answerWrong={["When button_a.was_pressed(), call display.clear()."]}
/>
<StepComplete
  prompt="Double the buttons, double the fun?"
  xp={25}
  successMessage=
{`## Dueling buttons - fantastic! {centered}
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str', 'button_a.was_pressed', 'button_b.was_pressed']}
  reqArgs={['wait=False', 'loop=True']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Radio4 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const errorRegex = /Attempted to use variable/
    const dialogContent = (
      <Markdown>
{`#### ...just kidding - you can fix this bug!

Inside your new function, Python sees the variable \`count\` as a **local** variable.
* **Local** variables only exist inside a function.
* You need to tell Python to use the **global** \`count\` variable instead!

Next step will show you how to do that...
`}
      </Markdown>
    )
    this.props.interceptErrorCb([errorRegex, dialogContent, "NOT THAT BUTTON!!!"])
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Move button code to a function {lesson-title}
___
#### Just one more thing to do before we fire up the **radio**!
* Right now your whole program is about buttons and counting.
* You need to organize the code so it's easier to add the **radio** part.

Your **final goal** is to have a main loop that looks like this:
\`\`\`
while True:
    # Check buttons
    #   (send count over radio if a button was pressed)

    # Check radio
    #   (receive another micro:bit's count over the air and display it)
\`\`\`
#### Working towards the above, define a *function* to contain your *button checking* code.
Define a function: \`def check_buttons():\`
* Move the code from your \`while\` loop to this new function!
* This function will just **check the buttons and return the count string**.
  * *Remove the \`display.scroll()\` parts!*
  * The function should \`return\` a string instead of displaying it.
  * If no button was pressed, just return an *empty string* \`return ""\`

Now simplify the \`while\` loop:
1. Check the *buttons* by calling your new function.
2. Display the *count* with \`display.scroll()\` if a button was pressed.
`}
<LegendMessageKeyboard>
{`Add a new function, \`def check_buttons():\`.

Inside your \`while\` loop, **call** the \`check_buttons()\` function.
* Notice how the loop is now ready to add the radio check code?
\`\`\`
from microbit import *

count = 0

def check_buttons():
    if button_b.was_pressed():
        count = count + 1
        return str(count)
    elif button_a.was_pressed():
        count = count - 1
        return str(count)
    else:
        return ""

while True:
    # Check buttons
    msg = check_buttons()
    if msg:
        display.scroll(msg, wait=False, loop=True)

    # Check radio
    #   TODO...
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Give it a try - **BUT** things may not go exactly as planned!
`}
</LegendMessageRun>
<LegendMessageWarning title="Caution - Bugs Ahead!">
{`When you assign to a variable inside a function, Python by default treats that as a **local** variable. Even if it has the same name as a **global** variable
you've already defined (like \`count\` in this case) the *local* variable just makes the *global* version invisible to code inside the function.
* So in this case, you were trying to modify the *local* \`count\` before it had been assigned an initial value.
* But you didn't want a **local** variable in the first place!
* How can you tell Python to use the **global** instead?

See ==locals and globals== for details...
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`Just add the \`global count\` declaration on a line by itself inside your function.
\`\`\`
from microbit import *

count = 0
$$$

def check_buttons():
$    global count
$$$
    if button_b.was_pressed():
        count = count + 1
        return str(count)
    elif button_a.was_pressed():
        count = count - 1
        return str(count)
    else:
        return ""

while True:
    # Check buttons
    msg = check_buttons()
    if msg:
        display.scroll(msg, wait=False, loop=True)

    # Check radio
    #   TODO...
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Should be smooth sailing now!
`}
</LegendMessageRun>
<StepComplete
  prompt="Got a crisp clean loop, with a groovy global counter?"
  xp={25}
  successMessage=
{`## Perhaps you ARE ready to face Vader alone... {centered}
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str', 'button_a.was_pressed', 'button_b.was_pressed', 'check_buttons']}
  reqArgs={['wait=False', 'loop=True']}
  reqFuncdefs={['check_buttons']}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Radio5 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## On the AIR {lesson-title}
___
`}
<img src={OnAir} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginLeft: 10, marginRight:10
    }}
/>
{`
#### Finally it's time to send and receive the count **wirelessly**!
> You will need **2 micro:bits** to test this code.

Adding radio functions to your program is actually very simple, thanks to the micro:bit ==radio== module.
`}
<div style={{clear:'right'}} />
<LegendMessageConcept title={'Concept: radio Module'}>
{`
\`\`\`
import radio
\`\`\`
This module provides functions like \`radio.send()\` and \`radio.receive()\` that let you send and receive message strings.
There's also a \`radio.config()\` function to configure the channel and other optional settings. See the ==radio== help for details.
* Valid channel numbers are 0-83.
* micro:bits have to be set to the *same channel* to hear each other!
* But, if there are other people using micro:bit radios nearby, you may want to set each pair of micro:bits to a *unique* channel
  so that radio tests don't interfere with one another.
`}
</LegendMessageConcept>
{`Right now your program *displays* the count *when a button is pressed*. **That needs to change!**
* Do **not** \`display.scroll()\` the count!
* Instead, \`radio.send()\` the count when a button is pressed.

That takes care of **sending** the message. **But you also want to *receive!***
* Add a \`radio.receive()\` check, the same way you \`check_buttons()\` in the \`while\` loop.
* If a message is received, then \`display.scroll(message)\` on the LED matrix!
`}
<LegendMessageKeyboard>
{`Add \`import radio\` to the top of your code.
* Enable the radio hardware by calling \`radio.on()\`.
* Optionally, use \`radio.config(channel=N)\` to change the channel. (choose your own number for 'N', from 0-83)

Change the code in your \`while\` loop:
* Call \`radio.send(msg)\` when a button is pressed, and
* Call \`display.scroll(msg)\` when a radio message is received.
\`\`\`
from microbit import *
$import radio
$
$radio.on()
$radio.config(channel=16)  # Channel can be 0-83

count = 0

def check_buttons():
    global count
    if button_b.was_pressed():
        count = count + 1
        return str(count)
    elif button_a.was_pressed():
        count = count - 1
        return str(count)
    else:
        return ""
$$$
$while True:
$    # Check buttons
$    msg = check_buttons()
$    if msg:
$        radio.send(msg)
$
$    # Check radio
$    msg = radio.receive()
$    if msg:
$        display.scroll(msg, wait=False, loop=True)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
<Markdown>
<img src={MicrobitBatt} alt=""
    style={{
      width: "20%", margin: 'auto', display:'block', marginLeft: 10, marginRight: 10, float: 'right'
    }}
/>
{`Load this code onto **two micro:bits**, and take turns sending and receiving the count!
* ***NOTE:*** **Program them one at a time.** *CodeSpace* does not support *more than one USB connection* simultaneously.
* If you have *more* micro:bits, set them up so you can watch the count as one broadcasts to all the others over the air!
  * Remember that they have to be on the same channel to communicate with each other.
`}
</Markdown>
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What does it mean to say: \`if msg:\` when \`msg\` is a ==string==, rather than a ==comparison== or ==boolean== type?
`}
  </Markdown>
  )}
  id={"String comparison versus implicit boolean"}
  xp={5}
  answerRight={"It means \"if the string is not empty\"."}
  answerWrong={["It means \"if the string exists\".", "It means \"if the string is empty\"."]}
/>
<StepComplete
  prompt="Are you sending and receiving wirelessly?"
  xp={25}
  successMessage=
{`## Amazing Action at a Distance! {centered}
#### The world's radio pioneers would be so proud of you!
* You stand on the shoulders of giants: Maxwell, Hertz, Marconi,...
> And now YOU have a radio transmitter and receiver that you can control with **code**.

I just know you're going to do **great** things with this!
`}
reqImports={['microbit', 'radio']}
reqCalls={
  ['display.scroll', 'str', 'button_a.was_pressed', 'button_b.was_pressed', 'check_buttons',
   'radio.send', 'radio.receive']
}
reqArgs={['wait=False', 'loop=True']}
reqFuncdefs={['check_buttons']}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Over and Out! {lesson-title}
___
`}
        <img src={IoTWorld} alt=""
          style={{
            width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10
          }}
        />
{`
### Now you can wirelessly control and monitor *anything!*

Sending and receiving basic messages wirelessly is pretty easy!
> Guess what? ALL computer networks are based on message "packets" like the ones you have coded in this project.

There is a lot more about wireless networks to explore, like:
* Sharing a channel with multiple users: Can you add an "address" for each device?
* Having a *private* conversation: How can a message be kept secret?
* Sending a message to a far-away device: Can you make each device "relay" messages along?
`}

<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Modify your **Wireless Counter** program:
  * Make it *reset* the count to **zero** if both buttons are pressed at the same time.
  * Rather than sending a **count**, send a **text message** based on the *count* value.
    * *(try using a **list** of strings for this!)*
* Add **channel select** when your program starts:
  * Use *buttons A/B* to scroll up/down through channels 0-83.
  * Press *both* buttons to *lock-in* a channel and continue your program!
* Make one of the previous projects **wireless**:
  * Remotely control music on another micro:bit.
  * Remote game controller.
  * Remote Night-Light or Spirit-Level sensor.
`}
</LegendMessageTry>

        </Markdown>
        <StepComplete
          prompt="Well done! Move ahead to more coding fun..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}


Prelude.stepId = 'Prelude'
Radio1.stepId = 'Radio1'
Radio2.stepId = 'Radio2'
Radio3.stepId = 'Radio3'
Radio4.stepId = 'Radio4'
Radio5.stepId = 'Radio5'
Finale.stepId = 'Finale'

export const radioMessenger = [
  Prelude,
  Radio1,
  Radio2,
  Radio3,
  Radio4,
  Radio5,
  Finale,
]
