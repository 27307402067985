// React wrapper for ACE Editor
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ace from 'brace'

class ReactAce extends Component {
  static propTypes = {
    editorId: PropTypes.string,
    onChange: PropTypes.func,
    onDeadKeyPress: PropTypes.func,
    onChangeBreakpoint: PropTypes.func,
    setReadOnly: PropTypes.bool,
    setValue: PropTypes.string,
    theme: PropTypes.string,
    mode: PropTypes.string,
    newLineMode: PropTypes.string,
    style: PropTypes.shape({
      height: PropTypes.string,
      width: PropTypes.string,
    }),
    fontSize: PropTypes.number,
    onChangeSelection: PropTypes.func,
    onCopy: PropTypes.func,
    enableBreakpoints: PropTypes.bool,
  }

  static defaultProps = {
    editorId: 'ace-editor',
    onChange: () => {},
    onChangeSelection: () => {},
    onCopy: () => {},
    onDeadKeyPress: () => {},
    onChangeBreakpoint: () => {},
    setValue: '',
    setReadOnly: false,
    theme: 'eclipse',
    mode: 'javascript',
    newLineMode: 'unix',
    style: { height: '300px', width: '400px' },
    fontSize: 16,
    enableBreakpoints: false,
  }

  state = {
    selectionIsEmpty: true,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const {
        onChange,
        setReadOnly,
        setValue,
        theme,
        mode,
        fontSize,
      } = this.props

      require(`brace/mode/${mode}`)
      require(`brace/theme/${theme}`)
      require('brace/ext/searchbox')

      const editor = ace.edit('ace-editor')
      this.editor = editor
      this.session = editor.getSession()
      this.session.setNewLineMode(this.props.newLineMode)
      this.session.setMode(`ace/mode/${mode}`)
      this.session.on('changeBreakpoint', this.props.onChangeBreakpoint)
      editor.setTheme(`ace/theme/${theme}`)
      editor.on('change', e => onChange(editor.getValue(), e))
      editor.on('changeSelection', () => {
        const selection = editor.getSelection()

        // Provide some smoothing so we don't generate so many events
        if (this.state.selectionIsEmpty !== selection.isEmpty()) {
          this.props.onChangeSelection(selection)
          this.setState({ selectionIsEmpty: selection.isEmpty() })
        }
      })
      editor.on('copy', this.props.onCopy)

      // Click to create breakpoints
      editor.on("guttermousedown", (e) => {
        const target = e.domEvent.target
        if (target.className.indexOf("ace_gutter-cell") === -1) {
          return
        }
        if (!editor.isFocused()) {
          return
        }
        if (e.clientX > 25 + target.getBoundingClientRect().left) {
          return
        }

        const session = e.editor.session
        const row = e.getDocumentPosition().row
        // Check if user clicked on "Invalid Breakpoint" decoration
        if (target.className.search('invalid-breakpoint') >= 0) {
          session.removeGutterDecoration(row, "invalid-breakpoint")
          session._signal("changeBreakpoint", {})
        } else {
          // Set or Clear Breakpoint
          const srcLine = session.getLine(row).trim()
          const allowBkpt = this.props.enableBreakpoints && (srcLine && srcLine[0] !== '#')
          if (allowBkpt) {
            const breakpoints = session.$breakpoints
            if (breakpoints && row in breakpoints) {
              session.clearBreakpoint(row)
              session._signal("clearSingleBreakpoint", row)
            } else {
              session.setBreakpoint(row)
              session._signal("setSingleBreakpoint", row)
            }
          }
        }

        e.stop()
      })

      editor.setReadOnly(setReadOnly)
      editor.setValue(setValue)
      editor.setFontSize(fontSize)
      editor.setShowPrintMargin(false)
      editor.$blockScrolling = Infinity
    }
  }

  shouldComponentUpdate() {
    return false
  }

  handleKeyDown = (event) => {
    const charCode = String.fromCharCode(event.which).toLowerCase()
    if (event.ctrlKey && charCode === 's') {
      // No need to manually save
      event.preventDefault()
    }
    else if (event.key === 'Dead') {
      this.props.onDeadKeyPress()
    }
  }

  render() {
    const { style, editorId } = this.props
    return (
      <div
        id={editorId}
        style={style}
        onKeyDown={this.handleKeyDown}
        role="none"
      />
    )
  }
}
export default ReactAce
