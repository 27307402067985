// The "Python With Robots" full lesson Module

import React from 'react'
import CodingModuleBase from '../CodingModuleBase'
import { peripheralsIntro, PeripheralsIntroImg } from './PeripheralsIntro'
import { liftOff, LiftOffImg } from './LiftOff'
import { conserveEnergy, ConserveEnergyImg } from './ConserveEnergy'
import { hatchLock, HatchLockImg } from './HatchLock'
import { alertSystem, AlertSystemImg } from './AlertSystem'
import { lifeSupport, LifeSupportImg } from './LifeSupport'
import { solarTracking, SolarTrackingImg } from './SolarTracking'
import { prepareLander, PrepareLanderImg } from './PrepareLander'
import { automaticGarden, AutomaticGardenImg } from './AutomaticGarden'
import { finale } from '../StartCoding/Finale'

// Projects and metadata for Google Classroom integration
export const projects = [
  {
    name: 'LiftOff Peripherals Kit',
    component: peripheralsIntro,
    description: 'This first project is all about getting to know your peripherals kit, what is in it, and how peripherals get connected together!',
    hasAssignment: true,
    img: PeripheralsIntroImg,
  },
  {
    name: 'Lift-Off',
    component: liftOff,
    description: 'The second project is all about integrating buttons and switches into your outer space adventure.',
    hasAssignment: true,
    img: LiftOffImg,
  },
  {
    name: 'Conserve Energy',
    component: conserveEnergy,
    description: 'This project uses a motion sensor and a knob to conserve power for the long journey through space.',
    hasAssignment: true,
    img: ConserveEnergyImg,
  },
  {
    name: 'Hatch Lock',
    component: hatchLock,
    description: 'This project introduces NeoPixels and a Microswitch to help the crew lock the hatch after docking.',
    hasAssignment: true,
    img: HatchLockImg,
  },
  {
    name: 'Alert System',
    component: alertSystem,
    description: 'This project uses multiple sensors and lights to alert the crew to potential issues.',
    hasAssignment: true,
    img: AlertSystemImg,
  },
  {
    name: 'Life Support',
    component: lifeSupport,
    description: 'This project introduces the continuous rotation servo to circulate air through the spacecraft.',
    hasAssignment: true,
    img: LifeSupportImg,
  },
  {
    name: 'Solar Tracking',
    component: solarTracking,
    description: 'This project introduces a sensor to track light and a positional servo to optimize solar power.',
    hasAssignment: true,
    img: SolarTrackingImg,
  },
  {
    name: 'Prepare Lander',
    component: prepareLander,
    description: 'This project introduces an object sensor and prepares the crew for landing on a planet.',
    hasAssignment: true,
    img: PrepareLanderImg,
  },
  {
    name: 'Automatic Garden',
    component: automaticGarden,
    description: 'This project prepares the crew for life in space by generating an automated garden.',
    hasAssignment: true,
    img: AutomaticGardenImg,
  },
  {
    name: 'The End',
    component: finale,
    description: 'You\'re done!',
    hasAssignment: false,
  },
]

const projectNames = projects.map(project => project.name)
const projectComponents = projects.map(project => project.component)

export default function (props) {
  const { ref2, ...other } = props
  return (
    <CodingModuleBase
      {...other}
      ref={ref2}
      projectNames={projectNames}
      projects={projectComponents}
    />
  )
}
