import React, { Component, Fragment } from 'react'
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles'

import { googleApi } from '../Gapi'
import ErrorBoundary from '../ErrorBoundary'
import { cblFirebase } from '../myfirebase'
import userProgress from '../UserProgress'
import Loading from '../Loading'
import UserSession from '../UserSession'
import LicensePortalForm from './LicensePortalForm'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#80e27e',
      main: '#4caf50', // same green as CodeSpace
      dark: '#087f23',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff64f',
      main: '#ffc400', // similar yellow to Codee
      dark: '#c79400',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
    },
  },
})

class LicensePortal extends Component {
  static defaultProps = {
    match: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      gSignedIn: googleApi.gapiAuthIsSignedIn,
      gLoaded: false,
      userAttributes: null,
      checkingPermissions: true,
      hasPermissions: false,
    }

    // here to prevent errors in the UserSession load
    userProgress.newAccountPromise().then(() => {
      console.log('non existing account')
    }).catch(() => {
      console.log('existing account')
    })
  }

  componentDidMount = () => {
    if (cblFirebase.userDoc) {
      this.checkRights()

    } else {
      cblFirebase.registerDocRetrievedCallback(() => {
        this.checkRights()
      })
    }

    googleApi.notifyOnAuthChange(this.handleGoogleSignedInNotify)
    googleApi.gapiLoadedPromise.then(() => {
      this.setState({ gLoaded: true })
    })

    userProgress.registerOnChangeCallback(this.handleUserProgressChange)
  }

  componentWillUnmount = () => {
    userProgress.unregisterOnChangeCallback(this.handleUserProgressChange)
    googleApi.unregisterNotifyOnAuthChange(this.handleGoogleSignedInNotify)
  }

  getAuthorized = () => {
    return (
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}>
            <div style={{ maxWidth: '400px', display: 'inline-block', margin: '0 auto', padding: '3px' }}>
              <div style={{ display: 'block', height: 'auto', overflow: 'auto' }}>
                <div style={{ float: 'right' }}>
                  <UserSession
                    signedIn={this.state.gSignedIn}
                    gLoaded={this.state.gLoaded}
                    userAttributes={this.state.userAttributes}
                  />
                </div>
              </div>
              <div style={{ marginTop: '30px' }}>
                {this.state.hasPermissions ?
                  <LicensePortalForm /> :
                  <p>UNATHORIZED! You do not have permissions to access this page!</p>
                }
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </MuiThemeProvider>
    )
  }

  handleGoogleSignedInNotify = (signedIn) => {
    this.setState({ gSignedIn: signedIn })
  }

  handleUserProgressChange = (userProgressObj, isUserData) => {
    if (isUserData) {
      this.setState({ userAttributes: <div /> }) // this causes the UserProgress block to rerender in the header and show email address
    }
  }

  checkRights = () => {
    console.log('User loaded checking permissions')
    cblFirebase.db.collection('adminRights').doc(cblFirebase.userDoc.id).get().then((doc) => {
      if (!doc.exists) {
        this.setState({ hasPermissions: false, checkingPermissions: false })
      } else {
        this.setState({ hasPermissions: true, checkingPermissions: false })
      }
    })
  }

  render() {
    return (
      /* App */
      this.state.userAttributes === null || this.state.checkingPermissions ?
        <div>
          <Loading />
        </div> :
        <Fragment>
          {
            this.getAuthorized()
          }
        </Fragment>
    )
  }
}

export default LicensePortal
