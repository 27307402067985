// Project: SensorsIntro

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import {
  tourFirstRun,
  tourStop,
  //WindowsOldVersionUSBnote
} from '../StartCoding/JumpstartIntro'
import Markdown from '../cbl-remarkable'
import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'

import ShipLiftOffImg from './assets/iStock-147087233.jpg'
import JourneyToSpaceImg from './assets/iStock-1171288622.jpg'


import SkillzImg from './assets/iStock-1164414770.jpg'

import OctopusBitImg from './assets/octopusbit_drawn.jpg'
import OctopusSwitch from './assets/JoxT6k21.jpg'
import OctopusPins from './assets/gk3dN4E1.jpg'
import InsertMicroBitImg from './assets/octo_connection.jpg'
import PeripheralsPackFullImg from './assets/periph_kit_explore3.jpg'
import ConnectCablesImg from './assets/connect_cables2.jpg'

import JoyBlast from '../JoyBlast'
import OctoHeader, { LegendOctoHeader } from './OctoHeader'

import mb from '../../mbEnum'

import Button from 'material-ui/Button'
import Joyride from '../cbl-joyride'
import FlyingCody from './FlyingCodyPeriph'

import peripheralsData from './PeripheralsData'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageInteract,
  LegendMessagePeripheral,
  LegendMessageWarning,
} from '../Legends'

export const PeripheralsIntroImg = GoogleClassroomImg

export class PeripheralsIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Welcome to Lift-Off Peripherals! {lesson-title}
___
`}
<br />
<FlyingCody
  wanderRate={7}
/>
{`
### Welcome Back! {centered}

Are you ready to expand your coding knowledge? I'll be your guide as **YOU** learn to interact with external components. 

You will complete some *Outer Space*-themed projects in your mission to *Lift-Off* with **code**.

#### Why add peripherals?
* A micro:bit is great, but sometimes you just need *more!*
* Sometimes you just need some hardware ==peripherals==.
* Like an **LED**, a **sensor**, a **pump**, or a **servo**...
* Peripherals allow you to interact with the world in **new** and **exciting** ways.
#### And now... It is time for YOU to hook up your favorite peripherals and get coding{centered}

As you complete this *project-based* course, you'll be learning skills that will allow you to imagine and create *ANYTHING*!
`}
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class PeripheralsKit extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      mounted: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({mounted: true}), 50)  // Hack! Workaround until we determine why JoyBlast positions are sometimes
                                                          // wrong immediately after render. Also would like to find a better way
                                                          // to know when positions can be calculated versus DidMount...
    //this.setState({mounted: true})
  }

  render() {
    return (
      <div style={{
        position: 'relative',
      }}
      ref={el => this.positionRef = el}
      >
        {this.positionRef ? (
          <JoyBlast
            steps={peripheralsData.map(val => 
            {
              const t = (
      <Markdown>
{val.description}
                <div style={{textAlign:'center'}}>
                  <img src={val.image} alt={val.title} style={{width: 200}} />
                </div>
              {`
`}
      </Markdown>
    )

              return {
                title: val.title,
                text: t,
                selector: '#' + val.selector,
              }
            }
            )}
            parentMounted={this.state.mounted}
            positionRef={this.positionRef}
          />
        ) : null}

        <Markdown>
  {`
## Explore Your Kit! {lesson-title}
___
The LiftOff Peripherals Kit contains many different ==peripherals==.
There are inputs and outputs, buttons, sensors, servos, and even a pump!  {centered}
  `}
          <div style={{textAlign: 'center'}}>
            <div style={{display: 'inline-block', position: 'relative', margin: 'auto'}}>
              <img src={PeripheralsPackFullImg} alt="Peripherals" draggable="false" style={{zIndex: '2', width: '800px'}} />

              <div id="pk_button" style={{position: 'absolute', top: '84px', left:'145px', width: '2px', height: '2px'}}></div>
              <div id="pk_pir" style={{position: 'absolute', top: '251px', left:'148px', width: '2px', height: '2px'}}></div>
              <div id="pk_wh_led" style={{display: 'none'}}></div>
              <div id="pk_180_serv" style={{position: 'absolute', top: '586px', left: '140px', width: '2px', height: '2px'}}></div>
              <div id="pk_switch" style={{position: 'absolute', top: '84px', left: '350px', width: '2px', height: '2px'}}></div>
              <div id="pk_temp" style={{position: 'absolute', top: '251px', left: '348px', width: '2px', height: '2px'}}></div>
              <div id="pk_red_led" style={{position: 'absolute', top: '405px', left: '350px', width: '2px', height: '2px'}}></div>
              <div id="pk_360_serv" style={{position: 'absolute', top: '586px', left: '350px', width: '2px', height: '2px'}}></div>
              <div id="pk_pot" style={{position: 'absolute', top: '84px', left: '550px', width: '2px', height: '2px'}}></div>
              <div id="pk_audio" style={{position: 'absolute', top: '251px', left: '553px', width: '2px', height: '2px'}}></div>
              <div id="pk_neo_pix" style={{position: 'absolute', top: '388px', left: '550px', width: '2px', height: '2px'}}></div>
              <div id="pk_pump" style={{position: 'absolute', top: '586px', left: '465px', width: '2px', height: '2px'}}></div>
              <div id="pk_crash" style={{position: 'absolute', top: '79px', left: '760px', width: '2px', height: '2px'}}></div>
              <div id="pk_light" style={{position: 'absolute', top: '246px', left: '760px', width: '2px', height: '2px'}}></div>
              <div id="pk_hunt" style={{position: 'absolute', top: '388px', left: '752px', width: '2px', height: '2px'}}></div>
              <div id="pk_soil" style={{position: 'absolute', top: '580px', left: '745px', width: '2px', height: '2px'}}></div>
              <div id="pk_relay" style={{position: 'absolute', top: '400px', left: '150px', width: '2px', height: '2px'}}></div>
            </div>
          </div>
          <br />
<LegendMessageInteract title={'Explore the Peripherals Kit'}>
<Markdown>
{`
Hover over the red tags to learn a little about each of the components.
`}
</Markdown>
</LegendMessageInteract>
<LegendMessageInteract title={'How do I Find this Information Again?'}>
<Markdown>
{`
Click this link &rarr; ==LiftOff Kit== 

This will add a topic to the **Tool Box** in the *upper right hand corner* of your screen.
The tool box allows you to return to **important** topics. 
You can access general information on the peripheral kit and learn even **more** about each of the components in your kit.
`}
</Markdown>
</LegendMessageInteract>
          <StepComplete
            lessons={this.props.lessons}
            btnNext={true}
            prompt="Press NEXT if you were able to add the Peripherals Kit to your tool box!"
          />

        </Markdown>

      </div>
    )
  }
}

export class SpaceJourney extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Mission "Peripherals" {lesson-title}
___
#### Mission Briefing:
`}
<div>
  <img src={JourneyToSpaceImg} alt="Journey to Space" style={{width: '40%', float:'right', margin:'0.5em'}} />
</div>
{`
A crew of astronauts is preparing to embark on a *long* and *arduous* journey into the vast reaches of **outer space**.

The crew must launch from *Earth*, survive a months-long journey through space, and then establish a colony on *Mars*.

They will **need** many electronic applications to aid them on their journey, and they are looking for *your* help to build them!

#### Each project, in the Peripherals course, will *simulate* real-world electronic applications that could be used:
* On-board a space shuttle
* As part of mission control
* On the *Mars* colony

Your **mission** is to utilize the **micro:bit** with different ==peripherals== to build applications needed by the crew.

`}
      </Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT should you choose to accept your mission!"
      />
    </div>
  )
  }
}

export class Prerequisites extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Got the Skillz? {lesson-title}
___
The **Peripherals** course is intended to be a *follow-on* to the **Jumpstart Python**
curriculum.  {centered}

You will need to **recall** and **apply** some basic *Python* knowledge learned in that course.

**Don't worry if you are a little rusty.** 
* You will get plenty of refresher along the way!
`}
<div>
  <img src={SkillzImg} alt="Skills" style={{width: '25%', maxWidth: '230px', float:'right'}} />
</div>
{`
#### The recommended *Python* knowledge includes:
* ==Variables==
* ==Functions== and ==parameters==
* ==Branching== with \`if\`, \`elif\` and \`else\`
* \`while\` ==loops==
* ==Timing== using the \`sleep\` function
* Using ==comments==
* ==Comparison operators== \`==\`, \`!=\`, \`<\`, and \`>\`
* ==Boolean== values \`True\` and \`False\`

If you already have those *Python* fundamentals then this course will be a **snap**!
`}
      </Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if you think you have the skillz!"
      />
    </div>
  )
  }
}

export class OctopusBit extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## The Octopus:bit {lesson-title}
___
Inside your ==LiftOff kit== you have a micro:bit connection device called the ==octopus:bit==.

`}
<div style={{textAlign: 'center'}} >
  <img src={OctopusBitImg} alt="OctopusBit" style={{width: '33%'}} />
</div>
{`
The ==octopus:bit== allows you to attach peripherals to a **micro:bit's** input / output pins using *color-coded* connector pins.
`}
<div style={{textAlign: 'center'}}>
  <img src={OctopusPins} alt="OctopusBit Pins" style={{width: '40%'}} />
</div>
{`
It also has a **VCC Switch** that allows you to switch the blue connector pins between 3.3 Volt and 5 Volt outputs.
`}
<LegendMessageWarning title={'High Voltage!'}>
<Markdown>
{`
Most projects in the Peripherals course use the **3.3V** setting for the ==octopus:bit=='s **VCC Switch**.

Using the incorrect setting can do permanent **DAMAGE** to your peripherals.

Both 3.3 and 5 Volts are both safe for humans, so there is no danger of getting shocked!
`}
</Markdown>
</LegendMessageWarning>
<LegendMessageInteract title={'Switch it to 3.3V'}>
<Markdown>
{`
Locate the **VCC Switch** and place it in the **3.3V** position.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <img src={OctopusSwitch} alt="OctopusBit Switch" style={{width: '30%'}} />
</div>
</LegendMessageInteract>
      </Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if the VCC Switch on your octopus:bit is set to 3.3V!"
      />
    </div>
  )
  }
}

export class InsertMicroBit extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Inserting the Micro:bit {lesson-title}
___
The **micro:bit** gets inserted into the ==octopus:bit==
as shown:  {centered}
`}
<div style={{textAlign: 'center'}} >
  <img src={InsertMicroBitImg} alt="Insert Microbit" style={{width: '33%'}} />
</div>
{`
The *micro:bit* buttons should be facing toward you when looking at the front of the ==octopus:bit==.

The red **PWR** LED, on the right side of the **octopus:bit**, will light up when the **micro:bit** is inserted *correctly*.
`}
      </Markdown>
      <LegendMessageWarning title={'Buttons to the Front!'}>
<Markdown>
{`
Inserting the **micro:bit** in the ==octopus:bit== backwards will prevent your peripherals from connecting!
`}
</Markdown>
</LegendMessageWarning>
      <LegendMessageInteract title={'Insert a Micro:bit'}>
      <Markdown>
{`
Insert your **micro:bit** into the ==octopus:bit==.

Now, look for the Red LED on the octopus:bit!
`}
      </Markdown>
      </LegendMessageInteract>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if the red LED on your octopus:bit is lit!"
      />
    </div>
  )
  }
}

export class ConnectPeripheral extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Connecting a Peripheral {lesson-title}
___
The ==octopus:bit== makes connecting peripherals easy, but there are a few things you should know before connecting!

First, the **colors** on the cables matter.
* Black is **G (Ground)** and needs to be connected to a **black** pin
* Red is **VCC (Power)** and needs to be connected to a **red** pin
* Yellow is **S (Signal)** and can be connected to a **yellow or blue** pin

Second, the two ends of the cable are **different**
* The **larger** end is inserted in the peripheral and should lock into place.
  * To remove it, press the small latch as you pull on the plastic connector.
* The **smaller** end attaches to the ==octopus:bit==.

***Careful!*** Never pull on the *wires* - always grasp the plastic connectors when you remove a cable.
`}
      </Markdown>
      <div style={{textAlign: 'center'}} >
        <img src={ ConnectCablesImg } alt={ "connecting octopus bit cables"} />
      </div>
      <Quiz
        lessons={this.props.lessons}
        prompt="What type of pin should the BLACK wire conenct to?"
        xp={5}
        answerRight={"G (Ground)"}
        answerWrong={["VCC (Power)", "S (Signal)"]}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt="Which color pin can you connect a S (Signal) wire to?"
        xp={5}
        answerRight={"Blue or Yellow"}
        answerWrong={["Black", "Red"]}
      />
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class ConnectRedLed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Connect the Red LED {lesson-title}
___
The Peripherals course has a new project **symbol** that lets you know it is time to connect a peripheral:
`}
<LegendMessagePeripheral>
<Markdown>
{`
This directs you to connect a peripheral to a specific ==octopus:bit== position.
`}
</Markdown>
</LegendMessagePeripheral>
{`
The Connect Peripheral project **symbol** will contain a peripheral and a connection location on the ==octopus:bit==.

This tells you to connect to **position 3** on the ==octopus:bit==:
`}
        <div style={{ display:"flex", justifyContent:"center" }} >
          <OctoHeader highlighted={[3]} imageAltText="octopus bit position 3" />
        </div>
        <LegendOctoHeader 
          pin={0}
          peripheral={6} // 6 = Red LED
          markdown={`Connect the **red LED** to **position 0** on the ==octopus:bit== now!`}
        />
      </Markdown>

      <StepComplete
        prompt="Press NEXT if red LED is connected!"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class LightItUp extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
    this.tourArray = []
  }

  handleRunTour = () => {
    if (this.props.lessons.props.mbState !== mb.state.IDLE || this.props.lessons.props.mbMode === mb.mode.DEBUG)
    {
      this.tourArray = tourFirstRun.concat(tourStop)
    } else {
      this.tourArray = tourFirstRun
    }
    this.tour.reset(true)
    this.setState({runTour: true})
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={this.tourArray}
          run={this.state.runTour}
        />
        <Markdown>
{`
## Light It Up! {lesson-title}
___
In the last step, you *connected* the red LED to **P0** (Pin 0). Now it's time to type in some **code** to light it up!

You will need to output a **HIGH** (3.3V) value to **P0** (Pin 0) to turn on the red LED.

#### So... how do I do that?
* You will need to use the micro:bit library's built-in ==pin== interface. The pin interface has many different ==functions== that you can use.

#### OK. Now how do I output a HIGH value?
* The \`write_digital\` function lets you output a digital value to a pin
`}
<LegendMessageConcept>
{`
Digital input or output values can be **HIGH** or **LOW**
  * **HIGH** can be \`1\` or \`True\`
  * **LOW** can be \`0\` or \`False\`

*You may recall these are ==Boolean== values*
`}
  </LegendMessageConcept>
{`

You will dive into many other ==pin== functions as you proceed through the peripherals course.

`}
<LegendMessageKeyboard>
<Markdown>
{`Use the ***File &rarr; New*** menu to create a new file and name it "LiftOff".

Click on the **Editor** panel to the left, and enter the code shown **below**.
`}<div className='shiny'>
<Markdown>
{`
\`\`\`
from microbit import *
pin0.write_digital(1)
\`\`\`
`}
</Markdown>
</div>
</Markdown>
</LegendMessageKeyboard>
          <LegendMessageRun>
            <br />
            <div style={{textAlign: 'center'}}>
              <Button
                variant='raised'
                onClick={this.handleRunTour}
              >
                Show me how to RUN the code...
              </Button>
            </div>
            <br />
          </LegendMessageRun>
          <StepComplete
            prompt="Did the red LED light up and stay lit?"
            xp={10}
            successMessage=
{`## AWESOME!! {centered}
### You have just used your first peripheral!
#### Proceed to the *Next* step where you will start making your code more readable.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital']}
            reqArgs={['1']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
          />
        </Markdown>
      </div>
    )
  }
}

export class DefineOn extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`
## Define ON and OFF {lesson-title}
___
Now define two ==constant==s for the ON and OFF states of the LED:
\`\`\`
LED_ON = 1
LED_OFF = 0
\`\`\`
#### Why spend the time when you could just use 0 and 1?
* \`LED_ON\` has more meaning than \`1\`. "Future You" will appreciate the *readability!*
* Circuits do not all operate the same. There are circuits where \`0\` could turn the LED on!
* Defining values up front helps avoid errors.
`}
<LegendMessageKeyboard>
<Markdown>
{`
Define two ==constant==s for the ON and OFF states of the LED and use the ON state to turn on the LED.
`}<div className='shiny'>
<Markdown>
{`
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

pin0.write_digital(LED_ON)
\`\`\`
`}
</Markdown>
</div>
</Markdown>
</LegendMessageKeyboard>
          <LegendMessageRun />
          <StepComplete
            prompt="Did the red LED light up again?"
            xp={5}
            successMessage=
{`## Shining Bright!! {centered}
### You just lit up the red LED again!
Okay, not THAT exciting. *Settle down there...*
> But your *code* is SO much more **readable!**
#### Proceed to the *Next* step.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital']}
            reqNames={['LED_ON', 'LED_OFF']}
            reqArgs={['LED_ON']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
          />
        </Markdown>
      </div>
    )
  }
}


export class FunctionalLED extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`
## Code with Meaning {lesson-title}
___
Now **you** will add a ==function== to make your code even more *meaningful*.

Call your function \`set_red_led\` and have it take one ==parameter== named \`val\`:
\`\`\`
def set_red_led(val):
\`\`\`

Creating functions with *meaningful* names will pay **BIG** dividends as your projects grow and become more involved.
`}
<LegendMessageKeyboard>
<Markdown>
{`
Move your red LED illumination code into the \`set_red_led()\` function. Have it use the \`val\` passed into the function to turn the LED on or off.
* Mind your ==indentation== - and remember to use the **TAB** key to indent your code!
`}<div className='shiny'>
<Markdown>
{`
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

$def set_red_led(val):
    pin0.write_digital(val)

$set_red_led(LED_ON)
\`\`\`
`}
</Markdown>
</div>
</Markdown>
</LegendMessageKeyboard>
          <LegendMessageRun />
          <StepComplete
            prompt="Did the red LED turn on and stay lit after your function call?"
            xp={10}
            successMessage=
{`## AWESOME!! {centered}
### You have just started an *incredible* journey with
### *Mission Pack: Lift Off!* {centered}
#### Proceed to the *Next* project where you will power on the rocket and count down to lift-off.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'set_red_led']}
            reqFuncdefs={['set_red_led']}
            reqNames={['LED_ON', 'LED_OFF']}
            reqArgs={['LED_ON']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
          />

        </Markdown>
      </div>
    )
  }
}

class PeripheralsIntroDone extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Ready for Launch! {lesson-title}
___

### You've completed the first LiftOff Peripherals Kit project!
...and you're at the start of a fantastic **adventure**. From this small first project, your journey will take you to greater
heights - more projects are ahead to *challenge* and *amaze* you!
`}
        <img src={ShipLiftOffImg} alt="" style={{margin: 'auto', width:'100%', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="A universe of possibilities awaits you... Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
PeripheralsIntro.stepId = 'PeripheralsIntro'
SpaceJourney.stepId = 'SpaceJourney'
Prerequisites.stepId = 'Prerequisites'
PeripheralsKit.stepId = 'PeripheralsKit'
OctopusBit.stepId = 'OctopusBit'
InsertMicroBit.stepId = 'InsertMicroBit'
ConnectPeripheral.stepId = 'ConnectPeripheral'
ConnectRedLed.stepId = 'ConnectRedLed'
LightItUp.stepId = 'LightItUp'
DefineOn.stepId = 'DefineOn'
FunctionalLED.stepId = 'FunctionalLED'
PeripheralsIntroDone.stepId = 'PeripheralsIntroDone'


export const peripheralsIntro = [
  PeripheralsIntro,
  Prerequisites,
  SpaceJourney,
  PeripheralsKit,
  OctopusBit,
  InsertMicroBit,
  ConnectPeripheral, // show how to connect to the octopus bit and how it is foolproof
  ConnectRedLed, // explain the new connect symbol
  LightItUp,
  DefineOn,
  FunctionalLED,
  PeripheralsIntroDone,
]
