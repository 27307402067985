// WebUSB management
import tracker from './tracking'

// Error codes for navigator.usb
const USB_DEV_DISCONNECT = 8
const USB_INVALID_STATE_ERROR = 11  // name="InvalidStateError"
const USB_TRANSFER_ERROR = 19
//const USB_TRANSFER_CANCEL = ??
const USB_ABORT_ERROR = 20

var serial = {};

function isIncompatibleWebUSBDevice(device) {
  // Normally every device that passes our 'requestPort filter' is compatible. This allows us
  // to detect special-case incompatible devices that match our filter settings.
  // Prevent connnection to incompatible DAPLINK WebUSB devices (newer "stock" micro:bits)
  return (device.vendorId === 0x0D28 && device.deviceVersionMajor !== 1)
}

(function() {
  serial.getPorts = function() {
    return navigator.usb.getDevices().then(devices => {
      const compatDevices = devices.filter(device => !isIncompatibleWebUSBDevice(device))
      return compatDevices.map(device => new serial.Port(device));
    });
  };

  serial.requestPort = function() {
    const filters = [
      // Microbit
      {
        'vendorId': 0x0D28,
        'productId': 0x0204
      },
      // CodeBot-CB2
      {
        'vendorId': 0x0483,
        'productId': 0x5740
      },
      // CodeBot-CB3
      {
        'vendorId': 0x544d,
        'productId': 0xcb03
      },
      // CodeX GEN2 Prototypes
      {
        'vendorId': 0x544d,
        'productId': 0xc0de
      },
      //// CodeX GEN1 Prototypes
      //{
      //  'vendorId': 0x239a,
      //  'productId': 0xc0de
      //},
      //// Kaluga-1
      //{
      //  'vendorId': 0x239a,
      //  'productId': 0x80c8
      //},
    ];
    return navigator.usb.requestDevice({
      'filters': filters
    }).then(
      (device) => {
        if (isIncompatibleWebUSBDevice(device)) {
          console.log("USB: Non FiriaLabs microbit selected!")
        } else {
          return new serial.Port(device)
        }
      },
      serial.requestDeviceFailed
    );
  }

  serial.requestDeviceFailed = function(err) {
    tracker.addBreadcrumb('USB requestDevice error', 'usb', 'error', err)
    // console.log(`USB requestDevice error - ${err}`)
  };

  serial.Port = function(device) {
    this.device_ = device;
  };

  serial.Port.prototype.connect = function() {
    let readLoop = () => {
      this.device_.transferIn(this.endpointIn_, 512).then(result => {
        // const bytes = new Uint8Array(result.data.buffer)
        // console.log(`==TRANSFERIN==\n${String.fromCharCode(...bytes)}\n====`)
        this.onReceive(result.data);
        readLoop();
      }, error => {
        if (error.code === USB_DEV_DISCONNECT) {
          // Do not continue to thrash readLoop in disconnect case.
          // TODO: Other cases MAY fall in this category (?)
        }
        else if (error.code === USB_INVALID_STATE_ERROR) {
          // Experimental...
          console.log("WebUSB: InvalidStateError")
          this.device_.close()
        }  
        else {
          // Transfer interrupted (possible timeout) - initiate another read
          // Known cases to accept: USB_TRANSFER_ERROR, USB_TRANSFER_CANCEL, USB_ABORT_ERROR
          readLoop();
        }
        this.onReceiveError(error)
      }).catch((err) => {
        // EXPERIMENTAL -- TODO: test this exception case, make sure we don't spinlock Chrome here...
        tracker.addBreadcrumb('USB unhandled transferIn error', 'usb', 'error', err)
        readLoop()
      })
    };

    return this.device_.open()
      .then(() => {
        console.log(`${Date.now()}: Device opened = ${this.device_.opened}`)
      })
      .then(() => {
        console.log('selectConfiguration')
        if (this.device_.configuration === null) {
          return this.device_.selectConfiguration(1);
        } else {
          console.log('WebUSB device configuration non-null')
        }
      })
      .then(() => {
        console.log('device=', this.device_)
        this.is_claimed = false
        console.log('Traversing interfaces configured on this device...')
        var configurationInterfaces = this.device_.configuration.interfaces;
        configurationInterfaces.forEach(element => {
          console.log(`  Interface #${element.interfaceNumber} (${element.claimed ? "claimed" : "not claimed"}), selected Alternate: name=${element.alternate.interfaceName}, class=${element.alternate.interfaceClass}`)
          console.log(`    * Checking ${element.alternates.length} Alternates`)
          element.alternates.forEach(elementalt => {
            console.log(`    - Alternate: name=${element.alternate.interfaceName}, class=${element.alternate.interfaceClass}`)
            if (elementalt.interfaceClass===0xff) {
              console.log(`     > Found interfaceClass=0xff`)
              this.interfaceNumber_ = element.interfaceNumber;
              this.is_claimed = element.claimed;
              elementalt.endpoints.forEach(elementendpoint => {
                if (elementendpoint.direction === "out") {
                  console.log('     > Found OUT endpoint')
                  this.endpointOut_ = elementendpoint.endpointNumber;
                }
                if (elementendpoint.direction==="in") {
                  console.log('     > Found IN endpoint')
                  this.endpointIn_ =elementendpoint.endpointNumber;
                }
              })
            }
          })
        })
      })
      // .then(() => {
      //   console.log('Releasing interfaceNumber ', this.interfaceNumber_)
      //   return this.device_.releaseInterface(this.interfaceNumber_)
      // })
      .then(() => {
        if (!this.is_claimed) {
          // Note: The claimInterface promise may NEVER settle, for example if another driver is bound to this device.
          // TODO: Due to that, we should add a timeout (promise.race) to alert of this error condition on timeout. 
          console.log(`${Date.now()}: Claiming interfaceNumber=(${this.interfaceNumber_}), device:`, this.device_)
          return this.device_.claimInterface(this.interfaceNumber_)
        } else {
          console.log('Already claimed. Skip to selectAlternateInterface...')
        }
      })
      // Firia: TEST 09-19-22  (If works, should check first if current is already selected alternate)
      .then(() => {
        console.log('Selecting Alternate interfaceNumber ', this.interfaceNumber_)
        return this.device_.selectAlternateInterface(this.interfaceNumber_, 0)
      })
      .then(() => {
        console.log(`${Date.now()}: controlTransferOut: interfaceNumber `, this.interfaceNumber_)
        return this.device_.controlTransferOut({
          'requestType': 'class',
          'recipient': 'interface',
          'request': 0x22,
          'value': 0x01,
          'index': this.interfaceNumber_})
      })
      .then(() => {
        console.log('readLoop')
        readLoop()
      })
      .catch((err) => {
        console.log("WebUSB: Error opening device.", err)
      })
    }

  serial.Port.prototype.disconnect = function() {
    return this.device_.controlTransferOut({
      'requestType': 'class',
      'recipient': 'interface',
      'request': 0x22,
      'value': 0x00,
      'index': this.interfaceNumber_
    }).then(() => this.device_.close());
  };

  serial.Port.prototype.send = function(data) {
    return this.device_.transferOut(this.endpointOut_, data).then(
      result => {
         // console.log('transferOut result:', result);
      },
      error => {
        console.error('USB Transfer out error', error)
        this.device_.close()
      }
    );
  };
})();

class WebusbComm {
  constructor() {
    //console.log("WebusbComm constructor")
    this.rxHandler = null
    this.port = null
    this.signalDevConnected = null
    this.signalDevDisconnected = null

    this.firstConnectPromise = new Promise((resolve, reject) => {
      this.firstConnect = () => resolve()
    })

    // Create simple event notifiers for usb connect/disconnect.
    // Fires when paired device is plugged/unplugged, OR as result of connectAvailable() resolve on start
    if (navigator.usb) {
      navigator.usb.addEventListener('connect', (connectionEvent) => {
        //console.log("Event! Connected to ", device);
        if (!isIncompatibleWebUSBDevice(connectionEvent.device)) {
          this.connectAvailable()
        }
      })
      navigator.usb.addEventListener('disconnect', (connectionEvent) => {
        console.log(`${Date.now()}: USB disconnected from device ${connectionEvent.device.productName}`)
        this.print('\nDisconnected.')
        this.gotDisconnected()
      })
    } else {
      tracker.addBreadcrumb('Unable to find navigator.usb', 'usb', 'info')
    }

    // console.log("USB Devices: ", navigator.usb.getDevices());
  }

  isConnected = () => {
    return this.port !== null
  }

  // An event has led to disconnected state.
  gotDisconnected = () => {
    this.port = null
    if (this.signalDevDisconnected) {
      this.signalDevDisconnected()
    }
  }

  connectAvailable = () => {
    console.log('connectAvailable')
    serial.getPorts()
    .then(ports => {
      //console.log("Ports: ", ports);
      if (ports.length === 0) {
        //this.print('\nNo devices connected.');   // Is this useful?? (DBE)
        console.log("WebUsb: No devices connected.")
        return null
      }
      else {
        this.port = ports[0];
        console.log('set this.port = ', this.port)
        return this.connect();
      }
    })
    .catch(error => {
      console.log("WebUsb exception in GetPorts: ", error)
    })
  }

  request = () => {
    console.log(`${Date.now()}: USB device requested. Port is currently ${this.port}`)
    if (!this.port) {
      serial.requestPort().then(selectedPort => {
        // If a user connects the USB AFTER opening the dialog - we need to make sure the connection
        // has not already been re-established
        if (!this.port && selectedPort) {
          this.port = selectedPort;
          this.connect();
        }
      }, (error) => {
        console.log(`${Date.now()}: Error selecting USB device`, error)
        this.gotDisconnected()
      });
    } else {
      // TEST - how did we get in this state?
      console.error("WebUsb: reconnecting to already established port")
      // this.connect()
    }
  }

  connect = () => {
    console.log(`${Date.now()}: Starting connection to USB device ${this.port.device_.productName}`)
    this.port.onReceive = this.recv
    this.port.onReceiveError = (error) => {
      if (error.code !== USB_TRANSFER_ERROR &&
          error.code !== USB_DEV_DISCONNECT &&
          error.code !== USB_ABORT_ERROR) {
        console.log(`USB Receive error: name=${error.name}, code=${error.code}`, error)
      }
    }
    return this.port.connect().then(() => {
      console.log(`${Date.now()}: USB connected to device ${this.port.device_.productName}`)
      //this.print('\nConnected.')   // DBE??
      this.firstConnect()
      if (this.signalDevConnected) {
        this.signalDevConnected()
      }
    }, (error) => {
      console.log(`${Date.now()}: USB connection error`, error)
      this.gotDisconnected()
    })
  }

  setRxHandler = (handler) => {
    this.rxHandler = handler
  }

  setSignalDeviceConnected = (handler) => {
    this.signalDevConnected = handler
  }

  setSignalDeviceDisconnected = (handler) => {
    this.signalDevDisconnected = handler
  }

  print (text) {
    if (this.rxHandler) {
      this.rxHandler(text)
    }
  }

  // USB port -> Webpage
  // data is a dataBuffer.
  // Note: reverted the following stream-decoder alternative while tracking a problem
  //   this.textDecoder = new TextDecoder('utf-8') // in ctor
  //   const text = this.textDecoder.decode(data.buffer, {stream: true})
  recv = (data) => {
    const arrayTool = new Uint8Array(data.buffer);
    // console.log(`==RECV==\n${String.fromCharCode(...arrayTool)}\n====`)

    //console.log("Serial rx: ", data)
    if (this.rxHandler) {
      this.rxHandler(arrayTool)
    } else {
      // console.log('no rxHandler')
    }
  }

  // Webpage -> USB port
  // text is a string.
  send = (text) => {
    //console.log("Serial tx: ", text)
    let textEncoder = new TextEncoder();
    if (this.port && this.port.send) {
      this.port.send(textEncoder.encode(text))
    }
    else {
      tracker.addBreadcrumb('USB send called with no port', 'usb', 'info')
    }
  }
}

const webusbComm = new WebusbComm()
export default webusbComm