import React from 'react'
import PropTypes from 'prop-types'
import Button from 'material-ui/Button'
import TextField from 'material-ui/TextField'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'material-ui/Dialog'
import { googleApi } from './Gapi'

export default class FormDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  state = {
    fileName: null,
  }

  handleClose = (result) => {
    this.setState({ fileName: null })
    this.props.onClose(result)
  }

  handleOk = () => {
    if (!googleApi.hasDriveScope()) {
      return googleApi.requestDriveScope(this.handleMoveFile)
    }
    return googleApi.driveCopy(this.props.gFileId, this.state.fileName)
      .then((response) => {
        this.handleClose(response.result)
      })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) { // ENTER
      this.handleOk()
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open && Boolean(this.props.gFileId)}
        onClose={() => this.handleClose(null)}
        onKeyDown={this.handleKeyDown}
        role="none"
      >
        <DialogTitle id="form-dialog-title">Copy File</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="File Name"
            fullWidth
            onChange={(event) => { this.setState({ fileName: event.target.value }) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(null)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button id='copy-file-ok' onClick={this.handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
