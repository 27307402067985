/* Terminal Console window - implements subset of ANSI/VT100 escape sequences required for MicroPython REPL.

   This implementation is a "minimalist" console built on the HTML5 native TextArea control. This makes it
   lighter-weight by far than any similar implementation I could find, with no external dependencies.

   Note: If in the future we want more complete support of terminal functions, consider
         hterm from: https://chromium.googlesource.com/apps/libapps/

*/
import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ConsoleRepl extends Component {
  static propTypes = {
    onCharInput: PropTypes.func.isRequired,
    maxChars: PropTypes.number,
  }
  static defaultProps = {
    maxChars: 4096,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      textContent: '',
    }
    this.eventHandled = false
    this.escape_state = 0
    this.cursor_ofs = 0
  }

  /*
  hexdump = (ch) => {
    let buf = ''
    for (let i = 0; i < ch.length; ++i) {
      const hex = ('00' + ch.charCodeAt(i).toString(16)).substr(-2)
      buf += hex + '.'
    }
    return buf
  }
  */

  // Append array of ASCII character codes to Console box
  // Usage: Use the 'ref' prop to call this imperatively
  appendChars = (chars) => {
    let current_cursor_ofs = this.cursor_ofs
    let newTextContent = this.state.textContent

    //console.log(`starting cursor=${current_cursor_ofs}, textArea.selector=${this.textArea.selectionEnd}`)
    //console.log(this.hexdump(chars))

    for (let i = 0; i < chars.length; i++) {
      const c = chars[i]
      if (this.escape_state === 1) {  // Got ESC
        if (c === '[') {
          this.escape_state = 2
          this.escape_cnt = ''
        }
        else {
          this.escape_state = 0
        }
      }
      else if (this.escape_state === 2) {  // Got [
        if (c === 'K') {  // clear to right of cursor
          if (this.cursor_ofs < 0) {
            newTextContent = newTextContent.slice(0, this.cursor_ofs)
            this.cursor_ofs = 0
          }
          this.escape_state = 0
        }
        else if (c === 'C') {  // cursor Right N cols
          this.cursor_ofs += this.escape_cnt
          this.escape_state = 0
        }
        else if (c === 'D') {  // cursor Left N cols
          this.cursor_ofs -= this.escape_cnt
          this.escape_state = 0
        }
        else {
          // Accumulate digits
          if ('0123456789'.includes(c)) {
            this.escape_cnt += c
            // remain in current state
          }
          else {
            console.log(`Console: unhandled VT Escape: ${c} (0x${c.charCodeAt(0).toString(16)})`)
            this.escape_state = 0
          }
        }
      }
      else {
        if (c === '\x1B') {
          this.escape_state = 1
        }
        else if (c === '\b') {  // backspace
          this.cursor_ofs -= 1
        }
        else {
          if (this.cursor_ofs < 0) {
            newTextContent = newTextContent.slice(0, this.cursor_ofs) + c
            this.cursor_ofs = 0
          }
          else {
            newTextContent += c
          }
          current_cursor_ofs = 0
        }
      }
    }

    if (newTextContent.length > this.props.maxChars) {
      newTextContent = newTextContent.slice(-this.props.maxChars)
    }

    // Update cursor (caret) position
    const cursor_move = this.cursor_ofs - current_cursor_ofs
    if (cursor_move) {
      // Defer cursor reposition to after the textArea is updated with new data
      setTimeout(() => {
        const newpos = this.textArea.selectionEnd + cursor_move
        this.textArea.selectionEnd = this.textArea.selectionStart = newpos
      })
    }
    //console.log(`cursor_move=${cursor_move}, textArea.selector=${this.textArea.selectionEnd}`)

    this.setState({textContent: newTextContent})
  }

  handleKeydown = (ev) => {
    // console.log("Console. Key press: ", ev.key, ev.keyCode)
    if (ev.keyCode === 8 || ev.keyCode === 13) {  // Backspace, CR
      this.props.onCharInput(ev.keyCode)
      this.eventHandled = true
    }
    else if (ev.keyCode === 9) {
      this.props.onCharInput(ev.keyCode)
      ev.preventDefault()
    }
    else if (ev.keyCode === 65 && ev.ctrlKey === true) { // ^A
      this.props.onCharInput(1)
    }
    else if (ev.keyCode === 66 && ev.ctrlKey === true) { // ^B
      this.props.onCharInput(2)
    }
    else if (ev.keyCode === 67 && ev.ctrlKey === true) { // ^C
      this.props.onCharInput(3)
    }
    else if (ev.keyCode === 68 && ev.ctrlKey === true) { // ^D
      this.props.onCharInput(4)
      ev.preventDefault()
    }
    else if (ev.keyCode === 69 && ev.ctrlKey === true) { // ^E
      this.props.onCharInput(5)
      ev.preventDefault()
    }
    else if (ev.keyCode === 37) { // Left Arrow
      this.props.onCharInput([27, 91, 68])  // ESC [ D
      ev.preventDefault()
    }
    else if (ev.keyCode === 38) { // Up Arrow
      this.props.onCharInput([27, 91, 65])  // ESC [ A
      ev.preventDefault()
    }
    else if (ev.keyCode === 39) { // Right Arrow
      this.props.onCharInput([27, 91, 67])  // ESC [ C
      ev.preventDefault()
    }
    else if (ev.keyCode === 40) { // Down Arrow
      this.props.onCharInput([27, 91, 66])  // ESC [ B
      ev.preventDefault()
    }

  }

  handleKeyinput = (ev) => {
    // console.log("Console. Key input: ", ev.target.value[ev.target.textLength - 1])
    if (this.eventHandled) {
      this.eventHandled = false
      return
    }
    const c = ev.nativeEvent.data
    if (c) {
      this.props.onCharInput(c.charCodeAt(0))
    }
  }

  handleMouseDown = (ev) => {
    // Mouse click sets focus, but does not do default textarea action of changing cursor position.
    ev.preventDefault()
    this.textArea.focus()
  }

  componentDidUpdate() {
    this.textArea.scrollTop = this.textArea.scrollHeight
  }

  render() {
    return (
      <textarea
        style={{
          flex: 1,
          display: 'flex',
          resize: 'none',
          border: '1px solid #d9d9d9',
          padding: '0.5em'
        }}
        onKeyDown={this.handleKeydown}
        onInput={this.handleKeyinput}
        onMouseDown={this.handleMouseDown}
        value={this.state.textContent}
        ref={(inst) => this.textArea = inst}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    )
  }
}


export default ConsoleRepl
