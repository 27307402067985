import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'material-ui/Dialog'
import Select from 'material-ui/Select'
import Button from 'material-ui/Button'
import Input, { InputLabel } from 'material-ui/Input'
import { FormControl } from 'material-ui/Form'
import { MenuItem } from 'material-ui/Menu'
import { CircularProgress } from 'material-ui/Progress'
import { googleApi } from './Gapi'


export class GoogleClassroomSubmit extends React.Component {
  static propTypes = {
    gFileId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    stepName: PropTypes.string.isRequired,
    currentProjectNum: PropTypes.number.isRequired,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => (
    {
      courses: null,
      courseWork: null,
      selectedCourse: null,
      selectedWork: null,
      showNoAccess: false,
      submitting: false,
      canTurnIn: true,
    }
  )

  componentDidUpdate = async (prevProps, prevState) => {
    if (!prevProps.open && this.props.open) {
      try {
        const response = await googleApi.getCourses()
        // Is user enrolled in any courses? Set to empty list if not.
        const myCourses = response.result.courses ? response.result.courses.filter(course => course.courseState === 'ACTIVE' && course.teacherFolder === undefined) : []
        this.setState({ courses: myCourses })
      } catch (error) {
        // console.error(error)
        if (error.status === 403) {
          this.setState({ showNoAccess: true })
        } else {
          throw error
        }
      }
    } else if (prevProps.open && !this.props.open) {
      this.setState(this.getInitialState())
    }

    if (this.state.selectedCourse !== null && this.state.selectedCourse !== prevState.selectedCourse) {
      const workResponse = await googleApi.getCourseWork(this.state.selectedCourse)
      let workWithSubmissions = {}
      let foundWork = false

      if (workResponse.result.courseWork) {
        const codespaceWork = workResponse.result.courseWork.filter(work => work.associatedWithDeveloper)
        const submissions = await Promise.all(codespaceWork.map(work => googleApi.getCourseWorkSubmissions(work.courseId, work.id)))
        workWithSubmissions = codespaceWork.reduce((obj, work) => {
          obj[work.id] = work // eslint-disable-line no-param-reassign
          return obj
        }, {})

        submissions.forEach((response) => {
          workWithSubmissions[response.courseWorkId].studentSubmissions = response.result.studentSubmissions
          // We can't set any meta-data when we create the google classroom assignments so best we can do is search by project number
          if (workWithSubmissions[response.courseWorkId].title && parseInt(workWithSubmissions[response.courseWorkId].title.replace(/\D/g, ''), 10) === this.props.currentProjectNum + 1) {
            this.setState({
              selectedWork: response.courseWorkId,
              canTurnIn: response.result.studentSubmissions[0].state !== 'TURNED_IN',
            })
            foundWork = true
          }
        })
      }

      this.setState({
        courseWork: workWithSubmissions,
        autoselectedWork: foundWork,
      })

      if (!foundWork) {
        this.setState({
          selectedWork: null,
          canTurnIn: true,
        })
      }
    }
  }

  getCoursesContent = () => {
    if (this.state.courseWork) {
      const courseWork = Object.values(this.state.courseWork)
      courseWork.sort((a, b) => parseInt(a.title.replace(/\D/g, ''), 10) - parseInt(b.title.replace(/\D/g, ''), 10))
      if (courseWork.length === 0) {
        return (
          <div>Please select a class that has CodeSpace assignments</div>
        )
      } else if (!this.state.canTurnIn) {
        return (
          <div>This assignment has already been turned in</div>
        )
      }

      return (
        <FormControl
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <InputLabel
            htmlFor="course-helper"
            shrink
            style={{
              marginTop: '1em',
            }}
          >
            {
              this.state.autoselectedWork ?
                <Fragment>Assignment</Fragment>
                :
                <Fragment>Choose an Assignment</Fragment>
            }
          </InputLabel>
          <Select
            style={{ flex: 1 }}
            value={this.state.selectedWork}
            onChange={(event) => {
              this.setState({
                selectedWork: event.target.value,
                canTurnIn: this.state.courseWork[event.target.value].studentSubmissions[0].state !== 'TURNED_IN',
              })
            }}
            input={
              <Input
                name="class"
                id="course-helper"
                readOnly={this.state.autoselectedWork}
              />
            }
          >
            {
              courseWork.map(work => (
                <MenuItem
                  key={work.id}
                  value={work.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    height: '3em',
                  }}
                >
                  <div style={{ width: '100%' }}>{work.title}</div>
                  <div
                    style={{
                      color: '#7f7f7f',
                      width: '100%',
                    }}
                  >
                    {work.studentSubmissions[0].state === 'TURNED_IN' ? <Fragment>Turned In</Fragment> : null}
                  </div>
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )
    }

    return (
      <Fragment>
        <CircularProgress
          style={{
            margin: 'auto',
            width: 20,
            height: 20,
            color: 'green',
          }}
        />
        <div
          style={{
            paddingLeft: '0.5em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          Loading Assignments...
        </div>
      </Fragment>
    )
  }

  getContent = () => {
    if (this.state.showNoAccess) {
      return (
        <div>
          This user currently does not have access to Google Classroom
        </div>
      )
    }

    return (
      <Fragment>
        <form autoComplete="off">

          <div style={{ display: 'flex' }}>
            {
            this.state.courses === null
              ?
                <div
                  style={{
                    display: 'flex',
                    height: '1.4em',
                  }}
                >
                  <CircularProgress
                    style={{
                      margin: 'auto',
                      width: 20,
                      height: 20,
                      color: 'green',
                    }}
                  />
                  <div
                    style={{
                      paddingLeft: '0.5em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    Loading Classes...
                  </div>
                </div>
              :
                <FormControl style={{ display: 'flex', flex: 1 }}>
                  <InputLabel
                    htmlFor="class-helper"
                    shrink
                    style={{
                      marginTop: '1em',
                    }}
                  >
                    Choose a class
                  </InputLabel>
                  <Select
                    style={{ flex: 1 }}
                    value={this.state.selectedCourse}
                    onChange={(event) => {
                      this.setState({
                        selectedCourse: event.target.value,
                        selectedWork: null,
                        courseWork: null,
                      })
                    }}
                    inputProps={{
                      name: 'class',
                      id: 'class-helper',
                    }}
                  >
                    {
                      this.state.courses.map(course => (
                        <MenuItem
                          key={course.id}
                          value={course.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            height: '3em',
                          }}
                        >
                          <div style={{ width: '100%' }}>{course.name}</div>
                          <div
                            style={{
                              color: '#7f7f7f',
                              width: '100%',
                            }}
                          >
                            {course.section}
                          </div>
                        </MenuItem>
                      ))
                  }
                  </Select>
                </FormControl>
          }
          </div>

          <div
            style={{
              marginTop: '1em',
              display: 'flex',
            }}
          >
            {
            this.state.selectedCourse
              ?
                this.getCoursesContent()
              :
                null
          }
          </div>

        </form>
      </Fragment>
    )
  }

  handleClose = (result) => {
    this.props.onClose(result)
  }

  handleOk = async () => {
    if (this.state.selectedCourse !== null || this.state.selectedWork !== null) {
      this.setState({ submitting: true })
      const studentResponse = await googleApi.getCourseStudent(this.state.selectedCourse, googleApi.getCurrentUser().getId())
      const copyResponse = await googleApi.driveCopy(
        this.props.gFileId,
        `${googleApi.getCurrentUser().getBasicProfile().getName()} - ${this.props.stepName}`,
        studentResponse.result.studentWorkFolder.id,
      )
      await googleApi.addCourseWorkSubmissionAttachment(
        this.state.selectedCourse,
        this.state.selectedWork,
        this.state.courseWork[this.state.selectedWork].studentSubmissions[0].id,
        [
          {
            driveFile: {
              id: copyResponse.result.id,
            },
          },
        ],
      )
      const turnInResponse = await googleApi.addCourseWorkSubmissionTurnIn(
        this.state.selectedCourse,
        this.state.selectedWork,
        this.state.courseWork[this.state.selectedWork].studentSubmissions[0].id,
      )
      // console.log(turnInResponse)
      this.handleClose(turnInResponse.status)
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) { // ENTER
      this.handleOk()
    } else if (e.keyCode === 27) { // ESCAPE
      this.handleClose(null)
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open && !!this.props.gFileId}
        onClose={() => this.handleClose(null)}
        onKeyDown={this.handleKeyDown}
        role="none"
      >
        <DialogTitle id="form-dialog-title">Turn In Google Classroom Assignment</DialogTitle>
        <DialogContent>
          {this.getContent()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleClose(null)}
            color="primary"
            autoFocus
            disabled={this.state.submitting}
          >
            Close
          </Button>
          <Button
            id="gclassroom-submit-ok"
            onClick={this.handleOk}
            color="primary"
            disabled={this.state.submitting || this.state.showNoAccess || this.state.selectedCourse === null || this.state.selectedWork === null || !this.state.canTurnIn}
          >
            {
              this.state.submitting
                ?
                  <span>Turning In...</span>
                :
                  <span>Turn In</span>
            }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default GoogleClassroomSubmit
