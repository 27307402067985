// Use Joyride format 'step' array to show a blast of simultaneous beacon/tooltips
// Relies on {selector, title, text, style, rotate} properties of each 'step' object.

// TODO: Try using MutationObserver (https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver)
//       to better track element positions. Also can pass in parent element as a prop and use mutation.type == 'childList'
//       rather than parentMounted prop to trigger iteration over selected elements.

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import MoreIcon from 'material-ui-icons/More'
import Popover from 'material-ui/Popover'

/*
// Handy utility function to get absolute position of an element
function getPosition(eltID) {
  let xPos = 0
  let yPos = 0

  let el = document.getElementById(eltID)

  while (el) {
    if (el.tagName === "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      const xScroll = el.scrollLeft || document.documentElement.scrollLeft
      const yScroll = el.scrollTop || document.documentElement.scrollTop

      xPos += (el.offsetLeft - xScroll + el.clientLeft)
      yPos += (el.offsetTop - yScroll + el.clientTop)
    } else {
      // for all other non-BODY elements
      xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft)
      yPos += (el.offsetTop - el.scrollTop + el.clientTop)
    }

    el = el.offsetParent
  }
  return {
    x: xPos,
    y: yPos
  }
}
*/

// Perhaps better way
function getPosition2(anchor, eltID) {
  const elt = document.getElementById(eltID)
  const rect = elt.getBoundingClientRect()
  const anchorRect = anchor.getBoundingClientRect()
  // console.log('rect: ', rect)
  return {x:(rect.x - anchorRect.x), y:(rect.y - anchorRect.y)}
}

// Popup a "help" card for given step
function PopStep(props)
{
  const step = props.step

  const popoverStyle = Object.assign(
    {
      padding: 10,
      zIndex: 20,
      backgroundColor: 'beige',
      fontSize: '110%',
      maxWidth: 400,
      maxHeight: 400
    },
    (step && step.style) || {}
  )

  return (
    <Popover
      open={Boolean(step)}
      onClose={props.onRequestClose}
      anchorEl={step && document.getElementById(step.selector.substr(1))}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      style={{
        pointerEvents: 'none',  // Important! Confounded Popover causes onMouseOut event otherwise!!
      }}
      PaperProps={{style: popoverStyle
        /*{
          padding: 10,
          zIndex: 20,
          backgroundColor: 'beige',
          fontSize: '110%',
          maxWidth: 400,
          maxHeight: 400
        }*/
      }}
    >
      {step &&  (
        <div>
          <h3 style={{margin: 0}}>{step.title}</h3>
          <hr />
          {typeof(step.text) === 'string' ? (
            <span dangerouslySetInnerHTML={{ __html:step.text }} />
           ) : step.text}
        </div>
      )}
    </Popover>
  )
}

class JoyBlast extends Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    parentMounted: PropTypes.bool.isRequired,
    positionRef: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      helpStep: null,
    }
  }

  showHelp = (step) => {
    this.setState({helpStep: step})
  }

  // MouseEvents broken out for debugging...
  mouseOutEvent = (ev, step) => {
    //console.log("out")
    this.setState({helpStep: null})
  }
  mouseOverEvent = (ev, step) => {
    //console.log("over")
    this.showHelp(step)
  }

  getButtonList = () => {
    const buttons = []
    this.props.steps.forEach(step => {
      const pos = getPosition2(this.props.positionRef, step.selector.substr(1))  // assume selector of form '#hello'
      const rotate = step.rotate ? step.rotate : '0deg'
      const b = (
        <IconButton  color="primary" aria-label="More"
          onMouseOver={(ev) => this.mouseOverEvent(ev, step)}
          onMouseOut={(ev) => this.mouseOutEvent(ev, step)}
          key={step.selector}
          onClick={() => this.showHelp(step)}
          style={{
            position: 'absolute',
            zIndex: 10,
            left: pos.x,
            top: pos.y - 24,
            color: 'red',
            transform: `rotate(${rotate})`,
          }}
        >
          <MoreIcon
          />
        </IconButton>
      )
      buttons.push(b)
    })

    return buttons
  }

  render() {
    if (this.props.parentMounted) {
      return (
        <div>
          {this.getButtonList()}
          <PopStep
            step={this.state.helpStep}
            onClose={() => {this.setState({helpStep: null})}}
          />
        </div>
      )
    }
    else {
      return null
    }
  }
}

export default JoyBlast
