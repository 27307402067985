import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import Popover from 'material-ui/Popover'
import Avatar from 'material-ui/Avatar'
import Typography from 'material-ui/Typography'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import Button from 'material-ui/Button'
import { CircularProgress } from 'material-ui/Progress'
import { googleApi } from './Gapi'
import firebase, { cblFirebase } from './myfirebase'
import UserProgress from './UserProgress'
//import { licensor } from './CodeSpaceLicensor'


const SIGNIN_DIV_ID = 'g-signin2'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
    textAlign: 'center',
    backgroundColor: '#f8f8f8',
    border: '1px solid #c6c6c6',
    padding: '5px 15px 5px 15px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.8em',
    cursor: 'pointer',
  },
  typography: {
    margin: theme.spacing.unit * 2,
  },
})


class UserSession extends Component {
  static propTypes = {
    gLoaded: PropTypes.bool.isRequired,
    signedIn: PropTypes.bool.isRequired,
    classes: PropTypes.shape(styles).isRequired,
    userAttributes: PropTypes.element,
  }

  constructor(props) {
    super(props)

    this.state = {
      avatarImgUrl: '',
      avatarImgAlt: 'Google Account',
      profileEmail: '',
      profileName: '',
      gSigninDisplay: 'block',
      openPopover: false,
      showDeleteDialog: false,
      dialogValue: '',
    }

    this.anchorEl = null
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.signedIn) {
      const profile = googleApi.getCurrentUser().getBasicProfile()
      this.setState({
        avatarImgUrl: profile.getImageUrl(),
        avatarImgAlt: 'Google',
        profileEmail: profile.getEmail(),
        profileName: profile.getName(),
        gSigninDisplay: 'none',
      })
    } else if (this.props.signedIn) {
      // User logged off
      this.setState({
        gSigninDisplay: 'block',
      })
    }
  }

  componentDidUpdate = () => {
    if (!this.props.signedIn && this.props.gLoaded) {
      googleApi.renderSigninButton(SIGNIN_DIV_ID)
    }
  }

  handleLineChange = (e) => {
    let line = e.target.value
    this.setState({
      dialogValue: line,
      deletingFailed: false,
    })
  }

  doDeleteAccount = async () => {
    console.log(cblFirebase.userDoc)
    if (cblFirebase.userDoc) {
      // if (cblFirebase.userDoc.data().lastUsedActivationCode) await licensor.releaseCode(cblFirebase.userDoc.data().lastUsedActivationCode)
      await UserProgress.deleteAllProgress()
      await cblFirebase.userDoc.ref.delete()
    }
    firebase.auth().signOut()
    googleApi.signOut()
  }

  getProfile = () => (
    this.props.signedIn ?
      <div
        style={{
          fontSize: '11px',
          fontFamily: 'Arial, sans-serif',
          marginTop: '8px',
          height: '1.1em',
          cursor: 'pointer',
        }}
        id='profile-email'
        onClick={() => { this.setState({ openPopover: true }) }}
        ref={(node) => { this.anchorEl = node }}
        role="none"
        onKeyPress={() => { this.setState({ openPopover: true }) }}
      >
        {this.state.profileEmail}
        <span
          style={{
            width: 0,
            height: 0,
            borderLeft: '4px dashed transparent',
            borderRight: '4px dashed transparent',
            borderTop: '4px solid black',
            color: 'black',
            display: 'inline-block',
            marginLeft: '6px',
            verticalAlign: 'middle',
          }}
        />
      </div>
      :
      null
  )

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '15px',
          flex: '0 0 auto',
        }}
      >
        {this.props.gLoaded ? <div id={SIGNIN_DIV_ID} style={{ display: this.state.gSigninDisplay, zIndex: 100 }} /> : null}
        {this.getProfile()}
        {this.props.userAttributes}
        <Popover
          open={this.state.openPopover}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => {
            this.setState({ openPopover: false })
          }}
        >
          <div
            style={{
              minWidth: '10em',
              display: 'flex',
              margin: '0.5em',
            }}
          >
            <div style={{ margin: '0 auto' }}>
              <div style={{ display: 'flex' }}>
                <Avatar
                  alt={this.state.avatarImgAlt}
                  src={this.state.avatarImgUrl}
                />
                <div>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: 'Arial',
                      fontSize: 13,
                    }}
                  >
                    {this.state.profileName}
                  </Typography>
                  <Typography>{this.state.profileEmail}</Typography>
                </div>
              </div>
              <div
                className={this.props.classes.button}
                id='sign-out-button'
                onClick={() => {
                  firebase.auth().signOut()
                  googleApi.signOut()
                  this.setState({ openPopover: false })
                }}
                role="menuitem"
                onKeyPress={() => {
                  firebase.auth().signOut()
                  googleApi.signOut()
                  this.setState({ openPopover: false })
                }}
                tabIndex={0}
              >
                Sign out
              </div>
              <div
                className={this.props.classes.button}
                id='delete-account-button'
                onClick={() => {
                  this.setState({
                    openPopover: false,
                    showDeleteDialog: true,
                  })
                }}
                role="menuitem"
              >
                Delete Account
              </div>
            </div>
          </div>
        </Popover>
        <Dialog
          id='delete-account-dialog'
          open={this.state.showDeleteDialog}
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting your account will permanently erase all data Firia Labs stores about you, including your email address and experience points.
              If you wish to delete your account, type 'YES' in all capital letters on the line below.
              <br />
              <b><em>Note:</em> After deletion you must sign-out of Google account and CLOSE or RELOAD browser page to complete process.</b>
            </DialogContentText>
            <TextField
              fullWidth
              id='delete-account-input'
              label="Delete Account?"
              style={{ marginTop: '1em' }}
              error={this.state.deletingFailed}
              helperText={this.state.deletingFailed ? 'Failed to delete.' : null}
              disabled={this.state.isDeleting}
              value={this.state.dialogValue}
              onChange={this.handleLineChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              id='add-license-validate'
              onClick={this.doDeleteAccount}
              color="primary"
              disabled={this.state.isDeleting}
              variant='raised'
              style={{
                transition: 'all 0.1s linear',
                transform: this.state.dialogValue === 'YES' ? 'scaleY(1)' : 'scaleY(0)',
                // height: this.state.activationDialogValue ? '36px' : '0px',
              }}
            >
              {this.state.isDeleting ? (
                <div style={{ display: 'flex', height: '1.4em' }}>
                  <CircularProgress
                    style={{
                      margin: 'auto',
                      width: 20,
                      height: 20,
                      color: 'red',
                    }}
                  />
                </div>
              ) : 'DELETE'}
            </Button>
            <Button
              onClick={() => {this.setState({showDeleteDialog: false})}}
              disabled={this.state.isDeleting}
              variant='raised'
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}


export default withStyles(styles)(UserSession)
