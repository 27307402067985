import React, { Component, Fragment } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { googleApi } from './Gapi'
import App from './App'
import SignInPage from './SignIn'
import { cblFirebase } from './myfirebase'
import Loading from './Loading'
import Dashboard from './dashboard/Dashboard'
import LicensePortal from './license-portal/LicensePortal'
import GooglePreviewHelper from './GooglePreviewHelper'
import { moduleEnums, moduleProjects, moduleDescription } from './lessons/CurriculumModules'
import LoadingErrorPage from './LoadingErrorPage'
import { tracker } from './tracking'

export default class AuthRouter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gLoaded: false,
      signedIn: googleApi.gapiAuthIsSignedIn,
      activated: false,
      userDocLoaded: false,
      errorPage: null,
    }

    googleApi.gapiLoadedPromise.then(() => {
      this.setState({ gLoaded: true })
    }).catch(err => {
      this.setState({errorPage: 
        (
          <LoadingErrorPage 
            errors = {[{name:'GAPI error', details:JSON.stringify(err)}]}
          />
        )      
      })
      tracker.addBreadcrumb('GAPI error', 'dev', 'debug', err)
    })

    googleApi.notifyOnAuthChange((isSignedIn) => {
      this.setState({ signedIn: isSignedIn })
    })

    cblFirebase.signedInPromise.then(() => {
    }).catch((err) => {
      this.setState({errorPage: 
        (
          <LoadingErrorPage 
            errors = {[{name:'Firebase auth error', details:JSON.stringify(err)}]}
          />
        )      
      })
      tracker.addBreadcrumb('Firebase auth error', 'dev', 'debug', err)
    })

    cblFirebase.dbConnectPromise.then(() => {
    }).catch((err) => {
      this.setState({errorPage: 
        (
          <LoadingErrorPage 
            errors = {[{name:'Firebase DB error', details:JSON.stringify(err)}]}
          />
        )      
      })
      tracker.addBreadcrumb('Firebase DB error', 'dev', 'debug', err)
    })

    if (cblFirebase.userDoc) {
      this.setState({
        userDocLoaded: true,
      })
    } else {
      cblFirebase.registerDocRetrievedCallback((doc) => {
        this.setState({ userDocLoaded: true })
      })
    }
  }

  renderLogin = () => {
    if (this.state.errorPage) {
      return this.state.errorPage
    } else if (!this.state.gLoaded) {
      return <Loading />
    }

    return !this.state.signedIn ? <SignInPage /> : <Redirect to="/" />
  }

  checkSignedIn = (routerProps) => {
    if (this.state.errorPage) {
      return this.state.errorPage
    } else if (!this.state.gLoaded) {
      return <Loading />
    }

    if (this.state.signedIn) {
      switch (routerProps.match.path) {
        case '/dashboard':
          return <Dashboard {...routerProps} />
        case '/license-portal':
          return <LicensePortal {...routerProps} />
        default:
          return <App {...routerProps} />
      }
    }
    return <Redirect to="/login" />
  }

  loadGDrive = (routerProps) => {
    if (this.state.errorPage) {
      return this.state.errorPage
    } else if (!this.state.gLoaded) {
      return <Loading />
    }

    if (this.state.signedIn) {
      return <App gDriveIds={routerProps.match.params.ids} />
    }
    return <Redirect to="/login" />
  }

  preview = (routerProps) => {
    const moduleName = moduleEnums[routerProps.match.params.moduleId]

    if ((routerProps.match.params.moduleId in moduleProjects)  && moduleName) {
      if (routerProps.match.params.projectId <= moduleProjects[routerProps.match.params.moduleId].length) {
        return window.navigator.userAgent.toLocaleLowerCase().includes('google web preview') || window.navigator.userAgent.toLocaleLowerCase().includes('googlebot') ? <GooglePreviewHelper {...routerProps} /> : this.checkSignedIn(routerProps)
      }
    }

    return <Redirect to="/" />
  }

  inIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) { // in the case of a cross origin request window.top cannot be accessed
      return true
    }
  }

  combineModules = (retObj) => {
    const r = retObj
    // TODO: could look at the efficiency of this "deep copy"
    r.data = JSON.parse(JSON.stringify(moduleDescription))
    Object.keys(moduleDescription).forEach((prjId) => {
      r.data[prjId].enum = prjId in moduleEnums ? moduleEnums[prjId] : ''
      r.data[prjId].projects = prjId in moduleProjects ? moduleProjects[prjId] : []
    })

    // Extract stepId strings while converting to JSON (project steps would otherwise be 'null')
    return JSON.stringify(r, (key, value) => (typeof value === 'function' ? value.stepId : value))
  }

  parseApiCommand = (match, location) => {
    let jsonString = '{"error" : "Unhandled API request."}'

    const retObj = { service: 'CodeSpace', method: match.params.command }

    switch (match.params.command) {
      case 'modules':
        jsonString = this.combineModules(retObj)
        break
      default:
        jsonString = '{"error":"Unknown API function."}'
        break
    }

    if (this.inIframe()) {
      // If we're in an iFrame, post result to parent.
      window.parent.postMessage(jsonString, '*')
    }

    return jsonString
  }

  apiV1 = ({match, location}) => {
    //document.write(this.parseApiCommand(match, location))
    return (
      <Fragment>
        {this.parseApiCommand(match, location)}
      </Fragment>
    )
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/login"
            render={this.renderLogin}
          />
          <Route
            path="/module/:moduleId/project/:projectId"
            render={this.preview}
          />
          <Route
            path="/gdrive/:gDriveIds"
            render={this.checkSignedIn}
          />
          <Route
            exact
            path="/"
            render={this.checkSignedIn}
          />
          <Route
            path="/api/v1/:command"
            render={this.apiV1}
          />
          <Route
            path="/dashboard"
            render={this.checkSignedIn}
          />
          <Route
            path="/license-portal"
            render={this.checkSignedIn}
          />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    )
  }
}
