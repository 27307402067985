// Project: Graphics

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageConcept,
  //LegendMessageInteract,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import heroImg from './assets/Graphics.gif'
import questions from './assets/Questions.gif'
import xImg from './assets/ImageX.gif'
import termites from './assets/Termites.svg'
import bounce from './assets/Bounce.svg'
import gateRunnerConcept from './assets/GateRunnerConcept.jpg'
import holewall from './assets/HoleWall.svg'
import ledMatrix from './assets/LedMatrix.gif'
import cyberTall from './assets/CyberTall.jpg'
import cyberFinish from './assets/CyberFinish.jpg'
import Quiz from '../Quiz'


export const GraphicsImg = cyberTall

class GraphicsIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Project: Graphics {lesson-title}
___
`}
<img src={heroImg} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
### Ready to make *custom graphic images*??
By now you may have tried all the ==built-in Images== the **micro:bit** has to offer.

*There are many... but you yearn for more!* {centered}

Can you create your own **Custom Images**?<br /> Oh yes! And *this* project will show you several ways to make the *display* do your bidding!

#### Project Goals:
* Create a custom \`Image\` icon.
* Fill the screen with pixels, and fade them in and out.
* Watch the screen get *eaten by **termites***!
* Experiment with the low-level \`display.set_pixel()\` function.
* Solve a *mystery plot* created with pixels.
* Code a **bouncing pixel** animation.
* Create an exciting pixel-based *rage game!*
`}
      </Markdown>
      <StepComplete
        prompt="Ready to get started?"
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
    )
  }
}

class CustomImage extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Custom Images {lesson-title}
___
`}
<img src={xImg} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
#### Hack the Display with your own Custom Images!
The micro:bit ==built-in images== collection contains about 65 different shapes for display on the LED matrix.
> That's a good start, but there are **many** images left for you to discover and create!

**How Many?**
Well, there are 5x5 = **25** LEDs on the display.
Each LED has **2** basic states, ON and OFF (not counting dimming-levels).
So the total number of ==binary== combinations is:
> 2<sup>25</sup> = 33,554,432 possible images!!

*The image you're looking for is out there...*

To learn how to make a custom \`Image\`, click here: ==Images==
* Making your own image is as simple as passing a string to the \`Image\` constructor.
* The string has a digit for each LED, numbered 0-9
  * 0=OFF ... 9=FULL BRIGHTNESS
`}
<LegendMessageKeyboard>
{`Do you see the micro:bit picture above? It has the \`big_x\` Image string overlayed onto the LED display.

Create a new file and name it **CustomImages**.

Type in the code below, to test out this example of drawing a **big X** on the display.
\`\`\`
from microbit import *
my_image = Image("90009:"
                 "09090:"
                 "00900:"
                 "09090:"
                 "90009")
display.show(my_image)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Run the code and watch the display. This code mostly just prepares the image data - there really isn't
much work for the micro:bit to do!
`}
</LegendMessageRun>

<LegendMessageTry>
{`*Hmmm. There is no **CIRCLE** in the built-in Image set...*
* Now that you can craft your own Images, it's time you fixed that!
\`\`\`
from microbit import *
$CIRCLE = Image("09990:"
$               "90009:"
$               "90009:"
$               "90009:"
$               "09990")
$display.show(CIRCLE)
\`\`\`

*Play around with some other shapes!*
* Try other brightness levels too...
`}
</LegendMessageTry>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why are the \`Image\` numbers always 5 digits long?
`}
  </Markdown>
  )}
  id={"LED string repr"}
  xp={5}
  answerRight={"The 5 digits correspond to brightness levels of 5 LEDs in a row of the display."}
  answerWrong={["An integer can be up to 5 digits long.", "Each LED supports a 5 digit brightness level."]}
/>
<StepComplete
  prompt="Are you making your own Images?"
  xp={25}
  successMessage=
{`## Spiffy DIY Icons! {centered}
### Customization is one of the awesome things about coding.
With computer software, there is **amazing** potential for creating new and interesting things!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'Image']}
  reqFuncdefs={[]}
  reqStatements={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class FillScreen extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Filling the Screen {lesson-title}
___
### ...and more fun tricks with \`Image\` objects

There's a quick way to create an image with every LED set to a specified brightness level.
\`\`\`
my_image = Image()  # Create an empty image
my_image.fill(9)    # Fill it with level=9 (bright!)
\`\`\`
`}

<LegendMessageKeyboard>
{`**Give it a try!** - *Modify* your code to fill every pixel!
\`\`\`
from microbit import *

# Fill the display
my_image = Image()
my_image.fill(9)
display.show(my_image)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Let's see you light up the screen!
`}
</LegendMessageRun>
<StepComplete
  prompt="Are all your LEDs shining brightly?"
  xp={25}
  successMessage=
{`## Brilliant! {centered}
### Oh so efficient...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'Image']}
  reqFuncdefs={[]}
  reqStatements={[]}
  reqNames={['fill']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Termites extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Termites! {lesson-title}
___
`}
<img src={termites} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
#### Old school organic fade-out code :-)
You will get to *fading* the LEDs using brightness levels in the next lesson. But let's have some fun first!
> *Why just fade-out the display, when you can watch it disintegrate before your eyes?*

You will be using the \`display.set_pixel(x, y, level)\` function.
* See ==display== for more on that.
* Now you're playing with ==Pixels==!
`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`Add a loop after you display the \`fill(9)\` solid image.
* Pick a ==random== x,y pixel from 0-4
* Devour it! (set it to 0 brightness)
\`\`\`
from microbit import *
$import random

$# Fill the display
my_image = Image()
my_image.fill(9)
display.show(my_image)

$while True:
$    x = random.randrange(5)
$    y = random.randrange(5)
$    display.set_pixel(x, y, 0)
$    sleep(100)
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<LegendMessageRun>
{`Get ready for random destruction!!!
`}
</LegendMessageRun>
{`This is a nice program, but sometimes it takes *forever* to randomly clear those last few pixels!
> ***Can you make it run faster?***
`}
<LegendMessageConcept title="Concept: Optimization">
{`Notice how the current **algorithm** you are using *always* does \`sleep(100)\` even if the randomly chosen pixel-location is already OFF?
What if you could check and only *sleep()* **if** the pixel was ON?
* **Hint:** Look for \`display.get_pixel()\` in the ==display== documentation.

Go ahead - try it!
`}
</LegendMessageConcept>
<StepComplete
  prompt="Have the termites eaten your display?"
  xp={25}
  successMessage=
{`## Yum! {centered}
### I could watch this for hours...
But I thought they'd never eat those last two pixels!
`}
  reqImports={['microbit', 'random']}
  reqCalls={['display.set_pixel', 'Image', 'display.show', 'sleep', 'random.randrange']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  reqNames={['fill']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class FadeScreen extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Fading In and Out {lesson-title}
___
### Playing with Image brightness levels

Bright LEDs are nice, but it's good to mix it up a little with some dimmer settings!
* \`fill(level)\` can set any brightness level you want.
* Can you make the display fade in and out?
`}

<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

$level = 9    # Start at MAX brightness level
$dir = -1     # Count downwards to zero

$while True:
$    level = level + dir
$    if level == 0 or level == 9:
$        # Change direction
$        dir = -dir

$    # Fill my_image with current 'level', and display it
    my_image = Image()
    my_image.fill(level)
    display.show(my_image)
$    sleep(100)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Ready to kick off the fade-in / fade-out cycle!
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`How is the variable \`level\` used in the line of code \`my_image.fill(level)\`?
`}
  </Markdown>
  )}
  id={"Image.fill param"}
  xp={5}
  answerRight={"It sets the new brightness level for all the pixels in the Image."}
  answerWrong={["It reverses the increasing/decreasing direction of brightness change.",
                "It breaks out of the while loop."]}
/>
<StepComplete
  prompt="Got a pulsating matrix of variable luminance?"
  xp={25}
  successMessage=
{`## Like an LED roller-coaster! {centered}
### Perhaps it *is* better to fade away...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'Image', 'sleep']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  reqNames={['fill']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class MysteryPlot extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Mystery Plot {lesson-title}
___
`}
<img src={questions} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
#### Ready for a challenge? Run some code to solve a *mystery*.
When you use \`display.set_pixel()\` you are *drawing with code*. That's *very* powerful, because with a little bit of code
you can create precise, complex, and elaborate designs which might be difficult or impossible to do manually.
> The code below draws a simple shape on the display.
#### Try reading through the code slowly, one line at a time:
* Keep track of \`x\` and \`y\` values
* The *outer loop* takes \`y\` from **0 to 4**
* The *inner loop* takes \`x\` from **0 to y**

Can you draw the shape on paper?
`}
<LegendMessageKeyboard>
{`
After you read through the code, **guess the mystery shape**!

You'll have to *type the code in* to confirm your guess.
\`\`\`
# Mystery Plot
from microbit import *
y = 0
while y < 5:
    x = 0
    while x <= y:
        display.set_pixel(x, y, 9)
        x = x + 1
    y = y + 1
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
There's only one way to solve the mystery for sure - **Run the Code!**
`}
</LegendMessageRun>
{`#### Want an extra challenge?
* What would happen if you started \`x\` at 4, and counted it down to *zero*?
`}
<LegendMessageDebug>
{`
This is a great time to **step** through the code. **Watch** the *variables* pane, and observe the values of \`x\`
and \`y\` as the program runs.
`}
</LegendMessageDebug>
<StepComplete
  prompt="Have you solved the mystery?"
  xp={25}
  successMessage=
{`## Excellent Graphing! {centered}
### Triangles are fundamental to computer graphics.
Modern Graphics Processing Units (GPUs) powering PCs and video game consoles can draw billions of triangles per second!
`}
  reqImports={['microbit',]}
  reqCalls={['display.set_pixel']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class BouncingBall extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Bouncing Ball  {lesson-title}
___
#### *Primal* Pixel Animation - bouncing off the walls!
Can you simulate a ball bouncing back and forth between two walls?
* If the ball is travelling to the **right**, then each step is \`x + 1\`.
* If it's going **left**, then each step is \`x - 1\`.

#### To keep bouncing, you'll need a *loop*:
* Display a *pixel* (the **ball**)
* If it hits a wall (x=0 or x=4) then *reverse direction*.
* Change the \`x\` position based on direction (+1 or -1)
* Repeat...
`}
<LegendMessageWarning>
{`The code below is *close*, but it's gonna need a little work. Give it a try, and see if you can fix it when you
notice a problem. (There's more help below.)
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`Create a new file and name it **Bounce**.
\`\`\`
from microbit import *
x = 0
y = 0
dir = 1  # Starting direction is to the right

while True:
    x = x + dir
    if x == 0 or x == 4:
        # Hit a wall, so reverse direction
        dir = -dir

    display.set_pixel(x, y, 9)
    sleep(100)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Give it a go!
`}
</LegendMessageRun>
<LegendMessageKeyboard title="Fix the Code">
{`As you've probably noticed, the pixel-ball is leaving a trail. The solution is to *erase* the previous
position each time you move it.
> This is a very common occurrence in computer graphics!
\`\`\`
from microbit import *
x = 0
y = 0
dir = 1  # Starting direction is to the right

while True:
    x = x + dir
    if x == 0 or x == 4:
        # Hit a wall, so reverse direction
        dir = -dir

    display.set_pixel(x, y, 9)
    sleep(100)
$$$
$    display.set_pixel(x, y, 0)
$$$

\`\`\`

### Try another *Run*...
`}
</LegendMessageKeyboard>
<StepComplete
  prompt="Is your ball bouncing happily?"
  xp={25}
  successMessage=
{`## Fantastic and Elastic! {centered}
### ...and *friction free* also.
Adding friction is an exercise for the reader ;-)
* Unless you're in space, coding a *Cylon* maybe.
`}
  reqImports={['microbit',]}
  reqCalls={['display.set_pixel', 'sleep']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Bounce2D extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Mega-Bounce in 2D!! {lesson-title}
___
`}
<img src={bounce} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:10, marginRight:5, marginBottom:10,
    }}
/>
{`
#### Activate your Y-axis, and bounce off 4 walls

This is a direct extension of the last lesson:
* Keep track of \`dir_x\` and \`dir_y\` independently.
* Update both \`x\` and \`y\` each time around loop.
* Check for collision with **all 4** walls.
`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`This code should look very familiar. Now you are controlling both \`x\` and \`y\`!
\`\`\`
from microbit import *

# Starting position (play with these!)
x = 0
y = 2

dir_x = 1  # Travel to the right
dir_y = 1  # Travel downward

while True:
    x = x + dir_x
    y = y + dir_y
    if x == 4 or x == 0:
        dir_x = -dir_x
    if y == 4 or y == 0:
        dir_y = -dir_y

    display.set_pixel(x, y, 9)
    sleep(100)
    display.set_pixel(x, y, 0)
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<LegendMessageRun>
{`Run the code as written above first...
* Try changing the starting postion:
  * How about \`y = 0\`?
  * And try \`y = 1\`...
* Can you change the starting direction to make the ball go *clockwise*?
`}
</LegendMessageRun>
<StepComplete
  prompt="Are you bouncing off the ceiling yet?"
  xp={25}
  successMessage=
{`## Welcome to the 2nd Dimension! {centered}
### It's amazing what you can do with a single *pixel*.
`}
  reqImports={['microbit',]}
  reqCalls={['display.set_pixel', 'sleep']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class PixelGames extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Make a Fast-Action Pixel Game {lesson-title}
___
#### Maybe even an addictive, rage-inducing one?
`}
<img src={gateRunnerConcept} alt=""
    style={{
      width: '50%', margin: 'auto', display:'block', float: 'right', marginLeft:10, marginRight:5, marginBottom:10,
    }}
/>
{`
#### Your Mission:
You have been hired by a famous game design studio to create a game called **Gate Runner**.
* They gave you a "Concept Sketch", shown here.
* It's a *vertical scroller* with the player just moving *left* and *right*.
* The player wins points by running/flying through gates (1 point each).
* Walls appear faster and faster, with gates randomly placed in them.
* Game ends when player collides with a wall.

#### First Step - Falling Walls
* In a *vertically scrolling* game, the sense of motion comes from objects moving toward the player, usually **down** the screen.
* First step is to create **walls** that appear at the top of screen and move down!

The code below starts by making a **wall** image, with just the top row of pixels ON.
* The *wall* is the only thing on the **field** for now...

Each time through the loop, it *shifts* the whole field down.

Check out the \`shift_down(n)\` function from the ==Image== library!
* It makes a new Image, with all pixels moved *down* by \`n\` rows. That's *exactly* what we need!
`}
<LegendMessageKeyboard>
{`Create a new file and name it **GateRunner**.

Notice that the \`Image\` string is written on a single line below. You can choose to code it this way, or split
across multiple lines to make it look more like the *display* as we did in prior Lessons.
\`\`\`
from microbit import *

wall = Image("55555:00000:00000:00000:00000")
field = wall

while True:
    field = field.shift_down(1)
    sleep(500)
    display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`The wall doesn't come back around, so make sure you **watch closely** when you run this code.
`}
</LegendMessageRun>
<StepComplete
  prompt="Got a falling wall?"
  xp={25}
  successMessage=
{`## Off to a good start! {centered}
### One simple piece at a time.
Even the most complex programs are created this way. Breaking the problem down into small, understandable chunks
makes all the difference!
> ==Divide and Conquer== !!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'sleep', 'field.shift_down', 'Image']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class GateRunner1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Gate Runner - Walls in Motion {lesson-title}
___
#### Next step in the game is a *moving* playing field!

It's time to add more walls, and give a real sense of **motion** to your *vertical scroller*.
* The "wall interval" should be **3**. That means adding a wall every 3rd row of the screen.

The code below shows a new feature of ==Image== objects: **addition**
* Adding two images *sums the levels for each pixel*.

Starting with an empty **field** (all 0's), a wall is *added* when "wall interval" \`i_wall == 3\`.
`}
<LegendMessageKeyboard>
{`Modify your code as follows:
* Start \`field\` with an empty \`Image\`.
* Add code to count \`i_wall\`, the wall-interval.
* Every 3rd row, *add* a new wall!
\`\`\`
from microbit import *

wall = Image("55555:00000:00000:00000:00000")
$field = Image()
$i_wall = 0

while True:
    field = field.shift_down(1)

$    i_wall = i_wall + 1
$    if i_wall == 3:
$        i_wall = 0
$        field = field + wall

    sleep(500)
    display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`This time the walls just keep coming!
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What is the purpose of the variable \`i_wall\`?
`}
  </Markdown>
  )}
  id={"Counter variable purpose"}
  xp={5}
  answerRight={"To count up to 3, so a new wall can be added every third row."}
  answerWrong={["To count the number of walls that have been displayed.",
                "To check when the current wall should be destroyed."]}
/>
<StepComplete
  prompt="Are you feeling the motion?"
  xp={25}
  successMessage=
{`## Sweet Scrolling! {centered}
### Top-down scrollers are a classic game style.
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'Image', 'field.shift_down', 'sleep']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class GateRunner2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Gate Runner - Make Some Gates! {lesson-title}
___
`}
<img src={holewall} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:10, marginRight:5, marginBottom:10,
    }}
/>
{`
#### Punch some random holes in those walls
The game is called **Gate Runner** - so it's time to add some **gates**!
* The walls are created at the top row, \`y = 0\`.
* Your **gate** will be a 1-pixel wide hole at a random \` x \` location.
`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`You'll need to ==import== the **random** module.
* Make the hole just after the code that adds the \`wall\`
* \`random.randrange(5)\` gives an **x** value from 0-4.
\`\`\`
from microbit import *
$import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0

while True:
    field = field.shift_down(1)

    i_wall = i_wall + 1
    if i_wall == 3:
        i_wall = 0
        field = field + wall
$        gate = random.randrange(5)
$        field.set_pixel(gate, 0, 0)

    sleep(500)
    display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<LegendMessageRun>
{`Get ready to fly through those gates!
`}
</LegendMessageRun>
<LegendMessageConcept title="Concept: Random Seeding">
{`Currently the gates are *different* each time the program runs. That keeps it challenging!
> *But sometimes you may want repeatability...*

Want a *repeatable pattern* for your game, so players can try to memorize some of the course?
* Add a call to \`random.seed(2857740885)\` near the top of your code, just after \`import random\`.
You can experiment with different **seed** values.

Take a look at the ==random== module for more details on how the micro:bit's *pseudo random number generator* works!
`}
</LegendMessageConcept>
<StepComplete
  prompt="Do your walls have random gates?"
  xp={25}
  successMessage=
{`## Getting Closer to a Game! {centered}
### A little randomness always spices things up nicely.
`}
  reqImports={['microbit', 'random']}
  reqCalls={['random.randrange', 'Image', 'field.shift_down', 'sleep', 'field.set_pixel', 'display.show']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class GateRunner3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const errorRegex = /ValueError:/
    const dialogContent = (
      <Markdown>
{`**Don't Panic!** This is a bug, but you can fix it...

You need to keep the player's \`x\` value within the range 0 to 4.

> The next step in the Lesson will show you how to do that!
`}
      </Markdown>
    )
    this.props.interceptErrorCb([errorRegex, dialogContent, "Player moved off-screen"])
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Gate Runner - Player Controls {lesson-title}
___
`}
<img src={ledMatrix} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
#### Add a *player* to your game!
The player in **Gate Runner** is a single bright pixel, moving left/right near the bottom of screen, at \`y = 3\`.

`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`Add player control and display, at the beginning of \`while\` loop.
* Be sure to initialize \`player_x\` and \`player_y\` above your \`while\` loop!
\`\`\`
from microbit import *
import random

$$$
wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0
$player_x = 2
$player_y = 3

while True:
$    # Control player
$    if button_a.was_pressed():
$        player_x = player_x - 1
$    if button_b.was_pressed():
$        player_x = player_x + 1

$    display.set_pixel(player_x, player_y, 9)

    # Scroll the field
    field = field.shift_down(1)

    # Update and check wall interval
    i_wall = i_wall + 1
    if i_wall == 3:
        i_wall = 0
$$$
        field = field + wall
        gate = random.randrange(5)
        field.set_pixel(gate, 0, 0)

    sleep(500)
    display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<LegendMessageRun>
{`Test out your player controls. Is everything running as it should?
`}
</LegendMessageRun>
{`#### Did you find a bug?
There are a couple of issues with the code above:
1. Need to prevent \`player_x\` going past the edges (0 and 4).
2. The player is **slow** to respond to buttons.

Fixing (1) above, to keep the player in-bounds is easy:
`}
<LegendMessageKeyboard>
{`Only move the player if a button was pressed **and** player is not at an edge.
\`\`\`
from microbit import *
import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0
player_x = 2
player_y = 3

while True:
$$$
$    # Control player
$    if button_a.was_pressed() and player_x > 0:
        player_x = player_x - 1
$    if button_b.was_pressed() and player_x < 4:
        player_x = player_x + 1
$$$

    display.set_pixel(player_x, player_y, 9)

    # Scroll the field
    field = field.shift_down(1)

    # Update and check wall interval
    i_wall = i_wall + 1
    if i_wall == 3:
        i_wall = 0
        field = field + wall
        gate = random.randrange(5)
        field.set_pixel(gate, 0, 0)

    sleep(500)
    display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Test the program again. Can you move the player past the edges?

*You will fix the **slow movement** problem in the next step...*
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What is the purpose of the code \`player_x = player_x - 1\`?
`}
  </Markdown>
  )}
  id={"Increment and decrement variable"}
  xp={5}
  answerRight={"To move the player 1 pixel to the left."}
  answerWrong={["To move the player 1 pixel to the right.",
                "To subtract points from player 1."]}
/>
<StepComplete
  prompt="Got a moving player?"
  xp={25}
  successMessage=
{`## This is quite moving {centered}
### Just not quite *fast* enough!
`}
  reqImports={['microbit', 'random']}
  reqCalls={[
    'display.set_pixel', 'random.randrange', 'Image', 'field.shift_down', 'sleep', 'field.set_pixel',
    'button_a.was_pressed', 'button_b.was_pressed', 'display.show'
  ]}
  reqFuncdefs={[]}
  reqStatements={['while', 'if', 'and']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class GateRunner4 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Gate Runner - Fast Timing {lesson-title}
___
#### Stop *sleeping* and check those buttons!

The *player controls* work, but they don't respond fast enough for an action game.
> Can you make the player move **instantly** when buttons are pressed?

The \`sleep(500)\` is what's slowing you down:
* The display is not updated until *after* the \`sleep\`.
* That's too long to wait before updating player position!

#### Change your loop to use \`running_time()\` for timing, rather than \`sleep(n)\`
* \`running_time()\` returns the number of milliseconds since starting.
* Decide when to **scroll** the field based on the *time*.
* Keep updating and displaying player position **every** loop.
  * Without sleep, the loop runs **fast**!
`}
<LegendMessageKeyboard>
{`Update your code as shown below.
* Be careful with **indentation**!
* Make sure to *remove* the \`sleep(500)\` at end of code.

Since the code is growing, more \`# comments \` have been added.
> Remember, typing the ==comments== is *optional.*
\`\`\`
from microbit import *
import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0

# Player pixel position
player_x = 2
player_y = 3

$$$
$# Control how often we scroll down
$scrollMs = 500
$nextScroll = running_time() + scrollMs

# Game Loop
while True:
    # Control player
    if button_a.was_pressed() and player_x > 0:
        player_x = player_x - 1
    if button_b.was_pressed() and player_x < 4:
        player_x = player_x + 1

    display.set_pixel(player_x, player_y, 9)

$    # Is it time to scroll? (INDENT FROM HERE DOWN!)
$    if running_time() > nextScroll:
$        # Scroll the field
        field = field.shift_down(1)
$        nextScroll = nextScroll + scrollMs

$        # Update and check wall interval
        i_wall = i_wall + 1
        if i_wall == 3:
            i_wall = 0
$$$
            # Add new wall
            field = field + wall

            # Punch hole for gate
            gate = random.randrange(5)
            field.set_pixel(gate, 0, 0)

$        # REMOVED SLEEP!
        display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Try controlling the player now. Much quicker, right?

You may have noticed that the player leaves a short *trail*.
> Can you guess why? How is it being erased?
`}
</LegendMessageRun>
{`#### Oh, and one more thing...
This **IS** supposed to be a **rage** game, so you'll want to make it *speed up* during play!
* Since the *scroll speed* is controlled by \`scrollMs\`, you just need to decrease that value.
* Try subtracting 5ms from it with every new gate!

**But** if you keep decreasing \`scrollMs\` the game will become truly *impossible*!
> You want *frustrating*, not impossible...
`}
<LegendMessageKeyboard>
{`To limit the speed, use an \`if\` statement like so:
\`\`\`
from microbit import *
import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0

# Player pixel position
player_x = 2
player_y = 3

# Control how often we scroll down
scrollMs = 500
nextScroll = running_time() + scrollMs

# Game Loop
while True:
    # Control player
    if button_a.was_pressed() and player_x > 0:
        player_x = player_x - 1
    if button_b.was_pressed() and player_x < 4:
        player_x = player_x + 1

    display.set_pixel(player_x, player_y, 9)

    # Is it time to scroll? (INDENT FROM HERE DOWN!)
    if running_time() > nextScroll:
        # Scroll the field
        field = field.shift_down(1)
        nextScroll = nextScroll + scrollMs

        # Update and check wall interval
        i_wall = i_wall + 1
        if i_wall == 3:
            i_wall = 0
            # Add new wall
            field = field + wall

$$$
$            # Punch hole for gate
            gate = random.randrange(5)
            field.set_pixel(gate, 0, 0)

$            # Increase scroll speed (RAGE!)
$            if scrollMs > 200:
$                scrollMs = scrollMs - 5

$        display.show(field)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<StepComplete
  prompt="Is your player moving speedily?"
  xp={25}
  successMessage=
{`## Those ARE some fast moves! {centered}
### Now you just have to make the walls hurt...
`}
  reqImports={['microbit', 'random']}
  reqCalls={[
    'display.set_pixel', 'random.randrange', 'Image', 'field.shift_down', 'field.set_pixel',
    'button_a.was_pressed', 'button_b.was_pressed', 'display.show', 'running_time'
  ]}
  reqFuncdefs={[]}
  reqStatements={['while', 'if', 'and']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class GateRunner5 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Gate Runner - Score! {lesson-title}
___
`}
<img src={cyberFinish} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:10, marginRight:5, marginBottom:10,
    }}
/>
{`
#### Risks and Rewards
Your player runs right through the walls!
* Where's the fun in that?
* Time to make the game challenging...
* **And** keep track of score!

The game ends when the player hits a wall pixel.
> *Can you check for a pixel on the field at the player's position?*

**Yes!** \`Image\` objects have a \`get_pixel(x,y)\` function that returns the level at the given location.
* Use that function to check the **field** before moving the player.
* If there's a pixel set at \`(player_x, player_y)\`, you have a *collision.*
  * Break out of the *Game Loop* to end the game if that happens!
`}
<div style={{clear:'right'}}>
<LegendMessageKeyboard>
{`Add a collision check at the beginning of your Game Loop.
\`\`\`
from microbit import *
import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0

# Player pixel position
player_x = 2
player_y = 3

# Control how often we scroll down
scrollMs = 500
nextScroll = running_time() + scrollMs

$$$
$# Game Loop
while True:
$    # Check for player collision with wall
$    if field.get_pixel(player_x, player_y):
$        break

$    # Control player
    if button_a.was_pressed() and player_x > 0:
$$$
        player_x = player_x - 1
    if button_b.was_pressed() and player_x < 4:
        player_x = player_x + 1

    display.set_pixel(player_x, player_y, 9)

    # Is it time to scroll? (INDENT FROM HERE DOWN!)
    if running_time() > nextScroll:
        # Scroll the field
        field = field.shift_down(1)
        nextScroll = nextScroll + scrollMs

        # Update and check wall interval
        i_wall = i_wall + 1
        if i_wall == 3:
            i_wall = 0
            # Add new wall
            field = field + wall

            # Punch hole for gate
            gate = random.randrange(5)
            field.set_pixel(gate, 0, 0)

            # Increase scroll speed (RAGE!)
            if scrollMs > 200:
                scrollMs = scrollMs - 5

        display.show(field)
\`\`\`
`}
</LegendMessageKeyboard>
</div>
<LegendMessageRun>
{`Try playing your game now. Hey! Now it's a GAME!
`}
</LegendMessageRun>
{`#### What's your *SCORE?*
Oh yeah, you *have* to keep score!
* Score one point for each gate.
* And display it at the end of the game, **outside the loop**.
`}
<LegendMessageKeyboard>
{`Make a \`score\` variable **before** your *Game Loop*, starting at 0.
* Update the score **inside** the loop where you create *gates*.
* Display the score **after** the loop ends.
\`\`\`
# Gate Runner
from microbit import *
import random

wall = Image("55555:00000:00000:00000:00000")
field = Image()
i_wall = 0

# Player pixel position
player_x = 2
player_y = 3

# Control how often we scroll down
scrollMs = 500
nextScroll = running_time() + scrollMs

$$$
$score = 0

$# Game Loop
while True:
    # Check for player collision with wall
    if field.get_pixel(player_x, player_y):
        break

    # Control player
    if button_a.was_pressed() and player_x > 0:
        player_x = player_x - 1
    if button_b.was_pressed() and player_x < 4:
        player_x = player_x + 1

    display.set_pixel(player_x, player_y, 9)

    # Is it time to scroll? (INDENT FROM HERE DOWN!)
    if running_time() > nextScroll:
        # Scroll the field
        field = field.shift_down(1)
        nextScroll = nextScroll + scrollMs

        # Update and check wall interval
        i_wall = i_wall + 1
        if i_wall == 3:
            i_wall = 0
            # Add new wall
            field = field + wall

$            # Punch hole for gate
            gate = random.randrange(5)
            field.set_pixel(gate, 0, 0)

$            # Score a point each gate
$            score = score + 1

$            # Increase scroll speed (RAGE!)
            if scrollMs > 200:
                scrollMs = scrollMs - 5

$        display.show(field)

$# Game Over
$display.show(Image.SAD)
$sleep(500)
$display.scroll(str(score), loop=True, wait=False)

$$$
\`\`\`
`}
</LegendMessageKeyboard>
<StepComplete
  prompt="Scoring some points?"
  xp={25}
  successMessage=
{`## Well Played!! {centered}
### You have built a complete *and* enjoyable game.
How cool is that?
`}
  reqImports={['microbit', 'random']}
  reqCalls={[
    'display.set_pixel', 'random.randrange', 'Image', 'field.shift_down', 'field.set_pixel',
    'button_a.was_pressed', 'button_b.was_pressed', 'display.show', 'running_time', 'field.get_pixel',
    'display.scroll'
  ]}
  reqFuncdefs={[]}
  reqStatements={['while', 'if', 'and']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Applications extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## That's a Wrap! {lesson-title}
___
`}
<img src={cyberTall} alt=""
    style={{
      width: '30%', margin: 'auto', display:'block', float: 'right', marginLeft:20, marginBottom:10,
    }}
/>
{`
#### Welcome to the world of CG! *(Computer Graphics)*
Using **code** to play around with **pixels** produces:
* Beautiful and easy user-interfaces for Web Browsers and other Apps on computers, phones, cars, etc.
* *Augmented Reality* Applications
  * Ex: **Sports** *ball-tracking* and *field-position* graphics on screen.
* Video games
* Movie special effects
* Visual Design and Simulation tools for **art**, **science**, **medicine**, and **industry**
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Craft a set of custom Images and **animate** by passing a list to ==display==.show()
* Connect a Speaker and add **sound**
  * Bouncing ball projects could beep when the ball hits a wall.
  * The *Gate Runner* game could use background ==music==, crash sounds, and more!
* Change to ==accelerometer== based player controls. (see *Spirit Level* project)
* Make a *side-scroller* game
  * Fly over a planet surface, dodging missiles and rescuing survivors...
* *Unlimited* possibilities - **Get creative!!**
`}
</LegendMessageTry>
<StepComplete
  prompt="You have mastered SO much! Keep Coding..."
  lessons={this.props.lessons}
  btnNext={true}
  btnGClassroom
  gFileId={this.props.gFileId}
/>

        </Markdown>
      </div>
    )
  }
}

/*
class Template extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Custom Image {lesson-title}
___
#### Subtitle
`}
<LegendMessageKeyboard>
{`
\`\`\`
# The code
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Notes on running
`}
</LegendMessageRun>
<StepComplete
  prompt="Completion query?"
  xp={25}
  successMessage=
{`## Success Cheer! {centered}
### Subtitle
`}
  reqImports={['microbit',]}
  reqCalls={['display.set_pixel']}
  reqFuncdefs={[]}
  reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}
*/

// Add static step IDs to uniquely identify the steps (minify nixes class names)
GraphicsIntro.stepId = 'GraphicsIntro'
CustomImage.stepId = 'CustomImage'
FillScreen.stepId = 'FillScreen'
FadeScreen.stepId = 'FadeScreen'
Termites.stepId = 'Termites'
MysteryPlot.stepId = 'MysteryPlot'
BouncingBall.stepId = 'BouncingBall'
Bounce2D.stepId = 'Bounce2D'
PixelGames.stepId = 'PixelGames'
GateRunner1.stepId = 'GateRunner1'
GateRunner2.stepId = 'GateRunner2'
GateRunner3.stepId = 'GateRunner3'
GateRunner4.stepId = 'GateRunner4'
GateRunner5.stepId = 'GateRunner5'
Applications.stepId = 'Applications'

export const graphics = [
  GraphicsIntro,
  CustomImage,
  FillScreen,
  Termites,
  FadeScreen,
  MysteryPlot,
  BouncingBall,
  Bounce2D,
  PixelGames,
  GateRunner1,
  GateRunner2,
  GateRunner3,
  GateRunner4,
  GateRunner5,
  Applications,
]
