import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'material-ui/Typography'
import { Drawer, CircularProgress } from 'material-ui'
import CloseIcon from 'material-ui-icons/Close'
import IconButton from 'material-ui/IconButton'
import Tooltip from 'material-ui/Tooltip'

import EditGroupForm from './EditGroupForm.jsx'
import { Database } from '../DataSources'
import { moduleEnums } from '../../lessons/CurriculumModules'


class EditGroupPanel extends React.PureComponent {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    groupDeleted: PropTypes.func.isRequired,
    refreshData: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    uiDisabled: PropTypes.bool.isRequired,
    disableUi: PropTypes.func.isRequired,
  }

  state = {
    curriculum: moduleEnums[0],
    joinCode: '',
    joinActive: false,
    created: new Date(),
    groupName: '',
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.groupId !== '' && newProps.groupId !== this.props.groupId) {
      this.setGroupData(newProps)
    } else if (newProps.groupId === '') {
      this.setState({
        curriculum: moduleEnums[0],
        joinCode: '',
        joinActive: false,
        created: new Date(),
        groupName: '',
      })
    }
  }

  setGroupData = (p) => {
    const grp = p.data.groups.find(obj => p.groupId === obj.groupId)
    this.setState({
      curriculum: grp.curriculum,
      joinCode: grp.joinCode,
      joinActive: grp.joinActive,
      created: grp.created,
      groupName: grp.groupName,
    })
  }

  groupUpdateComplete = () => {
    this.props.refreshData()
    this.setGroupData(this.props)
    this.props.disableUi(false)
  }

  updateGroup = (groupId, field, newVal) => {
    this.props.disableUi(true)
    if (field === 'joinCode') {
      Database.updateGroupJoinCode(groupId, this.state.joinCode).then(this.groupUpdateComplete)
    } else {
      Database.updateGroupField(groupId, field, newVal).then(this.groupUpdateComplete)
    }
  }

  render() {
    return (
      <Drawer
        classes={{ paper: this.props.classes.editGroupPanel }}
        variant="persistent"
        anchor="right"
        SlideProps={{ unmountOnExit: true }}
        open={this.props.isVisible}
      >
        <div style={{ margin: '0px' }} >
          <Tooltip title="Hide Editor">
            {
              this.props.uiDisabled ?
                <CircularProgress style={{ position: 'absolute', right: '31px', marginTop: '26px', width: 20, height: 20 }} /> :
                <IconButton onClick={this.props.toggleOpen} style={{ position: 'absolute', right: '15px', marginTop: '12px' }}>
                  <CloseIcon />
                </IconButton>
            }
          </Tooltip>
          <div style={{ marginTop: '32px' }}>
            <Typography
              variant="title"
              align="center"
            >
              Classroom Editor
            </Typography>
          </div>
          <EditGroupForm
            groupId={this.props.groupId}
            groupModified={this.updateGroup}
            groupDeleted={this.props.groupDeleted}
            showForm={this.props.groupId !== ''}
            curriculum={this.state.curriculum}
            joinCode={this.state.joinCode}
            joinActive={this.state.joinActive}
            created={this.state.created}
            groupName={this.state.groupName}
            disabled={this.props.uiDisabled}
          />
        </div>
      </Drawer>
    )
  }
}

export default EditGroupPanel
