// A lesson Module plugin for CodeSpace

import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Import styles here to apply across all project lessons in this Module
import './lesson-styles.css'
import 'react-html5video/dist/styles.css'

class CodingModuleBase extends Component {
  static propTypes = {
    showProjectNum: PropTypes.number.isRequired,
    showLessonNum: PropTypes.number.isRequired,
    lessons: PropTypes.object.isRequired,
    showDebugPanel: PropTypes.bool.isRequired,
    onDebugClick: PropTypes.func.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
    projectNames: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
    }
    this.projectNames = this.props.projectNames
    this.projects = this.props.projects
  }

  numProjects = () => this.props.projects.length

  numLessons = iProject => this.props.projects[iProject].length

  lessonName = (iProject, iLesson) => this.props.projects[iProject][iLesson].stepId

  lessonNames = iProject => this.props.projects[iProject].map(step => step.stepId)

  render() {
    return (
      React.createElement(
        this.props.projects[this.props.showProjectNum][this.props.showLessonNum],
        {
          lessons: this.props.lessons,
          showDebugPanel: this.props.showDebugPanel,
          onDebugClick: this.props.onDebugClick,
          interceptErrorCb: this.props.interceptErrorCb,
          gFileId: this.props.gFileId,
        },
      )
    )
  }
}

export default CodingModuleBase
