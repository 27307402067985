import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popover from 'material-ui/Popover'
import ScrollIndicator from './ScrollIndicator'
import ChevronRight from 'material-ui-icons/ChevronRight'

class HelpHintBreadcrumb extends Component {
  static propTypes = {
    hintName: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      crumbs: [this.props.hintName],
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.hintName !== prevProps.hintName) {
      let newState = this.state
      if (this.props.hintName === null) {
        newState.crumbs = []
      } else if (!this.state.crumbs.includes(this.props.hintName)) {
        newState.crumbs.push(this.props.hintName)
      }
      this.setState(newState)
    }
  }

  renderCrumbs = () => {
    let elemArray = [
      <div key="Tools"
      style={{
        display: 'inline',
      }}>
        Tools
      </div>
    ]
    for (const value of this.state.crumbs) {
      elemArray.push(
        <div key={value}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          flexShrink: 0,
        }}>
          <ChevronRight />
            {this.props.hintName === value ?
              <div style={{display: 'inline', padding: 5, backgroundColor: 'rgba(0, 0, 0, 0.12)'}}><b><mark>{value}</mark></b></div> :
              <mark>{value}</mark> }
        </div>
      )
    }
    return(elemArray)
  }

  render() {
    return(
      <div style={{
        minHeight: 35,
        paddingLeft: 5,
        borderColor: 'lightgray',
        borderWidth: 1,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderTopWidth: 1,
        backgroundColor: "#E6E6E6",
        display: this.state.crumbs.length < 2 ? 'none' : 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}>
        {this.renderCrumbs()}
      </div>
    )
  }
}

export default class HelpHintPopover extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    maxWidth: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    hintName: PropTypes.string,
  }

  renderChildren = (children) => {
    return React.Children.map(children, (child) => {
      // console.log("Checking on child: ", child)
      if (child.type === 'img') {
        // console.log("found an image: ", child)
        return React.cloneElement(child, {
          onLoad: () => {
            // console.log('I am an image that loaded.')
            this.popoverActions.updatePosition()
          }
        })
      } else {
        // Recurse to find more images
        if (child.props && child.props.children) {
          return React.cloneElement(child, {}, this.renderChildren(child.props.children))
        }
        return child
      }
    })
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps.anchorEl !== this.props.anchorEl) {
      // On content change, scroll new content to top
      if (this.scrollIndicator) {
        this.scrollIndicator.scrollTop()
      }
    }
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        onClose={this.props.onClose}
        onClick={this.props.onClick} // allow links to other hints
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            paddingBottom: 5,
            maxWidth: this.props.maxWidth,
            maxHeight: this.props.maxHeight,
          },
        }}
        action={(obj) => {
          this.popoverActions = obj
        }}
        style={{
          zIndex: 1600,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'inherit',
          }}
        >
        <div
          style={{
            flex: '1 0 auto',
          }}
        >
          <HelpHintBreadcrumb
            hintName={this.props.hintName}
          />
        </div>
        <div
          style={{
            flex: '1 1 auto',
            minHeight: '200px',
            maxHeight: this.props.maxHeight,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <ScrollIndicator
              ref={(component) => {this.scrollIndicator = component}}
          >
              {/* {this.props.children} */}
              <div style={{
                paddingLeft: 5,
                paddingRight: 5,
              }}>
                {this.renderChildren(this.props.children)}
              </div>
          </ScrollIndicator>
        </div>
        </div>
      </Popover>
    )
  }
}
