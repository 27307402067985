// Include a few utilities to make error messages easier to understand. Do this by adding more targeted
// error messages when possible or replace error messages entirely

const MATCH_STR_IDX = 0
const ERROR_STR_IDX = 1

// Define regex match for the message to override and the desired error message to replace it
// Array of arrays where [<Regex>, <displayed error message>]
const RUNTIME_ERRORS = [
  [/NameError: local variable referenced/, "Attempted to use Local variable before it was assigned a value"],
  [/TypeError: unsupported types/, "Invalid operation for the variable types"],
  [/IndentationError: unindent/, "Incorrect line indentation"],
  [/NameError: name '(\w+)' is not defined/, 'Attempted to use a variable that is not defined: '],
]

const SYNTAX_ERRORS = [
  [/Saw '\s*'/, "Incorrect line indentation"],
  [/Saw 'else'/, "Else statement without 'if' statement in code block"],
  [/Saw '(.)'/, "Found unexpected symbol "],
]

// These match up to collections in firestore
const ERROR_CATEGORIES = [
  [
    'indentation',
    'else',
    'unexpected-symbol',
  ], [
    'NameError',
    'TypeError',
    'IndentationError',
    'NameError',
  ],
]

// Common symbols to identify and verify case
const MB_LIB_BASE = [
  'microbit', 'Image', 'display', 'button_a', 'button_b', 'accelerometer', 'compass', 'i2c', 'uart', 'spi', 'reset',
  'sleep', 'running_time', 'panic', 'temperature', 'pin0', 'pin1', 'pin2', 'pin3', 'pin4', 'pin5', 'pin6',
  'pin7', 'pin8', 'pin9', 'pin10', 'pin11', 'pin12', 'pin13', 'pin14', 'pin15', 'pin16', 'pin19', 'pin20',
]
const MB_LIB_IMAGE = [
  'width', 'height', 'get_pixel', 'set_pixel', 'shift_left', 'shift_right', 'shift_up', 'shift_down', 'copy',
  'crop', 'invert', 'fill', 'blit', 'HEART', 'HEART_SMALL', 'HAPPY', 'SMILE', 'SAD', 'CONFUSED', 'ANGRY',
  'ASLEEP', 'SURPRISED', 'SILLY', 'FABULOUS', 'MEH', 'YES', 'NO', 'CLOCK12', 'CLOCK1', 'CLOCK2', 'CLOCK3',
  'CLOCK4', 'CLOCK5', 'CLOCK6', 'CLOCK7', 'CLOCK8', 'CLOCK9', 'CLOCK10', 'CLOCK11', 'ARROW_N', 'ARROW_NE',
  'ARROW_E', 'ARROW_SE', 'ARROW_S', 'ARROW_SW', 'ARROW_W', 'ARROW_NW', 'TRIANGLE', 'TRIANGLE_LEFT',
  'CHESSBOARD', 'DIAMOND', 'DIAMOND_SMALL', 'SQUARE', 'SQUARE_SMALL', 'RABBIT', 'COW', 'MUSIC_CROTCHET',
  'MUSIC_QUAVER', 'MUSIC_QUAVERS', 'PITCHFORK', 'XMAS', 'PACMAN', 'TARGET', 'ALL_CLOCKS', 'ALL_ARROWS',
  'TSHIRT', 'ROLLERSKATE', 'DUCK', 'HOUSE', 'TORTOISE', 'BUTTERFLY', 'STICKFIGURE', 'GHOST', 'SWORD',
  'GIRAFFE', 'SKULL', 'UMBRELLA', 'SNAKE',
]
const MB_LIB_DISPLAY = ['get_pixel', 'set_pixel', 'show', 'scroll', 'clear', 'on', 'off', 'is_on']
const MB_LIB_BUTTON = ['is_pressed', 'was_pressed', 'get_presses']
const MB_LIB_ACCEL = [
  'get_x', 'get_y', 'get_z', 'get_values', 'current_gesture', 'is_gesture', 'was_gesture', 'get_gestures',
]
const MB_LIB_COMPASS = [
  'heading', 'is_calibrated', 'calibrate', 'clear_calibration', 'get_x', 'get_y', 'get_z', 'get_field_strength',
  'get_calibration', 'set_calibration', 'start_calibrating', 'stop_calibrating', 'get_values',
]
const MB_LIB_I2C = ['init', 'read', 'write']
const MB_LIB_UART = ['init', 'any', 'read', 'readall', 'readline', 'readinto', 'write', 'ODD', 'EVEN']
const MB_LIB_SPI = ['init', 'write', 'read', 'write_readinto']
const MB_LIB_PINS = [
  'write_digital', 'read_digital', 'write_analog', 'read_analog', 'set_analog_period',
  'set_analog_period_microseconds', 'is_touched', 'PULL_UP', 'PULL_DOWN', 'NO_PULL', 'get_pull', 'set_pull',
  'get_mode',
]

const PY_BUILTINS = ['True', 'False']

// Combine all subclasses of microbit
const MB_LIB_ALL = [].concat(
  MB_LIB_BASE, MB_LIB_IMAGE, MB_LIB_DISPLAY, MB_LIB_BUTTON, MB_LIB_ACCEL, MB_LIB_COMPASS, MB_LIB_I2C, MB_LIB_UART,
  MB_LIB_SPI, MB_LIB_PINS, PY_BUILTINS
)

// Index into error arrays by type
const ALL_ERRORS = [SYNTAX_ERRORS, RUNTIME_ERRORS]

class ErrorHelper {
  constructor() {
    this.ERR_SYNTAX = 0
    this.ERR_RUNTIME = 1
  }

  // Return informative error message for case mismatch between known symbols
  findErrorInsight = (errMsg, symbols) => {
    // Check capitalization except where "argument" names might collide with symbols; e.g. display.show()
    if (!errMsg.includes("argument")) {
      let addErrInsight = this.checkCapitalization(errMsg, MB_LIB_ALL)
      if (addErrInsight.length > 0) {
        return `<b>Check capitalization of: '${addErrInsight}'</b>\n`
      }
    }
    return ''
  }

  checkCapitalization = (errMsg, symbols) => {
    // Check runtime error name callouts versus all required names, return any possible mis-capitalized names
    let possibles = []

    if (symbols.length > 0) {
      const lcNames = symbols.map(n => n.toLowerCase())

      // Get single-quoted names from Runtime error message (candidate names)
      let errNames = errMsg.match(/'[^']+'/g)
      if (errNames) {
        errNames = errNames.map(s => s.slice(1,-1))

        // Remove duplicates
        errNames = Array.from(new Set(errNames))

        // Return case mismatches
        possibles = errNames.filter((errName) => lcNames.includes(errName.toLowerCase()) && !symbols.includes(errName))
      }
    }
    return possibles
  }

  checkFileImports = () => {
    // TODO: detect if duplicate imports are found
    // TODO: detect if microbit object is called without importing microbit library
  }

  friendlyError = (msg, errorType) => {
    const errorArray = ALL_ERRORS[errorType]
    const errorArraySize = errorArray.length
    let friendlyMsg = msg
    const insight = this.findErrorInsight(msg)
    let errCategory = 'uncategorized'

    for (let i = 0; i < errorArraySize; i += 1) {
      const re = errorArray[i][MATCH_STR_IDX]
      const matches = re.exec(msg)
      if (matches) {
        friendlyMsg = errorArray[i][ERROR_STR_IDX]
        errCategory = ERROR_CATEGORIES[errorType][i]

        if (matches.length > 1) {
          friendlyMsg += `'${matches[1]}'`
        }
        break
      }
    }

    return {
      errorMsg: insight + friendlyMsg,
      friendlyMsg,
      insight,
      errCategory,
    }
  }
}

export default ErrorHelper
