import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { googleApi, CLASSROOM_STUDENT_SCOPES } from './Gapi'
import ClassroomBtn from './assets/32x32_yellow_stroke_icon@2x.png'
import { GoogleClassroomSubmit } from './GoogleClassroomSubmit'


export class ShareToGoogleClassroom extends React.Component {
  static propTypes = {
    gFileId: PropTypes.string.isRequired,
    stepName: PropTypes.string.isRequired,
    stepNext: PropTypes.func.isRequired,
    currentProjectNum: PropTypes.number.isRequired,
    projectIsCompleted: PropTypes.bool.isRequired,
  }

  state = {
    showCourses: false,
  }

  onCoursesClose = (result) => {
    this.setState({ showCourses: false })
    if (result === 200 && this.props.stepNext) {
      this.props.stepNext()
    }
  }

  onSubmitClick = () => {
    const hasScopes = googleApi.getCurrentUser().hasGrantedScopes(CLASSROOM_STUDENT_SCOPES)
    if (!hasScopes) {
      googleApi.getCurrentUser().grant({ scope: CLASSROOM_STUDENT_SCOPES }).then(async () => {
        this.setState({ showCourses: true })
      })
    } else {
      this.setState({ showCourses: true })
    }
  }

  render() {
    return (
      this.props.gFileId ?
        <Fragment>
          <div
            style={{
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: this.props.projectIsCompleted ? 'pointer' : null,
            }}
            role="none"
            onClick={this.props.projectIsCompleted ? this.onSubmitClick : null}
          >
            <img
              src={ClassroomBtn}
              alt=""
            />
            <span
              style={{
              marginLeft: '16px',
            }}
            >
              {
                this.props.projectIsCompleted ? <Fragment>Turn In Google Classroom Assignment</Fragment> : <Fragment>Please finish the project to submit your assignment</Fragment>
              }
            </span>
          </div>
          <GoogleClassroomSubmit
            open={this.state.showCourses}
            gFileId={this.props.gFileId}
            onClose={this.onCoursesClose}
            stepName={this.props.stepName}
            currentProjectNum={this.props.currentProjectNum}
          />
        </Fragment>
        :
        null
    )
  }
}

export default ShareToGoogleClassroom
