
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'

import AutomaticGardenIntroImg from './assets/iStock-1206648608.jpg'
import FinalAutomaticGardenImg from './assets/iStock-1023231622.jpg'
import WaterPumpConnectionImg from './assets/water_pump_battery.jpg'
import LampExampleImg from './assets/lamp.jpg'
import RelayImg from './assets/peripherals/pk_relay.jpg'
import WaterPumpSetupImg from './assets/water_pump_setup.jpg'

import SoilMoistureImg from './assets/iStock-616257726.jpg'

import AdvancedDebugImg from '../PythonWithRobots/assets/advanced_debug.jpg'

import AutomationImg from './assets/iStock-1208690325.jpg'

import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'

import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
  LegendMessageDebug,
  LegendMessageTry,
LegendMessagePeripheral,
} from '../Legends'

export const AutomaticGardenImg = GoogleClassroomImg

class ProjectAutomaticGarden extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Automatic Garden! {lesson-title}
___
### This project shows you how to connect and use a relay to operate devices like the water pump!
`}
<img src={AutomaticGardenIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The crew will need to be able to generate their own food when they reach their destination.
* They will require some automation to assist them in production!

**Build a system to sense soil mositure levels and automatically water a garden.**

**Project: Automatic Garden** will guide you through automating food production on Mars.

#### Project Goals:
* Learn about relays
* Add a soil moisture sensor to assist with automation
* Understand small pumps and their operation
* Learn how to prime a pump and what that means
* Automate food production for busy life in space!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class ConnectRelay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Automatic Garden Begin! {lesson-title}
___
#### Time to build the automated gardening system for the colony on Mars!

Your system will be complete with:
* A soil moisture detector and a water pump to perfectly balance the moisture in the soil.
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **AutomaticGarden**
`}
</LegendMessageInteract>
{`
### Connect the 3V relay to your octopus:bit!
* The relay will be used to provide external power to the water pump.
* You will learn about the relay and what it does!
`}
<LegendOctoHeader 
  pin={0}
  peripheral={16} // 14 = Relay
  markdown={`
  Connect the **3V Relay** to **position 0** on your ==octopus:bit==!
  `}
/>
</Markdown>
        <StepComplete
          prompt="Press NEXT to learn more about relays!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class ExternalPower extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## What is a Relay? {lesson-title}
___
### Why would you need to use a relay in your circuit?
`}
<img src={LampExampleImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`

**A relay is just an electrically operated switch!**
* It allows a relatively low power circuit to switch high powered loads.
* It could allow a single controller to switch multiple different loads at the same time.
* It could even allow a DC logic circuit to switch AC Power!

**The biggest benefit of a relay is isolation!**
* The DC logic circuit can be completely isolated from the load circuit

*How about an example of using a relay?*

Say you were trying to turn on a bedroom lamp using a battery operated device.
* The lamp requires AC (Alternating Current) power from a wall outlet.
* The battery logic controller would be completely destroyed if you hooked it into AC power.

**Both circuits need complete isolation from each other!**

Take a look at the diagram to visualize the lamp example.
`}
<Quiz
        lessons={this.props.lessons}
        prompt="When is the biggest benefit of using a relay?"
        xp={5}
        answerRight={"Circuit Isolation"}
        answerWrong={["More Available Power", "An Extra Switch"]}
      />

</Markdown>
        <StepComplete
          prompt="Press NEXT if you know why you would use a relay!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class ConnectPump extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Connect the Water Pump! {lesson-title}
___
### Time to connect the water pump to the relay.
`}
<LegendMessageWarning>
<Markdown>
{`
*Do I really need the relay?*
* Yes, to ensure proper operation you will need a relay for the water pump.

**Do not connect the water pump directly to a **micro:bit** ==pin==!**
* The **micro:bit** output pins **DO NOT** supply enough current to drive the pump properly.
* This could make the pump inoperative and in the worst case - might even cause damage.
`}
</Markdown>
</LegendMessageWarning>
<LegendMessageWarning>
<Markdown>
{`
*Well... Can I power the pump from the **octopus:bit** positions through the relay?*
* It may work sometimes...

The water pump can draw more current than even the ==octopus:bit== can supply (particularly when connected to a USB port).
* This can cause the **micro:bit** to momentarily trip offline and reset your program.

**The most reliable method is to power the pump with an EXTERNAL power source.**
`}
</Markdown>
</LegendMessageWarning>
<LegendMessageConcept>
<Markdown>
{`
*How much power does the pump need?*
* The optimal power supply for the pump would be 3 AA or 3 AAA Batteries.
* The pump normal operating voltage is 3 - 4.5 Volts DC.  

A single AA or AAA battery produces:
* ~ 1.5 V when it is new
* As low as 1.35 V when it is used
`}
</Markdown>
</LegendMessageConcept>
{`
If all you have is a **two-battery pack** that should still work! 
* Two batteries will generate plenty of current to operate the pump.
* However, the pump may stop operating if the voltage gets too low.
* Try to start with fresh batteries!
`}
<LegendMessagePeripheral title={'Connect the Water Pump'}>
<Markdown>
{`
Connect the **Water Pump** to the **3V Relay** and a **Battery Pack** as shown in the image below.
* You will need a small flat-head screwdriver to loosen and tighten the relay terminals
* You'll also need an additional *"male-to-male"* jumper-wire between the battery connector and the pump (both black wires)

**NOTE:** Do not tighten the terminals to the point where the ends of the wire break or bend.
* Tighten just enough to maintain a positive electrical connection!
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <img src={WaterPumpConnectionImg} alt="Water Pump Connection" style={{width: '40%'}} />
</div>
</LegendMessagePeripheral>
<Quiz
        lessons={this.props.lessons}
        prompt="When is the normal operating voltage range of the Water Pump?"
        xp={5}
        answerRight={"3 - 4.5 V DC"}
        answerWrong={["100 - 240 V AC", "3 - 5 V DC"]}
      />
</Markdown>
        <StepComplete
          prompt="Press NEXT if the water pump is connected!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class NormallyOpenClosed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## NO or NC? {lesson-title}
___
### Why are there 3 screw terminals on the relay?
`}
<img src={RelayImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
The relay has three different screw terminals:
* NC (Normally Closed)
* Power Input
* NO (Normally Open)

In the previous slide you connected the water pump to the **NO** terminal.

*What is NO vs NC?*
`}
<LegendMessageConcept>
<Markdown>
{`
When connecting the relay you should:
* Always connect the power that you are switching to the center screw terminal.

The other two terminals (NC and NO) are optional:
* NC (Normally Closed) = current will flow from the center screw terminal into the NC terminal **only** when the logic input to the relay is 0 (LOW).
* NO (Normally Open) = current will flow from the center screw terminal into the NO terminal **only** when the logic input to the relay is 1 (HIGH).

NO is the most common usage of a relay.
* This is because it is more common to want the peripheral to be powered with a HIGH signal and off with a LOW signal.
* Many relays only offer the NO terminal!

And yes... you can connect to both terminals at the same time if you want to!
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageKeyboard>
{`
**Time for an experiment!**

Use the relay with NO to see what happens when you switch the logic on **pin 0**.

We expect that when pin 0 is set to:
* 0 (Logic LOW) the pump will be OFF
* 1 (Logic HIGH) the pump will be ON

\`\`\`
$from microbit import *

$pin0.write_digital(1) # expect pump ON

$sleep(2000)

$pin0.write_digital(0) # expect pump OFF
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
{`
*Did your pump turn ON and OFF as expected?*
`}
<LegendMessagePeripheral>
{`
Now switch the wire from the **NO** terminal to the **NC** terminal.
`}
</LegendMessagePeripheral>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Did your pump perform opposite of the first test?"
            xp={5}
            successMessage=
{`## Pump Active! {centered}
### You got the water pump working!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep']}
            reqArgs={['0', '1']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class PumpCode extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Pump it Up! {lesson-title}
___
### Add code to operate the water pump!
`}
<LegendMessagePeripheral>
<Markdown>
{`
Start by switching your water pump power line back to the **NO** terminal as shown below.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <img src={WaterPumpConnectionImg} alt="Water Pump Connection" style={{width: '40%'}} />
</div>
</LegendMessagePeripheral>

<LegendMessageKeyboard>
{`
Now, add some code to operate your Water Pump:
1. Start with \`PUMP_ON\` and \`PUMP_OFF\` ==variables==.
2. Next, add a \`set_pump\` ==function== that writes a digital value to the relay.
3. Finally, add a \`while\` loop that turns the pump ON for 2 seconds and then OFF for 2 seconds.
\`\`\`
from microbit import *

$PUMP_ON = 1
$PUMP_OFF = 0
    
$def set_pump(val):
$    pin0.write_digital(val)
    
$while(True):
$    set_pump(PUMP_ON)
$    sleep(2000) # run pump for 2 seconds
        
$    set_pump(PUMP_OFF)
$    sleep(2000) # pump off for 2 seconds
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Is your pump turning ON and OFF?"
            xp={5}
            successMessage=
{`## On, Off, On, Off! {centered}
### Just keep that pump running!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep', 'set_pump']}
            reqArgs={['2000', 'PUMP_ON', 'PUMP_OFF']}
            reqFuncdefs={['set_pump']}
            reqNames={['PUMP_ON', 'PUMP_OFF']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class PrimePump extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Prime the Pump! {lesson-title}
___
### Time to Prime the Pump and Prepare it for Operation
`}
<LegendMessageConcept>
<Markdown>
{`
Many pumps must be primed before operation.

Priming is the process of removing air from the pump lines.
* In other words, you need to fill the lines with water before the pump can operate properly.

The pump must be primed because:
* Gases tend to expand when pushed and block the line.
* The pump will not generate enough pressure to push water through the gas.
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageWarning>
<Markdown>
{`
The majority of pump problems occur because the pump was not primed first. 
* Some pumps can even overheat if they are not properly primed!
`}
</Markdown>
</LegendMessageWarning>
{`
The first thing you will need is your water source.  
* A cup or container of water is perfect.  

You will also need a place to pump water to.
* A second empty cup will work!

The next step is to submerge the pump in the water as shown here:
`}
<img src={WaterPumpSetupImg} alt=""
    style={{
      width: '500px', margin: 'auto', display:'block'
    }}
/>
{`
It's OK! The pump is designed to be submersible.

**Try to keep the bottom of the pump above the bottom of the outflow line.

Now it is time to prime!

The easiest way to prime the water pump is: 
* Get it running and then squeeze water through the line!
* You can use your fingers to massage the water through the line.
`}
<LegendMessageKeyboard>
{`
Comment out the \`set_pump(PUMP_OFF)\` function call at the end of your loop.
* For pump priming you will need the pump to run continuously.
\`\`\`
from microbit import *

PUMP_ON = 1
PUMP_OFF = 0
    
def set_pump(val):
    pin0.write_digital(val)
    
while(True):
    set_pump(PUMP_ON)
    sleep(2000) # run pump for 2 seconds
        
$    # set_pump(PUMP_OFF)
    sleep(2000) # pump off for 2 seconds
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageKeyboard>
{`
**If your pump is running smoothly:**
* You can uncomment the \`set_pump(PUMP_OFF)\` function call at the end of your loop.
\`\`\`
from microbit import *

PUMP_ON = 1
PUMP_OFF = 0
    
def set_pump(val):
    pin0.write_digital(val)
    
while(True):
    set_pump(PUMP_ON)
    sleep(2000) # run pump for 2 seconds
        
$    set_pump(PUMP_OFF)
    sleep(2000) # pump off for 2 seconds
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Are you pumping water every 2 seconds?"
            xp={5}
            successMessage=
{`## Pumping Water! {centered}
### Awesome work getting the water flowing!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep', 'set_pump']}
            reqArgs={['2000', 'PUMP_ON', 'PUMP_OFF']}
            reqFuncdefs={['set_pump']}
            reqNames={['PUMP_ON', 'PUMP_OFF']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class MoistureSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Detect Soil Moisture {lesson-title}
___
### It is time to detect the moisture in the soil!
`}
<LegendOctoHeader 
  pin={1}
  peripheral={15} // 15 = Soil Moisture Sensor
  markdown={`
  Connect the **Soil Moisture Sensor** to **position 1** on your ==octopus:bit==!
  `}
/>
{`
The soil moisture sensor is an ==analog== sensor.

It measures the conductivity of the soil between the two sensor tines.
* One tine outputs a very small DC current.
* The second tine reads the current as an input.

**More water in the soil = More conductivity = Less resistance**
* This means as **moisture increases** the input values will also **increase**!
`}
<LegendMessageInteract>
{`
Place the soil moisture sensor into some soil!
`}
</LegendMessageInteract>
<LegendMessageKeyboard>
{`
Now, add some code to see the output of the soil moisture sensor:
1. Start with a \`read_moisture\` function that reads the ==analog== value of \`pin1\`.
2. Now take a reading in your ==loop== into a ==variable== called \`moisture_val\`.
3. Output that value to the ==debug== console.
\`\`\`
from microbit import *

PUMP_ON = 1
PUMP_OFF = 0

$def read_moisture():
$    return pin1.read_analog()
    
def set_pump(val):
    pin0.write_digital(val)
    
set_pump(PUMP_OFF)

while(True):
$    moisture_val = read_moisture()

$    print("Moisture Val: ", moisture_val)
    
    set_pump(PUMP_ON)
    sleep(2000) # run pump for 2 seconds
        
    set_pump(PUMP_OFF)
    sleep(2000) # pump off for 2 seconds
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageDebug title="Open The Advanced Debug Panel">
<img src={AdvancedDebugImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', marginRight:25, marginLeft: 10, marginBottom: 10
    }}
/>
</LegendMessageDebug>
<LegendMessageRun>
{`
You should be seeing a new soil moisture sensor value every 4 seconds.
`}
</LegendMessageRun>
</Markdown>
<StepComplete
            prompt="Are you seeing soil moisture values?"
            xp={5}
            successMessage=
{`## Detecting Moisture Levels! {centered}
### Well on your way to an automatic garden!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep', 'set_pump', 'read_moisture', 'print', 'pin1.read_analog']}
            reqArgs={['2000', 'PUMP_ON', 'PUMP_OFF', 'moisture_val']}
            reqFuncdefs={['set_pump', 'read_moisture']}
            reqNames={['PUMP_ON', 'PUMP_OFF']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class MoistureThreshold extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Moisture Threshold! {lesson-title}
___
### Add a Low Moisture Threshold to Start the Pump
`}
<img src={SoilMoistureImg} alt="Soil Moisture"     style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`
Add a \`LOW_MOISTURE_THRESHOLD\` variable to your code.
* This will be used to tell your program when to run the pump.
* You can start with a value of \`300\`.

Then add an \`if\` condition in the \`while\` loop to check against your new threshold.
\`\`\`
    if moisture_val < LOW_MOISTURE_THRESHOLD:
        set_pump(PUMP_ON)
        sleep(2000) # run pump for 2 seconds
\`\`\`
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

PUMP_ON = 1
PUMP_OFF = 0

$LOW_MOISTURE_THRESHOLD = 300

def read_moisture():
    return pin1.read_analog()
    
def set_pump(val):
    pin0.write_digital(val)
    
set_pump(PUMP_OFF)

while(True):
    moisture_val = read_moisture()

    print("Moisture Val: ", moisture_val)
    
$    if moisture_val < LOW_MOISTURE_THRESHOLD:
$        set_pump(PUMP_ON)
$        sleep(2000) # run pump for 2 seconds
        
    set_pump(PUMP_OFF)
    sleep(2000) # wait 10 seconds before running pump again
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageDebug title="Open The Advanced Debug Panel" />
<LegendMessageRun />
<LegendMessageInteract>
{`
You can test that the threshold is working by pulling the sensor out of the soil for a reading.
* This will create a high resistance through the air and return a value below \`300\`.
`}
</LegendMessageInteract>
</Markdown>
<StepComplete
            prompt="Does the pump stop at the threshold?"
            xp={5}
            successMessage=
{`## Moisture Level Threshold! {centered}
### Threshold of Awesome Exceeded!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep', 'set_pump', 'read_moisture', 'print', 'pin1.read_analog']}
            reqArgs={['2000', 'PUMP_ON', 'PUMP_OFF', 'moisture_val']}
            reqFuncdefs={['set_pump', 'read_moisture']}
            reqNames={['PUMP_ON', 'PUMP_OFF', 'LOW_MOISTURE_THRESHOLD', 'moisture_val']}
            reqStatements={['while', 'if']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}


class TuneMoistureReadings extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Fine Tune Automation {lesson-title}
___
#### You Just need to Fine Tune your Values a Little! {centered}
`}
<img src={AutomationImg} alt="fine tune automation"     style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }} />
{`

This step will require a little of your amazing testing ability!

First, lets change the last sleep delay from 2 seconds to 10 seconds:
\`\`\`
    set_pump(PUMP_OFF)
$    sleep(10000) # wait 10 seconds before running pump again
\`\`\`

This will give the soil a little time to absorb water before taking another reading!
* You can adjust that value as needed.
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

PUMP_ON = 1
PUMP_OFF = 0

LOW_MOISTURE_THRESHOLD = 300

def read_moisture():
    return pin1.read_analog()
    
def set_pump(val):
    pin0.write_digital(val)
    
set_pump(PUMP_OFF)

while(True):
    moisture_val = read_moisture()
    print("Moisture Val: ", moisture_val)
    
    if moisture_val < LOW_MOISTURE_THRESHOLD:
        set_pump(PUMP_ON)
        sleep(2000) # run pump for 2 seconds
        
    set_pump(PUMP_OFF)
$    sleep(10000) # wait 10 seconds before running pump again
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageTry>
{`
You need to determine the appropriate soil moisture level for your plant.

**All plants are different!**

You can adjust the moisture level by tweaking the LOW_MOISTURE_THRESHOLD up and down.
* Try some different values and see what your plant needs!
`}
</LegendMessageTry>
</Markdown>
<StepComplete
            prompt="Do you have the right moisture level for your plant?"
            xp={5}
            successMessage=
{`## Automated Production {centered}
### Your Garden is Ready!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_digital', 'sleep', 'set_pump', 'read_moisture', 'print', 'pin1.read_analog']}
            reqArgs={['2000', 'PUMP_ON', 'PUMP_OFF', 'moisture_val']}
            reqFuncdefs={['set_pump', 'read_moisture']}
            reqNames={['PUMP_ON', 'PUMP_OFF', 'LOW_MOISTURE_THRESHOLD', 'moisture_val']}
            reqStatements={['while', 'if']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}


class AutomaticGardenFinal extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Fully Automated Food Production! {lesson-title}
___
### You've completed project Automatic Garden!
You have detected soil moisture and incorporated a water pump. 

The tools that you created will be the core of the fully automated food production centers on Mars!

**The crew will be able to grow their own food as they begin life outside of Earth!!**
`}
        <img src={FinalAutomaticGardenImg} alt="" style={{margin: 'auto', width:'80%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="Excellent work automating the mission's food production. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectAutomaticGarden.stepId = 'ProjectAutomaticGarden'
ConnectRelay.stepId = 'ConnectRelay'
ExternalPower.stepId = 'ExternalPower'
ConnectPump.stepId = 'ConnectPump'
NormallyOpenClosed.stepId = 'NormallyOpenClosed'
PumpCode.stepId = 'PumpCode'
PrimePump.stepId = 'PrimePump'
MoistureSensor.stepId = 'MoistureSensor'
MoistureThreshold.stepId = 'MoistureThreshold'
TuneMoistureReadings.stepId = 'TuneMoistureReadings'
AutomaticGardenFinal.stepId = 'AutomaticGardenFinal'

export const automaticGarden = [
  ProjectAutomaticGarden,
  ConnectRelay,
  ExternalPower,
  ConnectPump,
  NormallyOpenClosed,
  PumpCode,
  PrimePump,
  MoistureSensor,
  MoistureThreshold,
  TuneMoistureReadings,
  AutomaticGardenFinal,
]
