import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from 'material-ui-icons/Edit'
import IconButton from 'material-ui/IconButton'
import Tooltip from 'material-ui/Tooltip'
import GroupSelectionBreadcrumbs from './GroupSelectionBreadcrumbs.jsx'


class GroupsTopBar extends React.PureComponent {
  static propTypes = {
    groupData: PropTypes.instanceOf(Array).isRequired,
    route: PropTypes.string.isRequired,
    updateRoute: PropTypes.func.isRequired,
    toggleEditPanel: PropTypes.func.isRequired,
    editPanelVis: PropTypes.bool.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  }

  render() {
    return (
      <div className={this.props.classes.topBar}>
        <div className={this.props.classes.breadcrumbs}>
          <GroupSelectionBreadcrumbs
            groupData={this.props.groupData}
            route={this.props.route}
            updateRoute={this.props.updateRoute}
          />
        </div>
        <Tooltip title={this.props.editPanelVis ? 'Hide Editor' : 'View Editor'}>
          <IconButton onClick={this.props.toggleEditPanel} className={this.props.classes.editPanelButton}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }
}

export default GroupsTopBar
