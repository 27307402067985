// Inspiring quotes

export const debuggingQuotes = [
  {
    quote: "If debugging is the process of removing software bugs, then programming must be the process of putting them in.",
    author: "Edsger W. Dijkstra",
  },
  {
    quote: "Debugging is twice as hard as writing the code in the first place. Therefore, if you write the code as cleverly as possible, you are, by definition, not smart enough to debug it.",
    author: "Brian Kernighan",
  },
  {
    quote: "Programming allows you to think about thinking, and while debugging you learn learning.",
    author: "Nicholas Negroponte",
  },
  {
    quote: "As soon as we started programming, we found to our surprise that it wasn't as easy to get programs right as we had thought. Debugging had to be discovered. I can remember the exact instant when I realized that a large part of my life from then on was going to be spent in finding mistakes in my own programs.",
    author: "Maurice Wilkes",
  },
  {
    quote: "There has never been an unexpectedly short debugging period in the history of computers.",
    author: "Steven Levy",
  },
  {
    quote: `Another effective [debugging] technique is to explain your code to someone else. This will often cause you to explain the bug to yourself. Sometimes it takes no more than a few sentences, followed by an embarrassed "Never mind, I see what's wrong. Sorry to bother you." This works remarkably well; you can even use non-programmers as listeners. One university computer center kept a teddy bear near the help desk. Students with mysterious bugs were required to explain them to the bear before they could speak to a human counselor.`,
    author: 'Brian Kernighan',
  },

  {
    quote: `To err is human, but to really foul things up you need a computer.`,
    author: "Paul R. Ehrlich",
  },
  {
    quote: `Each new user of a new system uncovers a new class of bugs.`,
    author: "Brian Kernighan",
  },
  {
    quote: `There are two ways of constructing a software design: One way is to make it so simple that there are obviously no deficiencies and the other way is to make it so complicated that there are no obvious deficiencies.`,
    author: "C.A.R. Hoare",
  },
  {
    quote: `The computing scientist’s main challenge is not to get confused by the complexities of his own making.`,
    author: "Edsger W. Dijkstra",
  },
  {
    quote: `The cheapest, fastest, and most reliable components are those that aren’t there.`,
    author: "Gordon Bell",
  },
  {
    quote: `Simplicity is prerequisite for reliability.`,
    author: "Edsger W. Dijkstra",
  },
  {
    quote: `The noblest pleasure is the joy of understanding.`,
    author: "Leonardo da Vinci",
  },
  {
    quote: `Simplicity is the ultimate sophistication.`,
    author: "Leonardo da Vinci",
  },
  {
    quote: `It's hard enough to find an error in your code when you're looking for it; it's even harder when you've assumed your code is error-free.`,
    author: "Steve McConnell",
  },
  {
    quote: `Computers are good at following instructions, but not at reading your mind.`,
    author: "Donald Knuth",
  },
  {
    quote: `On two occasions I have been asked, "Pray, Mr. Babbage, if you put into the machine wrong figures, will the right answers come out?" ... I am not able rightly to apprehend the kind of confusion of ideas that could provoke such a question. [ed. commonly said, "Garbage In, Garbage Out"]`,
    author: "Charles Babbage",
  },
  {
    quote: `Machines take me by surprise with great frequency.`,
    author: "Alan Turing",
  },
    
]