// Project: JumpstartIntro

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import MicrobitFront from './assets/microbit-front.gif'
import MicrobitBack from './assets/microbit-back.gif'
import MicrobitEsdImg from './assets/esd.png'
import MicrobitHeart from './assets/microbit-heart-trans.gif'
import UsbCable from './assets/mb-usb.jpg'
import Button from 'material-ui/Button'
import Joyride from '../cbl-joyride'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageConcept,
  LegendMessageInteract,
  LegendQuiz,
  LegendCollapsed,
} from '../Legends'
import FlyingCody from './FlyingCody'
import Markdown from '../cbl-remarkable'
import serComm from '../../WebusbSerialBridge'
import RunCode from './assets/RunCode.mp4'
import { DefaultPlayer as Video } from 'react-html5video'
import JoyBlast from '../JoyBlast'
import CheckIcon from 'material-ui-icons/Done'
import Journey from './assets/Journey.svg'
import mb from '../../mbEnum'
import UsbConnect from './assets/UsbConnect.mp4'
import Quiz from '../Quiz'
import ExpansionPanel, {ExpansionPanelDetails, ExpansionPanelSummary} from 'material-ui/ExpansionPanel'
import ExpandMoreIcon from 'material-ui-icons/ExpandMore'
import ModuleSelectVid from './assets/ModuleSelect_sm.mp4'

export const JumpstartIntroImg = Journey

// Check for pre-WebUSB OS versions
export let WindowsOldVersionUSBnote = null
const m = navigator.appVersion.match(/Windows[^\d]*([\d.]+)/)
const WIN8_1_VER = 6.3
if (m && parseFloat(m[1]) < WIN8_1_VER) {
  WindowsOldVersionUSBnote = (
    <div>
      <b style={{color:'red'}}>NOTE:</b><br />
      Looks like you're on an older version of Windows (pre 8.1) that doesn't natively support WebUSB! :-(<br />
      An upgrade may be required to enable USB access.<br />
      <span>See: </span>
      <a href="https://firialabs.com/blogs/support-forum/computer-requirements" target="_blank"  rel="noopener noreferrer">Computer Requirements</a>
      <span> for details.</span>
    </div>
  )
}

const tourCodeSpace = [
  {
    title: 'Welcome to CodeSpace!',
    text: (
     <Markdown>
{`Start with this brief **tour**, and get to know the
**CodeSpace** web application you'll be using.

Press **Next** to continue...
{just-right}
`}
     </Markdown>
    ),
    selector: '#lesson-content',
    position: 'left',
    //isFixed: true,
    style: {
      width: 400,
    }
  },
  {
    title: 'Editor Panel',
    text: (
      <Markdown>
{`
On the **left** is the **Text Editor**.
> **This** is where you will *type in your code*.

Continue the tour to learn about the **projects** you'll be coding!
`}
      </Markdown>
    ),
    selector: '#editor-panel',
    position: 'right',
    style: {
      width: 300
    }
  },
  {
    title: 'Curriculum Selector',
    text: (
      <Markdown>
{`
Click this button to access the **Curriculum Module Selection** window.

* Select a ***CodeBot*** or ***micro:bit*** based *course.*
* Enter *share-token* or *license-key* to enable more curriculum *seats.*

### Be sure to select your *Curriculum Module* after this tour!
`}
        <Video autoPlay loop muted controls={[]} 
               style={{backgroundColor: 'white', width:500, border:'solid', borderColor:'lightgray', margin:'auto'}}>
          <source src={ModuleSelectVid} type="video/mp4" />
        </Video>
      </Markdown>
    ),
    selector: '#select-curriculum',
    position: 'top',
    style: {
      width: 600
    }
  },
  {
    title: 'Lesson Panel',
    text: (
      <Markdown>
{`
This is the **Lesson Panel**, with **Step By Step** instructions for each **project**.
> Be sure to **carefully read** these steps!

**Really!** It's not "fluff"

...**every concept** mentioned is *needed* for your **project!**
`}
      </Markdown>
    ),
    selector: '#lesson-content',
    position: 'left',
    style: {
      width: 300
    }
  },
  {
    title: 'XP - Experience Points!',
    text: (
      <Markdown>
{`
Completing projects and gaining skills along the way will earn you **XP**.

Your current **XP** total is shown here.
`}
      </Markdown>
    ),
    selector: '#user-attr',
    position: 'top',
    style: {
      width: 350
    }
  },
  {
    title: 'Progress Bar',
    text: 'Your progress through the steps in each project will be shown by the <b>Progress Bar</b>.',
    selector: '#lesson-progress',
    position: 'top',
    style: {
      width: 200
    }
  },
  {
    title: 'Project Menu',
    text: (
      <Markdown>
{`
You can click the **Project Menu** to jump to different Projects.
`}
       <CheckIcon style={{float:'left', color:'green', margin:10}} />
{`
When you complete all the steps in a Project, it will be shown with a check mark.
`}
      </Markdown>
    ),
    selector: '#project-list',
    position: 'top-right',
    allowClicksThruHole: true,
    style: {
      width: 400
    }
  },
  {
    title: 'Tool Box',
    text: (
      <Markdown>
{`You will collect **coding tools** along the way. Click this button to access them.

### How can you collect tools?
When you see a word that looks like this (blue, with a tool icon):

> ==Debugging==

**Click on it** to learn more, gain **XP**, *and* add it to your **ToolBox**.

> *Go ahead, try it now!*
`}
      </Markdown>
    ),
    selector: '#toolbox-button',
    position: 'top',
    style: {
      width: 350
    }
  },
  {
    title: 'Next Step',
    text: 'Click the <b>NEXT</b> button to move on!',
    selector: '#stepcomplete-jumpstartintro-next',
    position: 'bottom-right',
    allowClicksThruHole: true,
    style: {
      width: 200
    }
  },

]

export class JumpstartIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }

    // Run tour automatically for new users
    this.props.lessons.restoredPromise.then(async () => {
      const runTour = !this.props.lessons.hasTourRun()
      if (runTour === true) {
        this.handleRunTour()
      }
    })
  }

  componentWillUnmount = () => {
  }

  handleRunTour = () => {
    if (this.tour &&
        this.props.lessons.state.currentProjectNum === 0 &&
        this.props.lessons.state.currentLessonNum === 0) {
      this.tour.reset(true)
      this.setState({runTour: true})
    }
  }

  hideTriangle = (doHide) => {
    let elems = document.getElementsByClassName('joyride-tooltip__triangle')
    if (elems.length === 0) {
      window.requestAnimationFrame(this.hideTriangle)
    } else {
      elems[0].style.visibility = doHide ? 'hidden' : null
    }
  }

  hideHole = (doHide) => {
    let elems = document.getElementsByClassName('joyride-hole')
    if (elems.length === 0) {
      window.requestAnimationFrame(this.hideHole)
    } else {
      elems[0].style.boxShadow = doHide ? '0 0 0 9999px rgba(0, 0, 0, 0.5)' : null
      elems[0].style.backgroundColor = doHide ? 'rgba(0, 0, 0, 0.5)' : null
    }
  }

  tourCb = async (ev) => {
    // Proceed to next lesson after tour
    if (ev.type === 'tooltip:before' &&
        ev.index === 0) {
      this.hideTriangle(true)
      this.hideHole(true)
    }
    if (ev.type === 'step:after' &&
        ev.index === 0) {
      this.hideTriangle(false)
      this.hideHole(false)
    }
    if (ev.type === 'step:before' &&
        ev.step.selector === '#stepcomplete-jumpstartintro-next') {
      document.getElementById('stepcomplete-jumpstartintro-next').scrollIntoView()
    }
    if (ev.type === 'terminated') {
      await this.props.lessons.testSetTourRun()  // Set flag indicating tour has run
    }
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={tourCodeSpace}
          run={this.state.runTour}
          callback={this.tourCb}
        />
<Markdown>
{`
## Welcome to CodeSpace! {lesson-title}
___
`}
<br />
<FlyingCody
  wanderRate={7}
/>
{`
### Greetings! {centered}

You are at the beginning of an exciting journey. I'll be your guide as you learn to build real-world projects with **code**.

#### Why learn coding?
* Hey, it's not just for robots anymore!
* Or laptops, mobile phones, and games,...
* Computer *chips* are making lots of things we use every day **smarter**
#### But... *Everything* computers do has to be **coded** by humans like **YOU** {centered}

As you complete this *project-based* course, you'll be learning skills that can be used to program *ANY* computer!
`}
</Markdown>
<div style={{textAlign: 'center'}}>
        <Button
          variant='raised'
          onClick={this.handleRunTour}
          style={{visibility: this.state.runTour ? 'hidden' : 'visible'}}
        >
          Take a Tour of CodeSpace
        </Button>
      </div>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        id="stepcomplete-jumpstartintro"
      />
      </div>
    );
  }
}


export class LegendOverview extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Project Symbols {lesson-title}
___
### These symbols highlight key parts of each step

`}
        <LegendMessageKeyboard>
          When you see this, get your fingers dancing on that keyboard!
        </LegendMessageKeyboard>
        <LegendMessageRun>
          When it's time to RUN your code, you'll see this symbol.
        </LegendMessageRun>
        <LegendMessageDebug>
          You'll learn how CodeSpace helps you debug the code step-by-step!
        </LegendMessageDebug>
        <LegendMessageTry>
          Experiment, practice, play! This means it's time to test your skills by trying out what you've learned "freestyle!"
        </LegendMessageTry>
        <LegendMessageWarning>
          Here there be dragons! This is where you'll look back and say, "Oh yeah, you said not to do that!"
        </LegendMessageWarning>
        <LegendMessageConcept>
          Useful new coding concepts and techniques will be highlighted here.
        </LegendMessageConcept>
        <LegendMessageInteract title="Physical Interaction">
          This symbol means you need to DO something with the hardware you'll be programming.
        </LegendMessageInteract>
        <LegendQuiz
          lessons={this.props.lessons}
        >
          Answer questions here for bonus XP!
        </LegendQuiz>
      </Markdown>
      <StepComplete
        prompt="Got the idea? Click NEXT to move on..."
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class MicrobitIntro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Behold the micro:bit! {lesson-title}
___
The computer you'll build projects with is called the **micro:bit**. {centered}

`}
<div style={{textAlign: 'center'}}>
  <img src={MicrobitFront} alt="microbit front side" style={{width: 200, marginRight: 5}} />
  <img src={MicrobitBack} alt="microbit back side" style={{width: 200}} />
</div>
{`
The BBC micro:bit is a powerful *embedded* computer. It has sensors and buttons for input, and an ==LED== display for output.

Even better, the micro:bit can **connect** to the world around it.

Those metal stripes along the bottom edge are electronic terminals you can wire to sensors, motors, lights, displays, speakers, and more!

What projects can *you* imagine using the **micro:bit** for?

* Control a light show
* Measure temperature and record it
* Operate a robot
* Generate music and sound effects
* Detect motion and activate an alarm

`}
      </Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class MicrobitEsd extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
      <Markdown>
{`
## Careful with your micro:bit! {lesson-title}
___
*A few precautions will keep it safe!* {centered}

`}
<div style={{textAlign: 'center'}}>
  <img src={MicrobitEsdImg} alt="microbit back side" style={{width: 400}} />
</div>
{`
**Static electricity** is a charge that can build up when you walk across carpet in socks or take off a wool sweater.
It causes the jolt and spark that happens sometimes when touching something grounded, like a faucet or lightswitch.

Hold your micro:bit by its **edges**, being gentle with the LEDs and other electronic components.
They're all exposed on the board as with most other **Maker** computers, so you can *really* get to know them.
More on that in the next few pages...

Keep your micro:bit in its case when not in use. It's good practice to touch some grounded metal (desk, doorknob) before
handling the micro:bit to avoid damaging its sensitive components with static electric discharge.

Please use the battery pack and the USB cable provided to power your micro:bit. Do not use portable battery chargers or USB
charging ports (often marked with a lightning bolt or 'SS'), to power your micro:bit. Using these may damage your micro:bit.
`}
      </Markdown>
      <Quiz
        lessons={this.props.lessons}
        prompt="What should you do before handling a micro:bit?"
        xp={5}
        answerRight={"Touch some grounded metal"}
        answerWrong={["Clean it with wet wipes", "Jumping jacks"]}
      />
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

const tourMicrobitBack = [
  {
    title: 'Central Processing Unit (CPU)',
    text: "This tiny chip is where all your CODE will run! It is a self-contained computer, with its own memory banks - and even a wireless interface!",
    selector: '#mb_cpu',
    position: 'bottom-left',
  },
  {
    title: 'Accelerometer',
    text: "Just like a game controller or phone that can sense tilting or shaking, this sensor chip lets the micro:bit detect motion and position.",
    selector: '#mb_accel',
    position: 'bottom-left',
  },
  {
    title: 'USB Connector',
    text: "This USB micro-B connector connects to your PC for programming the micro:bit. It can also be used to power the micro:bit using a USB charger.",
    selector: '#mb_usb_conn',
    position: 'bottom-left',
  },
  {
    title: 'Antenna',
    text: "The micro:bit's antenna for <b>radio</b> communications. This is a 'trace' antenna, meaning it's basically just a short piece of copper 'wiring' tuned to just the right length to send and receive wireless signals at 2.4GHz",
    selector: '#mb_antenna',
    position: 'bottom-left',
  },
  {
    title: 'Compass',
    text: "This sensor detects magnets! It can tell the strength and direction of a magnetic field, either the Earth's or that of a magnet.",
    selector: '#mb_compass',
    position: 'bottom-left',
  },
  {
    title: 'Reboot Button',
    text: "Press this button to <em>reboot</em> the micro:bit",
    selector: '#mb_reboot',
    position: 'bottom-right',
  },
  {
    title: 'USB Interface Processor',
    text: "Like the Main CPU, this is a complete computer on a chip! This chip is only responsible for managing the USB interface.",
    selector: '#mb_usb_ifc',
    position: 'bottom-right',
  },
  {
    title: 'Battery Connector',
    text: "Connection for an external 3 volt battery pack",
    selector: '#mb_batt_conn',
    position: 'bottom-right',
  },
]

class MicrobitTour1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      imgLoaded: false,
    }
  }

  render() {
    return (
      <div style={{
        position: 'relative',
      }}
      ref={el => this.positionRef = el}
      >
        {this.positionRef ? (
          <JoyBlast
            steps={tourMicrobitBack}
            parentMounted={this.state.imgLoaded}
            positionRef={this.positionRef}
          />
        ) : null}

        <Markdown>
  {`
  ## Anatomy of the micro:bit (back) {lesson-title}
  ___

  The micro:bit may be small, but there's LOTS of power in this little circuit board! {centered}
  `}
          <div style={{textAlign: 'center'}}>
            <div style={{display: 'inline-block', position: 'relative', margin: 'auto'}}>
              <img src={MicrobitBack} alt="microbit back side" style={{zIndex: '2'}} onLoad={() => this.setState({imgLoaded: true})}/>

              <div id="mb_cpu" style={{position: 'absolute', top: '107px', left:'86px', width: '2px', height: '2px'}}></div>
              <div id="mb_accel" style={{position: 'absolute', top: '264px', left:'40px', width: '2px', height: '2px'}}></div>
              <div id="mb_compass" style={{position: 'absolute', top: '219px', left: '38px', width: '2px', height: '2px'}}></div>
              <div id="mb_usb_conn" style={{position: 'absolute', top: '23px', left: '212px', width: '2px', height: '2px'}}></div>
              <div id="mb_antenna" style={{position: 'absolute', top: '32px', left: '61px', width: '2px', height: '2px'}}></div>
              <div id="mb_reboot" style={{position: 'absolute', top: '37px', left: '296px', width: '2px', height: '2px'}}></div>
              <div id="mb_usb_ifc" style={{position: 'absolute', top: '126px', left: '335px', width: '2px', height: '2px'}}></div>
              <div id="mb_batt_conn" style={{position: 'absolute', top: '40px', left: '372px', width: '2px', height: '2px'}}></div>
            </div>
          </div>
          <br />
          <LegendMessageInteract
            title="Explore Your micro:bit"
          >
            Hover over the red tags and learn more about the electronic components that give the micro:bit its super-powers.
          </LegendMessageInteract>
          <StepComplete
            lessons={this.props.lessons}
            btnNext={true}
          />

        </Markdown>

      </div>
    )
  }
}

const tourMicrobitFront = [
  {
    title: 'Button A - LEFT',
    text: (
      <Markdown>
{`A *momentary push button* you can **read** from your code.
In computer lingo this is an **input** device.
`}
      </Markdown>
    ),
    selector: '#mb_button_a',
  },
  {
    title: 'Button B - RIGHT',
    text: (
      <Markdown>
{`A *momentary push button* you can **read** from your code.
In computer lingo this is an **input** device.
`}
      </Markdown>
    ),
    selector: '#mb_button_b',
  },
  {
    title: 'LED Display',
    text: (
      <Markdown>
      {
`The micro:bit's LED display has 5x5 = 25 LEDs that you can control to make images, letters, and animations.
`}
        <img src={MicrobitHeart} alt="microbit display" width={100} style={{margin: 'auto', display: 'block'}} />
      </Markdown>

    ),
    selector: '#mb_display',
  },
  {
    title: 'Edge Connector',
    text: (
      <Markdown>
      {
`There are 25 electrical connections along the bottom edge of the micro:bit.

These connections (called "pins") give your **code** the ability to *sense* and *control* things in the outside world!
`}
      </Markdown>

    ),
    selector: '#mb_edge_conn',
  },
]

class MicrobitTour2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      imgLoaded: false,
    }
  }

  render() {
    return (
      <div style={{
        position: 'relative',
      }}
      ref={el => this.positionRef = el}
      >
        {this.positionRef ? (
        <JoyBlast
          steps={tourMicrobitFront}
          parentMounted={this.state.imgLoaded}
          positionRef={this.positionRef}
        />
      ) : null}
        <Markdown>
  {`
  ## Anatomy of the micro:bit (front) {lesson-title}
  ___

  The other side is the *front* of the board where the ==user interface== lives!
  {centered}
  `}
          <div style={{textAlign: 'center'}}>
            <div style={{display: 'inline-block', position: 'relative', margin: 'auto'}}>
              <img src={MicrobitFront} alt="microbit front side" style={{zIndex: '2'}} onLoad={() => this.setState({imgLoaded: true})}/>

              <div id="mb_button_a" style={{position: 'absolute', top: '186px', left:'40px', width: '2px', height: '2px'}}></div>
              <div id="mb_button_b" style={{position: 'absolute', top: '186px', left:'375px', width: '2px', height: '2px'}}></div>
              <div id="mb_display" style={{position: 'absolute', top: '194px', left:'204px', width: '2px', height: '2px'}}></div>
              <div id="mb_edge_conn" style={{position: 'absolute', top: '340px', left:'114px', width: '2px', height: '2px'}}></div>
              </div>
          </div>
          <br />
          <LegendMessageInteract
            title="Explore Your micro:bit"
          >
             Keep exploring! There's more information behind those red tags...
          </LegendMessageInteract>
        </Markdown>
        <StepComplete
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

export class MicrobitConnect extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{`
## Connect the micro:bit to your PC {lesson-title}
___
`}
{/* TODO This image is is of unknown license, remove before selling product. */}
<img src={UsbCable} alt="" style={{display: 'block', margin: '5px', height: '150px', float:'right', marginBottom:20}} />
{`### Now, use the ==USB== cable to connect the micro:bit to your Computer.
`}
<br />
<LegendMessageWarning
  title="Note"
>
  You may see a *window* pop-up when you plug in the micro:bit.

  Feel free to close this window, you won't need it for CodeSpace.
</LegendMessageWarning>
  <StepComplete
    prompt={`Continue once the micro:bit is connected to your computer.`}
    lessons={this.props.lessons}
    btnNext={true}
  />
      </Markdown>
      </div>
    )
  }
}

export class MicrobitFirmwareCheck extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{`
## Using other micro:bits with CodeSpace {lesson-title}
___

#### Click the sentence below which describes your micro:bit.

`}
<ExpansionPanel>
  <ExpansionPanelSummary
    expandIcon={<ExpandMoreIcon />}
  >
    I got my micro:bit from Firia Labs!
  </ExpansionPanelSummary>
  <ExpansionPanelDetails style={{flexDirection: 'column'}}>
    <StepComplete
      prompt={`It should be ready to go, let's move on to the next step!`}
      lessons={this.props.lessons}
      btnNext={true}
    />
  </ExpansionPanelDetails>
</ExpansionPanel>
<ExpansionPanel>
  <ExpansionPanelSummary
    expandIcon={<ExpandMoreIcon />}
  >
    I got my micro:bit from somewhere else!
  </ExpansionPanelSummary>
  <ExpansionPanelDetails style={{flexDirection: 'column'}}>
    <Markdown>
{`That's OK! Click the button below and follow all directions to update both MicroPython *and* Maintenance on your micro:bit.`}
    </Markdown>
    <Button
      onClick={() => {
        this.props.lessons.props.onShowFirmwareClick()
      }}
      variant='raised'
      color='primary'
      style={{
        alignSelf: 'center',
        margin: '10px',
      }}
    >
      Upgrade Instructions
    </Button>
    <StepComplete
      prompt={`Once your microbit is updated, move on to the next step!`}
      lessons={this.props.lessons}
      btnNext={true}
    />
  </ExpansionPanelDetails>
</ExpansionPanel>
      </Markdown>
      </div>
    )
  }
}

var tourUsbConnected = []

var tourUsbIcon = [
  {
    title: 'USB Connection',
    text: (
      <div>
        <p>Your micro:bit's connection status is shown by this icon. When the micro:bit is connected, this icon will appear darker.</p>
      </div>
    ),
    selector: '#usb-connected',
    position: 'bottom-left',
    allowClicksThruHole: true,
  },
]

var tourUsbVideo = [
  {
    title: 'USB Dialog',
    text: (
      <div>
        <p>Now the browser will show a dialog for the first connection. Make sure to select your micro:bit as shown in this video.</p>
        {WindowsOldVersionUSBnote}
      </div>
    ),
    selector: '#chrome-webusb-connect-vid',
    position: 'left',
    allowClicksThruHole: true,
  },
]

var tourUsbAlreadyConnected = [
  {
    title: 'USB Connection',
    text: (
      <div>
        <p>A micro:bit appears to already be connected! This icon will be a lighter color when the micro:bit is disconnected.</p>
      </div>
    ),
    selector: '#usb-connected',
    position: 'bottom-left',
    allowClicksThruHole: true,
  },
]

export class MicrobitWebUsbConnect extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runlinkTour: false,
    }
  }

  tourCb = (ev) => {
    if (ev.type === 'step:before' &&
        ev.step.selector === '#chrome-webusb-connect-vid') {
      document.getElementById('chrome-webusb-connect-vid').scrollIntoView()
    }
    if (ev.type === 'finished') {
      serComm.request()
    }
  }

  handleLinkUsb = () => {
    if (serComm.isConnected()) {
      tourUsbConnected = tourUsbAlreadyConnected
    } else {
      tourUsbConnected = tourUsbIcon.concat(tourUsbVideo)
    }
    if (this.linkTour) {
      this.linkTour.reset(true)
      this.setState({runlinkTour: true})
    }
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.linkTour = inst}}
          steps={tourUsbConnected}
          run={this.state.runlinkTour}
          callback={this.tourCb}
        />
      <Markdown>
{`
## Link the micro:bit to CodeSpace {lesson-title}
___
#### Before your micro:bit can be used with CodeSpace, it must be linked to your browser.
`}
<Video
  autoPlay muted loop
  id='chrome-webusb-connect-vid'
  controls={[]}
  style={{backgroundColor: 'white', float:'right', width:250, margin:20}}
>
  <source src={UsbConnect} type="video/mp4" />
</Video>

{`
### Connection Steps
1. Make sure the USB cable is connected *both* to your PC and the micro:bit
2. Click the CONNECT USB button below
3. Select "**CodeBot Py REPL**" or "**LPC1768**" from the *device list* that pops up

`}
<div style={{textAlign: 'center'}}>
  <Button
    variant='raised'
    color='primary'
    onClick={this.handleLinkUsb}
  >
    Connect USB
  </Button>
</div>
<br />
<LegendCollapsed
  summary="First time connecting and NOT a Firia Labs micro:bit?"
  summaryStyle={{fontSize:'1em', fontWeight:'normal', fontStyle:'italic', marginLeft:'8px'}}
  details={
    <Markdown>
{`
If your **micro:bit** is *not* from a ***Firia Labs*** kit, and hasn't connected to *CodeSpace* before, you'll need to
<a href="https://firialabs.com/blogs/support-forum/full-upgrade-or-onboarding-a-new-micro-bit" target="_blank" rel="noopener noreferrer">update</a>
its firmware first.
`}
    </Markdown>
  }
>
</LegendCollapsed>
<div style={{display: this.state.runlinkTour ? 'block' : 'none', clear: 'both'}}>
  <StepComplete
    prompt={`Move on once the micro:bit is linked.`}
    lessons={this.props.lessons}
    btnNext={true}
  />
</div>
      </Markdown>
      </div>
    )
  }
}

const tourNewFile = [
  {
    title: 'Set File Name',
    text: (
      <Markdown>
      {
`Type **Heart1** into this box. Do it **NOW**, *really!*

*This will be the name of your first program.*
* You can always *rename* the file you're working on by changing the name here.
`     }
      </Markdown>
    ),
    selector: '#code-filename',
    position: 'bottom-left',
    allowClicksThruHole: true,
  },
//   {
//     title: 'Move file to a Folder',
//     text: (
//       <Markdown>
//       {
// `Folders are **good**!
//
// You don't **have** to use a Folder for this lesson, but as a *coder* you'll want to
// organize all the awesome projects you're making!
//
// When your Google Drive root directory starts getting crowded, it's a good idea to
// organize your files into Folders. If that happens, click here to choose a Folder for your current file.
//
// Later, just use the File-->Open menu to browse to your code in Drive and load it back into the
// Editor panel.
// `     }
//       </Markdown>
//     ),
//     selector: '#code-folder-move',
//     position: 'bottom-left',
//     allowClicksThruHole: false,
//   },
//   {
//     title: 'Open a New File',
//     text: (
//       <Markdown>
//       {
// `If you want to switch to a **new** file, use the File-->New menu option.
//
// Your old file will still be there in Drive, and you'll have a fresh new workspace
// to code in!
// `     }
//       </Markdown>
//     ),
//     selector: '#menu-file',
//     position: 'bottom-left',
//     allowClicksThruHole: false,
//   },
]

class NewFile extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  handleRunTour = () => {
    this.tour.reset(true)
    this.setState({runTour: true})
    let elem = document.getElementById('code-filename')
    elem.focus()
    let selections = window.getSelection()
    selections.empty()
    selections.selectAllChildren(elem);
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={tourNewFile}
          run={this.state.runTour}
        />
        <Markdown>
        {
`## Save The Code! {lesson-title}
___
When you type code into the *Editor* panel, it is automatically saved to **Google Drive**.
`}

<LegendMessageConcept
  title="Concept: Files"
>
  Just like other documents, **code** is stored in *files* on a computer. Each code file should have a
  **name** that states its purpose.
</LegendMessageConcept>
{`
Press the button below to get started with **Files in CodeSpace**.
`
        }
        </Markdown>
        <div style={{textAlign: 'center'}}>
        <Button
          variant='raised'
          color='primary'
          onClick={this.handleRunTour}
        >
          Name That File!
        </Button>
      </div>
      <div style={{display: this.state.runTour ? 'block' : 'none'}}>
      <StepComplete
        prompt="It's time to write some code!"
        lessons={this.props.lessons}
        btnNext={true}
      />
      </div>
      </div>
    )
  }
}

export const tourFirstRun = [
  {
    title: 'The Run Button',
    text: (
      <Markdown>
      {
`Press the **button above** to:
* send your code to the micro:bit
* ...and **run** it!
`     }
      <Video autoPlay loop muted controls={[]} style={{backgroundColor: 'white'}}>
        <source src={RunCode} type="video/mp4" />
      </Video>
      {
`WooHoo!! Your code is **on the micro:bit**
`     }
      </Markdown>
    ),
    selector: '#tb-run',
    position: 'bottom-left',
    allowClicksThruHole: true,
    style: {
      width: 400
    }
  },
]

export const tourStop = [
    {
      title: 'The Stop Button',
      text: (
  <Markdown>
  {
`Tells the micro:bit to STOP your code.

Go ahead and **click** this button, since it seems your microbit is currently running.
`     }
  </Markdown>
      ),
      selector: '#tb-stop',
      position: 'right',
      allowClicksThruHole: true,
      style: {
        width: 400
      }
    },
]

export class ShowSomeHeart extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
    this.tourArray = []
  }

  handleRunTour = () => {
    if (this.props.lessons.props.mbState !== mb.state.IDLE || this.props.lessons.props.mbMode === mb.mode.DEBUG)
    {
      this.tourArray = tourFirstRun.concat(tourStop)
    } else {
      this.tourArray = tourFirstRun
    }
    this.tour.reset(true)
    this.setState({runTour: true})
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={this.tourArray}
          run={this.state.runTour}
        />
        <Markdown>
{`
## Show Some Heart! {lesson-title}
___
Now it's time for **you** to type in some code!

`}
<LegendMessageWarning
  title={'Note! Before you start coding'}
>
{`* Capitalization matters! Your code is **case sensitive**.
* ==Punctuation== is important!

> (*Relax*, you're not going to break anything, but programming languages are very strict!)
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
<Markdown>
{`
Click on the **Editor** panel to the left, and enter the code shown **below**.
* Don't worry about the ==colors==.
* Use **two** separate lines - *be sure to press **ENTER** after the **&ast;** below!*
`}<div className='shiny'>
<Markdown>
{`
\`\`\`
from microbit import *
display.show(Image.HEART)
\`\`\`
`}
</Markdown>
</div>
</Markdown>
</LegendMessageKeyboard>
          <LegendMessageRun>
            <br />
            <div style={{textAlign: 'center'}}>
              <Button
                variant='raised'
                onClick={this.handleRunTour}
              >
                Show me how to RUN the code...
              </Button>
            </div>
            <br />
          </LegendMessageRun>
          <StepComplete
            prompt="Do you see a HEART image on the micro:bit LED display?"
            xp={10}
            successMessage=
{`## AWESOME!! {centered}
### The programming language you're using is called "Python".
#### Proceed to the *Next* project to continue your journey as a *Python programmer*!
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['display.show']}
            reqArgs={['Image.HEART']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
          />

        </Markdown>
      </div>
    )
  }
}

class ShowOthers extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## More Images {lesson-title}
___

**The micro:bit has many more ==built-in Images==!**

The program below shows a different Image. It's just a *small* change to the code, so
only the line that you'd need to change is shown.
### Examples will *BLUR* parts you already know!
* Click on the code below to reveal the *whole* program.
* It will blur again when the mouse pointer leaves the code area.
\`\`\`
from microbit import *
$display.show(Image.MUSIC_QUAVERS)
\`\`\`

*(curious about the ==__underscores==?)*  {centered}

`}
<LegendMessageKeyboard>
{`
Now <b>modify</b> your code to show a different ==Image==!
`}
</LegendMessageKeyboard>
{`
\`\`\` collapsed Click here for a hint...
from microbit import *
display.show(Image.DUCK)
\`\`\`
`}
<LegendMessageTry title={`Try a few different images!`}>
{`
* Edit your code and press **Run** to test it out
* Move to the Next step when you're ready
`}
</LegendMessageTry>
        </Markdown>
        <StepComplete
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class OnYourWay extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Code ON! {lesson-title}
___

### You've completed the first project!
...and you're at the start of a fantastic **adventure**. From this small first project, your journey will take you to greater
heights - more projects are ahead to *challenge* and *amaze* you!
`}
        <img src={Journey} alt="" width={350} style={{margin: 'auto', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="A world of possibilities awaits you... Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}


// Add static step IDs to uniquely identify the steps (minify nixes class names)
JumpstartIntro.stepId = 'JumpstartIntro'
LegendOverview.stepId = 'LegendOverview'
MicrobitIntro.stepId = 'MicrobitIntro'
MicrobitEsd.stepId = 'MicrobitEsd'
MicrobitTour1.stepId = 'MicrobitTour1'
MicrobitTour2.stepId = 'MicrobitTour2'
MicrobitConnect.stepId = 'MicrobitConnect'
//MicrobitFirmwareCheck.stepId = 'MicrobitFirmwareCheck'
MicrobitWebUsbConnect.stepId = 'MicrobitWebUsbConnect'
NewFile.stepId = 'NewFile'
ShowSomeHeart.stepId = 'ShowSomeHeart'
ShowOthers.stepId = 'ShowOthers'
OnYourWay.stepId = 'OnYourWay'

export const jumpStartIntro = [
  JumpstartIntro,
  LegendOverview,
  MicrobitIntro,
  MicrobitEsd,
  MicrobitTour1,
  MicrobitTour2,
  MicrobitConnect,
  //MicrobitFirmwareCheck,
  MicrobitWebUsbConnect,
  NewFile,
  ShowSomeHeart,
  ShowOthers,
  OnYourWay,
]
