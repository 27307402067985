import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'
import Button from 'material-ui/Button'
import { CircularProgress } from 'material-ui/Progress'
import ContentPasteIcon from 'material-ui-icons/ContentPaste'
import DeleteIcon from 'material-ui-icons/Delete'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from 'material-ui/IconButton'
import Tooltip from 'material-ui/Tooltip'
import Snackbar from 'material-ui/Snackbar'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog'
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'material-ui/Table'
import { MessageDialog } from './Dialogs'
import LicenseDetails from './LicenseDetails'
import { licensor } from './CodeSpaceLicensor'
import { moduleDescription, moduleIndex } from './lessons/CurriculumModules'


class ActivationCodeDisplay extends Component {
  static propTypes = {
    text: PropTypes.string,
    label: PropTypes.string,
  }

  static defaultProps = {
    text: '',
    label: '',
  }

  state = {
    showClipboardCopiedNotice: false,
  }

  openClipboardNotice = () => {
    this.setState({
      showClipboardCopiedNotice: true,
    })
  }

  closeClipboardNotice = () => {
    this.setState({
      showClipboardCopiedNotice: false,
    })
  }

  render() {
    return (
      <div
        id={this.props.id ? this.props.id : null}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <div style={{
          fontSize: 12,
          fontStyle: 'italic',
        }}
        >
          {this.props.label}
        </div>
        <div style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
          border: '1px solid #CCC',
          height: '25px',
        }}
        >
          <div
            token-text='true'
            style={{
          flex: '1 1 auto',
          textAlign: 'center',
          verticalAlign: 'middle',
          lineHeight: '25px',
          padding: '0 4px 0 4px',
        }}
          >
            {this.props.text}
          </div>
          <CopyToClipboard
            text={this.props.text}
          >
            <Tooltip title="Copy to Clipboard">
              <div style={{
              backgroundColor: '#CCC',
              height: '25px',
              width: '25px',
            }}
              >
                <IconButton
                  onClick={this.openClipboardNotice}
                  style={{
                  height: '21px',
                  width: '21px',
                  margin: '2px',
                  // backgroundColor: 'rgb(104, 208, 52)',
                }}
                >
                  <ContentPasteIcon style={{
                  height: '18px',
                  width: '18px',
                }}
                  />
                </IconButton>
              </div>
            </Tooltip>
          </CopyToClipboard>

          <Snackbar
            onClose={this.closeClipboardNotice}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.showClipboardCopiedNotice}
            message={<span>Share token copied to clipboard!</span>}
            autoHideDuration={4000}
          />

        </div>
      </div>
    )
  }
}


class AddLicenseDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      dialogValue: '',
      isAdding: false,
      addingFailed: false,
    }
    this.errorText = ""
  }

  requestClose = () => {
    this.setState({
      dialogValue: '',
      isAdding: false,
      addingFailed: false,
    })
    this.props.onClose()
  }

  handleLineChange = (e) => {
    let line = e.target.value
    // Remove whitespace
    line = line.trim()
    this.setState({
      dialogValue: line,
      addingFailed: false,
    })
  }

  onValidationFailed = (errorText = 'Failed to validate') => {
    this.errorText = errorText
    this.setState({
      addingFailed: true,
      isAdding: false,
    })
  }

  doAddLicense = async () => {
    // console.log("Add license: ", this.state.dialogValue)
    this.setState({
      isAdding: true,
    })

    const added = await licensor.addLicense(this.state.dialogValue)

    if (added) {
      // If we don't have a Share Token, generate one now.
      if (!licensor.lastGenActivationCode || !licensor.lastGenActivationCode.length) {
        // Go ahead and generate a Share Token
        const gotToken = await licensor.generateShareToken()
        if (gotToken) {
          // Install new share token for this user (avoids confusion for common use case)
          const newToken = licensor.lastGenActivationCode.join('')
          const added = await licensor.listSeats(newToken)

          if (added.length > 0) {
            // Success!
            this.requestClose()
          } else {
            this.onValidationFailed("Failed to validate new Share Token")
          }
        }
        else {
          this.onValidationFailed("Failed to get Share Token for license")
        }
      } else {
        // Success (keeping existing share token)!
        this.requestClose()
      }
    } else {
      this.onValidationFailed()
    }
  }

  render() {
    return (
      <Dialog
        id='add-license-dialog'
        open={this.props.open}
      >
        <DialogTitle>Add License</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the license key you received from FiriaLabs to add the license to your account.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            id='add-license-input'
            label="License Key"
            style={{ marginTop: '1em' }}
            error={this.state.addingFailed}
            helperText={this.state.addingFailed ? this.errorText : null}
            disabled={this.state.isAdding}
            value={this.state.dialogValue}
            onChange={this.handleLineChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.doAddLicense()
                ev.preventDefault()
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id='add-license-validate'
            onClick={this.doAddLicense}
            color="primary"
            disabled={this.state.isAdding}
            variant='raised'
            style={{
              transition: 'all 0.1s linear',
              transform: this.state.dialogValue ? 'scaleY(1)' : 'scaleY(0)',
              // height: this.state.activationDialogValue ? '36px' : '0px',
            }}
          >
            {this.state.isAdding ? (
              <div style={{ display: 'flex', height: '1.4em' }}>
                <CircularProgress
                  style={{
                    margin: 'auto',
                    width: 20,
                    height: 20,
                    color: 'green',
                  }}
                />
              </div>
            ) : 'Validate'}
          </Button>
          <Button
            onClick={this.requestClose}
            disabled={this.state.isAdding}
            variant='raised'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

class GenerateTokenDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onGenerate: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Share Tokens allow others to activate CodeSpace features with seats from your license.
          If you regenerate your share token, everyone using the current one must enter the NEW share token next time they login or change curriculum modules.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
          >
            <Button
              id='confirm-regenerate-button'
              onClick={this.props.onGenerate}
              variant='raised'
            >
              Regenerate
            </Button>
            <Button
              onClick={this.props.onCancel}
              color="primary"
              variant='raised'
            >
              Go Back
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

export class LicenseManagerDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      openAddLicenseDialog: false,
      openGenerateTokenDialog: false,
      isBusy: false,
      generatingNewToken: false,
      deletingId: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      licensor.refreshUserLicenses()
    }
  }

  requestClose = () => {
    if (!this.state.isBusy) {
      this.props.onClose()
    }
  }

  removeLicense = async (licenseKey) => {
    this.setState({
      isBusy: true,
      deletingId: licenseKey,
    })

    await licensor.removeLicense(licenseKey)

    this.setState({
      isBusy: false,
      deletingId: '',
    })
  }

  openGenerateTokenDialog = () => {
    this.setState({ openGenerateTokenDialog: true })
  }

  closeGenerateTokenDialog = () => {
    this.setState({ openGenerateTokenDialog: false })
  }

  generateNewShareToken = async () => {
    this.setState({
      openGenerateTokenDialog: false,
      isBusy: true,
      generatingNewToken: true,
    })
    await licensor.generateShareToken()
    this.setState({
      isBusy: false,
      generatingNewToken: false,
    })
  }

  openAddLicenseDialog = () => {
    this.setState({ openAddLicenseDialog: true })
  }

  closeAddLicenseDialog = () => {
    this.setState({
      openAddLicenseDialog: false,
    })
  }

  roundButton = (text, color, onClick, id) => (
    <Button
      id={id ? id : null}
      variant='fab'
      color={color}
      onClick={onClick}
      style={{
        marginRight: 10,
        fontSize: 25,
        color: '#FFF',
      }}
      mini
    >
      {text}
    </Button>
  )

  translateProductName = (licenseName) => {
    // Fixup legacy productName
    const fixedLicName = licenseName === 'CodeSpace' ? 'StartCodingModule' : licenseName
    try {
      return moduleDescription[moduleIndex.get(fixedLicName)].name
    } catch (err) {
      console.log("Unmapped licenseName: ", licenseName)
    }
    return fixedLicName
  }


  renderTable = licenses => (
    licenses.length ?
      <Table id='license-table' style={this.styles.table}>
        <TableHead>
          <TableRow>
            <TableCell style={this.styles.cell}>Product</TableCell>
            <TableCell style={this.styles.cell}>Key</TableCell>
            <TableCell style={this.styles.cell}>Seats</TableCell>
            <TableCell style={this.styles.cell}>Expiration</TableCell>
            <TableCell style={this.styles.cell}>In-Use</TableCell>
            <TableCell style={this.styles.cell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses.map(val => (
            val.productName === 'PythonWithRobots' ? null :
            <TableRow key={val.licenseKey}>
              <TableCell id={`${val.licenseKey}-cell-product`} style={this.styles.cell}>{this.translateProductName(val.productName)}</TableCell>
              <TableCell id={`${val.licenseKey}-cell-id`} style={this.styles.cell} title={val.licenseKey}>{val.licenseKey.slice(0, 8)+'...'}</TableCell>
              <TableCell id={`${val.licenseKey}-cell-seats`} style={this.styles.cell}>{val.maxSeats}</TableCell>
              <TableCell id={`${val.licenseKey}-cell-expiration`} style={this.styles.cell}>{"Annual"}</TableCell>
              <TableCell id={`${val.licenseKey}-cell-in-use`} style={this.styles.cell}>{val.activeUsersCount}</TableCell>
              <TableCell id={`${val.licenseKey}-cell-delete`} style={this.styles.cell}>
                {this.state.deletingId === val.licenseKey ? (
                  <div style={{ display: 'flex', height: '2em' }}>
                    <CircularProgress
                      style={{
                            margin: 'auto',
                            width: 20,
                            height: 20,
                            color: 'green',
                          }}
                    />
                  </div>
                    ) : (
                      <Tooltip title="Remove this license">
                        <div style={{
                          height: '25px',
                          width: '25px',
                        }}
                        >
                          <IconButton
                            disabled={this.state.isBusy}
                            id={val.licenseKey}
                            onClick={() => this.removeLicense(val.licenseKey)}
                            style={{
                              height: '21px',
                              width: '21px',
                              margin: '2px',
                            }}
                          >
                            <DeleteIcon style={{
                              height: '18px',
                              width: '18px',
                            }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
              </TableCell>
            </TableRow>
              ))}
        </TableBody>
      </Table>
      :
      <div
        id='no-licenses-msg'
        style={{
          padding: '20px 0 20px',
          fontSize: '20px',
        }}
      >
          No license keys are assigned to your account.
      </div>
  )

  styles = {
    table: {
      marginTop: '1em',
      marginBottom: '1em',
    },
    cell: {
      paddingRight: '10px',
    },
  }

  render() {
    return (
      <LicenseDetails render={({ licenses, lastGenTokenText }) => (
        <MessageDialog
          open={this.props.open}
          title="Manage Licenses"
          onClose={this.requestClose}
          id={this.props.id ? this.props.id : null}
        >
          <DialogContent>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              position: 'absolute',
              top: '14px',
              right: '24px',
            }}
            >
              {this.roundButton('+', 'primary', this.openAddLicenseDialog, 'add-license-button')}
              <div style={{
                display: 'inline-block',
                fontSize: 20,
              }}
              >
              Add License
              </div>
            </div>

            <div>
            CodeSpace <em>License Keys</em> unlock <b>curriculum module</b> seats to share with a number of students.<br /><br />
             Your <b>Share Token</b> allows users to access all your license seats without disclosing the keys.
            </div>

            {licenses ? this.renderTable(licenses) :
              (<div
                style={{
                  padding: '20px 0 20px',
                  fontSize: '20px',
                }}
              >
                Fetching licenses...
              </div>)
            }

            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
            >
              <Button
                id='generate-token-button'
                disabled={!licenses || !licenses.length || this.state.isBusy}
                onClick={this.openGenerateTokenDialog}
                color="primary"
                variant='raised'
              >
                {this.state.generatingNewToken ? (
                  <div style={{ display: 'flex', height: '2em' }}>
                    <CircularProgress
                      style={{
                      margin: 'auto',
                      width: 20,
                      height: 20,
                      color: 'green',
                    }}
                    />
                  </div>
                ) : <div>Generate Share Token</div>}
              </Button>

              <ActivationCodeDisplay label='Generated Share Token:' text={licenses && licenses.length ? lastGenTokenText : ''} id='share-token-text' />
            </div>

            <AddLicenseDialog
              open={this.state.openAddLicenseDialog}
              onClose={this.closeAddLicenseDialog}
            />

            <GenerateTokenDialog
              open={this.state.openGenerateTokenDialog}
              onCancel={this.closeGenerateTokenDialog}
              onGenerate={this.generateNewShareToken}
            />

          </DialogContent>
          <DialogActions>
            <Button
              id='license-manager-done-button'
              disabled={this.state.isBusy}
              onClick={this.props.onClose}
              color="primary"
              variant='raised'
            >
            Done
            </Button>
          </DialogActions>
        </MessageDialog>
      )}
      />
    )
  }
}
