
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'

import SolarTrackingIntroImg from './assets/iStock-699871490.jpg'
import FinalSolarTrackingImg from './assets/iStock-1172893099.jpg'

import AdvancedDebugImg from '../PythonWithRobots/assets/advanced_debug.jpg'
import ReplStartImg from '../PythonWithRobots/assets/repl_start.jpg'
import PhotoSensorImg from './assets/peripherals/pk_light.jpg'
import DebouncingImg from './assets/iStock-638610308.jpg'
import SolarStatesImg from './assets/solar_states.jpg'
import SolarStateDiagramImg from './assets/solar_state_diagram_start.jpg'
import FullSolarStateDiagramImg from './assets/solar_state_diagram.jpg'

import PwmImg from './assets/pwm.jpg'
import AileronImg from './assets/iStock-1170697662.jpg'

import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'

import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'

import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
  LegendMessageDebug,
//  LegendMessageTry,
} from '../Legends'

export const SolarTrackingImg = GoogleClassroomImg

class ProjectSolarTracking extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Solar Tracking! {lesson-title}
___
### This project shows you how to operate a 180 positional servo to track the sun with solar panels!
`}
<img src={SolarTrackingIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The space ship needs to harvest power from the sun.
* The more efficient the solar panels can be the better!

**Rotating the panels to track the sun can improve power generation immensely.**

**Project: Solar Tracking** will guide you through turning solar panels to track the sun.

#### Project Goals:
* Learn about 180 Positional ==Servos==
* Understand the capabilities of a 180 Servo
* Add a light sensor to detect sun intensity
* Learn how to use the REPL
* Rotate the ship's solar panels to the sun's general location!!
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

class PositionalServo extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## 180 Servo {lesson-title}
___
#### The ship's solar panels can be rotated to track the sun. {centered}
`}
<LegendMessageInteract title="Start with a clean slate!" >
{`
1. Disconnect all Peripherals from the ==octopus:bit==.
2. Create a new file (click **File** menu at top-left of screen)
    * Name it **SolarTracking**
`}
</LegendMessageInteract>
{`
#### Now attach the 180 servo!
* The 180 ==servo== will rotate the solar panel to track the sun.
`}
<LegendOctoHeader 
  pin={0}
  peripheral={3} // 3 = 180 Servo
  markdown={`
  Connect the **180 Servo** to **position 0** on your ==octopus:bit==!

  **NOTE:** Your ==servo== will have orange, red, and brown wires.  Make sure:
  * The **ORANGE** wire is connected to the **YELLOW** pin
  * The **RED** wire is connected to the **RED** pin
  * The **BROWN** wire is connected to the **BLACK** pin
  `}
/>
{`
**Positional Servos could have many REAL uses on a space mission:**
* Rotating solar panels
* Controlling robotic arms
* Moving latches or locks
* Positioning devices or antennas

**180 Servos** have a sense of **position** but cannot rotate a full 360 degrees.
`}

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to drive your 180 Servo!"
      />
    </div>
  )
  }
}

class PositionDutyCycles extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## What is Your Position? {lesson-title}
___
`}
<img src={PwmImg} alt=""
    style={{
      width: '50%', margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
#### What are the duty cycle settings for the 180 servo? {centered}

The duty cycle for a 180 servo determines which position it is in.
* Your ==servo== is designed to operate with a duty cycle between 0.5 milliseconds and 2.5 milliseconds.

**This table shows duty cycles with position:**

| Duty Cycle (ms) | Position             |
|-----------------|-----------------------|
|       0.5       |    90 Degrees Clockwise          |
|       1.0       |    45 Degrees Clockwise          |
|       1.5       |    Centered            |
|       2.0       |    45 Degrees Counterclockwise   |
|       2.5       |    90 Degrees Counterclockwise   |

Add a function to set the **duty cycle** for your servo.
\`\`\`
def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)
\`\`\`
`}
<LegendMessageWarning>
{`
Your servo may **fail** to operate at a **2.5 ms** duty cycle using this \`set_servo\` function.
* The servo has a maximum limit of 2.5 milliseconds.
* You could exceed the maximum parameters of the servo due to rounding issues.

**This is not a big deal!**
* The servo will just not move if the limit is exceeded.

You can always set your maximum to 2.4 milliseconds OR round your value down to the nearest full number.
`}
</LegendMessageWarning>
<LegendMessageKeyboard>
{`
Use your new function to set the servo motor to 45 degrees counterclockwise for 5 seconds and then 45 degrees clockwise.
\`\`\`
from microbit import *

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

# 45 degrees counterclockwise
set_servo(2.0) 

sleep(5000)

# 45 degrees clockwise
set_servo(1.0) 
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Did your servo move to 2 different, known positions?"
            xp={5}
            successMessage=
{`## Positional Awareness! {centered}
### You now understand how to control the 180 servo!
`}
            reqImports={['microbit']}
            reqCalls={['sleep','pin0.write_analog','pin0.set_analog_period','set_servo']}
            reqArgs={['20','5000','1.0','2.0']}
            reqFuncdefs={['set_servo']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class FightForPosition extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Stay in Position! {lesson-title}
___
#### The 180 servo will always strive to stay in position! {centered}
`}
<LegendMessageConcept>
<Markdown>
{`
**There is no OFF position for the 180 ==servo== like there is for a 360 ==servo==.**
* The 180 servo is always working to stay in position
* If you push it either direction it will always come back to its set position
`}
<img src={AileronImg} alt=""
    style={{
      width: '50%', margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
**This is great for a system like a flap on an airplane!**
* When the pilot moves the flap to a certain position he or she expects it to stay there
* They do not want the wind rushing onto the flap to move it around
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageWarning>
{`
On the other hand...

**It is normal for the ==servo== to continue to make small movements and noise even after it gets in position.**
* The 180 servo does not turn OFF while sending it a signal.

The only way to guarantee that the 180 servo will stop moving is to stop the PWM signal completely
\`\`\`
pin0.write_analog(0)
\`\`\`

But... This will also cause the ==servo== to stop striving for position!
`}
</LegendMessageWarning>
<LegendMessageRun />
<LegendMessageInteract title="Push on the servo and try to move it to a different position." />
<LegendMessageKeyboard>
{`
Now change your code to send the servo no signal.
\`\`\`
from microbit import *

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

set_servo(0) 
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Push on the servo and try to move it to a different position." />
<Quiz
        lessons={this.props.lessons}
        prompt="When will a positional servo stop moving to the correct position?"
        xp={5}
        answerRight={"When you stop sending a signal"}
        answerWrong={["Once it gets in position", "After a few seconds"]}
      />
</Markdown>
<StepComplete
            prompt="Did your servo move freely after you stopped sending it a signal?"
            xp={5}
            successMessage=
{`## Staying Put! {centered}
### 180 Servos will dance until the music stops!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','set_servo']}
            reqArgs={['20','0']}
            reqFuncdefs={['set_servo']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}

class PercentPosition extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Percent Position {lesson-title}
___
#### It is not very straightforward to set ==servo== position based on a millisecond duty cycle!
*What if you want to set it to a certain percentage of its range instead?*

**You can use another function to do that!!**

Say you want a ==function== to move the solar panels to a percent location where:
| Percent (%) | Position             |
|-----------------|-----------------------|
|       0%       |    45 Degrees Clockwise          |
|       50%       |    Centered            |
|       100%       |    45 Degrees Counterclockwise   |

You can add the following function below your \`set_servo\` function:
\`\`\`
def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)
\`\`\`

*How does this \`duty_cycle\` equation work?*
* First, it takes the percent from 0 to 100 and divides by 100
   * This means that 30 will be 0.3 and 100 will be 1.0
* Second, you add that value to 1.0

**This returns a range from *1.0 to 2.0 ms* with percent as an input!!**
`}
<LegendMessageKeyboard>
{`
Add a call to your new \`move_panel\` function:
\`\`\`
from microbit import *

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

$def move_panel(percent):
$    # use range from 1 ms to 2 ms
$    duty_cycle = (percent / 100) + 1.0
$    set_servo(duty_cycle)

$# move solar panel to centered position (50%)
$move_panel(50)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>
<StepComplete
            prompt="Did your servo move to the expected position?"
            xp={5}
            successMessage=
{`## Positional Percentages! {centered}
### Great job adding the new \`move_panel\` function!
`}
            reqImports={['microbit']}
            reqCalls={['pin0.write_analog','pin0.set_analog_period','set_servo','move_panel']}
            reqArgs={['20','50','duty_cycle']}
            reqNames={['duty_cycle']}
            reqFuncdefs={['set_servo', 'move_panel']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
    </div>
  )
  }
}
class LightSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Light Sensor {lesson-title}
___
#### You have the ability to rotate the solar panels to a percent location.
*Now how can you detect the sun to start tracking it?*

#### With the Light Sensor of course!
* The light sensor is an **analog input** that provides the **intensity** of ambient light.
`}
<LegendOctoHeader 
  pin={1}
  peripheral={13} // 13 = Light Sensor
  markdown={`
  Connect the **Light Sensor** to **position 1** on your ==octopus:bit==!
  `}
/>
<LegendMessageConcept>
<Markdown>
<img src={PhotoSensorImg} alt=""
    style={{
      width: '50%', margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
The light sensor is built with a photoresistor
* A photoresistor creates less **resistance** when light hits it 
* Less resistance allows more current to pass through
* Your device reads a higher analog value in its ==ADC== as more current flows

**MORE LIGHT = HIGHER VALUE**
`}
</Markdown>
</LegendMessageConcept>
{`
**Ambient light sensors are used in all kinds of real-world applications:**
* Night lights
* Solar street lamps
* Car interior lights
* Real-world solar tracking devices
`}

<Quiz
        lessons={this.props.lessons}
        prompt="What type of input is the light sensor?"
        xp={5}
        answerRight={"analog"}
        answerWrong={["digital", "boolean"]}
      />

</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to start displaying sensor values!"
      />
    </div>
  )
  }
}

class PrintLightValues extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Print Raw Light Values to the REPL! {lesson-title}
___
### Time to output some values to see how the light sensor works!
`}
<img src={AdvancedDebugImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:25, marginLeft: 10, marginBottom: 10
    }}
/>
{`
*Do you remember how to open the Debug Panel?*
* You'll need to enable the **Debug Panel** menu option.
* You'll also need to enable the **Advanced Debug** menu option.
* Use the **View** menu to enable them, as shown here &rarr;
`}
<div style={{clear:'both'}} />
<LegendMessageDebug title="Open up the Advanced Debug Panel" />
{`The **Debug Console** panel will appear on the **left-hand** side next to the **Variables** panel.
`}
<img src={ReplStartImg} alt=""
    style={{
      width: "50%", margin: 'auto', display:'block', float:'left', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
Now, ***go back to your code in the Editor***.
`}
<div style={{clear:'both'}} />

<LegendMessageKeyboard>
{`
1. Start by reading the analog light sensor with a \`read_light_sensor\` ==function==.
2. Then add a while loop to keep reading the sensor.
3. \`print\` the analog value of the ambient light in your debug console.

\`\`\`
from microbit import *

pin0.set_analog_period(20)

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

$def read_light_sensor():
$    return pin1.read_analog()

# move solar panel to centered position (50%)
move_panel(50)

$while(True):
$    light_val = read_light_sensor()

$    print("Light Val: ", light_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
When you run this, the light sensor values should be showing up on the screen!
`}
</LegendMessageRun>
<LegendMessageInteract title={'Place your finger over the light sensor to watch the values decrease in the REPL.'}>
{`
Make note of the **lowest** values after you have completely covered the sensor and blocked all light.
* Make sure the values go lower than **250**
* This will be your low light threshold

**Add the following value just below the import statement in your code:**
\`\`\`
LOW_LIGHT_THRESHOLD = 250
\`\`\`
`}
</LegendMessageInteract>

<StepComplete
  prompt="Are sensor values showing up on the REPL?"
  xp={5}
  successMessage=
{`## Alight with Values! {centered}
The \`print()\` statement continues to be useful!
`}
            reqImports={['microbit']}
            reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel']}
            reqCalls={['pin0.set_analog_period','print','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel']}
            reqArgs={['20', '50', 'duty_cycle']}
            reqNames={['light_val', 'duty_cycle']}
            reqStatements={['while']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class SolarStates extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Analyzing Solar State {lesson-title}
___
#### Time to match light intensity with solar panel position

In the real-world you would probably need:
* A complicated algorithm to monitor the ship's orientation vs the sun's position
* Algorithms can be fine-tuned to move the panels continuously

**For this project you are going to setup three ==states==**
* These states assume the ship is always at a fixed orientation to the sun
* As the sun moves it will cross 3 different zones which will change the ==state== in your code
* You will move the solar panels to known positions as the sun transitions these zones

The three ==states== will be named:
* \`'morning'\`
* \`'afternoon'\`
* \`'night'\`

These state names do not have quite as much "meaning" in space, but they are an easy reference for sun movements.

You can see the **position** of the solar panels relative to ==state== in this image.
* States are in **Yellow**
* Solar Panel Positions are in **Blue**
`}
<img src={SolarStatesImg} alt=""
    style={{
      width: '70%', margin: 'auto', display:'block'
    }}
/>
{`
**Your code should ==transition== between ==states== at these two thresholds:**
* You have already determined the \`LOW_LIGHT_THRESHOLD\`
* You will also need a \`HIGH_LIGHT_THRESHOLD\`
`}
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT to implement the state transitioning logic!"
      />
    </div>
  )
  }
}

class SwitchSolarState extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Transition the State {lesson-title}
___
#### Write a function to ==transition== the state using a ==global== variable

Your solar panels should be set to the following values when they are in those states:
| Percent (%) | State             |
|-----------------|-----------------------|
|       0%       |    \`'morning'\`       |
|       50%       |    \`'afternoon'\`    |
|       100%       |    \`'night'\`   |

1. Add a ==global== variable \`state\` that can be accessed from a ==function== and set it to \`'morning'\`
\`\`\`
# states are 'morning', 'afternoon', 'night'
state = 'morning'
\`\`\`

2. Add a \`transition_state\` function to change the \`state\` variable and move the solar panels
\`\`\`
def transition_state(new_state):
    global state 
    state = new_state
    
    if new_state == 'morning':
        move_panel(0)
    elif new_state == 'afternoon':
        move_panel(50)
    else: # 'night'
        move_panel(100)
\`\`\`

3. Call the \`transition_state\` ==function== to set the ==state== to \`'night'\` just before your ==loop==
* This will make sure the solar panel position and the ==global== \`state\` variable are aligned before entering the ==loop==
\`\`\`  
transition_state('night')
\`\`\`
`}
<LegendMessageKeyboard>
  {`
\`\`\` collapsed The complete code can be found here...
from microbit import *

LOW_LIGHT_THRESHOLD = 250

pin0.set_analog_period(20)

$# states are 'morning', 'afternoon', 'night'
$state = 'morning'

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

def read_light_sensor():
    return pin1.read_analog()
    
$def transition_state(new_state):
$    global state 
$    state = new_state
$    
$    if new_state == 'morning':
$        move_panel(0)
$    elif new_state == 'afternoon':
$        move_panel(50)
$    else: # 'night'
$        move_panel(100)

# move solar panel to centered position (50%)
move_panel(50)

$transition_state('night')

while(True):
    light_val = read_light_sensor()

    print("Light Val: ", light_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<StepComplete
  prompt="Is your servo at the 100% position (not centered)?"
  xp={5}
  successMessage=
{`## Off Center! {centered}
Awesome job adding a \`transition_state\` function!
`}
  reqImports={['microbit']}
  reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel','transition_state']}
  reqCalls={['pin0.set_analog_period','print','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel', 'transition_state']}
  reqArgs={['0', '50', '100', '20', "'night'", 'duty_cycle']}
  reqNames={['light_val', 'LOW_LIGHT_THRESHOLD', 'duty_cycle', 'state']}
  reqStatements={['while', 'if', 'elif', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
  )
  }
}
class MorningAndNight extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Morning and Night {lesson-title}
___
#### Start by switching the state between \`'morning'\` and \`'night'\`

You can start switching ==states== in your \`while\` ==loop==
* You can ignore the \`'afternoon'\` state for now
* You should use the ==finite-state machine== diagram as a reference

**The ==finite-state machine== looks like this:**
`}
<img src={SolarStateDiagramImg} alt=""
    style={{
      width: '350px', margin: 'auto', display:'block'
    }}
/>
{`
\`if\` the state is \`'morning'\` you should transition to \`'night'\` when the \`light_val < LOW_LIGHT_THRESHOLD\`.
\`\`\`
    if state == 'morning':
        if light_val < LOW_LIGHT_THRESHOLD:
            transition_state('night')
\`\`\` 

You should then transition back to \`'night'\` when the \`light_val > LOW_LIGHT_THRESHOLD\`.
\`\`\`
    elif state == 'night':
        if light_val > LOW_LIGHT_THRESHOLD:
            transition_state('morning')
\`\`\` 
`}
<LegendMessageKeyboard>
  {`
\`\`\` collapsed The complete code is here...
from microbit import *

LOW_LIGHT_THRESHOLD = 250

pin0.set_analog_period(20)

# states are 'morning', 'afternoon', 'night'
state = 'morning'

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

def read_light_sensor():
    return pin1.read_analog()
    
def transition_state(new_state):
    global state 
    state = new_state
    
    if new_state == 'morning':
        move_panel(0)
    elif new_state == 'afternoon':
        move_panel(50)
    else: # 'night'
        move_panel(100)

# move solar panel to centered position (50%)
move_panel(50)

transition_state('night')

while(True):
    light_val = read_light_sensor()

    print("Light Val: ", light_val)

$    if state == 'morning':
$        if light_val < LOW_LIGHT_THRESHOLD:
$            transition_state('night')
$    elif state == 'night':
$        if light_val > LOW_LIGHT_THRESHOLD:
$            transition_state('morning')
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title={'Move your finger to cover the light sensor.'}>
{`
The servo should move to the \`'night'\` state.
`}
</LegendMessageInteract>
<LegendMessageInteract title={'Move your finger away from the light sensor.'}>
{`
The servo should move to the \`'morning'\` state.
`}
</LegendMessageInteract>
<StepComplete
  prompt="Is your servo transitioning between 'morning' and 'night'? (not centered)?"
  xp={5}
  successMessage=
{`## Stateful Logic! {centered}
You have two states covered!
`}
reqImports={['microbit']}
  reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel','transition_state']}
  reqCalls={['pin0.set_analog_period','print','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel', 'transition_state']}
  reqArgs={['0', '50', '100', '20', "'night'","'morning'", 'duty_cycle']}
  reqNames={['light_val', 'LOW_LIGHT_THRESHOLD', 'duty_cycle', 'state']}
  reqStatements={['while', 'if', 'elif', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
  )
  }
}

class Debouncing extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Debouncing {lesson-title}
___
#### There is a small problem with your ==state== machine!
* The ==state== transitions rely on light values dropping or rising continuously

Unfortunately, real-world sensors are almost never perfect
* Analog inputs can very easily **bounce** around

Examine this chart:
`}
<img src={DebouncingImg} alt=""
    style={{
      width: '70%', margin: 'auto', display:'block'
    }}
/>
{`
The ==state== is transitioning between 'morning' and 'night' at the \`LOW_LIGHT_THRESHOLD\`.

But... it is transitioning twice instead of the expected single transition.

**This is called ==bouncing==.**

*What are some events that could cause ==bouncing==?*
* A flicker in a lightbulb causing an inconsistent reading on a light sensor
* A user pressing a button with varied pressure causing multiple "presses"
* Electricity jumping between *closing* metal contacts
* A user clicking a mouse multiple times accidentally

This will become an even **BIGGER** problem as you add more states to the system.
* You do not want your system inadvertently changing to an unexpected ==state==!!

*What can you do to solve this problem?*

**One simplistic solution is to use a software delay to ==debounce== the reading.**

You can just ==delay== before taking the next measurement 
* This will reduce the opportunity for ==bouncing== around threshold values
`}
<LegendMessageKeyboard>
<Markdown>
{`
Add a simple delay to ==debounce== your application's ==state== transitions. 200 milliseconds should be enough!
\`\`\`
from microbit import *

LOW_LIGHT_THRESHOLD = 250

pin0.set_analog_period(20)

# states are 'morning', 'afternoon', 'night'
state = 'morning'

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

def read_light_sensor():
    return pin1.read_analog()
    
def transition_state(new_state):
    global state 
    state = new_state
    
    if new_state == 'morning':
        move_panel(0)
    elif new_state == 'afternoon':
        move_panel(50)
    else: # 'night'
        move_panel(100)

# move solar panel to centered position (50%)
move_panel(50)

transition_state('night')
      
while(True):
    light_val = read_light_sensor()

    print("Light Val: ", light_val)
    
    if state == 'morning':
        if light_val < LOW_LIGHT_THRESHOLD:
            transition_state('night')
    elif state == 'night':
        if light_val > LOW_LIGHT_THRESHOLD:
            transition_state('morning')
        
$    sleep(200)
\`\`\`
`}
</Markdown>
</LegendMessageKeyboard>
<LegendMessageRun />
<StepComplete
  prompt="Did you add a delay to prevent bouncing?"
  xp={5}
  successMessage=
{`## Debounced! {centered}
==Debouncing== is a great tool when it is needed!
`}
  reqImports={['microbit']}
  reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel','transition_state']}
  reqCalls={['pin0.set_analog_period','print','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel', 'sleep','transition_state']}
  reqArgs={['0', '50', '100', '20', '200',"'night'","'morning'", 'duty_cycle']}
  reqNames={['light_val', 'LOW_LIGHT_THRESHOLD', 'duty_cycle', 'state']}
  reqStatements={['while', 'if', 'elif', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
  )
  }
}


class SimulateSun extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Simulate the Sun {lesson-title}
___
#### Attach the white light to simulate the sun!
`}
<LegendOctoHeader 
  pin={2}
  peripheral={2} // 2 = White Light
  markdown={`
  Connect the **White LED** to **position 2** on your ==octopus:bit==!
  `}
/>
{`
`}
<LegendMessageKeyboard>
{`
Now add a line of code above your \`while\` loop to turn the white LED ON and leave it ON.
\`\`\` collapsed Try it yourself first...
from microbit import *

LOW_LIGHT_THRESHOLD = 250

pin0.set_analog_period(20)

# states are 'morning', 'afternoon', 'night'
state = 'morning'

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

def read_light_sensor():
    return pin1.read_analog()
    
def transition_state(new_state):
    global state 
    state = new_state
    
    if new_state == 'morning':
        move_panel(0)
    elif new_state == 'afternoon':
        move_panel(50)
    else: # 'night'
        move_panel(100)

# move solar panel to centered position (50%)
move_panel(50)

transition_state('night')

$# turn white LED ON
$pin2.write_digital(1)
        
while(True):
    light_val = read_light_sensor()

    print("Light Val: ", light_val)
    
    if state == 'morning':
        if light_val < LOW_LIGHT_THRESHOLD:
            transition_state('night')
    elif state == 'night':
        if light_val > LOW_LIGHT_THRESHOLD:
            transition_state('morning')
        
    sleep(200)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageDebug title="Open up the Advanced Debug Panel" />
<LegendMessageRun title="Run your code again" />
<LegendMessageInteract title={'Move the white LED close to the light sensor to watch the values increase.'}>
{`
When you white LED is close to the light sensor make note of the **highest** values.
* Make sure the values go higher than **500**
* This will be your high light threshold

**Add the following value below the import statement in your code:**
\`\`\`
HIGH_LIGHT_THRESHOLD = 500
\`\`\`
`}
</LegendMessageInteract>

<StepComplete
  prompt="Are sensor values increasing when you move the white LED close?"
  xp={5}
  successMessage=
{`## So Bright! {centered}
You just simulated the sun!
`}
  reqImports={['microbit']}
  reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel']}
  reqCalls={['pin2.write_digital', 'pin0.set_analog_period','print','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel', 'sleep','transition_state']}
  reqArgs={['1', '0', '50', '100', '20', '200',"'night'","'morning'", 'duty_cycle']}
  reqNames={['light_val', 'LOW_LIGHT_THRESHOLD', 'duty_cycle', 'state']}
  reqStatements={['while', 'if', 'elif', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
  )
  }
}

class AutoTrack extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Automatic Sun Tracking {lesson-title}
___
#### Add the final state to your code!
* \`'afternoon'\` is the final state for the solar tracker

The transition to the \`'afternoon'\` ==state== should occur when the sun is at its **brightest**.

**The ==finite-state machine== looks like this:**
`}
<img src={FullSolarStateDiagramImg} alt=""
    style={{
      width: '500px', margin: 'auto', display:'block'
    }}
/>
{`
The transition from \`'morning'\` to \`'afternoon'\` should utilize the new \`HIGH_LIGHT_THRESHOLD\`:
\`\`\`
    if state == 'morning':
        if light_val > HIGH_LIGHT_THRESHOLD:
            transition_state('afternoon')
\`\`\`

The transition from \`'afternoon'\` to \`'night'\` should utilize the \`LOW_LIGHT_THRESHOLD\`:
\`\`\`
    elif state == 'afternoon':
        if light_val < LOW_LIGHT_THRESHOLD:
            transition_state('night')
\`\`\`

`}
<LegendMessageKeyboard>
{`
You can remove the \`print\` statement if you would prefer.
\`\`\` 
from microbit import *

LOW_LIGHT_THRESHOLD = 250
HIGH_LIGHT_THRESHOLD = 500

pin0.set_analog_period(20)

# states are 'morning', 'afternoon', 'night'
state = 'morning'

def set_servo(ms_duty_cycle):
    pin0.write_analog(1023 * ms_duty_cycle / 20)

def move_panel(percent):
    # use range from 1 ms to 2 ms
    duty_cycle = (percent / 100) + 1.0
    set_servo(duty_cycle)

def read_light_sensor():
    return pin1.read_analog()
    
def transition_state(new_state):
    global state 
    state = new_state
    
    if new_state == 'morning':
        move_panel(0)
    elif new_state == 'afternoon':
        move_panel(50)
    else: # 'night'
        move_panel(100)

# move solar panel to centered position (50%)
move_panel(50)

transition_state('night')

# turn white LED ON
pin2.write_digital(1)
        
while(True):
    light_val = read_light_sensor()
    
    if state == 'morning':
$        if light_val > HIGH_LIGHT_THRESHOLD:
$            transition_state('afternoon')
$    elif state == 'afternoon':
        if light_val < LOW_LIGHT_THRESHOLD:
            transition_state('night')
    elif state == 'night':
        if light_val > LOW_LIGHT_THRESHOLD:
            transition_state('morning')
        
    sleep(200)
\`\`\`
`}
</LegendMessageKeyboard>

<LegendMessageInteract title={'Transition your solar tracking system between the 3 states.'}>
{`
1. Place the white LED next to the light sensor to transition from \`'morning'\` to \`'afternoon'\`
2. Cover the light sensor to transition from \`'afternoon'\` to \`'night'\`
3. Uncover the light sensor to transition from \`'night'\` to \`'morning'\`

And... **REPEAT**
\`\`\`
`}
</LegendMessageInteract>

<StepComplete
  prompt="Is your solar tracking system transitioning between all 3 states?"
  xp={5}
  successMessage=
{`## You are Tracking! {centered}
Congratulations on building a complete solar tracking system!
`}
  reqImports={['microbit']}
  reqFuncdefs={['read_light_sensor', 'set_servo', 'move_panel']}
  reqCalls={['pin2.write_digital', 'pin0.set_analog_period','read_light_sensor','pin0.write_analog', 'pin1.read_analog', 'set_servo', 'move_panel', 'sleep','transition_state']}
  reqArgs={['1', '0', '50', '100', '20', '200',"'night'","'morning'", "'afternoon'", 'duty_cycle']}
  reqNames={['light_val', 'LOW_LIGHT_THRESHOLD', 'HIGH_LIGHT_THRESHOLD','duty_cycle', 'state']}
  reqStatements={['while', 'if', 'elif', 'else']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
  )
  }
}

class SolarTrackingFinal extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Tracking the Sun! {lesson-title}
___
### You've completed project Solar Tracking!
You now know how to use positional servos and apply a more complex ==state machine==. 

The ship's power supply has been boosted over 30% by rotating the solar panels!

**You have created enough power to get the crew to Mars!!**
`}
        <img src={FinalSolarTrackingImg} alt="" style={{margin: 'auto', width:'40%', maxWidth: '703px', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="You tracked the sun and made more power.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectSolarTracking.stepId = 'ProjectSolarTracking'
PositionalServo.stepId = 'PositionalServo'
PositionDutyCycles.stepId = 'PositionDutyCycles'
FightForPosition.stepId = 'FightForPosition'
PercentPosition.stepId = 'PercentPosition'
LightSensor.stepId = 'LightSensor'
PrintLightValues.stepId = 'PrintLightValues'
SolarStates.stepId = 'SolarStates'
SwitchSolarState.stepId = 'SwitchSolarState'
MorningAndNight.stepId = 'MorningAndNight'
Debouncing.stepId = 'Debouncing'
SimulateSun.stepId = 'SimulateSun'
AutoTrack.stepId = 'AutoTrack'
SolarTrackingFinal.stepId = 'SolarTrackingFinal'

export const solarTracking = [
  ProjectSolarTracking,
  PositionalServo,
  PositionDutyCycles,
  FightForPosition,
  PercentPosition,
  LightSensor,
  PrintLightValues,
  SolarStates,
  SwitchSolarState,
  MorningAndNight,
  Debouncing,
  SimulateSun,
  AutoTrack,
  SolarTrackingFinal,
]
