import React, { Component } from 'react'
import { SIDEBAR_INDEXES } from './SideBar.jsx'
import GroupsMainPanel from './main-panels/GroupsMainPanel.jsx'

class MainPanel extends Component {
  getMainPanelContent = () => {
    switch (this.props.sidebarSelection) {
      case SIDEBAR_INDEXES.MY_GROUPS:
      default:
        return (
          <GroupsMainPanel
            data={this.props.data} 
            refreshData={this.props.refreshData}
            disableUi={this.props.disableUi}
            uiDisabled={this.props.uiDisabled}
          />
        )
    }
  }

  render() {
    return (
      <main className={this.props.sidebarOpen ? this.props.classes.contentShift : this.props.classes.content}>
        { this.getMainPanelContent() }
      </main>
    )
  }
}

export default MainPanel
