import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import FolderIcon from 'material-ui-icons/Folder'
import Tooltip from 'material-ui/Tooltip'
import { UsbIcon } from './UsbIcon'


export const defaultFilename = 'Untitled Program'

const styles = () => ({
  edit: {
    '& br': {
      display: 'none',
    },
    fontFamily: 'arial, sans-serif',
    fontSize: '1.15em',
    padding: '0px 5px 0px 5px',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  folder: {
    color: 'gray',
  },
})


class CodeFileFolder extends Component {
  static propTypes = {
    filename: PropTypes.string.isRequired,
    onFilenameChanged: PropTypes.func.isRequired,
    saved: PropTypes.bool.isRequired,
    onFolderClicked: PropTypes.func.isRequired,
    usbConnected: PropTypes.bool.isRequired,
    classes: PropTypes.shape(styles).isRequired,
    onUsbConnectRequest: PropTypes.func.isRequired,
    newFile: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      inputFilename: defaultFilename,
      prevFilename: defaultFilename,
      inputBorder: '1px solid rgba(0, 0, 0, 0)',
      inputHasFocus: false,
      open: false,
    }

    this.filenameInput = null
  }

  componentWillReceiveProps = (nextProps) => {
      if (nextProps.filename !== this.state.inputFilename) {
          this.setState({
              inputFilename: nextProps.filename,
              prevFilename: nextProps.filename,
          })
      }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.filenameInput && prevProps.newFile && !this.props.newFile) {
      // I'm being lazy here and using a timeout because otherwise it would take a lot of refs and state passing
      // to know when the menu actually closed before I can set focus
      setTimeout(() => {
        this.filenameInput.focus()
        const sel = window.getSelection()
        sel.removeAllRanges()
        const range = document.createRange()
        range.selectNodeContents(this.filenameInput)
        sel.addRange(range)
      }, 300)}
  }

  getFolder = () => (this.props.saved ? (
    <Tooltip title="Move to...">
      <FolderIcon
        onClick={() => {
            this.setState({
              open: false,
            })
            this.props.onFolderClicked()
          }}
        className={this.props.classes.folder}
        viewBox="0 0 22 18"
        transform="scale(0.9)"
      />
    </Tooltip>
  ) : null)

  getUsb = () => (
    <Tooltip title={this.props.usbConnected ? "USB Connected" : "USB Disconnected - click to connect"} className={this.props.classes.tooltip}>
      <UsbIcon
        viewBox="0 0 24 11"
        style={this.props.usbConnected ? {color: '#000'} : {color: '#c0c0c0'}}
        onClick={() => {
          if (!this.props.usbConnected) {
            this.props.onUsbConnectRequest()
          }
        }}
      />
    </Tooltip>
  )

  render() {
    return (
      <div style={{ display: 'flex', marginLeft: 5 }}>
        <div
          style={{
            marginTop: '9px',
            paddingLeft: '9px',
          }}
        >
          <span
            id="code-filename"
            contentEditable
            className={this.props.classes.edit}
            ref={(span) => this.filenameInput = span}
            style={{
              border: this.state.inputBorder,
              whiteSpace: 'nowrap',
            }}
            suppressContentEditableWarning
            onFocus={() => {
              this.setState({
                prevFilename: this.state.inputFilename,
                inputBorder: '1px solid',
                inputHasFocus: true,
              })
            }}
            onMouseEnter={() => {
              this.setState({
                inputBorder: '1px solid #eee',
              })
            }}
            onMouseLeave={() => {
              if (!this.state.inputHasFocus) {
                this.setState({
                  inputBorder: '1px solid rgba(0, 0, 0, 0)',
                })
              }
            }}
            onBlur={(event) => {
              let inputFilename = event.currentTarget.textContent.trim()
              if (!inputFilename) {
                // Disallow empty filename
                inputFilename = this.state.prevFilename
                event.currentTarget.textContent = this.state.prevFilename
              }
              if (this.props.onFilenameChanged && inputFilename !== this.state.prevFilename) {
                this.props.onFilenameChanged(inputFilename)
              }
              this.setState({
                inputBorder: '1px solid rgba(0, 0, 0, 0)',
                inputHasFocus: false,
                inputFilename,
              })
            }}
            onKeyDown={(e) => { if (e.keyCode === 13) e.target.blur() }}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          >
            {this.state.inputFilename}
          </span>
        </div>
        <div
          id="code-folder-move"
          style={{
            marginLeft: '1em',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          {this.getFolder()}
        </div>
        <div
          id="usb-connected"
          style={{
            marginLeft: '0.6em',
            display: 'flex',
            // visibility: this.props.usbConnected ? 'visible' : 'hidden',
            // opacity: this.props.usbConnected ? '1.0' : '0.3',
            alignItems: 'flex-end',
          }}
        >
          {this.getUsb()}
        </div>
      </div>
    )
  }
}


export default withStyles(styles)(CodeFileFolder)
