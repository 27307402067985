import React, { Fragment } from 'react'
// import PropTypes from 'prop-types'
import Table from 'material-ui/Table'
import TableBody from 'material-ui/Table/TableBody'
import TableCell from 'material-ui/Table/TableCell'
import TablePagination from 'material-ui/Table/TablePagination'
import TableRow from 'material-ui/Table/TableRow'

import { stableSort, getSorting, EnhancedTableHead } from './GroupsTable'


class ProgressTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'curriculum',
    selected: null,
    page: 0,
    rowsPerPage: 10,
  }

  handleRequestSort = (event, property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc' })
    this.setState({ orderBy: property })
  }

  handleDoubleClick = (event, uid) => {
    //this.props.onSelected(uid)
    //this.props.onDoubleClick(uid)
  }

  handleClick = (event, uid) => {
    this.setState({ selected: uid === this.state.selected ? null : uid })
    //this.props.onSelected(uid)
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value })
    this.setState({ page: 0 })
  }

  getRowData = (baseRow) => {
    const newRow = { xxx_name: baseRow.name, xxx_uid: baseRow.uid }
    if (baseRow.missingRecord) {
      newRow.name = 'USER DELETED'
    }
    this.props.projects.forEach((project) => {
      let completed = 0
      try {
        if (baseRow.module.progress.hasOwnProperty(project.name)) {
          completed = baseRow.module.progress[project.name].length
        }
      } catch (e) {
        completed = 0
      }
      const total = project.component.length
      newRow[project.name] = parseInt(((100 * completed) / total).toFixed(0), 10)
    })
    return newRow
  }

  render() {
    const headCells = this.props.projects.map((project, idx) => { return { id: project.name, numeric: true, disablePadding: false, label: project.name } })
    headCells.unshift({ id: 'xxx_name', numeric: false, disablePadding: false, label: 'Name' })

    const fullRows = this.props.rowData.map(baseRow => this.getRowData(baseRow))

    return (
      <div style={{ width: '100%', paddingTop: '4px', display: 'flex', flexDirection: 'column', minHeight: '0' }}>
        <div style={{ overflow: 'auto' }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headerCells={headCells}
            />
            <TableBody>
              {stableSort(fullRows, getSorting(this.state.order, this.state.orderBy))
                  .slice(this.state.page * this.state.rowsPerPage, (this.state.page * this.state.rowsPerPage) + this.state.rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    const user = this.props.rowData.find(baseRow => baseRow.uid === row.xxx_uid)

                  return (
                    <Fragment key={row.xxx_uid}>
                      {
                          this.state.selected === row.xxx_uid ?
                            <TableRow
                              key={-99}
                              aria-checked={this.state.selected === row.xxx_uid}
                              onClick={event => this.handleClick(event, row.xxx_uid)}
                              style={{ cursor: 'pointer' }}
                              selected
                              hover
                            >
                              <TableCell key="0" component="th" id={labelId} scope="row">{row.xxx_name}</TableCell>
                              {
                                this.props.projects.map((project, idx) => {
                                  const percent = row[project.name].toString()
                                  let completed = []
                                  try {
                                    if (user.module.progress.hasOwnProperty(project.name)) {
                                      completed = user.module.progress[project.name]
                                    }
                                  } catch (e) {}
                                  return (
                                    <TableCell key={project.name} id={project.name} style={{ verticalAlign: 'top' }}>
                                      <div>
                                        {`${percent}%`}
                                        {
                                          project.component.map((cmp, cmpIdx) => {
                                            const x = completed.includes(cmp)
                                            return (
                                              <Fragment key={cmp}>
                                                <br />
                                                <span style={{ color: x ? 'green' : 'red' }}>
                                                  {x ? '\u2713' : '\u2717'}{(idx + 1).toString()}.{(cmpIdx + 1).toString()}
                                                </span>
                                              </Fragment>
                                            )
                                          })
                                        }
                                      </div>
                                    </TableCell>
                                  )
                                })
                              }
                            </TableRow> :
                            <TableRow
                              hover
                              onClick={event => this.handleClick(event, row.xxx_uid)}
                              //onDoubleClick={event => this.handleDoubleClick(event, row._uid)}
                              role="checkbox"
                              aria-checked={this.state.selected === row.xxx_uid}
                              tabIndex={-1}
                              key={row.xxx_uid}
                              selected={this.state.selected === row.xxx_uid}
                              style={{ cursor: 'pointer' }}
                            >
                              <TableCell key="0" component="th" id={labelId} scope="row">{row.xxx_name}</TableCell>
                              {
                                this.props.projects.map((project) => {
                                  const percent = row[project.name].toString()
                                  return (
                                    <TableCell key={project.name} id={project.name}>{`${percent}%`}</TableCell>
                                  )
                                })
                              }
                            </TableRow>
                      }
                    </Fragment>
                  )
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={this.props.rowData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{ 'aria-label': 'previous page' }}
          nextIconButtonProps={{ 'aria-label': 'next page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    )
  }
}

export default ProgressTable
