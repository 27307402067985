import Raven from 'raven-js'
import smartlookClient from 'smartlook-client'
import { googleApi } from './Gapi'


// Yanked from raven-js
// with some tiny modifications
function isError(value) {
  switch ({}.toString.call(value)) {
    case '[object Error]':
      return true
    case '[object Exception]':
      return true
    case '[object DOMException]':
      return true
    default:
      return value instanceof Error
  }
}

class UserTracker {
  constructor() {
    if (document.location.hostname !== 'localhost') {
      const environment = document.location.hostname === 'makebit.firialabs.com' ? 'production' : 'development'
      Raven.config(
        'https://ebf6435497a34daf8720257d345fcbf5@sentry.io/260953',
        {
          release: process.env.REACT_APP_BITBUCKET_COMMIT,
          environment,
        },
      ).install()
      smartlookClient.init(environment === 'production' ? '96b331b60a51e9d4be03bd115f141e2732dacf9b' : 'e68c8753efb066a494f3eb69d1969fe61c50c0cb')

      if (!googleApi.gapiAuthIsSignedIn) {
        googleApi.notifyOnAuthChange((signedIn) => {
          if (signedIn) this.setUser()
        })
      }

      this.isTracking = true
    } else {
      this.isTracking = false
    }
    this.alwaysConsoleLog = false
  }

  setUser = () => {
    const profile = googleApi.getCurrentUser().getBasicProfile()
    Raven.setUserContext({
      email: profile.getEmail(),
      id: profile.getId(),
      username: profile.getName(),
    })
    if (window.smartlook) {
        smartlookClient.identify(profile.getId(), {
            name: profile.getName(),
            email: profile.getEmail(),
        })
    }
  }

  addBreadcrumb = (
    message,
    category = 'dev',
    level = 'debug',
    data = null,
  ) => {
    if (this.isTracking) {
      try {
          if (level === 'error') {
              const dataIsError = isError(data)
              if (dataIsError) {
                  Raven.captureException(data)
              } else {
                  Raven.captureException(new Error(message), {extra: data})
              }
          } else {
              Raven.captureBreadcrumb({
                  message,
                  category,
                  level,
                  data,
              })
          }
          smartlookClient.track('breadcrumb', {
              category,
              message,
              level,
              data,
              'release': process.env.REACT_APP_BITBUCKET_COMMIT,
          })
      } catch (err) {
        console.log("Error capturing breadcrumb:", err);
      }
    }
    if (this.alwaysConsoleLog || !this.isTracking) {
      console.log('Breadcrumb: ', {
        message,
        category,
        level,
        data,
      })
    }

    if (!this.isTracking && isError(data)) throw data
  }
}

export const tracker = new UserTracker()
export default tracker
