// Celebration animations - CodeBots Gone Wild!

import React from 'react'
import CodeBotLogoHappy from './StartCoding/assets/CodeBotLabs_Logo-Icon-happy-256.png'
import Confetti from 'react-confetti'

export const celebrationAnimations = [
  (
  <div style={{position: 'relative', width: '400px', height: '150px', margin: 'auto'}}>
    <Confetti
      width={400}
      height={150}
    />
    <img src={CodeBotLogoHappy} alt=""
      style={{display: 'block', margin: 'auto', width:100, position: 'relative',
        animationName: 'codebot-dance1',
        animationIterationCount: 'infinite',
        animationDuration: '2s',
        animationTimingFunction: 'linear',
      }} />
  </div>
  ),

  // TODO: Add mo animations!

]