// Project: CyberBit

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  //LegendMessageWarning,
  //LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import Quiz from '../Quiz'
import SecurityImg from './assets/Security.jpg'
import DecoderRing from './assets/DecoderRing.gif'
import Caesar from './assets/Caesar.jpg'
import Cipher1 from './assets/Cipher1.png'
import FutureSecure from './assets/FutureSecure.jpg'
import MailOutline from './assets/mail_outline.svg'

export const CyberBitImg = SecurityImg

class Prelude extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: CyberBit {lesson-title}
___
`}
<img src={SecurityImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10
    }}
/>
{`#### In this project you'll learn about packets and encryption as you *CODE* a bit of *security* into your micro:bit!
*CyberSecurity* is a term covering many actions needed to keep computer systems secure.
* If you build a house, you probably want to have **locks** on the doors.
* The same goes for computers! Most **coding** involves the *building* part, rather than the *locksmithing* ;-)
* But you *do* want to build **strong** *houses of code*, and you can make *locks* with code too! You'll learn about that in this project.

Now that your micro:bit is *wirelessly networked*, you might want to send **secret messages** to your friends *over the air*.
There are two major problems:
* The micro:bit \`radio.send()\` function **broadcasts** to *all devices* on a channel.
  * But what if you only want **one** device to process a message?
  * Can you add an **address** to the message, so only the specifically *addressed* device pays attention to it?
* Someone could spy on your messages!
  * Even your *arch nemesis*!!
  * Can you use a *secret code* to **encrypt** each message?

#### Project Goals:
* Add an **address header** to your *radio messages*.
  * Your micro:bit can specify *exactly* which other device to send its message to.
* Protect your *secret messages* from being intercepted.
  * Use a basic **cipher** with a *long and glorious history*!
`}
</Markdown>
        <StepComplete
          prompt="Press Next to Secure the 'bit..."
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class Cyber1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Plain Text Messages {lesson-title}
___
#### Start with a simple message that's broadcast to *everyone!*
* Got a *secret* to share?
* Not so fast! This is **not** a secure communication method.
  * First you'll transmit *"in the clear"*, later you'll **secure** it.
`}
<LegendMessageKeyboard>
{`Create a new file and name it **CyberHead**.
* Enter the code below - It's meant to be loaded onto *two* micro:bits.
* Notice that it sends a message **once** at startup, then just *loops* and *listens*.
\`\`\`
from microbit import *
import radio

radio.on()
radio.config(channel=16)  # Channel can be 0-83

# My Halloween costume... It's a secret - don't tell anyone!
msg = "Batman"
radio.send(msg)

while True:
    # Check radio
    msg = radio.receive()
    if msg:
        display.scroll(msg, wait=False, loop=True)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Load this code onto **two** microbits.
* You'll need to run one on a battery pack, or another computer.
* Use the ==reboot== button to restart one of the micro:bits so that it *transmits* the message.
`}
</LegendMessageRun>
<StepComplete
  prompt="Is your secret message being transmitted oh-so-insecurely?"
  xp={25}
  successMessage=
{`## Gotta love a plain-text message! {centered}
Your nemesis will see that coming a mile away...
`}
  reqImports={['microbit', 'radio']}
  reqCalls={['display.scroll', 'radio.on', 'radio.send', 'radio.receive']}
  reqFuncdefs={[]}
  reqStatements={['while', 'if']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Cyber2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Give your micro:bit its own *address*. {lesson-title}
___
`}
<img src={MailOutline} alt=""
    style={{
      width: "10%", margin: 'auto', display:'block', float:'right', marginLeft: 10, marginRight:10
    }}
/>
{`
#### Rather than sending a message to *everyone*, specify the *exact* device you're sending to.
* Just like in the last step, you will load the code onto *two* micro:bits.
* But you need to add an **"address"** to the message before *sending*.
* And your *receiving* micro:bit should *ignore* messages that don't match its **address**.

What's the best way to add an **address** to a message?
`}
<LegendMessageConcept title={'Concept: Packet Headers'}>
{`Message *chunks* sent between computers are called **"packets"**.
* Think of *packets* like *envelopes* in snail mail, or email messages on the computer.
  * There's a place up front for the **address**.
* **Packets** have the same feature!
  * Usually they're divided into at least two sections, like this:
  > \`_HEADER_\` \`_PAYLOAD_\`

The *HEADER* comes first, and often contains *addresses* and other information like the size
of the packet.

The *PAYLOAD* is the actual message content your application needs to send. Sometimes it's also
called the *DATA* portion of the packet.
`}
</LegendMessageConcept>
{`#### Now make your *own* HEADER
All it really needs is an **address** for the intended receiver of your message.
* A single ==byte== at the front of the message string will work just fine.
* That provides **256** unique addresses (0-255).

***One problem:** How do you insert a **byte** at the beginning of the message string?*
* The ==Character Encoding== of MicroPython strings uses **one byte per character**.
* Use the \`chr()\` function to encode a byte-sized number (0-255) into a single-character string, which will be your *HEADER*.
* Then use the \`+\` operator to *join* the *HEADER* with your **message** string.

**And next to *receive* the message:**
* Use ==string== indexing to read the first byte \`hdr = msg[0]\`
* Use the \`ord()\` function to convert \`hdr\` to an integer byte value (the *address*).
* Then use *string slicing* to separate the payload from the header \`payload = msg[1:]\`
`}
<LegendMessageKeyboard>
{`#### Don't worry - the *code* is simpler than the above explanation!
* Notice where the *addresses* are set.
* Change the \`my_addr\` and \`friend_addr\` values for **each micro:bit** you load this onto!
\`\`\`
from microbit import *
import radio

radio.on()
radio.config(channel=16)  # Channel can be 0-83

# Addresses of me and my friend
my_addr = 1
friend_addr = 2

# Send message with header
hdr = chr(friend_addr)  # convert address to single-byte
msg = hdr + "Batman"

radio.send(msg)

while True:
    # Check radio
    msg = radio.receive()
    if msg:
        hdr = msg[0]
        addr = ord(hdr)
        if addr == my_addr:
            payload = msg[1:]
            display.scroll(payload, wait=False, loop=True)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`If you have other micro:bits on the same channel, do a test to confirm you **only** display the messages from your *friend*!
* Make sure to load ***different*** code on *each micro:bit!*
  * Each one needs its own unique **my_addr**.
`}
</LegendMessageRun>
<StepComplete
  prompt="Is the personalized packet delivery working for you?"
  xp={25}
  successMessage=
{`## Brilliant Header! {centered}
Ronaldo couldn't have done it better...
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'radio.on', 'radio.send', 'radio.receive', 'ord', 'chr']}
reqFuncdefs={[]}
reqStatements={['while', 'if']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Checkpoint1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Checkpoint {lesson-title}
___
There were a ***lot*** of new concepts in the last step! Take a moment to be sure it's all making sense.

### Click on the ==string== help, and read about:
* String *indexing* with \`text[i]\`
* String *slicing* with \`text[i:j]\`

#### Answer the questions below for the following string:
\`\`\`
foo = "Python"
\`\`\`
`}
      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What is \`foo[2]\` ?
`}
</Markdown>
        )}
        id={"String indexing"}
        xp={5}
        answerRight={'"t"'}
        answerWrong={['"y"', '"h"', '"o"']}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What is \`foo[2:4]\` ?
`}
</Markdown>
        )}
        id={"String slicing, middle"}
        xp={5}
        answerRight={'"th"'}
        answerWrong={['"thon"', '"ytho"', '"yt"']}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What is \`foo[:3]\` ?
`}
</Markdown>
        )}
        id={"String slicing, begin"}
        xp={5}
        answerRight={'"Pyt"'}
        answerWrong={['"Py"', '"hon"', '"on"']}
      />

      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What is \`foo[1:]\` ?
`}
</Markdown>
        )}
        id={"String slicing, end"}
        xp={5}
        answerRight={'"ython"'}
        answerWrong={['"thon"', '"ytho"', '"n"']}
      />
{`### Now click on the ==Character Encoding== help, and read about \`chr()\` and \`ord()\`
`}
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What does the Python function \`chr(n)\` do?
`}
  </Markdown>
  )}
  id={"chr"}
  xp={5}
  answerRight={"Return the string whose Character Encoding is the integer 'n'."}
  answerWrong={["Change the rotation of 'n' by one position.", "Check if 'n' is Real or Imaginary."]}
/>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What does the Python function \`ord(c)\` do?
`}
  </Markdown>
  )}
  id={"ord"}
  xp={5}
  answerRight={"Return the integer Character Encoding for the single-character string 'c'."}
  answerWrong={["Sort the string 'c' in alphabetical order.", "Order a pizza, where 'c' represents quantity of cheese."]}
/>
<StepComplete
  prompt="Excellent. On with the project!"
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

class Cyber3 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Cipher like Caesar  {lesson-title}
___
`}
<img src={Caesar} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginLeft: 10, marginRight:10
    }}
/>
{`
#### Can you prevent others from *snooping* on your message?
The previous step showed how you can **address** a packet to just one receiver.
* But **any** receiver could ignore the *address* and display the message anyway!

*Now it's time to **encrypt** your message, so that it's much more difficult to intercept.*

For thousands of years people have used various methods to *scramble* the text of written messages.
* These methods are called **"ciphers"**.
* Such *secret messages* can only be "unlocked" by those who have the "key".

The **key** in this case means that you must have:
* The algorithm used to encode the message. *(or a device that implements the algorithm!)*
* A special *password* or *magic number* used to encode a message.
`}
<img src={DecoderRing} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float:'left', marginRight:10
    }}
/>
{`#### You are going to code a *Caesar cipher*.
It's named after the famed *Julius Caesar* (the guy in stone above), who used it to encode secret messages during many successful military campaigns.
* It was *state-of-the-art* 2000 years ago!
* Actually it's quite easy to crack... Fortunately we have much better *ciphers* today.
* And the steps you'll be using are common to **modern ciphers** too!
* *Code On!* The world of *cryptography* is yours to explore.
`}
<div style={{clear:'right'}} />
{`#### How does it work?
The *Caesar cipher* is a **substitution cipher**.
* Each letter of a message is replaced by another letter a specified number of positions down the alphabet.
* The number of positions to **shift** is the *key* to decoding the message.
  * It is said that *Caesar* usually used \`shift = 3\`
`}
<img src={Cipher1} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float:'right', marginLeft:10,
    }}
/>
{`#### See the example to the *right*:
* The message **"ABCD"** is encoded as **"DEFG"** after \`shift = 3\`.
`}
        <StepComplete
          prompt="Up Next: A Cyber Cipher Celebration..."
          lessons={this.props.lessons}
          btnNext={true}
        />
        </Markdown>
      </div>
    )
  }
}

class Cyber4 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Code the Cipher  {lesson-title}
___
#### Write some code to implement *Caesar's cipher!*
First step will be just to code and test the algorithm. After you're sure it works,
you'll add the full ==radio== capability.

How will you **shift** the letters of a message *down the alphabet*?
* **Letters** are already stored as **numbers** in the computer.
* The ==Character Encoding== functions will be useful here!

**Example:** - *Shift a letter by 3*
\`\`\`
letter = "A"       # Start with a plain-text letter
val = ord(letter)  # Translate it to an ASCII number value
val = val + 3      # Add a "shift" down the alphabet
encoded = chr(val) # Translate back to a text string
\`\`\`
`}
<LegendMessageKeyboard>
{`**Erase** the *Packet header* and *Address* parts of your code, but leave the ==radio== code.
* All you are doing in this step is ***testing*** your *cipher*, but you'll need the *radio* later!
* Also *erase* the code that handles *received messages*.
* Notice keyword \`pass\` used inside the \`if msg\` block?
  * That means *do nothing!* An *empty* block is not allowed, so this is a nice *placeholder* for the *receive* code you'll add in the next step.

Define a **function**, \`encode(text, shift)\`, that will shift a message string.
* The **shift** can be + or - so this function can also *shift back* to **decode** the message.

Notice the code below uses a \`for\` ==loop==.
* The variable \`c\` receives a letter from \`text\` each cycle of the loop.
* This type of loop is often more convenient than a \`while\` loop for *indexing through* a string or list.
\`\`\`
from microbit import *
import radio

radio.on()
radio.config(channel=16)  # Channel can be 0-83

$def encode(text, shift):
$    encoded = ""
$    for c in text:
$        val = ord(c) + shift
$        encoded = encoded + chr(val)
$
$    return encoded
$
$# Make a message, encode, and display
$msg = "Cats"
$code_msg = encode(msg, 3)
$display.scroll(code_msg)
$
$# Decode and display the message
$clear_msg = encode(code_msg, -3)
$display.scroll(clear_msg)
$
radio.send(msg)

while True:
    # Check radio
    msg = radio.receive()
$    if msg:
$        # Placeholder - write code for this later!
$        pass
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Watch the micro:bit display and check for the **encrypted** message.
* ...Then make sure the **plain-text** version scrolls by next!
`}
</LegendMessageRun>
{`#### Encryption = *Running a message through a cipher!*
There are many types of **encryption**, used to secure computers and the networks that connect them.
Every time you use the Internet or a mobile phone you are making use of *encryption*.
`}
<LegendMessageDebug title="Explore with the Debugger">
{`Try stepping through this code with the **debugger**.
* Open the *Debug Panel* to view the **Variables**.
* See how the string \`encoded\` is built by adding letters during the loop?
* It's cool to see the \`code_msg\` and \`clear_msg\` globals.
  * Your *cipher* is doing its job. That looks *unreadable*...
`}
</LegendMessageDebug>
<StepComplete
  prompt="Got encryption?"
  xp={25}
  successMessage=
{`## Cipher like Caesar! {centered}
#### Tossed alphabet salad with Caesar dressing!
But hold the anchovies on mine plz...
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'ord', 'chr', 'encode']}
reqFuncdefs={['encode']}
reqStatements={['for']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Cyber5 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Encrypted Wireless Messages {lesson-title}
___
#### Now to put your *cipher* to practical use!
* You just need to add the ==radio== functions to your \`encode()\` code.

#### Build a *Wireless Messenger* Caesar would be proud of!
1. Encrypt the message, and \`radio.send()\` it.
2. When you **receive** a message, un-encrypt it!
`}
<LegendMessageKeyboard>
{`Now *move* your code that *decodes* and scrolls the message.
* Put it inside the \`if msg\` block, replacing \`pass\`.
  * *Be sure to change to \`encode(msg, -3)\`, not \`code_msg\`.*
\`\`\`
from microbit import *
import radio

radio.on()
radio.config(channel=16)  # Channel can be 0-83

def encode(text, shift):
    encoded = ""
    for c in text:
        val = ord(c) + shift
        encoded = encoded + chr(val)

    return encoded

msg = "Cats"
code_msg = encode(msg, 3)

$$$
$radio.send(code_msg)

$while True:
$    # Check radio
$    msg = radio.receive()
$    if msg:
$        clear_msg = encode(msg, -3)
$        display.scroll(clear_msg, wait=False, loop=True)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Load up **two** micro:bits and see if they can decode the messages!
* Remember you have to *reboot* to send the message.
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`What happens if the two micro:bits \`shift\` values are set to **differing** numbers?
`}
  </Markdown>
  )}
  id={"Caesar"}
  xp={5}
  answerRight={"The decoded message will be incorrect."}
  answerWrong={["The encode function will not shift different values.", "The message is decoded correctly even if the shift doesn't match."]}
/>
<StepComplete
  prompt="Are your wireless messages securely scrambled?"
  xp={25}
  successMessage=
{`## Your secrets are safe with me! {centered}
`}
reqImports={['microbit', 'radio']}
reqCalls={['display.scroll', 'radio.on', 'radio.send', 'radio.receive', 'ord', 'chr', 'encode']}
reqFuncdefs={['encode']}
reqStatements={['while', 'if', 'for']}
lessons={this.props.lessons}
btnYes={true}
btnNo={true}
/>
        </Markdown>
      </div>
    )
  }
}

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## All Secure! {lesson-title}
___
`}
        <img src={FutureSecure} alt=""
          style={{
            width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft:10
          }}
        />
{`
### Congratulations! You have learned about and *implemented* some fundamental security measures.
* As a **coder** you need to understand how to make your programs secure for people who use them.
* You created encryption from scratch this time, to learn the basics.
  * But Python has **industry standard** *encryption* and *security* packages you can \`import\` when you need them.

You probably won't be writing your own encryption code, unless you become a *cryptography expert*. Hey, would an architect
creating amazing new buildings also invent new types of locks?
* Not likely - making locks is a specialized skill!
* And you might accidentally make locks that are easy to break...

There are many more topics to learn about in **Cybersecurity**, but that's all for now!
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* Can you combine **Packet Headers** and **Encryption**?
  * Try adding an **address header**. Don't encrypt the *whole packet*, just the *payload* part.
  * Add a second byte to the **header** that tells whether to **encrypt** or not.
    * You could make the byte value be the *shift* amount. Can you think of any drawbacks to that idea?
* Can you improve on the **Caesar cipher**?
  * How about a different \`shift\` value for each letter of the *message*?
  * You could use a ==list== of numbers as the "password", rather than a single *shift* value.
    * ...just keep **repeating** the list of shift-numbers if the message is *longer* than the list.
    * This is known as a **"Vigenère cipher"** - a famous cipher in more recent history!
`}
</LegendMessageTry>
        </Markdown>
        <StepComplete
          prompt="You have survived CyberCamp! Nothing more to see here..."
          lessons={this.props.lessons}
          btnNext
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

Prelude.stepId = 'Prelude'
Cyber1.stepId = 'Cyber1'
Cyber2.stepId = 'Cyber2'
Checkpoint1.stepId = 'Checkpoint1'
Cyber3.stepId = 'Cyber3'
Cyber4.stepId = 'Cyber4'
Cyber5.stepId = 'Cyber5'
Finale.stepId = 'Finale'

export const cyberBit = [
  Prelude,
  Cyber1,
  Cyber2,
  Checkpoint1,
  Cyber3,
  Cyber4,
  Cyber5,
  Finale,
]
