import React from 'react'
import Paper from 'material-ui/Paper'
import Typography from 'material-ui/Typography'
import FlyingCody from './lessons/StartCoding/FlyingCody'

function LoadingErrorPage(props) {
  return (
    <Paper
      style={{
        width: '50%',
        minWidth: '300px',
        padding: '50px',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      <div style={{ marginTop: '30px' }}>
        <FlyingCody />
        <Typography
          align="center"
          variant="title"
          style={{ marginTop: '40px' }}
        >
            Sorry, we are having some trouble loading...
        </Typography>
        <div>
          <Typography
            align="left"
            variant="body1"
            style={{ marginTop: '30px' }}
          >
            We noticed the following errors:
          </Typography>
          {
            props.errors.map((err, index) => {
              return (
                <div key={index}>
                  <Typography
                    align="left"
                    variant="body2"
                    style={{ marginTop: '5px', marginLeft: '50px' }}
                  >
                    { err.name }
                  </Typography>
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ marginTop: '5px', marginLeft: '70px' }}
                  >
                    { err.details }
                  </Typography>
                </div>
                )
              })
          }
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
          <Typography
            align="center"
            variant="body1"
            style={{ marginBottom: '5px' }}
          >
            This may be an issue with your network or firewall settings.
          </Typography>
          <p>
            See <a href="https://firialabs.com/blogs/support-forum/whitelist-urls">Required URLs</a> for details.
          </p>
          <p>
            Feel free to contact our support team for help!
          </p>
          <a href="mailto: support@firialabs.com">support@firialabs.com</a>
        </div>
      </div>
    </Paper>
  )
}

export default LoadingErrorPage
