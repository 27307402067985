import firebase from 'firebase'
import 'firebase/firestore'
import decode from 'jwt-decode'
import { googleApi } from './Gapi'
import { tracker } from './tracking'


// firebase.database.enableLogging(true)
// firebase.firestore.setLogLevel('debug')

function isUserEqual(googleUserId, firebaseUser) {
  if (firebaseUser) {
    const { providerData } = firebaseUser
    for (let i = 0; i < providerData.length; i += 1) {
      if (providerData[i].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUserId) {
        // We don't need to reauth the Firebase connection.
        return true
      }
    }
  }
  return false
}

class CodeBotLabsFirebase {
  constructor() {
    this.signedInPromise = new Promise((resolve, reject) => {
      this._signInResolve = resolve
      this._signInReject = reject
    })

    this.dbConnectPromise = new Promise((resolve, reject) => {
      this._dbConnectResolve = resolve
      this._dbConnectReject = reject
    })

    firebase.onLog(this.logCallback, {level: 'error'})

    this.app = firebase.initializeApp({
      apiKey: 'AIzaSyCdl1oq3kZ-csL1TM-Vtg_MDKtGZzDhi0o',
      authDomain: 'make-firialabs.firebaseapp.com',
      databaseURL: 'https://make-firialabs.firebaseio.com',
      projectId: 'make-firialabs',
      storageBucket: 'make-firialabs.appspot.com',
      messagingSenderId: '1046533668208',
    })
    this.db = firebase.firestore()
    this.db.settings({ experimentalAutoDetectLongPolling: true })
    // firebase.firestore.setLogLevel('debug')

    this.firebaseSignedIn = false
    googleApi.notifyOnAuthChange((isSignedIn) => {
      if (isSignedIn) {
        this.onSignIn(googleApi.getIdToken())
      } else {
        this.userDoc = null
        this.onFirebaseAuthChange(false)
      }
    })

    this.authChangeCallbacks = []
    this.docRetrievedCallbacks = []
    this.userDocChangesCallbacks = []

    this.unsubscribeUserDoc = null
    this.userDoc = null

    this.gclassroomSubmit = undefined
  }

  logCallback = (callbackParams) => {
    // One way to catch Firebase internal errors which are caught and not propagated.
    // console.log("FL FB Log: ", callbackParams.message)
    this._dbConnectReject(callbackParams.message)
  }

  onFirebaseSignInComplete = (idToken) => {
    this.onFirebaseAuthChange(true)
    this.retrieveUserDoc(idToken, true)
    this._signInResolve()
  }

  onSignIn = (googleIdToken) => {
    // console.log('googleIdToken=', googleIdToken, firebase.auth().currentUser)
    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      // TODO should we really unsubscribe? This was in the example code provided
      unsubscribe()
      const idToken = decode(googleIdToken)
      // Check if we are already signed-in Firebase with the correct user.
      if (!isUserEqual(idToken.sub, firebaseUser)) {
        // Build Firebase credential with the Google ID token.
        const credential = firebase.auth.GoogleAuthProvider.credential(googleIdToken)
        // Sign in with credential from the Google user.
        firebase.auth().signInWithCredential(credential)
          .then(() => this.onFirebaseSignInComplete(idToken))
          .catch((error) => {
            this.onFirebaseAuthChange(false)
            this._signInReject(error.message)
            tracker.addBreadcrumb('Unable to login to firebase', 'firebase', 'error', error)

            // // Handle Errors here.
            // const errorCode = error.code
            // const errorMessage = error.message
            // // The email of the user's account used.
            // const email = error.email
            // // The firebase.auth.AuthCredential type that was used.
            // const credential = error.credential
            // // ...
          })
      } else {
        // console.log('User already signed-in Firebase.')
        this.onFirebaseSignInComplete(idToken)
        this._signInResolve()
      }
    })
  }

  onUserDocSnapshot = (doc) => {
    // Wait for the initial retrieval from the server
    // If someone does a set on the userDoc before we get our first copy from the server then userDoc will be incomplete
    if (!this.userDoc && doc.metadata.fromCache) return
    const wasLoaded = !!this.userDoc

    this._dbConnectResolve(true)
    this.userDoc = doc

    if (!wasLoaded) {
      this.docRetrievedCallbacks.forEach((cb) => {
        try {
          cb(this.userDoc)
        } catch (err) {
          tracker.addBreadcrumb('Error notifying docRetrievedCallback', 'firebase', 'error', err)
        }
      })

      const idToken = decode(googleApi.getIdToken())
      this.userDoc.ref.set({
        uid: idToken.sub,
        name: idToken.name,
        email: idToken.email,
        locale: !!idToken.locale ? idToken.locale : '',
        lastSignIn: firebase.firestore.FieldValue.serverTimestamp(),
        firebaseUid: this.userDoc.id,
      }, { merge: true })
    }

    if (this.gclassroomSubmit === undefined && this.userDoc.exists && this.userDoc.data().gclassroomSubmit !== undefined) {
      this.gclassroomSubmit = this.userDoc.data().gclassroomSubmit
    }

    this.userDocChangesCallbacks.forEach((cb) => {
      try {
        cb(this.userDoc)
      } catch (err) {
        tracker.addBreadcrumb('Error notifying userDocChangesCallback', 'firebase', 'error', err)
      }
    })
  }

  retrieveUserDoc = async () => {
    const { uid } = firebase.auth().currentUser
    // console.log(firebase.auth().currentUser)

    try {
      const userDocRef = this.db.collection('users').doc(uid)

      this.unsubscribeUserDoc = userDocRef.onSnapshot(this.onUserDocSnapshot, (error) => {
        tracker.addBreadcrumb('An error occurred in listening for the userDoc Snapshot', 'firebase', 'error', error)
      })
    } catch (error) {
      tracker.addBreadcrumb('Error getting or setting firestore user document', 'firebase', 'error', error)
    }
  }

  registerFirebasAuthChangeCallback = (cb) => {
    this.authChangeCallbacks.push(cb)
  }

  registerDocRetrievedCallback = (cb) => {
    this.docRetrievedCallbacks.push(cb)
  }

  registerUserDocChangesCallback = (cb) => {
    this.userDocChangesCallbacks.push(cb)
  }

  onFirebaseAuthChange = (isSignedIn) => {
    if (!isSignedIn && this.unsubscribeUserDoc) {
      this.unsubscribeUserDoc()
      this.unsubscribeUserDoc = null
    }

    this.firebaseSignedIn = isSignedIn
    this.authChangeCallbacks.forEach((cb) => {
      try {
        cb(isSignedIn)
      } catch (err) {
        tracker.addBreadcrumb('Error notifying authChangeCallback', { data: err })
      }
    })
  }

  logError = ({
    type = 'unknown',
    category = 'uncategorized',
    content = {},
  }) => {
    if (!this.firebaseSignedIn) return // Our Firestore rules currently only allow signed in users to write data
    const data = {
      occurredAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...content,
    }

    this.db.collection(`/errors/${type}/${category}`).doc().set(data)
  }

  hasGClassroomSubmit = async () => {
    if (this.gclassroomSubmit === undefined && this.userDoc !== undefined && this.userDoc.data().gclassroomSubmit === undefined) {
      const queryResult = await this.db.collection('gclassroom-users').where('studentId', '==', googleApi.getCurrentUser().getId()).get()
      this.gclassroomSubmit = queryResult.docs.length > 0
    }

    return this.gclassroomSubmit
  }
}
export const cblFirebase = new CodeBotLabsFirebase()

export default firebase
