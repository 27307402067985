// Premium lesson Module for CodeSpace

import React from 'react'
import CodingModuleBase from '../CodingModuleBase'
import { jumpStartIntro, JumpstartIntroImg } from './JumpstartIntro'
import { displayGames, DisplayGamesImg } from './DisplayGames'
import { music, MusicImg } from './Music'
import { heartBeat, HeartbeatImg } from './Heartbeat'
import { billboard, BillboardImg } from './Billboard'
import { randAns, RandAnsImg } from './RandomAns'
import { gameSpinner, GameSpinnerImg } from './GameSpinner'
import { reactionTime, ReactionTimeImg } from './ReactionTime'
import { spiritLevel, SpiritLevelImg} from './SpiritLevel'
import { nightLight, NightLightImg } from './NightLight'
import { graphics, GraphicsImg } from './Graphics'
import { radioMessenger, RadioMessengerImg } from './RadioMessenger'
import { cyberBit, CyberBitImg } from './CyberBit'
import { tempSensor, TempSensorImg } from './TempSensor'
import { alarm, AlarmImg } from './Alarm'
import { soundsFun, SoundsFunImg } from './SoundsFun'
import { finale } from './Finale'


export const projects = [
  {
    name: 'First Steps',
    component: jumpStartIntro,
    description: 'This first project is all about getting to know the CodeSpace user interface and the micro:bit hardware. Before you finish, you’ll plug the hardware in and write some code to make it do something!',
    hasAssignment: true,
    img: JumpstartIntroImg,
  },
  {
    name: 'Display Games',
    component: displayGames,
    description: 'This project explores the micro:bit’s LED display and push-buttons. We’re jumping in head-first with some real Python coding.',
    hasAssignment: true,
    img: DisplayGamesImg,
  },
  {
    name: 'Micro Musician',
    component: music,
    description: 'Computers and music go great together! This project brings together coding, electronics, and music. It’s easy to connect a speaker to the micro:bit, and there are lots of built-in tunes to play.',
    hasAssignment: true,
    img: MusicImg,
  },
  {
    name: 'HeartBeat',
    component: heartBeat,
    description: 'The end goal of this project is simple – a continuously flashing heartbeat. Along the way it reinforces understanding of coding concepts learned so far, and ushers in the crucial concept of loops.',
    hasAssignment: true,
    img: HeartbeatImg,
  },
  {
    name: 'Personal Billboard',
    component: billboard,
    description: 'Have you ever made a sign to post on a door or wall? How about a name badge to wear? Or a cap or t-shirt with a message or slogan on it? In this project, you will build a device that lets you display images or text, and even use the micro:bit’s two push-buttons to select what is displayed to suit a particular occasion or mood.',
    hasAssignment: true,
    img: BillboardImg
  },
  {
    name: 'Answer Bot',
    component: randAns,
    description: 'This project builds on the concept of selecting from a list of items and adds random number generation to the mix. Up to this point the micro:bit has been pretty predictable – as you’d expect a computer to be! But some applications need randomness, or unpredictable results.',
    hasAssignment: true,
    img: RandAnsImg,
  },
  {
    name: 'Game Spinner',
    component: gameSpinner,
    description: 'In this project, you’ll make a Game Spinner that will show a spinning arrow on the LED display when you press Button A or B, and then slow down and stop in one of 8 random directions.',
    hasAssignment: true,
    img: GameSpinnerImg,
  },
  {
    name: 'Reaction Tester',
    component: reactionTime,
    description: 'In this project, you will create a game that measures the time between the display lighting up and a button being pressed. After the measurement is complete, this time will be scrolled across the display until a button is pressed to restart the game.',
    hasAssignment: true,
    img: ReactionTimeImg,
  },
  {
    name: 'Spirit Level',
    component: spiritLevel,
    description: 'How level is your desk or table? Write some code to find out! In this project you’ll build a spirit level! This is more than just a fun project – it’s a useful tool with practical applications.',
    hasAssignment: true,
    img: SpiritLevelImg,
  },
  {
    name: 'Night Light',
    component: nightLight,
    description: 'In this project, you will use an external light sensor to detect ambient light, and program the micro:bit’s LED display to act as a “nightlight”.',
    hasAssignment: true,
    img: NightLightImg
  },
  {
    name: 'Get Graphical',
    component: graphics,
    description: 'This project will show you several ways to make the display do your bidding!',
    hasAssignment: true,
    img: GraphicsImg,
  },
  {
    name: 'Radio Messenger',
    component: radioMessenger,
    description: 'Go wireless with the micro:bit, and code the Internet of Things.',
    hasAssignment: true,
    img: RadioMessengerImg,
  },
  {
    name: 'Cyber Bit',
    component: cyberBit,
    description: 'Keep it secret! Keep it safe! Can you secure your networked micro:bit?',
    hasAssignment: true,
    img: CyberBitImg,
  },
  {
    name: 'Temperature Sensor',
    component: tempSensor,
    description: 'Connect a thermistor to your micro:bit and find out how HOT it REALLY is in here!',
    hasAssignment: true,
    img: TempSensorImg,
  },
  {
    name: 'Alarm System',
    component: alarm,
    description: 'Code a complete, functional advanced alarm system to guard your stuff!',
    hasAssignment: true,
    img: AlarmImg,
  },
  {
    name: 'Sounds Fun',
    component: soundsFun,
    description: 'Create your own music, sound effects, and even a spacy musical instrument!',
    hasAssignment: true,
    img: SoundsFunImg,
  },
  {
    name: 'The End',
    component: finale,
    description: 'You\'re done!',
    hasAssignment: false,
  },
]

const projectNames = projects.map(project => project.name)
const projectComponents = projects.map(project => project.component)

export default function (props) {
  const { ref2, ...other } = props
  return (
    <CodingModuleBase
      {...other}
      ref={ref2}
      projectNames={projectNames}
      projects={projectComponents}
    />)
}
