import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactAce from './ReactAce'

export let editorInst = null

let instantiatedCallback
export const editorInstancePromise = new Promise((resolve, reject) => {
  instantiatedCallback = () => resolve()
})

class CodeEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onChangeSelection: PropTypes.func,
    onDeadKeyPress: PropTypes.func,
    onChangeBreakpoint: PropTypes.func,
    onCopy: PropTypes.func,
    enableBreakpoints: PropTypes.bool,
  }

  static defaultProps = {
    onChange: () => {},
    onChangeSelection: () => {},
    onChangeBreakpoint: () => {},
    onCopy: () => {},
    enableBreakpoints: false,
  }

  getEditorRef = (ace) => {
    if (ace) {
      editorInst = ace.editor
      instantiatedCallback()
    }
  }

  render() {
    return (
      <ReactAce
        mode="python"
        theme="chrome"
        setReadOnly={false}
        fontSize={16}
        enableBreakpoints={this.props.enableBreakpoints}
        onChange={this.props.onChange}
        onChangeSelection={this.props.onChangeSelection}
        onDeadKeyPress={this.props.onDeadKeyPress}
        onChangeBreakpoint={this.props.onChangeBreakpoint}
        onCopy={this.props.onCopy}
        style={{ width: '100%' }}
        ref={this.getEditorRef}
      />
    )
  }
}

export default CodeEditor
