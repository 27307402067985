// Project: DisplayGames

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  //LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import DigitalDisplay from './assets/DigitalDisplayCut.gif'
import { DefaultPlayer as Video } from 'react-html5video'
import StepCode from './assets/StepCam.mp4'
import StepVars from './assets/StepVars.mp4'
import Joyride from '../cbl-joyride'
import Button from 'material-ui/Button'
import SleepyCat from './assets/cat-sleep.jpg'
import TrafficLight from './assets/TrafficLight.png'
import MicrobitBatt from './assets/microbit-unplugged.gif'
import BattConn from './assets/batt-conn.gif'
import MicrobitSkate from './assets/microbit-skate.png'
import Quiz from '../Quiz'
import SteppingSteps from './assets/SteppingSteps-sm.png'
import Dialog from 'material-ui/Dialog'


export const DisplayGamesImg = DigitalDisplay

class FirstImage extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Display Games! {lesson-title}
___
#### This project explores the LED display
`}
<img src={DigitalDisplay} alt=""
    style={{
      width: 400, margin: 'auto', display:'block', float:'right'
    }}
/>
{
`From car dashboards to giant stadium scoreboards, you see ==LED== displays **everywhere**, and most of them are controlled by software.
The micro:bit display is small, but with *your code* it can do a lot!

#### Project Goals:
* Show a sequence of Images on the display
* Change the *animation* speed of the Images
* Display **text message** *strings*
* Crunch some numbers with a micro-calculator!
* Program a Push-Button to make a *fast-click* **game**
`}
<LegendMessageKeyboard>
{`
* Create a new file (click **File** menu at top-left of screen and select **New**)
* Name it **Display**
#### Type this code into the Editor:
\`\`\`
from microbit import *
display.show(Image.SQUARE)
\`\`\`
`}
</LegendMessageKeyboard>
{`Just like your first program, a good place to start.

It's pretty obvious what the \`display.show(Image.SQUARE)\` is telling the micro:bit to do.
> But what about the \`from microbit import *\` ?
Click to learn more about the ==import== statement.
`}
<LegendMessageRun />
<StepComplete
  prompt="Do you see a SQUARE image on the LED display?"
  xp={20}
  successMessage=
{`## All squared away! {centered}
### Off to a good start...
`}
  reqImports={['microbit']}
  reqCalls={['display.show']}
  reqArgs={['Image.SQUARE']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class ImageSeries1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Two in a Row? {lesson-title}
___
### Now display two images in sequence
`}
<LegendMessageWarning title="Head's up!">
{`The code below may NOT do what you expect! *Read Carefully!*
`}
</LegendMessageWarning>
{`
The computer executes your code **sequentially**
* Starting with **line 1**, then **line 2**, and so on.
* Oh, and... computers are **very fast**.
`}
<LegendMessageKeyboard>
{`
Add another ==Image== that will be displayed **after** the \`SQUARE\`.

#### Edit the code so that your whole program looks like this:
\`\`\`
from microbit import *
display.show(Image.SQUARE)
display.show(Image.HAPPY)
\`\`\`
`}
</LegendMessageKeyboard>
<Quiz
        lessons={this.props.lessons}
        prompt="What do you expect the code above to do?"
        xp={5}
        answerRight={"Display each Image quickly, and end showing the last one"}
        answerWrong={["Show the Images for about 1 second each", "Display only the first Image"]}
/>
{`When you write code, it **often** doesn't work the way you planned the *first time*. Part of the
*joy of coding* is figuring out **why** - and *fixing* it!
`}
<LegendMessageRun>
{`Press the **Run** button to try out your new code.
> **Watch the micro:bit display...**
`}
</LegendMessageRun>
<StepComplete
  prompt="Don't see the first Image? That's okay - press Next..."
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

class ImageSeries2 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## What's Going On? {lesson-title}
___
#### Why is only the **last** \`Image\` showing up?

Hey, at least your program did *something* different! The display now shows a **happy face**. But the goal is to see
**both** images clearly, one at a time...

**Notice that your program ENDS very quickly**
* It doesn't wait for you to see the *first* Image before it shows the *second* one.
`}
<LegendMessageConcept title="Concept: The Display">
{`The LED ==display== stays active, even **after** your program ends.

It always shows the **last** thing it was given. Like many computer ==peripherals==,
it operates independently of the computer itself.
`}
</LegendMessageConcept>
{`**Theory**: Both Images **are** being displayed, but:
* The \`SQUARE\` is only displayed for a *very* short time (too fast to see)
* The \`HAPPY\` image is the **last** thing displayed, so the **LED** keeps showing it even *after* the program ends.

**Now**, *test* this theory with a couple more Images.
`}

<LegendMessageKeyboard>
{`
Add two more \`Image\`s that will be displayed following the \`HAPPY\`.

(no need to repeat \`from microbit import *\` - you only need that once!)

#### Just add two lines to the code you already have
\`\`\`
from microbit import *
display.show(Image.SQUARE)
display.show(Image.HAPPY)
$display.show(Image.HOUSE)
$display.show(Image.ASLEEP)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Press the **Run** button to try out your new code.
> **Watch the micro:bit display...**
`}
</LegendMessageRun>
<StepComplete
  prompt="Did the program end quickly, with the Display showing the LAST image?"
  xp={20}
  successMessage=
{`## Speedily Done! {centered}
### As a robot, naturally I saw ALL those Images ;)
`}
  reqImports={['microbit']}
  reqCalls={['display.show']}
  reqArgs={['Image.SQUARE', 'Image.HAPPY', 'Image.HOUSE', 'Image.ASLEEP']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class StepByStep extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Step By Step Images {lesson-title}
___
`}
</Markdown>
<Markdown>
{`
#### Inside the Mind of the Computer!

Computers are fast. Even a small computer like the micro:bit can execute *millions* of operations per second!

The **CodeSpace debugger** lets you **Step** your program *one line at a time*, at your own speed,
so you can understand *exactly* what the computer is doing and ==debug== your code.

#### Watch the video below: All the Images *are* being displayed!
It's easy to see when the program goes *slowly, step-by-step*.
`}
<Video autoPlay muted loop controls={['PlayPause']} style={{backgroundColor: 'white', width:'80%', margin:'auto'}}>
  <source src={StepCode} type="video/mp4" />
</Video>
{`Each line of code runs *after* the **Step** button is clicked.

*The sequence below shows how **stepping** works:*
`}
<img src={SteppingSteps} alt=""
    style={{
      width: 400, margin: 'auto', display:'block',
    }}
/>
<StepComplete
  prompt="Next it's your turn..."
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

const tourFirstStepInto = [
  {
    title: 'The Step Button',
    text: (
      <Markdown>
      {
`Press the **Step** button above to:
* Load code onto the micro:bit and <span style="border-color:red;border-style:solid;">wait</span> on first line.

Then press it **again** to:
* **<span style="color:green;">execute</span>** the <span style="border-color:red;border-style:solid;">waiting</span> line and *step* to the next line.

*Keep pressing the **Step** button to walk slowly through each line of code from top to bottom!*
`     }
      </Markdown>

    ),
    selector: '#tb-step-in',
    position: 'bottom-left',
    style: {
      width: 400
    }
  },
]

class StepByStepTry extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      runTour: false,
    }
  }

  handleRunTour = () => {
    this.tour.reset(true)
    this.setState({runTour: true})
  }

  render() {
    return (
      <div>
        <Joyride
          ref2={inst => {this.tour = inst}}
          steps={tourFirstStepInto}
          run={this.state.runTour}
        />

<Markdown>
{
`## Step To It {lesson-title}
___
#### Your turn to use the debugger!
There **is** a way to see what's going on in your code. Use the **STEP** button!
`}
<LegendMessageConcept
  title={'Concept: Stepping'}
>
<Markdown>
{`
You can execute the code **one line at a time** by using the **STEP** button.
`}
</Markdown>
<div style={{textAlign: 'center'}}>
  <Button
    variant='raised'
    onClick={this.handleRunTour}
  >
    Show Me How To Step!
  </Button>
</div>
<br />
</LegendMessageConcept>
{`
This is a *very* powerful tool for ==debugging== your code. Be sure to use it whenever
you need to understand more clearly what the code is doing!
`}
<LegendMessageDebug title="Try stepping through your code!">
  <ol>
    <li>Press the STEP button to re-load your program and wait at the first line</li>
    <li>Then use the <b>STEP</b> button to execute each line of code</li>
    <li>The highlighted line executes <b>after</b> you click STEP</li>
    <li>Then the <em>next</em> line of code is red-boxed, waiting and ready to go</li>
    <li>Check the micro:bit LED display <em>after</em> each STEP</li>
  </ol>
</LegendMessageDebug>
<StepComplete
  prompt="Did you see each Image while stepping through the code?"
  xp={20}
  successMessage=
{`## Sweet!! {centered}
### Don't hesitate to explore code by stepping through it.
`}
  reqSyntax={true}  // default
  reqImports={['microbit',]}
  reqCalls={['display.show']}
  reqArgs={['Image.SQUARE', 'Image.HAPPY', 'Image.HOUSE', 'Image.ASLEEP']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class FreeStepDemo extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Step By Step Images {lesson-title}
___
`}
</Markdown>
<Markdown>
{`
#### Inside the Mind of the Computer!

Computers are fast. Even a small computer like the micro:bit can execute *millions* of operations per second!

The **CodeSpace debugger** lets you **Step** your program *one line at a time*, at your own speed,
so you can understand *exactly* what the computer is doing and ==debug== your code.

> (*This feature is enabled when you have the full version of CodeSpace **JumpStart** curriculum, or any other licensed curriculum module from Firia Labs. Upgrade now and you too can "step to it!"*)

#### Watch the video below: All the Images *are* being displayed!
It's easy to see when the program goes *slowly, step-by-step*.
`}
<Video autoPlay muted loop controls={['PlayPause']} style={{backgroundColor: 'white', width:'80%', margin:'auto'}}>
  <source src={StepCode} type="video/mp4" />
</Video>
{`Each line of code runs *after* the **Step** button is clicked.

*The sequence below shows how **stepping** works:*
`}
<img src={SteppingSteps} alt=""
    style={{
      width: 400, margin: 'auto', display:'block',
    }}
/>
{`#### Next step will be to slow down the micro:bit so you can *see* each Image.
> *First, add more Images to make the code look like the video above.*
`}
<LegendMessageKeyboard
  title={`Update your Code!`}
>
{`Add **two more Images** - watch them *fly by too quickly to see*!
\`\`\`
from microbit import *
display.show(Image.SQUARE)
display.show(Image.HAPPY)
$display.show(Image.HOUSE)
$display.show(Image.ASLEEP)
\`\`\`
`}
</LegendMessageKeyboard>
<StepComplete
  prompt="Next to fix the code..."
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

class DisplaySleep extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Slow it Down {lesson-title}
___
When you *step* *slowly* through the code, all the Images show up. So you just need a way to delay the computer a little
after it shows each Image.
\`\`\`
sleep(1000)
\`\`\`
`}
</Markdown>
<img src={SleepyCat} alt="sleep" style={{width: 150, float:'right', marginLeft: '5px', marginBottom: '15px'}} />
<Markdown>
{`
The above line of code will cause the micro:bit to ==delay== for 1000 milliseconds (thousandths of a second)
before going to the next line. That equals 1 second of delay - plenty of time to see a new Image displayed.
`}
<LegendMessageKeyboard
  title={`Update your Code!`}
>
{`Add a line with \`sleep(1000)\` on the *next* line of code **after** each \`display.show()\`.
\`\`\`
from microbit import *
display.show(Image.SQUARE)
$sleep(1000)
display.show(Image.HAPPY)
$sleep(1000)
display.show(Image.HOUSE)
$sleep(1000)
display.show(Image.ASLEEP)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<StepComplete
  prompt="Were you able to see all the Images in sequence?"
  xp={25}
  successMessage=
{`## Excellent! {centered}
### Steady as she goes!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'sleep']}
  reqArgs={['Image.SQUARE', 'Image.HAPPY', 'Image.HOUSE', 'Image.ASLEEP']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>

</Markdown>
      </div>
    )
  }
}

class Variable extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Name That Number! {lesson-title}
___
It would be fun to play with some different delay times. Right now the number \`1000\` appears *three* times
in the code, and **all** must be changed to adjust the delay between Images!

Instead of repeating a *literal number* like \`1000\` in your code, you can use a \`name\` instead. Read on to
learn how much *easier* this makes it to **change** your delay!
`}
<LegendMessageConcept
  title={`Concept: Variables`}
>
{`A ==variable== is a *name* to which you assign some *data*. The *data* could be a number, an Image, or
any other type of information your program uses.

Variables must be **defined** like this before they are used:
\`\`\`
delay = 1000
\`\`\`
The line of code above defines a variable \`delay\` that can now be used anywhere in the program below it, in place of \`1000\`.
The best part is, you can now change that value in *one place* in your code!
`}
</LegendMessageConcept>
{`Now that you're *up to speed* - it's time to...
`}
<LegendMessageKeyboard
  title={`Update your Code!`}
>
{`Define a variable
\`\`\`
delay = 1000
\`\`\`
* Put it on its own line, at the **top of your code**, next line after the \`import * \`.
* Replace all the \`sleep(1000)\` lines with \`sleep(delay)\`.
\`\`\`
from microbit import *
$delay = 1000
display.show(Image.SQUARE)
$sleep(delay)
display.show(Image.HAPPY)
$sleep(delay)
display.show(Image.HOUSE)
$sleep(delay)
display.show(Image.ASLEEP)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`
Try it with a few different \`delay\` values, just to prove it works!
`}
</LegendMessageRun>

<StepComplete
  prompt="Got your variable working?"
  xp={25}
  successMessage=
{`## Good Stuff! {centered}
### Variables are powerful tools in your Coding toolkit.
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'sleep']}
  reqArgs={['Image.SQUARE', 'Image.HAPPY', 'Image.HOUSE', 'Image.ASLEEP', 'delay']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class Checkpoint1 extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Checkpoint {lesson-title}
___
Your code is starting to get a little more complex! Take a moment to be sure it's all making sense.

You've been typing this at the top of your code:
\`\`\`
from microbit import *
\`\`\`
`}
      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What does \`from microbit import *\` do?
`}
</Markdown>
        )}
        id={"Knowledge of 'from microbit import *'"}
        xp={5}
        answerRight={"Provides access to built-in microbit code"}
        answerWrong={["Turns on the microbit LEDs", "Imports asterisks from the land of microbit"]}
      />
{`
You added a variable called \`delay\` to your program.
`}
      <Quiz
        lessons={this.props.lessons}
        prompt={(
<Markdown>
{`What does \`delay = 1000\` do?
`}
</Markdown>
        )}
        id={"Knowledge of variables"}
        xp={5}
        answerRight={"Assigns the value 1000 to a variable named 'delay'"}
        answerWrong={["Delays for 1000 milliseconds", "Sleeps for 1 second"]}
      />

<StepComplete
  prompt="Excellent. On with the project!"
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

class IntroString extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Text Messages {lesson-title}
___
You've shown lots of **Images** on the ==LED== display. But can it *also* display **text**?
* Experiment to see if \`display.show()\` can show \`"text"\`, and not just \`Image\` data.
`}
<LegendMessageConcept
  title={`Concept: Data Types`}
>
{`Your code already works with *Data ==types==*:

> \`Image.SQUARE\` - an ==Image== type

> \`1000\` - an ==Integer== type

The **type** for *text* like **"Hello"** is called ==string==
> \`"Hello"\` - a **string** type

> *(Strings must be in **quotation marks**)*
`}
</LegendMessageConcept>
{`Try to make the micro:bit display a text **string** message!
`}
<LegendMessageKeyboard>
{`
**Erase the code in the Editor** (except for the first line)

Add a single \`display.show()\` with the message shown below:
\`\`\`
from microbit import *
display.show("Ahoy")
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
</LegendMessageRun>
<StepComplete
  prompt="Are you seeing a text message?"
  xp={25}
  successMessage=
{`## Fabulous! {centered}
### A s-t-r-i-n-g is just the thing to get your message across :-)
`}
  reqImports={['microbit',]}
  reqCalls={['display.show']}
  reqArgs={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class ScrollingText extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Scrolling Text {lesson-title}
___
The \`display.show()\` function shows a ==string== *one character at a time*. That's okay, but
it can be hard to read. The micro:bit has a better way to show text messages: \`display.scroll()\`.
`}
<LegendMessageKeyboard>
{`
#### Change your program to use \`scroll\` instead of \`show\`:
\`\`\`
from microbit import *
display.scroll("Ahoy")
\`\`\`
`}
</LegendMessageKeyboard>

<LegendMessageTry>
{`
### Play around with some different messages of your choice.
* The computer doesn't care what text you put inside the quotes
  * *Except for some symbols which require ==Escape Sequences==*
`}
</LegendMessageTry>
<LegendMessageRun />
<StepComplete
  prompt="Did the display scroll your message?"
  xp={25}
  successMessage=
{`## Scrumptious! {centered}
### Scrolling text is *much* easier to read than one-character-at-a-time mode!
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll']}
  reqArgs={[]}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class GoodWithNumbers extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    interceptErrorCb: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const typeErrorRegex = /TypeError: can't convert 'int' object to str implicitly/
    const dialogContent = (
      <div>
        Don't worry, this <b>TypeError</b> message is expected! Your code is not complete yet.
        Continue the lesson to learn how to resolve this error
      </div>
    )
    this.props.interceptErrorCb([typeErrorRegex, dialogContent, "Type Error"])
  }

  render() {
    return (
      <div>
<Markdown>
{`## Good With Numbers? {lesson-title}
___
You might have heard that computers are good at doing mathematics.
Time to put that to the test!
`}
<LegendMessageKeyboard>
{`You already know how to define a variable.
* Previously, you assigned a literal \`1000\` to a variable name
* Now try assigning a simple calculation to a variable, and display the result!

Change your program to calculate \`num\` and **scroll** the result:
\`\`\`
from microbit import *
num = 2 + 2
display.scroll(num)
\`\`\`
`}
</LegendMessageKeyboard>

<LegendMessageWarning title="Unexpected Result Ahead...">
{`You will see a **TypeError** when you Run this code!
`}
</LegendMessageWarning>

<LegendMessageRun>
{`When you run the code, an **error message** will appear. That's because \`display.scroll()\` only works
for certain **types** of data - like **Strings**.

Your code gave a *number* (an **Integer** or "**int**" type) to \`display.scroll()\`, producing an error.
`}
</LegendMessageRun>

<StepComplete
  prompt="Next you will fix the error..."
  lessons={this.props.lessons}
  btnNext={true}
/>
</Markdown>
      </div>
    )
  }
}

class StringConversion extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Converting Types {lesson-title}
___
You've discovered that \`display.scroll()\` doesn't work with **Integer** types. But it *does* work with **Strings**.

> *Fortunately, **Python** makes it easy to convert back and forth between these types!*
`}
<LegendMessageConcept title="Concept: Type Conversions">
{`
> \`str(n)\`    Convert 'n' to a ==String==

> \`int(s)\`    Convert 's' to an ==Integer==
`}
</LegendMessageConcept>
<LegendMessageKeyboard>
{`Modify your program:
> **Replace** \`display.scroll(num)\` with \`display.scroll( str(num) )\`.

\`\`\`
from microbit import *
num = 2 + 2
$display.scroll( str(num) )
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
</LegendMessageRun>
<StepComplete
  prompt="Is your micro:bit displaying the right number?"
  xp={25}
  successMessage=
{`## Right on Target! {centered}
### Nice *micro:calculator* you have there!
`}
  reqImports={['microbit',]}
  reqCalls={['display.scroll', 'str']}
  reqArgs={[]}
  reqNames={['num']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class Countdown extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Countdown {lesson-title}
___
The final stage of this project is to make a **GAME** that works like this:
* While the display shows a *3-2-1 countdown*, press **Button-A** as many times as you can.
* If you press it more than *10 times*, you win a **T-Shirt**!

The first step is to make the **countdown**.
`}
<LegendMessageKeyboard title="Code a 3-2-1 Countdown">
{`* Create a new file (click **File** menu at top-left of screen and select **New**)
* Choose a name, like *DisplayGame*

Write the code yourself!

It should look familiar - just like your **Image Sequence** a few steps ago, except this time it's a sequence
of **Strings**: \`"3"\` - \`"2"\` - \`"1"\`
> Can you **finish the program** without peeking?
\`\`\`
$from microbit import *
$display.show("3")
$sleep(1000)
display.show("2")
sleep(1000)
display.show("1")
sleep(1000)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
</LegendMessageRun>
<StepComplete
  prompt="Got a 3-second countdown (3,2,1) ??"
  xp={25}
  successMessage=
{`## Ready for Liftoff! {centered}
### I can't wait to play your new game...
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'sleep']}
  reqArgs={['"3"', '"2"', '"1"']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class ButtonInput extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{`## Gamer Input {lesson-title}
___
Gotta grab some **User Input** for your game!

The **micro:bit** is *always* **counting button presses** - even while your code displays the *countdown*.
`}
<LegendMessageConcept title="Concept: Buttons">
{`The **micro:bit** provides several built-ins for ==Buttons== **A** and **B**, *including*:

\`button_a.get_presses()\` Number of times button has been pressed

\`button_a.was_pressed()\` True if button has been pressed since last check

\`button_a.is_pressed()\`  True if button is currently pressed
`}
</LegendMessageConcept>
{`Now it's time to **button** this thing up!
* Next step is to **get** and **display** the **number of button presses**
`}
<LegendMessageKeyboard>
{`Add this **code snippet** to the **end** of your program:
* Click the code to **expand** the full program view.
\`\`\`
from microbit import *
display.show("3")
sleep(1000)
display.show("2")
sleep(1000)
display.show("1")
sleep(1000)

$$$
$x = button_a.get_presses()
$display.scroll(str(x))
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`*Play* your game! Can you get > 10 clicks?
`}
</LegendMessageRun>
<StepComplete
  prompt="Counting your Clicks?"
  xp={25}
  successMessage=
{`## Twitch Gameplay {centered}
### This is getting fun!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'display.scroll', 'sleep', 'button_a.get_presses', 'str']}
  reqArgs={['"3"', '"2"', '"1"']}
  reqNames={['x']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class WinningCondition extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      showVideo: null,
    }
    this.videoRef = null
  }

  handleStepVideo = (ev) => {
    this.setState({showVideo:true})
  }

  getVidRef = (ref) => {
    if (ref && ref.videoEl) {
      ref.videoEl.playbackRate = 0.5  // Slow motion
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={Boolean(this.state.showVideo)}
          onClose={() => { this.setState({ showVideo: false }) }}
        >
        <Video autoPlay muted controls={['PlayPause', 'Seek']}
          style={{backgroundColor: 'white', width:'100%', margin:'auto', overflow:'hidden'}}
          ref={this.getVidRef}
        >
          <source src={StepVars} type="video/mp4" />
        </Video>
      </Dialog>

<Markdown>
{`## For The Win! {lesson-title}
___
### The final step in your game is to award a prize IF you win!

#### Here's the plan:
* **If** the number of clicks \`x > 9\`, **then**
  * you win a:  \`display.show(Image.TSHIRT)\`
* **Otherwise**,
  * just show the number of clicks: \`display.scroll(str(x))\`

**In *Python* code it looks like this:**
\`\`\`
if x > 9:
    display.show(Image.TSHIRT)
else:
    display.scroll(str(x))
\`\`\`
*Your code will take **a different ==branch==** depending on the value of \`x\`*
`}
<LegendMessageConcept title="Concept: Branching">
{`
The ==\`if condition\`== statement tells Python to only run the block of code ==indented== beneath it
if the *condition* is \`True\`.

Check for a **winner** with the *condition* \`x > 9\` since the ==comparison operator== will be \`True\` when **x** is \`10\` or higher.
`}
</LegendMessageConcept>
{`#### Okay, there's a lot of information to take in above! *(...take your time)*

Actually just watching the code run will help you understand the \`if\` statement.
`}
<Button
  variant='raised'
  onClick={this.handleStepVideo}
  style={{float:'right'}}
>
  Video Steps
</Button>

{`
* Go ahead - type in the code and **try stepping through it!**
* Be careful with the ==indentation== on lines after the \`if:\` and \`else:\` statements.
* A colon '**:**' always precedes an **indented** block of code.
`}
<LegendMessageKeyboard>
{`Modify your code so that instead of *always* displaying the count, you show a TSHIRT Image if the count is 10 or higher.

*(showing just last few lines of code)*
\`\`\`
from microbit import *
display.show("3")
sleep(1000)
display.show("2")
sleep(1000)
display.show("1")
sleep(1000)

$$$
x = button_a.get_presses()
$if x > 9:
$    display.show(Image.TSHIRT)
$else:
$    display.scroll(str(x))
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`You'll *probably* want to **test** this game a few times :-)
`}
</LegendMessageRun>
<StepComplete
  prompt="Can you win the TSHIRT?"
  xp={25}
  successMessage=
{`## Micro-Arcade Time! {centered}
### Give me *one* more try, I **know** I can get to \`10\`!
`}
  reqImports={['microbit',]}
  reqCalls={['display.show', 'display.scroll', 'sleep', 'button_a.get_presses', 'str']}
  reqArgs={['"3"', '"2"', '"1"', 'Image.TSHIRT']}
  reqStatements={['if']}
  reqNames={['x']}
  lessons={this.props.lessons}
  btnYes={true}
  btnNo={true}
/>
</Markdown>
      </div>
    )
  }
}

class Unplugged extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
          {
`## Unplug! {lesson-title}
___
After your code is *running* on the **micro:bit**
> **You can go *unplugged***
`}
<img src={BattConn} alt="" style={{width: 200, margin:20, transform: 'rotate(-90deg)'}} />
<img src={MicrobitBatt} alt="microbit with battery" style={{width: 300, margin:20}} />
{
`
### Your projects *don't need a computer attached* after coding.

> What will *you* create with this **portable power**?
`}
<img src={MicrobitSkate} alt=""
  style={{width: 300, transition: 'transform 2s'}}
  onLoad={(ev) => ev.target.style.transform='translate(300px)'}
/>
<LegendMessageWarning title="Be Gentle with Cables!">
{`When you **unplug** a cable, **DO NOT PULL** on the **wire**!

Hold the **connector** firmly when you *disconnect* and *connect*.
`}
</LegendMessageWarning>
<LegendMessageTry title="Take it for a Spin">
{`
**Disconnect** your **micro:bit** from the USB cable, and connect the **battery pack**!
* Press the **reset** button on the micro:bit to ==reboot== and start your program
* *Wait* a few seconds for the program to start...
* *(You did put the batteries in it, didn't you?)*

The micro:bit is an **embedded computer** - meaning you can build it *into* other projects, to sense and
control stuff in the *physical world*!
`}
</LegendMessageTry>

</Markdown>
<StepComplete
  prompt="Ready for more? Click Next..."
  lessons={this.props.lessons}
  btnNext={true}
/>
      </div>
    )
  }
}


class GameOver extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## From Games to... Traffic Lights! {lesson-title}
___
`}
</Markdown>
<img src={TrafficLight} alt="" width={101} style={{margin: 10, float:'left'}} />
<div style={{display:'flex'}}>
<Markdown>
{`
### Python is great for coding games
### ...and you're just getting started!  {centered}

You'll soon discover a lot more possibilities as you learn more about the **micro:bit**, and learn to build more complex software with text-based code.

### Real World Applications
The **skills you learned** in this project are used by *professional Software Developers* to build:
* Traffic Light Counters
* Sports Event Scoreboards
* Counters for People and Vehicles

#### ...And of course:
* Fast-twitch button-press **games**!
`}
      </Markdown>
      </div>
        <StepComplete
          prompt="Nice work! More coding adventures await you in the Next project..."
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
FirstImage.stepId = 'FirstImage'
ImageSeries1.stepId = 'ImageSeries1'
ImageSeries2.stepId = 'ImageSeries2'
StepByStep.stepId = 'StepByStep'
StepByStepTry.stepId = 'StepByStepTry'
FreeStepDemo.stepId = 'FreeStepDemo'
DisplaySleep.stepId = 'DisplaySleep'
Variable.stepId = 'Variable'
Checkpoint1.stepId = 'CheckpointVarSleep'
IntroString.stepId = 'IntroString'
ScrollingText.stepId = 'ScrollingText'
GoodWithNumbers.stepId = 'GoodWithNumbers'
StringConversion.stepId = 'StringConversion'
Countdown.stepId = 'Countdown'
ButtonInput.stepId = 'ButtonInput'
WinningCondition.stepId = 'WinningCondition'
Unplugged.stepId = 'Unplugged'
GameOver.stepId = 'GameOver'

export const displayGames = [
  FirstImage,
  ImageSeries1,
  ImageSeries2,
  StepByStep,
  StepByStepTry,
  DisplaySleep,
  Variable,
  Checkpoint1,
  IntroString,
  ScrollingText,
  GoodWithNumbers,
  StringConversion,
  Countdown,
  ButtonInput,
  WinningCondition,
  Unplugged,
  GameOver,
]

export const displayGamesFree = [
  FirstImage,
  ImageSeries1,
  //ImageSeries2,
  // StepByStep,
  // StepByStepTry,
  FreeStepDemo,
  DisplaySleep,
  Variable,
  Checkpoint1,
  IntroString,
  ScrollingText,
  GoodWithNumbers,
  StringConversion,
  Countdown,
  ButtonInput,
  WinningCondition,
  Unplugged,
  GameOver,
]
