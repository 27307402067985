// Control the debugger
import serComm from './WebusbSerialBridge'
import {editorInst, editorInstancePromise} from './CodeEditor'
import syntaxChecker from './SyntaxChecker'
import mb from './mbEnum.js'
import Debounce from './Debounce.js'
import promiseTimeout from './PromiseTimeout'
import ErrorHelper from './ErrorHelper'
import { FirmwareHashes } from './Firmware'
import {cblFirebase} from "./myfirebase"
import {diagField} from './FiriaDiagnostics'

const CODEX_MK1 = 'CodeX Mk1' // The real Mark 1
//const CODEX_MK1 = '1' // Using a Kaluga-1 as a stand-in for a real Mk1

const CODEBOT_CB3 = 'CodeBot CB3'

// Latest version of CodeBot CB2 firmware, for version check
//const CB2_FIRMWARE_HASH = 'e69e8a26c'   // v0.40
const CB2_FIRMWARE_HASH = 'd43d6917c'

const CB3_FIRMWARE_HASH = '6.1.0-rc.1-440-g82a1f8ae4'
const CODEX_FIRMWARE_HASH = '6.1.0-rc.1-440-g82a1f8ae4'

/*
  REPL Control-code commands:
    ^A (1) - Enter raw mode (no echo, simple prompt)
    ^B (2) - Exit raw mode
    ^C (3) - Break
    ^D (4) - Finish (submit) input in raw or paste modes | soft-reboot in REPL mode
    ^E (5) - Enter paste mode
*/
const CTL_ENTER_RAW = 1
const CTL_ENTER_RAW_CHR = String.fromCharCode(CTL_ENTER_RAW)
const CTL_EXIT_RAW = 2
const CTL_EXIT_RAW_CHR = String.fromCharCode(CTL_EXIT_RAW)
const CTL_BREAK = 3
const CTL_BREAK_CHR = String.fromCharCode(CTL_BREAK)
const CTL_FINISH = 4
const CTL_FINISH_CHR = String.fromCharCode(CTL_FINISH)
//const CTL_ENTER_PASTE = 5
//const CTL_ENTER_PASTE_CHR = String.fromCharCode(CTL_ENTER_PASTE)

// REPL embedded escape sequences delimiting debugger output
const REPL_ESC_DEBUG = "\xC2"
const REPL_ENTER_DEBUG = "\xAB"
const REPL_EXIT_DEBUG = "\xBB"
const REPL_VAR_PREFIX = "\xB7"

// Console filter states
const CF_STATE_ON = 0
const CF_STATE_ON_ESC = 1
const CF_STATE_OFF = 2
const CF_STATE_OFF_ESC = 3

// Vars RegEx expects REPL_VAR_PREFIX and also handles recursive ENTER/EXIT_DEBUG while getting __repr__ vals.
const varsRegEx = /(.*):\xc2\xb7(?:\xc2\xab)*(?:\xc2\xbb)*(.*)\xc2\xb7(.*)/
//const varsRegEx = /(.*):·(.*)·(.*)/  // this version when using stream textDecoder for serial rx handling
const tracebackRegEx = /(?:Traceback \(most recent call last\):)$\s+File(?:.*$\s+File)* "(.+)", line (\d+),?.*\r*\n(\S*:.*)\r*\n/gm
const localsRequestRegEx = /<<< d/
const globalsRequestRegEx = /<<< g(\d+)/
// eslint-disable-next-line
const replPromptRegEx = /\x0A\x3E\x3E\x3E\x20/gm
// eslint-disable-next-line
const debugPromptRegEx = /\x0A\x3C\x3C\x3C\x20/gm
const uPythonBootRegEx = /MicroPython v(\S*)-(?:([0-9a-fA-F]+)|\S*)(?:_dev)? on ([\d-]*); ([0-9a-fA-F]{8} )?micro:bit/gm
const codebotBootRegEx = /MicroPython ([0-9a-fA-F]+).* on ([\d-]*); ([0-9a-fA-F]+).* (\S*) with (\S*)/gm
const codebot3BootRegEx = /FiriaLabs IoT Python (.*) on ([\d-]*); ([0-9a-fA-F]+).* (CodeBot \S*) with (\S*)/gm
const codexBootRegEx = /FiriaLabs IoT Python (.*) on ([\d-]*); ([0-9a-fA-F]+).* (CodeX \S*) with (\S*)/gm
const unableToSetBkptRegEx = /ba (\d+)\s*Not an executable source line/gm
export let traceback = null

class DebugController {
  constructor() {
    this.commandBuf = ''
    this.accumulatedBuf = ''
    this.varsBuf = ''
    this.curDebugCursor = null
    this.curCursorHighlightRng = null
    this.priorLineHighlight = null
    this.priorLineNum = null
    this.console = null
    this.isStopped = false
    this.enConsole = true
    this.consoleFilterState = CF_STATE_ON
    this.consoleFilterDebugRecurse = 0  // >0 indicates count of levels of REPL_ENTER_DEBUG recursion
    this.downloading = false
    this.lastGlobalNum = 0
    this.fetchingVars = false
    this.devicePythonHash = ''
    this.mbVersionStr = ''
    this.mbUpgradeNag = true   // Only nag once per mb-connect for out of date firmware
    this.mbMac32 = 0
    this.devicePlatform = 'DEVICE'
    this.deviceNumBreakpoints = 0   // Number of supported breakpoints on connected platform

    this.onCmdPrompt = null
    this.onCmdFail = null
    this.cmdExpectExpr = null

    this.localVars = []
    this.globalVars = []
    this.onLocalVarChangeCb = null
    this.onGlobalVarChangeCb = null
    this.expectingGlobalVars = false
    this.onStoppedCb = null
    this.onStartDownload = null
    this.onEndDownload = null
    this.onTracebackCb = null
    this.onErrorCb = null
    this.onDownloadFailed = null
    this.openOutDateNotice = null
    this.onPlatformVersionCb = null

    this.mbMode = mb.mode.UNKNOWN
    this.mbState = mb.state.UNKNOWN
    this.onMbStateChangeCb = null
    this.onMbModeChangeCb = null
    this.onRunCb = null

    this.deferCb = new Debounce()

    this.tracebackBuffer = ''
    this.lastLoadedCode = '' // we could store a hash instead if we're worried about RAM. Then again, it's chrome...
    this.currentFilename = null

    this.firmwareHash = new FirmwareHashes()
    // console.log(this.firmwareHash.DAPLink)
    // console.log(this.firmwareHash.MicroPython)

    window.firiaSerialRxDebug = ""

    editorInstancePromise.then(() => {
      editorInst.session.on("setSingleBreakpoint", this.handleSetSingleBreakpoint)
      editorInst.session.on("clearSingleBreakpoint", this.handleClearSingleBreakpoint)
    })

    serComm.firstConnectPromise.then(() => {
      // Send something so we can try and determine if the debugger is enabled
      this.stop()
    })
  }

  updateFilename = (filename) => {
    this.currentFilename = filename
  }

  appendSerialRxDebug = (buf) => {
    // Maintain a small receive buffer for diagnostics
    window.firiaSerialRxDebug += buf
    window.firiaSerialRxDebug = window.firiaSerialRxDebug.slice(-1000)
  }

  handleDeviceConnect = () => {
    //console.log(`Connect: mbstate=${this.mbState}, mbmode=${this.mbMode}`)
    // On slow machines like Chromebooks, we may miss the connected device's boot message.
    // Since we not only need current "state" but also boot info like version and devicePlatform,
    // we need to issue a soft reboot.
    // Defering for 50ms to allow processing of serial buffer, then check if devicePlatform still unknown.
    // If so, send stop().
    setTimeout(() => {
      if (this.devicePlatform === 'DEVICE') {
        this.mbMode = mb.mode.DEBUG
        this.stop()
      }
    }, 50)

    this.consoleFilterState = CF_STATE_ON
    this.consoleFilterDebugRecurse = 0
  }

  handleDeviceDisconnect = () => {
    this.setDisconnected()
  }

  removeInvalidBreakpoints = () => {
    const dec = editorInst.session.$decorations
    let removed = false
    for (let i = 0; i < dec.length; ++i) {
      if (dec[i] && dec[i].search("invalid-breakpoint") >= 0) {
        dec[i] = ""
        removed = true
      }
    }
    if (removed) {
      // Notify Ace and observers of potential changes.
      editorInst.session._signal("changeBreakpoint", {})
    }
  }

  handleSetSingleBreakpoint = (row) => {
    // If breakpoints are changed while debugging and idle, send changes to device
    if (this.mbMode === mb.mode.DEBUG && this.mbState === mb.state.IDLE) {
      const rowNum = parseInt(row, 10) + 1
      this.sendDebuggerCmd(`ba ${rowNum}\r`, true)  // Add breakpoint to 'main.py'
    }
  }

  handleClearSingleBreakpoint = (row) => {
    // If breakpoints are changed while debugging and idle, send changes to device
    if (this.mbMode === mb.mode.DEBUG && this.mbState === mb.state.IDLE) {
      const rowNum = parseInt(row, 10) + 1
      this.sendDebuggerCmd(`bd ${rowNum}\r`, true)  // Delete breakpoint from 'main.py'
    }
  }

  // This function only knows about the current session, which is good enough for lessons to check if user
  // has run code, but not to _really_ know that the current code running in micro:bit is that in Editor.
  // For that we need to store a hash on MB's filesystem. See BBC-81
  isLatestCodeLoaded = () => {
    return this.lastLoadedCode === editorInst.getValue()
  }

  checkSyntax = () => {
    const numErrors = syntaxChecker.check()
    return numErrors === 0  // Syntax is good
  }

  // Send "unlock" key to micropython debugger.
  // Note: since this currently relies on "sendCommand" it can only be called during download state.
  sendKey = async () => {
    //uint32_t key0 = rnd32
    //uint32_t key1 = (mac32 * 9127) ^ (rnd32 * 9127)
    const key0 = Math.trunc(Math.random() * 0xFFFFFFFF) >>> 0
    const key1 = (((this.mbMac32 * 9127) & 0xFFFFFFFF) ^ (key0 * 9127)) >>> 0
    //console.log(`Unlock code: key0 = 0x${key0.toString(16)}, key1 = 0x${key1.toString(16)}`)

    // Send unlock command
    // Sometimes receive double-prompt, so require unique prompt 'expect' regex.
    const expectRegEx = RegExp(`${key1.toString(36)}\r\n<<<`.slice(-8))
    await this.sendCommand(`v${key0.toString(36)},${key1.toString(36)}\r`, expectRegEx, false)
  }

  checkVersionMicrobit = (bootMatch) => {
    // Groups: 1)version, 2)hash, 3)date 4)mac
    // E.g. "MicroPython v1.7.11-FiriaLabs-2fd9807 on 2018-01-29; 1f2f3f4f micro:bit"
    //                      1^               2^          3^          4^
    this.devicePlatform = 'MICROBIT'
    this.deviceNumBreakpoints = 0
    this.mbVersionStr = bootMatch[1]
    this.devicePythonHash = bootMatch[2]
    this.mbMac32 = bootMatch[4] ? Number.parseInt(bootMatch[4], 16) : 0

    if (this.onPlatformVersionCb) {
      this.onPlatformVersionCb()
    }

    const needUpgrade = !this.devicePythonHash || (this.devicePythonHash !== this.firmwareHash.MicroPython)

    if (needUpgrade && this.mbUpgradeNag) {
      this.mbUpgradeNag = false
      this.openOutDateNotice()
    }
  }

  checkVersionCodebot = (bootMatch) => {
    // Groups: 1)hash, 2)date, 3)platform, 4)CPU
    // E.g. "MicroPython 64453a93a on 2019-05-01; 4F126609 CODEBOT_CB2 with STM32L476"
    //                      1^             2^         3^        4^             5^
    this.devicePythonHash = bootMatch[1]
    this.devicePlatform = bootMatch[4]
    this.deviceNumBreakpoints = 16

    if (this.onPlatformVersionCb) {
      this.onPlatformVersionCb()
    }

    // Check version hash against latest version CodeBot hash
    const needUpgrade = !this.devicePythonHash || (this.devicePythonHash !== CB2_FIRMWARE_HASH)

    if (needUpgrade && this.mbUpgradeNag) {
      this.mbUpgradeNag = false
      this.openOutDateNotice()
    }
  }

  checkVersionCodebot3 = (bootMatch) => {
    // Groups: 1)hash, 2)date, 3)platform, 4)CPU
    // E.g. FiriaLabs IoT Python 6.1.0-rc.1-414-gdba2453ec on 2021-09-13; 00000000 CodeBot CB3 with ESP32S2
    //                              1^                         2^           3^        4^              5^
    this.devicePythonHash = bootMatch[1]
    this.devicePlatform = bootMatch[4]
    this.deviceNumBreakpoints = 16

    if (this.onPlatformVersionCb) {
      this.onPlatformVersionCb()
    }

    // Check version hash against latest version CodeBot hash
    const needUpgrade = !this.devicePythonHash || (this.devicePythonHash !== CB3_FIRMWARE_HASH)

    if (needUpgrade && this.mbUpgradeNag) {
      this.mbUpgradeNag = false
      this.openOutDateNotice()
    }
  }

  checkVersionCodex = (bootMatch) => {
    // Groups: 1)hash, 2)date, 3)platform, 4)CPU
    // E.g. FiriaLabs IoT Python 6.1.0-rc.1-414-gdba2453ec on 2021-09-13; 00000000 CodeX Mk1 with ESP32S2
    //                              1^                         2^           3^        4^              5^
    this.devicePythonHash = bootMatch[1]
    this.devicePlatform = bootMatch[4]
    this.deviceNumBreakpoints = 16

    if (this.onPlatformVersionCb) {
      this.onPlatformVersionCb()
    }

    // Check version hash against latest version CodeBot hash
    const needUpgrade = !this.devicePythonHash || (this.devicePythonHash !== CODEX_FIRMWARE_HASH)

    if (needUpgrade && this.mbUpgradeNag) {
      this.mbUpgradeNag = false
      this.openOutDateNotice()
    }
  }

  resetVersionInfo = () => {
    this.devicePlatform = 'DEVICE'
    this.deviceNumBreakpoints = 0
    this.devicePythonHash = ''
    this.mbVersionStr = ''
    this.mbUpgradeNag = true
  }

  setStopped = (isStopped) => {
    if (isStopped) {
      this.priorLineNum = null
      editorInst.setHighlightActiveLine(true)
    }
    if (this.onStoppedCb) {
      this.deferCb.cb(this.onStoppedCb, 100, isStopped)
    }
    this.isStopped = isStopped
  }

  setDisconnected = () => {
    this.setMbMode(mb.mode.UNKNOWN)
    this.setMbState(mb.state.UNKNOWN)
    this.setStopped(true)
    this.setDebugCursor(null)
    this.resetVersionInfo()
    if (this.downloading) {
      this.enConsole = true
      this.downloading = false
      if (this.onEndDownload) {
        this.onEndDownload()
      }
    }
  }

  registerConsole = (consoleInst) => {
    this.console = consoleInst
  }

  setLocalVarChangeCallback = (callback) => {
    this.onLocalVarChangeCb = callback
  }

  setGlobalVarChangeCallback = (callback) => {
    this.onGlobalVarChangeCb = callback
  }

  setMbStateChangeCallback = (callback) => {
    this.onMbStateChangeCb = callback
  }

  setMbModeChangeCallback = (callback) => {
    this.onMbModeChangeCb = callback
  }

  setOnRunCallback = (callback) => {
    this.onRunCb = callback
  }

  setStoppedCallback = (callback) => {
    this.onStoppedCb = callback
  }

  setDownloadHandlers = (onStart, onEnd) => {
    this.onStartDownload = onStart
    this.onEndDownload = onEnd
  }

  setTracebackCallback = (callback) => {
    this.onTracebackCb = callback
  }

  setErrorCallback = (callback) => {
    this.onErrorCb = callback
  }

  setDownloadFailedCallback = (callback) => {
    this.onDownloadFailed = callback
  }

  setOutOfDateCallback = (callback) => {
    this.openOutDateNotice = callback
  }

  setPlatformVersionCallback = (callback) => {
    this.onPlatformVersionCb = callback
  }

  setMbState = (state) => {
    if (this.mbState !== state) {
      // console.log(`DEBUG state from [ ${this.mbState} ${mb.getStateName(this.mbState)} ] to [ ${state} ${mb.getStateName(state)} ]`)
      this.mbState = state
      if (this.onMbStateChangeCb) {
        this.deferCb.cb(this.onMbStateChangeCb, 100, this.mbState)
      }
    }
  }

  setMbMode = (mode) => {
    if (this.mbMode !== mode) {
      // console.log(`DEBUG mode from [ ${this.mbMode} ${mb.getModeName(this.mbMode)} ] to [ ${mode} ${mb.getModeName(mode)} ]`)
      this.mbMode = mode
      if (this.onMbModeChangeCb) {
        this.deferCb.cb(this.onMbModeChangeCb, 100, this.mbMode)
      }
    }
  }

  // Place debug highlight/pointer on current line
  setDebugCursor = (line) => {
    // console.log("setDebugCursor = ", line)
    if (editorInst === null) {
      return
    }
    const session = editorInst.getSession()
    if (this.curDebugCursor !== null) {
      session.removeGutterDecoration(this.curDebugCursor - 1, "debug_pointer")
      session.removeMarker(this.curCursorHighlightRng.id)

      if (this.priorLineHighlight) {
        session.removeMarker(this.priorLineHighlight.id)
      }

      // Re-Enable highlight of active (cursor) line
      if (this.isStopped) {
        editorInst.setHighlightActiveLine(true)
      }
    }
    if (line !== null) {
      session.addGutterDecoration(line - 1, "debug_pointer")
      this.curCursorHighlightRng = session.highlightLines(line - 1, line - 1)  // default style is 'ace_step' (in theme css)

      // Keep selection in view, not centered (as scrollToLine) or top (as scrollToRow)
      editorInst.renderer.scrollSelectionIntoView(this.curCursorHighlightRng.start, this.curCursorHighlightRng.end, 0.5)
      editorInst.renderer.scrollToX(0)   // Keep left. Otherwise will jump to end of long lines while stepping

      // Disable highlight of active (cursor) line to avoid confusion with debug-pointer
      editorInst.setHighlightActiveLine(false)
      editorInst.clearSelection()

      // Highlight prior line
      if (this.priorLineNum) {
        this.priorLineHighlight = session.highlightLines(this.priorLineNum - 1, this.priorLineNum - 1, 'dbg_runline')
      }
      this.priorLineNum = line
    }
    this.curDebugCursor = line
  }

  // Track state of serial data stream - from debugger vs REPL/stdout
  trackDebugVsRepl = (strBuf) => {
    /* Note that depending on the speed of the computer, multiple messages can be received in a single buffer
    payload, therefore it is required that we recurse through the buffer until the LAST message has been
    identified. Otherwise the order that we check them will influence the current state.
    */
    this.accumulatedBuf += strBuf
    // console.log(`--ACCUM_BUF--\n${this.accumulatedBuf}\n--END--`)
    let regExMatch = false

    // CircuitPython introduces an additional prompt instead of going directly into the REPL
    // Go ahead and press ENTER so the usual startup banner will appear
    const replOfferRegEx = /Press any key to enter the REPL. Use CTRL-D to reload./gm
    const replOfferMatch = replOfferRegEx.exec(this.accumulatedBuf)
    if (replOfferMatch) {
      this.sendDebuggerCmd('\r', true)
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, replOfferMatch.index) + this.accumulatedBuf.slice(replOfferRegEx.lastIndex)
    }

    /*
    const stoppedExpr = /MP-Complete$/gm
    m = stoppedExpr.exec(this.accumulatedBuf)
    if (m) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, m.index) + this.accumulatedBuf.slice(stoppedExpr.lastIndex)
      this.setStopped(true)
      this.setDebugCursor(null)
    }
    */

    // Version Check (boot string)
    // TODO: mod CodeBot to use same boot string format as microbit, and merge these checks.
    uPythonBootRegEx.lastIndex = 0
    const uPythonBootMatch = uPythonBootRegEx.exec(this.accumulatedBuf)
    if (uPythonBootMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, uPythonBootMatch.index) + this.accumulatedBuf.slice(uPythonBootRegEx.lastIndex)
      this.checkVersionMicrobit(uPythonBootMatch)
    }

    // TODO: mod CodeBot to use same boot string format as microbit, and merge these checks.
    codebotBootRegEx.lastIndex = 0
    const codebotBootMatch = codebotBootRegEx.exec(this.accumulatedBuf)
    if (codebotBootMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, codebotBootMatch.index) + this.accumulatedBuf.slice(codebotBootRegEx.lastIndex)
      this.checkVersionCodebot(codebotBootMatch)
    }

    codebot3BootRegEx.lastIndex = 0
    const codebot3BootMatch = codebot3BootRegEx.exec(this.accumulatedBuf)
    if (codebot3BootMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, codebot3BootMatch.index) + this.accumulatedBuf.slice(codebot3BootRegEx.lastIndex)
      this.checkVersionCodebot3(codebot3BootMatch)
    }

    codexBootRegEx.lastIndex = 0
    const codexBootMatch = codexBootRegEx.exec(this.accumulatedBuf)
    if (codexBootMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, codexBootMatch.index) + this.accumulatedBuf.slice(codexBootRegEx.lastIndex)
      this.checkVersionCodex(codexBootMatch)
    }

    unableToSetBkptRegEx.lastIndex = 0
    const unableToSetBkptMatch = unableToSetBkptRegEx.exec(this.accumulatedBuf)
    if (unableToSetBkptMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, unableToSetBkptMatch.index) + this.accumulatedBuf.slice(unableToSetBkptRegEx.lastIndex)
      // console.log("Unable to set breakpoint", unableToSetBkptMatch)
      const line = parseInt(unableToSetBkptMatch[1], 10)
      // TODO: Popup "toast" warning "Breakpoint removed from non-executable line"
      editorInst.session.clearBreakpoint(line - 1)
      editorInst.session.addGutterDecoration(line - 1, "invalid-breakpoint")
      // Notify Ace and observers of potential changes.
      editorInst.session._signal("changeBreakpoint", {})
    }

    const terminatedExprRegEx = /debugger disarmed/gm
    const terminatedExprMatch = terminatedExprRegEx.exec(this.accumulatedBuf)
    if (terminatedExprMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, terminatedExprMatch.index) + this.accumulatedBuf.slice(terminatedExprRegEx.lastIndex)
      this.setStopped(false)
      this.setMbState(mb.state.RUNNING)
      this.hiddenDebugCursor = this.curDebugCursor
      this.setDebugCursor(null)
    }

    replPromptRegEx.lastIndex = 0
    const replMatch = replPromptRegEx.exec(this.accumulatedBuf)
    if (replMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, replMatch.index) + this.accumulatedBuf.slice(replPromptRegEx.lastIndex)
      if (!terminatedExprMatch || replMatch.index > terminatedExprMatch.index) {
        this.setStopped(true)
        this.setMbMode(mb.mode.REPL)
        this.setMbState(mb.state.IDLE)
        this.hiddenDebugCursor = this.curDebugCursor
        this.setDebugCursor(null)
      }
    }

    debugPromptRegEx.lastIndex = 0
    const debugMatch = debugPromptRegEx.exec(this.accumulatedBuf)
    if (debugMatch) {
      regExMatch = true
      this.accumulatedBuf = this.accumulatedBuf.slice(0, debugMatch.index) + this.accumulatedBuf.slice(debugPromptRegEx.lastIndex)
      if ( (!terminatedExprMatch || debugMatch.index > terminatedExprMatch.index) &&
           (!replMatch || debugMatch.index > replMatch.index) )
      {
        this.setStopped(false)
        this.setMbMode(mb.mode.DEBUG)
        this.setMbState(mb.state.IDLE)
        if (!this.curDebugCursor && this.hiddenDebugCursor) {
          this.setDebugCursor(this.hiddenDebugCursor)
        }
      }
    }

    // Make sure that we handle multiple (of the same message) in the buffer within a single call
    // to trackDebugVsRepl
    if (regExMatch) {
      this.accumulatedBuf = this.accumulatedBuf.slice(1)
      if (this.accumulatedBuf) {
        //console.log("More accumulated", this.accumulatedBuf)
        this.trackDebugVsRepl("")
      }
    }
    // console.log(`--mbMode=${mb.getModeName(this.mbMode)}, mbState=${mb.getStateName(this.mbState)}, isStopped=${this.isStopped}--`)
  }

  handleCommandResponseRx = (buf) => {
    this.commandBuf += buf
    // console.log("DL-rx:", buf)

    if (this.onCmdFail && this.commandBuf.includes('Traceback')) {
      this.commandBuf = ''
      this.onCmdFail("Traceback error")
    }
    // Check for command prompt if we're waiting for it
    else if (this.onCmdPrompt) {
      const result = this.cmdExpectExpr.exec(this.commandBuf)
      if (result) {
        // console.log("Got prompt")
        this.commandBuf = ''
        this.onCmdPrompt()
        this.onCmdPrompt = null
      }
    }
  }

  checkForVars = (buf) => {
    this.varsBuf += buf
    // console.log(`--VARSBUF--\n${this.varsBuf}\n--END--\n`)
    let globalVarsChanged = false
    let localVarsChanged = false
    let sawLine = false

    if (this.varsBuf.includes('\r')) {
      // console.log('Saw carriage return')

      const lastReturn = this.varsBuf.lastIndexOf('\r')
      this.varsBuf.slice(0, lastReturn + 1).split('\r').forEach((line) => {
        // Step to current line
        const lineExpr = /block=(.*?) file=(.*?) line=(\d+)/
        let m = lineExpr.exec(line)
        if (m && (m[2] === '__main__' || m[2] === 'main.py')) {
          let lineNum = parseInt(m[3], 10)
          if (this.mbMode === mb.mode.DEBUG) {
            this.fetchingVars = true
            this.sendDebuggerCmd('d\r', true)
            sawLine = true
          }
          this.setDebugCursor(lineNum)
        }
        localsRequestRegEx.lastIndex = 0
        if (localsRequestRegEx.test(line)) {
          // console.log('saw local vars request')
          this.localVars = []
          this.expectingGlobalVars = false
          this.sendDebuggerCmd('g0\r', true)
          //this.deferCb.cb(this.sendDebuggerCmd, 1000, 'g0\r', true)
        } else {
          globalsRequestRegEx.lastIndex = 0
          m = globalsRequestRegEx.exec(line)
          if (m) {
            // console.log('saw global vars request')
            this.lastGlobalNum = parseInt(m[1], 10)
            if (this.lastGlobalNum === 0) {
              this.globalVars = []
            }
            this.expectingGlobalVars = true
            this.setMbState(mb.state.RUNNING)
          }
        }

        varsRegEx.lastIndex = 0
        m = varsRegEx.exec(line)
        if (m) {
          let vars = this.localVars
          if (this.expectingGlobalVars) {
            vars = this.globalVars
            globalVarsChanged = true
          } else {
            localVarsChanged = true
          }

          vars.push({
            name: m[1],
            val: m[2],
            type: m[3],
          })
        }
      })

      this.varsBuf = this.varsBuf.slice(lastReturn + 1)

      if (this.onLocalVarChangeCb && localVarsChanged) {
        this.deferCb.cb(this.onLocalVarChangeCb, 100, this.localVars)
      }
      if (this.onGlobalVarChangeCb && globalVarsChanged) {
        this.deferCb.cb(this.onGlobalVarChangeCb, 100, this.globalVars)
      }

      // console.log('global count', this.globalVars.length, this.lastGlobalNum)
      if (globalVarsChanged && this.fetchingVars) {
        if (this.lastGlobalNum < 9 && this.globalVars.length === (this.lastGlobalNum + 1) * 8) {
          this.sendDebuggerCmd(`g${this.lastGlobalNum + 1}\r`, true)
          //this.deferCb.cb(this.sendDebuggerCmd, 1000, `g${this.lastGlobalNum + 1}\r`, true)
          this.fetchingVars = true
        } else if (!sawLine && this.varsBuf.slice(-4) === '<<< ') {
          this.fetchingVars = false
          this.enConsole = true
        }
      }
    }

    // console.log(`--VARSBUF--\n${this.varsBuf}\n--END--\n`)
  }

  checkForTraceback = (strBuf) => {
    this.tracebackBuffer += strBuf

    // If multiple tracebacks exist, process only the first one
    if (this.tracebackBuffer.includes("Traceback")) {
      let firstTracebackPos = this.tracebackBuffer.indexOf("Traceback")
      let secondTracebackPos = this.tracebackBuffer.indexOf("Traceback", firstTracebackPos+9)
      let tempBuffer = ""
      if (secondTracebackPos > 0) {
        tempBuffer = this.tracebackBuffer.slice(firstTracebackPos, secondTracebackPos)
      }
      else {
        tempBuffer = this.tracebackBuffer.slice(firstTracebackPos)
      }

      tracebackRegEx.lastIndex = 0
      const m = tracebackRegEx.exec(tempBuffer)
      // console.log("Checking for traceback: ", this.tracebackBuffer)
      if (m !== null && m[1] !== '<stdin>' && m[3].indexOf('KeyboardInterrupt') === -1) {
        let filename = m[1]
        let line_num = m[2]
        let err_msg = m[3]

        if (filename !== '__main__' && filename !== 'main.py') {
          // Append point-of-error filename and line number to message
          err_msg = `${err_msg}\n (${filename} line ${line_num})`

          // Find last instance of 'main' module in traceback, and set line_number there.
          const traceStackEntryRegEx = /File "(__main__|main\.py)", line (\d*)/gm
          const traceArray = [...tempBuffer.matchAll(traceStackEntryRegEx)]
          if (traceArray) {
            line_num = traceArray[traceArray.length - 1][2]
          }
          else {
            line_num = "1"   // Bail and set to first line
          }
        }

        const errorHelper = new ErrorHelper()
        const friendlyError = errorHelper.friendlyError(err_msg, errorHelper.ERR_RUNTIME)
        traceback = {
          file: filename,
          line: parseInt(line_num, 10),
          msg: friendlyError.errorMsg,
        }
        const logContent = {
          userCode: `${editorInst.getValue()}\n`,
          traceback: tempBuffer,
          line: traceback.line,
          file: filename,
          errorMsg: friendlyError.errorMsg,
        }

        if (this.onTracebackCb) this.onTracebackCb(traceback)
        cblFirebase.logError({
          type: 'runtime',
          category: friendlyError.errCategory,
          content: logContent,
        })

        this.tracebackBuffer = ''
        this.setStopped(true)
      }
    }

    if (this.tracebackBuffer.length > 500) {
      this.tracebackBuffer = this.tracebackBuffer.substr(-500)
    }
    // console.log(this.tracebacks)
  }

  // Filter debugger-specific output from console buffer (prior to sending to ConsoleRepl)
  filterConsole = (buf) => {
    // Expect to be called on fragments of debugger output stream, so run bytewise state machine.
    let outBuf = ''
    for (let i = 0; i < buf.length; i++) {
      const c = buf[i]
      if (this.consoleFilterState === CF_STATE_ON) {
        if (c === REPL_ESC_DEBUG) {
          this.consoleFilterState = CF_STATE_ON_ESC
          //console.log("cf=CF_STATE_ON_ESC")
        }
        else {
          outBuf += c
        }
      }
      else if (this.consoleFilterState === CF_STATE_ON_ESC) {
        if (c === REPL_ENTER_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF
          //console.log("cf=CF_STATE_OFF (enter debug)")
        }
        else if (c === REPL_VAR_PREFIX) {
          this.consoleFilterState = CF_STATE_OFF
          //console.log("cf=CF_STATE_OFF (var prefix)")
        }
        else {
          outBuf += REPL_ESC_DEBUG + c
          this.consoleFilterState = CF_STATE_ON
          //console.log(`cf=CF_STATE_ON (c=0x${c.charCodeAt(0).toString(16)})`)
        }
      }
      else if (this.consoleFilterState === CF_STATE_OFF) {
        if (c === REPL_ESC_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF_ESC
          //console.log("cf=CF_STATE_OFF_ESC")
        }
      }
      else if (this.consoleFilterState === CF_STATE_OFF_ESC) {
        if (c === REPL_EXIT_DEBUG) {
          if (this.consoleFilterDebugRecurse > 0) {
            this.consoleFilterDebugRecurse--
            this.consoleFilterState = CF_STATE_OFF
            //console.log(`cf=CF_STATE_OFF (recursive exit debug level ${this.consoleFilterDebugRecurse})`)
          }
          else {
            this.consoleFilterState = CF_STATE_ON
            //console.log("cf=CF_STATE_ON (exit debug)")
          }
        }
        else if (c === REPL_ENTER_DEBUG) {
          this.consoleFilterState = CF_STATE_OFF
          this.consoleFilterDebugRecurse++
          //console.log("cf=CF_STATE_OFF (recursive enter debug)")
        } else if (c === REPL_VAR_PREFIX) {
            this.consoleFilterState = CF_STATE_OFF
            //console.log("cf=CF_STATE_OFF (var prefix)")
        } else {
          this.consoleFilterState = CF_STATE_OFF
          //console.log(`cf=CF_STATE_OFF (c=0x${c.charCodeAt(0).toString(16)})`)
        }
      }
    }
    //console.log(`CF: in="${buf}" out="${outBuf}"`)
    return outBuf
  }

  // Characters received from serial port (echoed commands + responses)
  handleSerialChars = (buf) => {
    const strBuf = String.fromCharCode(...buf)
    // console.log(`rx[${strBuf}]`)
    this.appendSerialRxDebug(strBuf)
    this.handleCommandResponseRx(strBuf)
    if (!this.downloading) {
      if (this.mbMode === mb.mode.DEBUG) {
        this.checkForVars(strBuf)
      }
      this.trackDebugVsRepl(strBuf)
    }

    // Previously skipped checkForTraceback() if mb.state.IDLE, to avoid flagging errors in editor
    // based on REPL commands. However, on slow machines and/or tiny programs a run can cycle back to IDLE
    // in a single serial buffer, so even when state is IDLE we must check for traceback.
    this.checkForTraceback(strBuf)

    if (this.enConsole && this.console) {
      const buf = this.filterConsole(strBuf)
      if (diagField('rawConsole')) {
        this.console.appendChars(strBuf)
      } else {
        this.console.appendChars(buf)
      }
    }
  }

  load = async () => {
    this.downloading = true
    this.accumulatedBuf = ''
    this.tracebackBuffer = ''
    traceback = null

    this.enConsole = false
    // console.log('onStartDownload')
    if (this.onStartDownload) {
      this.onStartDownload()
    }

    // Check syntax prior to downloading, but inside "onStartDownload" to show progress during syntax check also.
    // Note: currently checkSyntax blocks, so this doesn't work. Need to push it to WebWorker and await completion asynchronously.
    if (this.checkSyntax()) {

      // Download and reset to start
      try {
        await this.download('main.py')

        // Send breakpoints
        if (this.deviceNumBreakpoints > 0) {
          this.downloading = false
          await this.sendAllBreakpoints()
        }

      }
      catch (error) {
        // Enable specific error handling behavior
        if (error === "Traceback error") {
          // The traceback error callback is already being invoked, so there is no reason to invoke it here
        }
        else {
          // Display download failed error, only if we don't have a more specific error to display
          this.onDownloadFailed()
          console.log("Download Failed", {error: error, rxBuf: window.firiaSerialRxDebug})
        }
        serComm.send(CTL_BREAK_CHR)   // ^C may be needed to exit triple-quotes
        this.stop()
        // console.log("***** Download error ***** - Did debug state return to normal?")
      }

    }
    this.enConsole = true
    this.downloading = false

    // console.log('onEndDownload')
    if (this.onEndDownload) {
      this.onEndDownload()
    }
  }

  stepNext = () => {
    this.sendDebuggerCmd('n\r')
    this.setDebugCursor(null)
  }

  stepInto = async () => {
    // console.log("Debugger, Step Into: ")
    this.setDebugCursor(null)
    if (this.mbMode === mb.mode.REPL && this.mbState === mb.state.IDLE) {
      // console.log("\tIn REPL+IDLE, downloading.")
      await this.load()
      this.sendDebuggerCmd('w\r')
    } else if (this.mbMode === mb.mode.DEBUG && this.mbState === mb.state.IDLE) {
      // console.log("\tIn DEBUG+IDLE, stepping in.")
      // Step Into
      this.sendDebuggerCmd('s\r')
    } else {
      // console.log("\tUnsupported mode&state. Mode: ", this.mbMode, " State: ", this.mbState)
    }
  }

  stepOut = () => {
    this.sendDebuggerCmd('r\r')
  }

  stop = () => {
    // console.log("Debugger, Stop.")
    if (this.devicePlatform === 'CODEBOT_CB2') {
      // CodeBot only requires this sequence, AND experiences FATAL unhandled exception errors when you 
      // issue a stop() while single-stepping if you do the full sequence with 'c\r' appended.
      // TODO: Investigate the FATAL error case...
      this.sendDebuggerCmd('\x02\x03c\r')  // Exit raw, ^C, 'c' to continue debugger, return for prompt
    } else if ((this.devicePlatform === CODEBOT_CB3) || (this.devicePlatform === CODEX_MK1)) {
      // NOTE! Requires 08/08/2021 CircuitPython mods INCLUDING TinyUSB library changes!
      this.sendDebuggerCmd('\x02\x03')  // Exit raw, ^C
    } else {
      // This stop() code is used for microbit, AND for any unknown device at onDeviceConnect().
      // Fortunately CodeBot seems to survive the latter case okay.
      if (this.mbMode === mb.mode.DEBUG) {
        this.sendDebuggerCmd('\x02\x03c\r')  // Exit raw mode, ^C, 'c' to continue debugger, return for prompt
      }
      else {
        this.sendDebuggerCmd('\x02\x03\r')  // Exit raw mode, ^C, return for prompt
      }
    }
    this.setDebugCursor(null)
    this.setStopped(true)
  }

  break = () => {
    serComm.send(CTL_BREAK_CHR)
  }

  play = async () => {
    // console.log("Debugger, Play: ")
    if (this.mbMode === mb.mode.REPL && this.mbState === mb.state.IDLE) {
      // console.log("\tIn REPL+IDLE, downloading and continuing.")
      await this.load()
      // Continue
      this.sendDebuggerCmd('c\r')
    } else if (this.mbMode === mb.mode.DEBUG && this.mbState === mb.state.IDLE) {
      // console.log("\tIn DEBUG+IDLE, continuing.")
      // Continue
      this.sendDebuggerCmd('c\r')
    } else {
      // console.log("\tUnsupported mode&state. Mode: ", this.mbMode, " State: ", this.mbState)
    }
    if (this.onRunCb) {
      this.onRunCb()
    }
  }

  sendDebuggerCmd = (cmd, internal = false) => {
    // console.log("sendDebuggerCmd: ", cmd)
    serComm.send(cmd)
    this.setMbState(mb.state.RUNNING)
    if (!internal) {
      this.fetchingVars = false
      if (this.onLocalVarChangeCb) {
        this.deferCb.cb(this.onLocalVarChangeCb, 500, [])
      }
      if (this.onGlobalVarChangeCb) {
        this.deferCb.cb(this.onGlobalVarChangeCb, 500, [])
      }
    }
  }

  sendCommand = (cmd, expect=/>/, finish=true, timeout=4000) => {
    this.commandBuf = ''
    let sendPromise = new Promise((resolve, reject) => {
      this.cmdExpectExpr = expect
      this.onCmdPrompt = resolve
      this.onCmdFail = reject

      // If cmd is a non-control character, append a "finish"
      if (finish && cmd.charCodeAt(0) > 9) {
        cmd += CTL_FINISH_CHR
      }

      // console.log("Send: " + cmd + ((cmd.slice(-1) === '\x04') ? " ^D" : ""))
      serComm.send(cmd)
    })
    return promiseTimeout(timeout, sendPromise)
  }

  // Testing the promise-based sendCommand function
  testCmd = async () => {
    await this.sendCommand(CTL_ENTER_RAW_CHR)
    await this.sendCommand('print("Hello, ")')
    await this.sendCommand('print("World.")')
    await this.sendCommand(CTL_EXIT_RAW_CHR)
  }

  copyPythonFile = async () => {
    // Called after download saves current file as 'main.py'. We want to allow 'import' if the user
    // has named the file properly, so we need to save it by the correct name. This is an MVP approach
    // to multi-file support.
    // Is currentFilename a proper Python filename?
    const match = this.currentFilename && this.currentFilename.match(/^[a-zA-Z_]\w*\.py$/)
    if (match) {
      // Copy main.py to proper filename (copy src->dest in chunks)
      // Allow extra time for file copy. Measured about 250ms for 10k file. Conservatively allow 1000ms per 10k.
      const copyTimeout = 4000 + Math.round(this.lastLoadedCode.length / 10)
      await this.sendCommand(`d=open("${this.currentFilename}","w")`)
      await this.sendCommand(`s=open("main.py")`)
      await this.sendCommand('while d.write(s.read(512)): pass\r', />/, true, copyTimeout)
      await this.sendCommand('d.close()')
    }
  }

  eraseUserCode = async () => {
    // Erase all user Python code from device
    await this.sendCommand(`#---- Erasing Python Files ----\r`, />>>/, false)
    await this.sendCommand(`import os\r`, />>>/, false)
    await this.sendCommand(`e=list(filter(lambda s:os.remove(s) or s if s.endswith('.py') and s != 'boot.py' else 0,os.listdir()))\r`, />>>/, false)
    await this.sendCommand(`_=open('main.py','w').write('')\r`, />>>/, false)
    await this.sendCommand(`print("---- Erased:",e)\r`, />>>/, false)
    serComm.send(CTL_FINISH_CHR)   // Soft reboot and sync filesystem (otherwise subsequent writes to main.py will fail)
    await this.sendCommand('c\r', /<<</, false)
  }

  sendAllBreakpoints = async () => {
    // Clear any current invalid breakpoints
    this.removeInvalidBreakpoints()

    // Send current breakpoints from editorInst to device. Assumes we're in debug/idle mode.
    // Ace editor maintains breakpoints as sparse array with row-index
    const breakpoints = editorInst.session.$breakpoints
    //console.log("sending bkpts: ", breakpoints)
    await this.sendCommand('bc\r', /<<</, false)  // Clear all breakpoints
    for (let k in breakpoints) {
      if (breakpoints[k] === "ace_breakpoint") {
        const row = parseInt(k, 10) + 1
        await this.sendCommand(`ba ${row}\r`, /<<</, false)  // Add breakpoint to 'main.py'
      }
    }
  }

  // Put file to the micropython file system
  // Should be in "stop" state first
  download = async (destFilename) => {
    let fileBuf = editorInst.getValue()
    this.lastLoadedCode = fileBuf
    fileBuf = fileBuf.replace(/'{3}/gm, `"""`)  // Replace all triple single quotes with triple double quotes

    // Verify we're in stop state; wait for prompt
    await this.sendCommand('\r', />>>/, false)

    // Open file and init write shortcut
    await this.sendCommand(CTL_ENTER_RAW_CHR)

    if ((this.devicePlatform === 'MICROBIT') || (this.devicePlatform === 'CODEBOT_CB2')) {
      await this.sendCommand(`fd=open("${destFilename}", "wb")`)
    } else {
      await this.sendCommand(`fd=open("${destFilename}", "w")`)
    }

    await this.sendCommand(`w=fd.write`)

    for (let i = 0; i < fileBuf.length; i += 64) {
      let chunk = fileBuf.substr(i, 64)

      // console.log("Chunk before: ", chunk)

      chunk = chunk.replace(/\\/g, '\\x5c')

      if (chunk.slice(-1) === `'`) {
        // If the last character is a single quote then escape it since we are about to wrap it in triple single quotes
        chunk = chunk.slice(0, -1) + "\\'"
      }

      // console.log("Chunk after: ", chunk)

      // Using triple single quotes to minimize what needs to be escaped
      await this.sendCommand(`w('''${chunk}''')`)
    }

    await this.sendCommand(`fd.close()`)

    // If current file is a proper Python filename, copy it also.
    await this.copyPythonFile()

    // Reset device
    if (this.devicePlatform === 'MICROBIT') {
      await this.sendCommand('from microbit import reset')
      await this.sendCommand('reset()', /__main__/)
      this.setMbMode(mb.mode.DEBUG)
    }
    else if (this.devicePlatform === 'CODEBOT_CB2') {
      // Rewrite boot.py in case of flash corruption. This will no longer be necessary when we:
      // a) Implement multifile support with file-integrity checks, and/or b) Fix flash corruption issues.
      await this.sendCommand('fd=open("boot.py", "wb")')
      await this.sendCommand('fd.write("import pyb\\nc=None")')
      await this.sendCommand('fd.close()')

      await this.sendCommand('pyb.sync()')  // Write file to FLASH
      await this.sendCommand(CTL_EXIT_RAW_CHR)
      serComm.send(CTL_FINISH_CHR)   // Soft reboot  (may not get prompt if looping)
      await this.sendCommand('\r', /<<</, false)
      this.setMbState(mb.state.IDLE)
      this.setMbMode(mb.mode.DEBUG)
    }
    else if ((this.devicePlatform === CODEBOT_CB3) || (this.devicePlatform === CODEX_MK1)) {
      // I don't know if the following is necessary or not.
      // I am only doing it because the CB2 code invokes pyb.sync()
      await this.sendCommand('from os import sync')
      await this.sendCommand('sync()')  // Write file to FLASH

      await this.sendCommand(CTL_EXIT_RAW_CHR)
      serComm.send(CTL_FINISH_CHR)   // Soft reboot  (may not get prompt if looping)
      await this.sendCommand('\r', /<<</, false)
      this.setMbState(mb.state.IDLE)
      this.setMbMode(mb.mode.DEBUG)
    }
    else {
      console.log("this.devicePlatform="+this.devicePlatform)
      console.error("Error: unknown devicePlatform on Download")
    }

    if (this.devicePlatform === 'MICROBIT') {
      // Send \r to "trap" device on bootup, evoking debug prompt
      await this.sendCommand('\r', /<<</, false)

      // Unlock
      await this.sendKey()
    }

    // Set console filter state directly since we will have missed the REPL_ENTER_DEBUG flag.
    this.consoleFilterState = CF_STATE_OFF
    this.consoleFilterDebugRecurse = 0
  }
}

const debugController = new DebugController()
export default debugController
