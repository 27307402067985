import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import { googleApi } from './Gapi'
import { DefaultPlayer as Video } from 'react-html5video'
import BackdropVid from './assets/BackdropBwMed2.mp4'
import FiriaLabsLogo from './assets/logo-min.svg'
import IconButton from 'material-ui/IconButton'
import Tooltip from 'material-ui/Tooltip'
import GoogIcon from './assets/G-icon.png'

export const SIGNIN_DIV_ID = 'g-signin2'


export default class SignInPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      signedIn: googleApi.gapiAuthIsSignedIn,
      error: null,
    }
  }

  handleGoogAuthChange = (isSignedIn) => {
    this.setState({ signedIn: isSignedIn })
  }

  componentWillMount = () => {
    googleApi.notifyOnAuthChange(this.handleGoogAuthChange)
    
    googleApi.authLoadedPromise.then(() => {
      if (!this.state.signedIn) {
        googleApi.renderSigninButton(SIGNIN_DIV_ID)
      }
    }, (err) => {
      // console.log(err)
      this.setState({error: err})
    })
  }

  componentWillUnmount = () => {
    googleApi.unregisterNotifyOnAuthChange(this.handleGoogAuthChange)
  }

  getError = () => {
      if (this.state.error.details && this.state.error.details.toLocaleLowerCase().includes('cookie')) {
          return (
              <div style={{background: 'beige'}}>
                  <p>This site requires that you enable storing third party cookies.</p>
                  <p>To enable go to:
                      <br/>Settings...
                      <br/>Advanced
                      <br/>Site Settings
                      <br/>Cookies
                  </p>
                  <p>Then toggle and disable "Block third-party cookies"</p>
              </div>
          )
      } else {
          return (
              <div style={{background: 'beige'}}>
                  <p>Unable to load Google login service</p>
                  {this.state.error.details && (
                    <div style={{width:'60%', maxHeight:200, overflowY:'auto', background:'white', margin:10, padding:10}}>
                      <h3>Details:</h3>
                      <p>{this.state.error.details}</p>
                    </div>
                  )}
              </div>
          )
      }
  }

  render() {
    if (this.state.signedIn) {
      return (<Redirect to="/" />)
    }
    return (
      <div style={{display:'flex'}}>
        <div
          style={{
            display:'flex',
            flexDirection: 'column',
            background: 'rgba(0, 0, 0, 0.5)',
            flex: 1,
            height: '100vh',
            justifyContent: 'space-between',
          }}
        >
          <div style={{margin:50}}>
            <img src={FiriaLabsLogo} alt="Firia Labs" style={{width: 400}} />
            <h2 style={{color:'white'}}>CodeSpace development platform</h2>
            <div id={SIGNIN_DIV_ID} style={{padding: '40px'}}/>
          </div>

          <div
            style={{
              marginBottom: '1em',
              marginLeft: '40px',
            }}
          >
            <a
              href="https://firialabs.com/pages/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: 'white',
              }}
            >
              Privacy Policy
            </a>
          </div>
        </div>

        <Video
          autoPlay muted loop playsInline
          controls={[]}
          id='bgvid'
        >
          <source src={BackdropVid} type="video/mp4" />
        </Video>

        <div
          style={{
            position:'absolute',
            top:0,
            right:0,
            margin:10,
          }}
        >
          <Tooltip title="Google Account Sign-out">
            <IconButton
              id="GoogAvatar_button"
              onClick={() => window.open('https://accounts.google.com/SignOutOptions', '_blank')}
            >
              <img src={GoogIcon} alt="" style={{width:24}} />
            </IconButton>
          </Tooltip>
        </div>

        {this.state.error ?
            this.getError()
          :
            null
        }
      </div>
    )
  }
}
