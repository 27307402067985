
import React from 'react'
import JoinGroupModal from './JoinGroupModal.jsx'
import { Database } from '../DataSources'


class JoinGroupDialog extends React.Component {
  state = {
    uiDisabled: false,
    groupsJoinedData: [],
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open) {
      this.disableUi(true)
      Database.getGroupsJoined().then((resp) => {
        this.setState({ groupsJoinedData: resp.groups })
        this.disableUi(false)
      }).catch((d) => {
        this.setState({ groupsJoinedData: [] })
        this.disableUi(false)
      })
    }
  }

  setJoinGroupModalVis = (open) => {
    this.props.onClose()
  }

  disableUi = (isDisabled) => {
    this.setState({ uiDisabled: isDisabled })
  }

  render() {
    return (
      <JoinGroupModal
        setVisibility={this.setJoinGroupModalVis}
        open={this.props.open}
        disableUi={this.disableUi}
        uiDisabled={this.state.uiDisabled}
        groupsJoinedData={this.state.groupsJoinedData}
      />
    )
  }
}

export default JoinGroupDialog
