import React from 'react'
import CssBaseline from 'material-ui/CssBaseline'
import { withStyles } from 'material-ui/styles'

import HeaderBar from './HeaderBar.jsx'
import SideBar, { SIDEBAR_INDEXES } from './SideBar.jsx'
import MainPanel from './MainPanel.jsx'
import { data } from './DataSources'

const drawerWidth = 200
const headerHeight = 64

const styles = theme => ({
  root: {
    height: '100%',
    marginLeft: drawerWidth,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    height: '100%',
    paddingTop: headerHeight,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    height: '100%',
    paddingTop: headerHeight,
  },
  fab: {
    margin: theme.spacing.unit + 1,
    textTransform: 'none',
  },
  extendedIcon: {
    marginRight: theme.spacing.unit + 1,
  },
  toolbar: theme.mixins.toolbar,
  headerHeight: {
    minHeight: headerHeight,
  },
})

class RootPanel extends React.Component {
  state = {
    sidebarOpen: true,
    sidebarSelection: SIDEBAR_INDEXES.MY_GROUPS,
    pageData: data,
    uiDisabled: false,
  };

  toggleSidebar = open => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    if (open === null) {
      this.setState({ sidebarOpen: !this.state.sidebarOpen })
    } else {
      this.setState({ sidebarOpen: open })
    }
  };

  sideBarSelectionChanged = (newSelection) => {
    this.setState({ sidebarSelection: newSelection })
  }

  refreshData = () => {
    this.setState({ pageData: data })
  }

  disableUi = (shouldDisable) => {
    this.setState({ uiDisabled: shouldDisable })
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        <HeaderBar
          classes={this.props.classes}
          toggleSidebar={this.toggleSidebar}
          getUserDisplay={this.props.getUserDisplay}
        />
        <SideBar
          classes={this.props.classes}
          open={this.state.sidebarOpen}
          selectionChanged={this.sideBarSelectionChanged}
          selectedIndex={this.state.sidebarSelection}
          refreshData={this.refreshData}
          disableUi={this.disableUi}
          uiDisabled={this.state.uiDisabled}
        />
        <MainPanel
          classes={this.props.classes}
          sidebarOpen={this.state.sidebarOpen}
          sidebarSelection={this.state.sidebarSelection}
          data={this.state.pageData}
          refreshData={this.refreshData}
          disableUi={this.disableUi}
          uiDisabled={this.state.uiDisabled}
        />
      </div>
    )
  }
}

export default withStyles(styles)(RootPanel)
export { drawerWidth }
