// UserProgress - track student progress through Lessons
import firebase, { cblFirebase } from './myfirebase'
import { defaultModule } from './lessons/CurriculumModules'
import { tracker } from './tracking'


class UserProgress {
  constructor() {
    this.db = firebase.firestore()
    this.onChangeCallbacks = new Set()
    this.xp = 0
    this.tourHasRun = false
    this.lastUsedActivationCode = ''
    this.lastModule = defaultModule
    this.loaded = false
    // this.newAccount = false
    this.moduleDocs = {}  // Firebase DocumentSnapshot reference containing user progress for each lesson module, e.g. 'StartCoding',...
    this.userDataLoaded = new Promise((resolve, reject) => {
      this.resolveUserDataLoaded = resolve
    })

    if (cblFirebase.userDoc) {
      this.loadSavedProgress()
    } else {
      cblFirebase.registerDocRetrievedCallback(() => { this.loadSavedProgress() })
    }

    this.docUnsubscribes = []

    firebase.auth().onAuthStateChanged((firebaseUser) => {
      // console.log('userprog firebase state change', firebaseUser)
      if (!firebaseUser) {
        this.docUnsubscribes.forEach(unsubscribe => {
          unsubscribe()
        })
      } else {
        // Clear any old doc refs
        this.docUnsubscribes = []
      }
    })
  }

  deleteAllProgress = async () => {
    let mods = await cblFirebase.userDoc.ref.collection('modules').get()
    mods.forEach(async (snap) => {
      await snap.ref.delete()
    })
    this.moduleDocs = {}
    this.loadSavedProgress()
  }

  registerOnLessonChange = (cb) => {
    this.onLessonChangeCallback = cb
  }

  // Callback: onChange(userProgressObj, isUserData)
  //   isUserData = true if change to current 'users' doc, false if change to 'modules' data
  registerOnChangeCallback = (cb) => {
    this.onChangeCallbacks.add(cb)
  }

  unregisterOnChangeCallback = (cb) => {
    this.onChangeCallbacks.delete(cb)
  }

  notifyOnChangeCallbacks = (isUserData) => {
    this.onChangeCallbacks.forEach((cb) => {
      try {
        cb(this, isUserData)
      } catch (err) {
        tracker.addBreadcrumb('An error occurred while notifying a user progress callback', { data: err })
      }
    })
  }

  newAccountPromise = () => {
    return new Promise((resolve, reject) => {
      this.isNewAccount = resolve
      this.isExistingAccount = reject
    })
  }

  loadSavedProgress = () => {
    // Query for all documents in the 'modules' collection for current user, and...
    // Register for callback on changes to all modules.
    //  (Note: new modules created after this initial load are registered by 'watchModule()' below)
    cblFirebase.userDoc.ref.collection('modules').get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        // New user account will land here. No 'modules' to register callbacks with, and at at this point
        // no clients have yet called registerChangeCallback() so we can't notify them that way.
        this.isNewAccount()  // Let clients know they're on their own for initialization
      } else {
        this.isExistingAccount()
        // Save each module's DocumentSnapshot reference and register for updates when DB changes.
        querySnapshot.forEach((queryDocSnapshot) => {
          const unsubscribe = queryDocSnapshot.ref.onSnapshot((docSnapshot) => {
            // This callback fires whenever docSnapshot in local or remote DB is updated
            this.moduleDocs[docSnapshot.id] = docSnapshot
            this.notifyOnChangeCallbacks(false)
          })
          this.moduleDocs[queryDocSnapshot.id] = queryDocSnapshot
          this.docUnsubscribes.push(unsubscribe)
        })
      }
      this.loaded = true
    })
    .catch((error) => {
      this.loaded = true // Set to true because we did load the user main doc
      this.notifyOnChangeCallbacks(false)
      tracker.addBreadcrumb('Unable to load firestore user module doc', { data: error })
    })

    // Register callback for all changes to current 'users' document
    const unsubscribe = cblFirebase.userDoc.ref.onSnapshot((doc) => {
      const userData = doc.data()
      // Ignore changes prior to setting 'lastSignIn' field
      if (userData && userData.lastSignIn !== null) {
        this.xp = userData.xp || 0
        this.tourHasRun = userData.tourHasRun || (this.xp > 10)  // No tour if user predates this flag (per XP value)
        this.lastUsedActivationCode = userData.lastUsedActivationCode || ''
        this.lastModule = userData.lastModule || defaultModule
        this.notifyOnChangeCallbacks(true)
        this.resolveUserDataLoaded()
      }
    })
    this.docUnsubscribes.push(unsubscribe)
  }

  addXp = (increment) => {
    if (typeof (increment) === 'number') {
      this.xp += increment
      if (cblFirebase.userDoc) {
        cblFirebase.userDoc.ref.set({ xp: this.xp }, { merge: true })
      }
    }
  }

  // Return true if tour should run (first-time user) - save so that happens only once for account.
  testSetTourRun = async () => {
    if (!this.tourHasRun) {
      this.tourHasRun = true
      await cblFirebase.userDoc.ref.update({tourHasRun: true })
      return true
    } else {
      return false
    }
  }

  completedHint = (hintName, xpCredit) => {
    return this.completedStep('ConceptToolBox', 'ToolNames', hintName, xpCredit)
  }

  isHintComplete = (hintName) => {
    return this.isStepComplete('ConceptToolBox', 'ToolNames', hintName)
  }

  numberHintsCompleted = () => {
    const hintsProgress = this.moduleDocs['ConceptToolBox'] ? this.moduleDocs['ConceptToolBox'].data().progress : {}
    const hintsComplete = hintsProgress ? hintsProgress['ToolNames'] : []
    return hintsComplete ? hintsComplete.length : 0
  }

  completedQuiz = (module, quizName, xpCredit) => {
    const moduleQuizzes = this.moduleDocs[module] ? this.moduleDocs[module].data().quizzes || [] : []

    if (!moduleQuizzes.includes(quizName)) {
      moduleQuizzes.push(quizName)
      if (this.loaded) {
        cblFirebase.userDoc.ref.collection('modules').doc(module).set({
          quizzes: moduleQuizzes,
        }, { merge: true })
        .then(() => {
          this.watchModule(module)
        })
      }
      this.addXp(xpCredit)
    }
  }

  isQuizComplete = (module, quizName) => {
    const moduleQuizzes = this.moduleDocs[module] ? this.moduleDocs[module].data().quizzes || [] : []
    return (moduleQuizzes.includes(quizName))
  }

  watchModule = (module) => {
    // If we aren't currently registered for callbacks on this module, do it now. (handles newly created module data)
    if (!this.moduleDocs[module]) {
      const unsubscribe = cblFirebase.userDoc.ref.collection('modules').doc(module).onSnapshot((docSnapshot) => {
        // This callback fires whenever docSnapshot in local or remote DB is updated
        this.moduleDocs[docSnapshot.id] = docSnapshot
        this.notifyOnChangeCallbacks(false)
      })
      this.docUnsubscribes.push(unsubscribe)
    }
  }

  // Record completion of given step. Return true if new completion, false if previously completed.
  completedStep = (module, projectName, stepName, xpCredit) => {
    const moduleProgress = this.moduleDocs[module] ? (this.moduleDocs[module].data() ? this.moduleDocs[module].data().progress : {} ) : {}
    const stepsComplete = moduleProgress && moduleProgress[projectName] ? moduleProgress[projectName] : []
    const alreadyComplete = stepsComplete.includes(stepName)

    if (!alreadyComplete) {
      stepsComplete.push(stepName)
      if (this.loaded) {
        cblFirebase.userDoc.ref.collection('modules').doc(module).set({
          progress: {
            [projectName]: stepsComplete,
          },
        }, { merge: true })
        .then(() => {
          this.watchModule(module)
        })
      }
      this.addXp(xpCredit)
    }

    return !alreadyComplete
  }

  isStepComplete = (module, projectName, step) => {
    const moduleProgress = this.moduleDocs[module] ? (this.moduleDocs[module].data() ? this.moduleDocs[module].data().progress : {} ) : {}
    const stepsComplete = moduleProgress ? moduleProgress[projectName] : []

    return stepsComplete ? stepsComplete.includes(step) : false
  }

  isProjectComplete = (module, projectName, stepList) => {
    const moduleProgress = this.moduleDocs[module] ? (this.moduleDocs[module].data() ? this.moduleDocs[module].data().progress : {} ) : {}
    const stepsComplete = moduleProgress ? moduleProgress[projectName] : []

    if (stepsComplete) {
      const remSteps = stepList.filter(step => !stepsComplete.includes(step))
      return remSteps.length === 0
    }

    return false
  }

  getModuleData = (module) => {
    return this.moduleDocs[module] ? this.moduleDocs[module].data() : {}
  }

  // User navigated to new Lesson step / project
  onLessonChange = (module, lesson, project) => {
    if (this.onLessonChangeCallback) this.onLessonChangeCallback(module, lesson, project)
    if (cblFirebase.userDoc) {
      cblFirebase.userDoc.ref.collection('modules').doc(module).set({
        lastLesson: lesson,
        lastProject: project,
      }, { merge: true })
      if (module !== this.lastModule) {
        cblFirebase.userDoc.ref.set({ lastModule: module }, { merge: true })
      }
    }
    this.lastModule = module
  }
}

export const progress = new UserProgress()
export default progress
