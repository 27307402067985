// Utility class to "debounce" callbacks for which only the latest result matters
export default class Debounce {
  constructor() {
    this.cbReg = new Map()
  }

  // Invoke func after (tm) ms unless a newer call occurs before then
  cb = (func, tm, ...args) => {
    const tmrId = this.cbReg.get(func)
    if (tmrId) {
      window.clearTimeout(tmrId)
    }
    this.cbReg.set(func, window.setTimeout(func, tm, ...args))
  }
}
