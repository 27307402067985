// Project: NightLight

import React, {Component} from 'react'
import StepComplete from '../StepComplete'
import {
  LegendMessageRun,
  LegendMessageDebug,
  LegendMessageKeyboard,
  LegendMessageTry,
  LegendMessageWarning,
  LegendMessageInteract,
  LegendMessageConcept,
} from '../Legends'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import NightLight from './assets/NightLight.svg'
import PhotocellConnectPic from './assets/photocell-conn.jpg'
import PhotocellClipsPic from './assets/pullup-clips.png'
import StadiumLight from './assets/StadiumLightSm.jpg'
import Quiz from '../Quiz'


export const NightLightImg = NightLight

class Intro extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
    gFileId: PropTypes.string,
  }

  static defaultProps = {
    gFileId: null,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Project: Night Light {lesson-title}
___
#### Make a smart Night Light, that turns ON when the room gets dark. {centered}
`}
<img src={NightLight} alt=""
    style={{
      width: "30%", float:'right', margin:10
    }}
/>
{`
You’ll use an external light sensor to detect ambient light, and the LED display as a nightlight.

#### Project Goals: create *two* versions of the Night Light:
**1 - Simple on/off control**
 * Light turns ON when sensor crosses a pre-set "dark threshold".

**2 - Variable dimming**
 * The darker it gets, the brighter it shines!
`}
</Markdown>
        <StepComplete
          prompt="Ready to light up the night? Press Next..."
          lessons={this.props.lessons}
          btnNext={true}
        />
<Markdown>
{`"May it be a light to you in dark places, when all other lights go out."
> Galadriel (J.R.R Tolkien), *Fellowship of the Ring*
`}
</Markdown>
      </div>
    )
  }
}

class LightSource extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Light Source {lesson-title}
___
Your first step is to make a **light** that you can control.

Using the edge connector, it's possible connect the micro:bit to all kinds of lights, but for *this* project,
the built-in LED display will work great.
`}
<img src={StadiumLight} alt=""
    style={{
      width: 100, float:'right', margin:10
    }}
/>
{`**The *Code* in the Arena**
> Some big Arenas and NFL stadiums have **huge** LED lights, controlled by **code** running on *tiny* wireless electronic boards like the micro:bit!

For your *Night Light*, rather than showing an image or text, just light up **ALL** the pixels on the LED display.
`}
<LegendMessageConcept>
{`
To set all the pixels of an \`Image\` to the same value, use the \`fill()\` function.
\`\`\`
all_on = Image()  # create an empty Image
all_on.fill(9)    # set all pixels to max brightness = 9
\`\`\`
This custom \`Image\` can be used with \`display.show(all_on)\` just like built-in \`Image.HEART\` and friends!
`}
</LegendMessageConcept>

<LegendMessageKeyboard>
{`
* Create a new file (click **File** menu at top-left of screen)
* Name it **NightLight**
#### You'll need just 4 lines of code to:
* Import the \`microbit\` module, create an empty \`Image\`, \`fill\` it with 9's, and \`display\` it!
*Try writing the code now - based on the information above.*
\`\`\` collapsed Peek here if you need help...
from microbit import *
all_on = Image()
all_on.fill(9)
display.show(all_on)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
        <StepComplete
          prompt="Are all the LEDs shining brightly?"
          xp={10}
          successMessage=
{`## Brilliant! {centered}
### 25 lights all doing your bidding, code-master.
`}
          reqImports={['microbit']}
          reqCalls={['display.show', 'Image']}
          reqArgs={['9']}
          reqNames={['fill']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
        />

      </Markdown>
    </div>
    )
  }
}

class ConnectPhotocell extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## Connect the Photocell {lesson-title}
___
#### Now you just need a way to *sense* the light in the room!
When it gets dark enough, you can turn **ON** the light source you made in the previous step.

#### This step uses the *photocell* component from your *Firia Labs Kit*.
`}
<LegendMessageInteract title="Connect Wires">
{`Use two **alligator clip** wires from your *kit* to connect the **photocell** to the micro:bit as shown below.

**Be sure to connect *only* to the \`0\` and \`3V\` metal pads!**
`}
</LegendMessageInteract>
<img src={PhotocellClipsPic} alt=""
    style={{
      width: 200, margin: 'auto', display:'block'
    }}
/>
<br />
<img src={PhotocellConnectPic} alt=""
    style={{
      width: 400, margin: 'auto', display:'block'
    }}
/>
</Markdown>
        <StepComplete
          prompt="All connected? Time to get GLOWing!"
          lessons={this.props.lessons}
          btnNext={true}
        />
      </div>
    )
  }
}

class LightSensor extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Light Sensing Code {lesson-title}
___
#### Now that the photocell is connected, write code to "read" from it!
* The photocell helps convert **light level** into an electrical **voltage level**.
* If you had a *voltage meter*, you could measure across the photcell
  * **dark = lower values**, and **bright = higher values**.
  * There are *infinite* variations of light level! It's an **analog** value.
* The micro:bit converts this voltage into *number* ranging from 0 to 1023.
  * Not quite infinity! Just a few digits... it's a ==digital== value.
  * Find out more about *Analog to Digital Conversions* - ==ADC==!
`}
<LegendMessageConcept title="Concept: Input Pins">
{`The microbit Python module lets you directly interact with ==pins== on the edge connector.
Two functions let you "read" the value of a connected sensor (pin 0 shown):
\`\`\`
pin0.read_digital()  # returns 0 or 1
pin0.read_analog()   # returns 0-1023
\`\`\`
* \`read_digital()\` returns a 1-bit ==binary== value as an integer: 0 or 1.
* \`read_analog()\` returns an integer also. Read about ==binary== to learn how many bits are needed for the ADC's 1024 levels.

> For your Light Sensor, \`read_analog()\` is just the thing.

**Note**
To "set up" the micro:bit's input, you'll need to call \`read_digital()\` once prior to reading the analog values.
`}
</LegendMessageConcept>
{`Now add Light Sensing to your code!
`}
<LegendMessageKeyboard>
{`
The new code is shown below. Click if you need to see more of the final program.
\`\`\`
# Night Light – ON/OFF version
# Requires a photocell connected between pins 0 and 3V.
from microbit import *

# Make a new Image filled with max brightness pixels
all_on = Image()
all_on.fill(9)
$$$

$pin0.read_digital()  # Setup Pin0

$while True:
$    # Read pin0: returns 0=dark to 1023=bright
$    value = pin0.read_analog()
$    if value < 500:
$$$
$        display.show(all_on)
$    else:
$        display.clear()
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Try shading the **photocell** with your hand. Can you make your LED light turn ON and OFF?
* Find *just the right* light-level to make the LEDs flicker... Cool!
`}
</LegendMessageRun>
<LegendMessageWarning title="Not Dark Enough?">
{`If you have a **bright** environment, such as a window with **sunlight** streaming in, it may be *difficult* to
completely **shade** the photocell.

Try moving to a darker area, or using an opaque material to completely cover the photocell.
`}
</LegendMessageWarning>
{`**Note:**  The value **500** in the code above is just an approximate value, based on typical photocell readings in a
room with a "moderate" amount of ambient light. Feel free to adjust as needed for *your* environment.
`}
<LegendMessageDebug>
{`Try stepping through the code with the **Debug Panel** expanded.

> Observe the *variables* pane to see the \`value\` that's read from the photocell under different lighting conditions.
`}
</LegendMessageDebug>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`The ADC converts an analog input to 1024 levels (numbers). That's called the *resolution* of the ADC. How many ==binary== **bits** of *resolution* does it have?
`}
  </Markdown>
  )}
  id={"ADC resolution"}
  xp={5}
  answerRight={"10 bits"}
  answerWrong={["8 bits.", "12 bits", "1 bit"]}
/>
        <StepComplete
          prompt="Is your Nightlight working?"
          xp={30}
          successMessage=
{`## Photo-Electrifying! {centered}
### Did you know that most Street Lights have photocells just like this?
`}
          reqImports={['microbit']}
          reqCalls={['pin0.read_digital', 'pin0.read_analog', 'display.show', 'display.clear', 'Image', ]}
          reqArgs={['9']}
          reqStatements={['while', 'if']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
        />

      </Markdown>
    </div>
    )
  }
}

class LightDimmer extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
      <Markdown>
{
`## Dimmable Light Sensor {lesson-title}
___
### Your night light is either fully **ON**, or completely **OFF**.
> *But if it's only **slightly** dark, just a little light will do...*
#### Make the night light *gradually* brighten as the room gets darker!

`}
<LegendMessageKeyboard>
{`
**The new code is shown below.**
> Notice it does some math to convert the 0-1023 value to the range 0-9 needed by the Image for LED dimming.

*Click if you need to see more of the final program.*
\`\`\`
# Night Light – dimming version
# Requires a photocell connected between pins 0 and 3V.
from microbit import *

all_on = Image()     # Create empty Image
pin0.read_digital()  # Setup Pin0
$$$

while True:
    # Read pin0: returns 0=dark to 1023=bright
    value = pin0.read_analog()

$    # Convert value to a 0-9 "lamp_level"
$    light_level = int(value / 113)
$    lamp_level = 9 - light_level

$    # Set all pixels to lamp_level
$    all_on.fill(lamp_level)

    display.show(all_on)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun>
{`Experiment with different light levels.
* You may need a pretty bright light to make it go fully OFF.
* Also, it might be hard to shade it enough to turn it fully ON!
`}
</LegendMessageRun>
<Quiz
  lessons={this.props.lessons}
  prompt={(
  <Markdown>
{`Why does the code above divide the \`value\` by **113**?
`}
  </Markdown>
  )}
  id={"Scaling math"}
  xp={5}
  answerRight={"To scale 'value' down to 0-9. 113 is int(1023 / 9)."}
  answerWrong={["There are 113 lumens per bit.", "To keep 'value' from being negative."]}
/>
        <StepComplete
          prompt="Got a fancy dimmable Night Light now?"
          xp={30}
          successMessage=
{`## That's SO Analog! {centered}
### Dimming the LEDs saves power.
*You have an ECO-NightLight there!*
`}
          reqImports={['microbit']}
          reqCalls={['pin0.read_digital', 'pin0.read_analog', 'display.show', 'Image', ]}
          reqStatements={['while']}
          lessons={this.props.lessons}
          btnYes={true}
          btnNo={true}
        />

      </Markdown>
    </div>
    )
  }
}

class Finished extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Welcome to Smart Lighting {lesson-title}
___
### This project has introduced you to an area with **lots** of potential for improving the world!
*Light Sensors and LED lights controlled with **code** can reduce **energy consumed** and make lighting more awesome!*

#### This code can help a lot of real-world applications:
* **Outdoor Lighting**
  * Street Lights, Parking lots, Home lighting
* **Stadium Lights**
  * Even controlling the light color so it looks better on camera
* **Indoor Lighting**
  * Sensing daylight from windows and skylights is called **Daylight Harvesting** - it saves energy!
  * That's exactly what your last NightLight code was doing!
`}
<LegendMessageTry>
{`### Suggested Re-mix Ideas:
* The **photocell** is very sensitive. Write code that counts up whenever the sensed value changes by a small amount.
  * Can you detect how many times someone has walked by the sensor?
  * Detect any object *breaking a beam of light* shining on the photocell!
* Send a *radio message* when the light goes ON or OFF.
  * *Finally* you can test if the light in the **fridge** goes OFF when the door is shut!
`}
</LegendMessageTry>

        </Markdown>
        <StepComplete
          prompt="Well done! Move ahead to more coding fun..."
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)

Intro.stepId = 'Intro'
LightSource.stepId = 'LightSource'
ConnectPhotocell.stepId = 'ConnectPhotocell'
LightSensor.stepId = 'LightSensor'
LightDimmer.stepId = 'LightDimmer'
Finished.stepId = 'Finished'

export const nightLight = [
  Intro,
  LightSource,
  ConnectPhotocell,
  LightSensor,
  LightDimmer,
  Finished,
]
