import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { licensor } from './CodeSpaceLicensor'
import { defaultModule } from './lessons/CurriculumModules'


export default class LicenseDetails extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
  }

  state = {
    licenses: null,
    lastUsedActivationCode: null,
    isActivated: true,   // defaulting to activated so we don't bounce progress for licensed users on page reload.
    initialLoadComplete: false,
    currentModule: defaultModule,
    lastGenTokenArray: [],
    lastGenTokenText: '',
    lastFetchError: '',
  }

  componentDidMount() {
    licensor.registerOnChangeCallback(this.onLicenseChange)
  }

  componentWillUnmount() {
    licensor.unregisterOnChangeCallback(this.onLicenseChange)
  }

  onLicenseChange = () => {
    // console.log('license stuff changed')

    this.setState({
      licenses: licensor.licenses,
      lastUsedToken: licensor.lastUsedActivationCode,
      isActivated: licensor.isActivated,
      initialLoadComplete: licensor.initialLoadComplete,
      currentModule: licensor.productSeat,
      lastGenTokenArray: licensor.lastGenActivationCode,
      lastGenTokenText: licensor.lastGenActivationCode ? licensor.lastGenActivationCode.join(' ') : '',
      lastFetchError: licensor.lastFetchError,
    })
  }

  render() {
    return (
      <Fragment>
        {this.props.render(this.state)}
      </Fragment>
    )
  }
}
