import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'material-ui/Dialog'
import Select from 'material-ui/Select'
import Button from 'material-ui/Button'
import { InputLabel } from 'material-ui/Input'
import { FormControl } from 'material-ui/Form'
import { MenuItem } from 'material-ui/Menu'
import { CircularProgress } from 'material-ui/Progress'
import { googleApi } from './Gapi'
import { moduleEnums, moduleProjects, moduleDescription } from './lessons/CurriculumModules'
import { cblFirebase } from './myfirebase'
import List, { ListItem } from 'material-ui/List'
import Checkbox from 'material-ui/Checkbox'
import KeyboardArrowDownIcon from 'material-ui-icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from 'material-ui-icons/KeyboardArrowUp'

export class GoogleClassroomAssignments extends React.Component {
  static propTypes = {
    currentModule: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => (
    {
      courses: null,
      selectedCourse: '',
      showNoAccess: false,
      submitting: false,
      accessErrMsg: null,
      selectedAssignments: {},
    }
  )

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.open && this.props.open) {
      try {
        const courses = []
        const coursesResponse = await googleApi.getMyTeachingCourses()

        if ('courses' in coursesResponse.result) {
          let activeCourses = coursesResponse.result.courses.filter(course => course.courseState === 'ACTIVE')
          if (activeCourses) {
            const courseWork = await Promise.all(activeCourses.map(course => googleApi.getCourseWork(course.id)))
            activeCourses = activeCourses.reduce((obj, course) => {
              obj[course.id] = course // eslint-disable-line no-param-reassign
              return obj
            }, {})
            courseWork.forEach((response) => {
              if (!response.result.courseWork || (activeCourses[response.result.courseWork[0].courseId].teacherFolder)) {
                activeCourses[response.courseId].hasAssignments = response.result.courseWork && response.result.courseWork.some(element => element.associatedWithDeveloper)
                courses.push({...activeCourses[response.courseId], ...response.result})
              }
            })
          }
        }

        this.setState({ courses })
      } catch (error) {
        console.error(error)
        if (error.status === 403) {
          this.setState({
            showNoAccess: true,
            accessErrMsg: error.result.error.message,
          })
        } else {
          throw error
        }
      }
    } else if (prevProps.open && !this.props.open) {
      this.setState(this.getInitialState())
    }
  }

  handleAssignmentListItemClick = (assignmentId) => {
    this.setState((state) => {
      const copyOfSelectedAssignments = { ...state.selectedAssignments }
      if (assignmentId in copyOfSelectedAssignments) {
        delete copyOfSelectedAssignments[assignmentId]
      } else {
        copyOfSelectedAssignments[assignmentId] = true
      }
      return {selectedAssignments: copyOfSelectedAssignments}
    })
  }

  handleAssignmentListToggleAll = (allAssignmentsSelected, assignmentIds=[]) => {
    if (!allAssignmentsSelected) {
      this.setState({
        selectedAssignments: {},
      })
      return
    }

    const selectedAssignments = {}
    assignmentIds.forEach(id => selectedAssignments[id] = true)
    this.setState({
      selectedAssignments,
    })
  }

  getContent = () => {
    if (this.state.showNoAccess) {
      return (
        <div>
          You can't use Classroom with this account
          <p />
          If your school uses G Suite for Education, sign in with your school account.
          {
            this.state.accessErrMsg ?
              <p>
                Google said: {this.state.accessErrMsg}
              </p>:
              null
          }
        </div>
      )
    }

    if (this.state.courses && this.state.courses.length === 0) {
      return (
        <div>
          Unable to find any classes that you are teaching that need CodeSpace assignments
        </div>
      )
    }

    let moduleId = null
    for (let m in moduleEnums) {
      if (moduleEnums[m] === this.props.currentModule) {
        moduleId = m
        break
      }
    }
    if (moduleId === null || !(moduleId in moduleProjects)) {
      return (
        <div>
          Unable to add assignments for free curriculum modules, please switch to a different module
        </div>
      )
    }


    const assignmentList = moduleProjects[moduleId].filter(project => project.hasAssignment)
    const allAssignmentsSelected = Object.keys(this.state.selectedAssignments).length === 0

    return (
      <Fragment>
        <form autoComplete='off'>
          <div style={{ display: 'flex' }}>
            {
              this.state.courses === null ?
                <div
                  style={{
                    display: 'flex',
                    height: '1.4em',
                  }}
                >
                  <CircularProgress
                    style={{
                      margin: 'auto',
                      width: 20,
                      height: 20,
                      color: 'green',
                    }}
                  />
                  <div
                    style={{
                      paddingLeft: '0.5em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    Loading Classes...
                  </div>
                </div>              :
                <div style={{width: '100%'}}>
                  <FormControl style={{ display: 'flex', width: '100%' }}>
                    <InputLabel
                      htmlFor='class-helper'
                      shrink
                      style={{
                        marginTop: '1em',
                      }}
                    >
                      Choose a class
                    </InputLabel>
                    <Select
                      style={{ flex: 1, height: '100%' }}
                      value={this.state.selectedCourse}
                      displayEmpty={true}
                      onChange={(event) => {
                        if (event.target.value !== this.state.selectedCourse) {
                          this.setState({
                            selectedCourse: event.target.value,
                            selectedAssignments: {},
                          })
                        }
                      }}
                      SelectDisplayProps={{style: {height: 40}}}
                      inputProps={{
                        name: 'class',
                        id: 'class-helper',

                      }}
                    >
                      {
                        this.state.courses.map(course => (
                          <MenuItem
                            key={course.id}
                            value={course.id}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'left',
                            }}
                          >
                            <div style={{ width: '100%' }}>{course.name}</div>
                            <div
                              style={{
                                color: '#7f7f7f',
                                width: '100%',
                              }}
                            >
                              {course.section}
                            </div>
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  {this.state.selectedCourse ?
                    <>
                      <div style={{paddingTop: 30, paddingBottom: 10, fontSize: 17}}>{`${moduleDescription[moduleId].name}`}</div>
                      <div style={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,0.2)',
                        borderRadius: 1,
                        padding: 0,
                      }}>
                        <ListItem
                          role={undefined}
                          dense
                          button
                          style={{
                            padding: 5,
                          }}
                          onClick={() => this.handleAssignmentListToggleAll(allAssignmentsSelected, assignmentList.map(project => project.name))}
                          disabled={this.state.submitting || this.state.showNoAccess || this.state.selectedCourse === ''}
                        >
                          <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%'}}>
                            <Checkbox
                              checked={allAssignmentsSelected}
                              style={{color: allAssignmentsSelected ? 'rgba(76, 175, 79)' : 'rgba(0,0,0,0.4)'}}
                              disableRipple
                            />
                            <div style={{color: allAssignmentsSelected ? null : 'rgba(0,0,0,0.55)'}}>{`Add All ${assignmentList.length} Assignments`}</div>
                            {allAssignmentsSelected ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                          </div>
                        </ListItem>
                      </div>
                      <div style={{ padding: 5 }}></div>
                      {allAssignmentsSelected ? null :
                        <>
                          <div style={{paddingTop: 15, paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                            <div style={{fontSize: 15}}>{'Assignment Selection'}</div>
                            <div style={{fontSize: 13, paddingLeft: 5, color: 'rgba(0,0,0,0.8)'}}>{`${assignmentList.length - Object.keys(this.state.selectedAssignments).length}/${assignmentList.length}`}
                            </div>
                          </div>
                          <List style={{
                            width: '100%',
                            maxHeight: 225,
                            overflowY: 'scroll',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderColor: 'rgba(0,0,0,0.2)',
                            borderRadius: 1,
                            padding: 0,
                          }}>
                            {assignmentList.map((project, idx) => {
                              const selected = !(project.name in this.state.selectedAssignments)
                              return (
                                <ListItem
                                  key={project.name}
                                  dense
                                  button
                                  divider
                                  style={{
                                    padding: 0,
                                  }}
                                  onClick={() => this.handleAssignmentListItemClick(project.name)}
                                  hoverColor={'red'}
                                  disabled={this.state.submitting || this.state.showNoAccess || this.state.selectedCourse === ''}
                                >
                                  <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', width: '100%'}}>
                                    <Checkbox
                                      checked={selected}
                                      style={{color:  selected ? 'rgba(76, 175, 79)' : 'rgba(0,0,0,0.4)'}}
                                      disableRipple
                                    />
                                    <div style={{color: selected ? null : 'rgba(0,0,0,0.55)'}}>{`${idx+1}  -  ${project.name}`}</div>
                                    <div style={{width: 24}}></div>
                                  </div>
                                </ListItem>
                              )
                            })}
                          </List>
                        </>}
                    </>:null}
                </div>
            }
          </div>
        </form>
      </Fragment>
    )
  }

  handleClose = (result) => {
    this.props.onClose(result)
  }

  handleOk = async () => {
    if (this.state.selectedCourse !== '') {
      this.setState({ submitting: true })

      let responses = []
      try {
        const course = this.state.courses.find(element => element.id === this.state.selectedCourse)
        if (course) {
          // Locate current module by name (TODO: keep indexed?)
          let moduleId = null
          for (let m in moduleEnums) {
            if (moduleEnums[m] === this.props.currentModule) {
              moduleId = m
              break
            }
          }
          if (moduleId === null || !(moduleId in moduleProjects)) {
            // TODO: Report error to user - current curriculum has no projects!
            console.log('GoogleClassroomAssignments: NO PROJECTS!')
            this.handleClose(responses)
            return
          }
          const projectList = moduleProjects[moduleId]
          // const workResponse = await googleApi.getCourseWork(this.state.selectedCourse)
          // const existingAssignments =
          const assignmentList = projectList.filter(project => project.hasAssignment)
          function getIdx(projectName) {
            for (const i in assignmentList) {
              const assignment = assignmentList[i]
              if (assignment.name === projectName){
                return parseInt(i)
              }
            }
          }
          responses = await Promise.all(projectList.filter(project => project.hasAssignment && !(project.name in this.state.selectedAssignments)).map((project, index) => googleApi.createCourseWork(this.state.selectedCourse, {
            title: `Lesson ${getIdx(project.name) + 1}: ${project.name}`,
            description: project.description,
            state: 'DRAFT',
            workType: 'ASSIGNMENT',
            materials: [
              {
                link: {
                  url: `https://makebit.firialabs.com/module/${moduleId}/project/${getIdx(project.name) + 1}`, // Missing trailing slash seems to be important for preview to work

                  // Can't set these below; see https://issuetracker.google.com/issues/36760273
                  title: null,
                  thumbnailUrl: null,
                },
              },
            ],
          })))
        }

        // Get student list and save their course/student IDs in our 'gclassroom-users' collection
        const response = await googleApi.getCourseAllStudents(this.state.selectedCourse)
        if (response.result.students) {
          await Promise.all(response.result.students.map(student => cblFirebase.db.collection('gclassroom-users').doc(student.userId).set({
            courseId: this.state.selectedCourse,
            studentId: student.userId,
          })))
        }
      } catch (error) {
        console.error(error)
      }

      this.handleClose(responses)
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) { // ENTER
      this.handleOk()
    } else if (e.keyCode === 27) { // ESCAPE
      this.handleClose(null)
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.handleClose(null)}
        onKeyDown={this.handleKeyDown}
        role='none'
      >
        <DialogTitle id='form-dialog-title'>Add Google Classroom Assignments</DialogTitle>
        <DialogContent style={{width: 450}}>
          {this.getContent()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleClose(null)}
            color='primary'
            autoFocus
            disabled={this.state.submitting}
          >
            Close
          </Button>
          <Button
            id='gclassroom-submit-ok'
            onClick={this.handleOk}
            color='primary'
            disabled={this.state.submitting || this.state.showNoAccess || this.state.selectedCourse === ''}
          >
            {
              this.state.submitting ?
                <span>Adding...</span>:
                <span>{'Add Assignments'}</span>
            }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default GoogleClassroomAssignments
