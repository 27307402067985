import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'


export const UsbIcon = props => (
  <SvgIcon {...props}>
      <path d="M 24,5.333754 21.3339,3.794098 V 4.892413 H 7.7067 l 2.65337,-2.803307 c 0.22581,-0.181001 0.52126,-0.307974 0.82518,-0.315007 1.2295,0 1.95963,-3.2e-4 2.22839,-8.52e-4 0.18218,0.519026 0.67152,0.893656 1.25326,0.893656 0.73594,0 1.33319,-0.597192 1.33319,-1.333398 C 16.00009,0.596979 15.4029,0 14.6669,0 14.08516,0 13.59582,0.374417 13.41364,0.893017 l -2.20228,-3.73e-4 c -0.59687,0 -1.2223,0.327475 -1.62038,0.711377 0.0109,-0.0114 0.0222,-0.02355 -6.3e-4,2.13e-4 -0.008,0.0095 -2.81514,2.973918 -2.81514,2.973918 C 6.54988,4.758887 6.25458,4.88506 5.95087,4.892307 H 4.4093 C 4.20496,3.873915 3.30566,3.106645 2.2269,3.106645 0.99697,3.106645 0,4.103617 0,5.333164 c 0,1.22993 0.99697,2.22695 2.2269,2.22695 1.07897,0 1.97827,-0.76791 2.18261,-1.78715 h 1.51456 c 0.004,0 0.008,2.1e-4 0.0117,0 h 3.34994 c 0.30312,0.008 0.59804,0.13406 0.82316,0.31501 0,0 2.80608,2.96422 2.81476,2.97381 0.0225,0.0238 0.0113,0.0114 2.2e-4,2.1e-4 0.39807,0.38385 1.02377,0.71111 1.62075,0.71111 l 2.12235,-4.8e-4 v 0.89392 h 2.6668 v -2.66653 h -2.6668 v 0.89275 c 0,0 -0.55898,-10e-4 -2.14835,-10e-4 -0.30398,-0.007 -0.59975,-0.13379 -0.82546,-0.31479 l -2.65395,-2.80384 h 10.29469 v 1.10007 z" />
  </SvgIcon>
)

export default UsbIcon