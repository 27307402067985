// Bot Animation
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CodeBotHead from '../StartCoding/assets/codebot_head.svg'
import CodeBotHeadBlink from '../StartCoding/assets/codebot_head_blink.svg'
import CodeBotBody from '../StartCoding/assets/codebot_body.svg'
import CodeBotFlame from '../StartCoding/assets/codebot_flame.svg'
import CodeBotPeriphs from './assets/codebot_peripherals2.svg'

export default class FlyingCody extends Component {
  static propTypes = {
    wanderRate: PropTypes.number,
  }
  constructor(props, context) {
    super(props)
    this.state = {
    }
  }

  render() {
    const css = `
    @keyframes headBlink {
      0% {
        opacity: 0;
      }
      95% {
        opacity: 0;
      }
      96% {
        opacity: 1;
      }
      99% {
        opacity: 1;
      }
    }
    @keyframes motionBodyWander {
      from { transform: translateX(-60px) }
      to   { transform: translateX(40px) }
    }
    @keyframes motionBody {
      from { transform: rotate(0deg) translateX(10px) rotate(0deg); }
      to   { transform: rotate(360deg) translateX(10px) rotate(-360deg); }
    }
    @keyframes motionHead {
      from { transform: rotate(0deg) translateX(1px) rotate(0deg); }
      to   { transform: rotate(360deg) translateX(1px) rotate(-360deg); }
    }
    @keyframes motionFlame {
      from { transform: translateY(0px) }
      to   { transform: translateY(4px) }
    }
    @keyframes perspective {
      from { transform: scale(1) }
      to   { transform: scale(0.8) }
    }
    `
    return (
      <div
      >
        <style>
          {css}
        </style>
        <div
          className={"cody-styles"}
          style={{
            display: 'block',
            width: 100,
            margin: 'auto',
            animation: `motionBodyWander ${this.props.wanderRate}s ease-in-out 0s infinite alternate`
           }}
        >
          <div
            style={{
              display: 'block',
              width: 100,
              height: 200,
              margin: 'auto',
              position: 'relative',
              animation: 'motionBody 4s linear 0s infinite',
            }}
          >
            <img src={CodeBotHead} alt="" style={{
              display: 'block',
              margin: 'auto',
              width: 100,
              position: 'absolute',
              zIndex: 5,
              animation: 'motionHead 4s linear -2s infinite',
            }}/>
            <img src={CodeBotHeadBlink} alt="" style={{
              display: 'block',
              opacity: '0',
              margin: 'auto',
              width: 100,
              position: 'absolute',
              zIndex: 6,
              animation: 'motionHead 4s linear -2s infinite, headBlink 14s linear 0s infinite',
            }}/>
            <img src={CodeBotBody} alt="" style={{
              display: 'block',
              margin: 'auto',
              width: 100,
              position: 'absolute',
              zIndex: 4,
            }}/>
            <img src={CodeBotFlame} alt="" style={{
              display: 'block',
              margin: 'auto',
              width: 100,
              position: 'absolute',
              top: '-2px',
              zIndex: 3,
              animation: 'motionFlame 2s ease 0s infinite alternate',
            }}/>
            <img src={CodeBotPeriphs} alt="" style={{
              display: 'block',
              margin: 'auto',
              width: 300,
              position: 'absolute',
              left: '-100px',
              top: '10px',
              zIndex: 2,
            }}/>
          </div>
        </div>
      </div>
    )
  }
}
