
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StepComplete from '../StepComplete'
import Markdown from '../cbl-remarkable'
import GoogleClassroomImg from './assets/periph_kit_explore3.jpg'
import LiftOffProjIntroImg from './assets/iStock-1162815759.jpg'
import ShipLiftOffImg from './assets/iStock-1081929288.jpg'
import SwitchImg from './assets/iStock-478272220.gif'
import CleanEnergyImg from './assets/iStock-1170425284.jpg'
import LoopImg from './assets/iStock-184233907.jpg'
import ButtonImg from './assets/iStock-1201200962.jpg'
import BreakImg from './assets/iStock-488052330.jpg'
import CountdownImg from './assets/iStock-485196183.jpg'
import AltPinFuncsImg from './assets/octo_alt_funcs.jpg'
import { LegendOctoHeader } from './OctoHeader'

import Quiz from '../Quiz'


import {
  LegendMessageRun,
  LegendMessageKeyboard,
  LegendMessageConcept,
  LegendMessageWarning,
  LegendMessageInteract,
} from '../Legends'

export const LiftOffImg = GoogleClassroomImg

export class ProjectLiftOff extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Project: Lift-Off! {lesson-title}
___
### This project explores buttons and switches, with a quick refresher on many of the coding concepts you have learned previously!
`}
<img src={LiftOffProjIntroImg} alt=""
    style={{
      width: 400, margin: 'auto', marginLeft: '1em', display:'block', float:'right'
    }}
/>
{`
**Mission Briefing:**

The first project in **Mission: Lift Off** is getting the crew's rocket ship off the ground.

The ship is going to need a **power switch**, all personnel will be tuned to a **countdown sequence**, and mission control needs a **launch button** to fire the engines and blast the crew's rocket out of the atmosphere.

#### Project Goals:
* Utilize buttons, switches, and LEDs together
* Understand the differences between a button and a switch
* Learn about additional IO pin functions on the **micro:bit**
* Refresh your **Python** knowledge
* Get the crew's mission to Mars underway!!

#### You should start where you ended in the peripherals intro {centered}
`}
<LegendMessageKeyboard>
{`
#### Make sure this code is displayed in the editor:
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

set_red_led(LED_ON)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendOctoHeader 
  pin={0}
  peripheral={6} // 6 = Red LED
  markdown={`Make sure the **red LED** is connected to **position 0** on the ==octopus:bit==.`}
/>
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
    </div>
  )
  }
}

export class AddASwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Add a Switch {lesson-title}
___
#### The first step is getting power on the ship! {centered}
`}
<img src={SwitchImg} alt=""
    style={{
      width: "30%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
You will need a **switch** to turn on power for the shuttle's launch operation.

A switch is a ==peripheral== that can be set to a ==binary== **ON** or **OFF** position.

The switch in your ==LiftOff Kit== is similar to a light switch in your **home**.
`}
<LegendOctoHeader 
  pin={1}
  peripheral={4} // 4 = Switch
  markdown={`Connect the **switch** to **position 1** on your ==octopus:bit== now!`}
/>
<LegendMessageInteract title="Go ahead and press the switch a few times to see how it moves!" />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if your switch is connected!"
      />
    </div>
  )
  }
}

export class ReadSwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Turn the Power ON!! {lesson-title}
___
#### Now that your switch is connected you can use it to turn the power ON. {centered}

Use the \`read_digital()\` ==function== in **micro:bit** ==pin==s to read the position of your switch. 

This code snippet returns the ==binary== value of a switch connected to **position 1**:

\`\`\`
switch_val = pin1.read_digital()
\`\`\`

The **micro:bit** library uses *pull-up* mode on input ==pins== by default. This means the return value will be:
* \`1\` normally if nothing is connected or the switch is extended **OUT**
* \`0\` if the switch is pressed **IN** and the circuit is connected

**Try it out using your red LED as a test platform!** {centered}
`}
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

$switch_val = pin1.read_digital()

$set_red_led(switch_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageInteract title="Extend the switch to the OUT position." />
<LegendMessageRun>
{`
**REMINDER:** This program does not loop. It will read the switch one time, set the LED, and end.
`}
</LegendMessageRun>
{`
Your **red LED**
should be **ON**
and shining bright. {centered} 
`}
<LegendMessageInteract title="Now press the switch to the IN position." />
<LegendMessageRun />
{`
Your **red LED**
should be **OFF**. {centered}

If you are thinking, *"Hmmm... the power should come **ON** when the switch is **IN**"* ... You will get to that soon, but it is **important** to realize that you can **choose** which way it works in your own projects!
`}
</Markdown>
      <StepComplete
            prompt="Did you use your switch to turn ON an LED?"
            xp={5}
            successMessage=
{`## AWESOME!! {centered}
### You are using inputs and outputs together!
#### Proceed to the next step.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital', 'set_red_led', 'pin0.write_digital']}
            reqFuncdefs={['set_red_led']}
            reqNames={['LED_ON', 'LED_OFF', 'switch_val']}
            reqArgs={['switch_val']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

export class ConstantSwitching extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Loop, Loop, Loop the Switch {lesson-title}
___
#### You can turn the power ON, but it is a bit of a hassle to run your program each time.
`}
<img src={LoopImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
*"How can I make the program keep checking?"* 

If you reach back into your **Python** knowledge, you might remember that ==loops== allow a program to run sections
of code multiple times.

With a ==loop==, you can check the position of your switch over and over again. You can use a \`while True:\` loop like this to make it run forever:
\`\`\`
while True:
    switch_val = pin1.read_digital()
    
    set_red_led(switch_val)
\`\`\`

**Test out your new loop!!** {centered}
`}
<LegendMessageKeyboard>
{`Remember to use the ==Editor Shortcut==s to select those two lines of code and ***TAB*** to ==indent== them inside the loop.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

$while True:
$    switch_val = pin1.read_digital()
    
$    set_red_led(switch_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Now cycle the power switch position between IN and OUT." />
</Markdown>

<StepComplete
            prompt="Is your switch working in the loop?"
            xp={5}
            successMessage=
{`## LOOPING!! {centered}
### Your switch now works all the time!
#### Proceed to the next step.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital', 'set_red_led', 'pin0.write_digital']}
            reqFuncdefs={['set_red_led']}
            reqNames={['LED_ON', 'LED_OFF', 'switch_val']}
            reqArgs={['switch_val']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

export class FunctionalSwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## A Functional Switch {lesson-title}
___
#### Your *code* can read the switch.
> Now make your code easier for a ***person*** to read!

Start by writing a ==function== that ==returns== the switch value:
\`\`\`
def read_power_switch():
    return pin1.read_digital()
\`\`\`

See how this function is more meaningful?
`}
<LegendMessageConcept title="Concept: Abstraction">
<Markdown>
{`
Your code will get more **complicated** and harder to read as you add more ==peripherals== to a project. It is **crucial** to stay **organized**!

A powerful tool for organizing code is **abstraction**. When you define a function like \`read_power_switch()\` you are creating a new *abstraction!*
* Now the rest of the code doesn't have to know about the details.
* In the future you could completely change how the *power switch* works, and all the other code would keep working without change!
`}
</Markdown>
</LegendMessageConcept>
<LegendMessageKeyboard>
{`
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

def set_red_led(val):
    pin0.write_digital(val)

$def read_power_switch():
$    return pin1.read_digital()

while True:
$    switch_val = read_power_switch()
    
    set_red_led(switch_val)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
</Markdown>

<StepComplete
            prompt="Is your read power switch function operational?"
            xp={5}
            successMessage=
{`## NICE WORK!! {centered}
### Optimally *organized* and AMAZINGLY *Abstract!*
* Your *low-level* code deals with the details (pins).
* ...but at a high-level it's about **Switches and LEDs!**
> *I can almost hear the countdown starting!*
#### Proceed to the next step.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital', 'set_red_led', 'pin0.write_digital', 'read_power_switch']}
            reqFuncdefs={['set_red_led', 'read_power_switch']}
            reqNames={['LED_ON', 'LED_OFF', 'switch_val']}
            reqArgs={['switch_val']}
            reqStatements={['while']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

export class CleanPower extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Clean Energy {lesson-title}
___
#### Now, how do you make the LED turn ON when the switch is pressed *IN?*
`}
<img src={CleanEnergyImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
Start by mapping it out. Say you want:
* **ON** if the switch is pressed **IN**
* **OFF** if the switch is extended **OUT**

Since \`read_power_switch()\` returns \`0\` when pressed **IN**, go ahead and declare that \`0\` means \`POWER_ON\`.

Define a ==variable== at the top of your file:
\`\`\`
POWER_ON = 0
\`\`\`

*"Now, how can I turn the LED on*  **\`if\`**  *the switch value is \`POWER_ON\`?"* 

Get your **Python** thinking cap on again. Think... Think... That's it!!

**You can use ==branching== to turn on your LED!**

An **\`if\`** statement allows your code to choose its path based on a ==condition==. 
`}
<LegendMessageKeyboard>
{`
Rewrite your while ==loop== like the following.
* Watch out for the *double equals* ==comparison== operator!
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

$POWER_ON = 0

def set_red_led(val):
    pin0.write_digital(val)

def read_power_switch():
    return pin1.read_digital()

$while True:
$    switch_val = read_power_switch()

$    if switch_val == POWER_ON:
$        set_red_led(LED_ON)
$    else:
$        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the switch IN to turn ON the red LED." />
</Markdown>
      <StepComplete
            prompt="Did the red LED turn on and stay lit when you pressed the switch IN?"
            xp={25}
            successMessage=
{`## AWESOME!! {centered}
### You have just learned how to apply power to your launch operation!
#### Proceed to the next step to start work on a launch countdown.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital', 'pin0.write_digital', 'read_power_switch', 'set_red_led']}
            reqFuncdefs={['set_red_led', 'read_power_switch']}
            reqNames={['LED_ON', 'LED_OFF', 'POWER_ON', 'switch_val']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

export class ButtonVsSwitch extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Button vs Switch {lesson-title}
___
#### You are going need a button to start your countdown to lift-off!
`}
<img src={ButtonImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
*"What is the difference between a button and a switch?"*

As strange as it seems, the **micro:bit** does not see a difference! 

A button and a switch are both read with \`pin.read_digital()\`.

The difference between a button and a switch is entirely **mechanical**:
* A switch locks in the **ON** position 
* A button only stays in the **ON** position until it is released
`}
<LegendOctoHeader 
  pin={5}
  peripheral={0} // 0 = Button
  markdown={`Connect the **button** to **position 5** on your ==octopus:bit==!`}
/>
<Quiz
        lessons={this.props.lessons}
        prompt="Which peripheral momentarily stays ON when you press it but does not lock into place?"
        xp={5}
        answerRight={"Button"}
        answerWrong={["Switch", "Red LED"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
   </div>
  )
  }
}

export class ReadButton extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Read That Button {lesson-title}
___
#### You need some code to read your button!
Before you can write the code to start the countdown, you'll need a new function that *reads* your new button.
* Naturally you'll want an *easy-to-read* and *meaningful* function for this!

Start with some test code to turn **red LED** on *only* while the button is being pressed.
`}
<LegendMessageKeyboard>
{`
1. Add a new \`def read_countdown_btn():\` ==function== just above \`def read_power_switch():\`
\`\`\`
def read_countdown_btn():
\`\`\`

2. Next, add a ==constant== to the top of the file that defines the *digital value* when the button is *pressed*.
The value returned by \`read_digital()\` is \`0\` when **pressed** just like the switch:
\`\`\`
PRESSED = 0
\`\`\`

3. Finally, change the ==branch== statement in your ==loop== to read the ***button*** instead of the *switch.*

\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0

$PRESSED = 0

def set_red_led(val):
    pin0.write_digital(val)

$def read_countdown_btn():
$    return pin5.read_digital()

def read_power_switch():
    return pin1.read_digital()

while True:
$    btn_val = read_countdown_btn()

$    if btn_val == PRESSED:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the button to blink the red LED.">
{`Try *momentarily* pressing the **button**, and watch the **LED**.
* Can you flash it on and off quickly?
`}
</LegendMessageInteract>
</Markdown>
      <StepComplete
            prompt="Does the red LED turn ON while you're pressing the button?"
            xp={10}
            successMessage=
{`## GREAT JOB!! {centered}
### You just learned the difference between a button and a switch!
That's a *speedy* \`while True:\` loop you've got.
* Seems like the LED comes on *instantly* when you press that button!
#### Proceed to the next step.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital','pin0.write_digital','pin5.read_digital','set_red_led','read_countdown_btn']}
            reqFuncdefs={['set_red_led','read_countdown_btn','read_power_switch']}
            reqNames={['LED_ON','LED_OFF','PRESSED','POWER_ON','btn_val', 'btn_val==PRESSED']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

export class AltPinFunctions extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
  return (
    <div>
<Markdown>
{`
## Pin Alternate Functions {lesson-title}
___
The ==octopus:bit== gives you access to ***lots more ==pins==*** your code can control.
* Only \`pin0\`, \`pin1\`, and \`pin2\` are easy to access with 'gator clips!
* But... there is a catch :-/
`}
<LegendMessageWarning title={'Pins Acting Strange'}>
<Markdown>
{`
Some of the input / output ==pins== exposed by the ==octopus:bit== are already used by internal **micro:bit** ==peripherals==.
`}
</Markdown>
</LegendMessageWarning>
<LegendMessageInteract title={'How do I Find the Pin Alternate Functions?'}>
<Markdown>
{`
Click the link &rarr; ==alternate functions== to see a diagram of pin alternate functions.
This also adds the topic to the tool box in the *upper right hand corner* of your screen.
* Check out your *ToolBox!*
* It's a great reference when you're developing your own custom projects.
`}
</Markdown>
</LegendMessageInteract>
{`
#### This graphic lists all the capabilities and ==alternate functions== of pins on the ==octopus:bit==:
`}
<div style={{display:'flex'}}>
  <img style={{margin:'auto'}} src={AltPinFuncsImg} alt="octopus bit pin alternate functions"/>
</div>
{`
First, note the **green ~** symbol. That symbol means the ==pin== in that position can be used as an **==analog== input**.
You will need that capability to read sensors with the \`pin.read_analog()\` function.

If you study position **P4** (Pin 4) you will see that the **micro:bit** uses Pin 4 internally to light up LEDs in Column 2.
Pin 4 is also an **analog input** pin.

You may notice that **P0**, **P1**, and **P2** have no ==alternate functions==. 
* That means you can use them with **NO FEAR** of a conflict!!
* It is also why those 3 pins are the big exposed pads on the **micro:bit**.
`}
<Quiz
        lessons={this.props.lessons}
        prompt="If you needed to read a temperature sensor, as an analog input, which position could you connect to?"
        xp={5}
        answerRight={"P2 (Pin 2)"}
        answerWrong={["P13 (Pin 13)", "P6 (Pin 6)"]}
      />
      <Quiz
        lessons={this.props.lessons}
        prompt="What is the alternate function of position P9 (Pin 9)?"
        xp={5}
        answerRight={"LED Col 7"}
        answerWrong={["LED Col 8", "BUTTON B"]}
      />
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
      />
   </div>
  )
  }
}

export class AltButtonA extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }
  render() {
    return (
      <div>
  <Markdown>
{`
## BUTTON A Alternate {lesson-title}
___
Earlier, you connected a button to position **P5** (Pin 5). 
The alternate function of **P5** is **BUTTON A**. 
`}
<img src={AltPinFuncsImg} alt="octopus bit pin alternate functions"/>
{`
So... the ==pin== you used for your button is the same pin used for **BUTTON A** on the base **micro:bit**.

**It's time to try an experiment. Run your same code again.** {centered}
`}
<LegendMessageRun />
<LegendMessageInteract title="This time press BUTTON A on the micro:bit." />
</Markdown>
      <StepComplete
            prompt="Did the red LED turn on whenever you pressed BUTTON A?"
            xp={5}
            successMessage=
{`## WOW!! {centered}
### You witnessed pin ==alternate functions== in action!
#### Proceed to the next step to start writing code for a countdown.
`}
            reqSyntax={true}  // default
            reqImports={['microbit']}
            reqCalls={['pin1.read_digital','pin0.write_digital','pin5.read_digital','set_red_led','read_countdown_btn']}
            reqFuncdefs={['set_red_led','read_countdown_btn','read_power_switch']}
            reqNames={['LED_ON','LED_OFF','PRESSED','POWER_ON','btn_val']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqStatements={['while', 'if', 'else']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
            gFileId={this.props.gFileId}
      />
    </div>
  )
  }
}

class WhiteLed extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Red and White All Over {lesson-title}
___
A ***flashing*** *white LED* will make a great **Countdown Indicator**.
* First get the LED ready.
* *Flashing* will come next!
`}

<LegendOctoHeader 
  pin={16}
  peripheral={2} // 2 = White LED
  markdown={`Connect the **white LED** to **position 16** on your ==octopus:bit==!`}
/>
{`
Add a new ==function== \`set_white_led\` above the \`set_red_led\` function.

The function should set the **white LED** to the given ==parameter== value.
* *HINT:* you can **reuse** the \`LED_ON\` and \`LED_OFF\` ==constants== as *arguments* to this function!!
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0

PRESSED = 0

$def set_white_led(val):
$    pin16.write_digital(val)

def set_red_led(val):
    pin0.write_digital(val)

def read_countdown_btn():
    return pin5.read_digital()

def read_power_switch():
    return pin1.read_digital()

while True:
    btn_val = read_countdown_btn()

    if btn_val == PRESSED:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</Markdown>
      <StepComplete
        lessons={this.props.lessons}
        btnNext={true}
        prompt="Press NEXT if the white LED is ready for use!"
      />
    </div>
    )
  }
}

class Countdown extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Countdown! {lesson-title}
___
#### Add a new \`def countdown():\` ==function== so the launch operation can prepare for lift-off!
`}
<img src={CountdownImg} alt=""
    style={{
      width: "40%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
You will need to use ==delays== to make a proper countdown. 

If you remember, a *delay* is just a pause in your program. 
* It is as simple as calling the \`sleep()\` function with a *millisecond* ==argument==.
\`\`\`
sleep(1000) # delay for 1 second
\`\`\`
`}
<div style={{clear:'both'}} />
<LegendMessageKeyboard>
{`
1. Add the \`def countdown():\` function just above your while ==loop==.
 * The function should flash the **white LED** a few times:
2. Update the \`if\` statement in your ==loop== to call the \`countdown\` function when the button is pressed.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0
PRESSED = 0

def set_red_led(val):
    pin0.write_digital(val)

def set_white_led(val):
    pin16.write_digital(val)

def read_countdown_btn():
    return pin5.read_digital()

def read_power_switch():
    return pin1.read_digital()

$$$
$def countdown():
$    set_white_led(LED_ON)
$    sleep(500)
$    set_white_led(LED_OFF)
$    sleep(500)
$    set_white_led(LED_ON)
$    sleep(500)
$    set_white_led(LED_OFF)
$    sleep(500)
$    set_white_led(LED_ON)

while True:
    btn_val = read_countdown_btn()

$    if btn_val == PRESSED:
$        countdown()

$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Press the button to start the countdown sequence." />

          <StepComplete
            prompt="Did your white LED flash a countdown sequence?"
            xp={5}
            successMessage=
{`## Excellent! {centered}
### You are counting down. It is nearly time for lift-off!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','set_red_led','read_countdown_btn','read_power_switch','countdown']}
            reqCalls={['pin16.write_digital','pin5.read_digital','pin1.read_digital','pin0.write_digital','set_white_led','read_countdown_btn','countdown','sleep']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqNames={['LED_ON', 'LED_OFF', 'PRESSED','POWER_ON', 'btn_val']}
            reqStatements={['while','if']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
        </Markdown>
      </div>
    )
  }
}

class SwitchAndButton extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Switches and Buttons... Oh My!{lesson-title}
___
#### What if you only want your *countdown sequence* to happen if the *power is ON?*
`}
<LegendMessageConcept>
{`
There is **always** more than one way to accomplish something in code!

This problem is no different. There are **MANY** ways to solve this.
`}
</LegendMessageConcept>
{`
That said... Why don't you try using an \`elif\`? 
* An \`elif\` allows you to use more than two ==conditions== in a ==branching== \`if\` statement.

You can also use an \`and\` ==logical operator== to test if the power is on \`and\` the button was pressed

**The three ==conditions== you will check are:**
1. \`if\` *the power is on* \`and\` *the button was pressed*
    * start the countdown sequence
2. \`elif\` *the power is on (and you already know the button is not pressed)*
    * turn the red LED on
3. \`else\`
    * turn the red LED off because the power is off

`}
<LegendMessageKeyboard>
{`
Go ahead and replace your ==loop== with this one.  It applies the three ==conditions== above.
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0
PRESSED = 0

def set_red_led(val):
    pin0.write_digital(val)

def set_white_led(val):
    pin16.write_digital(val)
    
def read_countdown_btn():
    return pin5.read_digital()

def read_power_switch():
    return pin1.read_digital()

def countdown():
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)

$$$
while True:
    btn_val = read_countdown_btn()
    switch_val = read_power_switch()

    if btn_val == PRESSED and switch_val == POWER_ON:
        countdown()
    elif switch_val == POWER_ON:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />
<LegendMessageInteract title="Test each possibility">
<Markdown>
{`
1. Start with the power switch **OFF**. Make sure the red LED is **OFF**.
2. Try pressing the button and make sure it doesn't do anything.
3. Turn the power switch **ON**. Check that the red LED turns **ON**.
4. Press the button to start the countdown sequence!!
`}
</Markdown>
</LegendMessageInteract>


          <StepComplete
            prompt="Did your white LED flash a countdown sequence?"
            xp={5}
            successMessage=
{`## Excellent! {centered}
### You are almost ready for lift-off!
`}
            reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_countdown_btn','countdown','read_power_switch','set_red_led']}
            reqCalls={['pin16.write_digital','pin5.read_digital','pin0.write_digital','pin1.read_digital','set_white_led','read_countdown_btn','countdown','sleep','read_power_switch','set_red_led']}
            reqArgs={['LED_ON', 'LED_OFF']}
            reqNames={['LED_ON', 'LED_OFF', 'PRESSED','POWER_ON', 'btn_val', 'switch_val']}
            reqStatements={['while','if','elif','else','and']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />
        </Markdown>
      </div>
    )
  }
}

class BreakOff extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Need a Break! {lesson-title}
___
#### This rocket only launches *once*
* But your \`countdown()\` sequence runs *every time* you press the button.
* Can you *remove* the ability to countdown twice?
`}
<img src={BreakImg} alt=""
    style={{
      width: "25%", margin: 'auto', display:'block', float:'right', marginRight:10, marginLeft: 10, marginBottom: 10
    }}
/>
{`
You can **stop** your program after the \`countdown()\` by exiting the ==loop==. 

*Do you remember how to exit a ==loop==?*
* You can use the \`break\` statement. 

Calling \`break\` immediately exits a ==loop==.
`}
<LegendMessageKeyboard>
{`**Add a \`break\` to your code right after \`countdown()\`:**
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0
PRESSED = 0

def set_red_led(val):
    pin0.write_digital(val)

def set_white_led(val):
    pin16.write_digital(val)
    
def read_countdown_btn():
    return pin5.read_digital()

def read_power_switch():
    return pin1.read_digital()

def countdown():
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)

$$$
while True:
    btn_val = read_countdown_btn()
    switch_val = read_power_switch()

    if btn_val == PRESSED and switch_val == POWER_ON:
        countdown()
$        break
    elif switch_val == POWER_ON:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
$$$
\`\`\`
`}
</LegendMessageKeyboard>
<Quiz
    lessons={this.props.lessons}
    id={"if elif"}
    prompt={(
      <Markdown>
        {`What statement allows you to add ==condition==al branches to an \`if\` statement?`}
      </Markdown>
    )}
    xp={5}
    answerRight={"elif"}
    answerWrong={["break", "and"]}
/>
<Quiz
    lessons={this.props.lessons}
    prompt="What statement exits a loop immediately when called?"
    xp={5}
    answerRight={"break"}
    answerWrong={["elif", "and"]}
/>
</Markdown>
<StepComplete
    prompt="Can you only launch once?"
    xp={5}
    successMessage=
{`## Good Stuff! {centered}
### Your *launch system* is ready.
`}
    reqImports={['microbit']}
    reqFuncdefs={['set_white_led','read_countdown_btn','countdown','read_power_switch','set_red_led']}
    reqCalls={['pin16.write_digital','pin5.read_digital','pin0.write_digital','pin1.read_digital','set_white_led','read_countdown_btn','countdown','sleep','read_power_switch','set_red_led']}
    reqArgs={['LED_ON', 'LED_OFF']}
    reqNames={['LED_ON', 'LED_OFF', 'PRESSED','POWER_ON', 'btn_val', 'switch_val']}
    reqStatements={['while','if','elif','else','and', 'break']}
    lessons={this.props.lessons}
    btnYes={true}
    btnNo={true}
/>
   </div>
    )
  }
}

class LiftOff extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
{`## Lift-Off{lesson-title}
___
#### Are you ready for launch?

You can simulate a lift-off on the **micro:bit** using \`display.show()\` at the end of your \`countdown()\` ==function==.
\`\`\`
display.show(Image.DIAMOND_SMALL)
sleep(1000)
display.show(Image.DIAMOND)
\`\`\`

#### And now you are ready to send this ship into outer space!!
`}
<LegendMessageKeyboard>
{`Add your lift-off simulation:
\`\`\`
from microbit import *

LED_ON = 1
LED_OFF = 0

POWER_ON = 0

PRESSED = 0

def set_white_led(val):
    pin16.write_digital(val)

def set_red_led(val):
    pin0.write_digital(val)
    
def read_countdown_btn():
    return pin5.read_digital()
    
def read_power_switch():
    return pin1.read_digital()
    
def countdown():
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)
    sleep(500)
    set_white_led(LED_OFF)
    sleep(500)
    set_white_led(LED_ON)
$$$
$    display.show(Image.DIAMOND_SMALL)
$    sleep(1000)
$    display.show(Image.DIAMOND)
$$$

while True:
    btn_val = read_countdown_btn()
    switch_val = read_power_switch()

    if btn_val == PRESSED and switch_val== POWER_ON:
        countdown()
        break
    elif switch_val == POWER_ON:
        set_red_led(LED_ON)
    else:
        set_red_led(LED_OFF)
\`\`\`
`}
</LegendMessageKeyboard>
<LegendMessageRun />

          <StepComplete
            prompt="Did your white LED flash a countdown sequence and then your micro:bit simulate a rocket lifting off?"
            xp={25}
            successMessage=
{`## Excellent! {centered}
### Your rocket has Lifted Off!! Your mission into outer space is underway!
`}
reqImports={['microbit']}
            reqFuncdefs={['set_white_led','read_countdown_btn','countdown','read_power_switch','set_red_led']}
            reqCalls={['display.show','pin16.write_digital','pin5.read_digital','pin0.write_digital','pin1.read_digital','set_white_led','read_countdown_btn','countdown','sleep','read_power_switch','set_red_led']}
            reqArgs={['LED_ON', 'LED_OFF','Image.DIAMOND_SMALL','Image.DIAMOND']}
            reqNames={['LED_ON', 'LED_OFF', 'PRESSED','POWER_ON','btn_val','switch_val']}
            reqStatements={['while','if','elif','else','and','break']}
            lessons={this.props.lessons}
            btnYes={true}
            btnNo={true}
          />

        </Markdown>
      </div>
    )
  }
}

class Houston extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
        <Markdown>
          {
`## Houston, We Have Lift Off! {lesson-title}
___

### You've completed project Lift-Off!
...and you helped launch the crew into outer space. What will they need now that they have left Earth's amosphere?
`}
        <img src={ShipLiftOffImg} alt="" style={{margin: 'auto', width:'100%', display: 'block'}} />

        </Markdown>
        <StepComplete
          prompt="New peripherals are just around the corner.. Click Next!"
          lessons={this.props.lessons}
          btnNext={true}
          btnGClassroom
          gFileId={this.props.gFileId}
        />
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)
ProjectLiftOff.stepId = 'ProjectLiftOff'
AddASwitch.stepId = 'AddASwitch'
ReadSwitch.stepId = 'ReadSwitch'
ConstantSwitching.stepId = 'ConstantSwitching'
FunctionalSwitch.stepId = 'FunctionalSwitch'
CleanPower.stepId = 'CleanPower'
ButtonVsSwitch.stepId = 'ButtonVsSwitch'
ReadButton.stepId = 'ReadButton'
AltPinFunctions.stepId = 'AltPinFunctions'
AltButtonA.stepId = 'AltButtonA'
WhiteLed.stepId = 'WhiteLed'
Countdown.stepId = 'Countdown'
SwitchAndButton.stepId = 'SwitchAndButton'
BreakOff.stepId = 'BreakOff'
LiftOff.stepId = 'LiftOff'
Houston.stepId = 'Houston'

export const liftOff = [
  ProjectLiftOff,
  AddASwitch,
  ReadSwitch,
  ConstantSwitching,
  FunctionalSwitch,
  CleanPower,
  ButtonVsSwitch,
  ReadButton,
  AltPinFunctions,
  AltButtonA,
  WhiteLed,
  Countdown,
  SwitchAndButton,
  BreakOff,
  LiftOff,
  Houston,
]
