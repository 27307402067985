import { moduleEnums, moduleProjects, moduleDescription } from '../lessons/CurriculumModules'
import firebase, { cblFirebase } from '../myfirebase'
import { BASE_LICENSOR_URL } from '../CodeSpaceLicensor'

class Group {
  constructor() {
    this.groupId = ''
    this.groupName = 'Default Class Name'
    this.curriculum = moduleEnums[0]
    this.joinCode = ''
    this.joinActive = true
    this.created = 1411188818541
  }
}

class DashboardData {
  constructor() {
    this.codespace = this.getCodespaceData()
    this.groups = null
    this.owner = null
  }

  getCodespaceData = () => {
    const r = { data: null }
    // TODO: could look at the efficiency of this "deep copy"
    r.data = JSON.parse(JSON.stringify(moduleDescription))
    Object.keys(moduleDescription).forEach((prjId) => {
      r.data[prjId].enum = prjId in moduleEnums ? moduleEnums[prjId] : ''
      r.data[prjId].projects = prjId in moduleProjects ? moduleProjects[prjId] : []
    })
    const json = JSON.stringify(r, (key, value) => (typeof value === 'function' ? value.stepId : value))
    // Extract stepId strings while converting to JSON (project steps would otherwise be 'null')
    return JSON.parse(json).data
  }
}

const data = new DashboardData()

const getDateObj = (obj, field) => {
  // firebase seems to return bizarre fields from its Timestamp object 
  // if a cloud function retrieves it rather than going direct to the db
  if (obj[field] instanceof Date) {
    return obj[field]
  }

  let newDate = new Date(1970, 0, 1)
  if (typeof (obj[field]) !== 'undefined') {
    try {
      newDate = obj[field].toDate()
    } catch (e) {
      if (typeof (obj[field]._seconds) !== 'undefined') {
        newDate = new Date(obj[field]._seconds * 1000)
      } else {
        // dont know what else to try - hopefully those catch everything
      }
    }
  }
  return newDate
}

class Database {
  static cloudCall = (cloudFunc, bodyObj) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${BASE_LICENSOR_URL}/${cloudFunc}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + accessToken
          },
          body: JSON.stringify(bodyObj),
        },
      )
        .then((response) => {
          response.json().then((jsonData) => {
            if (!response.ok) {
              reject(jsonData)
            }
            resolve(jsonData)
          })
        }).catch(reject)
    })
  }

  static getGroupsData = () => {
    return new Promise((resolve, reject) => {
      const myGroupsData = []
      const getGroupPromises = []
      const userData = cblFirebase.userDoc.data()
      if (typeof (userData.groupsOwned) !== 'undefined') {
        userData.groupsOwned.forEach((gId) => {
          getGroupPromises.push(cblFirebase.db.collection('groups').doc(gId).get())
        })
      }

      Promise.all(getGroupPromises).then((values) => {
        values.forEach((doc) => {
          if (doc.exists) {
            const dd = doc.data()
            myGroupsData.push({
              groupId: doc.id,
              groupName: dd.groupName,
              curriculum: dd.curriculum,
              joinCode: dd.joinCode,
              joinActive: dd.joinActive,
              created: getDateObj(dd, 'created'),
              modified: getDateObj(dd, 'modified'),
            })
          }
        })

        // TODO: make this a database call that checks the user's licenses
        const availableCurricula = []
        Object.values(moduleEnums).forEach((name) => {
          if (name.substring(0, 4) !== 'Free') {
            availableCurricula.push(name)
          }
        })

        resolve({ groupsData: myGroupsData, availableCurricula })
      }).catch(reject)
    })
  }

  static getGroupMembers = (groupId) => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('getGroupMembers', {
        uid: cblFirebase.userDoc.id,
        groupId,
      }).then((retData) => {
        // console.log(retData)
        resolve(retData)
      }).catch(reject)
    })
  }

  static joinGroup = (joinCode) => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('joinGroup', {
        uid: cblFirebase.userDoc.id,
        joinCode,
      })
        .then(resolve)
        .catch(reject)
    })
  }

  static removeGroupMember = (memberUid, groupId) => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('removeGroupMember', {
        uid: cblFirebase.userDoc.id,
        memberUid,
        groupId,
      })
        .then(resolve)
        .catch(reject)
    })
  }

  static getGroupsJoined = () => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('getGroupsJoined', {
        uid: cblFirebase.userDoc.id,
      })
        .then(resolve)
        .catch(reject)
    })
  }

  static updateGroupJoinCode = (groupId, oldJoinCode) => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('updateGroupJoinCode', {
        uid: cblFirebase.userDoc.id,
        groupId,
        oldJoinCode,
      }).then((retData) => {
        const g = data.groups.find((obj => obj.groupId === groupId))
        g.joinCode = retData.joinCode
        resolve()
      }).catch(reject)
    })
  }

  static createGroup = (groupName, curriculum) => {
    return new Promise((resolve, reject) => {
      Database.cloudCall('createGroup', {
        uid: cblFirebase.userDoc.id,
        groupName,
        curriculum,
      }).then((retData) => {
        cblFirebase.db.collection('groups').doc(retData.groupId).get().then((groupDoc) => {
          const grp = new Group()
          const groupData = groupDoc.data()
          grp.groupId = groupDoc.id
          grp.groupName = groupData.groupName
          grp.curriculum = groupData.curriculum
          grp.joinCode = groupData.joinCode
          grp.joinActive = groupData.joinActive
          grp.created = groupData.created
          data.groups.push(grp)
          resolve()
        })
      }).catch(reject)
    })
  }

  static deleteGroup = (groupId) => {
    const idx = data.groups.findIndex((obj => obj.groupId === groupId))
    const removed = data.groups.splice(idx, 1)
    return new Promise((resolve, reject) => {
      Database.cloudCall('deleteGroup', {
        uid: cblFirebase.userDoc.id,
        groupId,
      }).then((retData) => {
        if (retData.success) {
          resolve()
        } else {
          data.groups.push(removed)
          reject(new Error('Error while deleting group'))
        }
      }).catch(() => {
        data.groups.push(removed)
        reject(new Error('Error while deleting group'))
      })
    })
  }

  static updateGroupField = (groupId, field, newVal) => {
    return new Promise((resolve, reject) => {
      if (newVal === null || newVal === '' || field === 'joinCode') {
        // reject
      } else {
        const jsonObj = {}
        jsonObj[field] = newVal
        jsonObj.modified = firebase.firestore.FieldValue.serverTimestamp()
        jsonObj.modifiedBy = cblFirebase.userDoc.id
        cblFirebase.db.collection('groups').doc(groupId).update(jsonObj)
          .then(() => {
            const g = data.groups.find((obj => obj.groupId === groupId))
            g[field] = newVal
            resolve()
          })
          .catch(reject)
      }
    })
  }
}

export { Group, Database, data, DashboardData, getDateObj }
