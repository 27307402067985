import React from 'react'
// import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import Tooltip from 'material-ui/Tooltip'
import Table from 'material-ui/Table'
import TableBody from 'material-ui/Table/TableBody'
import TableCell from 'material-ui/Table/TableCell'
import TablePagination from 'material-ui/Table/TablePagination'
import TableRow from 'material-ui/Table/TableRow'
import RemoveIcon from 'material-ui-icons/DeleteForever'

import { stableSort, getSorting, EnhancedTableHead } from './GroupsTable'
import { getDateObj } from '../DataSources'


const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  // { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'percent', numeric: true, disablePadding: false, label: 'Overall %'},
  { id: 'xp', numeric: true, disablePadding: false, label: 'XP' },
  { id: 'day', numeric: false, disablePadding: false, label: 'Last Active' },
  { id: 'remove', numeric: false, disablePadding: false, label: '' }, // TODO: disable sort on this
]

class OverviewTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'curriculum',
    selected: [],
    page: 0,
    rowsPerPage: 10,
  }

  handleRequestSort = (event, property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc'
    this.setState({ order: isDesc ? 'asc' : 'desc' })
    this.setState({ orderBy: property })
  }

  handleDoubleClick = (event, uid) => {
    this.props.onSelected(uid)
    this.props.onDoubleClick(uid)
  }

  handleClick = (event, uid) => {
    const selectedIndex = this.state.selected.indexOf(uid)
    let newSelected = [uid]

    if (selectedIndex !== -1) {
      newSelected = []
    }
    this.setState({ selected: newSelected })
    this.props.onSelected(uid)
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value })
    this.setState({ page: 0 })
  }

  getRowData = (row, totalCount) => {
    const newRow = row

    if (row.missingRecord) {
      newRow.percent = 0
      newRow.day = 0
      newRow.xp = 0
      newRow.name = 'USER DELETED'
      return newRow
    }

    let userCount = 0
    this.props.projects.forEach((project) => {
      try {
        if (row.module.progress.hasOwnProperty(project.name)) {
          userCount += row.module.progress[project.name].length
        }
      } catch (e) {} // do nothing
    })

    if (!('xp' in row)) {
      newRow.xp = 0
    }

    const day = getDateObj(row, 'lastActivity')
    newRow.day = day.getTime()
    newRow.percent = parseInt(((userCount * 100) / totalCount).toFixed(0), 10)
    return newRow
  }

  render() {
    const isSelected = uid => this.state.selected.indexOf(uid) !== -1

    let totalCount = 0
    this.props.projects.forEach((project) => { 
      totalCount += project.component.length
    })

    const fullRows = this.props.rowData.map(row => this.getRowData(row, totalCount))

    return (
      <div style={{ width: '100%', paddingTop: '4px', display: 'flex', flexDirection: 'column', minHeight: '0' }}>
        <div style={{ overflow: 'auto' }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
              headerCells={headCells}
            />
            <TableBody>
              {stableSort(fullRows, getSorting(this.state.order, this.state.orderBy))
                  .slice(this.state.page * this.state.rowsPerPage, (this.state.page * this.state.rowsPerPage) + this.state.rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.uid)
                    const labelId = `enhanced-table-checkbox-${index}`
                    const pct = row.percent.toString()
                    const day = new Date(row.day)

                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, row.uid)}
                      onDoubleClick={event => this.handleDoubleClick(event, row.uid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uid}
                      selected={isItemSelected}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" id={labelId} scope="row" >{row.name}</TableCell>
                      {
                        // <TableCell align="left">{row.email}</TableCell>
                      }
                      <TableCell >{`${pct}%`}</TableCell>
                      <TableCell align="left">{row.xp}</TableCell>
                      <TableCell align="left">{day.toLocaleDateString('en-US')}</TableCell>
                      <TableCell>
                        <Tooltip placement="left-end" title={`Remove ${row.name}`}>
                          <div>
                            <IconButton
                              style={{ height: '24px' }}
                              onClick={() => { this.props.onRemove(row.uid) }}
                              disabled={this.props.uiDisabled}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={this.props.rowData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          backIconButtonProps={{ 'aria-label': 'previous page' }}
          nextIconButtonProps={{ 'aria-label': 'next page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    )
  }
}

export default OverviewTable
