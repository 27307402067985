import StartCodingModule, { projects as startCodingProjects } from './StartCoding/StartCodingModule'
import FreeStarterModule from './StartCoding/FreeStarterModule'
// import FreePythonWithRobotsModule from './PythonWithRobots/FreePWRmodule'
// import PythonWithRobots, { projects as pythonWithRobotsProjects } from './PythonWithRobots/PythonWithRobotsModule'
import LiftOffMicrobit, { projects as liftOffPeripheralsProjects } from './LiftOffPeripherals/LiftOffPeripheralsModule'

import MicrobitIcon from './assets/MicrobitIcon.png'
// import CodeBotIcon from './assets/Cb2Icon-128.png'
import PeripheralsIcon from './assets/OctopusBit.png'

// Top-level React components for each Curriculum Module
export const moduleList = {
  StartCodingModule,
  FreeStarterModule,
  // FreePythonWithRobotsModule,
  // PythonWithRobots,
  LiftOffMicrobit,
}

// Module names to index above 'moduleList' object; must match the License "product" field
export const moduleEnums = {
  0: 'FreeStarterModule', // Free JumpStart
  1: 'StartCodingModule', // Full JumpStart (aka "Codespace" in actual license field)
  // 2: 'FreePythonWithRobotsModule',
  // 3: 'PythonWithRobots',
  4: 'LiftOffMicrobit', // micro:bit Peripherals Curriculum
}

// Allow lookup of moduleEnum index by name
export const moduleIndex = new Map(Object.entries(moduleEnums).map(entry => entry.reverse()))

// Expose project list of each Module. Used by Google Classroom integration.
export const moduleProjects = {
  1: startCodingProjects,
  // 3: pythonWithRobotsProjects,
  4: liftOffPeripheralsProjects,
}

export const moduleDescription = {
  0: {
    name: 'Free Trial micro:bit',
    icon: MicrobitIcon,
    description: 'A free sample of the JumpStart Python curriculum for micro:bit',
    url: 'https://firialabs.com/collections/software',
  },
  1: {
    name: 'Jumpstart Python',
    icon: MicrobitIcon,
    description: `Learn Python while building lots of awesome projects with your micro:bit. This curriculum module will
                  lead you every step of the way, from your first lines of Python code to writing programs that measure sensors
                  and communicate wirelessly to remote devices!`,
    url: 'https://firialabs.com/collections/software',
  },
  // 2: {
  //   name: 'Free Trial CodeBot',
  //   icon: CodeBotIcon,
  //   description: 'Free sample of the Python with Robots curriculum.',
  //   url: 'https://firialabs.com/collections/python-robotics',
  // },
  // 3: {
  //   name: 'Python with Robots',
  //   icon: CodeBotIcon,
  //   description: `Learn Python from a Robot! Learn about all the amazing capabilities of the CodeBot CB2 rover by writing
  //                 code to bring it to life. This complete set of projects will take you from zero to Python-robotics hero!`,
  //   url: 'https://firialabs.com/collections/python-robotics',
  // },
  4: {
    name: 'Lift-Off Peripherals micro:bit',
    icon: PeripheralsIcon,
    description: `Learn to incorporate peripherals into your micro:bit projects. This curriculum module will guide you  
                  through projects and demonstrate utilizing external LEDs, buttons, sensors, and servos. You will explore 
                  multiple peripherals while the curriculum takes you on an exciting journey into outer space!`,
    url: 'https://firialabs.com/collections/mission-packs',
  },
}

export const defaultModule = 'FreeStarterModule'
