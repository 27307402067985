import React, { Component } from 'react'

import Markdown from '../cbl-remarkable'

import { LegendMessagePeripheral } from '../Legends'
import peripheralsData from './PeripheralsData'

import OctoHeaderImg from './assets/octo_header.jpg'
import OctoCableImg from './assets/cable_sml.jpg'

const fLeftLocs = [9, 26, 42, 59, 75, 92, 108, 125, 164, 181, 142, 198, 215, 231, 248, 264, 281]

class OctoHeader extends Component {
  render() {
    const covColor = this.props.coverHexColor ? this.props.coverHexColor : "#000000"
    const highColor = this.props.highlightHexColor ? this.props.highlightHexColor : "#66FF00"
    const altText = this.props.imageAltText ? this.props.imageAltText : "Octopus Bit Header"
    return (
      <div style={{ position:"relative" }}>
        <img src={ OctoHeaderImg } alt={ altText } />
        {
          this.props.covered ? this.props.covered.map((pos, index) => {
            const textFromLeft = (fLeftLocs[pos] + 3).toString() + 'px'
            return (
              <div
                key={index}
                style={{
                  position:"absolute",
                  top:"24px",
                  left:textFromLeft,
                  width:"15px",
                  height:"52px",
                  border:"1px solid " + covColor,
                  backgroundColor:covColor,
                }}
              />
            )
          }) : null
        }
        {
          this.props.highlighted ? this.props.highlighted.map((pos, index) => {
            const textFromLeft = (fLeftLocs[pos] - 2).toString() + 'px'
            return (
              <div
                key={index}
                style={{
                  position:"absolute",
                  top:"-4px",
                  left:textFromLeft,
                  width:"16px",
                  height:"74px",
                  border:"6px solid " + highColor,
                  backgroundColor:"transparent",
                }}
              />
            )
          }) : null
        }
      </div>
    )
  }
}

export class LegendOctoHeader extends Component {
  render() {
    return (
      <LegendMessagePeripheral>
        <Markdown>
        {this.props.markdown}
        <div style={{ float: "left", width: "160px" }} >
          <img src={ peripheralsData[this.props.peripheral].image } alt={ peripheralsData[this.props.peripheral].title } style={{ width: "160px"}} />
        </div>
        { this.props.hideWire ? null : 
        <div style={{ float: "left", width: "190px", marginTop: "38px" }} >
          <img src={ OctoCableImg } alt="octopus bit cable" />
        </div>
        }
        <div style={{ float: "left", width: "350px", marginTop: "33px" }} >
          <OctoHeader highlighted={[this.props.pin]} imageAltText={ "octopus bit pin " + this.props.pin.toString() } />
        </div>
        </Markdown>
      </LegendMessagePeripheral>
    )
  }
}

export default OctoHeader