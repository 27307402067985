import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles'
import Snackbar from 'material-ui/Snackbar'
import Button from 'material-ui/Button'
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog'
import Popover from 'material-ui/Popover'
import purple from 'material-ui/colors/purple'
import green from 'material-ui/colors/green'
import red from 'material-ui/colors/red'
import SplitPane from 'react-split-pane'
import logo from './assets/bot.min.svg'
import CodeFileFolder, { defaultFilename } from './CodeFileFolder'
import MenuBar from './MenuBar'
import ToolBar from './ToolBar'
import UserSession from './UserSession'
import CodeEditor, { editorInst } from './CodeEditor'
import ConsoleRepl from './ConsoleRepl'
import VariablesPane from './VariablesPane'
import serComm from './WebusbSerialBridge'
import debugController from './DebugController'
import { googleApi, REFRESH_WATERMARK, CLASSROOM_SCOPES } from './Gapi'
import LessonPanel from './Lessons'
import { moduleEnums } from './lessons/CurriculumModules'
import syntaxChecker from './SyntaxChecker'
import mb from './mbEnum'
import ErrorBoundary from './ErrorBoundary'
import CopyFileDialog from './CopyFileDialog'
import VersionCheck from './VersionCheck'
import { MessageDialog } from './Dialogs'
import { FirmwareFiles, FirmwareHashes } from './Firmware'
import Debounce from './Debounce'
import UserProgress from './UserProgress'
import { cblFirebase } from './myfirebase'
import { tracker } from './tracking'
import IconWarning from 'material-ui-icons/Warning'
import { LicenseManagerDialog } from './LicenseManager'
import JoinGroupDialog from './dashboard/modals/JoinGroupDialog'
import LicenseDetails from './LicenseDetails'
import GoogleClassroomAssignments from './GoogleClassroomAssignments'
import ProductSelectDialog from './ProductSelect';
import ExpansionPanel, {ExpansionPanelDetails, ExpansionPanelSummary} from 'material-ui/ExpansionPanel'
import multiTabComm from './MultiTabCommunication'
import ExpandMoreIcon from 'material-ui-icons/ExpandMore'
import MicrobitIcon from './lessons/assets/MicrobitIcon.png'

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: {
      ...purple,   // Purple and green play nicely together.
    },
    error: red,
  },
})


class App extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        moduleId: PropTypes.string,
        projectId: PropTypes.string,
        gDriveIds: PropTypes.string,
      }).isRequired,
    }), // 'match' is injected by react-router
  }

  static defaultProps = {
    match: null,
  }

  constructor(props, context) {
    super(props)
    this.state = {
      showRightPanel: true,
      showBottomPanel: false,
      showAlert: false,
      gFileId: null,
      saving: false,
      saved: null,
      gSignedIn: googleApi.gapiAuthIsSignedIn,
      gLoaded: false,
      gDriveAccess: googleApi.hasDriveScope(),
      loadingFile: false,
      filename: defaultFilename,
      newFile: false,
      localVariables: [],
      globalVariables: [],
      mbState: mb.state.UNKNOWN,
      mbMode: mb.mode.UNKNOWN,
      showAdvDebug: false,
      debugging: false,
      downloading: false,
      usbConnected: serComm.isConnected(),
      windowWidth: 0,
      windowHeight: 0,
      online: navigator.onLine,
      enableRedirectNotice: true,
      enableMultipleTabsOpenNotice: false,
      enableOutOfSyncNotice: false,
      enableOutOfDateNotice: false,
      mbOutOfSync: false,
      openEditLockoutDialog: false,
      openTroubleshootingDialog: false,
      openLicenseOptionsDialog: false,
      openLicenseManagerDialog: false,
      openJoinGroupDialog: false,
      openFirmwareDialog: false,
      showErrorDialog: false,
      openLessonErrorDialog: false,
      showCopyFileDialog: false,
      hasSelection: false,
      copyText: '',
      mainPanelSize: null,
      hasUndo: false,
      hasRedo: false,
      codeErrorAnchor: null,
      codeErrorMessage: "",
      codeErrorColor: null,
      codeEndAnchor: null,
      userAttributes: null,
      openIntlKeyboardWarning: false,
      showAddAssignmentsDialog: false,
      assignmentsAdded: false,
      currentNumEditorBreakpoints: 0,
      connectedDeviceMaxBreakpoints: 0,
      showWindowsCompatWarning: false,
      showConfirmEraseDevice: false,
      openFileErrorDialog: false,
    }
    this.showGoogleDriveInstall = false
    this.console = null
    this.debounce = new Debounce().cb
    this.intlKeyWarningShown = false
    this.mbIsStepping = false
    this.lastEditorChanges = []
    this.lastEditorNumLines = 0
    this.overrideErrorMsg = []
    this.lessonDialogTitle = ""
    this.lessonDialogContent = ""
    this.priorUserProgress = null
    this.unlicensedUserPromptedOnce = false
    this.fileErrorDialogContent = ''

    this.uPythonHash = new FirmwareHashes()

    serComm.setRxHandler(this.handleSerialChars)
    serComm.setSignalDeviceConnected(this.handleWebusbDeviceConnected)
    serComm.setSignalDeviceDisconnected(this.handlWebusbDeviceDisconnected)
    // Check if serial port is already available.
    serComm.connectAvailable()

    this.needsSave = false
    this.restored = false
    this.offlineBackoffTimer = 2

    debugController.setLocalVarChangeCallback(this.onDebugLocalVarChange)
    debugController.setGlobalVarChangeCallback(this.onDebugGlobalVarChange)
    debugController.setMbStateChangeCallback(this.onDebugStateChange)
    debugController.setMbModeChangeCallback(this.onDebugModeChange)
    debugController.setStoppedCallback(this.onDebuggerStopChange)
    debugController.setDownloadHandlers(this.handleDownloadStart, this.handleDownloadEnd)
    debugController.setTracebackCallback(this.handleTraceback)
    debugController.setErrorCallback(this.handleDebugError)
    debugController.setDownloadFailedCallback(this.handleDownloadFailed)
    debugController.setOnRunCallback(this.handleOnRun)
    debugController.setOutOfDateCallback(this.openOutDateNotice)
    debugController.setPlatformVersionCallback(this.handlePlatformVersion)
    syntaxChecker.setCodeErrorCallback(this.handleCodeError)

    this.gFilePicker = null
    this.gFilePickerTimeout = null

    multiTabComm.setMultipleTabsOpenCallback(this.handleMultipleTabsOpen)

    if (props.match && props.match.params.moduleId && props.match.params.projectId) {
      UserProgress.onLessonChange(moduleEnums[props.match.params.moduleId], 0, parseInt(props.match.params.projectId, 10) - 1)
    }
  }

  handleMultipleTabsOpen = () => this.setState({enableMultipleTabsOpenNotice: true})

  checkOsVersion = () => {
    // Check for OS incompatibilities, and flag to show warning dialog
    const m = navigator.appVersion.match(/Windows[^\d]*([\d.]+)/)
    const WIN10_VER = 10.0
    if (m && parseFloat(m[1]) < WIN10_VER) {
      this.setState({showWindowsCompatWarning: true})
    }
  }

  onDebugLocalVarChange = (localVars) => {
    this.setState({
      localVariables: localVars
    })
  }

  onDebugGlobalVarChange = (vars) => {
    if (!this.state.showAdvDebug && syntaxChecker.parseResults) {
      // Filter globals not referenced in parsed code
      const refNames = syntaxChecker.parseResults.names
      vars = vars.filter(v => refNames.includes(v.name))

      // Filter meta-values
      vars = vars.filter(v => v.val.length === 0 || v.val[0] !== '<')
    }

    this.setState({
      globalVariables: vars
    })
  }

  onDebugStateChange = (state) => {
    // console.log(`APP state from [ ${this.state.mbState} ${mb.getStateName(this.state.mbState)} ] to [ ${state} ${mb.getStateName(state)} ]`)
    if (state === this.state.mbState) {
      return
    }

    this.setState({
      mbState: state
    })

    this.mbIsStepping = (this.state.mbMode === mb.mode.DEBUG && this.state.mbState === mb.state.IDLE)

    if (this.state.mbState === mb.state.IDLE || this.state.mbState === mb.state.UNKNOWN) {
      this.setState({
        mbOutOfSync: false,
        enableOutOfSyncNotice: true
      })
    }
  }

  onDebugModeChange = (mode) => {
    // console.log(`APP mode from [ ${this.state.mbMode} ${mb.getModeName(this.state.mbMode)} ] to [ ${mode} ${mb.getModeName(mode)} ]`)

    if (mode === mb.mode.REPL &&    // returning to REPL
        (this.state.mbMode === mb.mode.DEBUG ||   // Normal case: from debug
         this.state.mbMode === mb.mode.REPL)      // Short script: from REPL
       )
    {
      this.handleRunComplete()
    }

    // If mode unchanged, avoid unnecessary setState()
    if (mode === this.state.mbMode) {
      return
    }

    this.mbIsStepping = (mode === mb.mode.DEBUG && this.state.mbState === mb.state.IDLE)

    this.setState({
      mbMode: mode
    })
  }

  onDebuggerStopChange = (isStopped) => {
    this.setState({
      debugging: !isStopped
    })
  }

  saveGFileState = (gFileId, filename) => {
    debugController.updateFilename(filename)
    if (cblFirebase.userDoc) {
      cblFirebase.userDoc.ref.set({
        prevGFileId: gFileId,
        prevFilename: filename,
      }, { merge: true })
    }
  }

  onDriveSave = (response) => {
    this.setState({
      gFileId: response.result.id,
      saving: false,
      saved: this.needsSave ? this.state.saved : new Date()
    })
    this.saveGFileState(response.result.id,  this.state.filename)
    this.resetErrorTimer()
  }

  onDriveError = (err) => {
    console.log("Drive error:", err)
    const error = err.result.error
    if (error.code === 401) {
      if (!googleApi.getCurrentUser()) {
        // This can happen when the user signs out while we are in the middle of saving a file
        return tracker.addBreadcrumb('Google API reported there was no current user', { data: err })
      }
      // Handle "Invalid Credentials" due to token expiration (e.g. sleeping laptop wakes up with old token)
      // Suggested action: Refresh the access token using the long-lived refresh token.
      // If this fails, direct the user through the OAuth flow, as described in Authorizing Your App with Google Drive.
      googleApi.getCurrentUser().reloadAuthResponse() // Forces a refresh of the access token, returns promise with reloaded AuthResponse
        .then((authResponse) => {
          this.needsSave = true
          this.setState({
            saving: false,
            saved: false,
          })
        })
        .catch((authErr) => {
          tracker.addBreadcrumb('Error on Drive reloadAuthResponse', { data: authErr })
        })
    } else if (error.code === -1) {
      // Handle "network error" due to various connection problems
      // Let's wait and retry after a timeout period
      this.needsSave = true
      this.setState({
        saving: false,
        saved: false,
      })
      this.notifyDriveError()
    } else {
      // TODO: Handle other errors explicitly. The following just causes constant retries.
      this.needsSave = true
      this.setState({
        saving: false,
        saved: false,
      })
      tracker.addBreadcrumb('onDriveError', { data: err })
    }
  }

  notifyDriveError = () => {
    this.disableConnection()
    this.debounce(this.enableConnection, this.offlineBackoffTimer * 1000)
  }

  resetErrorTimer = () => {
    this.offlineBackoffTimer = 2
  }

  enableConnection = () => {
    this.offlineBackoffTimer **= 2
    this.setState({
      online: true,
      showAlert: false,
    })
  }

  disableConnection = () => {
    this.setState({
      online: false,
      showAlert: true,
    })
  }

  save = () => {
    this.debounce(this.deferSave, 1000)
  }

  deferSave = () => {
    // console.log('SAVING:', this.needsSave, this.state.saving)

    this.setState({
      saving: true
    })

    // TODO: We need to support exponential backoff https://developers.google.com/drive/v3/web/handle-errors#implementing_exponential_backoff
    if (this.state.gFileId) {
      googleApi.driveSave(this.state.gFileId, editorInst.getValue())
        .then(this.onDriveSave)
        .catch((err) => {
          this.onDriveError(err)
        })
    } else {
      googleApi.driveSaveAs(defaultFilename, editorInst.getValue())
        .then(this.onDriveSave)
        .catch((err) => {
          this.onDriveError(err)
        })
    }
  }

  handleGoogleSignedInNotify = (signedIn) => {
    this.setState({
      gSignedIn: signedIn,
      gDriveAccess: googleApi.hasDriveScope(),
    })
  }

  componentDidMount = () => {
    UserProgress.registerOnChangeCallback(this.handleUserProgressChange)
    UserProgress.registerOnLessonChange(this.handleOnLessonChange)

    if (cblFirebase.userDoc) {
      this.restore()
    } else {
      cblFirebase.registerDocRetrievedCallback(() => {
        if (!this.restored) this.restore()
      })
    }

    googleApi.notifyOnAuthChange(this.handleGoogleSignedInNotify)
    googleApi.gapiLoadedPromise.then(() => {
      this.setState({ gLoaded: true })
    })

    window.addEventListener('offline', () => {
      this.setState({
        online: false,
        showAlert: true,
      })
    })
    window.addEventListener('online', () => {
      this.setState({
        online: true,
        showAlert: false,
      })
    })
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
    this.setState({ mainPanelSize: window.innerWidth / 2 })

    if (UserProgress.newAccount) {
      this.handleUserProgressChange(null, true)
    }

    this.checkOsVersion()

    setTimeout(this.closeRedirectNotice, 30000)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.online && this.state.gSignedIn && this.state.gDriveAccess && this.state.showAlert) {
      this.setState({
        showAlert: false,
      })
    }

    if (this.state.online && this.state.gSignedIn && this.state.gDriveAccess && this.needsSave && !this.state.saving) {
      this.needsSave = false
      this.save()
    } else if (!prevState.newFile && this.state.newFile) {
      // Finish new file
      editorInst.setValue('', 1)
      editorInst.session.clearBreakpoints()
      editorInst.focus()
      this.setState({
        gFileId: null,
        loadingFile: false,
        newFile: false,
        saved: null,
        filename: defaultFilename,
      })
    }

    // Remove 'position' applied by react-split-pane, since it breaks the ability of react-joyride
    // to properly position overlays relative to first positioned ancestor.
    const elt = document.getElementsByClassName("Pane vertical Pane2")
    for (let e of elt) {
      e.style.removeProperty('position')
    }

    // Update editor if panel visibility changes (also must be hooked to split-pane resize events)
    this.updateEditorPanel()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
    googleApi.unregisterNotifyOnAuthChange(this.handleGoogleSignedInNotify)
    UserProgress.unregisterOnChangeCallback(this.handleUserProgressChange)
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  handleLessonPanelToggle = (event) => {
    this.setState({showRightPanel: !this.state.showRightPanel})
  }

  handleDebugPanelToggle = (event) => {
    this.setState({showBottomPanel: !this.state.showBottomPanel})
  }

  handleSyntaxCheck = () => {
    syntaxChecker.check()
  }

  updateEditorPanel = (event) => {
    editorInst.resize()
  }

  handleSerialChars = (text) => {
    //this.console.appendChars(text)
    debugController.handleSerialChars(text)
  }

  handleConsoleChars = (charCode) => {
    //console.log("ASCII: ", charCode)
    const buf = typeof(charCode) === 'number' ? String.fromCharCode(charCode) : String.fromCharCode(...charCode)
    serComm.send(buf)
  }

  handleWebusbDeviceConnected = () => {
    //console.log("UI: device attached!")
    this.setState({usbConnected: true})
    debugController.handleDeviceConnect()
  }

  handlWebusbDeviceDisconnected = () => {
    // console.log("UI: device lost!")
    this.setState({usbConnected: false})
    debugController.handleDeviceDisconnect()
  }

  getConsoleRef = (inst) => {
    this.console = inst
    debugController.registerConsole(inst)
  }

  handleEditorChangeBreakpoint = () => {
    const breakpoints = editorInst.session.$breakpoints
    const numEditorBreakpoints = breakpoints.filter(Boolean).length
    if (numEditorBreakpoints !== this.state.currentNumEditorBreakpoints) {
      this.setState({currentNumEditorBreakpoints: numEditorBreakpoints})
    }
  }

  handleEditorChange = (newValue, e) => {
    syntaxChecker.clearEditorMarkers()
    const newNumLines = editorInst.session.getLength()

    // Do the edits affect breakpoints? Ace doesn't handle this for us...
    // Adjust the breakpoint list based on lines added/deleted.
    if (editorInst.session.$breakpoints && e.start.row < e.end.row) {
      if (newNumLines !== this.lastEditorNumLines) {
        const delta = newNumLines - this.lastEditorNumLines
        if (delta > 0) {
          // Insert empty bkpt entries corresponding to new lines
          const empties = Array.apply(null, Array(delta))
          // Determine starting row of new breakpoint lines. If changed content, start on next line.
          const splitPrefix = editorInst.session.getLine(e.start.row).slice(0, e.start.column).trim()
          const startRow = e.start.row + (splitPrefix ? 1 : 0)
          editorInst.session.$breakpoints.splice(startRow, 0, empties)
        } else {
          // Remove bkpt entries corresponding to removed lines
          // Determine starting row of new breakpoint lines. If no content change, start on next line.
          const splitSuffix = editorInst.session.getLine(e.start.row).slice(e.start.column).trim()
          const startRow = e.start.row + (splitSuffix ? 0 : 1)
          editorInst.session.$breakpoints.splice(startRow, -delta)
        }
        // Notify Ace and observers of potential changes.
        editorInst.session._signal("changeBreakpoint", {})
      }
    }

    // Warn on EditorChange during Debug, except when Edit is due to fresh 'page reload'.
    if (this.state.usbConnected && this.mbIsStepping && this.lastEditorNumLines) {
      this.lastEditorChanges.push(e)  // Changes may come in groups, so accumulate.
      this.setState({
        openEditLockoutDialog: true,
        editLockoutUndo: true,
      })
    }

    this.lastEditorNumLines = newNumLines
    this.debounce(this.deferHandleEditorChange, 500, newValue, e)
  }

  handleDownloadFailed = () => {
    this.handleDebugError(
      <div
        onClick={() => {
          this.handleTroubleDialog()
          this.handleCodeErrorClose()
        }}
        style={{cursor: 'pointer'}}
      >
        <b style={{color:"red"}}>Error</b><br />
        Loading code failed.<br />
        <u>Click here</u> for help.
      </div>
    )
  }

  handleOnRun = () => {
    this.mbIsStepping = false
  }

  handleTroubleDialog = () => {
    this.setState({
      openTroubleshootingDialog: true
    })
  }

  handleProductSelectDialog = () => {
    this.setState({
      openLicenseOptionsDialog: true,
    })
  }

  handleLicenseManagerDialog = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        openLicenseManagerDialog: true
      })
      this.resolveLicenseManagerDialog = resolve
    })
  }

  handleJoinGroupDialog = () => {
    this.setState({ openJoinGroupDialog: true })
  }

  handleAddAssignmentsDialog = () => {
    if (!googleApi.getCurrentUser().hasGrantedScopes(CLASSROOM_SCOPES)) {
      googleApi.getCurrentUser().grant({ scope: CLASSROOM_SCOPES }).then(() => {
        this.setState({
          showAddAssignmentsDialog: true,
        })
      }, (err) => {
        return console.error(err)
      })
    } else {
      this.setState({
        showAddAssignmentsDialog: true,
      })
    }
  }

  handleFirmwareDialog = () => {
    this.setState({
      openFirmwareDialog: true
    })
  }

  closeTroubleDialog = () => {
    this.setState({
      openTroubleshootingDialog: false
    })
  }

  closeLicenseOptionsDialog = (openLicenseMangerNext) => {
    this.setState({
      openLicenseOptionsDialog: false,
      openLicenseManagerDialog: openLicenseMangerNext
    })
  }

  closeLicenseManagerDialog = () => {
    this.setState({
      openLicenseManagerDialog: false
    })
    if (this.resolveLicenseManagerDialog) {
      this.resolveLicenseManagerDialog()
    }
  }

  closeJoinGroupDialog = () => {
    this.setState({ openJoinGroupDialog: false })
  }

  deferHandleEditorChange = (newValue, e) => {
    if (this.state.usbConnected && this.state.mbState !== mb.state.IDLE) {
      this.setState({
        mbOutOfSync: true
      })
    }

    const undoMgr = editorInst.getSession().getUndoManager()
    this.setState({
      hasUndo: undoMgr.hasUndo(),
      hasRedo: undoMgr.hasRedo(),
    })

    if (this.state.loadingFile || this.state.newFile) {
      return
    } else if (!this.state.online) {
      // We check needsSave in componentDidUpdate which gets called when state.online gets updated
      this.needsSave = true
      this.setState({
        saved: false
      })
      return
    } else if (!this.state.gSignedIn || !this.state.gDriveAccess) {
      this.needsSave = true
      this.setState({
        showAlert: true,
        saved: false,
      })
      return
    } else if (this.state.saving) {
      this.needsSave = true
      return
    }

    this.save()
  }

  handleEditorChangeSelection = (selection) => {
    this.debounce(this.deferHandleEditorChangeSelection, 500, selection)
  }

  deferHandleEditorChangeSelection = (selection) => {
      // console.log('handleEditorChangeSelection', selection.isEmpty())
    this.setState({ hasSelection: !selection.isEmpty() })
  }

  closeEditorLockoutDialogStopDebug = () => {
    debugController.stop()
    this.setState({
      openEditLockoutDialog: false,
      editLockoutUndo: false
    })
  }

  closeEditorLockoutDialogKeepDebug = () => {
    if (this.state.editLockoutUndo) {
      if (this.lastEditorChanges) {
        // Operate on copy, since change handler will fire while reverting.
        const deltas = Array.from(this.lastEditorChanges)
        while (deltas.length > 0) {
          const delta = deltas.pop()
          editorInst.getSession().doc.revertDelta(delta)
        }
        this.lastEditorChanges = []
      } else {
        editorInst.undo()
      }
    }
    this.setState({
      openEditLockoutDialog: false,
      editLockoutUndo: false,
    })
  }

  closeRedirectNotice = () => {
    this.setState({
      enableRedirectNotice: false
    })
  }

  closeOutOfSyncNotice = () => {
    this.setState({
      enableOutOfSyncNotice: false
    })
  }

  openOutDateNotice = () => {
    this.setState({
      enableOutOfDateNotice: true
    })
  }

  closeOutDateNotice = () => {
    this.setState({
      enableOutOfDateNotice: false
    })
  }

  handleDownloadStart = () => {
    this.setState({downloading: true})
  }

  handleDownloadEnd = () => {
    this.setState({downloading: false})
  }

  handlePlatformVersion = () => {
    // Received platform and version info
    this.setState({connectedDeviceMaxBreakpoints: debugController.deviceNumBreakpoints})
  }

  displayInterceptError = (msg) => {
    const RegExIDX = 0
    const messageContentIDX = 1
    let errorArraySize = this.overrideErrorMsg.length

    for (let i = 0; i < errorArraySize; i++) {
      let re = this.overrideErrorMsg[i][RegExIDX]
      const matches = re.exec(msg)
      if (matches) {
        this.lessonDialogContent = this.overrideErrorMsg[i][messageContentIDX]
        this.setState({openLessonErrorDialog: true})
        break
      }
    }
  }

  interceptErrorCb = (msgArray) => {
    const dialogTitleIDX = 2
    this.lessonDialogTitle = msgArray[dialogTitleIDX]
    this.overrideErrorMsg.push(msgArray)
  }

  clearLessonDialogs = () => {
    this.lessonDialogTitle = ""
    this.lessonDialogContent = ""
    this.overrideErrorMsg = []
  }

  handleTraceback = (traceback) => {
    const session = editorInst.getSession()
    const ann = session.getAnnotations()
    ann.push({
      row: traceback.line - 1,
      text: traceback.msg,
      type: 'warning',
    })
    session.setAnnotations(ann)

    syntaxChecker.addLineMarker(traceback.line)

    this.handleCodeError('Runtime', traceback.line, traceback.msg)
  }

  handleDebugError = (message) => {
    this.genErrorContent(message)
  }

  handleFilenameChange = async (filename) => {
    try {
      this.setState({
        saving: true,
        filename: filename
      })
      let response  
      if (this.state.gFileId) {
        response = await googleApi.driveRename(this.state.gFileId, filename)
      } else {
        response = await googleApi.driveSaveAs(filename, editorInst.getValue())
      }
      this.onDriveSave(response)
    }
    catch(err) {
      this.onDriveError(err)
    }
  }

  loadFromGDrive = async (gFileId, filename) => {
    // console.log("loadFromGDrive")
    this.setState({ loadingFile: true })

    let gFileResource = null
    try {
      gFileResource = await googleApi.driveGetMetadata(gFileId, 'id,name,isAppAuthorized,mimeType')
    } catch (err) {
      this.fileErrorDialogContent = 'We were unable to open the selected file. '
      if (!googleApi.hasDriveFullScope()) {
        this.fileErrorDialogContent += 'Please update your Google Drive permissions from the File menu'
      } else {
        this.fileErrorDialogContent += 'The file may no longer exist'
      }
      this.setState({
        openFileErrorDialog: true,
      })
      return
    }
    if (gFileResource.status === 200) {
      filename = gFileResource.result.name
      if (gFileResource.result.mimeType !== 'text/plain') {
        this.fileErrorDialogContent = `The file "${filename}" could not be opened since the type is unsupported`
        this.setState({
          openFileErrorDialog: true,
        })
        return
      }
    } else {
      this.fileErrorDialogContent = 'We were unable to open the selected file'
      this.setState({
        openFileErrorDialog: true,
      })
      return
    }

    googleApi.driveLoad(gFileId).then((gFileResource) => {
      // console.log(gFileResource)
      editorInst.setValue(gFileResource.body, 1)
      editorInst.session.clearBreakpoints()
      setTimeout(() => editorInst.getSession().getUndoManager().reset(), 0) // ACE delays adding the entry into the undo table
      editorInst.focus()
      this.setState({
        gFileId,
        loadingFile: false,
        filename,
        saved: new Date(),
      })
      this.saveGFileState(gFileId, filename)
    }, (error) => {
      this.setState({ loadingFile: false })
      tracker.addBreadcrumb('loadFromGDrive error', { data: error })
    })
  }

  handleFilePicked = (gDriveDocResp) => {
    this.loadFromGDrive(gDriveDocResp.id, gDriveDocResp.name)
  }

  handleNewFile = () => {
    if (this.mbIsStepping) {
      this.setState({
        openEditLockoutDialog: true,
        editLockoutUndo: false
      })
    } else {
      this.setState({ newFile: true })
    }
  }

  openFilePicker = () => {
    this.gFilePicker = googleApi.getCBLFilePicker(this.pickerCallback)
    this.gFilePicker.setVisible(true)
    // If we don't get a loaded event than we need to dispose of the file picker
    this.gFilePickerTimeout = setTimeout(() => this.gFilePicker.dispose(), 5000)
  }

  handleOpenFile = () => {
    if (this.mbIsStepping) {
      this.setState({
        openEditLockoutDialog: true,
        editLockoutUndo: false
      })
      return
    }

    if (!googleApi.hasDriveScope()) {
      return googleApi.requestDriveScope(this.handleOpenFile)
    }

    // hasDriveScope checked that we have a current user
    if (Date.now() > (googleApi.getCurrentUser().getAuthResponse().expires_at - (REFRESH_WATERMARK * 1000))) {
      // Forces a refresh of the access token
      googleApi.getCurrentUser().reloadAuthResponse().then(this.openFilePicker)
    } else {
      this.openFilePicker()
    }
  }

  handleWebusbConnect = () => {
    serComm.request()
    // console.log("Trying to connect to webusb...")
  }

  pickerCallback = (response) => {
    // console.log("pickerCallback", response)

    if (response.action === 'picked') {
      this.handleFilePicked(response.docs[0])
      this.gFilePicker.dispose()
    } else if (response.action === 'cancel') {
      this.gFilePicker.dispose()
    } else if (response.action === 'loaded') {
      clearTimeout(this.gFilePickerTimeout)
    }
  }

  handleFolderPicked = (folderId) => {
    googleApi.driveMove(this.state.gFileId, folderId)
  }

  handleMoveFile = () => {
    if (!googleApi.hasDriveScope()) {
      return googleApi.requestDriveScope(this.handleMoveFile)
      // hasDriveScope checked that we have a current user
    }

    if (this.mbIsStepping) {
      this.setState({
        openEditLockoutDialog: true,
        editLockoutUndo: false
      })
    } else {
      if (Date.now() > (googleApi.getCurrentUser().getAuthResponse().expires_at - (REFRESH_WATERMARK * 1000))) {
        // Forces a refresh of the access token
        googleApi.getCurrentUser().reloadAuthResponse().then(this.openFolderPicker)
      } else {
        this.openFolderPicker()
      }
    }
  }

  openFolderPicker = () => {
    googleApi.getFolderPicker((response) => {
      if (response.action === 'picked') {
        this.handleFolderPicked(response.docs[0].id)
      }
    }).setVisible(true)
  }

  handleCopyFile = () => {
    if (!googleApi.hasDriveScope()) {
      return googleApi.requestDriveScope(this.handleMoveFile)
    }

    if (this.mbIsStepping) {
      this.setState({
        openEditLockoutDialog: true,
        editLockoutUndo: false
      })
    } else {
      this.setState({ showCopyFileDialog: true })
    }

    return null
  }

  handleCopyDialogClose = (result) => {
    this.setState({ showCopyFileDialog: false })
    if (result) {
      this.loadFromGDrive(result.id, result.name)
    }
  }

  handleCopyText = () => {
    this.setState({ copyText: editorInst.getSelectedText() })
  }

  handleEraseDevice = () => {
    this.setState({showConfirmEraseDevice: true})
  }

  getConnectionText = () => {
    if (this.needsSave) {
      if (!this.state.online) {
        return "Internet connection lost and there are unsaved changes. Trying to connect..."
      } else if (!this.state.gSignedIn) {
        return "There are unsaved changes. Please Sign in with Google to save"
      }
      return "There are unsaved changes. Click here to enable saving on Google Drive."
    }
    return "Internet connection lost. Trying to connect..."
  }

  genErrorContent = (jsx) => {
    const anchor = document.getElementsByClassName("ace_content")
    if (anchor.length > 0) {
      this.setState({codeErrorAnchor : anchor[0], codeErrorMessage : jsx, codeErrorColor: '#ffff99'})
    }
  }

  handleCodeError = (errType, line, msg) => {
    this.displayInterceptError(msg)

    let txt = `<div><b style="color:red">`
    txt += errType ? `${errType} Error` : `Error`
    txt += line ? ` - Line ${line}</b><br />${msg.replace(`\n`,`<br />`)}</div>` : `</b><br />${msg.replace(`\n`,`<br />`)}</div>`

    msg = <div dangerouslySetInnerHTML={{__html: txt}}></div>
    this.genErrorContent(msg)

    // Scroll error into view
    if (line) {
      line = Math.max(line - 5, 0)
      editorInst.renderer.scrollToRow(line)
    }
  }

  handleCodeErrorClose = () => {
    this.setState({codeErrorAnchor : null})
  }

  handleRunComplete = () => {
    const anchor = document.getElementsByClassName("ace_content")
    if (anchor.length > 0) {
      setTimeout(this.handleCodeEndClose, 2000)   // Auto-close program end popover
      this.setState({codeEndAnchor : anchor[0]})
    }
  }

  handleCodeEndClose = () => {
    this.setState({codeEndAnchor : null})
  }

  handleOnLessonChange = (module, lesson, project) => {
    this.clearLessonDialogs()
  }

  handleUserProgressChange = (userProgressObj, isUserData) => {
    if (isUserData) {
      const userAttrElement = (
        <div
          id='user-attr'
          style={{
            marginTop: 10,
            borderRadius: 4,
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '13px',
            animationName: 'emphasize-animation-flash',
            animationDuration: '1s',
            animationIterationCount: '1',
          }}
        >
          {`XP: ${userProgressObj ? userProgressObj.xp : 0}`}
        </div>
      )
      this.setState({userAttributes: userAttrElement})

      // Show ProductSelect dialog automatically for unlicensed users on load/reload
      if (!this.unlicensedUserPromptedOnce && userProgressObj.lastUsedActivationCode === '' && userProgressObj.tourHasRun) {
        this.setState({openLicenseOptionsDialog: true})
        this.unlicensedUserPromptedOnce = true
      }

      // Restart animation to "emphasize" user stats each change,
      // IF it's a notable change, such as XP
      if (this.priorUserProgress && this.priorUserProgress.xp !== userProgressObj.xp) {
        const id = document.getElementById('user-attr')
        if (id) {
          id.style.removeProperty('animation-name')
          setTimeout(() => id.style.setProperty('animation-name', 'emphasize-animation-flash'), 50)
        }
      }

      // Retain shallow copy of userProgress
      this.priorUserProgress = {...userProgressObj}
    }


    if (userProgressObj) {
      // console.log(userProgressObj.lastModule)
      const progress = userProgressObj.getModuleData(userProgressObj.lastModule)
      // console.log(progress)

      if (Object.entries(progress).length !== 0 &&
          !googleApi.hasDriveFullScope() &&
          cblFirebase.userDoc.data().showGoogleDriveInstall !== false &&
          (userProgressObj.lastModule !== 'FreeStarterModule' ||
           progress.lastLesson !== 0 || progress.lastProject !== 0)) {

        // TODO: Enable this AFTER google has completed "API OAuth Dev Verification" for CodeSpace
        //       ** Submitted request on 10/30/2019 **
        // Uncomment the following line to enable "toast" to prompt users for Drive integration.
        // this.showGoogleDriveInstall = true

      }
    }
  }

  handleDeadKeyPress = () => {
    if (!this.intlKeyWarningShown) {
      this.setState({ openIntlKeyboardWarning: true })
      this.intlKeyWarningShown = true
      if (cblFirebase.userDoc) cblFirebase.userDoc.ref.set({ intlKeyWarningShown: true }, { merge: true })
    }
  }

  handleUsbConnectRequest = () => {
    serComm.request()
  }

  restore = () => {
    this.restored = true
    if (cblFirebase.userDoc.exists) {
      const data = cblFirebase.userDoc.data()
      if (this.props.match && this.props.match.params.gDriveIds) {
        this.loadFromGDrive(this.props.match.params.gDriveIds, null)
      } else if (data.prevGFileId && data.prevFilename) {
        this.loadFromGDrive(data.prevGFileId, data.prevFilename)
      }
      this.intlKeyWarningShown = data.intlKeyWarningShown || false
    }
  }

  // Keep connection alert out of the way of mouse
  handleConnectionAlertHover = (ev) => {
    if (this.bumpConnectionAlert) {
      document.getElementById('net-connection-alert').style.transform='translate(0px,50px)'
      this.bumpConnectionAlert = false
    } else {
      document.getElementById('net-connection-alert').style.transform='translate(0px,0px)'
      this.bumpConnectionAlert = true
    }
  }

  render() {
    return (
      /* App */
      <LicenseDetails render={({ isActivated, currentModule, initialLoadComplete }) => (
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
          <div
            style={{display: 'flex', flexDirection: 'column', height: '100vh', padding: 0, backgroundColor: 'lightgray'}}>

            <VersionCheck />

            {/* Code complete popover */}
            <Popover
              id='code-complete-popover'
              open={Boolean(this.state.codeEndAnchor)}
              anchorEl={this.state.codeEndAnchor}
              onClose={this.handleCodeEndClose}
              disableAutoFocus={true}
              disableEnforceFocus={true}
              transitionDuration={{enter:100, exit:500}}
              PaperProps={{
                style: {
                  backgroundColor:'beige',
                  borderStyle:'solid',
                  borderColor:'black',
                  borderRadius: 8,
                  padding: 5,
                }
              }}
              style={{
                marginTop: 24,
              }}
              anchorOrigin={{     // Attach point on anchor
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{  // Attach point on popup
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <b style={{margin: '2em'}}>Program Ended</b>
            </Popover>

            {/* Code error popover */}
            <Popover
              open={Boolean(this.state.codeErrorAnchor)}
              anchorEl={this.state.codeErrorAnchor}
              onClose={this.handleCodeErrorClose}
              PaperProps={{
                style: {
                  backgroundColor: this.state.codeErrorColor ? this.state.codeErrorColor : '#ffff99',
                  padding: 5,
                }
              }}
              style={{
                marginTop: 64,
              }}
              anchorOrigin={{     // Attach point on anchor
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{  // Attach point on popup
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {this.state.codeErrorMessage}
            </Popover>

            {/* Notify user of another instance of Codespace in separate tab */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              style={{textAlign: "center"}}
              open={this.state.enableMultipleTabsOpenNotice}
              message={<span>CodeSpace is running in another window or tab. Close the other(s) and <a style={{color: 'red'}} href={window.location.href}>reload the page</a> to remove this message</span>}
            />

            {/* 'Editing code while program is running' notice. */}
            <Snackbar
              onClose={this.closeOutOfSyncNotice}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.enableOutOfSyncNotice && this.state.mbOutOfSync}
              message={<span>Code is still running... <br /><strong>Stop</strong> and then <strong>Run</strong> again to use new code from editor.</span>}
            />

            {/* Notify user of out-of-date device firmware */}
            <Snackbar
              onClose={this.closeOutDateNotice}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.enableOutOfDateNotice}
              action={<Button color="primary" size="small" onClick={this.handleFirmwareDialog}>Update Now</Button>}
              message={<span dangerouslySetInnerHTML={{__html: `Your ${debugController.devicePlatform} needs an update`}}></span>}
            />

            {/* Notify user of adding courses */}
            <Snackbar
              onClose={() => { this.setState({ assignmentsAdded: false }) }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.state.assignmentsAdded}
              message={<span>CodeSpace student assignments added!</span>}
              autoHideDuration={4000}
            />

            {/* Notify user of Google Drive connected app */}
            <Snackbar
              // onClose={this.closeOutDateNotice}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.showGoogleDriveInstall}
              action={
                <span>
                  <Button
                    size="small"
                    onClick={() => {
                      cblFirebase.userDoc.ref.set({ showGoogleDriveInstall: false }, { merge: true})
                      this.showGoogleDriveInstall = false
                    }}
                    style={{
                      color: 'rgb(224, 224, 244)',
                    }}
                  >
                    Maybe Later
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      googleApi.requestDriveFullScope((response) => {
                        // console.log(response)
                        this.showGoogleDriveInstall = false
                        cblFirebase.userDoc.ref.set({ showGoogleDriveInstall: false }, { merge: true})
                      })
                    }}
                  >
                    Enable
                  </Button>
                </span>
              }
              message={
                <span>
                  CodeSpace supports opening your saved programs directly from Google Drive.<br />
                  Would you like to enable this capability?
                </span>
              }
            />

            {/* Lockout editing code while stepping through program */}
            <Dialog open={this.state.openEditLockoutDialog}>
              <DialogTitle>{"Stop debugger?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You're currently stepping through a program. If you wish to change some code, you must first <strong>Stop</strong> the debugger.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeEditorLockoutDialogKeepDebug} color="primary">
                  Continue Debugging
                </Button>
                <Button onClick={this.closeEditorLockoutDialogStopDebug} color="primary" autoFocus>
                  Stop Debugger
                </Button>
              </DialogActions>
            </Dialog>

            {/* Warn if International Keyboard detected */}
            <Dialog open={this.state.openIntlKeyboardWarning}>
              <DialogTitle>{"International Keyboard?"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Looks like your <b>keyboard</b> may be set to a non-English language mode.<br />
                  To type "quotation marks" and special characters you need to either:<br />
                  <ul>
                    <li>Change to <b>US</b> keyboard (on Chromebook, click the box at lower right of screen), <b>or</b></li>
                    <li>Use a 2-key combination; For example: ' + <em>SPACE</em> makes a quotation mark on Chromebook <b>INTL</b></li>
                  </ul>
                  <em>More information: </em><a href="https://support.google.com/chromebook/answer/1059492" target="_blank"  rel="noopener noreferrer">Chromebook Keyboard Language</a>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {this.setState({openIntlKeyboardWarning: false})}}
                  color="primary" autoFocus
                >
                  Got it!
                </Button>
              </DialogActions>
            </Dialog>

            {/* Troubleshooting Help Dialog */}
            <MessageDialog
              title="Troubleshooting Steps"
              open={this.state.openTroubleshootingDialog}
              onClose={() => this.setState({openTroubleshootingDialog: false})}
            >
              <DialogContent>
                <em>If your device is not responding, try the following to recover it</em> <br /><br />
                First, try pressing the <b>Reboot</b> button on the device. Often this will bring it back!<br /><br />
                <b>If that doesn't work, perform these three steps:</b><br />
                1. Unplug the USB cable <br />
                2. Click Chrome's <em>Reload</em> button <br />
                3. Plug the USB cable back in <br /><br />
                You should now have a <em>fresh start</em> to connect with your device and write some CODE!
                <br /><br />
                <div style={{
                  fontSize: '10px',
                  marginTop: '2em',
                }}>
                  Support ID: {cblFirebase.userDoc ? cblFirebase.userDoc.id : 'not loaded'}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeTroubleDialog} color="primary">
                  OK
                </Button>
              </DialogActions>
            </MessageDialog>

            {/* OS Compatibility Warning Dialog */}
            <MessageDialog
              title="Compatibility Warning"
              open={this.state.showWindowsCompatWarning}
              onClose={() => this.setState({showWindowsCompatWarning: false})}
            >
              <DialogContent style={{padding:0}}>
                <div>
                  <b style={{color:'red'}}>NOTE:</b><br />
                  Looks like you're on an older version of <b>Windows</b> that doesn't natively support WebUSB :-(<br /><br />
                  You may need to upgrade your OS to enable USB access with CodeSpace.<br />
                  <span>See: </span>
                  <a href="https://firialabs.com/blogs/support-forum/computer-requirements" target="_blank"  rel="noopener noreferrer">Computer Requirements</a>
                  <span> for details.</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({showWindowsCompatWarning: false})} color="primary">
                  OK
                </Button>
              </DialogActions>
            </MessageDialog>

            {/* "Are you sure you want to erase device?"" Dialog */}
            <MessageDialog
              title="Confirm Erase"
              open={this.state.showConfirmEraseDevice}
              onClose={() => this.setState({showConfirmEraseDevice: false})}
            >
              <DialogContent style={{padding:0}}>
                <div>
                  <b style={{color:'red'}}>NOTE:</b><br />
                  <span dangerouslySetInnerHTML={{__html: `This will erase all Python files from your ${debugController.devicePlatform}.`}}></span>
                  <ul>
                    <li>That will give you a fresh start for new code!</li>
                    <li>...But any custom <em>imports</em> you've loaded will need to be re-loaded when you want to use them again.</li>
                  </ul>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({showConfirmEraseDevice: false})
                    debugController.eraseUserCode()
                  }}
                  color="primary"
                >
                  Erase!
                </Button>
                <Button onClick={() => this.setState({showConfirmEraseDevice: false})}>
                  Cancel
                </Button>
              </DialogActions>
            </MessageDialog>

            {/* Product Selection dialog (click on Product icon) */}
            <ProductSelectDialog
              open={this.state.openLicenseOptionsDialog}
              onClose={this.closeLicenseOptionsDialog}
            />

            {/* License Manager Dialog */}
            <LicenseManagerDialog
              open={this.state.openLicenseManagerDialog}
              onClose={this.closeLicenseManagerDialog}
              id='license-manager'
            />

            <JoinGroupDialog
              open={this.state.openJoinGroupDialog}
              onClose={this.closeJoinGroupDialog}
            />

            {/* Firmware Download */}
            <MessageDialog
              title="Firmware Download"
              open={this.state.openFirmwareDialog}
              onClose={() => this.setState({openFirmwareDialog: false})}
            >
              Select your device for instructions on updating the firmware
              <DialogContent>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{minHeight: 24}}>
                    <img src={MicrobitIcon} alt=""
                      style={{
                        height:32, margin: 'auto', display:'block', float:'left', marginRight:4, marginLeft: 4
                      }}
                    />
                    <h3 style={{margin:'auto'}}>micro:bit</h3>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{display:'block'}}>
                    <b>Follow the steps below to upgrade your micro:bit:</b><br />
                    <ol>
                    <li> Save this <a href={FirmwareFiles[1]} download={FirmwareFiles[1].match(/micropython_[a-f0-9]{7}/)[0]+'.hex'}>MicroPython</a> file to your PC.</li>
                    <li> In your local PC's file system, locate the <b>MICROBIT</b> drive. This will appear just like a <em>USB Flash Drive</em> when
                        you plug in the <b>micro:bit</b>.
                    </li>
                    <li> Drag the downloaded <em>MicroPython</em> file from Step-1 to the MICROBIT drive. </li>
                    <li> After a few seconds, the file will be copied and the <b>micro:bit</b> will restart.</li>
                    </ol>
                    <br />
                    Your micro:bit is now up to date, and ready for you to <em>start Coding!</em><br />
                    For additional details <a href="https://firialabs.com/blogs/support-forum/full-upgrade-or-onboarding-a-new-micro-bit"
                    target="_blank" rel="noopener noreferrer">
                    Click Here</a>.
                    <br />
                    <div style={{fontSize:'0.8em', margin:10}}>
                      <span>(Above link also describes onboarding process for micro:bits not purchased from Firia Labs. This </span>
                        <a href={FirmwareFiles[0]} download={FirmwareFiles[0].match(/maintenance_[a-f0-9]{7}/)[0]+'.hex'}>maintenance</a>
                      <span> download will <em>only</em> be needed for that case.)</span>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({openFirmwareDialog: false})} color="primary">
                  OK
                </Button>
                </DialogActions>
              </MessageDialog>

            {/* Lesson Error Dialog */}
            <MessageDialog
              title={(
                <div>
                  <IconWarning
                    style={{fill:'#E65100', marginRight: 10, float:'left', transform:'scale(1.5)'}}
                  />
                  {this.lessonDialogTitle}
                </div>
              )}
              open={this.state.openLessonErrorDialog}
              onClose={() => this.setState({openLessonErrorDialog: false})}
              style={{zIndex:9999, marginTop:100}}
            >
              <DialogContent style={{padding:0,}}>
                {this.lessonDialogContent}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({openLessonErrorDialog: false})} color="primary">
                  OK
                </Button>
              </DialogActions>
            </MessageDialog>

            {/* File Error Dialog */}
            <MessageDialog
              title={(
                <div>
                  <IconWarning
                    style={{
                      fill: '#E65100',
                      marginRight: 10,
                      float: 'left',
                      transform: 'scale(1.5)',
                    }}
                  />
                  {this.lessonDialogTitle}
                </div>
              )}
              open={this.state.openFileErrorDialog}
              onClose={() => this.setState({ openFileErrorDialog: false })}
              style={{
                zIndex: 9999,
                marginTop: 100,
              }}
            >
              <DialogContent style={{ padding: 0 }}>
                {this.fileErrorDialogContent}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ openFileErrorDialog: false })} color="primary">
                  OK
                </Button>
              </DialogActions>
            </MessageDialog>

            {/* Body */}
            <SplitPane
              split="vertical"
              defaultSize={this.state.windowWidth / 2}
              minSize={this.state.windowWidth / 3}
              maxSize={this.state.windowWidth - 100}
              paneStyle={{ display: 'flex' }}
              pane1Style={!this.state.showRightPanel ? { minWidth: '100%' } : null}
              pane2Style={{ boxShadow: '-4px 0px 10px #999', minWidth: '100px' }}
              onDragFinished={(newSize) => { this.setState({ mainPanelSize: newSize }) }}
              onChange={this.updateEditorPanel}
            >
              {/* Main Panel */}
              <SplitPane
                split="horizontal"
                defaultSize="75%"
                minSize={200}
                maxSize={this.state.windowHeight - 100}
                style={{
                  position: 'initial',
                  display: 'flex',
                }}
                pane1Style={(this.state.showBottomPanel && isActivated) ?
                  { display: 'flex', flex: '1 1 auto' }
                  :
                  { display: 'flex', flex: '1 1 auto', minHeight: '100%' }
                }
                pane2Style={{ display: 'flex', minHeight: 0 }}  /* minHeight=0 required for proper scrolling of VARIABLES panel */
                onChange={this.updateEditorPanel}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 7,
                    backgroundColor: '#EEEEEE',
                    minWidth: (this.state.windowWidth / 3),
                  }}
                >
                  {/* Header */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: 60,
                      backgroundColor: "white",
                      minWidth: (this.state.windowWidth / 3),
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      minWidth: '100px',
                    }}
                    >
                      {/* Logo Icon */}
                      <div
                        style={{
                          width: 40,
                          //backgroundColor: '#4AB848',
                          marginLeft: 10,
                          flex: '0 0 auto',
                        }}
                      >
                        <img
                          src={logo}
                          style={{
                            height: 40,
                            marginTop: 10,
                            marginLeft: 9
                          }}
                          alt=''
                        />
                      </div>
                      {/* Left hdr */}
                      <div style={{flex: '4 1 auto', display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                        {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}
                          {/* Program Name and folder icon */}
                          <CodeFileFolder
                            onFilenameChanged={this.handleFilenameChange}
                            saved={this.state.saved !== null}
                            onFolderClicked={this.handleMoveFile}
                            filename={this.state.filename}
                            usbConnected={this.state.usbConnected}
                            onUsbConnectRequest={this.handleUsbConnectRequest}
                            newFile={this.state.newFile}
                          />
                          {/* <Button>License!</Button>
                        </div> */}
                        {/* Menu bar */}
                        <MenuBar
                          saving={this.state.saving}
                          saved={this.state.saved !== null}
                          hasOpenFile={this.state.gFileId !== null}
                          hasSelection={this.state.hasSelection}
                          loadingFile={this.state.loadingFile}
                          onNewClick={this.handleNewFile}
                          onOpenClick={this.handleOpenFile}
                          onShowAdvDebugClick={() => { this.setState({ showAdvDebug: !this.state.showAdvDebug }) }}
                          onDebugClick={this.handleDebugPanelToggle}
                          onShowTroubleClick={this.handleTroubleDialog}
                          onShowCourseSelectClick={this.handleProductSelectDialog}
                          onShowLicenseManagerClick={this.handleLicenseManagerDialog}
                          onShowJoinGroupClick={this.handleJoinGroupDialog}
                          onShowFirmwareClick={this.handleFirmwareDialog}
                          onLessonClick={this.handleLessonPanelToggle}
                          onFolderClicked={this.handleMoveFile}
                          onCopyClick={this.handleCopyFile}
                          usbConnected={this.state.usbConnected}
                          showDebugPanel={this.state.showBottomPanel}
                          showLessonPanel={this.state.showRightPanel}
                          showAdvDebug={this.state.showAdvDebug}
                          copyText={this.state.copyText}
                          hasRedo={this.state.hasRedo}
                          hasUndo={this.state.hasUndo}
                          isActivated={isActivated}
                          onShowAddAssignmentsClick={this.handleAddAssignmentsDialog}
                        />
                      </div>

                      {/* Internet Connection Error Alert (right side in header) */}
                      <div
                        id= "net-connection-alert"
                        onMouseOver={this.handleConnectionAlertHover}
                        style={{
                          position: 'absolute',
                          textAlign: 'center',
                          top: 23,
                          right: 23,
                          zIndex: 99,
                          visibility: this.state.showAlert ? 'visible' : 'hidden'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            paddingBottom: 5,
                            cursor: this.state.gDriveAccess ? null : 'pointer',
                          }}
                        >
                          <div
                            style={{
                              boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                              minHeight: 14,
                              transition: 'opacity 0.218s',
                              padding: '6px 16px',
                              backgroundColor: this.needsSave ? '#2196F3' : '#f9edbe',
                              borderColor: this.needsSave ? '#2196F3' : '#f0c36d',
                              color: this.needsSave ? '#fff' : '#333',
                              borderStyle: 'solid',
                              fontSize: 11,
                              textAlign: 'center',
                              borderRadius: 2,
                              borderWidth: 1,
                              cursor: 'default',
                            }}
                          >
                            {this.getConnectionText()}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      {/* User status badge... placeholder */}
                      {/*
                      <div style={{marginRight:10, backgroundColor:'blue'}}>
                        badge
                      </div>
                      */}

                      {/* Avatar */}
                      <UserSession
                        gLoaded={this.state.gLoaded}
                        signedIn={this.state.gSignedIn}
                        userAttributes={this.state.userAttributes}
                      />
                    </div>

                  </div>

                  {/* Toolbar */}
                  <div style={{
                    height: 35,
                    marginTop: 1,
                    borderColor: 'lightgray',
                    borderWidth: 1,
                    borderTopStyle: 'solid',
                    borderBottomStyle: 'solid',
                    borderTopWidth: 1,
                    backgroundColor: "#FAFAFA"
                  }}>
                    <ToolBar
                      onDebugClick={this.handleDebugPanelToggle}
                      showAdvDebug={this.state.showAdvDebug}
                      debugging={this.state.debugging}
                      downloading={this.state.downloading}
                      onLessonClick={this.handleLessonPanelToggle}
                      showDebugPanel={this.state.showBottomPanel}
                      showLessonPanel={this.state.showRightPanel}
                      usbConnected={this.state.usbConnected}
                      onWebusbConnectClick={this.handleWebusbConnect}
                      onCheckCodeClick={this.handleSyntaxCheck}
                      onEraseDeviceClick={this.handleEraseDevice}
                      mbState={this.state.mbState}
                      mbMode={this.state.mbMode}
                      onShowFirmwareClick={this.handleFirmwareDialog}
                      isActivated={isActivated}
                    />
                  </div>

                  {/* Code Editor */}
                  <div id="editor-panel" style={{ flex: 5, display: 'flex' }}>
                    <CodeEditor
                      onChange={this.handleEditorChange}
                      onChangeSelection={this.handleEditorChangeSelection}
                      onCopy={this.handleCopyText}
                      onDeadKeyPress={this.handleDeadKeyPress}
                      onChangeBreakpoint={this.handleEditorChangeBreakpoint}
                      enableBreakpoints={this.state.connectedDeviceMaxBreakpoints > 0 &&
                                         this.state.currentNumEditorBreakpoints < this.state.connectedDeviceMaxBreakpoints &&
                                         this.state.mbState === mb.state.IDLE}
                    />
                  </div>
                </div>
                {/* Bottom "data" Panel */}
                <SplitPane
                  split="vertical"
                  defaultSize="50%"
                  minSize={302} // These magic numbers are SWAGs based on something that looks good on my screen
                  maxSize={this.state.mainPanelSize - 216}
                  style={{
                    position: 'initial',
                    flex: 1,
                    backgroundColor: 'white',
                  }}
                  paneStyle={{ display: 'flex' }}
                  pane1Style={this.state.showAdvDebug ?
                    { display: 'flex' }
                    :
                    { display: 'none' }
                  }
                >
                  {/* Console */}
                  <div
                    style={{
                      flex: 2,
                      display: (this.state.showAdvDebug && this.state.showBottomPanel) ? 'flex' : 'none',
                      margin: '10px 5px 10px 10px',
                    }}
                  >
                    <ConsoleRepl
                      onCharInput={this.handleConsoleChars}
                      ref={this.getConsoleRef}
                    />
                  </div>
                  {/* Variables */}
                  <div id='variable-pane-wrapper' style={{ flex: 1, display: 'flex' }}>
                    <VariablesPane
                      localVariables={this.state.localVariables}
                      globalVariables={this.state.globalVariables}
                    />
                  </div>
                </SplitPane>
              </SplitPane>
              {/* Right Panel */}
              <div
                style={{
                  border: '1px solid rgb(217, 217, 217)',
                  borderTop: 'none',
                  width: '100%',
                }}
              >
                <LessonPanel
                  showLessonPanel={this.state.showRightPanel}
                  windowHeight={this.state.windowHeight}
                  windowWidth={this.state.windowWidth}
                  showDebugPanel={this.state.showBottomPanel}
                  onDebugClick={this.handleDebugPanelToggle}
                  mbMode={this.state.mbMode}
                  mbState={this.state.mbState}
                  interceptErrorCb={this.interceptErrorCb}
                  onShowFirmwareClick={this.handleFirmwareDialog}
                  isActivated={isActivated}
                  currentModule={currentModule}
                  gFileId={this.state.gFileId}
                  onSelectCurriculum={this.handleProductSelectDialog}
                  initialLicenseLoadComplete={initialLoadComplete}
                />
              </div>
            </SplitPane>
          </div>
          <CopyFileDialog
            open={this.state.showCopyFileDialog}
            onClose={this.handleCopyDialogClose}
            gFileId={this.state.gFileId}
          />
          <GoogleClassroomAssignments
            currentModule={currentModule}
            open={this.state.showAddAssignmentsDialog}
            onClose={(result) => {
              this.setState({ showAddAssignmentsDialog: false })
              if (result) {
                this.setState({ assignmentsAdded: true })
              }
            }}
          />
        </ErrorBoundary>
      </MuiThemeProvider>
      )} />
    )
  }
}

export default App
