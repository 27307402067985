// Contextual styled messages adorned with icons, to consistently highlight elements of Lessons

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IconBulb from 'material-ui-icons/LightbulbOutline'
import IconToys from 'material-ui-icons/Toys'
import IconKeyboard from 'material-ui-icons/Keyboard'
import IconWarning from 'material-ui-icons/Warning'
import IconRunning from 'material-ui-icons/DirectionsRun'
import IconDebug from 'material-ui-icons/SubdirectoryArrowRight'
import IconInteract from 'material-ui-icons/TouchApp'
import IconPeripheral from 'material-ui-icons/SettingsInputComponent'
import IconHelp from 'material-ui-icons/Help'
import Markdown from './cbl-remarkable'
import ExpansionPanel, {ExpansionPanelSummary, ExpansionPanelDetails} from 'material-ui/ExpansionPanel'
import IconExpandMore from 'material-ui-icons/ExpandMore'
import IconHelpOutline from 'material-ui-icons/HelpOutline'

// Contextual styled message
export class LegendMessage extends Component {
  static propTypes = {
    icon: PropTypes.func,
    iconColor: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
  }

  static defaultProps = {
    icon: IconRunning,
    iconColor: 'black',
    title: '',
    style: {},
    id: null,
  }

  render() {
    let {style, title, children, icon, iconColor, ...other} = this.props
    let legendTitle = null
    let legendText = null
    style = Object.assign({
      padding: '0.25em',
      flex: '1 1 auto',
      maxWidth: '95%',
      overflowX: 'auto',
      background: 'white',
      boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.75)',
    }, style)

    if (this.props.title) {
      legendTitle = <h3 style={{fontSize: '1.25em', margin: 0}}>
        {this.props.title}
      </h3>
    }

    if (this.props.children) {
      if (typeof(this.props.children) === 'string') {
        legendText = <Markdown>{this.props.children}</Markdown>
      }
      else {
        legendText = <div style={{marginTop: '0.25em', overflow: 'auto'}}>{this.props.children}</div>
      }
    }

    return (
      <div>
        <div
          style={{display: 'flex', margin: '1.5em 1.5em 1.5em 0', position: 'relative'}}
          id={this.props.id}
          {...other}
        >
          <div style={{padding: '0.25em'}}>
            {React.createElement(this.props.icon, {
              style: {
                fill:this.props.iconColor,
                height: '1.5em',
                filter: 'drop-shadow(2px 2px 2px #999999)',
              }
            })}
          </div>
          <div style={style}>
            {legendTitle}
            {legendText}
          </div>
        </div>
        {/* <span><hr /></span> */}
      </div>
    )
  }
}

class LegendMessageBase extends Component {
  constructor(props, title, icon, iconColor) {
    super(props)
    this.title = title
    this.icon = icon
    this.iconColor = iconColor
  }
  render() {
    let customProps = {}
    Object.assign(customProps, {
      title: this.title,
      icon: this.icon,
      iconColor: this.iconColor,
    }, this.props)
    return (
      React.createElement(LegendMessage, customProps, this.props.children)
    )
  }
}

export class LegendMessageRun extends LegendMessageBase {
  constructor(props) {
    super(props, "Run your program", IconRunning, 'green')
  }
}

export class LegendMessageTry extends LegendMessageBase {
  constructor(props) {
    super(props, "Try your skills", IconToys, '#03a9f4')
  }
}

export class LegendMessageKeyboard extends LegendMessageBase {
  constructor(props) {
    super(props, "Type in the Code", IconKeyboard, '#78909c')
  }
}

export class LegendMessageDebug extends LegendMessageBase {
  constructor(props) {
    super(props, "Debug the Code", IconDebug, '#ff9800')
  }
}

export class LegendMessageWarning extends LegendMessageBase {
  constructor(props) {
    super(props, "Caution", IconWarning, '#E65100')
  }
}

export class LegendMessageConcept extends LegendMessageBase {
  constructor(props) {
    super(props, "Coding Concepts", IconBulb, 'purple')
  }
}

export class LegendMessageInteract extends LegendMessageBase {
  constructor(props) {
    super(props, "Physical Computing", IconInteract, 'brown')
  }
}

export class LegendMessagePeripheral extends LegendMessageBase {
  constructor(props) {
    super(props, "Connect Peripheral", IconPeripheral, 'blue')
  }
}

export class LegendQuiz extends LegendMessageBase {
  constructor(props) {
    super(props, "Test your knowledge", IconHelp, 'rgb(237, 79, 79)')
  }
}

export class LegendCollapsed extends React.Component {
  static propTypes = {
    summary: PropTypes.node,
    details: PropTypes.node.isRequired,
    summaryStyle: PropTypes.object,
  }

  static defaultProps = {
    summary: 'Click to expand',
    summaryStyle: {},
  }

  render () {
    return (
      <div style={{display:'flex', maxWidth: '100%', margin: '1.5em 1.5em 1.5em 0'}}>
        <IconHelpOutline
          style={{padding:'.25em', fill:'#cd8acd', flex: '0 0 auto'}}
        />
        <ExpansionPanel
          style={{width: 'fit-content', flex: '1 1 auto', minWidth: '50px', margin: '0', boxShadow: 'rgba(0, 0, 0, 0.75) 5px 5px 10px 0px'}}
        >
          <ExpansionPanelSummary
            expandIcon={<IconExpandMore />}
            style={{paddingLeft: '0.25em'}}
          >
            <h3 style={{fontSize: '1.25em', margin: 0, ...this.props.summaryStyle}}>
              {this.props.summary}
            </h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{padding: '0px 10px 5px 10px', marginTop: -10, overflowX: 'auto'}}
          >
            {this.props.details}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}
