import React, { Component } from 'react'
import Divider from 'material-ui/Divider'
import { withStyles } from 'material-ui'
import AvailableCurriculaSelector from './AvailableCurriculaSelector.jsx'
import GroupsTopBar from './GroupsTopBar.jsx'
import GroupsTable from './GroupsTable.jsx'
import EditGroupPanel from './EditGroupPanel.jsx'
import MembersPanel from './MembersPanel.jsx'
import { Database } from '../DataSources'
import { moduleEnums } from '../../lessons/CurriculumModules'
import { drawerWidth } from '../RootPanel'

const rightDrawerWidth = 328

const styles = theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '8px 16px',
  },
  tableNoShift: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
    height: '100%',
  },
  tableShifted: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    maxWidth: `calc(100vw - ${rightDrawerWidth + drawerWidth}px)`,
    height: '100%',
  },
  mpContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    height: '100%',
  },
  mpShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: rightDrawerWidth,
    height: '100%',
  },
  editGroupPanel: {
    width: rightDrawerWidth,
    flexShrink: 0,
    top: 60,
    padding: 0,
  },
  tableSection: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainSection: {
    flexGrow: 1,
    minHeight: 0,
  },
  breadcrumbs: {
    flex: '1 1 300px',
    marginTop: 10,
  },
  editPanelButton: {
    margin: 0,
    padding: 10,
    float: 'right',
  },
})

class GroupsMainPanel extends Component {
  state = {
    route: '/home',
    editPanelVisible: false,
    selectedGroupId: '',
  }

  getMainContent = route => (
    <div className={this.state.editPanelVisible ? this.props.classes.mpShift : this.props.classes.mpContent}>
      <div className={this.state.editPanelVisible ? this.props.classes.tableShifted : this.props.classes.tableNoShift}>
        {this.getTableContent(route)}
      </div>
    </div>
  )

  getGroupsTable = (route, gsData) => {
    return (
      <GroupsTable
        rowData={gsData}
        data={this.props.data}
        onSelectGroup={this.groupSelected}
        onDoubleClick={(groupId) => {
          const groupFound = gsData.find(grp => grp.groupId === groupId)
          const currVal = Object.keys(moduleEnums).find(key => moduleEnums[key] === groupFound.curriculum)
          this.updateRoute(`home/curriculum-${currVal.toString()}/${groupId}`)
        }}
      />
    )
  }

  getTableContent = (route) => {
    const pathnames = route.split('/').filter(x => x)
    const sel = pathnames[pathnames.length - 1]
    if (sel === 'home' || sel.substr(0, 10) === 'curriculum') {
      let gsData = null
      if (sel === 'home') {
        gsData = this.props.data.groups
      } else {
        const filterCurr = parseInt(sel.substring(11), 10)
        gsData = this.props.data.groups.filter(g => g.curriculum === moduleEnums[filterCurr])
      }

      return (
        <div className={this.props.classes.tableSection} >
          <AvailableCurriculaSelector updateRoute={this.updateRoute} route={route} data={this.props.data} />
          <Divider />
          {this.getGroupsTable(route, gsData)}
        </div>
      )
    }

    const gData = this.props.data.groups.find(x => x.groupId === pathnames[pathnames.length - 1])
    return (
      <MembersPanel
        groupId={gData.groupId}
        curriculum={gData.curriculum}
        disableUi={this.props.disableUi}
        uiDisabled={this.props.uiDisabled}
      />
    )
  }

  deleteGroup = (groupId) => {
    this.setState({ selectedGroupId: '', route: '/home' })

    this.props.disableUi(true)
    Database.deleteGroup(groupId).then(() => {
      this.props.refreshData()
      this.props.disableUi(false)
    })
  }

  groupSelected = (groupId) => {
    this.setState({ selectedGroupId: groupId })
  }

  updateRoute = (newRoute) => {
    this.setState({ route: newRoute })
  }

  toggleEditPanelVis = () => {
    this.setState({ editPanelVisible: !this.state.editPanelVisible })
  }

  render() {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <GroupsTopBar
          groupData={this.props.data.groups}
          classes={this.props.classes}
          route={this.state.route}
          updateRoute={this.updateRoute}
          toggleEditPanel={this.toggleEditPanelVis}
          editPanelVis={this.state.editPanelVisible}
        />
        <Divider />
        <div className={this.props.classes.mainSection}>
          {this.getMainContent(this.state.route)}
          <EditGroupPanel
            toggleOpen={this.toggleEditPanelVis}
            classes={this.props.classes}
            groupId={this.state.selectedGroupId}
            isVisible={this.state.editPanelVisible}
            refreshData={this.props.refreshData}
            groupDeleted={this.deleteGroup}
            data={this.props.data}
            disableUi={this.props.disableUi}
            uiDisabled={this.props.uiDisabled}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GroupsMainPanel)
