// React wrapper for remarkable (https://github.com/jonschlinkert/remarkable)
// Firia Labs: modified react-remarkable to add 'full' constructor param

import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'remarkable'
import rules from 'remarkable/lib/rules'
import classy from 'remarkable-classy'
import hljs from 'highlight.js'
import './ace-style-highlight.css'
import {cblPythonHighlight} from './python-lang-highlight'
import {LegendCollapsed} from './Legends'
import Visibility from 'material-ui-icons/BlurCircular'
import AddCircleOutline from 'material-ui-icons/AddCircleOutline'
import Tooltip from 'material-ui/Tooltip'
import katex from 'remarkable-katex'
import 'katex/dist/katex.min.css'

// Register our custom language parser defs which more closely match Ace Editor
hljs.registerLanguage('python3', cblPythonHighlight)

const regexCode = /<pre><code(.*?)>([\s\S]*?)<\/code><\/pre>/

const defaultOptions = {
  typographer: true,

  highlight: function(str, lang) {
    try {
      return hljs.highlight('python3', str).value
    } catch (err) {}

    return '' // use external default escaping
  }
}

// Render a line of text as a <span>, blurred if line starts with $
class CodeLine extends React.Component {
  static propTypes = {
    line: PropTypes.string.isRequired,
    blurEnabled: PropTypes.bool.isRequired,
  }

  render() {
    const spanStyle = {
      transition: 'filter 0.1s',
      padding: '0 0.25em 0 0.25em',
    }
    let innerHTML = this.props.line
    const regexBlur = /^\$([\s\S]*)/m
    let match = regexBlur.exec(innerHTML)
    if (match) {
      innerHTML = match[1]
    } else if (this.props.blurEnabled) {
      Object.assign(spanStyle, {filter: 'blur(0.22em)'})
    }
    return (
      <span style={spanStyle} dangerouslySetInnerHTML={{__html: innerHTML }}/>
    )
  }
}

class CodeContainer extends React.Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    classes: PropTypes.string,
  }

  static defaultProps = {
    classes: '',
  }

  constructor(props) {
    super(props)
    this.code = this.props.code

    this.collapsed = false
    const regexExpansion = /class="(.*?)collapsed ?(.*?)"/
    let matchExpansion = regexExpansion.exec(this.props.classes)
    if (matchExpansion) {
      this.collapsed = true
      this.title = matchExpansion[2]
    }

    this.shrunken = false
    const regexFold = /^\${3}[\n\r]?/mg
    let matchFoldBegin = regexFold.exec(this.props.code)
    if (matchFoldBegin) {
      let matchFoldEnd = regexFold.exec(this.props.code)
      if (matchFoldEnd) {
        if (regexFold.exec(this.props.code)) {
          console.log("Error: Too many folding lines '$$$' found in markdown code:\n", this.props.code)
        } else {
          // We have two sets of '$$$', create shrunken code element
          this.shrunken = true
          this.codeBefore = this.code.substring(0, matchFoldBegin.index)
          this.codeAfter = this.code.substring(matchFoldEnd.index + matchFoldEnd[0].length)
          this.code = this.code.substring(matchFoldBegin.index + matchFoldBegin[0].length, matchFoldEnd.index)
        }
      } else {
        console.log("Error: Only one folding line '$$$' found in markdown code:\n", this.props.code)
      }
    }

    const regexBlur = /^\$([^$].*)$/m
    this.blur = regexBlur.test(this.props.code)

    this.state = {
      blurEnabled: this.blur,
      shrinkEnabled: this.shrunken,
    }
  }

  componentWillReceiveProps(nextProps) {
    // Added because a lot of state is calculated in ctor, leading to bug (re-appearance of CodeContainer content
    // in cascading help popups) when props.code change is rendered but ctor has squirreled prior strings away.
    if (nextProps.code !== this.props.code) {
      this.constructor(nextProps)  // Recalculate everything if the code changed
    }
  }

  componentDidMount() {
    if (this.shrunken || this.blur) {
      this.elemPre.style.cursor = 'zoom-in'
    }
    if (this.shrunken) {
      this.existingHeight = this.elemWrapper.offsetHeight
      this.elemWrapper.style.height = this.elemCode.offsetHeight + 'px'
      this.elemPositioner.style.top = '-' + this.elemCodeBefore.offsetHeight + 'px'
      //this.elemPre.style.boxShadow = 'inset 0 1em 1em -0.5em #BDBDBD, inset 0 -1em 1em -0.5em #BDBDBD'
      this.elemPre.style.borderTopStyle = 'dashed'
      this.elemPre.style.borderTopWidth = '4px'
      this.elemPre.style.borderBottomStyle = 'dashed'
      this.elemPre.style.borderBottomWidth = '4px'
    }
  }

  // Removes one layer of 'fog' from the code block
  csiEnhance = () => {
    if (this.state.shrinkEnabled) {
      this.setState({shrinkEnabled: false})
      this.elemWrapper.style.height = this.existingHeight + 'px'
      this.elemPositioner.style.top = '0px'
      //this.elemPre.style.boxShadow = 'inset 0 0 0 0 black, inset 0 0 0 0 black'
      this.elemPre.style.borderStyle = 'solid'
      this.elemPre.style.borderWidth = 'initial'
    } else if (this.state.blurEnabled) {
      this.setState({blurEnabled: false})
      this.elemPre.style.cursor = null
    }
  }

  renderCollapsed = (code, title) => {
    return (
      <LegendCollapsed
        summary={title}
        details={<pre
          ref={(elem) => {this.elemPre = elem}}
          style={{flex: '1 1 auto'}}
          onClick={this.csiEnhance}
          >
            <code>{code}</code>
          </pre>}
      />
    )
  }

  renderUncollapsed = (code) => {
    return (
      <pre
        ref={(elem) => {this.elemPre = elem}}
        onClick={this.csiEnhance}
        style={{
          //width:'fit-content',
          //minWidth:'80%',
          maxWidth:'90%',
          overflowX: 'auto',
          margin:'auto 60px 10px',
          //marginBottom:10,
          padding: 0,
        }}
      >
        <code>{code}</code>
      </pre>
    )
  }

  render () {
    let renderedCode = []

    if (this.shrunken) {
      let linesBefore = this.codeBefore.split('\n')
      if (linesBefore[linesBefore.length-1] === '') {
        linesBefore.pop()
      }
      let lines = this.code.split('\n')
      if (lines[lines.length-1] === '') {
        lines.pop()
      }
      let linesAfter = this.codeAfter.split('\n')
      if (linesAfter[linesAfter.length-1] === '') {
        linesAfter.pop()
      }
      renderedCode = (
        <div
          ref={(elem) => {this.elemWrapper = elem}}
          style={{position: 'relative', overflow: 'hidden', transition: 'all 0.3s', overflowX:'auto'}}
        >
          <div
            ref={(elem) => {this.elemPositioner = elem}}
            style={{position: 'relative', transition: 'all 0.3s'}}
          >
            <div
              ref={(elem) => {this.elemCodeBefore = elem}}
            >
              {linesBefore.map((line, index) => (
                <CodeLine
                  key={index}
                  line={line + '\n'}
                  blurEnabled={this.state.blurEnabled}
                />
              ))}
            </div>
            <div ref={(elem) => {this.elemCode = elem}} >
              {lines.map((line, index) => (
                <CodeLine
                  key={index}
                  line={line + '\n'}
                  blurEnabled={this.state.blurEnabled}
                />
              ))}
            </div>
            <div
              ref={(elem) => {this.elemCodeAfter = elem}}
            >
              {linesAfter.map((line, index) => (
                <CodeLine
                  key={index}
                  line={line + '\n'}
                  blurEnabled={this.state.blurEnabled}
                />
              ))}
            </div>
          </div>
        </div>
      )
    } else {
      let lines = this.code.split('\n')
      if (lines[lines.length-1] === '') {
        lines.pop()
      }
      renderedCode = (
        <div style={{overflowX:'auto'}}>
          {lines.map((line, index) => (
            <CodeLine
              key={index}
              line={line + '\n'}
              blurEnabled={this.state.blurEnabled}
            />
          ))}
        </div>
      )
    }

    const adornedCode = this.state.blurEnabled || this.state.shrinkEnabled
    const viewIconStyle={
      margin:'auto',
      position: 'absolute',
      top: -14,
      left: '48%',
      display: adornedCode ? 'block' : 'none',
      fill:'#757575',
      background: 'white',
      borderRadius:'50%'
    }
    renderedCode = (
      <div
        onMouseLeave={() => {
          if (this.blur) {
            this.setState({blurEnabled:true})
          }
        }}
        style={{cursor: adornedCode ? 'zoom-in' : 'initial',
        overflowX: adornedCode ? 'inherit' : 'auto',
        position: 'relative'}}
      >
        {this.state.shrinkEnabled ?
          (<Tooltip title="Expand Code" style={{visibility: adornedCode ? 'visible' : 'hidden'}} >
             <AddCircleOutline style={viewIconStyle} />
           </Tooltip>
          ) :
          (<Tooltip title="Reveal Code" style={{visibility: adornedCode ? 'visible' : 'hidden'}} >
             <Visibility style={viewIconStyle} />
           </Tooltip>
          )
        }
        {renderedCode}
      </div>
    )

    if (this.collapsed) {
      return (this.renderCollapsed(renderedCode, this.title))
    } else {
      return (this.renderUncollapsed(renderedCode))
    }
  }
}

class Remarkable extends React.Component {
  render() {
    var Container = this.props.container

    return (
      <Container>
        {this.content()}
      </Container>
    )
  }

  highlightInlineCode = (tokens, idx) => {
    const text = tokens[idx].content
    return `<span style="background:#fafafac0; border-style:solid; border-color: #BDBDBDa0; border-radius: .3em; border-width: thin;
             font-family: monospace; font-size: 125%; white-space: nowrap;"> ${hljs.highlight('python3', text).value}</span>`
  }

  // Common setup for Remarkable after instantiation
  setupMd = () => {
    this.md.set({html: true})

    this.md.use(katex)
    this.md.use(classy)

    this.md.renderer.rules.table_open = function () {
      return '<table class="table table-striped">\n';
    };

    // Monkey-patch our inline highlighter for single-backtick inline code snippets
    rules.code = this.highlightInlineCode
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.options !== this.props.options) {
      this.md = new Markdown('full', nextProps.options)
      this.setupMd()
    }
  }

  content() {
    if (this.props.source) {
      return <span dangerouslySetInnerHTML={{
          __html: this.renderMarkdown(this.props.source) }}/>
    } else {
      return React.Children.map(this.props.children, child => {
        if (typeof child === 'string') {
          let rendered = this.renderMarkdown(child)

          let match = regexCode.exec(rendered)
          let retval = []
          if (match) {
            let count = 0
            while (match) {
              retval.push(
                <div key={count}>
                  <span dangerouslySetInnerHTML={{__html: match.input.substring(0, match.index) }}/>
                  <CodeContainer
                    classes={match[1]}
                    code={match[2]}
                  />
                </div>
              )
              rendered = rendered.substring(match.index + match[0].length)
              match = regexCode.exec(rendered)
              count++
            }
            return (
              <div>
                {retval}
                <div dangerouslySetInnerHTML={{__html: rendered }} />
              </div>
            )
          } else {
            return (<span dangerouslySetInnerHTML={{ __html: rendered }}/>)
          }
        } else {
          return child
        }
      })
    }
  }

  renderMarkdown(source) {
    if (!this.md) {
      this.md = new Markdown('full', this.props.options)
      this.setupMd()
    }

    return this.md.render(source)
  }
}

Remarkable.defaultProps = {
  container: 'div',
  options: defaultOptions
}

export default Remarkable
