import React, { Component, PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles'
import classNames from 'classnames'
import Popover from 'material-ui/Popover'
import Typography from 'material-ui/Typography'
import UndoIcon from 'material-ui-icons/Undo'
import RedoIcon from 'material-ui-icons/Redo'
import SearchIcon from 'material-ui-icons/Search'
import CheckIcon from 'material-ui-icons/Check'
import LinkIcon from 'material-ui-icons/OpenInNew'
import FolderIcon from 'material-ui-icons/Folder'
import CutIcon from 'material-ui-icons/ContentCut'
import PasteIcon from 'material-ui-icons/ContentPaste'
import CopyIcon from 'material-ui-icons/ContentCopy'
import MemoryIcon from 'material-ui-icons/Memory'
import AssignmentIcon from 'material-ui-icons/Assignment'
import AddAssignmentsIcon from 'material-ui-icons/AssignmentInd'
import DownloadIcon from 'material-ui-icons/GetApp'
import CurriculumIcon from 'material-ui-icons/LibraryBooks'
import JoinClassIcon from 'material-ui-icons/GroupAdd'
import DashboardIcon from 'material-ui-icons/Group'
import copy from 'copy-to-clipboard'
import { editorInst } from './CodeEditor'
import googleApi from "./Gapi";
import {cblFirebase} from "./myfirebase";


const menuUpdateStyle = {
  color: '#777',
  marginLeft: '14px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'arial, sans-serif',
  fontSize: '13px',
  paddingLeft: 7,
  paddingTop: 5,
  minWidth: '25px',
  flex: '1 1 0%',
  overflow: 'hidden',
}

const styles = theme => ({
  typography: {
    // margin: theme.spacing.unit * 2,
    fontFamily: 'arial, sans-serif',
    fontSize: '13px',
    color: 'black',
  },
  typographyDisabled: {
    // margin: theme.spacing.unit * 2,
    fontFamily: 'arial, sans-serif',
    fontSize: '13px',
    color: '#cccccc',
  },
  accel: {
    marginLeft: theme.spacing.unit * 2,
    fontFamily: 'arial, sans-serif',
    fontSize: '13px',
    color: '#777',
    flex: 1,
    textAlign: 'right',
  },
  myMenuItemDiv: {
    display: 'flex',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    // marginTop: theme.spacing.unit * 1,
    // marginBottom: theme.spacing.unit * 1
  },
  myMenuItemEnabled: {
    cursor: 'pointer',
  },
  myMenuItemDisabled: {
    cursor: 'default',
    color: 'rgb(51, 51, 51)',
  },
  image: {
    width: 18,
    height: 18,
    marginRight: 10,
  },
  menuSeperator: {
    borderTop: '1px solid #ebebeb',
    marginTop: 6,
    marginBottom: 6,
  },
  menuBox: {
    border: '1px solid rgba(0,0,0,.2)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    borderBottom: 'none',
  },
  menuBase: {
    cursor: 'default',
    fontFamily: 'arial, sans-serif',
    fontSize: '13px',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    paddingBottom: 7,
    zIndex: 10,
    outline: 0,
    // boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
  },
  menuPopup: {
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    border: '1px solid rgba(0,0,0,.2)',
    borderTop: 'none',
  },
})


class MyMenuItemBase extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    classes: PropTypes.shape(styles).isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    onClick: PropTypes.func.isRequired,
    id: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    id: null,
  }

  state = {
    backgroundColor: '#fff',
  }

  render() {
    return (
      <div
        className={classNames(this.props.classes.myMenuItemDiv, this.props.disabled ? this.props.classes.myMenuItemDisabled : this.props.classes.myMenuItemEnabled)}
        style={{
          backgroundColor: this.state.backgroundColor,
          opacity: this.props.disabled ? 0.4 : null,
        }}
        onMouseEnter={() => {
          if (!this.props.disabled) {
            this.setState({
              backgroundColor: '#eee',
            })
          }
        }}
        onMouseLeave={() => {
          this.setState({
            backgroundColor: '#fff',
          })
        }}
        onClick={this.props.disabled ? null : this.props.onClick}
        onKeyPress={this.props.onClick}
        role="button"
        tabIndex={0}
        id={this.props.id}
      >
        {this.props.children}
      </div>
    )
  }
}

const MyMenuItem = withStyles(styles)(MyMenuItemBase)


class MyMenuBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape(styles).isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }

  state = {
    backgroundColor: '#fff',
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={this.props.classes.menuBase}
        style={{ backgroundColor: this.state.backgroundColor }}
        onMouseEnter={() => {
          this.setState({
            backgroundColor: '#eee',
          })
        }}
        onMouseLeave={() => {
          this.setState({
            backgroundColor: '#fff',
          })
        }}
        onClick={this.props.onClick}
        onKeyPress={this.props.onClick}
        role="button"
        tabIndex={0}
      >
        {this.props.children}
      </div>
    )
  }
}

const MyMenuRoot = withStyles(styles)(MyMenuBase)


class MenuBar extends Component {
  static propTypes = {
    saving: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    hasSelection: PropTypes.bool.isRequired,
    hasOpenFile: PropTypes.bool.isRequired,
    loadingFile: PropTypes.bool.isRequired,
    onNewClick: PropTypes.func.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    onShowAdvDebugClick: PropTypes.func.isRequired,
    onShowTroubleClick: PropTypes.func.isRequired,
    onShowCourseSelectClick: PropTypes.func.isRequired,
    onShowLicenseManagerClick: PropTypes.func.isRequired,
    onShowJoinGroupClick: PropTypes.func.isRequired,
    onShowFirmwareClick: PropTypes.func.isRequired,
    onFolderClicked: PropTypes.func.isRequired,
    onLessonClick: PropTypes.func.isRequired,
    onDebugClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    showDebugPanel: PropTypes.bool.isRequired,
    showLessonPanel: PropTypes.bool.isRequired,
    showAdvDebug: PropTypes.bool.isRequired,
    copyText: PropTypes.string.isRequired,
    classes: PropTypes.shape(styles).isRequired,
    hasUndo: PropTypes.bool.isRequired,
    hasRedo: PropTypes.bool.isRequired,
    isActivated: PropTypes.bool.isRequired,
    onShowAddAssignmentsClick: PropTypes.func.isRequired,
  }

  state = {
    showFileMenu: false,
    showEditMenu: false,
    showViewMenu: false,
    showHelpMenu: false,
    fileMenuAnchor: null,
    editMenuAnchor: null,
    viewMenuAnchor: null,
    helpMenuAnchor: null,
    myClipboard: '',
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.copyText !== this.props.copyText) {
      this.setState({ myClipboard: nextProps.copyText })
    }
  }

  onFileClose = () => {
    this.setState({
      showFileMenu: false,
      showEditMenu: false,
      showViewMenu: false,
      showHelpMenu: false,
    })
  }

  getStatusText = () => {
    if (this.props.loadingFile) {
      return 'Loading...'
    } else if (this.props.saving) {
      return 'Saving...'
    } else if (this.props.saved) {
      return 'All changes saved in Google Drive'
    }

    return ''
  }

  // Maintain focus in Editor amidst Menu clicks
  areaClick = () => {
    editorInst.focus()
  }

  render() {
    return (
      <div
        onClick={this.areaClick}
        style={{
          display: 'flex',
          flex: '1 1 auto',
          marginLeft: '7px',
          paddingLeft: '5px',
          marginTop: '2px',
          minWidth: '50px',
        }}
        role="none"
      >
        <MyMenuRoot
          id="menu-file"
          onClick={(event) => {
            this.setState({
              showFileMenu: !this.state.showFileMenu,
              fileMenuAnchor: event.currentTarget,
            })
          }}
          selected={this.state.showFileMenu}
        >
          File
        </MyMenuRoot>
        <Popover
          open={this.state.showFileMenu}
          anchorEl={this.state.fileMenuAnchor}
          onClose={this.onFileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MyMenuItem
            id="menu-file-new"
            onClick={() => {
              this.props.onNewClick()
              this.onFileClose()
            }}
          >
            <div className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>New</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-file-open"
            onClick={() => {
              this.props.onOpenClick()
              this.onFileClose()
            }}
          >
            <div className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Open...</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-file-copy"
            onClick={() => {
              this.props.onCopyClick()
              this.onFileClose()
            }}
            disabled={!this.props.hasOpenFile}
          >
            <div className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Make a copy...</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-file-move"
            onClick={() => {
              this.props.onFolderClicked()
              this.onFileClose()
            }}
            disabled={!this.props.hasOpenFile}
          >
            <FolderIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Move to...</Typography>
          </MyMenuItem>
          {
            !googleApi.hasDriveFullScope()
                ?
                <Fragment>
                  <div className={this.props.classes.menuSeperator}/>
                  <MyMenuItem
                      id="menu-file-gdrive"
                      onClick={() => {
                        googleApi.requestDriveFullScope((response) => {
                          // console.log(response)
                          cblFirebase.userDoc.ref.set({showGoogleDriveInstall: false}, {merge: true})
                        })
                        this.onFileClose()
                      }}
                      disabled={googleApi.hasDriveFullScope()}
                  >
                    <div className={this.props.classes.image}/>
                    <Typography className={this.props.classes.typography}>Update Google Drive
                      Permissions...</Typography>
                  </MyMenuItem>
                </Fragment>
                :
                null
          }
        </Popover>

        <MyMenuRoot
          id="menu-edit"
          onClick={(event) => {
            this.setState({
              showEditMenu: !this.state.showEditMenu,
              editMenuAnchor: event.currentTarget,
            })
          }}
        >
          Edit
        </MyMenuRoot>
        <Popover
          open={this.state.showEditMenu}
          anchorEl={this.state.editMenuAnchor}
          onClose={this.onFileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MyMenuItem
            id="menu-edit-undo"
            onClick={() => {
              editorInst.undo()
              this.onFileClose()
            }}
            disabled={!this.props.hasUndo}
          >
            <UndoIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Undo</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+Z</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-edit-redo"
            onClick={() => {
              editorInst.redo()
              this.onFileClose()
            }}
            disabled={!this.props.hasRedo}
          >
            <RedoIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Redo</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+Y</Typography>
          </MyMenuItem>
          <div className={this.props.classes.menuSeperator} />
          <MyMenuItem
            disabled={!this.props.hasSelection}
            id="menu-edit-cut"
            onClick={() => {
              const text = editorInst.getSelectedText()
              copy(text)
              this.setState({ myClipboard: text })
              editorInst.remove()
              this.onFileClose()
            }}
          >
            <CutIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Cut</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+X</Typography>
          </MyMenuItem>
          <MyMenuItem
            disabled={!this.props.hasSelection}
            id="menu-edit-copy"
            onClick={() => {
              const text = editorInst.getSelectedText()
              copy(text)
              this.setState({ myClipboard: text })
              this.onFileClose()
            }}
          >
            <CopyIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Copy</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+C</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-edit-paste"
            onClick={() => {
              editorInst.insert(this.state.myClipboard)
              this.onFileClose()
            }}
            disabled={this.state.myClipboard === ''}
          >
            <PasteIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Paste</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+V</Typography>
          </MyMenuItem>
          <div className={this.props.classes.menuSeperator} />
          <MyMenuItem
            id="menu-edit-search"
            onClick={() => {
              editorInst.execCommand('find')
              this.onFileClose()
            }}
          >
            <SearchIcon disabled={false} className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Search...</Typography>
            <Typography className={this.props.classes.accel}>Ctrl+F</Typography>
          </MyMenuItem>
        </Popover>

        <MyMenuRoot
          id="menu-view"
          onClick={(event) => {
            this.setState({
              showViewMenu: !this.state.showViewMenu,
              viewMenuAnchor: event.currentTarget,
            })
          }}
        >
          View
        </MyMenuRoot>
        <Popover
          open={this.state.showViewMenu}
          anchorEl={this.state.viewMenuAnchor}
          onClose={this.onFileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MyMenuItem
            id="menu-view-lessons"
            onClick={() => {
              this.props.onLessonClick()
              this.onFileClose()
            }}
          >
            {this.props.showLessonPanel ? <CheckIcon className={this.props.classes.image} /> : <div className={this.props.classes.image} />}
            <Typography className={this.props.classes.typography}>Show Lesson Panel</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-view-debug"
            onClick={() => {
              this.props.onDebugClick()
              this.onFileClose()
            }}
            disabled={!this.props.isActivated}
          >
            {this.props.showDebugPanel ? <CheckIcon className={this.props.classes.image} /> : <div className={this.props.classes.image} />}
            <Typography className={this.props.classes.typography}>Show Debug Panel</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-view-adv-debug"
            onClick={() => {
              this.props.onShowAdvDebugClick()
              this.onFileClose()
            }}
            disabled={!this.props.isActivated}
          >
            {this.props.showAdvDebug ? <CheckIcon className={this.props.classes.image} /> : <div className={this.props.classes.image} />}
            <Typography className={this.props.classes.typography}>Show Advanced Debug</Typography>
          </MyMenuItem>
        </Popover>

        <MyMenuRoot
          id="menu-help"
          onClick={(event) => {
            this.setState({
              showHelpMenu: !this.state.showHelpMenu,
              helpMenuAnchor: event.currentTarget,
            })
          }}
        >
          Help
        </MyMenuRoot>
        <Popover
          open={this.state.showHelpMenu}
          anchorEl={this.state.helpMenuAnchor}
          onClose={this.onFileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MyMenuItem
            id="menu-help-support"
            onClick={() => {
              window.open('https://learn.firialabs.com/support/knowledge-base', '_blank')
            }}
          >
            <LinkIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Firia Labs Support</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-mb-docs"
            onClick={() => {
              window.open('http://microbit-micropython.readthedocs.io/en/latest/microbit_micropython_api.html', '_blank')
            }}
          >
            <LinkIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>micro:bit Docs</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-py-docs"
            onClick={() => {
              window.open('https://docs.python.org/release/3.4.7/', '_blank')
            }}
          >
            <LinkIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Python Docs</Typography>
          </MyMenuItem>
          <div className={this.props.classes.menuSeperator} />
          <MyMenuItem
            id="menu-help-licenses"
            onClick={(e) => {
              e.stopPropagation()
              this.props.onShowLicenseManagerClick()
              this.onFileClose()
            }}
          >
            <AssignmentIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Manage Licenses</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-course-selection"
            onClick={(e) => {
              e.stopPropagation()
              this.props.onShowCourseSelectClick()
              this.onFileClose()
            }}
          >
            <CurriculumIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Select curriculum...</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-add-assignments"
            onClick={(e) => {
              e.stopPropagation()
              this.props.onShowAddAssignmentsClick()
              this.onFileClose()
            }}
          >
            <AddAssignmentsIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Add Student Assignments...</Typography>
          </MyMenuItem>
          <div className={this.props.classes.menuSeperator} />
          <MyMenuItem
            id="menu-help-goto-dashboard"
            onClick={(e) => {
              e.stopPropagation()
              window.open('/dashboard', '_blank')
            }}
          >
            <DashboardIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Teacher Dashboard</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-join-group"
            onClick={(e) => {
              e.stopPropagation()
              this.onFileClose()
              this.props.onShowJoinGroupClick()
            }}
          >
            <JoinClassIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Join Class</Typography>
          </MyMenuItem>          
          <div className={this.props.classes.menuSeperator} />
          <MyMenuItem
            id="menu-help-troubleshoot"
            onClick={() => {
              this.props.onShowTroubleClick()
              this.onFileClose()
            }}
          >
            <MemoryIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Troubleshooting</Typography>
          </MyMenuItem>
          <MyMenuItem
            id="menu-help-firmware"
            onClick={() => {
              this.props.onShowFirmwareClick()
              this.onFileClose()
            }}
          >
            <DownloadIcon className={this.props.classes.image} />
            <Typography className={this.props.classes.typography}>Firmware Download</Typography>
          </MyMenuItem>

        </Popover>

        <div id="doc-status-text" style={menuUpdateStyle} title={this.getStatusText()}>
          {this.getStatusText()}
        </div>
      </div>
    )
  }
}


export default withStyles(styles)(MenuBar)
