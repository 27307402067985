// Python3 syntax checking in Javascript
import {editorInst} from './CodeEditor'
import * as antlr4 from 'antlr4/index'
import Python3Lexer from './generated-parser/Python3Lexer'
import Python3Parser from './generated-parser/Python3Parser'
import { Python3Listener } from './generated-parser/Python3Listener'
import { ErrorListener } from 'antlr4/error'
import { ParseTreeWalker } from 'antlr4/tree'
import { DefaultErrorStrategy } from 'antlr4/error/ErrorStrategy'
//import { BailErrorStrategy } from 'antlr4/error/ErrorStrategy'
import { Token, Interval } from 'antlr4'
import ace from 'brace'
import ErrorHelper from './ErrorHelper'
import { tracker } from './tracking'
import { cblFirebase } from './myfirebase'
const Range = ace.acequire('ace/range').Range  // really the best way to import this?

// Override functions called on entry/exit for each node of parse tree, to gather desired state.
class PyListener extends Python3Listener {
  constructor (syntaxChecker) {
    super()

    // Main job is to populate checker's parseResults object:
    this.checker = syntaxChecker
    this.checker.parseResults = {
      defs: [],    // every defined func name
      params: [],          // every param from every func defined, etc.
      calls: [],           // every callable called
      args: [],            // every arg passed to any func, etc.
      imports: [],         // imported module names
      statements: [],      // 'while', 'if', 'for', 'break', 'continue'
      names: [],           // Catch-all for now
    }
  }

  addStatement = (stmt) => {
    if (!this.checker.parseResults.statements.includes(stmt)) {
      this.checker.parseResults.statements.push(stmt)
    }
  }

  addResult = (prop, ...val) => {
    const a = this.checker.parseResults[prop]
    if (Array.isArray(val)) {
      val.forEach(element => {
        if (!a.includes(element)) {
          a.push(element)
        }
      })
    }
    else {
      if (!(a.includes(val))) {
        a.push(val)
      }
    }
  }

  enterFor_stmt = (ctx) => {
    this.addStatement('for')
  }
  enterWhile_stmt = (ctx) => {
    this.addStatement('while')
  }
  enterIf_stmt = (ctx) => {
    this.addStatement('if')
    // Seek 'elif' and 'else' in this stmtContext
    for (let c of ctx.children) {
      if (c.symbol) {
        const txt = c.getText()
        if (txt !== ':') {
          this.addStatement(txt)
        }
      }
    }
  }
  enterContinue_stmt = (ctx) => {
    this.addStatement('continue')
  }
  enterBreak_stmt = (ctx) => {
    this.addStatement('break')
  }

  enterParameters = (ctx) => {
    // Function def param list, e.g. '(foo, bar, zot=3)'
    //console.log(`Line ${ctx.start.line} Parameters: ${ctx.getText()}`)
    const params = ctx.getText().slice(1,-1).split(',')  // Remove parens, make into list
    this.addResult('params', ...params)
    this.addResult('names', ...params)
  }

  enterArgument = (ctx) => {
    // Single arg in function call arg list
    //console.log(`Line ${ctx.start.line} Argument: ${ctx.getText()}`)
    const name = ctx.getText()
    this.addResult('args', name)
  }

  enterAtom = (ctx) => {
    //console.log(`Line ${ctx.start.line} Atom: ${ctx.getText()}`)
    const name = ctx.getText()
    this.addResult('names', name)
  }

  checkCalls = (text) => {
    // Hack to get some calls
    // TODO: Remove these hacks and traverse ctx node tree, gathering real terminal nodes...
    const re = /([[_a-zA-Z][_a-zA-Z0-9.]+)\(/g
    while (true) {
      let m = re.exec(text)
      if (m) {
        this.addResult('calls', m[1])
      }
      else {
        break
      }
    }
  }

  enterTest = (ctx) => {
    //console.log(`Line ${ctx.start.line} Test: ${ctx.getText()}`)
    const testText = ctx.getText()

    this.checkCalls(testText)

    // Hack to scrape some names
    const names = ctx.getText().split(/[.(),[\]]/)
    this.addResult('names', ...names)
  }

  enterOr_test = (ctx) => {
    // TODO: Be more comprehensive with these checks as we could have many more children here...
    if (ctx.getChildCount() >= 3 && ctx.children[1].getText() === 'or') {
      this.addStatement('or')
      this.checkCalls(ctx.children[0].getText())
      this.checkCalls(ctx.children[2].getText())
    }
  }

  enterAnd_test = (ctx) => {
    if (ctx.getChildCount() >= 3 && ctx.children[1].getText() === 'and') {
      this.addStatement('and')
      this.checkCalls(ctx.children[0].getText())
      this.checkCalls(ctx.children[2].getText())
    }
  }

  enterNot_test = (ctx) => {
    if (ctx.getChildCount() >= 2 && ctx.children[0].getText() === 'not') {
      this.addStatement('not')
      //this.checkCalls(ctx.children[0].getText())
      //this.checkCalls(ctx.children[2].getText())
    }
  }

  enterImport_from = (ctx) => {
    // from NAME import ...
    //console.log(`Line ${ctx.start.line} Enter import from: ${ctx.getText()}`)
    const name = ctx.children[1].getText()
    this.addResult('imports', name)
  }

  enterImport_name = (ctx) => {
    //console.log(`Line ${ctx.start.line} Enter import name: ${ctx.getText()}`)
    const name = ctx.children[1].getText()
    this.addResult('imports', name)
  }

  enterFuncdef = (ctx) => {
    //console.log(`Line ${ctx.start.line} Enter func: ${ctx.NAME().getText()} ${ctx.parameters().getText()}`)
    const name = ctx.NAME().getText()
    this.addResult('defs', name)
    this.addResult('names', name)
  }

}

class PyErrorListener extends ErrorListener {
  constructor (syntaxChecker) {
    super()
    this.checker = syntaxChecker
  }

  syntaxError = (recognizer, offendingSymbol, line, column, msg, e) => {
    syntaxChecker.curErrCount++;
    if (syntaxChecker.curErrCount > syntaxChecker.stopAfterNerrors) {
      return
    }

    let errorMsg = msg
    const logContent = {
      userCode: `${editorInst.getValue()}\n`,
      antlrMsg: msg,
      line,
      column,
    }

    const errorHelper = new ErrorHelper()
    const friendlyError = errorHelper.friendlyError(msg, errorHelper.ERR_SYNTAX)
    errorMsg = friendlyError.errorMsg

    // console.log("Python Syntax: " + errorMsg)
    const session = editorInst.getSession()

    // TODO: Check if line exists in actual editor code (could be last \n we added) - and adjust line/col to end of
    //       prior line if so!  E.g. at end: display.show(Image.HEART

    const id = session.addMarker(new Range(line-1, column, line-1, column + offendingSymbol.text.length),
                                 "ace-syntaxerr", "line", true)
    this.checker.errorMarkers.push(id)

    // Set annotations cumulatively (oddly Ace has no builtin for this)
    const ann = session.getAnnotations()
    ann.push({
      row: line - 1,
      column: column,
      text: errorMsg,      // Can alternately specify annotation.html: ...
      type: "error"   // 'error', 'warning', or 'info' correspond to CSS of 'ace_error', etc.
    })
    session.setAnnotations(ann)

    logContent.errorMsg = errorMsg
    cblFirebase.logError({
      type: 'syntax',
      category: friendlyError.errCategory,
      content: logContent,
    })

    if (this.checker.codeErrorCb) {
      this.checker.codeErrorCb('Syntax', line, errorMsg)
    }
  }

}

// Approach 1:
// Since we want to stop on first error, override BailErrorStrategy, rather than DefaultErrorStrategy.
// "Bail" throws an exception upon first error, which we catch in the try block when invoking the parser below.
// Approach 2:
// Override default error strategy, and decide for ourselves when to stop reporting errors.
class PyErrorStrategy extends DefaultErrorStrategy {

  reportMissingToken = (recognizer, e) => {
    if (this.inErrorRecoveryMode(recognizer)) {
      return
    }

    this.beginErrorCondition(recognizer)
    let t = recognizer.getCurrentToken()
    let unexpectedToken = this.getTokenErrorDisplay(t)
    const expecting = this.getExpectedTokens(recognizer)
    const expectedToken = expecting.toString(recognizer.literalNames, recognizer.symbolicNames)

    while ((unexpectedToken.slice(1,-1).trim().length === 0 || unexpectedToken === "'\\n'") && t.tokenIndex > 0) {
      t = recognizer._input.tokens[t.tokenIndex - 1]
      unexpectedToken = "'" + t.text + "'"
    }

    let msg = "Missing " + expectedToken + " at " + unexpectedToken
    if (expectedToken === "':'") {
      msg = "Expected a colon ':' after " + unexpectedToken
    }

    recognizer.notifyErrorListeners(msg, t, null)
  }

  truncatedExpectedTokens = (recognizer, e) => {
    let expected = e.getExpectedTokens().toString(recognizer.literalNames, recognizer.symbolicNames)
    if (expected.length > 40) {
      expected = expected.substr(0, 40) + '...'
    }
    return expected
  }

  reportInputMismatch = (recognizer, e) => {
    let badToken = e.offendingToken
    const badText = badToken.text.trim()
    let msg = ''

    // If offending token text is whitespace, point to prior token
    if (badText.trim().length === 0 && badToken.tokenIndex > 0) {
      badToken = recognizer._input.tokens[badToken.tokenIndex - 1]
      e.offendingToken = badToken
      msg += "Expected: " + this.truncatedExpectedTokens(recognizer, e)
    }
    else if (e.offendingToken.text === '<EOF>') {
      msg = "At end of file, expected '" + this.truncatedExpectedTokens(recognizer, e) + "'"
    }
    else {
      msg = "Symbol '" + e.offendingToken.text + "' does not belong here.\nExpected '" + this.truncatedExpectedTokens(recognizer, e) + "'"
    }

    recognizer.notifyErrorListeners(msg, e.offendingToken, e)
  }

  reportNoViableAlternative = (recognizer, e) => {
    var tokens = recognizer.getTokenStream();
    var input;
    if(tokens !== null) {
        if (e.startToken.type===Token.EOF) {
            input = "<EOF>";
        } else {
            input = tokens.getText(new Interval(e.startToken, e.offendingToken));
            input = input.replace("\n", "")
        }
    } else {
        input = "<unknown input>";
    }
    var msg = "Not understanding the code: " + this.escapeWSAndQuote(input);
    recognizer.notifyErrorListeners(msg, e.offendingToken, e);
  }

  reportUnwantedToken = (recognizer)  => {
    if (this.inErrorRecoveryMode(recognizer)) {
        return;
    }
    this.beginErrorCondition(recognizer);
    var t = recognizer.getCurrentToken();
    var tokenName = this.getTokenErrorDisplay(t);
    var expecting = this.getExpectedTokens(recognizer);

    // If offending token is EOL, point to prior token
    if (tokenName === "'\\n'" && t.tokenIndex > 0) {
      t = recognizer._input.tokens[t.tokenIndex - 1]
      tokenName = "'End of Line'"
      // Adjust column to point after prior token
      t.column += recognizer.getTokenErrorDisplay(t).length - 2  // subtract enclosing quotes
    }

    var msg = "Saw " + tokenName + " but expected " +
    expecting.toString(recognizer.literalNames, recognizer.symbolicNames);

    recognizer.notifyErrorListeners(msg, t, null);
  }

}

class SyntaxChecker {

  constructor () {
    this.errorMarkers = []
    this.stopAfterNerrors = 1
    this.curErrCount = 0
    this.parseResults = null
    this.codeErrorCb = null
  }

  setCodeErrorCallback = (cb) => {
    this.codeErrorCb = cb
  }

  // Beginning of a function to traverse the tree. Switching to using Antlr's "Listener/Walker", but
  // leaving this code for future reference.
  dumpParseResult = (tree, ruleNames) => {
    let treeDump = 'Tree Dump:\n'

    for (let i = 0; i < tree.children.length; ++i) {
      const child = tree.children[i]
      const nodeType = ruleNames[child.ruleIndex]
      treeDump += "node: " + nodeType + ", obj: "+ child.constructor.name + ", " + child.getText() + "\n"
      if ('children' in child) {
        this.dumpParseResult(child, ruleNames)
      }
    }
    console.log(treeDump)
  }

  addLineMarker = (line) => {
    const session = editorInst.getSession()
    const editorLineText = session.getLine(line-1)
    const id = session.addMarker(new Range(line-1, 0, line-1, editorLineText.length),
                                 "ace-syntaxerr", "line", true)
    this.errorMarkers.push(id)
  }

  // Remove all gutter annotations
  clearEditorAnnotations = () => {
    const session = editorInst.getSession()
    session.clearAnnotations()
  }

  // Remove all column-highlight markers
  clearEditorMarkers = () => {
    if (this.errorMarkers) {
      const session = editorInst.getSession()
      this.errorMarkers.forEach((id) => {
        session.removeMarker(id)
      })
      this.errorMarkers = []
    }
  }

  check = () => {
    // Start with a clean slate of error markers
    this.clearEditorAnnotations()
    this.clearEditorMarkers()
    this.curErrCount = 0
    this.parseResults = null

    // Grab editor text. Append newline, since parser grammar requires it
    let input = editorInst.getValue() + '\n'

    // Standard Antlr4 setup
    const chars = new antlr4.InputStream(input);
    const lexer = new Python3Lexer.Python3Lexer(chars);
    const tokens  = new antlr4.CommonTokenStream(lexer);
    const parser = new Python3Parser.Python3Parser(tokens);
    parser.buildParseTrees = true;

    // Add special error handler hooks
    parser.removeErrorListeners()  // remove default ConsoleErrorListener
    parser.addErrorListener(new PyErrorListener(this))
    parser._errHandler = new PyErrorStrategy()  // setErrorHandler() not in JS library, so we set directly

    // Parse input - starting with 'file_input' as root
    try {
      const tree = parser.file_input();
      //this.dumpParseResult(tree, parser.ruleNames)
      const walker = new ParseTreeWalker()
      walker.walk(new PyListener(this), tree)
    }
    catch (e) {
      tracker.addBreadcrumb("Parser exception", 'parser', 'error', e)
    }

    tracker.addBreadcrumb("Parse Results", 'parser', 'debug', this.parseResults)

    return parser._syntaxErrors  // Number of errors found, 0 if none
  }

}

const syntaxChecker = new SyntaxChecker()
export default syntaxChecker
