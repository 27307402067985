import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DownArrow from './down-arrow.svg'
import ResizeObserver from 'resize-observer-polyfill'

export default class ScrollIndicator extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    keepScrollBar: PropTypes.bool,
  }

  static defaultProps = {
    keepScrollBar: false,
  }

  componentDidMount() {
    // Scroll down when the down arrow is clicked
    const elemScrollImg = this.image
    const elemContent = this.wrapper
    elemScrollImg.addEventListener('click', () => {
      elemContent.scrollBy({
        top: elemContent.clientHeight - 50,
        left: 0,
        behavior: 'smooth',
      })
    })
    this.ro = new ResizeObserver(() => {
      // console.log("resize")
      this.forceUpdate()
    })
    this.ro.observe(this.scroller)
  }

  componentWillUnmount() {
    this.ro.disconnect()
  }

  scrollTop = () => {
    this.wrapper.scrollTop = 0
  }

  contentScroll = () => {
    // Force render() on scroll event, to update Scroll Arrow
    this.forceUpdate()
  }

  scrollArrowOpacity = () => {
    const maxOpacity = 0.5
    const minOpacity = 0.2

    // Distance from the end before we give up and just hide the arrows.
    const closeEnough = 30

    const elt = this.wrapper
    if (elt) {
      let scrollPortion = ((elt.scrollHeight - closeEnough) - elt.clientHeight)
      if (scrollPortion < 0) {
        scrollPortion = 0
      }
      const pctScrolled = scrollPortion ? Math.ceil(elt.scrollTop) / scrollPortion : 1.0
      // console.log("pctScrolled=", pctScrolled)
      // console.log('scrollHeight=', elt.scrollHeight)
      // console.log('scrollTop=', elt.scrollTop)
      // console.log('clientHeight=', elt.clientHeight)
      if (pctScrolled >= 1) {
        return 0
      }
      return ((maxOpacity-minOpacity) * (1 - pctScrolled)) + minOpacity
    }
    return 0
  }

  render() {
    const opacity = this.scrollArrowOpacity()
    const styles = {
      container: {
        maxHeight: 'inherit',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        minHeight: 0,
      },
      wrapper: {
        flex: '1 1 auto',
        overflowY: this.props.keepScrollBar ? 'scroll' : 'auto',
        minHeight: '50px',
      },
      arrowWrapper: {
        position: 'relative',
        margin: 'auto',
        display: 'block',
      },
      arrowImage: {
        width: 50,
        marginLeft: -25,
        position: 'absolute',
        bottom: 15,
        zIndex: 5,
        opacity: opacity,
        visibility: opacity ? 'visible' : 'hidden',
        cursor: 'pointer',
        filter: 'drop-shadow(0 0 2px #FFFFFF)',
      },
    }

    return (
      <div style={styles.container}>

        {/* Displayed content */}
        <div
          ref={(elem) => {this.wrapper = elem}}
          onScroll={this.contentScroll}
          style={styles.wrapper}
        >
          <div ref={(elem) => {this.scroller = elem}} >
            {this.props.children}
          </div>
        </div>

        {/* Scroll indicator arrow - bottom center */}
        <div style={styles.arrowWrapper} >
          <img
            src={DownArrow}
            ref={(elem) => {this.image = elem}}
            alt='Scroll down arrow'
            className='scroll-down-arrow'
            style={styles.arrowImage}
          />
        </div>

      </div>
    )
  }
}
