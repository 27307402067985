// Project: Finale - not really a project, but an "End of the Line" notice

import React, {Component} from 'react'
import Markdown from '../cbl-remarkable'
import PropTypes from 'prop-types'
import TrailContinues from './assets/TrailContinues.jpg'

class Finale extends Component {
  static propTypes = {
    lessons: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div>
<Markdown>
{
`## The End (...for now) {lesson-title}
___
#### This is the end of the current project set {centered}

**But don't despair!**
* By now you likely have a **lot** of ideas for your own projects
  * Explore and Build!
  * Share your creations with us on your favorite social media ***@FIRIALABS!***
* The team at **Firia Labs** has a lot more projects in store for you too. Stay tuned!

### Enjoy your continuing adventures with coding!
`}
<img src={TrailContinues} alt=""
    style={{
      width: '90%', margin:'auto', display:'block',
    }}
/>
</Markdown>
      </div>
    )
  }
}

// Add static step IDs to uniquely identify the steps (minify nixes class names)

Finale.stepId = 'Finale'

export const finale = [
  Finale,
]
