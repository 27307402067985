class MicroBitEnum {
  constructor() {
    // Enum used to track the microbit's mode
    this.mode = Object.freeze({
      REPL: 1, // >>>
      RAW: 2, // >
      PASTE: 3, // ..?
      DEBUG: 4, // <<<
      UNKNOWN: 5 // ..?
    })

    // Enum used to track the microbit's state
    this.state = Object.freeze({
      RUNNING: 1, // No prompt
      IDLE: 2, // Waiting at prompt
      UNKNOWN: 3 // We don't know
    })
  }

  getStateName = (value) => {
    return Object.keys(this.state).find(key => this.state[key] === value)
  }

  getModeName = (value) => {
    return Object.keys(this.mode).find(key => this.mode[key] === value)
  }
}

const microBitEnum = new MicroBitEnum()
export default microBitEnum
